import { Component, OnInit, ViewChild } from '@angular/core';
import { DataBaseService } from '../provider.service';
import { TabsetComponent } from 'ngx-bootstrap/tabs';
import * as moment from 'moment';
import { AppComponent } from '../app.component';

@Component({
  selector: 'app-celesc',
  templateUrl: './celesc.component.html',
  styleUrls: ['./celesc.component.css']
})
export class CelescComponent implements OnInit {
  @ViewChild('staticTabs', { static: false }) staticTabs: TabsetComponent;
  contrato:any = "";
  idCelesc:any = "";
  codigoConcessionaria:any = "";
  nomeClienteContratante:any = "";
  numSequencialEnvio:number = null;
  codigoContaGerencial:any = "";
  associados:any = [];
  arrayRemessasSelecionadas:any = [];
  arrayRetorno:any = [];
  comandosDoMovimento:any = [
    {"codigo":"74","descricao":"Cadastramento da UC"},
    {"codigo":"75","descricao":"Rejeição por Troca de Titularidade"},
    {"codigo":"77","descricao":"Cancelamento do plano de convênio"},
    {"codigo":"78","descricao":"Alteração cadastral ou término de cobertura"},
    {"codigo":"80","descricao":"Data de Vencimento Prevista"}
  ];
  informativoDoRegistro:any = [
    {"codigo":"81","descricao":"Faturado"},
    {"codigo":"82","descricao":"Arrecadado (Fatura Paga)"},
    {"codigo":"86","descricao":"Alteração de vencimento"},
    {"codigo":"90","descricao":"Parcela cancelada"},
    {"codigo":"91","descricao":"Cancelamento da arrecadação"}
  ];
  ocorrencias:any = [
    {"codigo":"00","descricao":"Ativo"},
    {"codigo":"01","descricao":"Dificuldade financeira"},
    {"codigo":"02","descricao":"Já possui seguro"},
    {"codigo":"03","descricao":"Não quer mais o seguro"},
    {"codigo":"19","descricao":"Outros"},
    {"codigo":"21","descricao":"Mudança de Classe / Classe da UC não permitida"},
    {"codigo":"22","descricao":"Troca de titularidade"},
    {"codigo":"23","descricao":"Grupo de tensão da UC diferente de A ou B"},
    {"codigo":"24","descricao":"Localização da UC não é Urbana ou Rural"},
    {"codigo":"25","descricao":"Localidade sem convênio"},
    {"codigo":"26","descricao":"Convênio encerrado"},
    {"codigo":"27","descricao":"Convênio de localidade encerrado"},
    {"codigo":"28","descricao":"Unidade consumidora desligada"},
    {"codigo":"29","descricao":"Unidade consumidora não existe"},
    {"codigo":"40","descricao":"CPF enviado diferente do cadastro"},
    {"codigo":"73","descricao":"UC com vigência expirada. Cancelado em mês anterior"},
    {"codigo":"75","descricao":"Rejeição por Troca de Titularidade"},
    {"codigo":"80","descricao":"Data vencimento prevista"},
    {"codigo":"85","descricao":"Duplicidade"},
    {"codigo":"98","descricao":"Entrada confirmada"},
    {"codigo":"99","descricao":"De - Para de identificação do cliente"}
  ]
  
  constructor(public db: DataBaseService, public app:AppComponent) { }

  ngOnInit(){
    this.db.getDadosCelesc().subscribe((data:any) => {
      this.idCelesc = data[0]._id;
      this.contrato = data[0].contrato;
      this.codigoConcessionaria = data[0].codigoConcessionaria;
      this.nomeClienteContratante = data[0].nomeClienteContratante;
      this.numSequencialEnvio = data[0].numSequencialEnvio;
      this.codigoContaGerencial = data[0].codigoContaGerencial;
    })
  }

  getFile(e:any,tipo:any){
    this.arrayRetorno = [];
    let arquivo:any;
    if(tipo == 'drop'){
      arquivo = e[0];
      let nome = e[0].name.split('.');
      if(nome[nome.length - 1] != "PAD"){
        this.app.openAlert("Arquivo Incorreto!");
        return;
      }
    }else{
      arquivo = e.target.files[0];
    }
    this.readFileContent(arquivo).then((content:any) => {
      if(content){
        let linhas = content.split(/\r\n|\n/);
        linhas.forEach((l:any) => {
            this.separarLinha(l);
        })
      }else{
        this.app.openAlert("Arquivo Incorreto!");
      }
    }).catch(error => console.log(error))
  }
  
  
  readFileContent(f:any){
    const reader = new FileReader()
    return new Promise((resolve, reject) => {
      reader.onload = event => resolve(event.target.result)
      reader.onerror = error => reject(error)
      reader.readAsText(f)
    })
  }

  retornaCoberturaOcorrencia(cod:any){
    let i = this.ocorrencias.map((item) => {
      return item.codigo;
    }).indexOf(cod);
    if(i >= 0){
      return this.ocorrencias[i].descricao;
    }
  }

  retornaComandoMovimento(cod:any){
    let i = this.comandosDoMovimento.map((item) => {
      return item.codigo;
    }).indexOf(cod);
    if(i >= 0){
      return this.comandosDoMovimento[i].descricao;
    }
  }

  retornaInformativo(cod:any){
    let i = this.informativoDoRegistro.map((item) => {
      return item.codigo;
    }).indexOf(cod);
    if(i >= 0){
      return this.informativoDoRegistro[i].descricao;
    }
  }

  formataCPF(cpf){
    cpf = cpf.replace(/[^\d]/g, "");
    return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
  }
  
  separarLinha(linha:any){
    let rgb:any = [255, 0, 0];
    rgb[0] = Math.round(Math.random() * 255);
    rgb[1] = Math.round(Math.random() * 255);
    rgb[2] = Math.round(Math.random() * 255);
    let brightness = Math.round(((parseInt(rgb[0]) * 299) + (parseInt(rgb[1]) * 587) + (parseInt(rgb[2]) * 114)) / 1000);
    let color = (brightness > 125) ? 'black' : 'white';
    let bg = 'rgb(' + rgb[0] + ',' + rgb[1] + ',' + rgb[2] + ')';
    let identificacao = linha.slice(0,1);
    if(identificacao == 2 || identificacao == 6){
     if(this.arrayRetorno.length > 0){
      let i = this.arrayRetorno.map((a) => {
          return a.unidadeConsumidora
        }).indexOf(linha.slice(1,14))
        if(i >= 0){
          bg = this.arrayRetorno[i].bgColor;
          color = this.arrayRetorno[i].fontColor;
        }
      }
      let vlrLancamento = parseFloat(linha.slice(14,23).substring(0,linha.slice(14,23).length-2)+"."+ linha.slice(14,23).substring(linha.slice(14,23).length-2,linha.slice(14,23).length));
      let vlrLBaseCalc = parseFloat(linha.slice(129,144).substring(0,linha.slice(129,144).length-2)+"."+ linha.slice(129,144).substring(linha.slice(129,144).length-2,linha.slice(14,23).length));
      let obj = {
        bgColor:bg,
        fontColor:color,
        identificacao: identificacao,
        unidadeConsumidora: linha.slice(1,14),
        valorLancamento: vlrLancamento,
        dataRegLancamento: linha.slice(23,25)+"/"+linha.slice(25,27)+"/"+linha.slice(27,31),
        contaGerencial: linha.slice(33,41),
        idClienteConveniado: linha.slice(83,89),
        numSeqRegistro: linha.slice(144,150),
        comandoMovimento: "",
        coberturaOcorrencia: "",
        descricaoCoberturaOcorrencia: "",
        cpfCliente: "",
        cnpjCliente: "",
        mesVigencia: "",
        fimVigencia: "",
        complementoCnpj: "",
        informativoRegistro: "",
        codigoOrigemFatura: "",
        numeroFatura: "",
        dataVencimentoBaixa: "",
        valorBaseCalculo: 0
      }
      if(identificacao == 2){
        obj.comandoMovimento = this.retornaComandoMovimento(linha.slice(31,33));
        obj.coberturaOcorrencia = this.retornaCoberturaOcorrencia(linha.slice(41,43));
        obj.descricaoCoberturaOcorrencia = linha.slice(43,73);
        obj.cpfCliente = this.formataCPF(linha.slice(89,100));
        obj.cnpjCliente = linha.slice(89,101);
        obj.mesVigencia = linha.slice(101,103)+"/"+linha.slice(103,105)+"/"+linha.slice(105,109);
        obj.fimVigencia = linha.slice(109,111)+"/"+linha.slice(111,113)+"/"+linha.slice(113,117);
        obj.complementoCnpj = linha.slice(117,119);
      }
      if(identificacao == 6){
        obj.informativoRegistro = this.retornaInformativo(linha.slice(31,33));
        obj.codigoOrigemFatura = linha.slice(101,104);
        obj.numeroFatura = linha.slice(104,121);
        obj.dataVencimentoBaixa = linha.slice(121,123)+"/"+linha.slice(123,125)+"/"+linha.slice(125,129);
        obj.valorBaseCalculo = vlrLBaseCalc;
      }
      this.arrayRetorno.push(obj);
    }
  }

  buscarAssociadosCelesc(){
    this.db.getAssociadosPendentesCelesc().subscribe((data:any) => {
      this.associados = data;
    })
  }

gerarArquivoRemessa(){
  if(this.arrayRemessasSelecionadas.length > 0){
    this.db.getDadosCelesc().subscribe((get:any) => {
      let idCelesc = get[0]._id;
      let dados = get[0];
      let ctrl = 1;
      let remessas = "";
      let somaRemessas = 0;
      let numSequencialRegistro = 2;
      this.arrayRemessasSelecionadas.forEach((r) => {
        let d = r.dataAdesao.split('-').reverse();
        let dtReg = d[0]+d[1]+d[2];
        let mesInicioVigencia = moment(r.adesao).add(1,'months').set('date', 1).format('DDMMyyyy');
        let mensal = this.addZeros((r.totalMensalidade).toFixed(2).replace(/\./g,''),9);
        somaRemessas += r.totalMensalidade;
        remessas += "2"+this.addZeros(r.unidadeConsumidora,13)+mensal+dtReg+"74"+dados.codigoContaGerencial+"00"+"                                        "+this.addZeros(r.matricula,6)+r.cpf.replace(/\./g,'').replace(/-/g,'')+" "+mesInicioVigencia+"00000000"+"                           "+this.addZeros(numSequencialRegistro,6)+"\r\n";
        numSequencialRegistro++;
      if(this.arrayRemessasSelecionadas.length == ctrl){
        let dt = new Date(Date.now() - 10800000).toISOString().split('T')[0].split('-').reverse();
        let dataHoje = dt[0]+dt[1]+dt[2];
        let numSeqEnvio = this.addZeros(dados.numSequencialEnvio+1,6);
        let numSeqRegistroSalvar = this.addZeros(dados.numSequencialEnvio+1,4);
        let header = "1"+dados.contrato+dados.codigoConcessionaria+dataHoje+"R$    "+numSeqEnvio+"  "+dados.nomeClienteContratante+"                                        1000001";
        let footer = "9"+this.addZeros((somaRemessas).toFixed(2).replace(/\./g,''),11)+"                                                                                                                                    "+this.addZeros(numSequencialRegistro,6);
        let text = header+"\r\n"+remessas+footer;
        const element = document.createElement("a");
        const file = new Blob([text], {
          type: "text/plain",
        });
        element.href = URL.createObjectURL(file);
        element.download = "ECEL"+numSeqRegistroSalvar+".PAD";
        document.body.appendChild(element);
        element.click();
        let seqEnvio =  dados.numSequencialEnvio+1;
        let o = {
          numSequencialEnvio: seqEnvio
        }
        if(window.confirm('Deseja Encaminhar o Arquivo via E-mail para Celesc?')){
          let anexo = new FormData();
          anexo.append('files[]', file, "ECEL"+numSeqRegistroSalvar+".PAD")
          this.db.sendRemessaCelesc(anexo).subscribe(res => console.log(res), err => console.log(err))
          this.db.patchCelesc(idCelesc,o).subscribe(res => {
            console.log(res);
            this.ngOnInit();
          }, err => console.log(err))
        }else{
          this.db.patchCelesc(idCelesc,o).subscribe(res => {
            console.log(res);
            this.ngOnInit();
          }, err => console.log(err))
        }
        }else{
          ctrl++;
        };
      });
    });
  };
}

addZeros(num, len) {
  let nums = String(num);
  let counter = nums.length;
  while(counter < len) {
      nums = "0" + nums;
      counter++;
    }
  return nums;
}

buscarAssociados(){
  this.arrayRemessasSelecionadas = [];
  this.associados = [];
  this.db.getAssociadosPendentesCelesc().subscribe((data:any) => {
    if(data.length > 0){
      data.forEach((d) => {
        d.array = true;
        this.associados.push(d);
        this.arrayRemessasSelecionadas.push(d);
      })
    }
  })
}

checkArray(e,associado){
  let marcado = e.target.checked;
  if(marcado){
    this.arrayRemessasSelecionadas.push(associado);
  }else{
    let i = this.arrayRemessasSelecionadas.map((a) => {
      return a.matricula;
    }).indexOf(associado.matricula);
    if(i >= 0){
      this.arrayRemessasSelecionadas.splice(i,1);
    }
  }
}

salvarConfigs(){
  let obj = {
    "contrato" : this.contrato,
    "codigoConcessionaria" : this.codigoConcessionaria,
    "nomeClienteContratante" : this.nomeClienteContratante,
    "numSequencialEnvio" : this.numSequencialEnvio,
    "codigoContaGerencial" : this.codigoContaGerencial
  }
  this.db.patchCelesc(this.idCelesc,obj).subscribe(res =>{
    this.app.openAlert("Dados Salvos com Sucesso!");
    console.log(res);
  }, err => console.log(err))

}

}
