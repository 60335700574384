<div class="row align-items-center header">
    <div class="col col-1">
        &nbsp;
    </div>
    <div class="col titulo" style="text-align: center;">
        GERAR COMISSÕES
    </div>
    <div class="col col-2" style="text-align: right;">
        <button class="btn btn-sm btn-outline-light" (click)="printXsl()">
            <fa-icon [icon]="['fas', 'file-excel']"></fa-icon>
        </button>
        &nbsp;
        <button class="btn btn-sm btn-outline-light" (click)="print()">
            <fa-icon [icon]="['fas', 'print']"></fa-icon>
        </button>
    </div>
</div>
<br>
<div class="row align-items-end" style="padding:5px">
    <div class="col col-4">
        <label>Data</label>
        <input type="month" class="form-control" [(ngModel)]="mesComissao">
    </div>
    <div class="col col-7">
        <label>Colaborador</label>
        <ng-select class="form-control" [items]="colaboradores" bindLabel="nome" appendTo="body" [(ngModel)]="colaborador" (change)="mudouVendedor()"></ng-select>
    </div>
    <div class="col col-1">
        <label>&nbsp;</label>
        <button class="btn btn-primary" (click)="getComissao()" [disabled]="!mesComissao || !colaborador"><fa-icon [icon]="['fas', 'search']"></fa-icon></button>
    </div>
</div>
<br>
<div id="divToPrintCom" *ngIf="associados.length > 0">
    <div class="row print-only" style="margin-left:30px;margin-right:30px">
        <div class="col col-3" style="text-align:center">
            <img src="../../assets/img/logo.png">
        </div>
        <div class="col" style="text-align:center; font-size:2em">
            {{colaborador?.nome}}<br>
            Mês Referência: {{mes(mesComissao)}}       
        </div>
    </div>
    <br>
    <label class="titles">Agenciamento:</label>
    <br>
    <table class="table table-striped">
        <thead class="thead">
            <tr>
                <th>#</th>
                <th>Matricula</th>
                <th>Adesão</th>
                <th>Titular</th>
                <th>Plano</th>
                <th>Contrato</th>
                <th>Pagamento</th>
                <th>Origem</th>
                <th>Mensalidade</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let rel of agenciamentoMes; let i = index">
                <td>{{i+1}}</td>
                <td>{{ rel?.matricula }}</td>
                <td>{{ rel?.dataAdesao | date: 'dd/MM/yyyy' }}</td>
                <td>{{ rel?.nome }}</td>
                <td>{{ rel?.plano }}</td>
                <td>{{ rel?.subContrato ? rel?.subContrato : rel?.planoFisicoCheck ? "PLANO FÍSICO" : "PLANO EMPRESARIAL"}}</td>
                <td>{{ rel?.formaPagamento }}</td>
                <td>{{ rel?.origem == "INCLUSÃO EMPRESA" ? "INCLUSÃO EMPRESA" : rel?.origem == "ALTERAÇÃO" ? "ALTERAÇÃO" : "NOVO"}}</td>
                <td>{{ rel?.totalMensalidade | currency:'BRL'}}</td>
            </tr>
        </tbody>
    </table>
    <table style="margin:10px">
        <tr style="text-align: left;">
            <td style="padding-right:30px"><strong>AGENCIAMENTO:</strong></td>
            <td>{{ valorTotalVendas | currency:'BRL'}}</td>
        </tr>
    </table>  
    <br>
    <label class="titles">Cancelados no Mês:</label>
    <br>
    <table class="table table-striped">
        <thead class="thead">
            <tr>
                <th>#</th>
                <th>Matricula</th>
                <th>Titular</th>
                <th>Adesão</th>
                <th>Data Cancelamento</th>
                <th>Motivo</th>
                <th>Plano</th>
                <th>Contrato</th>
                <th>Comissão</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let rel of canceladosNoMes; let i = index">
                <td>{{i+1}}</td>
                <td>{{ rel?.matricula }}</td>
                <td>{{ rel?.nome }}</td>
                <td>{{ rel?.dataAdesao | date: 'dd/MM/yyyy' }}</td>
                <td>{{ rel?.dataCancelamento  | date: 'dd/MM/yyyy' }}</td>
                <td>{{ rel?.motivoCancelamento }}</td>
                <td>{{ rel?.plano }}</td>
                <td>{{ rel?.subContrato ? rel?.subContrato : rel?.planoFisicoCheck ? "PLANO FÍSICO" : "PLANO EMPRESARIAL"}}</td>
                <td>{{ rel?.totalMensalidade | currency:'BRL'}}</td>
            </tr>
        </tbody>
    </table>
    <br>
    <table style="margin:10px">
        <tr style="text-align: left;">
            <td style="padding-right:30px"><strong>AGENCIAMENTOS CANCELADOS NO MÊS:</strong></td>
            <td>{{ vlrAssociadosCanceladosMes | currency:'BRL'}}</td>
        </tr>
    </table>
    <br>
    <label class="titles">Cancelados:</label>
    <br>
    <table class="table table-striped">
        <thead class="thead">
            <tr>
                <th>#</th>
                <th>Matricula</th>
                <th>Titular</th>
                <th>Adesão</th>
                <th>Data Cancelamento</th>
                <th>Motivo</th>
                <th>Plano</th>
                <th>Contrato</th>
                <th>Forma Pagamento</th>
                <th>Mensalidade</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let rel of cancelados; let i = index">
                <td>{{i+1}}</td>
                <td>{{ rel?.matricula }}</td>
                <td>{{ rel?.nome }}</td>
                <td>{{ rel?.dataAdesao | date: 'dd/MM/yyyy' }}</td>
                <td>{{ rel?.dataCancelamento  | date: 'dd/MM/yyyy' }}</td>
                <td>{{ rel?.motivoCancelamento }}</td>
                <td>{{ rel?.plano }}</td>
                <td>{{ rel?.subContrato ? rel?.subContrato : rel?.planoFisicoCheck ? "PLANO FÍSICO" : "PLANO EMPRESARIAL"}}</td>
                <td>{{ rel?.formaPagamento }}</td>
                <td>{{ rel?.valorComissao | currency:'BRL'}}</td>
            </tr>
        </tbody>
    </table>
    <br>
    <table style="margin:10px">
        <tr style="text-align: left;">
            <td style="padding-right:30px"><strong>CANCELADOS:</strong></td>
            <td>{{ valorTotalCancelados | currency:'BRL'}}</td>
        </tr>
    </table>
    <br>
    <label class="titles">Bônus de Produtividade:</label>
    <br>
    <table style="margin:10px">
        <tr style="text-align: left;">
            <td style="padding-right:30px"><strong>META DE VENDAS:</strong></td>
            <td style="text-align: right;">{{ metaColaborador | currency:'BRL' }}</td>
        </tr>
        <tr style="text-align: left;">
            <td style="padding-right:30px"><strong>VENDAS NO MÊS</strong><br><span style="font-size:12px;font-style:italic;">(AGENCIAMENTO - CANCELADOS):</span></td>
            <td style="text-align: right;">{{ (valorTotalVendas -  vlrAssociadosCanceladosMes)  | currency:'BRL' }}</td>
        </tr>
        <tr style="text-align: left;">
            <td style="padding-right:30px"><strong>BÔNUS:</strong></td>
            <td style="text-align: right;">{{ bonusColaborador | currency:'BRL'}}</td>
        </tr>
    </table>
    <br>
    <label class="titles">Resumo Financeiro:</label>
    <br>
    <table style="margin:10px">
        <tr style="text-align: left;">
            <td style="padding-right:30px"><strong>COMISSÃO:</strong></td>
            <td style="text-align: right;">(+){{ comissaoColaborador | currency:'BRL' }}</td>
        </tr>
        <tr style="text-align: left;">
            <td colspan="2" style="font-size:14px">
                {{percentualAtingido < 100 ? '90% DO AGENCIAMENTO' : percentualAtingido <= 129 ? '100% DO AGENCIAMENTO' : '120% DO AGENCIAMENTO'}}
            </td>
        </tr>
        <tr style="text-align: left;">
            <td style="padding-right:30px"><strong>ALTERAÇÃO (40%):</strong></td>
            <td style="text-align: right;">(+){{ totalVendasAlteracao | currency:'BRL' }}</td>
        </tr>
        <tr style="text-align: left;">
            <td style="padding-right:30px"><strong>BÔNUS:</strong></td>
            <td style="text-align: right;">(+){{ bonusColaborador | currency:'BRL' }}</td>
        </tr>
        <tr style="text-align: left;">
            <td style="padding-right:30px"><strong>CANCELAMENTOS NO MÊS:</strong></td>
            <td style="text-align: right;">(-){{ vlrAssociadosCanceladosMes | currency:'BRL'}}</td>
        </tr>
        <tr style="text-align: left;">
            <td style="padding-right:30px"><strong>CANCELAMENTOS:</strong></td>
            <td style="text-align: right;">(-){{ valorTotalCancelados | currency:'BRL'}}</td>
        </tr>
        <tr style="text-align: left;">
            <td style="padding-right:30px"><strong>DÉBITOS ANTERIORES:</strong></td>
            <td style="text-align: right;">(-){{ debitoAnterior | currency:'BRL'}}</td>
        </tr>
        
        <tr style="text-align: left;">
            <td style="padding-right:30px"><strong>TOTAL:</strong></td>
            <td style="text-align: right;"><strong>{{ comissaoColaborador + totalVendasAlteracao +bonusColaborador - valorTotalCancelados - vlrAssociadosCanceladosMes- debitoAnterior | currency:'BRL'}}</strong></td>
        </tr>
    </table>
    <br><br>
    <button class="btn btn-success no-print" (click)="gravarComissao()" *ngIf="db?.usuario?.permissoes['comissoes'] == 'EDITAR'">GRAVAR COMISSÃO</button>
    <br>
    <div style="height: 3em;"></div>
    <div class="row print-only" style="float: left;">
        <div class="col" style="text-align: center;">
            ____________________________________________________<br><br>
            {{ colaborador?.nome }}
        </div>
    </div>
</div>
