import { Component, OnInit } from '@angular/core';
import { DataBaseService } from '../provider.service';
import * as moment from 'moment';
import * as XLSX from 'xlsx';
import { AppComponent } from '../app.component';

@Component({
  selector: 'app-relatorio-comissoes',
  templateUrl: './relatorio-comissoes.component.html',
  styleUrls: ['./relatorio-comissoes.component.css']
})

export class RelatorioComissoesComponent implements OnInit {
  mesComissao:any = "";
  gerente:any = "";
  gerentes:any = [];
  colaborador:any = "";
  colaboradores:any = [];
  comissaoGerada:any = [];
  comissaoGerente:boolean = false;
  nomeComissao:any = "";
  agenciamentoGerente:any = [];

  constructor(private db: DataBaseService, public app:AppComponent) { }

  ngOnInit(): void {
    this.db.getUsuariosComercialComissao('"nome":1,"comissaoSobreVendasCheck":1,"gerente":1,"comissaoSobreVendas":1').subscribe((vends:any) => {
      this.colaboradores = vends;
      this.gerentes = vends.filter((v:any) => {return v.comissaoSobreVendasCheck});
    })
  }

  mes(data:any){
    let m = Number(data.split('-')[1]) - 1;
    let arr = ["Janeiro","Fevereiro","Março","Abril","Maio","Junho","Julho","Agosto","Setembro","Outubro","Novembro","Dezembro"];
    return arr[m];
  }

  getRelatorioComissao(){
    this.nomeComissao = this.colaborador.nome;
    this.comissaoGerente = false;
    this.db.getComissoesByDataVendedor(this.mesComissao,this.colaborador.nome).subscribe((com:any) => {
      if(com){
        this.comissaoGerada = com[0];
      }
    })
  }


  getComissaoGerente(){
    let totalAgenciamento = 0;
    let totalRolagem = 0;
    this.agenciamentoGerente = [];
    this.nomeComissao = this.gerente.nome;
    let vendsGerente = this.colaboradores.filter((v:any) => {return v.gerente == this.gerente.nome});
    if(vendsGerente.length > 0){
      this.comissaoGerente = true;
      vendsGerente.forEach((vendedor:any) => {
        this.db.getComissoesByDataVendedor(this.mesComissao,vendedor.nome).subscribe((com:any) => {
          if(com[0].agenciamentoMes.length > 0){
              com[0].agenciamentoMes.forEach((a:any) => {
                  a.vendedor = vendedor.nome;
                  totalAgenciamento += a.comissao;
                  this.agenciamentoGerente.push(a);
              })
          }
        })
      })
    }else{
      this.comissaoGerente = false;
    }
  }

/* 
  getComissaoGerente(){
    let totalAgenciamento = 0;
    let totalRolagem = 0;
    this.agenciamentoGerente = [];
    this.nomeComissao = this.gerente.nome;
    let vendsGerente = this.colaboradores.filter((v:any) => {return v.gerente == this.gerente.nome});
    if(vendsGerente.length > 0){
      this.comissaoGerente = true;
      vendsGerente.forEach((vendedor:any) => {
        this.db.getComissoesByDataVendedor(this.mesComissao,vendedor.nome).subscribe((com:any) => {
          if(com[0].agenciamentoMes.length > 0){
            let ctrl = 1;
              com[0].agenciamentoMes.forEach((a:any) => {
                if(a.pago){
                  a.vendedor = vendedor.nome;
                  totalAgenciamento += a.comissao;
                  this.agenciamentoGerente.push(a);
                }
                if(com[0].agenciamentoMes.length == ctrl){
                  if(com[0].arrRolagem.length > 0){
                    com[0].arrRolagem.forEach((r:any) => {
                      if(r.pago){
                        r.vendedor = vendedor.nome;
                        totalRolagem += r.comissao;
                        this.agenciamentoGerente.push(r);
                      }
                    })
                  }
                }else{
                  ctrl++;
                }
              })
          }else{
            if(com[0].arrRolagem.length > 0){
              com[0].arrRolagem.forEach((r:any) => {
                if(r.pago){
                  r.vendedor = vendedor.nome;
                  totalRolagem += r.comissao;
                  this.agenciamentoGerente.push(r);
                }
              })
            }
          }
        })
      })
    }else{
      this.comissaoGerente = false;
    }
  } */

  print(){
    let div = document.getElementById('divToPrintRelCom').innerHTML;
    let newWindow = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    newWindow.document.open();
    newWindow.document.write(`
    <html>
        <head>
          <title>Viva Max - Proposta</title>
          <link rel="stylesheet" type="text/css" href="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css">
          <script type="text/javascript" src="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/js/bootstrap.min.js"></script>
        </head>
        <body onload="window.print()">
        ${div}     
        </body>
      </html>
    `);
    newWindow.document.close();
  }

  printXsl(){
    var ws = XLSX.utils.table_to_sheet(document.getElementById('divToPrintRelCom'),{raw:true});
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'vivamax.xlsx',{cellStyles:true});
  }
}