<div class="container-fluid">
<div id="myModal" class="modalLightbox">
    <span (click)="closeModal()" class="close">&times;</span>
    <div class="prev" (click)="prevImg()">&lt;</div>
    <img [src]="imagemSelecionada" class="imagemLightbox">
    <div class="next" (click)="nextImg()">&gt;</div>
</div> 
<div style="width: 800px; height: 400px;display: none;">
    <ng2-pdfjs-viewer 
      #pdfViewerContasPagar
      [externalWindow]="true"
      [downloadFileName]="'vivamax.pdf'"
      [openFile]="true"
      [viewBookmark]="false"
      [download]="true"></ng2-pdfjs-viewer>
  </div>
<!-- MODAL VER CONTA PAGAR -->
<div class="modal" id="modalVerContaPagar">
    <div class="voltartopo">
        <button class="btn btn-outline-light" (click)="closeModalVerConta()"><fa-icon [icon]="['fas', 'arrow-left']"></fa-icon> Voltar</button>
    </div>
    <div style="margin-top:3em;padding:50px">
    <div *ngIf="tipoDeContaEditar == 'CONTA A PAGAR'">
        <div class="row">
            <div class="col col-6">
                <label>Fornecedor</label>
                <select [(ngModel)]="fornecedorEditar" class="form-control">
                    <option selected disabled></option>
                    <option *ngFor="let fornecedor of fornecedores" [value]="fornecedor.razaoSocial">{{fornecedor.razaoSocial}}</option>
                </select>
            </div>
            <div class="col col-6">
                <label>Observação</label>
                <input type="text" class="form-control" [(ngModel)]="observacaoContaEditar" oninput="this.value = this.value.toUpperCase()">
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col col-3">
                <label>Tipo Vencimento</label>
                <select class="form-control" [(ngModel)]="tipoVencimentoContaEditar" readonly>
                    <option value="À Vista">À Vista</option>
                    <option value="Parcelado">Parcelado</option>
                    <option value="Semanal">Semanal</option>
                    <option value="Mensal">Mensal</option>
                    <option value="Anual">Anual</option>
                </select>
            </div>
            <div class="col col-3">
                <label>Valor</label>
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon1">R$</span>
                    </div>
                    <input type="number" class="form-control" [(ngModel)]="valorContaEditar" (change)="calcularTotalEditar()" min="0">
                </div>    
            </div>
            <div class="col col-4">
                <label>Vencimento</label>
                <input type="date" class="form-control" [(ngModel)]="vencimentoContaEditar">
            </div>
            <div class="col col-2">
                <label>Parcela</label>
                <input type="text" class="form-control" [(ngModel)]="parcelaConta" readonly>
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col col-8">
                <label>Código de Barras</label>
                <input type="text" class="form-control" [(ngModel)]="codigoBarrasEditar">
            </div>
            <div class="col col-4" style="text-align: center;">
                <div class="form-check">
                    <label class="form-check-label" for="aguardandoEditar" style="padding-bottom:1em">
                        Aguardando Aprovação
                       </label><br>
                    <input class="form-check-input" type="checkbox" id="aguardandoEditar" [(ngModel)]="aguardandoAprovacaoEditar">
                </div>
             </div>
        </div>
        <br>
        <div class="row">
            <div class="col col-4">
                <label>Forma de Pagamento</label>
                <select [(ngModel)]="formaPagamentoEditar" class="form-control" [ngStyle]="{'border-color': !formaPagamentoEditar ? '#dd443e' : '#3cb42c'}">
                    <option value="" selected></option>
                    <option *ngFor="let forma of todasFormasPagamento" [value]="forma.forma">{{forma.forma}}</option>
                </select>
            </div>
             <div class="col col-4">
                <label>Tipo de Lançamento</label>
                <select [(ngModel)]="tipoLancamentoEditar" class="form-control">
                    <option selected></option>
                    <option value="PAGO NO DIA">PAGO NO DIA</option>
                    <option value="PAGO NO VENCIMENTO">PAGO NO VENCIMENTO</option>
                    <option value="PAGO EM ATRASO">PAGO EM ATRASO</option>
                    <option value="LANÇADO PARA O VENCIMENTO">LANÇADO PARA O VENCIMENTO</option>
                </select>
            </div>
            <div class="col col-4">
                <label>Banco:</label>
                <select [(ngModel)]="bancoEditar" class="form-control">
                    <option value=""></option>
                    <option *ngFor="let b of bancos">{{b.nome}}</option>
                </select>
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col col-3">
                <label>Juros</label>
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon1">R$</span>
                    </div>
                    <input type="number" class="form-control" [(ngModel)]="jurosContaEditar" (change)="calcularTotalEditar()" min="0">
                </div>  
            </div>
            <div class="col col-3">
                <label>Taxas</label>
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon1">R$</span>
                    </div>
                    <input type="number" class="form-control" [(ngModel)]="taxasContaEditar" (change)="calcularTotalEditar()" min="0">
                </div>  
            </div>
            <div class="col col-3">
                <label>Desconto</label>
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon1">R$</span>
                    </div>
                    <input type="number" class="form-control" [(ngModel)]="descontoContaEditar" (change)="calcularTotalEditar()" min="0">
                </div>  
            </div>
            <div class="col col-3">
                <label>Total a Pagar</label>
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon1">R$</span>
                    </div>
                    <input type="number" class="form-control" [(ngModel)]="valorTotalEditar" readonly>
                </div>  
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col">
                <label>Selecionar Centro de Custo:</label>
                <form [formGroup]="ccEditar">
                    <div class="form-group col-3" style="text-align: left;" [hidden]="ccListEditar?.length > 0">
                        <br>
                      <button class="btn btn-outline-success btn-sm" (click)="addCCEditar()">
                        <fa-icon [icon]="['fas', 'plus-circle']" size="1x"></fa-icon> ADICIONAR CENTRO DE CUSTO
                      </button>
                    </div>
                    <div class="card-body" formArrayName="centroCusto">
                        <div [formGroupName]="i" class="row" *ngFor="let centros of ccFormGroupEditar.controls; let i = index;">
                            <div class="form-group col-5">
                                <label>Centro de Custo</label><br>
                                <select [ngClass]="{'desabilita': ccEditar.controls.centroCusto.value[i].contaOrcamentaria}" formControlName="centroDeCusto" class="form-control" [ngStyle]="{'border-color': !ccListEditar.valid ? '#dd443e' : '#3cb42c'}">
                                    <option value="Administrativo">Administrativo</option>
                                    <option value="Comercial">Comercial</option>
                                    <option value="Publicidade">Publicidade</option>
                                </select>
                            </div>
                            <div class="form-group col-7">
                                <label>Conta Orçamentária</label><br>
                                <select [ngClass]="{'desabilita': ccEditar.controls.centroCusto.value[i].contaContabil}" formControlName="contaOrcamentaria" class="form-control" [ngStyle]="{'border-color': !ccListEditar.valid ? '#dd443e' : '#3cb42c'}">
                                    <option selected [value]=""></option>
                                    <option *ngFor="let cC of (listaDeCC | filterCC: ccEditar.controls.centroCusto.value[i].centroDeCusto)" [value]="cC.contaOrcamentaria">{{cC.contaOrcamentaria}}</option>
                                </select>
                            </div>
                            <div class="form-group col-6">
                                <label>Conta Contabil</label><br>
                                <select formControlName="contaContabil" class="form-control" [ngStyle]="{'border-color': !ccListEditar.valid ? '#dd443e' : '#3cb42c'}" *ngFor="let cO of (listaDeCC | filterCO: ccEditar.controls.centroCusto.value[i].contaOrcamentaria)">
                                    <option selected [value]=""></option>
                                    <option *ngFor="let c of cO.contasContabeis" [value]="c.conta">{{c.conta}}</option>
                                </select>
                            </div>
                            <div class="form-group col-4">
                                <label>Valor do Centro de Custo</label><br>
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="basic-addon1">R$</span>
                                    </div>
                                    <input type="number" min="0" class="form-control" placeholder="Valor" formControlName="valor" [ngStyle]="{'border-color': !ccListEditar.valid ? '#dd443e' : '#3cb42c'}">
                                </div> 
                            </div>
                            <div class="form-group col-2 text-right">
                                <br>
                                <button class="btn btn-danger" (click)="removeCCEditar(i)">
                                    <fa-icon [icon]="['fas', 'minus-circle']" size="1x"></fa-icon>
                                </button>&nbsp;
                                <button class="btn btn-success" (click)="addCCEditar()" [disabled]="!ccListEditar.valid">
                                    <fa-icon [icon]="['fas', 'plus-circle']" size="1x"></fa-icon>
                                </button>
                            </div>
                        </div>   
                    </div> 
                </form>
            </div>
        </div>
        <br>
        <div class="row" *ngIf="dadosBancario" style="background-color: rgb(235, 235, 235); padding: 10px; text-align: center;">
            <div class="col">
                <label>Banco</label><br>
                {{dadosBancario?.banco}}
            </div>
            <div class="col">
                <label>Agência</label><br>
                {{dadosBancario?.agencia}} - {{dadosBancario?.agenciaDigito}}
            </div>
            <div class="col">
                <label>Conta Corrente</label><br>
                {{dadosBancario?.contaCorrente}} - {{dadosBancario?.contaCorrenteDigito}}
            </div>
            <div class="col">
                <label>Tipo Chave Pix</label><br>
                {{dadosBancario?.tipoPix}}
            </div>
            <div class="col">
                <label>Chave Pix</label><br>
                {{dadosBancario?.pix}}
            </div>
        </div>
        <br>
        <div class="row" style="text-align: center;">
            <div class="col">
                <label>Cadastrado Por</label><br>
               {{contaSelecionada.usuario}} | {{contaSelecionada.data | date:'dd/MM/yyyy'}} - {{contaSelecionada.hora}}
            </div>
        </div>
    </div>
    <!-- FIM CONTAS A PAGAR -->
     <!-- INICIO CAMPOS REEMBOLSO -->
     <div *ngIf="tipoDeContaEditar == 'REEMBOLSO'">
        <div class="row">
            <div class="col col-3">
                <label>Data da Solicitação</label><br>
                <input type="date" class="form-control" [(ngModel)]="dataSolicitacaoEditar">
            </div>
            <div class="col col-3">
                <label>Data da Vencimento</label><br>
                <input type="date" class="form-control" [(ngModel)]="dataVencimentoEditar">
            </div>
            <div class="col col-6">
                <label>Colaborador</label><br>
                <select [(ngModel)]="colaboradorReembolsoEditar" class="form-control">
                    <option selected disabled></option>
                    <option *ngFor="let colaborador of colaboradores" [value]="colaborador.nome">{{colaborador.nome}}</option>
                </select>
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col">
                <label>Motivo</label><br>
                <input type="text" class="form-control" [(ngModel)]="motivoReembolsoEditar" oninput="this.value = this.value.toUpperCase()">
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col col-4">
                <label>Situação</label><br>
                <select [(ngModel)]="situacaoReembolsoEditar" class="form-control">
                    <option value=""></option>
                    <option value="DEFERIDO">DEFERIDO</option>
                    <option value="INDEFERIDO">INDEFERIDO</option>
                </select>
            </div>
            <div class="col col-8" *ngIf="situacaoReembolsoEditar == 'INDEFERIDO'">
                <label>Motivo Indeferimento</label>
                <input type="text" class="form-control" [(ngModel)]="observacaoReembolso">
            </div>
            <div class="col col-4" *ngIf="situacaoReembolsoEditar == 'DEFERIDO'">
                <label>Forma de Reembolso</label>
                <select [(ngModel)]="formaPagamentoEditar" class="form-control" [ngStyle]="{'border-color': !formaPagamentoEditar ? '#dd443e' : '#3cb42c'}">
                    <option value="" selected></option>
                    <option *ngFor="let forma of todasFormasPagamento" [value]="forma.forma">{{forma.forma}}</option>
                </select>
            </div>
            <div class="col col-4" *ngIf="situacaoReembolsoEditar == 'DEFERIDO'">
                <label>Data do Reembolso</label><br>
                <input type="date" class="form-control" [(ngModel)]="dataReembolso">
            </div>
        </div>
        <br>
        <div class="row" *ngIf="situacaoReembolsoEditar == 'DEFERIDO'">
            <div class="col col-12">
                <label>Observação</label>
                <input type="text" class="form-control" [(ngModel)]="observacaoReembolso" oninput="this.value = this.value.toUpperCase()">
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col">
                <form [formGroup]="reembolsosEditar">
                    <div class="form-group col-3" style="text-align: left;" [hidden]="reembolsoListEditar?.length > 0">
                        <br>
                      <button class="btn btn-outline-success btn-sm" (click)="addReembolsoEditar('')">
                        <fa-icon [icon]="['fas', 'plus-circle']" size="1x"></fa-icon> ADICIONAR REEMBOLSO
                      </button>
                    </div>
                    <div class="card-body" formArrayName="reembolso">
                        <div [formGroupName]="i" class="row" *ngFor="let contact of reembolsoEditarFormGroup.controls; let i = index;">
                            <div class="form-group col-6">
                                <label>Tipo de Reembolso</label><br>
                                <select  formControlName="tipo" class="form-control" [ngStyle]="{'border-color': !reembolsoListEditar.valid ? '#dd443e' : '#3cb42c'}">
                                    <option selected value=""></option>
                                    <option value="ALIMENTAÇÃO">ALIMENTAÇÃO</option>
                                    <option value="BORRACHARIA">BORRACHARIA</option>
                                    <option value="BRINDE">BRINDE</option>
                                    <option value="CHIP CELULAR">CHIP CELULAR</option>
                                    <option value="COMBUSTÍVEL">COMBUSTÍVEL</option>
                                    <option value="ESTACIONAMENTO">ESTACIONAMENTO</option>
                                    <option value="HOTEL">HOTEL</option>
                                    <option value="LAVAGEM">LAVAGEM</option>
                                    <option value="MULTA ESTACIONAMENTO">MULTA ESTACIONAMENTO</option>
                                    <option value="OUTROS">OUTROS</option>
                                    <option value="PAPELARIA">PAPELARIA</option>
                                    <option value="PEDÁGIO">PEDÁGIO</option>
                                </select>
                            </div>
                            <div class="form-group col-3">
                                <label>Valor do Reembolso</label><br>
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="basic-addon1">R$</span>
                                    </div>
                                    <input type="number" min="0" class="form-control" placeholder="Valor" formControlName="valor" [ngStyle]="{'border-color': !reembolsoListEditar.valid ? '#dd443e' : '#3cb42c'}">
                                </div> 
                            </div>
                            <div class="form-group col-2 text-right">
                                <br>
                                <button class="btn btn-danger" (click)="removeReembolsoEditar(i)">
                                    <fa-icon [icon]="['fas', 'minus-circle']" size="1x"></fa-icon>
                                </button>&nbsp;
                                <button class="btn btn-success" (click)="addReembolsoEditar()">
                                    <fa-icon [icon]="['fas', 'plus-circle']" size="1x"></fa-icon>
                                </button>
                            </div>
                        </div>   
                    </div> 
                </form>
            </div>
        </div>
        <br>
        <div class="row" style="text-align: center;">
            <div class="col">
                <label>Cadastrado Por</label><br>
               {{contaSelecionada.usuario}} | {{contaSelecionada.data | date:'dd/MM/yyyy'}} - {{contaSelecionada.hora}}
            </div>
        </div>
    </div>
    <!-- FIM CAMPOS REEMBOLSO -->
     <!-- VENCIMENTOS -->
     <div *ngIf="tipoDeContaEditar == 'VENCIMENTOS'">
        <div class="row">
            <div class="col col-5">
                <label>Colaborador</label><br>
                <select [(ngModel)]="colaboradorVencimentoEditar" class="form-control">
                    <option selected disabled></option>
                    <option *ngFor="let colaborador of colaboradores" [value]="colaborador.nome">{{colaborador.nome}}</option>
                </select>
            </div>
            <div class="col col-4">
                <label>Tipo Vencimento</label>
                <select class="form-control" [(ngModel)]="tipoVencimentoCadastroEditar">
                    <option value="SALÁRIO">SALÁRIO</option>
                    <option value="VALE REFEIÇÃO">VALE REFEIÇÃO</option>
                    <option value="13º SALÁRIO">13º SALÁRIO</option>
                    <option value="ADIANTAMENTO">ADIANTAMENTO</option>
                    <option value="AJUDA DE CUSTO">AJUDA DE CUSTO</option>
                    <option value="BÔNUS DE PRODUTIVIDADE">BÔNUS DE PRODUTIVIDADE</option>
                    <option value="FÉRIAS">FÉRIAS</option>
                    <option value="PARTICIPAÇÃO DOS LUCROS">PARTICIPAÇÃO DOS LUCROS</option>
                    <option value="PRÓ-LABORE">PRÓ-LABORE</option>
                    <option value="RECISÃO">RECISÃO</option>
                    <option value="VALE TRANSPORTE">VALE TRANSPORTE</option>
                </select>
            </div>
            <div class="col col-3">
                <label>Vencimento</label>
                <input type="date" class="form-control" [(ngModel)]="vencimentoContaEditar">
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col col-3">
                <label>Valor</label>
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon1">R$</span>
                    </div>
                    <input type="number" class="form-control" [(ngModel)]="valorContaEditar" min="0">
                </div>    
            </div>
            <div class="col col-3">
                <label>Forma de Pagamento</label>
                <select [(ngModel)]="formaPagamentoEditar" class="form-control" [ngStyle]="{'border-color': !formaPagamentoEditar ? '#dd443e' : '#3cb42c'}">
                    <option value="" selected></option>
                    <option *ngFor="let forma of todasFormasPagamento" [value]="forma.forma">{{forma.forma}}</option>
                </select>
            </div>
            <div class="col col-6">
                <label>Observação</label>
                <input type="text" class="form-control" [(ngModel)]="observacaoEditar" oninput="this.value = this.value.toUpperCase()">
            </div>
        </div>
        <br>
        <div class="row" style="text-align: center;">
            <div class="col">
                <label>Cadastrado Por</label><br>
               {{contaSelecionada.usuario}} | {{contaSelecionada.data | date:'dd/MM/yyyy'}} - {{contaSelecionada.hora}}
            </div>
        </div>
    </div>
    <!-- FIM VENCIMENTOS -->
        <br>
        <div class="row justify-content-md-center">
            <div class="col col-3" *ngFor="let pdf of pdfsConta;" style="margin: 10px; background-color:#ce2626; color:white; height: 250px; width:100%; text-align: center; border-radius: 10px;">
                <div class="delButton" name="delButton" *ngIf="db?.usuario?.permissoes['financeiro'] == 'EDITAR'">
                    <button class="btn btn-dark btn-sm" (click)="delDocConta(pdf.url)"><strong>X</strong></button>
                </div>
                <div (click)="openModalPdfViewer(pdf.url)" style="position: relative;left:50%;top:50%;transform: translate(-50%,-50%);cursor:pointer">
                    <fa-icon [icon]="['fas', 'file-pdf']" style="font-size: 5em;"></fa-icon>
                        <br>DOCUMENTO PDF
                </div>
            </div>
            <div class="col col-3 img-container" *ngFor="let img of imagesConta; let i = index" style="margin: 10px; text-align: center;">
                <div class="delButton" name="delButton" *ngIf="db?.usuario?.permissoes['financeiro'] == 'EDITAR'">
                    <button class="btn btn-danger btn-sm" (click)="delDocConta(img.url)"><strong>X</strong></button>
                </div>
                <img [src]="img.url" (click)="openModalLightBox(img.url, i)" style="border-radius: 10px; height: 250px; width:100%;" crossorigin="anonymous">
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col">
                <div class="uploadfilecontainer" (click)="fileDocumentosEditar.click()" appDragDrop (onFileDropped)="onChangeContaPagarEditar($event)">
                    <input hidden type="file" #fileDocumentosEditar (change)="onChangeClickContaPagarEditar($event)" multiple>
                </div>
                <div class="row align-items-center">
                    <div class="col col-1">
                        &nbsp;
                    </div>
                    <div class="col">
                        <div class="files-list" *ngFor="let file of filesContaPagarEditar; let i= index">
                            <p>{{ file }}</p>
                        </div> 
                    </div>
                    <div class="col col-2" [hidden]="filesContaPagarEditar?.length < 1">
                        <button class="btn btn-primary btn-block" (click)="anexarDoc()">ANEXAR</button>
                    </div>
                    <div class="col col-1">
                        &nbsp;
                    </div>
                </div>
            </div>
        </div>
       <br>
       <div class="row">
           <div class="col">
               &nbsp;
           </div>
           <div class="col col-2">
                <button class="btn btn-danger btn-block" (click)="closeModalVerConta()">FECHAR</button>
           </div>
           <div class="col col-3" *ngIf="tipoDeContaEditar != 'REEMBOLSO'">
                <button class="btn btn-primary btn-block" (click)="editarConta()" [disabled]="filesContaPagarEditar?.length > 0 || ccListEditar.value.length < 1 || !ccListEditar.valid">EDITAR CONTA</button>
           </div>
           <div class="col col-3" *ngIf="tipoDeContaEditar == 'REEMBOLSO'">
                <button class="btn btn-primary btn-block" (click)="salvarReembolso()" [disabled]="!reembolsoListEditar.valid || ccListEditar.value.length < 1 || !ccListEditar.valid">EDITAR REEMBOLSO</button>
            </div>
           <div class="col col-3" *ngIf="tipoDeContaEditar != 'REEMBOLSO'">
             <button class="btn btn-success btn-block" (click)="pagarConta()" [disabled]="!formaPagamentoEditar || formaPagamentoEditar == '' || filesContaPagarEditar?.length > 0 || ccListEditar.value.length < 1 || !ccListEditar.valid">PAGAR CONTA</button>
           </div>
           <div class="col col-3" *ngIf="tipoDeContaEditar == 'REEMBOLSO'">
            <button class="btn btn-success btn-block"  (click)="pagarReembolso()" [disabled]="!formaPagamentoEditar || formaPagamentoEditar == '' || !situacaoReembolsoEditar || !dataReembolso || !observacaoContaEditar || ccListEditar.value.length < 1 || !ccListEditar.valid">PAGAR REEMBOLSO</button>
          </div>
          <div class="col">
            &nbsp;
          </div>
       </div>
    </div>
</div>
<!-- MODAL CONTA A PAGAR -->
<div class="modal" id="modalContaPagar">
    <div class="voltartopo">
        <button class="btn btn-outline-light" (click)="closeModalNovaConta()"><fa-icon [icon]="['fas', 'arrow-left']"></fa-icon> Voltar</button>
    </div>
    <div style="margin-top:3em;padding:50px">
        <div class="row">
            <div class="col col-4">
                <label>SELECIONE O TIPO DE CONTA:</label>
            </div>
            <div class="col col-8">
                <select [(ngModel)]="tipoDeConta" class="form-control">
                    <option selected value=""></option>
                    <option value="CONTA A PAGAR">CONTA A PAGAR</option>
                </select>
            </div>
           <!--  <div class="col col-8">
                <select [(ngModel)]="tipoDeConta" class="form-control">
                    <option selected value=""></option>
                    <option value="CONTA A PAGAR">CONTA A PAGAR</option>
                    <option value="REEMBOLSO">REEMBOLSO</option>
                    <option value="VENCIMENTOS">VENCIMENTOS</option>
                </select>
            </div> -->
        </div>
        <br>
       <!-- INICIO CAMPOS CONTAS A PAGAR -->
       <div *ngIf="tipoDeConta == 'CONTA A PAGAR'">
        <div class="row">
            <div class="col col-8">
                <label>Fornecedor</label>
                <select [(ngModel)]="fornecedorConta" class="form-control" (change)="pegarBanco(fornecedorConta)">
                    <option selected disabled></option>
                    <option *ngFor="let fornecedor of fornecedores" [value]="fornecedor.razaoSocial">{{fornecedor.razaoSocial}}</option>
                </select>
            </div>
            <div class="col col-4">
                <label>Forma de Pagamento</label>
                <select [(ngModel)]="formaPagamento" class="form-control">
                    <option value="" selected></option>
                    <option *ngFor="let forma of todasFormasPagamento" [value]="forma.forma">{{forma.forma}}</option>
                </select>
            </div>
        </div>
        <div class="row">
            <div class="col col-12">
                <label>Observação</label>
                <input type="text" class="form-control" [(ngModel)]="observacaoConta" oninput="this.value = this.value.toUpperCase()">
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col col-8">
                <label>Código de Barras</label>
                <input type="text" class="form-control" [(ngModel)]="codigoBarras">
            </div>
            <div class="col col-4" style="text-align: center;">
                <div class="form-check">
                    <label class="form-check-label" for="aguardando" style="padding-bottom:1em">
                        Aguardando Aprovação
                    </label><br>
                    <input class="form-check-input" type="checkbox" id="aguardando" [(ngModel)]="aguardandoAprovacao">
                </div>
             </div>
        </div>
        <div class="row">
            <div class="col col-4">
                <label>Tipo Vencimento</label>
                <select class="form-control" [(ngModel)]="tipoVencimentoConta">
                    <option value="À Vista">À Vista</option>
                    <option value="Parcelado">Parcelado</option>
                    <option value="Semanal">Semanal</option>
                    <option value="Mensal">Mensal</option>
                    <option value="Anual">Anual</option>
                </select>
            </div> 
            <div class="col col-4">
                <label>Tipo de Lançamento</label>
                <select [(ngModel)]="tipoLancamento" class="form-control">
                    <option selected></option>
                    <option value="PAGO NO DIA">PAGO NO DIA</option>
                    <option value="PAGO NO VENCIMENTO">PAGO NO VENCIMENTO</option>
                    <option value="PAGO EM ATRASO">PAGO EM ATRASO</option>
                    <option value="LANÇADO PARA O VENCIMENTO">LANÇADO PARA O VENCIMENTO</option>
                </select>
            </div>
            <div class="col col-4">
                <label>Banco:</label>
                <select [(ngModel)]="banco" class="form-control">
                    <option value=""></option>
                    <option *ngFor="let b of bancos">{{b.nome}}</option>
                </select>
            </div>
        </div>
        <br>
        <div class="row" *ngIf="tipoVencimentoConta == 'Semanal' || tipoVencimentoConta == 'Mensal' || tipoVencimentoConta == 'Anual'">
            <div class="col col-3">
                <label>Repetir</label>
                <div class="input-group mb-3">
                    <input type="number" min="0" class="form-control" [(ngModel)]="repeticoes">
                    <div class="input-group-prepend">
                        <span class="input-group-text" id="basic-addon1" *ngIf="tipoVencimentoConta == 'Semanal'">Semanas</span>
                        <span class="input-group-text" id="basic-addon1" *ngIf="tipoVencimentoConta == 'Mensal'">Meses</span>
                        <span class="input-group-text" id="basic-addon1" *ngIf="tipoVencimentoConta == 'Anual'">Anos</span>
                      </div>
                </div>  
            </div>
            <div class="col col-3">
                <label>Valor</label>
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon1">R$</span>
                    </div>
                    <input type="number" min="0" class="form-control" [(ngModel)]="valorConta">
                </div>    
            </div>
            <div class="col col-3">
                <label>Vencimento Inicial</label>
                <input type="date" class="form-control" [(ngModel)]="vencimentoConta">
            </div>
        </div>
        <br>
        <br>
        <div class="row" *ngIf="tipoVencimentoConta == 'À Vista'">
            <div class="col col-3">
                <label>Valor</label>
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon1">R$</span>
                    </div>
                    <input type="number" min="0" class="form-control" [(ngModel)]="valorConta">
                </div>    
            </div>
            <div class="col col-3">
                <label>Vencimento</label>
                <input type="date" class="form-control" [(ngModel)]="vencimentoConta">
            </div>
        </div>
        <br>
        <form [formGroup]="parcelados" *ngIf="tipoVencimentoConta == 'Parcelado'">
            <div class="form-group col-3" style="text-align: left;" [hidden]="parceladosList?.length > 0">
                <br>
              <button class="btn btn-outline-success btn-sm" (click)="addParcela('')">
                <fa-icon [icon]="['fas', 'plus-circle']" size="1x"></fa-icon> ADICIONAR PARCELA
              </button>
            </div>
            <div class="card-body" formArrayName="parcela">
                <div [formGroupName]="i" class="row" *ngFor="let contact of parcelasFormGroup.controls; let i = index;">
                    <div class="form-group col-4">
                        <label>Vencimento da Parcela {{i+1}}</label><br>
                        <input type="date" class="form-control" placeholder="Vencimento" formControlName="vencimento">
                    </div>
                    <div class="form-group col-3">
                        <label>Valor da Parcela {{i+1}}</label><br>
                        <div class="input-group mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text" id="basic-addon1">R$</span>
                            </div>
                            <input type="number" min="0" class="form-control" placeholder="Valor" formControlName="valor">
                        </div> 
                    </div>
                    <div class="form-group col-2 text-right">
                        <br>
                        <button class="btn btn-danger" (click)="removeParcela(i)">
                            <fa-icon [icon]="['fas', 'minus-circle']" size="1x"></fa-icon>
                        </button>&nbsp;
                        <button class="btn btn-success" (click)="addParcelaNova({vencimento:parcelados.controls.parcela.value[i].vencimento,valor:parcelados.controls.parcela.value[i].valor})">
                            <fa-icon [icon]="['fas', 'plus-circle']" size="1x"></fa-icon>
                        </button>
                    </div>
                </div>   
            </div> 
        </form>
        <br>
        <div class="row" *ngIf="tipoVencimentoConta">
            <div class="col">
                <label>Selecionar Centro de Custo:</label>
                <form [formGroup]="cc">
                    <div class="form-group col-3" style="text-align: left;" [hidden]="ccList?.length > 0">
                        <br>
                      <button class="btn btn-outline-success btn-sm" (click)="addCC()">
                        <fa-icon [icon]="['fas', 'plus-circle']" size="1x"></fa-icon> ADICIONAR CENTRO DE CUSTO
                      </button>
                    </div>
                    <div class="card-body" formArrayName="centroCusto">
                        <div [formGroupName]="i" class="row" *ngFor="let centros of ccFormGroup.controls; let i = index;">
                            <div class="form-group col-5">
                                <label>Centro de Custo</label><br>
                                <select [ngClass]="{'desabilita': cc.controls.centroCusto.value[i].contaOrcamentaria}" formControlName="centroDeCusto" class="form-control" [ngStyle]="{'border-color': !ccList.valid ? '#dd443e' : '#3cb42c'}">
                                    <option value="Administrativo">Administrativo</option>
                                    <option value="Comercial">Comercial</option>
                                    <option value="Publicidade">Publicidade</option>
                                </select>
                            </div>
                            <div class="form-group col-7">
                                <label>Conta Orçamentária</label><br>
                                <select [ngClass]="{'desabilita': cc.controls.centroCusto.value[i].contaContabil}" formControlName="contaOrcamentaria" class="form-control" [ngStyle]="{'border-color': !ccList.valid ? '#dd443e' : '#3cb42c'}">
                                    <option selected [value]=""></option>
                                    <option *ngFor="let cC of (listaDeCC | filterCC: cc.controls.centroCusto.value[i].centroDeCusto)" [value]="cC.contaOrcamentaria">{{cC.contaOrcamentaria}}</option>
                                </select>
                            </div>
                            <div class="form-group col-6">
                                <label>Conta Contabil</label><br>
                                <select formControlName="contaContabil" class="form-control" [ngStyle]="{'border-color': !ccList.valid ? '#dd443e' : '#3cb42c'}" *ngFor="let cO of (listaDeCC | filterCO: cc.controls.centroCusto.value[i].contaOrcamentaria)">
                                    <option selected [value]=""></option>
                                    <option *ngFor="let c of cO.contasContabeis" [value]="c.conta">{{c.conta}}</option>
                                </select>
                            </div>
                            <div class="form-group col-4">
                                <label>Valor do Centro de Custo</label><br>
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="basic-addon1">R$</span>
                                    </div>
                                    <input type="number" min="0" class="form-control" placeholder="Valor" formControlName="valor" [ngStyle]="{'border-color': !ccList.valid ? '#dd443e' : '#3cb42c'}">
                                </div> 
                            </div>
                            <div class="form-group col-2 text-right">
                                <br>
                                <button class="btn btn-danger" (click)="removeCC(i)">
                                    <fa-icon [icon]="['fas', 'minus-circle']" size="1x"></fa-icon>
                                </button>&nbsp;
                                <button class="btn btn-success" (click)="addCC()" [disabled]="!ccList.valid">
                                    <fa-icon [icon]="['fas', 'plus-circle']" size="1x"></fa-icon>
                                </button>
                            </div>
                        </div>   
                    </div> 
                </form>
            </div>
        </div>
        <br>
        <div class="row" *ngIf="dadosBancario" style="background-color: rgb(235, 235, 235); padding: 10px; text-align: center;">
            <div class="col">
                <label>Banco</label><br>
                {{dadosBancario?.banco}}
            </div>
            <div class="col">
                <label>Agência</label><br>
                {{dadosBancario?.agencia}} - {{dadosBancario?.agenciaDigito}}
            </div>
            <div class="col">
                <label>Conta Corrente</label><br>
                {{dadosBancario?.contaCorrente}} - {{dadosBancario?.contaCorrenteDigito}}
            </div>
            <div class="col">
                <label>Tipo Chave Pix</label><br>
                {{dadosBancario?.tipoPix}}
            </div>
            <div class="col">
                <label>Chave Pix</label><br>
                {{dadosBancario?.pix}}
            </div>
        </div>
        <br>
    </div>
    <!-- FIM CAMPOS CONTAS A PAGAR -->
     <!-- VENCIMENTOS -->
     <div *ngIf="tipoDeConta == 'VENCIMENTOS'">
        <div class="row">
            <div class="col col-5">
                <label>Colaborador</label><br>
                <select [(ngModel)]="colaboradorVencimento" class="form-control">
                    <option selected disabled></option>
                    <option *ngFor="let colaborador of colaboradores" [value]="colaborador.nome">{{colaborador.nome}}</option>
                </select>
            </div>
            <div class="col col-4">
                <label>Tipo Vencimento</label>
                <select class="form-control" [(ngModel)]="tipoVencimentoCadastro">
                    <option value="SALÁRIO">SALÁRIO</option>
                    <option value="VALE REFEIÇÃO">VALE REFEIÇÃO</option>
                    <option value="13º SALÁRIO">13º SALÁRIO</option>
                    <option value="ADIANTAMENTO">ADIANTAMENTO</option>
                    <option value="AJUDA DE CUSTO">AJUDA DE CUSTO</option>
                    <option value="BÔNUS DE PRODUTIVIDADE">BÔNUS DE PRODUTIVIDADE</option>
                    <option value="FÉRIAS">FÉRIAS</option>
                    <option value="PARTICIPAÇÃO DOS LUCROS">PARTICIPAÇÃO DOS LUCROS</option>
                    <option value="PRÓ-LABORE">PRÓ-LABORE</option>
                    <option value="RECISÃO">RECISÃO</option>
                    <option value="VALE TRANSPORTE">VALE TRANSPORTE</option>
                </select>
            </div>
            <div class="col col-3">
                <label>Vencimento</label>
                <input type="date" class="form-control" [(ngModel)]="vencimentoConta">
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col col-3">
                <label>Valor</label>
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon1">R$</span>
                    </div>
                    <input type="number" class="form-control" [(ngModel)]="valorConta" min="0">
                </div>    
            </div>
            <div class="col col-3">
                <label>Banco:</label>
                <select [(ngModel)]="banco" class="form-control">
                    <option value=""></option>
                    <option *ngFor="let b of bancos">{{b.nome}}</option>
                </select>
            </div>
            <div class="col col-6">
                <label>Observação</label>
                <input type="text" class="form-control" [(ngModel)]="observacaoConta" oninput="this.value = this.value.toUpperCase()">
            </div>
        </div>
    </div>
    <!-- FIM VENCIMENTOS -->
     <!-- INICIO CAMPOS REEMBOLSO -->
     <div *ngIf="tipoDeConta == 'REEMBOLSO'">
        <div class="row">
            <div class="col col-3">
                <label>Data da Solicitação</label><br>
                <input type="date" class="form-control" [(ngModel)]="dataSolicitacao">
            </div>
            <div class="col col-3">
                <label>Data da Vencimento</label><br>
                <input type="date" class="form-control" [(ngModel)]="dataVencimento">
            </div>
            <div class="col col-6">
                <label>Colaborador</label><br>
                <select [(ngModel)]="colaboradorReembolso" class="form-control">
                    <option selected disabled></option>
                    <option *ngFor="let colaborador of colaboradores" [value]="colaborador.nome">{{colaborador.nome}}</option>
                </select>
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col">
                <label>Motivo</label><br>
                <input type="text" class="form-control" [(ngModel)]="motivoReembolso" oninput="this.value = this.value.toUpperCase()">
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col">
                <form [formGroup]="reembolsos">
                    <div class="form-group col-3" style="text-align: left;" [hidden]="reembolsoList?.length > 0">
                        <br>
                      <button class="btn btn-outline-success btn-sm" (click)="addReembolso('')">
                        <fa-icon [icon]="['fas', 'plus-circle']" size="1x"></fa-icon> ADICIONAR REEMBOLSO
                      </button>
                    </div>
                    <div class="card-body" formArrayName="reembolso">
                        <div [formGroupName]="i" class="row" *ngFor="let contact of reembolsoFormGroup.controls; let i = index;">
                            <div class="form-group col-6">
                                <label>Tipo de Reembolso</label><br>
                                <select  formControlName="tipo" class="form-control" [ngStyle]="{'border-color': !reembolsoList.valid ? '#dd443e' : '#3cb42c'}">
                                    <option selected value=""></option>
                                    <option value="ALIMENTAÇÃO">ALIMENTAÇÃO</option>
                                    <option value="BORRACHARIA">BORRACHARIA</option>
                                    <option value="BRINDE">BRINDE</option>
                                    <option value="CHIP CELULAR">CHIP CELULAR</option>
                                    <option value="COMBUSTÍVEL">COMBUSTÍVEL</option>
                                    <option value="ESTACIONAMENTO">ESTACIONAMENTO</option>
                                    <option value="HOTEL">HOTEL</option>
                                    <option value="LAVAGEM">LAVAGEM</option>
                                    <option value="MULTA ESTACIONAMENTO">MULTA ESTACIONAMENTO</option>
                                    <option value="OUTROS">OUTROS</option>
                                    <option value="PAPELARIA">PAPELARIA</option>
                                    <option value="PEDÁGIO">PEDÁGIO</option>
                                </select>
                            </div>
                            <div class="form-group col-3">
                                <label>Valor do Reembolso</label><br>
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="basic-addon1">R$</span>
                                    </div>
                                    <input type="number" min="0" class="form-control" placeholder="Valor" formControlName="valor" [ngStyle]="{'border-color': !reembolsoList.valid ? '#dd443e' : '#3cb42c'}">
                                </div> 
                            </div>
                            <div class="form-group col-2 text-right">
                                <br>
                                <button class="btn btn-danger" (click)="removeReembolso(i)">
                                    <fa-icon [icon]="['fas', 'minus-circle']" size="1x"></fa-icon>
                                </button>&nbsp;
                                <button class="btn btn-success" (click)="addReembolso()">
                                    <fa-icon [icon]="['fas', 'plus-circle']" size="1x"></fa-icon>
                                </button>
                            </div>
                        </div>   
                    </div> 
                </form>
            </div>
        </div>
    </div>
    <br>
    <!-- FIM CAMPOS REEMBOLSO -->
        <div *ngIf="tipoDeConta">
            <br>
            <div class="row">
                <div class="col">
                    <div class="uploadfilecontainer" (click)="fileDocumentos.click()" appDragDrop (onFileDropped)="onChangeContaPagar($event)">
                        <input hidden type="file" #fileDocumentos (change)="onChangeClickContaPagar($event)" multiple>
                    </div>
                    <div class="files-list" *ngFor="let file of filesContaPagar; let i= index">
                        <p>{{ file }}</p>
                    </div> 
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col">
                    <button class="btn btn-danger btn-block" (click)="closeModalNovaConta()">CANCELAR</button>
                </div>
                <div class="col" *ngIf="tipoDeConta == 'CONTA A PAGAR'">
                        <button class="btn btn-success btn-block" (click)="cadastrarConta()" [disabled]="ccList.value.length < 1 || !ccList.valid || !tipoVencimentoConta || !fornecedorConta || !observacaoConta || ((tipoVencimentoConta == 'Semanal' || tipoVencimentoConta == 'Mensal' || tipoVencimentoConta == 'Anual') && (repeticoes < 1 || !valorConta || !vencimentoConta))">CADASTRAR CONTA</button>
                </div>
                <div class="col" *ngIf="tipoDeConta == 'VENCIMENTOS'">
                    <button class="btn btn-success btn-block" (click)="cadastrarConta()" [disabled]="ccList.value.length < 1 || !ccList.valid || !tipoVencimentoCadastro || !colaboradorVencimento">CADASTRAR VENCIMENTO</button>
                </div>
                <div class="col" *ngIf="tipoDeConta == 'REEMBOLSO'">
                    <button class="btn btn-success btn-block" (click)="cadastrarReembolso()" [disabled]="ccList.value.length < 1 || !ccList.valid || !colaboradorReembolso || !motivoReembolso || !reembolsoList.valid">CADASTRAR REEMBOLSO</button>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- MODAL CADASTRO FORMA PAGAMENTO -->
<div class="modal" id="modalFormasPagamento">
    <div class="voltartopo">
        <button class="btn btn-outline-light" (click)="closeModalFormas()"><fa-icon [icon]="['fas', 'arrow-left']"></fa-icon> Voltar</button>
    </div>
    <div style="margin-top:3em;padding:50px">
        <form [formGroup]="formasPagamento">
            <div class="row" formArrayName="forma">
                <div class="col col-12" style="font-size: 0.85em" *ngFor="let forma of formasPagamentoFormGroup.controls; let i = index;">
                    <div [formGroupName]="i" class="row">
                        <div class="form-group col-9">
                            <input type="text" class="form-control" formControlName="forma" placeholder="Forma Pagamento" oninput="this.value = this.value.toUpperCase()">
                        </div>
                        <div class="form-group col-3 text-right">
                            <button class="btn btn-danger btn-sm" type="button" (click)="removePagamento(i)" *ngIf="this.formaPagamentoList?.length > 1">
                            <fa-icon [icon]="['fas', 'minus-circle']" size="1x"></fa-icon>
                            </button>&nbsp;
                            <button class="btn btn-success btn-sm" type="button" (click)="addPagamento()">
                            <fa-icon [icon]="['fas', 'plus-circle']" size="1x"></fa-icon>
                            </button>
                        </div>
                    </div>
                </div>
            </div>   
        </form>
       <br>
       <div class="row">
           <div class="col col-4">
               &nbsp;
           </div>
           <div class="col col-2">
                <button class="btn btn-danger btn-block" (click)="closeModalFormas()">CANCELAR</button>
           </div>
           <div class="col col-2">
                <button class="btn btn-success btn-block" (click)="salvarFormas()">SALVAR</button>
           </div>
           <div class="col col-4">
                &nbsp;
            </div>
       </div>
    </div>
</div>
<div class="row  align-items-center header">
    <div class="col" style="text-align: right;">
        <button class="btn btn-sm btn-outline-light" (click)="openModalFormas()">
            CADASTRAR FORMA DE PAGAMENTO
        </button>&nbsp;&nbsp;
        <button class="btn btn-sm btn-outline-light" (click)="openModalNovaConta()">
            CADASTRAR CONTA A PAGAR
        </button>
    </div>
</div>
<br>
<div class="row align-items-end" style="padding:5px">
    <div class="col col-3">
        <label>Data Inicio</label>
        <input type="date" class="form-control" [(ngModel)]="dataInicioConta">
    </div>
    <div class="col col-3">
        <label>Data Fim</label>
        <input type="date" class="form-control" [(ngModel)]="dataFimConta">
    </div>
    <div class="col col-1">
        <label>&nbsp;</label>
        <button class="btn btn-primary" (click)="getContas(dataInicioConta,dataFimConta)" [disabled]="!dataInicioConta || !dataFimConta"><fa-icon [icon]="['fas', 'search']"></fa-icon></button>
    </div>
    <div class="col col-5">
        <label>Banco</label>
        <select [(ngModel)]="bancoContaVer" class="form-control" (change)="filtroBanco(bancoContaVer);limparFormaPagamento();limparFornecedor()">
            <option value=""></option>
            <option *ngFor="let b of bancos">{{b.nome}}</option>
        </select>
    </div>
  </div>
  <div class="row align-items-center">
    <div class="col col-4">
        <label>Forma de Pagamento</label>
        <select [(ngModel)]="formaPagamentoContaVer" class="form-control" (change)="filtro(formaPagamentoContaVer);limparFornecedor();limparBanco()">
            <option value="" selected></option>
            <option *ngFor="let forma of todasFormasPagamento" [value]="forma.forma">{{forma.forma}}</option>
        </select>
    </div>
    <div class="col col-6">
        <label>Fornecedor</label>
        <ng-select class="form-control" [items]="fornecedores" bindLabel="razaoSocial" appendTo="body" [(ngModel)]="fornecedorBusca" (change)="filtroFornecedor(fornecedorBusca);limparFormaPagamento();limparBanco()"></ng-select>
    </div>
    <div class="col col-2">
        <br>
        <button class="btn btn-primary" (click)="getTodasContas();limparFormaPagamento();limparFornecedor();limparBanco()">TODAS CONTAS</button>
    </div>
</div>
<br>
<div class="table-responsive">
    <table class="table table-striped" style="text-align: center;">
        <thead class="thead-dark">
            <tr>
                <th>Fornecedor / Colaborador</th>
                <th>Observação</th>
                <th>Vencimento</th>
                <th>Tipo</th>
                <th>Pagamento</th>
                <th>Banco</th>
                <th>Valor</th>
                <th>Parcela</th> 
                <th *ngIf="db?.usuario?.permissoes['financeiro'] == 'EDITAR'">Editar / Pagar</th>
                <th *ngIf="db?.usuario?.permissoes['financeiro'] == 'EDITAR'">Excluir</th>
            </tr>
        </thead>
        <tbody *ngFor="let conta of todasContasPagar; let i = index">
            <tr *ngIf="conta.tipoDeConta != 'VENCIMENTOS' || (conta.tipoDeConta == 'VENCIMENTOS' && db?.usuario?.permissoes['vencimentos'] == 'EDITAR')">
                <td>{{ conta?.fornecedor }}</td>
                <td>{{ conta?.observacao }}</td>
                <td [ngStyle]="{'background-color': conta?.vencimento <= dataHoje ? '#e08383' : 'null'}" >{{ conta?.vencimento | date:'dd/MM/yyyy' }}</td>
                <td>{{ conta?.tipoVencimento }}</td>
                <td>{{ conta?.formaPagamento }}</td>
                <td>{{ conta?.banco }}</td>
                <td>{{ conta?.valor | currency:'BRL' }}</td>
                <td>{{ conta?.parcela }}</td>
                <td>
                    <button class="btn btn-outline-success" (click)="openModalVerConta(conta)">
                        <fa-icon [icon]="['fas', 'edit']"></fa-icon>
                    </button>
                </td>
                <td >
                    <button *ngIf="db?.usuario?.permissoes['financeiro'] == 'EDITAR'" class="btn btn-outline-danger" (click)="excluirConta(conta._id,i)">
                        <fa-icon [icon]="['fas', 'trash']"></fa-icon>
                    </button>
                </td>
            </tr>
        </tbody>
    </table>
</div>
<div class="row" style="padding:10px">
    <div class="col col-8">&nbsp;</div>
    <div class="col col-4" style="text-align: right;font-weight: bold;">Total: {{totalContasPagar | currency:'BRL'}}</div>
</div>
</div>