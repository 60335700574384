import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class DataBaseService {
   urlDataBase:any = "https://data.mongodb-api.com/app/database-msxfu/endpoint/http/dbhookweb";
   urlSite:any = "https://app.vivamax.com.br"
   apiVindi:any = "https://data.mongodb-api.com/app/database-msxfu/endpoint/vindi/vindi?";
   apiPush:any = "https://data.mongodb-api.com/app/database-msxfu/endpoint/apiaws";
   apiEmailBoasVindas:any = "https://data.mongodb-api.com/app/database-msxfu/endpoint/enviarboasvindas";
   apiTokenDoc:any = "https://data.mongodb-api.com/app/database-msxfu/endpoint/tokendoc";
   apiClienteDoc:any = "https://data.mongodb-api.com/app/database-msxfu/endpoint/apiAddDoc";
   apiDelDoc24:any = "https://data.mongodb-api.com/app/database-msxfu/endpoint/deleteDocConecta";
   //apiSMSemMassa:any = "https://webhooks.mongodb-realm.com/api/client/v2.0/app/api-osobf/service/smsmassa/incoming_webhook/api";
   //apiSMS:any = "https://webhooks.mongodb-realm.com/api/client/v2.0/app/api-osobf/service/sms/incoming_webhook/web-sms?secret";
   apiSorte:any = this.urlDataBase+'?coll=sorte';
   apiUsuarios:any = this.urlDataBase+'?coll=usuarios';
   apiAssociados:any = this.urlDataBase+'?coll=associados';
   apiCredenciados:any = this.urlDataBase+'?coll=credenciados';
   apiFinanceiro:any = this.urlDataBase+'?coll=financeiro';
   apiContasReceber:any = this.urlDataBase+'?coll=contas-receber';
   apiPlanos:any = this.urlDataBase+'?coll=planos';
   apiSubContratos:any = this.urlDataBase+'?coll=subcontratos';
   apiLogs:any = this.urlDataBase+'?coll=logs';
   apiConfiguracoes:any = this.urlDataBase+'?coll=configuracoes';
   apiFornecedores:any = this.urlDataBase+'?coll=fornecedores';
   apiFavorecidos:any = this.urlDataBase+'?coll=favorecidos';
   apiCaixaVindi:any = this.urlDataBase+'?coll=caixaVindi';
   apiReceberVindi:any = this.urlDataBase+'?coll=receberVindi';
   apiFormularios:any = this.urlDataBase+'?coll=formularios';
   apiEmpresas:any = this.urlDataBase+'?coll=empresas';
   apiFaturamento:any = this.urlDataBase+'?coll=faturamento';
   apiCelesc:any = this.urlDataBase+'?coll=celesc';
   apiCargos:any = this.urlDataBase+'?coll=cargos';
   apiProgramacao:any = this.urlDataBase+'?coll=programacao';
   apiProgramacaoLogs:any = this.urlDataBase+'?coll=proglogs';
   apiCentroCusto:any = this.urlDataBase+'?coll=centroCusto';
   apiComissoes:any = this.urlDataBase+'?coll=comissoes';
   apiMetricas:any = this.urlDataBase+'?coll=metricas';
   apiVendas:any = this.urlDataBase+'?coll=vendas';
   permissaoExcluirAssociado:any = "";
   tipoUsuario:any = "";
   usuario:any = "";
   todasFormasPagamento:any = [];
   cargos:any = [];
   configuracoes:any = [];
   idCargo:any = "";
   emails:any = [];
   motivosAtendimento:any = [];

   constructor(public http:HttpClient){
    window.addEventListener("load", () => {
    this.getUsuarioById(localStorage.getItem('idUserVivamax')).subscribe((u:any) => {
      if(u){
        this.permissaoExcluirAssociado = u.excluirAssociado;
        this.tipoUsuario = u.tipo;
        this.usuario = u;
      }
      this.getCargos().subscribe((data:any) => {
        this.cargos = data[0].cargos;
        this.idCargo = data[0]._id;
        this.getConfigs().subscribe((z:any) => {
          this.configuracoes = z;
          this.todasFormasPagamento = z[5].pagamentos;
          this.motivosAtendimento = z[26].motivosAtendimento
          this.emails = z[23].emails;
        })
      })
     });
   })  
  }

  getCpfs(url:any){
    return this.http.get(url);
  }

  getDependenteByCpf(cpf:any){
    return this.http.get(this.apiAssociados+'&method=GET&filtro={"dependentes.cpf":"'+cpf+'"}');
  }

  getDependenteExtraByCpf(cpf:any){
    return this.http.get(this.apiAssociados+'&method=GET&filtro={"dependentesExtra.cpf":"'+cpf+'"}');
  }

  patchCargos(id:any,dados:any){
    let body = JSON.stringify(dados);
    return this.http.get(this.apiCargos+'&method=PATCH&id='+id+'&body='+body)
  }

  getLogsProgramacao(ano:any){
    return this.http.get(this.apiProgramacaoLogs+'&method=GET&filtro={"anoProg":"'+ano+'"}&sort={"data":1,"hora":-1}');
  }

   getFormularios(){
    return this.http.get(this.apiFormularios+'&method=GET');
  }

  getProgramacoes(){
    return this.http.get(this.apiProgramacao+'&method=GET');
  }

  getComissoes(){
    return this.http.get(this.apiComissoes+'&method=GET');
  }

  getComissoesByData(mes:any){
    return this.http.get(this.apiComissoes+'&method=GET&filtro={"mesComissao":"'+mes+'"}');
  }

  getComissoesByDataVendedor(mes:any,vendedor:any){
    return this.http.get(this.apiComissoes+'&method=GET&filtro={"mesComissao":"'+mes+'","vendedor":"'+vendedor+'"}');
  }

  postComissao(dados:any){
    let body = JSON.stringify(dados);
    return this.http.get(this.apiComissoes+'&method=POST&body='+body)
  }

  getProgramacaoByAno(ano:any){
    return this.http.get(this.apiProgramacao+'&method=GET&filtro={"ano":"'+ano+'"}');
  }

  getProgramacoesAnos(array:any){
    return this.http.get(this.apiProgramacao+'&method=GET&sort={"ano":1}&project={'+array+'}');
  }

  getCentrosCusto(){
    return this.http.get(this.apiCentroCusto+'&method=GET');
  }

  patchProgramacao(id:any,dados:any){
    let body = JSON.stringify(dados);
    return this.http.get(this.apiProgramacao+'&method=PATCH&id='+id+'&body='+body)
  }
  
  patchProgramacaoAdm(id:any,dados:any){
    let body = JSON.stringify({administrativo:dados});
    return this.http.get(this.apiProgramacao+'&method=PATCH&id='+id+'&body='+body)
  }

  patchProgramacaoCom(id:any,dados:any){
    let body = JSON.stringify({comercial:dados});
    return this.http.get(this.apiProgramacao+'&method=PATCH&id='+id+'&body='+body)
  }

  patchProgramacaoPub(id:any,dados:any){
    let body = JSON.stringify({publicidade:dados});
    return this.http.get(this.apiProgramacao+'&method=PATCH&id='+id+'&body='+body)
  }

  patchCentroCusto(id:any,dados:any){
    let body = JSON.stringify(dados);
    return this.http.get(this.apiCentroCusto+'&method=PATCH&id='+id+'&body='+body)
  }

  getLoginByUsuario(mail:any, pwd:any){
    return this.http.get(this.apiUsuarios+'&method=GET&filtro={"email":"'+mail+'","pwd":"'+pwd+'"}')
  }

  getLoginByUsuarioProjection(mail:any, pwd:any,array:any){
    return this.http.get(this.apiUsuarios+'&method=GET&filtro={"email":"'+mail+'","pwd":"'+pwd+'","situacao":{"$not":{"$regex":"DEMITIDO","$options":"i"}}}&project={'+array+'}')
  }

  getAllUsuarios(){
    return this.http.get(this.apiUsuarios+'&method=GET')
  }

  getAllUsuariosComTarefas(){
    return this.http.get(this.apiUsuarios+'&method=GET&filtro={"$or":[{"situacao":"EFETIVADO"},{"situacao":"EXPERIÊNCIA"}]}&sort={"nome":1}')
  }
  
  getAllUsuariosAtivos(){
    return this.http.get(this.apiUsuarios+'&method=GET&filtro={"situacao":{"$not":{"$regex":"DEMITIDO","$options":"i"}}}&sort={"nome":-1}')
  }

  getUsuariosComercial(){
    return this.http.get(this.apiUsuarios+'&method=GET&filtro={"colaboradorComercial":true,"situacao":{"$not":{"$regex":"DEMITIDO","$options":"i"}}}&sort={"nome":1}')
  }

  getUsuariosComercialComissao(array:any){
    return this.http.get(this.apiUsuarios+'&method=GET&filtro={"colaboradorComercial":true,"situacao":{"$not":{"$regex":"DEMITIDO","$options":"i"}}}&sort={"nome":1}&project={'+array+'}');
  }

  getCredenciados(){
    return this.http.get(this.apiCredenciados+'&method=GET')
  }

  getCredenciadosFilter(laboratorio:any,credenciado:any,especialidade:any,exame:any,cidade:any){
    let lab = laboratorio == true ? '"laboratorioCheck":true,' : '';
    let espec = especialidade != '' ?  '"$or":[{"especialidade":{"$regex":"'+especialidade+'","$options":"i"}},{"profissionais.especialidade":{"$regex":"'+especialidade+'","$options":"i"}}],' : '';
    let exam = exame != '' ? '"exames.nomeExame":{"$regex":"'+exame+'","$options":"i"},' : '';
    return this.http.get(this.apiCredenciados+'&method=GET&filtro={'+lab+espec+exam+'"municipio":{"$regex":"'+cidade+'","$options":"i"},"fantasiaCredenciado":{"$regex":"'+credenciado+'","$options":"i"}}');
  }

  getCredenciadosProjection(array:any){
    return this.http.get(this.apiCredenciados+'&method=GET&project={'+array+'}')
  }
  
  getEmpresas(){
    return this.http.get(this.apiEmpresas+'&method=GET&sort={"fantasiaEmpresa":1}')
  }

  getEmpresasComissao(array:any){
    return this.http.get(this.apiEmpresas+'&method=GET&sort={"fantasiaEmpresa":1}&project={'+array+'}');
  }

  getAllFornecedores(){
    return this.http.get(this.apiFornecedores+'&method=GET&sort={"razaoSocial":1}')
  }

  cadastroFornecedor(dados:any){
    let body = JSON.stringify(dados);
    return this.http.get(this.apiFornecedores+'&method=POST&body='+body)
  }

  editarFornecedor(id:any,dados:any){
    let body = JSON.stringify(dados);
    return this.http.get(this.apiFornecedores+'&method=PATCH&id='+id+'&body='+body)
  }

  getAllFavorecidos(){
    return this.http.get(this.apiFavorecidos+'&method=GET')
  }

  cadastroFavorecido(dados:any){
    let body = JSON.stringify(dados);
    return this.http.get(this.apiFavorecidos+'&method=POST&body='+body)
  }

  editarFavorecido(id:any,dados:any){
    let body = JSON.stringify(dados);
    return this.http.get(this.apiFavorecidos+'&method=PATCH&id='+id+'&body='+body)
  }

  cadastroUsuario(dados:any){
    let body = JSON.stringify(dados);
    return this.http.get(this.apiUsuarios+'&method=POST&body='+body)
  }

  patchUsuario(id:any,dados:any){
    let body = JSON.stringify(dados);
    return this.http.get(this.apiUsuarios+'&method=PATCH&id='+id+'&body='+body)
  }

  deleteContaOrcamentaria(id:any){
    return this.http.get(this.apiCentroCusto+'&method=DELETE&id='+id);
   }

  deleteUsuario(id:any){
    return this.http.get(this.apiUsuarios+'&method=DELETE&id='+id);
   }

   deleteContaFinanceiro(id:any){
    return this.http.get(this.apiFinanceiro+'&method=DELETE&id='+id);
   }

   deleteFornecedor(id:any){
    return this.http.get(this.apiFornecedores+'&method=DELETE&id='+id);
   }

   deleteCredenciado(id:any){
    return this.http.get(this.apiCredenciados+'&method=DELETE&id='+id);
   }

   deleteEmpresa(id:any){
    return this.http.get(this.apiEmpresas+'&method=DELETE&id='+id);
   }

   postProgramacao(dados:any){
    let body = JSON.stringify(dados);
    return this.http.get(this.apiProgramacao+'&method=POST&body='+body);
  }

  postCentroCusto(dados:any){
    let body = JSON.stringify(dados);
    return this.http.get(this.apiCentroCusto+'&method=POST&body='+body);
  }

   postCredenciado(dados:any){
    let body = JSON.stringify(dados);
    return this.http.get(this.apiCredenciados+'&method=POST&body='+body);
  }

  postEmpresa(dados:any){
    let body = JSON.stringify(dados);
    return this.http.get(this.apiEmpresas+'&method=POST&body='+body);
  }

   removeDocConta(id:any, url:any){
    return this.http.get(this.apiFinanceiro+'&method=DELETEDOCCONTA&id='+id+'&url='+url);
  }

  removeDocCredenciado(id:any, url:any){
    return this.http.get(this.apiCredenciados+'&method=DELETEDOCCONTA&id='+id+'&url='+url);
  }

  removeDocEmpresa(id:any, url:any){
    return this.http.get(this.apiEmpresas+'&method=DELETEDOCCONTA&id='+id+'&url='+url);
  }

   getUsuarioById(id:any){
    return this.http.get(this.apiUsuarios+'&method=GET&id='+id);
  }

  getSorte(){
    return this.http.get(this.apiSorte+'&method=GET');
  }

  getSorteLivre(){
    return this.http.get(this.apiSorte+'&method=GET&filtro={"usado":false}');
  }

  patchSorte(id:any,dados:any){
    let body = JSON.stringify(dados);
    return this.http.get(this.apiSorte+'&method=PATCH&id='+id+'&body='+body)
  }

  getAssociadosByPlano(plano:any){
    return this.http.get(this.apiAssociados+'&method=GET&project={"plano":1,"nome":1,"numSorte":1}&filtro={"plano":{"$regex":"'+plano+'","$options":"i"}}&sort={"_id":-1}');
  }
  
  getCredenciadosById(id:any){
    return this.http.get(this.apiCredenciados+'&method=GET&id='+id);
  }

  getAnexosCredenciadosById(id:any){
    return this.http.get(this.apiCredenciados+'&method=GET&id='+id+'&project={"anexos":1}');
  }

  postFinanceiro(dados:any){
    let body = JSON.stringify(dados);
    return this.http.get(this.apiFinanceiro+'&method=POST&body='+body);
  }

  editarContaFinanceiro(id:any,dados:any){
    let body = JSON.stringify(dados);
    return this.http.get(this.apiFinanceiro+'&method=PATCH&id='+id+'&body='+body)
  }

  patchCredenciado(id:any,dados:any){
    let body = JSON.stringify(dados);
    return this.http.get(this.apiCredenciados+'&method=PATCH&id='+id+'&body='+body)
  }

  patchEmpresa(id:any,dados:any){
    let body = JSON.stringify(dados);
    return this.http.get(this.apiEmpresas+'&method=PATCH&id='+id+'&body='+body)
  }

  getAssociados(){
    return this.http.get(this.apiAssociados+'&method=GET');
  }

  getAssociadosProjection(array:any){
    return this.http.get(this.apiAssociados+'&method=GET&project={'+array+'}&sort={"_id":-1}');
  }

  getAssociadoByCpf(array:any,cpf:any){
    return this.http.get(this.apiAssociados+'&method=GET&project={'+array+'}&filtro={"cpf":"'+cpf+'"}&sort={"empresa":-1}');
  }

  getAssociadoByCpfEmpresa(array:any,cpf:any,empresa:any){
    return this.http.get(this.apiAssociados+'&method=GET&project={'+array+'}&filtro={"cpf":"'+cpf+'","empresa":"'+empresa+'"}&sort={"empresa":-1}');
  }

  getAssociadoByCpfAll(cpf:any){
    return this.http.get(this.apiAssociados+'&method=GET&filtro={"cpf":"'+cpf+'"}&sort={"empresa":-1}');
  }

  getAssociadosByAno(ano:any){
    return this.http.get(this.apiAssociados+'&method=GET&filtro={"dataAdesao":{"$gte":"'+ano+'-10-01","$lte":"'+ano+'-12-31"}}');
  }

  getAssociadosByVendedor(vendedor:any){
    return this.http.get(this.apiAssociados+'&method=GET&filtro={"vendedor":"'+vendedor+'"}');
  }

  getAssociadosByVendedorProjection(vendedor:any,array:any){
    return this.http.get(this.apiAssociados+'&method=GET&filtro={"vendedor":"'+vendedor+'"}&project={'+array+'}');
  }

  getAssociadosVivamax(){
    return this.http.get(this.apiAssociados+'&method=GET&filtro={"empresa":"VIVA MAX"}');
  }

  getAssociadosPlanoFisicoCarne(){
    return this.http.get(this.apiAssociados+'&method=GET&filtro={"planoFisicoCheck":true,"$or":[{"formaPagamento":"BOLETO"},{"formaPagamento":"CARNÊ"}]}');
  }

  getAssociadosPendentesCelesc(){
    return this.http.get(this.apiAssociados+'&method=GET&filtro={"celescStatus":"Aguardando Envio"}');
  }

  getAssociadosConecta(){
    return this.http.get(this.apiAssociados+'&method=GET&filtro={"empresa":"CONECTA"}&sort={"dataAdesao":-1}');
  }

  getAssociadosConectaAtivo(){
    return this.http.get(this.apiAssociados+'&method=GET&filtro={"empresa":"CONECTA","situacao":"ATIVO"}&sort={"dataAdesao":-1}');
  }

  getAssociadosById(id:any){
    return this.http.get(this.apiAssociados+'&method=GET&id='+id);
  }

  getAssociadosAtivosData(dataInicio:any,dataFim:any){
    return this.http.get(this.apiAssociados+'&method=GET&filtro={"dataAdesao":{"$gte":"'+dataInicio+'","$lte":"'+dataFim+'"},"situacao":"ATIVO"}&sort={"nome":1}');
  }

  getContasPagar(dataInicio:any,dataFim:any){
    return this.http.get(this.apiFinanceiro+'&method=GET&filtro={"vencimento":{"$gte":"'+dataInicio+'","$lte":"'+dataFim+'"},"status":"Pendente","tipo":"Saida"}&sort={"vencimento":1}');
  }

  getContasPagarProjection(dataInicio:any,dataFim:any,array:any){
    return this.http.get(this.apiFinanceiro+'&method=GET&filtro={"vencimento":{"$gte":"'+dataInicio+'","$lte":"'+dataFim+'"},"status":"Pendente","tipo":"Saida"}&sort={"vencimento":1}&project={'+array+'}');
  }

  getTodasContasPagar(){
    return this.http.get(this.apiFinanceiro+'&method=GET&filtro={"status":"Pendente","tipo":"Saida"}&sort={"vencimento":1,"observacao":1}');
  }

  getEntradas(dataInicio:any,dataFim:any){
    return this.http.get(this.apiFinanceiro+'&method=GET&filtro={"vencimento":{"$gte":"'+dataInicio+'","$lte":"'+dataFim+'"},"tipo":"Entrada"}&sort={"vencimento":1}');
  }

  getCaixaComissao(dataInicio:any,dataFim:any,array:any){
    return this.http.get(this.apiFinanceiro+'&method=GET&filtro={"vencimento":{"$gte":"'+dataInicio+'","$lte":"'+dataFim+'"},"tipo":"Entrada"}&sort={"dataPagamento":-1}&project={'+array+'}');
  }

  getContasReceber(dataInicio:any,dataFim:any,favorecido:any){
    return this.http.get(this.apiFinanceiro+'&method=GET&filtro={"vencimento":{"$gte":"'+dataInicio+'","$lte":"'+dataFim+'"},"favorecido":{"$regex":"'+favorecido+'","$options":"i"},"status":"Pendente","tipo":"Entrada"}&sort={"vencimento":1}');
  }

  getContasReceberProjection(dataInicio:any,dataFim:any,array:any){
    return this.http.get(this.apiFinanceiro+'&method=GET&filtro={"vencimento":{"$gte":"'+dataInicio+'","$lte":"'+dataFim+'"},"status":"Pendente","tipo":"Entrada"}&sort={"vencimento":1}&project={'+array+'}');
  }

  getContasPagas(dataInicio:any,dataFim:any,fornecedor:any){
    return this.http.get(this.apiFinanceiro+'&method=GET&filtro={"dataPagamento":{"$gte":"'+dataInicio+'","$lte":"'+dataFim+'"},"fornecedor":{"$regex":"'+fornecedor+'","$options":"i"},"status":"Pago"}&sort={"dataPagamento":1}');
  }

  getContasCliente(nome:any, cpf:any){
    return this.http.get(this.apiFinanceiro+'&method=GET&filtro={"$or":[{"documentoFavorecido":"'+cpf+'"},{"favorecido":"'+nome+'"}]}&sort={"data":1}');
  }

  getCaixa(dataInicio:any,dataFim:any){
    return this.http.get(this.apiFinanceiro+'&method=GET&filtro={"dataPagamento":{"$gte":"'+dataInicio+'","$lte":"'+dataFim+'"},"status":"Pago"}&sort={"dataPagamento":1}');
  }

  getCaixaDash(dataInicio:any,dataFim:any){
    return this.http.get(this.apiFinanceiro+'&method=GET&filtro={"dataPagamento":{"$gte":"'+dataInicio+'","$lte":"'+dataFim+'"},"status":"Pago"}&sort={"dataPagamento":1}&project={"dataPagamento":1,"valorTotal":1,"tipo":1}');
  }

  getFinanceiroCC(dataInicio:any,dataFim:any,centroCusto:any,contaOrcamentaria:any,contaContabil:any,formaPagamento:any,fornecedorConta:any){
    let cCusto = "";
    let cC = "";
    let cO = "";
    let cContabil = "";
    let formaPgto = "";
    let fornecedor = "";
    if(centroCusto != ''){cCusto = '"centrosCusto.centroDeCusto":"'+centroCusto+'",'};
    if(contaOrcamentaria != ''){cO = '"centrosCusto.contaOrcamentaria":"'+contaOrcamentaria+'",'};
    if(contaContabil != ''){cC = '"centrosCusto.contaContabil":"'+contaContabil+'",'};
    if(formaPagamento != ''){formaPgto = '"formaPagamento":"'+formaPagamento+'",'};
    if(fornecedorConta != ''){fornecedor = '"$or":[{"fornecedor":{"$regex":"'+fornecedorConta+'","$options":"i"}},{"favorecido":{"$regex":"'+fornecedorConta+'","$options":"i"}}],'};
    return this.http.get(this.apiFinanceiro+'&method=GET&filtro={'+cCusto+cC+cO+cContabil+formaPgto+fornecedor+'"dataPagamento":{"$gte":"'+dataInicio+'","$lte":"'+dataFim+'"},"status":"Pago"}&sort={"dataPagamento":1}');
  }
 
  getCaixaFornecedor(dataInicio:any,dataFim:any,fornecedor:any,formaPagamento:any,banco:any){
    return this.http.get(this.apiFinanceiro+'&method=GET&filtro={"formaPagamento":{"$regex":"'+formaPagamento+'","$options":"i"},"banco":{"$regex":"'+banco+'","$options":"i"},"dataPagamento":{"$gte":"'+dataInicio+'","$lte":"'+dataFim+'"},"$or":[{"fornecedor":{"$regex":"'+fornecedor+'","$options":"i"}},{"favorecido":{"$regex":"'+fornecedor+'","$options":"i"}}],"status":"Pago"}&sort={"dataPagamento":1}');
  }

  getContasById(id:any){
    return this.http.get(this.apiFinanceiro+'&method=GET&id='+id);
  }

  getAssociadosCanceladosData(dataInicio:any,dataFim:any){
    return this.http.get(this.apiAssociados+'&method=GET&filtro={"dataCancelamento":{"$gte":"'+dataInicio+'","$lte":"'+dataFim+'"},"situacao":"CANCELADO"}&sort={"nome":1}');
  }

  getAssociadosSolicitacaoCancelados(dataInicio:any,dataFim:any){
    return this.http.get(this.apiAssociados+'&method=GET&filtro={"dataSolicitacaoCancelamento":{"$gte":"'+dataInicio+'","$lte":"'+dataFim+'"},"solicitouCancelamento":true,"situacao":{"$not":{"$regex":"CANCELADO","$options":"i"}}}&sort={"nome":1}');
  }

  getAssociadosMatricula(key:any,empresa:any){
    let emp = "";
    if(empresa != "TODOS"){
      emp = '"empresa":"'+empresa+'",'
    };
    return this.http.get(this.apiAssociados+'&method=GET&filtro={'+emp+'"matricula":'+key+'}&sort={"nome":1}');
  }

  getAssociadosVivamaxMatricula(key:any){
    return this.http.get(this.apiAssociados+'&method=GET&filtro={"empresa":"VIVA MAX","matricula":'+key+'}&sort={"nome":1}');
  }

  getAssociadosSegurosMatricula(key:any){
    return this.http.get(this.apiAssociados+'&method=GET&filtro={"empresa":"SEGUROS","matricula":'+key+'}&sort={"nome":1}');
  }

  getAssociadosVivamaxCpf(cpf:any){
    return this.http.get(this.apiAssociados+'&method=GET&filtro={"empresa":"VIVA MAX","cpf":"'+cpf+'"}&sort={"nome":1}');
  }

  getAssociadosCpf(cpf:any,empresa:any){
    let emp = "";
    if(empresa != "TODOS"){
      emp = '"empresa":"'+empresa+'",'
    };
    return this.http.get(this.apiAssociados+'&method=GET&filtro={'+emp+'"cpf":"'+cpf+'"}&sort={"nome":1}');
  }

  getAssociadoNome(titular:any,empresa:any){
    let emp = "";
    if(empresa != "TODOS"){
      emp = '"empresa":"'+empresa+'",'
    };
    return this.http.get(this.apiAssociados+'&method=GET&filtro={'+emp+'"nome":{"$regex":"'+titular+'","$options":"i"}}&sort={"_id":-1}');
   }
  
   getTodosAssociadosData(dtInicio:any,dtFim:any,empresa:any){
    let emp = "";
    if(empresa != "TODOS"){
      emp = '"empresa":"'+empresa+'",'
    };
    return this.http.get(this.apiAssociados+'&method=GET&filtro={'+emp+'"dataAdesao":{"$gte":"'+dtInicio+'","$lte":"'+dtFim+'"}}&sort={"_id":-1}');
  }

  getAssociadosSegurosCpf(cpf:any){
    return this.http.get(this.apiAssociados+'&method=GET&filtro={"empresa":"SEGUROS","cpf":"'+cpf+'"}&sort={"nome":1}');
  }

  getAssociadosConectaMatricula(key:any){
    return this.http.get(this.apiAssociados+'&method=GET&filtro={"empresa":"CONECTA","matricula":'+key+'}&sort={"nome":1}');
  }

  getAssociadosConectaCpf(cpf:any){
    return this.http.get(this.apiAssociados+'&method=GET&filtro={"empresa":"CONECTA","cpf":"'+cpf+'"}&sort={"nome":1}');
  }

  deleteAssociado(id:any){
   return this.http.get(this.apiAssociados+'&method=DELETE&id='+id);
  }

  deleteFavorecido(id:any){
    return this.http.get(this.apiFavorecidos+'&method=DELETE&id='+id);
   }

  cadastroProposta(dados:any){
    let body = JSON.stringify(dados);
    return this.http.get(this.apiAssociados+'&method=POST&body='+body);
  }

  postLogsProgramacao(dados:any){
    let body = JSON.stringify(dados);
    return this.http.get(this.apiProgramacaoLogs+'&method=POST&body='+body);
  }

  getSubContratos(){
    return this.http.get(this.apiSubContratos+'&method=GET');
  }

  getSubContratosProjection(array:any){
    return this.http.get(this.apiSubContratos+'&method=GET&project={'+array+'}');
  }

  getMetricas(){
    return this.http.get(this.apiMetricas+'&method=GET&sort={"data":-1}'); 
  }

  getSubContratosById(id:any){
    return this.http.get(this.apiSubContratos+'&method=GET&id='+id);
  }

  getSubContratosByNome(nome){
    return this.http.get(this.apiSubContratos+'&method=GET&filtro={"nome":"'+nome+'"}');
  }

  postSubContratos(dados:any){
    let body = JSON.stringify(dados);
    return this.http.get(this.apiSubContratos+'&method=POST&body='+body);
  }

  deleteSubContratos(id:any){
    return this.http.get(this.apiSubContratos+'&method=DELETE&id='+id);
  }

  patchSubContratos(id:any,dados:any){
    let body = JSON.stringify(dados);
    return this.http.get(this.apiSubContratos+'&method=PATCH&id='+id+'&body='+body);
  }

  getUsuariosTipo(tipo:any){
    return this.http.get(this.apiUsuarios+'&method=GET&filtro={"tipo":"'+tipo+'"}');
  }

  newHistorico(id:any,dados:any){
    let body = JSON.stringify({"historicos":dados});
    return this.http.get(this.apiAssociados+'&method=POSTINSIDE&id='+id+'&body='+body);
  }

  postSorteioConfiguracoes(id:any,dados:any){
    let body = JSON.stringify({"sorteios":dados});
    return this.http.get(this.apiConfiguracoes+'&method=POSTINSIDE&id='+id+'&body='+body);
  }

  postObservacaoUser(id:any,dados:any){
    let body = JSON.stringify({"observacao":dados});
    return this.http.get(this.apiUsuarios+'&method=POSTINSIDE&id='+id+'&body='+body);
  }

  newAgendamento(id:any,dados:any){
    let body = JSON.stringify({"agendamentos":dados});
    return this.http.get(this.apiAssociados+'&method=POSTINSIDE&id='+id+'&body='+body);
  }

  novaVenda(id:any,dados:any){
    let body = JSON.stringify({"vendas":dados});
    return this.http.get(this.apiUsuarios+'&method=POSTINSIDE&id='+id+'&body='+body);
  }

  patchAgendamento(id:any, idAgendamento:any, dados:any){
    let body = JSON.stringify(dados);
    return this.http.get(this.apiAssociados+'&method=PATCHAGENDAMENTO&id='+id+'&idAgenda='+idAgendamento+'&body='+body);
  }

  getAssociadosVivamaxTitular(titular:any){
   return this.http.get(this.apiAssociados+'&method=GET&filtro={"empresa":"VIVA MAX","nome":{"$regex":"'+titular+'","$options":"i"}}&sort={"_id":-1}');
  }

  getAssociadosSegurosTitular(titular:any){
    return this.http.get(this.apiAssociados+'&method=GET&filtro={"empresa":"SEGUROS","nome":{"$regex":"'+titular+'","$options":"i"}}&sort={"_id":-1}');
   }

  getAssociadosData(dtInicio:any,dtFim:any){
    return this.http.get(this.apiAssociados+'&method=GET&filtro={"empresa":"VIVA MAX","dataAdesao":{"$gte":"'+dtInicio+'","$lte":"'+dtFim+'"}}&sort={"_id":-1}');
  }

  getAssociadosSegurosData(dtInicio:any,dtFim:any){
    return this.http.get(this.apiAssociados+'&method=GET&filtro={"empresa":"SEGUROS","dataAdesao":{"$gte":"'+dtInicio+'","$lte":"'+dtFim+'"}}&sort={"_id":-1}');
  }

  getAssociadosVivamaxBeneficiario(nomeBeneficiario:any){
    return this.http.get(this.apiAssociados+'&method=GET&filtro={"dependentes.nome":{"$regex":"'+nomeBeneficiario+'","$options":"i"}}&sort={"_id":-1}');
  }

  getAssociadosVivamaxBeneficiarioExtra(nomeBeneficiario:any){
    return this.http.get(this.apiAssociados+'&method=GET&filtro={"dependentesExtra.nome":{"$regex":"'+nomeBeneficiario+'","$options":"i"}}&sort={"_id":-1}');
  }

  getAssociadosConectaTitular(titular:any){
    return this.http.get(this.apiAssociados+'&method=GET&filtro={"empresa":"CONECTA","nome":{"$regex":"'+titular+'","$options":"i"}}&sort={"_id":-1}');
  }

  getAssociadosConectaData(dtInicio:any,dtFim:any){
    return this.http.get(this.apiAssociados+'&method=GET&filtro={"empresa":"CONECTA","dataAdesao":{"$gte":"'+dtInicio+'","$lte":"'+dtFim+'"}}&sort={"_id":-1}');
  }

  getAssociadosConectaBeneficiario(nomeBeneficiario:any){
    return this.http.get(this.apiAssociados+'&method=GET&filtro={"empresa":"CONECTA","dependentes.nome":{"$regex":"'+nomeBeneficiario+'","$options":"i"}}');
  } 

  getCep(cep:any){
    return this.http.get('https://viacep.com.br/ws/'+cep+'/json/');
  }

  patchPlanos(id:any,dados:any){
    let body = JSON.stringify(dados);
    return this.http.get(this.apiPlanos+'&method=PATCH&id='+id+'&body='+body);
  }

  postPlanos(dados:any){
    let body = JSON.stringify(dados);
    return this.http.get(this.apiPlanos+'&method=POST&body='+body);
  }

  deletePlano(id:any){
    return this.http.get(this.apiPlanos+'&method=DELETE&id='+id);
  }

  getAssociadoByPlano(plano:any){
    return this.http.get(this.apiAssociados+'&method=GET&filtro={"plano":"'+plano+'"}');
  }

  getPlanos(){
    return this.http.get(this.apiPlanos+'&method=GET&filtro={"planoVendas":true}&sort={"empresaOrigem":-1,"tipo":-1,"nome":1}');
  }

  getTodosPlanos(){
    return this.http.get(this.apiPlanos+'&method=GET&sort={"empresaOrigem":-1,"tipo":-1,"nome":1}');
  }

  getPlanosComissao(array:any){
    return this.http.get(this.apiPlanos+'&method=GET&sort={"empresaOrigem":-1,"tipo":-1,"nome":1}&project={'+array+'}');
  }

  getPlanosConecta(){
    return this.http.get(this.apiPlanos+'&method=GET&filtro={"planoVendas":true,"empresaOrigem":"CONECTA"}&sort={"tipo":-1,"nome":1}');
  }

  getPlanosVivamax(){
    return this.http.get(this.apiPlanos+'&method=GET&filtro={"planoVendas":true,"empresaOrigem":"VIVA MAX"}&sort={"tipo":-1,"nome":1}');
  }

  getPlanosVivamaxSeguro(){
    return this.http.get(this.apiPlanos+'&method=GET&filtro={"planoVendas":true,"planoSeguro":true}&sort={"tipo":-1,"nome":1}');
  }

  getAllPlanosConecta(){
    return this.http.get(this.apiPlanos+'&method=GET&filtro={"empresaOrigem":"CONECTA"}&sort={"tipo":-1,"nome":1}');
  }

  getAllPlanosVivamax(){
    return this.http.get(this.apiPlanos+'&method=GET&filtro={"empresaOrigem":"VIVA MAX"}&sort={"tipo":-1,"nome":1}');
  }

  getAllPlanosVivamaxSeguro(){
    return this.http.get(this.apiPlanos+'&method=GET&filtro={"planoSeguro":true}&sort={"tipo":-1,"nome":1}');
  }

  getPlanosById(id:any){
    return this.http.get(this.apiPlanos+'&method=GET&id='+id);
  }

  getEmpresasById(id:any){
    return this.http.get(this.apiEmpresas+'&method=GET&id='+id);
  }

  getAnexosEmpresasById(id:any){
    return this.http.get(this.apiEmpresas+'&method=GET&id='+id+'&project={"anexos":1}');
  }

  getEmpresasByNome(nome:any){
    return this.http.get(this.apiEmpresas+'&method=GET&filtro={"fantasiaEmpresa":"'+nome+'"}');
  }

  getPlanosVivamaxByTipoByNome(tipo,nome){
    return this.http.get(this.apiPlanos+'&method=GET&filtro={"empresaOrigem":"VIVA MAX","tipo":"'+tipo+'","nome":"'+nome+'"}');
  }

  getPlanosConectaByTipoByNome(tipo,nome){
    return this.http.get(this.apiPlanos+'&method=GET&filtro={"empresaOrigem":"CONECTA","tipo":"'+tipo+'","nome":"'+nome+'"}');
  }

  getPlanosEmpresariais(){
    return this.http.get(this.apiPlanos+'&method=GET&filtro={"tipo":"PLANO EMPRESARIAL"}');
  }

  patchAssociado(id:any,dados:any){
    let body = JSON.stringify(dados);
    return this.http.get(this.apiAssociados+'&method=PATCH&id='+id+'&body='+body);
  }

  getConfigs(){
    return this.http.get(this.apiConfiguracoes+'&method=GET&sort={"_id":1}');
  }

  deleteFaturamento(id:any,idFaturamento:any){
    return this.http.get(this.apiFaturamento+'&method=DELETEFATURAMENTO&id='+id+'&idFaturamento='+idFaturamento);
   }

  deleteNomesNegociacao(id:any,nome:any){
    return this.http.get(this.apiConfiguracoes+'&method=DELETENOMENEGOCIACAO&id='+id+'&nome='+nome);
   }

  deleteTag(id:any,tag:any){
    return this.http.get(this.apiConfiguracoes+'&method=DELETETAG&id='+id+'&nome='+tag);
   }

  deleteBanco(id:any,banco:any){
    return this.http.get(this.apiConfiguracoes+'&method=DELETEBANCO&id='+id+'&nome='+banco);
   }
   
   postTags(id:any,dados:any){
    let body = JSON.stringify({"tags":dados});
    return this.http.get(this.apiConfiguracoes+'&method=POSTINSIDE&id='+id+'&body='+body);
   }

   postNomesNegociacao(id:any,dados:any){
    let body = JSON.stringify({"nomesNegociacao":dados});
    return this.http.get(this.apiConfiguracoes+'&method=POSTINSIDE&id='+id+'&body='+body);
   }

   postBanco(id:any,dados:any){
    let body = JSON.stringify({"bancos":dados});
    return this.http.get(this.apiConfiguracoes+'&method=POSTINSIDE&id='+id+'&body='+body);
   }

   patchConfiguracoes(id:any,dados:any){
    let body = JSON.stringify(dados);
    return this.http.get(this.apiConfiguracoes+'&method=PATCH&id='+id+'&body='+body)
  }

patchDependentes(id:any,dados:any){
  let body = JSON.stringify(dados);
  return this.http.get(this.apiAssociados+'&method=PATCH&id='+id+'&body='+body)
}

  getVendedores(){
    return this.http.get(this.apiUsuarios+'&method=GET&filtro={"$or":[{"tipo":"VENDEDOR"},{"tipo":"REPRESENTANTE PJ"}]}&sort={"nome":1}');
  }

  getAllUsuariosEscritorio(){
    return this.http.get(this.apiUsuarios+'&method=GET&filtro={"situacao":{"$not":{"$regex":"DEMITIDO","$options":"i"}},"tipo":{"$not":{"$regex":"VENDEDOR","$options":"i"}}}&sort={"nome":1}');
  }

  getAllUsuariosEscritorioProjection(array:any){
    return this.http.get(this.apiUsuarios+'&method=GET&filtro={"situacao":{"$not":{"$regex":"DEMITIDO","$options":"i"}},"tipo":{"$not":{"$regex":"VENDEDOR","$options":"i"}}}&sort={"nome":1}&project={'+array+'}');
  }

  getVendedoresAtivos(){
    return this.http.get(this.apiUsuarios+'&method=GET&filtro={"colaboradorComercial":true,"situacao":{"$not":{"$regex":"DEMITIDO","$options":"i"}}}&sort={"nome":1}');
  }

  getVendedoresAtivosProjection(array:any){
    return this.http.get(this.apiUsuarios+'&method=GET&filtro={"colaboradorComercial":true,"situacao":{"$not":{"$regex":"DEMITIDO","$options":"i"}}}&sort={"nome":1}&project={'+array+'}');
  }

  getLogsData(dtI,dtF){
    return this.http.get(this.apiLogs+'&method=GET&filtro={"data":{"$gte":"'+dtI+'","$lte":"'+dtF+'"}}&sort={"data":1,"hora":-1}');
  }

  getLogsDataMenu(dtI,dtF,menu){
    return this.http.get(this.apiLogs+'&method=GET&filtro={"data":{"$gte":"'+dtI+'","$lte":"'+dtF+'"},"menu":{"$regex":"'+menu+'","$options":"i"}}&sort={"data":1,"hora":-1}');
  }

  getLogsDataUsuario(dtI,dtF,user){
    return this.http.get(this.apiLogs+'&method=GET&filtro={"data":{"$gte":"'+dtI+'","$lte":"'+dtF+'"},"usuario":{"$regex":"'+user+'","$options":"i"}}&sort={"data":1,"hora":-1}');
  }

  postLogs(dados:any){
    let body = JSON.stringify(dados);
    return this.http.get(this.apiLogs+'&method=POST&body='+body);
  }

  atualizarCorretoraDep(id:any, cpfDependente:any, dados:any){
    let body = JSON.stringify(dados);
    return this.http.get(this.apiAssociados+'&method=PATCHCORRETORADEP&id='+id+'&cpfDependente='+cpfDependente+'&body='+body)
  }

  atualizarCorretoraDepExtra(id:any, cpfDependente:any, dados:any){
    let body = JSON.stringify(dados);
    return this.http.get(this.apiAssociados+'&method=PATCHCORRETORADEPEXTRA&id='+id+'&cpfDependente='+cpfDependente+'&body='+body)
  }

  cadastrarSinistro(id:any, tipoDependente:any, nomeDependente:any, dados:any){
    let body = JSON.stringify(dados);
    return this.http.get(this.apiAssociados+'&method=PATCHSINISTRO&id='+id+'&tipoDependente='+tipoDependente+'&nomeDependente='+nomeDependente+'&body='+body)
  }

  postHistoricoSinistro(id:any,dados:any){
    let body = JSON.stringify({"sinistro.historicos":dados});
    return this.http.get(this.apiAssociados+'&method=POSTINSIDE&id='+id+'&body='+body);
  }
  
  postHistoricoSinistroDependente(id:any,tipoDependente:any, nomeDependente:any,dados:any){
    let body = JSON.stringify(dados);
    return this.http.get(this.apiAssociados+'&method=POSTINSIDESINISTRO&id='+id+'&body='+body+'&tipoDependente='+tipoDependente+'&nomeDependente='+nomeDependente);
  }

  getAssociadosPendentes(){
    return this.http.get(this.apiAssociados+'&method=GET&filtro={"situacao":"PENDENTE"}&sort={"nome":1}');
  }
    
  getAssociadosByPlanos(valorPersonalizado:any,empresaPlano:any,tipoPlano:any,exceto:any,dataInicioPlano:any,dataFimPlano:any,cidadePlano:any,plano:any,excetoPlano:any,vendors:any,situacaoPlanos:any,sexo:any, taxaAdesao:any, subContrato:any, profissao:any,nascimento:any,motivoPendencia:any,motivoInadimplencia:any,mesPagamento:any,formaPagamento:any,codigoEmpresa:any){
    let nomePlano;
    let buscaPlano;
    let sitPlano;
    let relVendedores;
    let buscaSituacao;
    let buscaVendedores;
    let motivoInad = "";; 
    let motivoPen = "";;
    let mesPgto = "";;
    let codEmpresa = "";
    let sub = "";;
    let excecao = "";
    let adesao = "";
    let planoTipo = "";
    let planPers = valorPersonalizado == "TODOS" ? '' : '"valorPersonalizado":'+valorPersonalizado+',';
    if(codigoEmpresa != ''){codEmpresa = '"codigoEmpresa":"'+codigoEmpresa+'",';};
    if(mesPagamento != ''){mesPgto = '"mesFolhaPagamento":{"$regex":"'+mesPagamento+'","$options":"i"},';}
    if(motivoInadimplencia != ''){motivoInad = '"motivoInadimplencia":{"$regex":"'+motivoInadimplencia+'","$options":"i"},';}
    if(motivoPendencia != ''){motivoPen = '"motivoPendencia":{"$regex":"'+motivoPendencia+'","$options":"i"},';}
    if(subContrato != ''){sub = '"subContrato":"'+subContrato+'",';}
    if(plano && plano.length > 0){
      nomePlano = JSON.stringify(plano);      
      buscaPlano = '"plano":{"$in":'+nomePlano+'},';
    }else{
      buscaPlano = '"plano":{"$regex":"'+plano+'"},';
    }
    if(situacaoPlanos && situacaoPlanos.length > 0 && situacaoPlanos != "TODOS"){
      sitPlano = JSON.stringify(situacaoPlanos);
      buscaSituacao = '"situacao":{"$in":'+sitPlano+'},';
    }else{
      buscaSituacao = '"situacao":{"$regex":"'+situacaoPlanos+'"},';
    }
    if(vendors && vendors.length > 0 && vendors != "TODOS"){
      relVendedores = JSON.stringify(vendors);
      buscaVendedores = '"vendedor":{"$in":'+relVendedores+'},';
    }else{
      buscaVendedores = '"vendedor":{"$regex":"'+vendors+'"},';
    }
    if(exceto == "SIM"){excecao = '"plano":{"$not":{"$regex":"'+excetoPlano+'","$options":"i"}},';}
    if(taxaAdesao != null){adesao = '"taxaAdesao":'+taxaAdesao+',';}
    if(tipoPlano == "PLANO FISICO"){
      planoTipo = '"planoFisicoCheck":true,';
    }
    if(tipoPlano == "PLANO EMPRESARIAL"){
      planoTipo = '"planoEmpresarialCheck":true,';
    }
    if(tipoPlano == "PLANO SERVIDOR"){
      planoTipo = '"subContrato":{"$exists":true},"$and":[{"codigoEmpresa":null},{"codigoEmpresa":{"$exists":false}}],"$and":[{"$or":[{"planoEmpresarialCheck":false},{"planoEmpresarialCheck":null}]},{"$or":[{"planoFisicoCheck":false},{"planoFisicoCheck":null}]}],';
    }
    if(tipoPlano == "TODOS" || tipoPlano == null){
      planoTipo = "";
    }
    return this.http.get(this.apiAssociados+'&method=GET&filtro={'+planPers+buscaPlano+excecao+motivoInad+motivoPen+mesPgto+codEmpresa+excecao+adesao+planoTipo+sub+buscaSituacao+buscaVendedores+'"empresa":{"$regex":"'+empresaPlano+'","$options":"i"},"nascimento":{"$regex":"'+nascimento+'","$options":"i"},"dataAdesao":{"$gte":"'+dataInicioPlano+'","$lte":"'+dataFimPlano+'"},"municipio":{"$regex":"'+cidadePlano+'","$options":"i"},"sexo":{"$regex":"'+sexo+'","$options":"i"},"funcao":{"$regex":"'+profissao+'","$options":"i"},"formaPagamento":{"$regex":"'+formaPagamento+'","$options":"i"}}&sort={"nome":1}');
  }

  getAssociadosByDataAtivo(dtInicio:any,dtFim:any){
    return this.http.get(this.apiAssociados+'&method=GET&filtro={"data":{"$gte":"'+dtInicio+'","$lte":"'+dtFim+'"},"situacao":"ATIVO"}');
  }

  getAssociadosByDataAtivoAlterado(dtInicio:any,dtFim:any,vendedor:any){
    return this.http.get(this.apiAssociados+'&method=GET&filtro={"vendedor":{"$regex":"'+vendedor+'","$options":"i"},"data":{"$gte":"'+dtInicio+'","$lte":"'+dtFim+'"},"$or":[{"situacao":"ATIVO"},{"situacao":"ALTERAÇÃO"}]}');
  }

  getAssociadosByCidade(cidade:any){
    return this.http.get(this.apiAssociados+'&method=GET&filtro={"cidade":"'+cidade+'","situacao":"ATIVO"}');
  }

  getAssociadosByAdesaoAtivoAlterado(dtInicio:any,dtFim:any,vendedor:any){
    return this.http.get(this.apiAssociados+'&method=GET&filtro={"vendedor":{"$regex":"'+vendedor+'","$options":"i"},"dataAdesao":{"$gte":"'+dtInicio+'","$lte":"'+dtFim+'"},"$or":[{"situacao":"ATIVO"},{"situacao":"ALTERAÇÃO"}]}');
  }

  getAssociadosByAgendamento(dtInicio:any,dtFim:any,matricula:any,valor:any,medico:any,cidade:any,pagamento:any,paciente:any){
    if(matricula != null && valor == null){
      return this.http.get(this.apiAssociados+'&method=GET&filtro={"agendamentos.dataAgendamento":{"$gte":"'+dtInicio+'","$lte":"'+dtFim+'"},"agendamentos.pacienteMedico":{"$regex":"'+paciente+'","$options":"i"},"agendamentos.laboratorioMedico":{"$regex":"'+medico+'","$options":"i"},"agendamentos.formaPagamento":{"$regex":"'+pagamento+'","$options":"i"},"municipio":{"$regex":"'+cidade+'","$options":"i"},"matricula":'+matricula+'}');
    }
    if(valor != null && matricula == null){
      return this.http.get(this.apiAssociados+'&method=GET&filtro={"agendamentos.dataAgendamento":{"$gte":"'+dtInicio+'","$lte":"'+dtFim+'"},"agendamentos.pacienteMedico":{"$regex":"'+paciente+'","$options":"i"},"agendamentos.laboratorioMedico":{"$regex":"'+medico+'","$options":"i"},"agendamentos.formaPagamento":{"$regex":"'+pagamento+'","$options":"i"},"municipio":{"$regex":"'+cidade+'","$options":"i"},"agendamentos.valorConsulta":'+valor+'}');
    }
    if(matricula != null && valor != null){
      return this.http.get(this.apiAssociados+'&method=GET&filtro={"agendamentos.dataAgendamento":{"$gte":"'+dtInicio+'","$lte":"'+dtFim+'"},"agendamentos.pacienteMedico":{"$regex":"'+paciente+'","$options":"i"},"agendamentos.laboratorioMedico":{"$regex":"'+medico+'","$options":"i"},"agendamentos.formaPagamento":{"$regex":"'+pagamento+'","$options":"i"},"municipio":{"$regex":"'+cidade+'","$options":"i"},"agendamentos.valorConsulta":'+valor+',"matricula":'+matricula+'}');
    }
    if(matricula == null && valor == null){
      return this.http.get(this.apiAssociados+'&method=GET&filtro={"agendamentos.dataAgendamento":{"$gte":"'+dtInicio+'","$lte":"'+dtFim+'"},"agendamentos.pacienteMedico":{"$regex":"'+paciente+'","$options":"i"},"agendamentos.laboratorioMedico":{"$regex":"'+medico+'","$options":"i"},"agendamentos.formaPagamento":{"$regex":"'+pagamento+'","$options":"i"},"municipio":{"$regex":"'+cidade+'","$options":"i"}}');
    } 
  }

  getAssociadosByProcedimento(dtInicio:any,dtFim:any,matricula:any,valor:any,medico:any,cidade:any,pagamento:any,paciente:any){
    if(matricula != null && valor == null){
      return this.http.get(this.apiAssociados+'&method=GET&filtro={"agendamentos.agendadoParaMedico":{"$gte":"'+dtInicio+'","$lte":"'+dtFim+'"},"agendamentos.pacienteMedico":{"$regex":"'+paciente+'","$options":"i"},"agendamentos.laboratorioMedico":{"$regex":"'+medico+'","$options":"i"},"agendamentos.formaPagamento":{"$regex":"'+pagamento+'","$options":"i"},"municipio":{"$regex":"'+cidade+'","$options":"i"},"matricula":'+matricula+'}');
    }
    if(valor != null && matricula == null){
      return this.http.get(this.apiAssociados+'&method=GET&filtro={"agendamentos.agendadoParaMedico":{"$gte":"'+dtInicio+'","$lte":"'+dtFim+'"},"agendamentos.pacienteMedico":{"$regex":"'+paciente+'","$options":"i"},"agendamentos.laboratorioMedico":{"$regex":"'+medico+'","$options":"i"},"agendamentos.formaPagamento":{"$regex":"'+pagamento+'","$options":"i"},"municipio":{"$regex":"'+cidade+'","$options":"i"},"agendamentos.valorConsulta":'+valor+'}');
    }
    if(matricula != null && valor != null){
      return this.http.get(this.apiAssociados+'&method=GET&filtro={"agendamentos.agendadoParaMedico":{"$gte":"'+dtInicio+'","$lte":"'+dtFim+'"},"agendamentos.pacienteMedico":{"$regex":"'+paciente+'","$options":"i"},"agendamentos.laboratorioMedico":{"$regex":"'+medico+'","$options":"i"},"agendamentos.formaPagamento":{"$regex":"'+pagamento+'","$options":"i"},"municipio":{"$regex":"'+cidade+'","$options":"i"},"agendamentos.valorConsulta":'+valor+',"matricula":'+matricula+'}');
    }
    if(matricula == null && valor == null){
      return this.http.get(this.apiAssociados+'&method=GET&filtro={"agendamentos.agendadoParaMedico":{"$gte":"'+dtInicio+'","$lte":"'+dtFim+'"},"agendamentos.pacienteMedico":{"$regex":"'+paciente+'","$options":"i"},"agendamentos.laboratorioMedico":{"$regex":"'+medico+'","$options":"i"},"agendamentos.formaPagamento":{"$regex":"'+pagamento+'","$options":"i"},"municipio":{"$regex":"'+cidade+'","$options":"i"}}');
    } 
  }

  getAssociadosBySinistroTitular(dtInicio:any,dtFim:any,matricula:any,sinistrado:any,situacao:any){
    if(matricula){
      return this.http.get(this.apiAssociados+'&method=GET&filtro={"sinistro.dataSinistro":{"$gte":"'+dtInicio+'","$lte":"'+dtFim+'"},"nome":{"$regex":"'+sinistrado+'","$options":"i"},"sinistro.situacao":{"$regex":"'+situacao+'","$options":"i"},"matricula":'+matricula+'}');
    }else{
      return this.http.get(this.apiAssociados+'&method=GET&filtro={"sinistro.dataSinistro":{"$gte":"'+dtInicio+'","$lte":"'+dtFim+'"},"nome":{"$regex":"'+sinistrado+'","$options":"i"},"sinistro.situacao":{"$regex":"'+situacao+'","$options":"i"}}');
    }
  }

  getAssociadosBySinistroDependente(dtInicio:any,dtFim:any,matricula:any,sinistrado:any,situacao:any,tipo:any){
    if(matricula){
      return this.http.get(this.apiAssociados+'&method=GET&filtro={"dependentes.sinistro.dataSinistro":{"$gte":"'+dtInicio+'","$lte":"'+dtFim+'"},"dependentes.nome":{"$regex":"'+sinistrado+'","$options":"i"},"dependentes.sinistro.situacao":{"$regex":"'+situacao+'","$options":"i"},"dependentes.tipo":{"$regex":"'+tipo+'","$options":"i"},"matricula":'+matricula+'}');
    }else{
      return this.http.get(this.apiAssociados+'&method=GET&filtro={"dependentes.sinistro.dataSinistro":{"$gte":"'+dtInicio+'","$lte":"'+dtFim+'"},"dependentes.nome":{"$regex":"'+sinistrado+'","$options":"i"},"dependentes.sinistro.situacao":{"$regex":"'+situacao+'","$options":"i"},"dependentes.tipo":{"$regex":"'+tipo+'","$options":"i"}}');
    }
  }

  getAssociadosBySinistroDependenteExtra(dtInicio:any,dtFim:any,matricula:any,sinistrado:any,situacao:any,tipo:any){
     if(matricula){
      return this.http.get(this.apiAssociados+'&method=GET&filtro={"dependentesExtra.sinistro.dataSinistro":{"$gte":"'+dtInicio+'","$lte":"'+dtFim+'"},"dependentesExtra.nome":{"$regex":"'+sinistrado+'","$options":"i"},"dependentesExtra.sinistro.situacao":{"$regex":"'+situacao+'","$options":"i"},"dependentesExtra.tipo":{"$regex":"'+tipo+'","$options":"i"},"matricula":'+matricula+'}');
    }else{
      return this.http.get(this.apiAssociados+'&method=GET&filtro={"dependentesExtra.sinistro.dataSinistro":{"$gte":"'+dtInicio+'","$lte":"'+dtFim+'"},"dependentesExtra.nome":{"$regex":"'+sinistrado+'","$options":"i"},"dependentesExtra.sinistro.situacao":{"$regex":"'+situacao+'","$options":"i"},"dependentesExtra.tipo":{"$regex":"'+tipo+'","$options":"i"}}');
    }  
  }

  getAssociadosByDataPlano(dtInicio:any,dtFim:any,plano:any,tipoPlano:any){
    let tipos:any = "";
    if(tipoPlano == "PLANO FISICO"){
      tipos = '"planoFisicoCheck":true,"$or":[{"planoEmpresarialCheck":false},{"planoEmpresarialCheck":null}],';
    }
    if(tipoPlano == "PLANO SERVIDOR"){
      tipos = '"$or":[{"planoFisicoCheck":false},{"planoFisicoCheck":null}],"$or":[{"planoEmpresarialCheck":false},{"planoEmpresarialCheck":null}],';
    }
    if(tipoPlano == "PLANO EMPRESARIAL"){
      tipos = '"$or":[{"planoEmpresarialCheck":false},{"planoEmpresarialCheck":null}],"planoEmpresarialCheck":true,';
    }
    if(tipoPlano == ""){
      tipos = "";
    }
    if(plano && plano.length > 0){
      let planos = JSON.stringify(plano);      
      return this.http.get(this.apiAssociados+'&method=GET&filtro={'+tipos+'"dataAdesao":{"$gte":"'+dtInicio+'","$lte":"'+dtFim+'"},"plano":{"$in":'+planos+'},"$or":[{"situacao":{"$not":{"$regex":"CANCELADO","$options":"i"}}},{"situacao":"PENDENTE","motivoPendencia":"MIGRAÇÃO VIVA MAX NOVOS PRODUTOS"},{"situacao":"PENDENTE","motivoPendencia":"OUTROS"},{"situacao":"PENDENTE","motivoPendencia":"EXONERACAO/APOSENTADORIA"}]}');
    }else{
      return this.http.get(this.apiAssociados+'&method=GET&filtro={'+tipos+'"dataAdesao":{"$gte":"'+dtInicio+'","$lte":"'+dtFim+'"},"$or":[{"situacao":{"$not":{"$regex":"CANCELADO","$options":"i"}}},{"situacao":"PENDENTE","motivoPendencia":"MIGRAÇÃO VIVA MAX NOVOS PRODUTOS"},{"situacao":"PENDENTE","motivoPendencia":"OUTROS"},{"situacao":"PENDENTE","motivoPendencia":"EXONERACAO/APOSENTADORIA"}]}');
    }
  }

  getAssociadosAniversario(data:any){
    return this.http.get(this.apiAssociados+'&method=GET&filtro={"nascimento":{"$regex":"'+data+'"},"situacao":"ATIVO"}')
  }

  enviarDocUser(id:any,imagedata:any){
    let url = this.urlSite+'/upload-doc-user.php?id='+id;
    return this.http.post(url, imagedata);
  }

  enviarContrato(id:any,imagedata:any){
    let url = this.urlSite+'/upload-contrato.php?id='+id;
    return this.http.post(url, imagedata);
  }

  enviarSubContrato(id:any,imagedata:any){
    let url = this.urlSite+'/upload-subcontrato.php?id='+id;
    return this.http.post(url, imagedata);
  }

  enviarBoleto(id:any,imagedata:any){
    let url = this.urlSite+'/upload-boleto.php?id='+id;
    return this.http.post(url, imagedata);
  }

  enviarBoletoEmpresa(id:any,imagedata:any,rdm:any){
    let url = this.urlSite+'/upload-boleto-empresa.php?id='+id+'&random='+rdm;
    return this.http.post(url, imagedata);
  }

  enviarBoletoSubContrato(id:any,imagedata:any,rdm:any){
    let url = this.urlSite+'/upload-boleto-subcontrato.php?id='+id+'&random='+rdm;
    return this.http.post(url, imagedata);
  }

  enviarDocAssociado(id:any,imagedata:any){
    let url = this.urlSite+'/upload-doc-associado.php?id='+id;
    return this.http.post(url, imagedata);
  }

  enviarDocCancelamento(id:any,imagedata:any){
    let url = this.urlSite+'/upload-doc-cancelamento.php?id='+id;
    return this.http.post(url, imagedata);
  }

  enviarDocSinistro(id:any,imagedata:any,nomeDependente:any,tipoDependente:any){
    let url = this.urlSite+'/upload-doc-sinistro.php?id='+id+'&nome='+nomeDependente+'&tipoDependente='+tipoDependente;
    return this.http.post(url, imagedata);
  }

  enviarDocFinanceiro(id:any,imagedata:any){
    let url = this.urlSite+'/upload-doc-financeiro.php?id='+id;
    return this.http.post(url, imagedata);
  }

  enviarDocVendas(id:any,imagedata:any,idVenda:any){
    let url = this.urlSite+'/upload-doc-vendas.php?id='+id+'&idVenda='+idVenda;
    return this.http.post(url, imagedata);
  }

  enviarDocCredenciado(id:any,imagedata:any){
    let url = this.urlSite+'/upload-doc-credenciados.php?id='+id;
    return this.http.post(url, imagedata);
  }

  enviarDocEmpresa(id:any,imagedata:any){
    let url = this.urlSite+'/upload-doc-empresas.php?id='+id;
    return this.http.post(url, imagedata);
  }

  removeSinistroAssociadoDB(id:any, url:any, tipo:any, nomeDependente:any){
    return this.http.get(this.apiAssociados+'&method=DELETEDOCSINISTRO&id='+id+'&nome='+nomeDependente+'&tipo='+tipo+'&url='+url);
  }

  removeDoc(fullUrl){
    let path = fullUrl.split('br/')[1];
    let url = this.urlSite+'/delete-doc.php?path='+path;
    return this.http.post(url,{});
  }

  removeDocUsuarioDB(id:any, url:any){
    return this.http.get(this.apiUsuarios+'&method=DELETEDOC&id='+id+'&url='+url);
  }

  removeDocVendaDB(id:any, url:any,idVenda:any){
    return this.http.get(this.apiUsuarios+'&method=DELETEDOCVENDA&id='+id+'&url='+url+'&idVenda='+idVenda);
  }

  deleteVendaDB(id:any,idVenda:any){
    return this.http.get(this.apiUsuarios+'&method=DELETEVENDA&id='+id+'&idVenda='+idVenda);
  }

  removeDocSubContratoDB(id:any, url:any){
    return this.http.get(this.apiSubContratos+'&method=DELETEDOC&id='+id+'&url='+url);
  }

  removeDocAssociadoDB(id:any, url:any){
    return this.http.get(this.apiAssociados+'&method=DELETEDOC&id='+id+'&url='+url);
  }

  removeDocCancelamento(id:any, url:any){
    return this.http.get(this.apiAssociados+'&method=DELETEDOCCANCELAMENTO&id='+id+'&url='+url);
  }

  getAgendamentosTodos(){
    return this.http.get(this.apiAssociados+'&method=GET&filtro={"agendamentos.agendadoParaMedico":{"$exists":true,"$ne":[]}}')
  }

  getAgendamentosTodosProjection(array:any){
    return this.http.get(this.apiAssociados+'&method=GET&filtro={"agendamentos.agendadoParaMedico":{"$exists":true,"$ne":[]}}&project={'+array+'}')
  }

  getAgendamentos(data){
    return this.http.get(this.apiAssociados+'&method=GET&filtro={"agendamentos.agendadoParaMedico":"'+data+'"}')
  }

  getAgendamentosProjection(data:any,array:any){
    return this.http.get(this.apiAssociados+'&method=GET&filtro={"agendamentos.agendadoParaMedico":"'+data+'"}&project={'+array+'}')
  }
  
  getAssociadosCancelados(dataInicio:any,dataFim:any,matricula:any,motivo:any,subContrato:any,cidade:any,vendedor:any){
    let mat = "";
    if(matricula != null){
      mat = '"matricula":'+matricula+'';
    }
    return this.http.get(this.apiAssociados+'&method=GET&filtro={"situacao":"CANCELADO","dataCancelamento":{"$gte":"'+dataInicio+'","$lte":"'+dataFim+'"},"motivoCancelamento":{"$regex":"'+motivo+'","$options":"i"},"subContrato":{"$regex":"'+subContrato+'","$options":"i"},"municipio":{"$regex":"'+cidade+'","$options":"i"},"vendedor":{"$regex":"'+vendedor+'","$options":"i"}'+mat+'}')
  }

  deleteAgenda(id:any, idAgenda:any){
    return this.http.get(this.apiAssociados+'&method=DELETEAGENDA&id='+id+'&idAgenda='+idAgenda);
   }

/*
CODIGOS PARA CORRIGIR ERROS NOS DEPENDENTES
  getMulher(){
    return this.http.get(this.apiAssociados+'&method=GET&filtro={"dependentes.tipo":"CÔNJUGE"}')
   }

    patchConjuge(id,nome,matricula){
     return this.http.get(this.apiAssociados+'&method=PATCHCONJUGE&id='+id+'&nome='+nome+'&matricula='+matricula)
   } 

   getFilhos(){
    return this.http.get(this.apiAssociados+'&method=GET&filtro={"$or":[{"dependentes.tipo":"FILHO"},{"dependentes.tipo":"FILHA"}],"dependentes.asf":"FUNERAL AUTOMÁTICO"}')
   }

   patchFilhos(id,nome,matricula){
    return this.http.get(this.apiAssociados+'&method=PATCHFILHOS&id='+id+'&nome='+nome+'&matricula='+matricula)
  }
   */

  getMaiorIdade(dataLimite){
    return this.http.get(this.apiAssociados+'&method=GET&filtro={"$or":[{"dependentes.tipo":"FILHO"},{"dependentes.tipo":"FILHA"}],"dependentes.nascimento":{"$lt":"'+dataLimite+'"}}');
   }

   getMaiorIdadeProjection(dataLimite:any,array:any){
    return this.http.get(this.apiAssociados+'&method=GET&filtro={"$or":[{"dependentes.tipo":"FILHO"},{"dependentes.tipo":"FILHA"}],"dependentes.nascimento":{"$lt":"'+dataLimite+'"}}&project={'+array+'}');
   }

   patchFilhosMaior(id,nome,matricula){
    return this.http.get(this.apiAssociados+'&method=PATCHFILHOSMAIOR&id='+id+'&nome='+nome+'&matricula='+matricula);
  }

  newObservacao(id:any,dados:any){
    let body = JSON.stringify({"observacoes":dados});
    return this.http.get(this.apiSubContratos+'&method=POSTINSIDE&id='+id+'&body='+body);
  }

  getCredenciadosSearch(nome,esp,cidade){
    return this.http.get(this.apiCredenciados+'&method=GET&filtro={"fantasiaCredenciado":{"$regex":"'+nome+'","$options":"i"},"$or":[{"especialidade":{"$regex":"'+esp+'","$options":"i"}},{"profissionais.especialidade":{"$regex":"'+esp+'","$options":"i"}}],"municipio":{"$regex":"'+cidade+'","$options":"i"}}');
  }

  getEmpresasSearch(nome,cidade){
    return this.http.get(this.apiEmpresas+'&method=GET&filtro={"fantasiaEmpresa":{"$regex":"'+nome+'","$options":"i"},"municipio":{"$regex":"'+cidade+'","$options":"i"}}');
  }

  getCaixaVindi(){
    return this.http.get(this.apiCaixaVindi+'&method=GET');
  }

  getReceberVindi(){
    return this.http.get(this.apiReceberVindi+'&method=GET');
  }  

  getCobrancasPagas(dataInicio,dataFim,page){
    return this.http.get(this.apiVindi+'req=charges?query=status:paid AND paid_at >= '+dataInicio+' AND paid_at <= '+dataFim+'&sort_by=paid_at&per_page=50&page='+page);
  }

  getVindiByCpf(cpf:any){
    return this.http.get(this.apiVindi+'req=customers&query=registry_code:'+cpf+'&sort_by=name&per_page=50&page=1');
  }

  getVindiById(id:any){
    return this.http.get(this.apiVindi+'req=customers/'+id+'&sort_by=name&per_page=50&page=1');
  }

  getFaturasById(id:any){
    return this.http.get(this.apiVindi+'req=bills&query=customer_id:'+id+'%20AND%20status:paid&sort_by=status&per_page=50&page=1');
  }

  getFaturasByIdDate(id:any,data:any){
  return this.http.get(this.apiVindi+'req=bills&query=customer_id:'+id+'%20AND%20status:paid%20AND%20created_at<='+data+'&sort_by=status&per_page=50&page=1');
  }

  getAtendimentos(dataInicio,dataFim,motivo,empresa,plano,subcontrato){
    let mots = "";
    if(motivo && motivo.length > 0){
      if(motivo[0] != ""){
        let motivos = JSON.stringify(motivo);  
        mots = '"historicos.motivo":{"$in":'+motivos+'},';
      }
    }    
    return this.http.get(this.apiAssociados+'&method=GET&filtro={'+mots+'"localTrabalho":{"$regex":"'+empresa+'","$options":"i"},"plano":{"$regex":"'+plano+'","$options":"i"},"subContrato":{"$regex":"'+subcontrato+'","$options":"i"},"historicos.data":{"$gte":"'+dataInicio+'","$lte":"'+dataFim+'"}}');
  }

  sendEmail(email:any,assunto:any,corpo:any){
    return this.http.get( this.urlSite+'/sendEmail.php?email='+email+'&assunto='+assunto+'&corpo='+corpo);
  }

  enviarForm(id:any,imagedata:any){
    let url = this.urlSite+'/upload-formulario.php?id='+id;
    return this.http.post(url, imagedata);
  }

  postEvento(dados:any){
    let body = JSON.stringify({"agenda":dados});
    return this.http.get(this.apiUsuarios+'&method=POSTINSIDE&id='+dados.idUser+'&body='+body);
  }

  patchEvento(idUser:any,idAgenda:any,dados:any){
    let body = JSON.stringify(dados);
    return this.http.get(this.apiUsuarios+'&method=PATCHAGENDAVENDEDOR&id='+idUser+'&idAgenda='+idAgenda+'&body='+body);
  }

  postTarefa(dados:any){
    let body = JSON.stringify({"tarefas":dados});
    return this.http.get(this.apiUsuarios+'&method=POSTINSIDE&id='+dados.idResponsavel+'&body='+body);
  }

  patchTarefa(idUser:any,idTarefa:any,dados:any){
    let body = JSON.stringify(dados);
    return this.http.get(this.apiUsuarios+'&method=PATCHTAREFA&id='+idUser+'&idTarefa='+idTarefa+'&body='+body);
  }

  postAviso(id,dados:any){
    let body = JSON.stringify({"avisos":dados});
    return this.http.get(this.apiUsuarios+'&method=POSTINSIDE&id='+id+'&body='+body);
  }

  patchAviso(idUser:any,idAviso:any,dados:any){
    let body = JSON.stringify(dados);
    return this.http.get(this.apiUsuarios+'&method=PATCHAVISO&id='+idUser+'&idAviso='+idAviso+'&body='+body);
  }
  
  deleteTarefa(idUser:any,idTarefa:any){
    return this.http.get(this.apiUsuarios+'&method=DELTAREFAUSUARIO&id='+idUser+'&idTarefa='+idTarefa);
  }

  deleteEvento(idUser:any,idAgenda:any){
    return this.http.get(this.apiUsuarios+'&method=DELAGENDAVENDEDOR&id='+idUser+'&idAgenda='+idAgenda);
  }
  
  getAssociadosByCodigoEmpresa(codigo:any){
    return this.http.get(this.apiAssociados+'&method=GET&filtro={"codigoEmpresa":"'+codigo+'"}')
  }

  getAssociadosBySubContrato(nome:any){
    return this.http.get(this.apiAssociados+'&method=GET&filtro={"subContrato":"'+nome+'"}')
  }

  getCombinados(dtI:any,dtF:any,titular:any,tipo:any,subContrato:any,empresa:any,situacaoPlanosComb:any){
    let tipos = "";
    let sitPlano = "";
    let buscaSituacao = "";
    let subs = subContrato ? '"subContrato":"'+subContrato+'",' : '';
    let emps = empresa ? '"codigoEmpresa":"'+empresa+'",' : '';
    if(tipo == "FISICO"){
      tipos = '"planoFisicoCheck":true,"$or":[{"planoEmpresarialCheck":false},{"planoEmpresarialCheck":null}],'
    }
    if(tipo == "SERVIDOR"){
      tipos = '"$or":[{"planoFisicoCheck":false},{"planoFisicoCheck":null}],"$or":[{"planoEmpresarialCheck":false},{"planoEmpresarialCheck":null}],'
    }
    if(tipo == "EMPRESARIAL"){
      tipos = '"$or":[{"planoEmpresarialCheck":false},{"planoEmpresarialCheck":null}],"planoEmpresarialCheck":true,'
    }
    if(tipo == ""){
      tipos = ""
    }
    if(situacaoPlanosComb && situacaoPlanosComb.length > 0 && situacaoPlanosComb != "TODOS"){
      sitPlano = JSON.stringify(situacaoPlanosComb);
      buscaSituacao = '"situacao":{"$in":'+sitPlano+'},'
    }
    return this.http.get(this.apiAssociados+'&method=GET&filtro={'+tipos+buscaSituacao+subs+emps+'"dataAdesao":{"$gte":"'+dtI+'","$lte":"'+dtF+'"},"nome":{"$regex":"'+titular+'","$options":"i"}}')
  }

  patchMatricula(id:any,dados:any){
    let body = JSON.stringify(dados);
    return this.http.get(this.apiConfiguracoes+'&method=PATCH&id='+id+'&body='+body);
  }

  postFaturamento(dados:any){
    let body = JSON.stringify(dados);
    return this.http.get(this.apiFaturamento+'&method=POST&body='+body)
  }

  getFaturamentoByData(data:any){
    return this.http.get(this.apiFaturamento+'&method=GET&filtro={"data":"'+data+'"}')
  }

  getFaturamentoByDataFaturamento(data:any){
    return this.http.get(this.apiFaturamento+'&method=GET&filtro={"dataFaturamento":{"$lte":"'+data+'"}}')
  }

  patchFaturamentoValor(id,indice,campo,valor){
    return this.http.get(this.apiFaturamento+'&method=PATCHFATURAMENTOVALOR&id='+id+'&indice='+indice+'&campo='+campo+'&valor='+valor);
  }

  patchFaturamentoStatus(id:any,idFaturamento:any,status:any){
    return this.http.get(this.apiFaturamento+'&method=PATCHFATURAMENTOSTATUS&id='+id+'&idFaturamento='+idFaturamento+'&status='+status);
  }

  patchFaturamentoPlano(id:any,indice:any,plano:any){
    return this.http.get(this.apiFaturamento+'&method=PATCHFATURAMENTOPLANO&id='+id+'&indice='+indice+'&plano='+plano);
  }

  patchFaturamentoData(id:any,indice:any,data:any){
    return this.http.get(this.apiFaturamento+'&method=PATCHFATURAMENTODATA&id='+id+'&indice='+indice+'&data='+data);
  }

  patchFaturamentoAssociados(id:any,indice:any,dados:any,novoTotal:any,novaVida:any){
    let body = JSON.stringify(dados);
    return this.http.get(this.apiFaturamento+'&method=PATCHFATURAMENTOASSOCIADOS&id='+id+'&indice='+indice+'&body='+body+'&total='+novoTotal+'&vidasAtivas='+novaVida);
  }
  
  getFinanceiro(empresa:any){
    return this.http.get(this.apiFinanceiro+'&method=GET&filtro={"favorecido":"'+empresa+'","tipo":"Entrada"}');
  }

  getUsuariosByEmail(email:any){
    return this.http.get(this.apiUsuarios+'&method=GET&filtro={"email":"'+email+'"}');
  }

  getDadosCelesc(){
    return this.http.get(this.apiCelesc+'&method=GET');
  }

  patchCelesc(id,dados){
    let body = JSON.stringify(dados);
    return this.http.get(this.apiCelesc+'&method=PATCH&id='+id+'&body='+body);
  }

  sendRemessaCelesc(anexo){
    let url = this.urlSite+'/sendCelesc.php';
    return this.http.post(url,anexo);
  }

  getCargos(){
    return this.http.get(this.apiCargos+'&method=GET');
  }

  /* 
  envioSMS(nr:any,msg:any){
    let objRequest = {
      numero:nr,
      mensagem:msg
    }
    let body = JSON.stringify(objRequest);
    return this.http.get(this.apiSMS+body);
  }

  envioSMSMassa(arr:any,msg:any){
    let objRequest = {
      numeros:arr,
      mensagem:msg,
    }
    let body = JSON.stringify(objRequest);
    return this.http.get(this.apiSMSemMassa+body);
  } */
  
  envioPush(arn,msg,titulo){
    let objRequest = {
      tipo:"usuario",
      arn:arn,
      titulo:titulo,
      mensagem:msg
    }
    let body = JSON.stringify(objRequest);
    return this.http.get(this.apiPush+'?body='+body);
}

  envioPushMassa(msg,titulo){
    let dados = {
      tipo:"topico",
      arn:"",
      titulo:titulo,
      mensagem:msg
    }
    let body = JSON.stringify(dados);
    return this.http.get(this.apiPush+'?body='+body);
  }
  
  getAssociadoByPlanoMkt(plano:any){
    return this.http.get(this.apiAssociados+'&method=GET&filtro={"plano":"'+plano+'","$or":[{"situacao":"ATIVO"},{"situacao":"ALTERAÇÃO"}]}&sort={"nome":1}');
  }

  getAssociadoByOrigemEmpresaMkt(empresa:any){
    return this.http.get(this.apiAssociados+'&method=GET&filtro={"empresa":"'+empresa+'","$or":[{"situacao":"ATIVO"},{"situacao":"ALTERAÇÃO"}]}&sort={"nome":1}');
  }

  getAssociadoByEmpresaMkt(cod:any){
    return this.http.get(this.apiAssociados+'&method=GET&filtro={"codigoEmpresa":"'+cod+'","$or":[{"situacao":"ATIVO"},{"situacao":"ALTERAÇÃO"}]}&sort={"nome":1}');
  }
  
  getAssociadosByCidadeMkt(cidade:any){
    return this.http.get(this.apiAssociados+'&method=GET&filtro={"municipio":{"$regex":"'+cidade+'","$options":"i"},"$or":[{"situacao":"ATIVO"},{"situacao":"ALTERAÇÃO"}]}&sort={"nome":1}');
  }

  getAssociadosByAdesaoMkt(dtInicio:any,dtFim:any){
    return this.http.get(this.apiAssociados+'&method=GET&filtro={"dataAdesao":{"$gte":"'+dtInicio+'","$lte":"'+dtFim+'"},"$or":[{"situacao":"ATIVO"},{"situacao":"ALTERAÇÃO"}]}&sort={"nome":1}');
  }

  getAssociadoBySubContratooMkt(subContrato:any){
    return this.http.get(this.apiAssociados+'&method=GET&filtro={"subContrato":"'+subContrato+'","$or":[{"situacao":"ATIVO"},{"situacao":"ALTERAÇÃO"}]}&sort={"nome":1}');
  }

  getAssociadosByTipoPlanoMkt(tipoPlano:any){
    let consulta = "";
    if(tipoPlano == "TODOS" || tipoPlano == ""){
      consulta = "";
    }
    if(tipoPlano == "PLANO FISICO"){
      consulta = '"planoFisicoCheck":true,';
    }
    if(tipoPlano == "PLANO EMPRESARIAL"){
      consulta = '"planoEmpresarialCheck":true,';
    }
    if(tipoPlano == "PLANO SERVIDOR"){
      consulta = '"$or":[{"planoEmpresarialCheck":false},{"planoEmpresarialCheck":null}],"$or":[{"planoFisicoCheck":false},{"planoFisicoCheck":null}],';
    }
    return this.http.get(this.apiAssociados+'&method=GET&filtro={'+consulta+'"$or":[{"situacao":"ATIVO"},{"situacao":"ALTERAÇÃO"}]}&sort={"nome":1}');
  }

  enviarEmailBoasVindas(empresa:any,usuario:any,email:any){
     this.http.get(this.apiEmailBoasVindas+'?empresa='+JSON.stringify(empresa)+'&user='+JSON.stringify(usuario)+'&email='+JSON.stringify(email)).subscribe(res => console.log(res), err=> console.log(err))
  }

  getCanceladoInadimplenteCpf(cpf:any){
    return this.http.get(this.apiAssociados+'&method=GET&filtro={"cpf":"'+cpf+'","situacao":"CANCELADO","motivoCancelamento":"INADIMPLÊNCIA"}');
  }

  getAllUsuariosCanceladosProjection(array:any){
    return this.http.get(this.apiAssociados+'&method=GET&filtro={"$or":[{"situacao":"CANCELADO"},{"situacao":"ALTERAÇÃO"}]}&project={'+array+'}');
  }

  getTokenDoc(){
    return this.http.get(this.apiTokenDoc);
  }

  postDoc24(token:any,dados:any){
    let body = JSON.stringify(dados);
    return this.http.get(this.apiClienteDoc+'?token='+token+'&body='+body);
  }

  delDoc24(token:any,cpf:any){
    return this.http.get(this.apiDelDoc24+'?token='+token+'&cpf='+cpf);
  }

  patchVenda(id:any,dados:any){
    let body = JSON.stringify(dados);
    return this.http.get(this.apiVendas+'&method=PATCH&id='+id+'&body='+body);
  }
  
  cadastrarVenda(dados:any){
    let body = JSON.stringify(dados);
    return this.http.get(this.apiVendas+'&method=POST&body='+body);
  }

  getVendasByIdVendedor(idVendedor:any){
    return this.http.get(this.apiVendas+'&method=GET&filtro={"idVendedor":"'+idVendedor+'"}&sort={"data":-1}');
  }

  getVendas(dtInicio:any,dtFim:any,vendedor:any){
    return this.http.get(this.apiVendas+'&method=GET&filtro={"data":{"$gte":"'+dtInicio+'","$lte":"'+dtFim+'"},"vendedor":{"$regex":"'+vendedor+'","$options":"i"}}&sort={"data":-1}');
  }

}