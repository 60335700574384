import { Component, OnInit, ViewChild } from '@angular/core';
import { DataBaseService } from '../provider.service';
import { MASKS } from 'ng-brazil';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { AppComponent } from '../app.component';

@Component({
  selector: 'app-sub-contratos',
  templateUrl: './sub-contratos.component.html',
  styleUrls: ['./sub-contratos.component.css']
})

export class SubContratosComponent implements OnInit {
  @ViewChild('pdfViewerSubContrato') pdfViewerOnDemand;
  public MASKS = MASKS;
  subContratos:any = [];
  pdfs:any = [];
  images:any = [];
  docs:any = [];
  nome:any = "";
  cnpj:any = "";
  ie:any = "";
  telefone:any = "";
  rua:any = "";
  bairro:any = "";
  complemento:any = "";
  numero:any = "";
  cep:any = "";
  cidade:any = "";
  estado:any = "";
  regiao:any = "";
  regiaoEditar:any = "";
  nomeEditar:any = "";
  situacaoEditar:any = "";
  situacaoOriginal:any = "";
  cnpjEditar:any = "";
  ieEditar:any = "";
  telefoneEditar:any = "";
  ruaEditar:any = "";
  bairroEditar:any = "";
  complementoEditar:any = "";
  numeroEditar:any = "";
  cepEditar:any = "";
  cidadeEditar:any = "";
  estadoEditar:any = "";
  idSubContrato:any = "";
  contatos:FormGroup;
  contatosEditar:FormGroup;
  responsaveis:FormGroup;
  responsaveisEditar:FormGroup;
  observacoes:any = [];
  observacao:any = "";
  usuario:any = "";
  dataHoje:any = "";
  dataVencimento:any = "";
  dataVencimentoEditar:any = "";
  dataFaturamento:any = "";
  dataFaturamentoEditar:any = "";
  email:any = "";
  emailEditar:any = "";
  descontoEmpresa:number = 0;
  descontoEmpresaEditar:number = 0;
  checkConsignetEditar:boolean = false;
  checkConsignet:boolean = false;
  contrato:any = "";
  filesContrato:any = [];
  filesContratoEditar:any = [];
  selectedFilesContrato:any = "";
  selectedFilesContratoEditar:any = "";
  imagemSelecionada:any = "";
  indexImage:number = 0;

  public listaDeContatos:FormArray;
  get contatosFormGroup(){return this.contatos.get('contato') as FormArray;}
  public listaDeContatosEditar:FormArray;
  get contatosEditarFormGroup(){return this.contatosEditar.get('contatoEditar') as FormArray;}
  public listaResponsaveis:FormArray;
  get responsaveisFormGroup(){return this.responsaveis.get('responsaveis') as FormArray;}
  public listaResponsaveisEditar:FormArray;
  get responsaveisEditarFormGroup(){return this.responsaveisEditar.get('responsaveisEditar') as FormArray;}

  constructor(public db:DataBaseService, private formBuilder: FormBuilder, public app:AppComponent) { }

  ngOnInit(){
    this.usuario = localStorage.getItem('usuarioVivamax');
    this.dataHoje = new Date(Date.now() - 10800000).toISOString().split('T')[0];
    this.contatos = this.formBuilder.group({contato: this.formBuilder.array([this.createContato()])});
    this.contatosEditar = this.formBuilder.group({contatoEditar: this.formBuilder.array([])});
    this.listaDeContatos = this.contatos.get('contato') as FormArray;
    this.listaDeContatosEditar = this.contatosEditar.get('contatoEditar') as FormArray;
    this.responsaveis = this.formBuilder.group({responsaveis: this.formBuilder.array([this.createResponsavel()])});
    this.responsaveisEditar = this.formBuilder.group({responsaveisEditar: this.formBuilder.array([])});
    this.listaResponsaveis = this.responsaveis.get('responsaveis') as FormArray;
    this.listaResponsaveisEditar = this.responsaveisEditar.get('responsaveisEditar') as FormArray;
    this.db.getSubContratos().subscribe((data:any) => {
      this.subContratos = data;
    })
  }

  createContato():FormGroup{
    return this.formBuilder.group({
      nome: [null, Validators.compose([Validators.required])],
      nascimento:[null, Validators.compose([Validators.required])],
      cargo:[null, Validators.compose([Validators.required])],
    });
  }

  addContato(){
    this.listaDeContatos.push(this.createContato());
  }

  removeContato(index){
    this.listaDeContatos.removeAt(index);
  }

  createContatoEditar(item):FormGroup{
    return this.formBuilder.group({
      nome: item.nome,
      nascimento:item.nascimento,
      cargo:item.cargo,
    });
  }

  addContatoEditar(item){
    this.listaDeContatosEditar.push(this.createContatoEditar(item));
  }

  removeContatoEditar(index){
    this.listaDeContatosEditar.removeAt(index);
  }

  createResponsavel():FormGroup{
    return this.formBuilder.group({
      nome: [null, Validators.compose([Validators.required])]
    });
  }

  addResponsavel(){
    this.listaResponsaveis.push(this.createResponsavel());
  }

  removeResponsavel(index){
    this.listaResponsaveis.removeAt(index);
  }
  
  createResponsavelEditar(item):FormGroup{
    return this.formBuilder.group({
      nome: item.nome
    });
  }

  addResponsavelEditar(item){
    this.listaResponsaveisEditar.push(this.createResponsavelEditar(item));
  }

  removeResponsavelEditar(index){
    this.listaResponsaveisEditar.removeAt(index);
  }

  openModalNovo(){
    let modal = document.getElementById('modalNova');
    modal.style.display = "block";
    this.filesContrato = [];
  }

  fecharModalNovo(){
    this.checkConsignet = false;
    this.nome = "";
    this.telefone = "";
    this.cnpj = "";
    this.ie = "";
    this.cep = "";
    this.rua = "";
    this.numero = "";
    this.complemento = "";
    this.bairro = "";
    this.cidade = "";
    this.estado = "";
    this.dataVencimento = "";
    this.dataFaturamento = "";
    this.email = "";
    this.descontoEmpresa = 0;
    this.regiao = "";
    this.listaDeContatos.clear();
    this.addContato();
    this.listaResponsaveis.clear();
    this.addResponsavel();
    let modal = document.getElementById('modalNova');
    modal.style.display = "none";
  }

  cadastrarSubContrato(){
    if(window.confirm("Deseja realmente cadastrar esse Sub Contrato?")){
      let jsonDate = new Date().toJSON();
      let horaAgora = new Date(jsonDate).toLocaleTimeString();
      let idx = this.subContratos.map((s:any) => {
        return s.nome;
      }).indexOf(this.nome);
      if(idx < 0){
      let dados = {
        nome: this.nome,
        situacao:"ATIVO",
        telefone: this.telefone,
        cnpj:this.cnpj,
        ie:this.ie,
        cep:this.cep,
        rua:this.rua,
        numero:this.numero,
        complemento:this.complemento,
        bairro:this.bairro,
        cidade: this.cidade,
        estado: this.estado,
        regiao: this.regiao,
        dataVencimento: this.dataVencimento,
        dataFaturamento: this.dataFaturamento,
        email: this.email,
        desconto: Number(this.descontoEmpresa),
        observacoes:[],
        checkConsignet:this.checkConsignet,
        contatos: this.contatos.get('contato').value,
        responsaveis: this.responsaveis.get('responsaveis').value,
        documentos:[]
      }
      this.db.postSubContratos(dados).subscribe(res => {
        console.log(res);
        if(this.filesContrato.length > 0){
          const imagedata = new FormData();
          for(let i=0; i<this.selectedFilesContrato.length; i++){imagedata.append('files[]', this.selectedFilesContrato[i], this.selectedFilesContrato[i].name)};
           this.db.enviarSubContrato( res['insertedId'], imagedata).subscribe((res) => {
               console.log(res);
               this.app.openAlert("Sub Contrato cadastrado com Sucesso!");
               this.ngOnInit();
               this.fecharModalNovo();
             },(error) => {
               console.log(error);
               this.app.openAlert("Sub Contrato cadastrado com Sucesso!");
               this.ngOnInit();
               this.fecharModalNovo();
            });
        }
        let bodyLogs = {
          "menu" : "SUB CONTRATOS", 
          "descricao" : "Sub Contrato Cadastrado: "+this.nome, 
          "data" : this.dataHoje, 
          "hora" : horaAgora, 
          "usuario" : this.usuario
        }
        this.db.postLogs(bodyLogs).subscribe((res:any) => {
          console.log(res);
        },err => console.log(err))
      }, err => {
        console.log(err);
        this.app.openAlert("Erro ao cadastrar Sub Contrato, tente novamente!")
      })
    }else{
      this.app.openAlert("Já Existe um Sub Contrato com esse Nome, por favor altere o nome para prosseguir!")
    }
    }
  }

  editarSubContratos(){
    if(window.confirm("Deseja realmente editar esse Sub Contrato?")){
      let jsonDate = new Date().toJSON();
      let horaAgora = new Date(jsonDate).toLocaleTimeString();
      let dados = {
        nome: this.nomeEditar,
        situacao:this.situacaoEditar,
        telefone: this.telefoneEditar,
        cnpj:this.cnpjEditar,
        ie:this.ieEditar,
        cep:this.cepEditar,
        rua:this.ruaEditar,
        numero:this.numeroEditar,
        complemento:this.complementoEditar,
        bairro:this.bairroEditar,
        cidade: this.cidadeEditar,
        estado: this.estadoEditar,
        regiao: this.regiaoEditar,
        checkConsignet:this.checkConsignetEditar,
        dataVencimento: this.dataVencimentoEditar,
        dataFaturamento: this.dataFaturamentoEditar,
        desconto: Number(this.descontoEmpresaEditar),
        email: this.emailEditar,
        contatos:this.contatosEditar.get('contatoEditar').value,
        responsaveis:this.responsaveisEditar.get('responsaveisEditar').value
      }
      this.db.patchSubContratos(this.idSubContrato,dados).subscribe(res => {
        console.log(res);
        if(this.situacaoOriginal == "ATIVO" && this.situacaoEditar != "ATIVO"){
          this.db.getAssociadosBySubContrato(this.nomeEditar).subscribe((dataA:any) => {
            if(dataA){
              let obj:any;
              if(this.situacaoEditar == "CANCELADO"){
                obj = {
                  situacao: this.situacaoEditar,
                  dataCancelamento: this.dataHoje,
                  motivoCancelamento: "SUB CONTRATO CANCELADO"
                }
              }else{
                obj = {
                  situacao: this.situacaoEditar
                }
              }
              let ctrl = 1;
              dataA.forEach((aS:any) => {
                if(aS.situacao != "CANCELADO"){
                  this.db.patchAssociado(aS._id,obj).subscribe(res => console.log(res),err => console.log(err));
                  if(dataA.length == ctrl){
                    let bodyLogs = {
                      "menu" : "SUB CONTRATOS", 
                      "descricao" : "Sub Contrato Editado: "+this.nomeEditar, 
                      "data" : this.dataHoje, 
                      "hora" : horaAgora, 
                      "usuario" : this.usuario
                    }
                    this.db.postLogs(bodyLogs).subscribe((res:any) => {
                      console.log(res);
                    this.app.openAlert("Sub Contrato editado com Sucesso!");
                    this.ngOnInit();
                    this.fecharModalEditar();
                    })
                  }else{
                    ctrl++;
                  }
                }
              })
            }
          })
        }else if(this.situacaoOriginal != "ATIVO" && this.situacaoEditar == "ATIVO"){
          this.db.getAssociadosBySubContrato(this.nomeEditar).subscribe((dataA:any) => {
            if(dataA){
              let obj:any;
              if(this.situacaoOriginal == "CANCELADO"){
                obj = {
                  situacao: this.situacaoEditar,
                  dataCancelamento: "",
                  motivoCancelamento: ""
                }
              }else{
                obj = {
                  situacao: this.situacaoEditar
                }
              }
              let ctrl = 1;
              dataA.forEach((aS:any) => {
                if(aS.situacao != "CANCELADO"){
                  this.db.patchAssociado(aS._id,obj).subscribe(res => console.log(res),err => console.log(err));
                  if(dataA.length == ctrl){
                    let bodyLogs = {
                      "menu" : "SUB CONTRATOS", 
                      "descricao" : "Sub Contrato Editado: "+this.nomeEditar, 
                      "data" : this.dataHoje, 
                      "hora" : horaAgora, 
                      "usuario" : this.usuario
                    }
                  this.db.postLogs(bodyLogs).subscribe((res:any) => {
                    console.log(res);
                    this.app.openAlert("Sub Contrato editado com Sucesso!");
                    this.ngOnInit();
                    this.fecharModalEditar();
                  });
                  }else{
                    ctrl++;
                  }
                }
              })
            }
          })
        }else{
          let bodyLogs = {
            "menu" : "SUB CONTRATOS", 
            "descricao" : "Sub Contrato Editado: "+this.nomeEditar, 
            "data" : this.dataHoje, 
            "hora" : horaAgora, 
            "usuario" : this.usuario
          }
          this.db.postLogs(bodyLogs).subscribe((res:any) => {
            console.log(res);
            this.app.openAlert("Sub Contrato editado com Sucesso!");
            this.ngOnInit();
            this.fecharModalEditar();
          },(err:any) => console.log(err))
        }
      }, err => {
        console.log(err);
        this.app.openAlert("Erro ao editar Sub Contrato, tente novamente!")
      })
    }
  }

  openModalEditar(sub){
      let modal = document.getElementById('modalEditar');
      modal.style.display = "block";
      if(sub.observacoes && sub.observacoes.length > 0){
        sub.observacoes.map((s:any) => {
          return this.reverseReplaceStrings(s.observacao);
        })
      }
      this.nomeEditar = sub.nome;
      this.situacaoEditar = sub.situacao;
      this.situacaoOriginal = sub.situacao;
      this.telefoneEditar = sub.telefone;
      this.cidadeEditar = sub.cidade;
      this.estadoEditar = sub.estado;
      this.descontoEmpresaEditar = sub.desconto;
      this.idSubContrato = sub._id;
      this.cnpjEditar = sub.cnpj;
      this.regiaoEditar = sub.regiao;
      this.observacoes = sub.observacoes.sort((a:any,b:any) => {if(a.data < b.data){return 1}if(a.data > b.data){return -1}if(a.hora < b.hora){return 1}if(a.hora > b.hora){return -1}});
      this.ieEditar = sub.ie;
      this.ruaEditar = sub.rua;
      this.bairroEditar = sub.bairro;
      this.complementoEditar = sub.complemento;
      this.numeroEditar = sub.numero;
      this.cepEditar = sub.cep;
      this.dataVencimentoEditar = sub.dataVencimento;
      this.dataFaturamentoEditar = sub.dataFaturamento;
      this.emailEditar = sub.email;
      this.checkConsignetEditar = sub.checkConsignet;
      this.filesContratoEditar = [];
      if(sub.contatos && sub.contatos.length > 0){
        sub.contatos.forEach((item) => {
        this.addContatoEditar(item)
        })
      }else{
        this.addContatoEditar("")
      }
      if(sub.responsaveis && sub.responsaveis.length > 0){
        sub.responsaveis.forEach((item) => {
        this.addResponsavelEditar(item)
        })
      }else{
        this.addResponsavelEditar("")
      }
      this.docs = [];
      this.pdfs = [];
      this.images = [];
      this.docs = sub.documentos;
      if(this.docs && this.docs.length > 0){
        this.docs.forEach((item) => {
         let ext = item.url.split('.').pop();
          if(ext == 'pdf' || ext == 'PDF'){
            this.pdfs.push(item);
          }else{
            this.images.push(item);
          }
        })
      }
  }

  fecharModalEditar(){
    let modal = document.getElementById('modalEditar');
    modal.style.display = "none";
    this.nomeEditar = "";
    this.telefoneEditar = "";
    this.observacao = "";
    this.situacaoEditar = "";
    this.descontoEmpresaEditar = 0;
    this.observacoes = [];
    this.cidadeEditar = "";
    this.regiaoEditar = "";
    this.estadoEditar = "";
    this.idSubContrato = "";
    this.cnpjEditar = "";
    this.ieEditar = "";
    this.ruaEditar = "";
    this.bairroEditar = "";
    this.complementoEditar = "";
    this.numeroEditar = "";
    this.cepEditar = "";
    this.dataFaturamentoEditar = "";
    this.dataVencimentoEditar = "";
    this.emailEditar = "";
    this.checkConsignetEditar = false;
    this.docs = [];
    this.pdfs = [];
    this.images = [];
    this.listaDeContatosEditar.clear();
  }

  excluirSubContratos(id){
    if(window.confirm("Deseja realmente excluir esse Sub Contrato?")){
      this.db.deleteSubContratos(id).subscribe(res => {
        console.log(res);
        this.app.openAlert("Sub Contrato excluido com Sucesso!");
        this.ngOnInit();
      }, err => {
        console.log(err);
        this.app.openAlert("Erro ao excluir Sub Contrato, tente novamente!")
      })
    }
  }

  buscarCep(cepGet){
    let c = cepGet.replace(/_/gi,'').replace('.','').replace('-','');
    if(c.length > 7){
      this.db.getCep(c).subscribe((data:any) => {
        let cep = data;
         if(cep.erro == true){
           this.app.openAlert("CEP NAO ENCONTRADO");
         }else{
           this.rua = cep.logradouro.toUpperCase();
           this.bairro = cep.bairro.toUpperCase();
           this.cidade = cep.localidade.toUpperCase();
           this.estado = cep.uf.toUpperCase();
         }
      });
    }
  }

  buscarCepEditar(cepGet){
    let c = cepGet.replace(/_/gi,'').replace('.','').replace('-','');
    if(c.length > 7){
      this.db.getCep(c).subscribe((data:any) => {
        let cep = data;
         if(cep.erro == true){
           this.app.openAlert("CEP NAO ENCONTRADO");
         }else{
           this.ruaEditar = cep.logradouro.toUpperCase();
           this.bairroEditar = cep.bairro.toUpperCase();
           this.cidadeEditar = cep.localidade.toUpperCase();
           this.estadoEditar = cep.uf.toUpperCase();
         }
      });
    }
  }

  cadastrarObservacao(){
    let jsonDate = new Date().toJSON();
    let hora = new Date(jsonDate).toLocaleTimeString();
    let obj = {
      data:this.dataHoje,
      hora:hora,
      usuario:this.usuario,
      observacao:this.replaceStrings(this.observacao)
    };
    this.db.newObservacao(this.idSubContrato,obj).subscribe(res => {
      console.log(res);
      obj.observacao = this.observacao
      this.observacoes.unshift(obj);
      this.observacao = "";
    }, err => {
      console.log(err);
    })
  }

  replaceStrings(string:any){
    if(!string){return}
    return string.replace(/\%/gi,'%25').replace(/\+/gi,'%2B').replace(/\!/gi,'%21').replace(/\&/gi,'%26').replace(/\;/gi,'%3B');
  }

  reverseReplaceStrings(string:any){
    if(!string){return}
    return string.replace(/%2B/gi,'+').replace(/%21/gi,'!').replace(/%25/gi,'%').replace(/%26/gi,'&').replace(/%3B/gi,';');
  }

  calcularFaturamento(dataVencimento){
    this.dataFaturamento = moment(dataVencimento).subtract(10,'days').toISOString().split('T')[0];
  }

  calcularFaturamentoEditar(dataVencimento){
    this.dataFaturamentoEditar = moment(dataVencimento).subtract(10,'days').toISOString().split('T')[0];
  }

  mudarSituacao(situacao:any){
    if(this.situacaoOriginal == "ATIVO" && situacao != "ATIVO"){
      this.app.openAlert("ATENÇÃO! AO ALTERAR A SITUAÇÃO DO SUB CONTRATO TODOS OS ASSOCIADOS DESTE SUB CONTRATO TERÃO A MESMA SITUAÇÃO ATRIBUIDA!")
    }
  }

    onChangeContrato(event){
      let arrayTypes = ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'];
      this.selectedFilesContrato = <FileList>event;
    for(let i=0; i<this.selectedFilesContrato.length; i++){
        if(!arrayTypes.includes(this.selectedFilesContrato[i].type)){
          this.app.openAlert("Tipo de Arquivo Não Suportado!\nEscolha um documento em PDF")
          this.selectedFilesContrato = "";
      }} 
      this.filesContrato = [];
      for(let i=0; i<this.selectedFilesContrato.length; i++){
        this.filesContrato.push(this.selectedFilesContrato[i].name);
      }
  }
  
  onChangeClickContrato(event){
    let arrayTypes = ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'];
    this.selectedFilesContrato = <FileList>event.srcElement.files;
    for(let i=0; i<this.selectedFilesContrato.length; i++){
        if(!arrayTypes.includes(this.selectedFilesContrato[i].type)){
        this.app.openAlert("Tipo de Arquivo Não Suportado!\nEscolha um documento em PDF")
        this.selectedFilesContrato = "";
      }} 
      this.filesContrato = [];
      for(let i=0; i<this.selectedFilesContrato.length; i++){
        this.filesContrato.push(this.selectedFilesContrato[i].name);
      }
  }  

  onChangeContratoEditar(event){
    let arrayTypes = ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'];
    this.selectedFilesContratoEditar = <FileList>event;
    for(let i=0; i<this.selectedFilesContratoEditar.length; i++){
        if(!arrayTypes.includes(this.selectedFilesContratoEditar[i].type)){
          this.app.openAlert("Tipo de Arquivo Não Suportado!\nEscolha um documento em PDF")
          this.selectedFilesContratoEditar = "";
      }} 
      this.filesContratoEditar = [];
      for(let i=0; i<this.selectedFilesContratoEditar.length; i++){
        this.filesContratoEditar.push(this.selectedFilesContratoEditar[i].name);
      }
  }
  
  onChangeClickContratoEditar(event){
    let arrayTypes = ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'];
    this.selectedFilesContratoEditar = <FileList>event.srcElement.files;
    for(let i=0; i<this.selectedFilesContratoEditar.length; i++){
        if(!arrayTypes.includes(this.selectedFilesContratoEditar[i].type)){
        this.app.openAlert("Tipo de Arquivo Não Suportado!\nEscolha um documento em PDF")
        this.selectedFilesContratoEditar = "";
      }} 
      this.filesContratoEditar = [];
      for(let i=0; i<this.selectedFilesContratoEditar.length; i++){
        this.filesContratoEditar.push(this.selectedFilesContratoEditar[i].name);
      }
  }
  
  uploadFileContrato(){
    if(this.contrato != ''){
      this.db.removeDoc(this.contrato).subscribe(res => {
        console.log(res), err => console.log(err);
      })
    }
    const imagedata = new FormData();
    for(let i=0; i<this.selectedFilesContratoEditar.length; i++){imagedata.append('files[]', this.selectedFilesContratoEditar[i], this.selectedFilesContratoEditar[i].name)};
     this.db.enviarSubContrato(this.idSubContrato, imagedata).subscribe((res) => {
         console.log(res);
         this.app.openAlert("Contrato Enviado Com Sucesso!");
         this.getDocsSubContrato();
       },(error) => {
         console.log(error);
         this.app.openAlert("Contrato Enviado Com Sucesso!");
         this.getDocsSubContrato();
       });
   }
   
  getDocsSubContrato(){
    this.db.getSubContratosById(this.idSubContrato).subscribe((result:any) => {
      this.docs = result.documentos;
      this.pdfs = [];
      this.images = [];
      if(this.docs && this.docs.length > 0){
        this.docs.forEach((item) => {
        let ext = item.url.split('.').pop();
          if(ext == 'pdf' || ext == 'PDF'){
            this.pdfs.push(item);
          }else{
            this.images.push(item);
          }
        })
      }
    })
  }

  delDoc(url){
    if(window.confirm('Deseja Realmente Deletar esse Documento?')){
      let jsonDate = new Date().toJSON();
      let horaAgora = new Date(jsonDate).toLocaleTimeString();
      let bodyLogs = {
        "menu" : "SUB CONTRATOS", 
        "descricao" : "Documento Excluido: "+this.nomeEditar, 
        "data" : this.dataHoje, 
        "hora" : horaAgora, 
        "usuario" : this.usuario
      }
      this.db.removeDoc(url).subscribe(res => {
        console.log(res);
        this.db.removeDocSubContratoDB(this.idSubContrato,url).subscribe(result => {
          console.log(result);         
            this.db.postLogs(bodyLogs).subscribe(res => {
              console.log(res);
              this.atualizarDocsDel(url);
            },error => console.log(error))
        }, error => {
          console.log(error);
        })
       },err => {
         console.log(err);
          this.db.removeDocSubContratoDB(this.idSubContrato,url).subscribe(result => {
          console.log(result);
          this.db.postLogs(bodyLogs).subscribe(res => {
            console.log(res);
            this.atualizarDocsDel(url);
          },error => console.log(error))
        }, error => {
          console.log(error);
        })
      })
   }
 }

 
atualizarDocsDel(url){
  this.pdfs = [];
  this.images = [];
  let idx = this.docs.map((item) => {
    return item.url
  }).indexOf(url)
  if(idx >= 0){
    this.docs.splice(idx,1);
    if(this.docs && this.docs.length > 0){
        this.docs.forEach((item) => {
        let ext = item.url.split('.').pop();
          if(ext == 'pdf' || ext == 'PDF'){
            this.pdfs.push(item);
          }else{
            this.images.push(item);
          }
        })
      }
    }
  }

  openModalPdfViewer(url){
    this.pdfViewerOnDemand.pdfSrc = url;
    this.pdfViewerOnDemand.refresh();
  }

  
  openModalLightBox(url, i){
    console.log(url)
    this.indexImage = i;
    this.imagemSelecionada = url;
    let modal = document.getElementById('myModal')
    modal.style.display = "block";
  }
  
  nextImg(){
    let i = this.indexImage
    if((this.images.length - 1) == i){
      this.imagemSelecionada = this.images[0].url;
      this.indexImage = 0;
    }else{
      i++
      this.imagemSelecionada = this.images[i].url;
      this.indexImage++
    }
  }
  
  prevImg(){
    let i = this.indexImage
    if(i == 0){
      this.imagemSelecionada = this.images[this.images.length - 1].url;
      this.indexImage = this.images.length - 1;
    }else{
      i--
      this.imagemSelecionada = this.images[i].url;
      this.indexImage--
    }
  }
  
  closeModal(){
    let modal = document.getElementById('myModal')
    modal.style.display = "none";
  }


}
