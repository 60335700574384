import { Component, OnInit } from '@angular/core';
import { AuthService } from './auth.service';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { DataBaseService } from './provider.service';
import * as moment from 'moment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit{
  url:any = "";
  dataHoje:any = "";
  idDataAniversario:any = "";
  contasPagarHoje:number = 0;
  agendamentosHoje:number = 0;
  contasReceberHoje:number = 0;
  faturamentoHoje:number = 0;
  avisosArray:any = [];
  arrayTarefas:any = [];
  arrayAvisos:any = [];
  tarefasPendentes:number = 0;
  tarefasUsuarioPendente:number = 0;
  nomeUsuario:any = "";
  screenSelect:boolean = false;
  mensagem:any = "";
  accord = 'accord';

  constructor(public router:Router, public auth:AuthService, public db:DataBaseService){}
  
ngOnInit(){
    if(localStorage.getItem('tokenVivamax')){
      this.nomeUsuario = localStorage.getItem('usuarioVivamax');
      this.dataHoje = new Date(Date.now() - 10800000).toISOString().split('T')[0];
        this.db.getConfigs().subscribe((data:any) => {
        let ultimaDataAniversario = data[3].dataAniversario.split('/').reverse().join('-');
        this.idDataAniversario = data[3]._id;
        this.avisosArray = data[15];
        if(ultimaDataAniversario < this.dataHoje){
          this.checkAniversarios();
        }
        this.verificarAlertas();
        this.verificarAvisos();
        this.navEvents();
      });
  }else{
    this.auth.logOut()
  }
}
/* 
criarTarefaSorteio(){
    let randomGeraLink = Math.random().toString(36).slice(-8);
    let dataHoje = new Date(Date.now() - 10800000).toISOString().split('T')[0];
    let jsonDate = new Date().toJSON();
    let hora = new Date(jsonDate).toLocaleTimeString();
    let dados = {
    "id" : randomGeraLink,
    "data" : dataHoje,
    "hora" : hora,
    "titulo" : "SORTEIO DO MÊS",
    "descricao" : "Atualizar o número sorteado do mês em CONFIGURAÇÔES - SORTEADO DO MÊS",
    "idResponsavel" : "5fd912d0bd32b1a715b54936",
    "responsavel" :  "ANA MINIUK",
    "prioridade" : "ALTA",
    "resolvido" : false,
    "dataResolucao" : "",
    "horaResolucao" : "",
    "observacao":"GERADO PELO SISTEMA",
    "idUserSolicitacao":"5fbf09cc9e7d804442ceddcf",
    "nomeSolicitante":"PAULO GUERRA",
    "origem":"Sistema"
    }
    this.db.postTarefa(dados).subscribe(res => {
      console.log(res);
      let oLogs = {
        "menu" : "TAREFAS", 
        "descricao" : "Tarefa Criada: ATUALIZAR NÚMERO SORTEIO DO MÊS", 
        "data" : this.dataHoje, 
        "hora" : hora, 
        "usuario" : "SISTEMA"
      }
      this.db.postLogs(oLogs).subscribe(ress => {
        console.log(ress);
      }, errr => console.log(errr))
    },err => console.log(err))
} */

private navEvents() {
  this.router.events.pipe(filter(e => e instanceof NavigationEnd)).subscribe((e: any) => {
    this.url = e.url
  });
}

goTo(local:any){ 
  this.router.navigate(['/'+local]);
  this.closeMenu();
}

openMenu(){
  document.getElementById("mySidenav").style.width = "auto";
}

closeMenu(){
  document.getElementById("mySidenav").style.width = "0";
}

checkAniversarios(){
  let jsonDate = new Date().toJSON();
  let hora = new Date(jsonDate).toLocaleTimeString();
  this.db.getAssociadosProjection('"dependentes":1,"nome":1,"matricula":1').subscribe((data:any) => {
    let todosAssociados = data;
    let controlAssociados = 1;
    todosAssociados.forEach((item:any) => {
      let controlDependentes = 1;
      if(item.dependentes.length > 0){
        let mudou = false;
        item.dependentes.forEach((dependente) => {
          if((dependente.tipo == "FILHO" || dependente.tipo == "FILHA") && dependente.asf == "FUNERAL AUTOMÁTICO"){
            if(this.verificaIdade(dependente.nascimento) == true){
              mudou = true;
              dependente.asf = "NÃO HÁ FUNERAL CONTRATADO";
            }
          }
          if(item.dependentes.length == controlDependentes){
            if(mudou == true){
              let corpo = {
                "dependentes" : item.dependentes
              }
              this.db.patchDependentes(item._id, corpo).subscribe(result => {
                console.log(result);
                let body = {
                  "menu" : "INICIO", 
                  "descricao" : "Atualização Automática do Titular: "+item.nome+" - Matricula: "+item.matricula+" referente a maioridade do Dependentes: "+dependente.nome+", alterando de FUNERAL AUTOMÁTICO ATÉ 18 ANOS para NÃO HÁ FUNERAL CONTRATADO", 
                  "data" : this.dataHoje, 
                  "hora" : hora, 
                  "usuario" : "SISTEMA"
                }
                this.db.postLogs(body).subscribe(res => {
                  console.log(res);
                }, err => {
                  console.log(err)
                })
              })
            }
          }else{
            controlDependentes++
          }
        });
    }
      if(controlAssociados == todosAssociados.length){
        let dados = {
          dataAniversario : this.dataHoje
        }
        this.db.patchConfiguracoes(this.idDataAniversario, dados).subscribe(res => {
          console.log(res);
        }, err => {
          console.log(err)
        })
      }else{
        controlAssociados++
      }
    })
  })
}

verificaIdade(e){
  if(e){
    var d = new Date;
    let dt = e.split('/').reverse().join('-');
    let data = new Date(dt.split('-')[0],dt.split('-')[1]-1,dt.split('-')[2])
    let ano_atual = d.getFullYear();
    let mes_atual = d.getMonth() + 1;
    let dia_atual = d.getDate();
    let ano_aniversario = data.getFullYear();;
    let mes_aniversario = data.getMonth() + 1;
    let dia_aniversario = data.getDate();
    let idade = ano_atual - ano_aniversario;
    if(mes_atual < mes_aniversario || mes_atual == mes_aniversario && dia_atual < dia_aniversario){
        idade--;
    }
    if(idade >= 18){
      return true;
    }else{
      return false;
    }
  }else{
    return false;
  }
}

verificarAlertas(){
  this.arrayTarefas = [];
  this.db.getAllUsuariosEscritorioProjection('"tarefas":1').subscribe((usr:any) => {
    let ctrlTask = 1;
    usr.forEach((u:any) => {
      if(u.tarefas.length > 0){
        u.tarefas.forEach((t) => {
          if(t.resolvido == false){
            this.tarefasPendentes++;
            if(t.idResponsavel == localStorage.getItem('idUserVivamax')){
              this.tarefasUsuarioPendente++;
              this.arrayTarefas.push(t)
            };
          };
        });
      };
      if(usr.length == ctrlTask){
        if(this.tarefasPendentes > 0){
          this.showAlertaTarefasGeral();
        }
        if(this.tarefasUsuarioPendente > 0){
          this.showAlertaTarefas(this.arrayTarefas);
        }
      }else{
        ctrlTask++
      }
    });
    this.db.getAgendamentosProjection(this.dataHoje,'"agendamentos":1').subscribe((dataAgendamentos:any) => {
      let totalAgendamentos:number = 0;
      let ctrl:number = 1;
      let ctrlFat:number = 1;
      let fatHoje:number = 0;
      dataAgendamentos.forEach((uA:any) => {
        uA.agendamentos.forEach((a:any) => {
          if(a.agendadoParaMedico == this.dataHoje){
            totalAgendamentos++
          }
        })
        if(dataAgendamentos.length == ctrl){
          this.agendamentosHoje = totalAgendamentos;
        }else{
          ctrl++
        }
      })
      if(this.agendamentosHoje > 0){this.showAlertaAgendamentos()};
      this.db.getContasPagarProjection("2000-01-01",this.dataHoje,'"_id":1').subscribe((dataContasPagar:any) => {
        this.contasPagarHoje = dataContasPagar.length > 0 ? dataContasPagar.length : 0;
        if(this.contasPagarHoje > 0){this.showAlertaContasPagar()};
        this.db.getContasReceberProjection("2000-01-01",this.dataHoje,'"_id":1').subscribe((dataContasReceber:any) => {
          this.contasReceberHoje = dataContasReceber.length > 0 ? dataContasReceber.length : 0;
          if(this.contasReceberHoje > 0){this.showAlertaContasReceber()};
          let dataFat = "";
         if(Number(this.dataHoje.split('-')[2]) < 20){
          dataFat = moment(this.dataHoje).subtract(1,'months').date(20).format().split('T')[0];
          }else{
            dataFat = moment(this.dataHoje).date(20).format().split('T')[0];
          }
            this.db.getFaturamentoByData(dataFat).subscribe((data:any) => {
              if(data && data[0].faturamentos.length > 0){
                data[0].faturamentos.forEach((f) => {
                  if(moment(f.dataFaturamento) <= moment(this.dataHoje) && f.status != "FATURADO"){
                     fatHoje++;
                  }
                  if(data[0].faturamentos.length == ctrlFat){
                    this.faturamentoHoje = fatHoje;
                    if(this.faturamentoHoje > 0){this.showAlertaFaturamento()};
                  }else{
                    ctrlFat++;
                  }
                })
              };
            });
        });
      });
     });
  });
}

verificarAvisos(){
  this.arrayAvisos = [];
  let ctrl = 1;
  this.db.getAllUsuariosEscritorioProjection('"avisos":1').subscribe((usr:any) => {
    usr.forEach((u:any) => {
      if(u.avisos.length > 0){
        u.avisos.forEach((a) => {
          if(a.visto == false && a.idUserSolicitacao == localStorage.getItem('idUserVivamax')){
            this.arrayAvisos.push(a);
          };
        });
      };
      if(usr.length == ctrl){
        if(this.arrayAvisos.length > 0){this.showAvisos()}
      }else{
        ctrl++
      }
    })
  })  
}

showAlertaAgendamentos(){
  if(this.avisosArray.agendamento[this.db.tipoUsuario.toLowerCase()]){
     let boxAgendamento = document.getElementById('alertaAgendamento');
    boxAgendamento.style.display = "flex";
  }
}

showAlertaContasPagar(){
  if(this.avisosArray.contasPagar[this.db.tipoUsuario.toLowerCase()]){
     let boxContasPagar = document.getElementById('alertaContasPagar');
    boxContasPagar.style.display = "flex";
  }
}

showAlertaContasReceber(){
  if(this.avisosArray.contasReceber[this.db.tipoUsuario.toLowerCase()]){
      let boxContasReceber = document.getElementById('alertaContasReceber');
    boxContasReceber.style.display = "flex";
  }
}

showAlertaFaturamento(){
  if(this.avisosArray.faturamento[this.db.tipoUsuario.toLowerCase()]){
    let boxFaturamento = document.getElementById('alertaFaturamento');
    boxFaturamento.style.display = "flex";
  }
}

showAlertaTarefasGeral(){
  if(this.avisosArray.tarefas[this.db.tipoUsuario.toLowerCase()]){
    let boxFaturamento = document.getElementById('alertaTarefasGeral');
    boxFaturamento.style.display = "flex";
  }
}

showAlertaTarefas(array){
  let ctrl = 1;
  let list = document.getElementById('tarefasLista');
  list.innerHTML += "<ul>"
  array.forEach((task) => {
    list.innerHTML += "<li>"+task.titulo+"</li>";
    if(array.length == ctrl){
      list.innerHTML += "</ul>"
      let boxFaturamento = document.getElementById('alertaTarefas');
       boxFaturamento.style.display = "flex";
    }else{
      ctrl++
    }
  })
}

closeBoxAlert(id){
  let box = document.getElementById(id);
  box.classList.add("slide-right");
  setTimeout(() => {
    box.style.display = "none";
  },2000)
}

showAvisos(){
  let ctrl = 1;
  let list = document.getElementById('avisosLista');
  list.innerHTML += "<ul>"
  this.arrayAvisos.forEach((aviso) => {
    list.innerHTML += "<li>"+aviso.titulo+"</li>";
    if(this.arrayAvisos.length == ctrl){
      list.innerHTML += "</ul>"
      let boxFaturamento = document.getElementById('avisos');
       boxFaturamento.style.display = "flex";
    }else{
      ctrl++
    }
  })
}

closeBoxAviso(){
  let box = document.getElementById('avisos');
  box.classList.add("slide-right");
  setTimeout(() => {
    box.style.display = "none";
  },2000)
  this.arrayAvisos.forEach((a) => {
    a.visto = true;
    this.db.patchAviso(a.idUserSolicitacao,a.id,a).subscribe(res => console.log(res), err => console.log(err));
  })
}

openModalAssociados(){
  this.closeMenu();
  let modalTurmas = document.getElementById('associadoModal')
  modalTurmas.style.display = "block";
  modalTurmas.scrollTo(0,0)
  this.screenSelect = true;
}

closeModalAssociados(){
  let modalTurmas = document.getElementById('associadoModal')
  modalTurmas.style.display = "none";
  this.screenSelect = false;
}

openAlert(msg:any){
  this.mensagem = msg;
  let modSair:any = document.getElementById('aviso');
  modSair.style.display = "block";
}

closeAlert(){
  this.mensagem = "";
  let modSair:any = document.getElementById('aviso');
  modSair.style.display = "none";
}

splitName(fullName: string): { firstName: string; lastName: string } {
  const nameParts = fullName.trim().split(/\s+/);
  const lastName = nameParts.pop();
  const firstName = nameParts.join(' ');
  return { firstName, lastName: lastName || '' };
}

limpaCPF(cpf: string): string {
  return cpf.replace(/[.\-]/g, '');
}

checkEmail(email:any):boolean{
  if (email) {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[a-zA-Z]{2,}$/;
    if (emailPattern.test(email)) {
      return true;
    } else {
      return false;
    }
  }
  return false;
}

checkCPF(c:any):boolean{
  if(c){
    let cpf:any = c.replace(/[.\-]/g, '')
      if(cpf && cpf.length >= 11){
        if(!cpf || cpf.length != 11 || cpf == "00000000000" || cpf == "11111111111" || cpf == "22222222222" || cpf == "33333333333" || cpf == "44444444444" || cpf == "55555555555" || cpf == "66666666666" || cpf == "77777777777" || cpf == "88888888888" || cpf == "99999999999"){
            return false;
        }
        let soma:number = 0;
        let resto:number = 0;
        for(let i = 1; i <= 9; i++){
          soma = soma + parseInt(cpf.substring(i-1, i)) * (11 - i);
        }
        resto = (soma * 10) % 11;
        if((resto == 10) || (resto == 11)){
          resto = 0;
        }  
        if(resto != parseInt(cpf.substring(9, 10))){
          return false;
        }else{
          soma = 0;
        }
        for(let i = 1; i <= 10; i++){
          soma = soma + parseInt(cpf.substring(i-1, i)) * (12 - i);
        }
        resto = (soma * 10) % 11;
        if((resto == 10) || (resto == 11)){
          resto = 0;
        }
        if(resto != parseInt(cpf.substring(10, 11))){
          return false;
        }
      return true;
    }else{
      return false;
    }
  }
}

}