<div class="container-fluid">
<div class="row align-items-center header">
    <div class="col" style="text-align: center;margin-left: 40px;">
        <button class="btn btn-sm btn-outline-light" (click)="showPlanos()">GERAIS</button>&nbsp;&nbsp;
        <button class="btn btn-sm btn-outline-light" (click)="showAgendamentos()">AGENDAMENTOS</button>&nbsp;&nbsp;
        <button class="btn btn-sm btn-outline-light" (click)="showAtendimentos()">ATENDIMENTOS</button>&nbsp;&nbsp;
        <button class="btn btn-sm btn-outline-light" (click)="showSinistros()">SINISTROS</button>&nbsp;&nbsp;
        <button class="btn btn-sm btn-outline-light" (click)="showCancelados()">CANCELAMENTOS</button>&nbsp;&nbsp;
        <button class="btn btn-sm btn-outline-light" (click)="showSeguros()">SEGUROS</button>&nbsp;&nbsp;
        <button class="btn btn-sm btn-outline-light" (click)="showAniversariantes()">ANIVERSARIANTES</button>&nbsp;&nbsp;
        <button class="btn btn-sm btn-outline-light" (click)="showCombinados()">COMBINADOS</button>
    </div>
    <div class="col col-2" style="text-align: right;">
        <button class="btn btn-sm btn-outline-light" (click)="printXsl()">
            <fa-icon [icon]="['fas', 'file-excel']"></fa-icon>
        </button>
        &nbsp;
        <button class="btn btn-sm btn-outline-light" (click)="print()">
            <fa-icon [icon]="['fas', 'print']"></fa-icon>
        </button>
    </div>
</div>
<br>
<!-- RELATÓRIO GERAIS -->
<div *ngIf="relPlanos">
    <div class="row">
        <div class="col" style="text-align: center; color:#c7444a; font-weight: bold;">
            <h2>RELATÓRIOS GERAIS</h2>
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col col-8">
            <div class="row" style="padding:5px; font-size: 0.85em;">
                <div class="col col-6">
                    <label>Data Inicio</label>
                    <input type="date" (change)="verificaData(dataInicioPlano)" class="form-control" [(ngModel)]="dataInicioPlano">
                </div>
                <div class="col col-6">
                    <label>Data Fim</label>
                    <input type="date" (change)="verificaData(dataFimPlano)" class="form-control" [(ngModel)]="dataFimPlano">
                </div>
            </div>
            <div class="row" style="padding:5px; font-size: 0.85em;">
                <div class="col col-6">
                    <label>Empresa</label>
                    <select class="form-control" [(ngModel)]="empresaPlano">
                        <option value=""></option>
                        <option value="VIVA MAX">VIVA MAX</option>
                        <option value="CONECTA">CONECTA</option>
                        <option value="SEGUROS">SEGUROS</option>
                    </select>
                </div>
                <div class="col col-6">
                    <label>Tipo do Plano</label>
                    <select class="form-control" [(ngModel)]="tipoPlano" (change)="selectTipoPlanoGeral()">
                        <option value="TODOS">TODOS</option>
                        <option value="PLANO FISICO">PESSOA FÍSICA</option>
                        <option value="PLANO SERVIDOR">SERVIDOR PÚBLICO</option>
                        <option value="PLANO EMPRESARIAL">EMPRESARIAL</option>
                    </select>
                </div>
            </div>
            <div class="row" style="padding:5px; font-size: 0.85em;">
                <div class="col col-6">
                    <label>Exceto Plano</label>
                    <select class="form-control" [(ngModel)]="excetoPlano" [disabled]="nomePlano != ''">
                        <option value="NENHUM" selected>NENHUM</option>
                        <option [value]="plano.nome" *ngFor="let plano of planos">{{plano.nome}}</option>
                    </select>
                    <br>
                    <label>Valor Personalizado</label>
                    <select class="form-control" [(ngModel)]="valorPersonalizado">
                        <option value="TODOS">TODOS</option>
                        <option [ngValue]="false">NÃO</option>
                        <option [ngValue]="true">SIM</option>
                    </select>  
                </div>
                <div class="col col-6">
                    <label>Vendedor</label>
                    <select class="form-control" [(ngModel)]="vendedorPlano" multiple>
                        <option value="TODOS">TODOS</option>
                        <option [value]="vend.nome" *ngFor="let vend of vendedores">{{vend.nome}}</option>
                    </select>
                    <small>Segure <strong>CTRL</strong> para escolher mais que um</small> 
                </div>
            </div>
            <div class="row" style="padding:5px; font-size: 0.85em;">
                <div class="col col-12">
                    <label>Sub Contrato</label>
                    <ng-select class="form-control" [items]="subContratos" bindLabel="nome" appendTo="body" [(ngModel)]="subContrato"></ng-select>
                </div>
            </div>
            <div class="row" style="padding:5px; font-size: 0.85em;">
                <div class="col col-12">
                    <label>Empresa</label>
                    <ng-select class="form-control" [items]="empresas" bindLabel="fantasiaEmpresa" appendTo="body" [(ngModel)]="empresa"></ng-select>
                </div>
            </div>
            <div class="row" style="padding:5px; font-size: 0.85em;">
                <div class="col col-6">
                    <label>Cidade</label>
                    <input type="text" class="form-control" [(ngModel)]="cidadePlano">
                </div>   
              </div>
            <div class="row" style="padding:5px; font-size: 0.85em;">
                <div class="col col-4">
                    <label>Taxa de Adesão</label>
                    <input type="number" class="form-control" [(ngModel)]="taxaAdesao">
                    <br>
                    <label>Sexo</label>
                    <select class="form-control" [(ngModel)]="sexo">
                        <option value=""></option>
                        <option value="MASCULINO">MASCULINO</option>
                        <option value="FEMININO">FEMININO</option>
                    </select>
                </div>
                <div class="col col-4">
                    <label>Profissão</label>
                    <input type="text" class="form-control" [(ngModel)]="profissao">
                    <br>
                    <label>Data Nascimento</label>
                    <input type="date" class="form-control" [(ngModel)]="dataNascimento">
                </div>
                <div class="col col-4">
                    <label>Situação</label>
                    <select class="form-control" [(ngModel)]="situacaoPlano" multiple>
                        <option value="TODOS">TODOS</option>
                        <option value="ATIVO">ATIVO</option>
                        <option value="PENDENTE">PENDENTE</option>
                        <option value="ALTERAÇÃO">ALTERAÇÃO</option>
                        <option value="INADIMPLENTE I">INADIMPLENTE I</option>
                        <option value="INADIMPLENTE II">INADIMPLENTE II</option>
                        <option value="AFASTADO">AFASTADO</option>
                        <option value="AFASTADO INADIMPLENTE">AFASTADO INADIMPLENTE</option>
                        <option value="CANCELADO">CANCELADO</option>
                    </select>
                    <small>Segure <strong>CTRL</strong> para escolher mais que um</small>
                </div>
            </div>
            <div class="row" style="padding:5px; font-size: 0.85em;">
                <div class="col col-3">
                    <label>Documento Assinatura</label>
                    <select class="form-control" [(ngModel)]="semAssinatura" (change)="selectAssinatura()">
                        <option value="TODOS">TODOS</option>
                        <option value="SEM">SEM ASSINATURA</option>
                        <option value="COM">COM ASSINATURA</option>
                    </select>
                </div>
                <div class="col col-3">
                    <label>Envio de Autorização</label>
                    <select class="form-control" [(ngModel)]="semAutorizacao" (change)="selectAutorizacao()">
                        <option value="TODOS">TODOS</option>
                        <option value="SEM">NÂO ENVIADAS</option>
                        <option value="COM">ENVIADAS</option>
                    </select>
                </div>
                <div class="col col-3">
                    <label>Motivo da Pendência</label>
                    <select class="form-control" [(ngModel)]="motivoPendencia">
                        <option value="" selected></option>
                        <option *ngFor="let motPend of motivosPendencia" [value]="motPend.nome">{{motPend.nome}}</option>
                    </select>
                </div>
                <div class="col col-3">
                    <label>Motivo da Inadimplência</label>
                    <select class="form-control" [(ngModel)]="motivoInadimplente">
                        <option value="" selected></option>
                        <option *ngFor="let motInad of motivosInadimplente" [value]="motInad.nome">{{motInad.nome}}</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="col col-4">
            <div class="row" style="padding:5px; font-size: 0.85em;">
                <div class="col" style="text-align: center;">
                    <label>Nome do Plano</label>
                    <select class="form-control" [(ngModel)]="nomePlano" multiple size="8">
                        <option [value]="plano.nome" *ngFor="let plano of planosGerais">{{plano.nome}}</option>
                    </select>
                     <small>Segure <strong>CTRL</strong> para escolher mais que um plano</small>
                </div>
            </div>
            <div class="row align-items-center">
                <div class="col col-6">
                    <button class="btn btn-danger btn-block" (click)="limparCamposPlanos()">LIMPAR CAMPOS</button>
                </div>
                <div class="col col-6">
                    <button class="btn btn-primary btn-block" (click)="buscarPlanos()" [disabled]="!dataInicioPlano || !dataFimPlano">BUSCAR</button>
                </div>
            </div>
            <br>
            <br>
            <div class="row" style="padding:5px; font-size: 0.85em;">
                <div class="col col-6">
                    <label>Mês Folha de Pagamento</label>
                    <select class="form-control" [(ngModel)]="mesPagamento">
                        <option value="" selected></option>
                        <option value="-01">Janeiro</option>
                        <option value="-02">Fevereiro</option>
                        <option value="-03">Março</option>
                        <option value="-04">Abril</option>
                        <option value="-05">Maio</option>
                        <option value="-06">Junho</option>
                        <option value="-07">Julho</option>
                        <option value="-08">Agosto</option>
                        <option value="-09">Setembro</option>
                        <option value="-10">Outubro</option>
                        <option value="-11">Novembro</option>
                        <option value="-12">Dezembro</option>
                    </select>
                </div> 
                <div class="col col-6">
                    <label>Forma Pagamento</label>
                    <select class="form-control" [(ngModel)]="formaPagamento">
                        <option value="" selected></option>
                        <option value="BOLETO">BOLETO</option>
                        <option value="CARNÊ">CARNÊ</option>
                        <option value="DEBITO RECORRENTE">DÉBITO RECORRENTE</option>
                        <option value="DESCONTO EM FOLHA DE PAGAMENTO">DESCONTO EM FOLHA</option>
                        <option value="EMPRESARIAL">EMPRESARIAL</option>
                    </select>
                </div>
            </div>
        </div>
    </div>
<br>
<br>
<div class="row align-items-start filtros" style="font-size: 0.75em;">
    <div class="col col-2">
        <div class="form-check">
            <input class="form-check-input" type="checkbox" value="" id="adesaocheck" [(ngModel)]="adesaoSelect">
            <label class="form-check-label" for="adesaocheck">
             ADESÃO
            </label>
          </div>
    </div>
    <div class="col col-2">
        <div class="form-check">
            <input class="form-check-input" type="checkbox" value="" id="cadastrocheck" [(ngModel)]="cadastroSelect">
            <label class="form-check-label" for="cadastrocheck">
             CADASTRO
            </label>
        </div>
    </div>
    <div class="col col-2">
        <div class="form-check">
            <input class="form-check-input" type="checkbox" value="" id="telefonecheck" [(ngModel)]="telefoneSelect">
            <label class="form-check-label" for="telefonecheck">
             CELULAR
            </label>
          </div>
    </div>
    <div class="col col-2">
        <div class="form-check">
            <input class="form-check-input" type="checkbox" value="" id="cidadecheck" [(ngModel)]="cidadeSelect">
            <label class="form-check-label" for="cidadecheck">
             CIDADE
            </label>
          </div>
    </div>
    <div class="col col-2">
        <div class="form-check">
            <input class="form-check-input" type="checkbox" value="" id="contratocheck" [(ngModel)]="contratoSelect">
            <label class="form-check-label" for="contratocheck">
             CONTRATO
            </label>
          </div>
    </div>
    <div class="col col-2">
        <div class="form-check">
            <input class="form-check-input" type="checkbox" value="" id="cpfcheck" [(ngModel)]="cpfSelect">
            <label class="form-check-label" for="cpfcheck">
             CPF
            </label>
          </div>
    </div>
    <div class="col col-2">
        <div class="form-check">
            <input class="form-check-input" type="checkbox" value="" id="afastadoDesdeSelectCheck" [(ngModel)]="afastadoDesdeSelect">
            <label class="form-check-label" for="afastadoDesdeSelectCheck">
             DATA AFASTAMENTO
            </label>
          </div>
    </div> 
    <div class="col col-2">
        <div class="form-check">
            <input class="form-check-input" type="checkbox" value="" id="dtcancelamentocheck" [(ngModel)]="dataCancelamentoSelect">
            <label class="form-check-label" for="dtcancelamentocheck">
             DATA CANCELAMENTO
            </label>
          </div>
    </div>
    <div class="col col-2">
        <div class="form-check">
            <input class="form-check-input" type="checkbox" value="" id="dtNascimentoCheck" [(ngModel)]="dataNascimentoSelect">
            <label class="form-check-label" for="dtNascimentoCheck">
             DATA NASCIMENTO
            </label>
          </div>
    </div>
    <div class="col col-2">
        <div class="form-check">
            <input class="form-check-input" type="checkbox" value="" id="diasPendentesSelectCheck" [(ngModel)]="diasPendentesSelect">
            <label class="form-check-label" for="diasPendentesSelectCheck">
             DIAS PENDENTES
            </label>
        </div>
    </div> 
    <div class="col col-2">
        <div class="form-check">
            <input class="form-check-input" type="checkbox" value="" id="emailCheck" [(ngModel)]="emailSelect">
            <label class="form-check-label" for="emailCheck">
             E-MAIL
            </label>
          </div>
    </div>
    <div class="col col-2">
        <div class="form-check">
            <input class="form-check-input" type="checkbox" value="" id="empresacheck" [(ngModel)]="empresaSelect">
            <label class="form-check-label" for="empresacheck">
             EMPRESA
            </label>
        </div>
    </div>
    <div class="col col-2">
        <div class="form-check">
            <input class="form-check-input" type="checkbox" value="" id="enderecocheck" [(ngModel)]="enderecoSelect">
            <label class="form-check-label" for="enderecocheck">
             ENDEREÇO
            </label>
          </div>
    </div>
    <div class="col col-2">
        <div class="form-check">
            <input class="form-check-input" type="checkbox" value="" id="formaPagamentoSelectCheck" [(ngModel)]="formaPagamentoSelect">
            <label class="form-check-label" for="formaPagamentoSelectCheck">
             FORMA PAGAMENTO
            </label>
          </div>
    </div> 
    <div class="col col-2">
        <div class="form-check">
            <input class="form-check-input" type="checkbox" value="" id="idadeCheck" [(ngModel)]="idadeSelect">
            <label class="form-check-label" for="idadeCheck">
             IDADE
            </label>
          </div>
    </div>
    <div class="col col-2">
        <div class="form-check">
            <input class="form-check-input" type="checkbox" value="" id="localTrabalhoCheck" [(ngModel)]="localTrabalhoSelect">
            <label class="form-check-label" for="localTrabalhoCheck">
             LOCAL TRABALHO
            </label>
        </div>
    </div> 
    <div class="col col-2">
        <div class="form-check">
            <input class="form-check-input" type="checkbox" value="" id="matriculacheck" [(ngModel)]="matriculaSelect">
            <label class="form-check-label" for="matriculacheck">
             MATRICULA
            </label>
          </div>
    </div>
    <div class="col col-2">
        <div class="form-check">
            <input class="form-check-input" type="checkbox" value="" id="mensalidadecheck" [(ngModel)]="mensalidadeSelect">
            <label class="form-check-label" for="mensalidadecheck">
             MENSALIDADE
            </label>
          </div>
    </div>
    <div class="col col-2">
        <div class="form-check">
            <input class="form-check-input" type="checkbox" value="" id="motivocancelamentocheck" [(ngModel)]="motivoCancelamentoSelect">
            <label class="form-check-label" for="motivocancelamentocheck">
             MOTIVO CANCELAMENTO
            </label>
          </div>
    </div>
    <div class="col col-2">
        <div class="form-check">
            <input class="form-check-input" type="checkbox" value="" id="motivoinadimplenciacheck" [(ngModel)]="motivoInadimplenteSelect">
            <label class="form-check-label" for="motivoinadimplenciacheck">
             MOTIVO INADIMPLÊNCIA
            </label>
          </div>
    </div>
    <div class="col col-2">
        <div class="form-check">
            <input class="form-check-input" type="checkbox" value="" id="pendenciacheck" [(ngModel)]="motivoPendenciaSelect">
            <label class="form-check-label" for="pendenciacheck">
             MOTIVO PENDÊNCIA
            </label>
          </div>
    </div>
    <div class="col col-2">
        <div class="form-check">
            <input class="form-check-input" type="checkbox" value="" id="obsPendenciacheck" [(ngModel)]="observacaoPendenciaSelect">
            <label class="form-check-label" for="obsPendenciacheck">
             OBSERVAÇÃO PENDÊNCIA
            </label>
          </div>
    </div>
    <div class="col col-2">
        <div class="form-check">
            <input class="form-check-input" type="checkbox" value="" id="origemCheck" [(ngModel)]="origemSelect">
            <label class="form-check-label" for="origemCheck">
             ORIGEM
            </label>
        </div>
    </div> 
    <div class="col col-2">
        <div class="form-check">
            <input class="form-check-input" type="checkbox" value="" id="planoCheck" [(ngModel)]="planoSelect">
            <label class="form-check-label" for="planoCheck">
            PLANO
            </label>
          </div>
    </div> 
    <div class="col col-2">
        <div class="form-check">
            <input class="form-check-input" type="checkbox" value="" id="sexocheck" [(ngModel)]="sexoSelect">
            <label class="form-check-label" for="sexocheck">
             SEXO
            </label>
          </div>
    </div> 
    <div class="col col-2">
        <div class="form-check">
            <input class="form-check-input" type="checkbox" value="" id="situacaocheck" [(ngModel)]="situacaoSelect">
            <label class="form-check-label" for="situacaocheck">
             SITUAÇÃO
            </label>
          </div>
    </div>
    <div class="col col-2">
        <div class="form-check">
            <input class="form-check-input" type="checkbox" value="" id="taxaAdesaocheck" [(ngModel)]="taxaAdesaoSelect">
            <label class="form-check-label" for="taxaAdesaocheck">
             TAXA ADESÃO
            </label>
          </div>
    </div>   
    <div class="col col-2">
        <div class="form-check">
            <input class="form-check-input" type="checkbox" value="" id="titularcheck" [(ngModel)]="titularSelect">
            <label class="form-check-label" for="titularcheck">
             TITULAR
            </label>
          </div>
    </div>
    <div class="col col-2">
        <div class="form-check">
            <input class="form-check-input" type="checkbox" value="" id="totalMensalidadecheck" [(ngModel)]="totalMensalidadeSelect">
            <label class="form-check-label" for="totalMensalidadecheck">
             TOTAL MENSALIDADE
            </label>
          </div>
    </div>
    <div class="col col-2">
        <div class="form-check">
            <input class="form-check-input" type="checkbox" value="" id="vendedorcheck" [(ngModel)]="vendedorSelect">
            <label class="form-check-label" for="vendedorcheck">
             VENDEDOR
            </label>
          </div>
    </div>  
    <div class="col col-2">
        <div class="form-check">
            <input class="form-check-input" type="checkbox" value="" id="dependentescheck" [(ngModel)]="dependenteSelect">
            <label class="form-check-label" for="dependentescheck">
             DEPENDENTES
            </label>
          </div>
    </div>  
</div>
<div id="divToPrintRel">
    <table class="table table-striped" style="min-width: 100% !important; overflow-x: auto;">
        <thead class="thead-dark">
            <tr>
                <th>#</th>
                <th *ngIf="empresaSelect">Empresa</th>
                <th *ngIf="cadastroSelect">Cadastro</th>
                <th *ngIf="adesaoSelect">Adesão</th>
                <th *ngIf="situacaoSelect">Situação</th>
                <th *ngIf="matriculaSelect">Matricula</th>
                <th *ngIf="titularSelect">Titular</th>
                <th *ngIf="cpfSelect">CPF</th>
                <th *ngIf="sexoSelect">Sexo</th>
                <th *ngIf="enderecoSelect">Endereço</th>
                <th *ngIf="cidadeSelect">Cidade</th>
                <th *ngIf="telefoneSelect">Celular</th>
                <th *ngIf="contratoSelect">Contrato</th>
                <th *ngIf="mensalidadeSelect">Mensalidade</th>
                <th *ngIf="totalMensalidadeSelect">Total Mensalidade</th>
                <th *ngIf="taxaAdesaoSelect">Taxa Adesão</th>
                <th *ngIf="emailSelect">E-mail</th>
                <th *ngIf="planoSelect">Plano</th>
                <th *ngIf="vendedorSelect">Vendedor</th>
                <th *ngIf="motivoPendenciaSelect">Motivo Pendência</th>
                <th *ngIf="observacaoPendenciaSelect">Observação Pendência</th>
                <th *ngIf="dataCancelamentoSelect">Data Cancelamento</th>
                <th *ngIf="dataNascimentoSelect">Data Nascimento</th>
                <th *ngIf="idadeSelect">Idade</th>                
                <th *ngIf="motivoCancelamentoSelect">Motivo Cancelamento</th>
                <th *ngIf="motivoInadimplenteSelect">Motivo Inadimplência</th>
                <th *ngIf="afastadoDesdeSelect">Data Afastamento</th>
                <th *ngIf="formaPagamentoSelect">Forma Pagamento</th>
                <th *ngIf="diasPendentesSelect">Dias Pendentes</th>
                <th *ngIf="localTrabalhoSelect">Local Trabalho</th>  
                <th *ngIf="origemSelect">Origem</th>
                <th *ngIf="dependenteSelect">Dependentes</th>                                   
            </tr>
        </thead>
        <tbody *ngFor="let rel of relatorio; let i = index">
            <tr>
                <td>{{i+1}}</td>
                <td *ngIf="empresaSelect" [ngStyle]="{'color': rel?.empresa == 'VIVA MAX' ? '#e64249' : '#00e0c2'}"><strong>{{ rel?.empresa }}</strong></td>
                <td *ngIf="cadastroSelect">{{ rel?.data | date: 'dd/MM/yyyy' }}</td>
                <td *ngIf="adesaoSelect">{{ rel?.dataAdesao | date: 'dd/MM/yyyy' }}</td>
                <td *ngIf="situacaoSelect">{{ rel?.situacao }}</td>
                <td *ngIf="matriculaSelect">{{ rel?.matricula }}</td>
                <td *ngIf="titularSelect">{{ rel?.nome }}</td>
                <td *ngIf="cpfSelect">{{ rel?.cpf }}</td>
                <td *ngIf="sexoSelect">{{ rel?.sexo }}</td>
                <td *ngIf="enderecoSelect">{{ rel?.rua }} - {{ rel?.numero }}, {{ rel?.bairro }}</td>
                <td *ngIf="cidadeSelect">{{ rel?.municipio }}</td>
                <td *ngIf="telefoneSelect">{{ rel?.celular }}</td>
                <td *ngIf="contratoSelect">{{ rel?.subContrato }} <span *ngIf="rel?.planoFisicoCheck">PLANO FISICO</span><span *ngIf="rel?.planoEmpresarialCheck">PLANO EMPRESARIAL</span></td>
                <td *ngIf="mensalidadeSelect">{{ rel?.mensalidade | currency:'BRL'}}</td>
                <td *ngIf="totalMensalidadeSelect">{{ rel?.totalMensalidade | currency:'BRL'}}</td>
                <td *ngIf="taxaAdesaoSelect">{{ rel?.taxaAdesao | currency:'BRL'}}</td>
                <th *ngIf="emailSelect">{{ rel?.email?.toLowerCase() }}</th>
                <th *ngIf="planoSelect">{{ rel?.plano }}</th>
                <td *ngIf="vendedorSelect">{{ rel?.vendedor }}</td>
                <td *ngIf="motivoPendenciaSelect">{{ rel?.motivoPendencia }}</td>
                <td *ngIf="observacaoPendenciaSelect">{{ rel?.observacaoPendente }}</td>
                <td *ngIf="dataCancelamentoSelect">{{ rel?.dataCancelamento | date: 'dd/MM/yyyy' }}</td>
                <td *ngIf="dataNascimentoSelect">{{ rel?.nascimento | date: 'dd/MM/yyyy' }}</td>
                <td *ngIf="idadeSelect">{{ rel?.idade }}</td>
                <td *ngIf="motivoCancelamentoSelect">{{ rel?.motivoCancelamento }}</td>
                <td *ngIf="motivoInadimplenteSelect">{{ rel?.motivoInadimplente }}</td>
                <td *ngIf="afastadoDesdeSelect">{{ rel?.afastadoDesde  | date: 'dd/MM/yyyy'}}</td>      
                <td *ngIf="formaPagamentoSelect">{{ rel?.formaPagamento }}</td>      
                <td *ngIf="diasPendentesSelect">{{ rel?.diasPendentes }}</td>
                <td *ngIf="localTrabalhoSelect">{{ rel?.localTrabalho }}</td>
                <td *ngIf="origemSelect">{{ rel?.origem }}</td>
                <td *ngIf="dependenteSelect">
                    <div *ngFor="let dep of rel?.dependentes; let iDep = index;">
                        {{iDep+1}} - {{ dep?.tipo + ": "}} {{ dep?.nome + " - "}} {{ dep?.nascimento  + " - "}} {{ dep?.cpf ? dep?.cpf + " - " : '' }} {{ dep?.asf + " / "}}
                    </div>
                    <div *ngFor="let depExtra of rel?.dependentesExtra; let iDepExtra = index;">
                        {{iDep+iDepExtra+1}} - {{ depExtra?.tipo + ": "}} {{ depExtra?.nome + " - "}} {{ depExtra?.nascimento  + " - "}} {{ depExtra?.cpf ? depExtra?.cpf + " - " : '' }} {{ depExtra?.asf + " / "}}
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
    <br>
    <div class="row" style="text-align: center;" *ngIf="relatorio.length > 0 && (mensalidadeSelect || totalMensalidadeSelect)">
        <div class="col" style="line-height: 2em; border:1px solid #444">
            <strong>TOTAL VIVA MAX: {{mensalidadeVivamax | currency:'BRL'}}</strong><br>
        </div>
        <div class="col" style="line-height: 2em; border:1px solid #444">
            <strong>TOTAL CONECTA: {{mensalidadeConecta | currency:'BRL'}}</strong><br>
        </div>
        <div class="col" style="line-height: 2em; border:1px solid #444">
            <strong>TOTAL: {{mensalidadeTotal | currency:'BRL'}}</strong><br>
        </div>
    </div>
    <div style="height:3em"></div>
    </div>
</div>
<!-- RELATÓRIO DE AGENDAMENTOS -->
<div *ngIf="relAgendamentos"> 
    <div class="row" style="padding:5px;">
        <div class="col col-3">
            <label>Agendado em (Inicio)</label>
            <input type="date" (change)="verificaData(dataInicioAgendamento)" class="form-control" [(ngModel)]="dataInicioAgendamento">
        </div>
        <div class="col col-3">
            <label>Agendado em (Fim)</label>
            <input type="date" (change)="verificaData(dataFimAgendamento)" class="form-control" [(ngModel)]="dataFimAgendamento">
        </div>
        <div class="col col-3">
            <label>Agendado Para (Inicio)</label>
            <input type="date" (change)="verificaData(dataInicioProcedimentoAgendamento)" class="form-control" [(ngModel)]="dataInicioProcedimentoAgendamento">
        </div>
        <div class="col col-3">
            <label>Agendado Para (Fim)</label>
            <input type="date" (change)="verificaData(dataFimProcedimentoAgendamento)" class="form-control" [(ngModel)]="dataFimProcedimentoAgendamento">
        </div>
    </div>
    <div class="row" style="padding:5px;">
        <div class="col col-2">
            <label>Matricula</label>
            <input type="number" class="form-control" [(ngModel)]="matriculaAgendamento">
        </div>
        <div class="col col-3">
            <label>Médico</label>
            <input type="text" class="form-control" [(ngModel)]="medicoAgendamento">
        </div>
        <div class="col col-4">
            <label>Paciente</label>
            <input type="text" class="form-control" [(ngModel)]="pacienteAgendamento">
        </div>
        <div class="col col-3">
            <label>Cidade</label>
            <input type="text" class="form-control" [(ngModel)]="cidadeAgendamento">
        </div>
    </div>
    <div class="row" style="padding:5px;">
        <div class="col col-3">
            <label>Pagamento</label>
            <select class="form-control" [(ngModel)]="pagamentoAgendamento">
                <option value=""></option>
                <option value="CARTÃO">CARTÃO</option>
                <option value="DINHEIRO">DINHEIRO</option>
            </select>  
        </div>
        <div class="col col-3">
            <label>Valor</label>
            <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="basic-addon1">R$</span>
                </div>
                <input type="number" class="form-control" [(ngModel)]="valorAgendamento">
            </div>  
        </div>
        <div class="col col-3">
            <br>
            <button class="btn btn-primary btn-block" (click)="buscarAgendamentos()">BUSCAR POR AGENDAMENTOS</button>
        </div>
        <div class="col col-3">
            <br>
            <button class="btn btn-success btn-block" (click)="buscarAgendamentosProcedimento()">BUSCAR POR PROCEDIMENTOS</button>
        </div>
    </div>
<br>
<div id="divToPrintRel">
    <div class="row">
        <div class="col" style="text-align: center; color:#c7444a; font-weight: bold;">
            <h2>RELATÓRIOS AGENDAMENTOS</h2>
        </div>
    </div>
    <br>
    <table class="table table-striped">
        <thead class="thead-dark">
            <tr>
                <th>#</th>
                <th>Matricula</th>
                <th>Data Agendamento</th>
                <th>Horário Médico</th>
                <th>Data Procedimento</th>
                <th>Titular</th>
                <th>Paciente</th>
                <th>Cidade</th>
                <th>Médico</th>
                <th>Especialidade</th>
                <th>Forma Pagamento</th>
                <th>Valor</th>
            </tr>
        </thead>
        <tbody *ngFor="let rel of relatorioAgendamentos; let i = index">
            <tr>
                <td>{{i+1}}</td>
                <td>{{ rel?.matricula }}</td>
                <td>{{ rel?.dataAgendamento | date: 'dd/MM/yyyy' }}</td>
                <td>{{ rel?.horarioMedico }}</td>
                <td>{{ rel?.agendadoParaMedico | date: 'dd/MM/yyyy' }}</td>
                <td>{{ rel?.titular }}</td>
                <td>{{ rel?.pacienteMedico }}</td>
                <td>{{ rel?.cidade }}</td>
                <td>{{ rel?.laboratorioMedico }}</td>
                <td>{{ rel?.especialidadeMedico }}</td>
                <td>{{ rel?.formaPagamento }}</td>
                <td>{{ rel?.valorConsulta | currency:'BRL'}}</td>
            </tr>
         </tbody>
    </table>
</div>
</div>
<!-- RELATÓRIO DE ATENDIMENTOS -->
<div *ngIf="relAtendimentos"> 
    <div class="row" style="padding:5px;">
        <div class="col col-3">
            <label>Data Inicio</label>
            <input type="date" class="form-control" [(ngModel)]="dataInicioAtendimento">
        </div>
        <div class="col col-3">
            <label>Data Fim</label>
            <input type="date" class="form-control" [(ngModel)]="dataFimAtendimento">
        </div>
        <div class="col col-6">
            <label>Motivo</label>
            <select class="form-control" [(ngModel)]="motivoAtendimento" multiple>
                <option value="" selected>TODOS</option> 
                <option *ngFor="let motivo of db.motivosAtendimento" [value]="motivo.nome">{{motivo.nome}}</option> 
            </select>  
        </div>
    </div>
    <div class="row" style="padding:5px;">
        <div class="col col-6">
            <label>Empresa</label>
            <select class="form-control" [(ngModel)]="empresaAtendimento">
                <option value="" selected></option> 
                <option [value]="emp.fantasiaEmpresa" *ngFor="let emp of empresas">{{emp.fantasiaEmpresa}}</option>
            </select>
        </div>
        <div class="col col-6">
            <label>Plano</label>
            <select class="form-control" [(ngModel)]="planoAtendimento">
                <option value="" selected></option> 
                <option [value]="plano.nome" *ngFor="let plano of planos">{{plano.nome}}</option>
            </select>
        </div>
    </div>
    <div class="row" style="padding:5px;">
        <div class="col col-11">
            <label>Contrato</label>
            <select class="form-control" [(ngModel)]="contratoAtendimento">
                <option value="" selected></option> 
                <option [value]="contrato.nome" *ngFor="let contrato of subContratos">{{contrato.nome}}</option>
            </select>
        </div>
        <div class="col col-1">
            <br>
            <button class="btn btn-primary" (click)="buscarAtendimento()">
                <fa-icon [icon]="['fas', 'search']"></fa-icon>
            </button>
        </div>
    </div>
<br>
<div id="divToPrintRel">
    <div class="row">
        <div class="col" style="text-align: center; color:#c7444a; font-weight: bold;">
            <h2>RELATÓRIOS ATENDIMENTO</h2>
        </div>
    </div>
    <br>
    <table class="table table-striped">
        <thead class="thead-dark">
            <tr>
                <th>#</th>
                <th>Data</th>
                <th>Matricula</th>
                <th>Nome</th>
                <th>Empresa</th>
                <th>Plano</th>
                <th>Contrato</th>
                <th>Motivo</th>
                <th>Descrição</th>
            </tr>
        </thead>
        <tbody *ngFor="let rel of relatorioAtendimentos; let i = index">
            <tr>
                <td>{{i+1}}</td>
                <td>{{ rel?.data | date: 'dd/MM/yyyy' }}</td>
                <td>{{ rel?.matricula }}</td>
                <td>{{ rel?.nome }}</td>
                <td>{{ rel?.localTrabalho }}</td>
                <td>{{ rel?.plano }}</td>
                <td>{{ rel?.subContrato }}</td>
                <td>{{ rel?.motivo }}</td>
                <td>{{ rel?.msg }}</td>
            </tr>
         </tbody>
    </table>
</div>
</div>
<!-- RELATÓRIO DE SINISTROS -->
<div *ngIf="relSinistros">
    <div class="row" style="padding:5px;">
        <div class="col col-3">
            <label>Data Inicio</label>
            <input type="date" (change)="verificaData(dataInicioSinistro)" class="form-control" [(ngModel)]="dataInicioSinistro">
        </div>
        <div class="col col-3">
            <label>Data Fim</label>
            <input type="date" (change)="verificaData(dataFimSinistro)" class="form-control" [(ngModel)]="dataFimSinistro">
        </div>
        <div class="col col-2">
            <label>Matricula</label>
            <input type="number" class="form-control" [(ngModel)]="matriculaSinistro">
        </div>
        <div class="col col-4">
            <label>Sinistrado</label>
            <input type="text" class="form-control" [(ngModel)]="sinistradoSinistro">
        </div>
    </div>
    <div class="row" style="padding:5px;">
        <div class="col col-5">
            <label>Filtros de Serviços</label>
            <div class="row">
                <div class="col">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="indeSinistro" [(ngModel)]="indenizacaoSinistro" (change)="changeServicos()">
                        <label class="form-check-label" for="indeSinistro">
                         Indenização
                        </label>
                      </div>
                </div>
                <div class="col">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="assistSinistro" [(ngModel)]="assistenciaSinistro" (change)="changeServicos()">
                        <label class="form-check-label" for="assistSinistro">
                         Assistência
                        </label>
                      </div>
                </div>
                <div class="col">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="servSinistro" [(ngModel)]="servicoSinistro" (change)="changeServicos()">
                        <label class="form-check-label" for="servSinistro">
                         Serviços
                        </label>
                      </div>
                </div>
            </div>
        </div>
        <div class="col col-3">
            <label>Situação</label>
            <select class="form-control" [(ngModel)]="situacaoSinistro">
                <option value=""></option>
                <option value="PENDENTE">PENDENTE</option>
                <option value="RECUSADO">RECUSADO</option>
                <option value="LIQUIDADO">LIQUIDADO</option>
            </select>  
        </div>
        <div class="col col-3">
            <label>Tipo</label>
            <select class="form-control" [(ngModel)]="tipoSinistro">
                <option value=""></option>
                <option value="TITULAR">TITULAR</option>
                <option value="CÔNJUGE">CÔNJUGE</option>
                <option value="FILHO">FILHO</option>
                <option value="FILHA">FILHA</option>
                <option value="MÃE">MÃE</option>
                <option value="PAI">PAI</option>
                <option value="SOGRO">SOGRO</option>
                <option value="SOGRA">SOGRA</option>
            </select>  
        </div>
        <div class="col col-1">
            <br>
            <button class="btn btn-primary btn-block" (click)="buscarSinistros()" [disabled]="!dataInicioSinistro || !dataFimSinistro">BUSCAR</button>
        </div>
    </div>
<br>
<div id="divToPrintRel">
    <div class="row">
        <div class="col" style="text-align: center; color:#c7444a; font-weight: bold;">
            <h2>RELATÓRIOS SINISTROS</h2>
        </div>
    </div>
    <br>
    <table class="table table-striped">
        <thead class="thead-dark">
            <tr>
                <th>#</th>
                <th>Matricula</th>
                <th>Sinistrado</th>
                <th>Serviço</th>
                <th>Situação</th>
                <th>Tipo</th>
            </tr>
        </thead>
        <tbody *ngFor="let rel of relatorio; let i = index">
            <tr>
                <td>{{i+1}}</td>
                <td>{{ rel?.matricula }}</td>
                <td>{{ rel?.sinistrado }}</td>
                <td>{{ rel?.servico }}</td>
                <td>{{ rel?.situacao }}</td>
                <td>{{ rel?.tipo }}</td>
            </tr>
         </tbody>
    </table>
</div>
</div>
<!-- RELATÓRIO DE CANCELADOS -->
<tabset #staticTabs *ngIf="relCancelados">
    <tab heading="SOLICITAÇÕES DE CANCELAMENTO" style="padding:10px;">
        <div class="row" style="padding:5px;">
            <div class="col col-3">
                <label>Data Inicio</label>
                <input type="date" (change)="verificaData(dataInicioAgendamento)" class="form-control" [(ngModel)]="dataInicioSolicitacaoCancelado">
            </div>
            <div class="col col-3">
                <label>Data Fim</label>
                <input type="date" (change)="verificaData(dataFimAgendamento)" class="form-control" [(ngModel)]="dataFimSolicitacaoCancelado">
            </div>
            <div class="col col-2">
                <br>
                <button class="btn btn-primary btn-block" (click)="buscarSolicitacaoCancelados()" [disabled]="!dataInicioSolicitacaoCancelado || !dataFimSolicitacaoCancelado">BUSCAR</button>
            </div>
        </div>
        <br>
        <div id="divToPrintRel" *ngIf="!cancelamentoAba">
            <div class="row">
                <div class="col" style="text-align: center; color:#c7444a; font-weight: bold;">
                    <h2>RELATÓRIO SOLICITAÇÃO CANCELAMENTOS</h2>
                </div>
            </div>
            <br>
            <table class="table table-striped">
                <thead class="thead-dark">
                    <tr>
                        <th>#</th>
                        <th>Data Solicitação</th>
                        <th>Hora Solicitação</th>
                        <th>Matricula</th>
                        <th>Nome</th>
                        <th>Motivo Solicitação</th>
                        <th>Atendente</th>
                        <th>Meses</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let rel of relatorio; let i = index">
                        <td>{{i+1}}</td>
                        <td>{{ rel?.dataSolicitacaoCancelamento | date: 'dd/MM/yyyy' }}</td>
                        <td>{{ rel?.horaSolicitacaoCancelamento }}</td>
                        <td>{{ rel?.matricula }}</td>
                        <td>{{ rel?.nome }}</td>
                        <td>{{ rel?.motivoSolicitacaoCancelamento }}</td>
                        <td>{{ rel?.usuarioSolicitacoCancelamento }}</td>
                        <td>{{ rel?.meses }}</td>
                    </tr>
                 </tbody>
            </table>
            <br>
            <div class="row" style="text-align: center;" *ngIf="relatorio.length > 0">
                <div class="col" style="line-height: 2em; border:1px solid #444">
                    <strong>TOTAL VIVA MAX: {{mensalidadeVivamax | currency:'BRL'}}</strong><br>
                </div>
                <div class="col" style="line-height: 2em; border:1px solid #444">
                    <strong>TOTAL CONECTA: {{mensalidadeConecta | currency:'BRL'}}</strong><br>
                </div>
                <div class="col" style="line-height: 2em; border:1px solid #444">
                    <strong>TOTAL: {{mensalidadeTotal | currency:'BRL'}}</strong><br>
                </div>
            </div>
        </div>
    </tab>
    <tab heading="CANCELAMENTOS" style="padding:10px;">
            <div class="row" style="padding:5px;">
                <div class="col col-3">
                    <label>Data Inicio</label>
                    <input type="date" (change)="verificaData(dataInicioAgendamento)" class="form-control" [(ngModel)]="dataInicioCancelado">
                </div>
                <div class="col col-3">
                    <label>Data Fim</label>
                    <input type="date" (change)="verificaData(dataFimAgendamento)" class="form-control" [(ngModel)]="dataFimCancelado">
                </div>
                <div class="col col-6">
                    <label>Motivo Cancelamento</label>
                    <select class="form-control" [(ngModel)]="motivoCancelamento">
                        <option value="" selected></option>
                        <option *ngFor="let mot of motivosCancelamento" [value]="mot.nome">{{mot.nome}}</option>
                    </select>
                </div>
            </div>
            <div class="row" style="padding:5px;">
                <div class="col col-3">
                    <label>Matricula</label>
                    <input type="number" class="form-control" [(ngModel)]="matriculaCancelado">
                </div>
                <div class="col col-5">
                    <label>Nome</label>
                    <input type="text" class="form-control" [(ngModel)]="nomeCancelado">
                </div>
                 <div class="col col-4">
                    <label>Cidade</label>
                    <input type="text" class="form-control" [(ngModel)]="cidadeCancelado">
                </div>
            </div>
            <div class="row" style="padding:5px;">                
                <div class="col col-6">
                    <label>Sub Contrato</label>
                    <ng-select class="form-control" [items]="subContratos" bindLabel="nome" appendTo="body" [(ngModel)]="subContratoCancelado"></ng-select>
                </div>
                <div class="col col-5">
                    <label>Vendedor</label>
                    <select class="form-control" [(ngModel)]="vendedorCancelamento">
                        <option value=""></option>
                        <option [value]="vend.nome" *ngFor="let vend of vendedores">{{vend.nome}}</option>
                    </select>
                </div>
                <div class="col col-1">
                    <br>
                    <button class="btn btn-primary" (click)="buscarCancelados()" [disabled]="!dataInicioCancelado || !dataFimCancelado">
                        <fa-icon [icon]="['fas', 'search']"></fa-icon>
                    </button>
                </div>
            </div>
        <br>
        <div id="divToPrintRel" *ngIf="cancelamentoAba">
            <div class="row">
                <div class="col" style="text-align: center; color:#c7444a; font-weight: bold;">
                    <h2>RELATÓRIO CANCELAMENTOS</h2>
                </div>
            </div>
            <br>
            <table class="table table-striped">
                <thead class="thead-dark">
                    <tr>
                        <th>#</th>
                        <th>Data Adesão</th>
                        <th>Data Cancelamento</th>
                        <th>Matricula</th>
                        <th>Nome</th>
                        <th>Motivo Cancelamento</th>
                        <th>Contrato</th>
                        <th>Mensalidade</th>
                        <th>Vendedor</th>
                        <th>Meses</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let rel of relatorio; let i = index">
                        <td>{{i+1}}</td>
                        <td>{{ rel?.dataAdesao | date: 'dd/MM/yyyy' }}</td>
                        <td>{{ rel?.dataCancelamento | date: 'dd/MM/yyyy' }}</td>
                        <td>{{ rel?.matricula }}</td>
                        <td>{{ rel?.nome }}</td>
                        <td>{{ rel?.motivoCancelamento }}</td>
                        <td>{{ rel?.subContrato }}<span *ngIf="rel?.planoFisicoCheck">PLANO FISICO</span></td>
                        <td>{{ rel?.totalMensalidade | currency:'BRL' }}</td>
                        <td>{{ rel?.vendedor }}</td>
                        <td>{{ rel?.meses }}</td>
                    </tr>
                 </tbody>
            </table>
            <br>
            <div class="row" style="text-align: center;" *ngIf="relatorio.length > 0">
                <div class="col" style="line-height: 2em; border:1px solid #444">
                    <strong>TOTAL VIVA MAX: {{mensalidadeVivamax | currency:'BRL'}}</strong><br>
                </div>
                <div class="col" style="line-height: 2em; border:1px solid #444">
                    <strong>TOTAL CONECTA: {{mensalidadeConecta | currency:'BRL'}}</strong><br>
                </div>
                <div class="col" style="line-height: 2em; border:1px solid #444">
                    <strong>TOTAL: {{mensalidadeTotal | currency:'BRL'}}</strong><br>
                </div>
            </div>
        </div>
    </tab>
    <tab heading="MOVIMENTAÇÃO EXCLUSÃO" style="padding:10px;">
        <div class="row" style="padding:5px;">
            <div class="col col-4">
                <label>Data Inicio</label>
                <input type="date" (change)="verificaData(dataInicioAgendamento)" class="form-control" [(ngModel)]="dataInicioCancelado">
            </div>
            <div class="col col-4">
                <label>Data Fim</label>
                <input type="date" (change)="verificaData(dataFimAgendamento)" class="form-control" [(ngModel)]="dataFimCancelado">
            </div>
            <div class="col col-4">
                <label>Matricula</label>
                <input type="number" class="form-control" [(ngModel)]="matriculaCancelado">
            </div>
        </div>
        <div class="row" style="padding:5px;">
            <div class="col col-6">
                <label>Nome</label>
                <input type="text" class="form-control" [(ngModel)]="nomeCancelado">
            </div>
            <div class="col col-2" style="text-align: right;">
                <br>
                <button class="btn btn-primary btn-block" (click)="buscarCancelados()" [disabled]="!dataInicioCancelado || !dataFimCancelado">BUSCAR</button>
            </div>
        </div>
    <br>
    <div id="divToPrintRel" *ngIf="cancelamentoAba">
        <div class="row">
            <div class="col" style="text-align: center; color:#c7444a; font-weight: bold;">
                <h2>RELATÓRIO MOVIMENTAÇÃO EXCLUSÃO</h2>
            </div>
        </div>
        <br>
        <table class="table table-striped">
            <thead class="thead-dark">
                <tr>
                    <th>#</th>
                    <th>Data Adesão</th>
                    <th>Data Cancelamento</th>
                    <th>Matricula</th>
                    <th>Nome</th>
                    <th>Motivo Cancelamento</th>
                    <th>Contrato</th>
                    <th>Mensalidade</th>
                    <th>Meses</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let rel of relatorio; let i = index">
                    <td>{{i+1}}</td>
                    <td>{{ rel?.dataAdesao | date: 'dd/MM/yyyy' }}</td>
                    <td>{{ rel?.dataCancelamento | date: 'dd/MM/yyyy' }}</td>
                    <td>{{ rel?.matricula }}</td>
                    <td>{{ rel?.nome }}</td>
                    <td>{{ rel?.motivoCancelamento }}</td>
                    <td>{{ rel?.subContrato }}<span *ngIf="rel?.planoFisicoCheck">PLANO FISICO</span></td>
                    <td>{{ rel?.totalMensalidade | currency:'BRL' }}</td>
                    <td>{{ rel?.meses }}</td>
                </tr>
             </tbody>
        </table>
        <br>
        <div class="row" style="text-align: center;" *ngIf="relatorio.length > 0">
            <div class="col" style="line-height: 2em; border:1px solid #444">
                <strong>TOTAL VIVA MAX: {{mensalidadeVivamax | currency:'BRL'}}</strong><br>
            </div>
            <div class="col" style="line-height: 2em; border:1px solid #444">
                <strong>TOTAL CONECTA: {{mensalidadeConecta | currency:'BRL'}}</strong><br>
            </div>
            <div class="col" style="line-height: 2em; border:1px solid #444">
                <strong>TOTAL: {{mensalidadeTotal | currency:'BRL'}}</strong><br>
            </div>
        </div>
    </div>
</tab>
</tabset>
<!-- RELATÓRIO DE SEGUROS 
<div *ngIf="relSeguros">
    <div class="row">
        <div class="col col-8">
            <div class="row align-items-end" style="padding:5px; font-size: 0.85em;">
                <div class="col col-3">
                    <label>Data Inicio</label>
                    <input type="date" (change)="verificaData(dataSegurado)" class="form-control" [(ngModel)]="dataSeguradoInicio">
                </div>
                <div class="col col-3">
                    <label>Data Fim</label>
                    <input type="date" (change)="verificaData(dataSegurado)" class="form-control" [(ngModel)]="dataSeguradoFim">
                </div>
                <div class="col col-6">
                    <label>Tipo do Plano</label>
                    <select class="form-control" [(ngModel)]="tipoPlanoSegurado" (change)="selectTipoPlanoSegurado()">
                        <option value=""></option>
                        <option value="PLANO FISICO">PLANO FISICO</option>
                        <option value="PLANO SERVIDOR">PLANO SERVIDOR</option>
                        <option value="PLANO EMPRESARIAL">PLANO EMPRESARIAL</option>
                    </select>
                </div>
                <div class="col col-6">
                    <label>Filtrar Marcadas</label>
                    <select class="form-control" [(ngModel)]="marcadas">
                        <option value="TODOS">TODOS</option>
                        <option value="marcadas">MARCADAS</option>
                        <option value="nao">NÃO MARCADAS</option>
                    </select>
                </div>
                <div class="col col-6">
                    <label>Exibir Somente</label>
                    <select class="form-control" [(ngModel)]="tipoSegurado">
                        <option value="TITULAR">TITULAR</option>
                        <option value="DEPENDENTE">DEPENDENTE</option>
                    </select>
                </div> 
            </div>
        </div>
        <div class="col col-4">
            <div class="row">
                <div class="col" style="text-align: center;">
                    <label>Nome do Plano</label>
                    <select class="form-control" [(ngModel)]="nomePlanoSeguros" multiple>
                        <option [value]="plano.nome" *ngFor="let plano of planosSegurado">{{plano.nome}}</option>
                    </select>
                     <small>Segure <strong>CTRL</strong> para escolher mais que um plano</small>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <button class="btn btn-primary btn-block" (click)="buscarSegurados()" [disabled]="!dataSeguradoInicio || !dataSeguradoFim">BUSCAR</button>
                </div>
            </div>
        </div>
    </div>
<br>
<div id="divToPrintRel">
    <div class="row">
        <div class="col" style="text-align: center; color:#c7444a; font-weight: bold;">
            <h2>RELATÓRIOS SEGUROS</h2>
        </div>
    </div>
    <br>
    <table class="table table-striped">
        <thead class="thead-dark">
            <tr>
                <th>#</th>
                <th>Adesão</th>
                <th>Matricula</th>
                <th>Nome Completo</th>
                <th>Sexo</th>
                <th>Nascimento</th>
                <th>CPF</th>
                <th>Nº Sorte</th>
                <th>Capital Segurado</th>
            </tr>
        </thead>
        <tbody *ngFor="let rel of relatorio; let i = index">
            <tr *ngIf="tipoSegurado == 'TITULAR'">
                <td>{{i+1}}</td>
                <td>{{ rel?.dataAdesao | date: 'dd/MM/yyyy' }}</td>
                <td>{{ rel?.matricula }}</td>
                <td>{{ rel?.nome }}</td>
                <td>{{ rel?.sexo }}</td>
                <td>{{ rel?.nascimento | date: 'dd/MM/yyyy' }}</td>
                <td>{{ rel?.cpf }}</td>
                <td>{{ rel?.numSorte }}</td>
                <td>{{ rel?.valorCapitalSegurado | currency:'BRL'}}</td>
            </tr>
         </tbody>   
         <tbody *ngFor="let dependente of relatorioDependentes; let i = index">
             <tr *ngIf="tipoSegurado == 'DEPENDENTE'">
                <td>{{i+1}}</td>
                <td>{{ dependente?.dataAdesao | date: 'dd/MM/yyyy' }}</td>
                <td>{{ rel?.matricula }}</td>
                <td>{{ dependente?.nome }}</td>
                <td>
                <span *ngIf="dependente?.tipo == 'FILHO' || dependente?.tipo == 'PAI' || dependente?.tipo == 'SOGRO'">MASCULINO</span>
                <span *ngIf="dependente?.tipo == 'FILHA' || dependente?.tipo == 'MÃE' || dependente?.tipo == 'SOGRA'">FEMININO</span>
                <span *ngIf="dependente?.tipo == 'CÔNJUGE' && dependente?.sexoTitular == 'MASCULINO'">FEMININO</span>
                <span *ngIf="dependente?.tipo == 'CÔNJUGE' && dependente?.sexoTitular == 'FEMININO'">MASCULINO</span>
                </td>
                <td>{{ dependente?.nascimento }}</td>
                <td>{{ dependente?.cpf }}</td>
                <td>{{ dependente?.numSorte }}</td>
                <td>{{ dependente?.valorCapitalSegurado | currency:'BRL'}}</td>
            </tr>
        </tbody> 
    </table>
    </div>
</div> -->
<!-- RELATÓRIO DE ANIVERSARIANTES -->
<div *ngIf="relAniversariantes">
    <div class="row align-items-end" style="padding:5px; font-size:0.85em">
        <div class="col col-2">
            <label>Data Aniversário</label>
            <input type="date" (change)="verificaData(dataAniversario)"class="form-control" [(ngModel)]="dataAniversario">
        </div>        
        <div class="col col-1">
            <br>
            <button class="btn btn-primary" (click)="buscarAniversariantes()" [disabled]="!dataAniversario">BUSCAR</button>
        </div>
    </div>
    <br>
    <div id="divToPrintRel">
        <div class="row">
            <div class="col" style="text-align: center; color:#c7444a; font-weight: bold;">
                <h2>RELATÓRIOS DE ANIVERSARIANTES</h2>
            </div>
        </div>
        <br>
        <table class="table table-striped">
            <thead class="thead-dark">
                <tr>
                    <th>#</th>
                    <th>Matricula</th>
                    <th>Nome</th>
                    <th>Data Nascimento</th>
                    <th>Telefone</th>
                    <th>Celular</th>
                    <th>Whatsapp</th>
                </tr>
            </thead>
            <tbody *ngFor="let rel of relatorio; let i = index">
                <tr>
                    <td>{{i+1}}</td>
                    <td>{{ rel?.matricula }}</td>
                    <td>{{ rel?.nome }}</td>
                    <td>{{ rel?.nascimento | date: 'dd/MM/yyyy' }}</td>
                    <td>{{ rel?.telefone }}</td>  
                    <td>{{ rel?.celular }}</td>  
                    <td>
                        <span *ngIf="rel?.whatsapp == true">
                            <button class="btn btn-success" (click)="sendWhats(rel?.celular)"><fa-icon [icon]="['fab', 'whatsapp']"></fa-icon></button>
                        </span>
                        <span *ngIf="rel?.whatsapp == false">
                            NÃO
                        </span>
                    </td>
                </tr>
            </tbody>
        </table>
        </div>
    </div>
    <!-- RELATÓRIO DE COMBINADOS -->
<div *ngIf="relCombinados">
    <div class="row align-items-end" style="padding:5px; font-size:0.85em">
        <div class="col col-3">
            <label>Data Inicio</label>
            <input type="date" class="form-control" [(ngModel)]="dataInicioComb">
        </div>
        <div class="col col-3">
            <label>Data Fim</label>
            <input type="date" class="form-control" [(ngModel)]="dataFimComb">
        </div>
        <div class="col col-6">
            <label>Titular</label>
            <input type="text" class="form-control" [(ngModel)]="titularComb">
        </div>
    </div>
    <div class="row align-items-end" style="padding:5px; font-size:0.85em">
        <div class="col col-3">
            <label>Tipo do Plano</label>
            <select class="form-control" [(ngModel)]="tipoPlanoComb">
                <option value=""></option>
                <option value="EMPRESARIAL">EMPRESARIAL</option>
                <option value="FISICO">PESSOA FÍSICA</option>
                <option value="SERVIDOR">SERVIDOR PÚBLICO</option>
            </select>
        </div>
        <div class="col col-9">
            <label>SubContrato</label>
            <ng-select class="form-control" [items]="subContratos" bindLabel="nome" appendTo="body" [(ngModel)]="subContratoComb"></ng-select>
        </div>
    </div>
    <div class="row">
        <div class="col col-7">
            <label>Empresa</label>
            <ng-select class="form-control" [items]="empresas" bindLabel="fantasiaEmpresa" appendTo="body" [(ngModel)]="empresaComb"></ng-select>
        </div>
        <div class="col col-4">
            <label>Situação</label>
            <select class="form-control" [(ngModel)]="situacaoPlanoComb" multiple>
                <option value="TODOS">TODOS</option>
                <option value="ATIVO">ATIVO</option>
                <option value="PENDENTE">PENDENTE</option>
                <option value="ALTERAÇÃO">ALTERAÇÃO</option>
                <option value="INADIMPLENTE I">INADIMPLENTE I</option>
                <option value="INADIMPLENTE II">INADIMPLENTE II</option>
                <option value="AFASTADO">AFASTADO</option>
                <option value="AFASTADO INADIMPLENTE">AFASTADO INADIMPLENTE</option>
                <option value="CANCELADO">CANCELADO</option>
            </select>
            <small>Segure <strong>CTRL</strong> para escolher mais que um</small>
        </div>
        <div class="col col-1">
            <br>
            <button class="btn btn-primary" (click)="buscarCombinados()" [disabled]="!dataInicioComb && !dataFimComb && !titularComb && !tipoPlanoComb && !subContratoComb && !empresaComb">
                <fa-icon [icon]="['fas', 'search']"></fa-icon>
            </button>
        </div>
    </div>
    <br>
    <div id="divToPrintRel">
        <div class="row">
            <div class="col" style="text-align: center; color:#c7444a; font-weight: bold;">
                <h2>RELATÓRIO COMBINADO<br> {{subContratoComb?.nome}}{{empresaComb?.fantasiaEmpresa}}</h2>
            </div>
        </div>
        <br>
        <table class="table table-striped">
            <thead class="thead-dark">
                <tr>
                    <th>#</th>
                    <th>Matricula</th>
                    <th>Associado</th>
                    <th>CPF</th>
                    <th>Adesão</th>
                    <th>Tipo Plano</th>
                    <th>Planos Contratados</th>
                    <th>Viva Max</th>
                    <th>Seguros</th>
                    <th>Conecta</th>
                    <th>Total dos Planos</th>
                </tr>
            </thead>
            <tbody *ngFor="let rel of relatorio; let i = index">
                <tr>
                    <td>{{ i+1 }}</td>
                    <td>{{ rel?.matricula }}</td>
                    <td>{{ rel?.nome }}</td>
                    <td>{{ rel?.cpf }}</td>
                    <td>{{ rel?.dataAdesao | date: 'dd/MM/yyyy' }}</td>
                    <td>{{ rel?.subContrato }} <span
                        *ngIf="rel?.planoFisicoCheck && !rel?.planoEmpresarialCheck">PLANO
                        FISICO</span><span
                        *ngIf="!rel?.planoFisicoCheck && rel?.planoEmpresarialCheck">PLANO
                        EMPRESARIAL</span></td>
                    <td>{{ rel?.plano }}</td>
                    <td style="font-weight:500" [style.color]="rel.vivamaxValor > 0 ? '#d9232e' : '#cdcdcd'">{{ rel?.vivamaxValor | currency:'BRL' }}</td>
                    <td style="font-weight:500" [style.color]="rel.segurosValor > 0 ? '#c65652' : '#cdcdcd'">{{ rel?.segurosValor | currency:'BRL'}}</td>
                    <td style="font-weight:500" [style.color]="rel.conectaValor > 0 ? '#00b0ac' : '#cdcdcd'">{{ rel?.conectaValor | currency:'BRL' }}</td>
                    <td style="font-weight:500">{{ rel?.totalMensalidade | currency:'BRL' }}</td>  
                    <td></td>
                </tr>
            </tbody>
        </table>
        <br>
        <div class="row" style="text-align: right;padding-right: 20px;" *ngIf="relatorio.length > 0">
            <div class="col col-8"></div>
            <div class="col col-4" style="line-height: 2em; border:1px solid #444">
                <strong>TOTAL: {{totalCombinados | currency:'BRL'}}</strong><br>
            </div>
        </div>
        <div style="height:3em"></div>
        </div>
    </div>




<!-- RELATÓRIO DE SEGUROS COMBINADO -->
<div *ngIf="relSeguros">
    <div class="row">
        <div class="col col-8">
            <div class="row align-items-end" style="padding:5px; font-size: 0.85em;">
                <div class="col col-3">
                    <label>Data Inicio</label>
                    <input type="date" (change)="verificaData(dataSegurado)" class="form-control" [(ngModel)]="dataSeguradoInicio">
                </div>
                <div class="col col-3">
                    <label>Data Fim</label>
                    <input type="date" (change)="verificaData(dataSegurado)" class="form-control" [(ngModel)]="dataSeguradoFim">
                </div>
                <div class="col col-6">
                    <label>Tipo do Plano</label>
                    <select class="form-control" [(ngModel)]="tipoPlanoSegurado" (change)="selectTipoPlanoSegurado()">
                        <option value=""></option>
                        <option value="PLANO FISICO">PLANO FISICO</option>
                        <option value="PLANO SERVIDOR">PLANO SERVIDOR</option>
                        <option value="PLANO EMPRESARIAL">PLANO EMPRESARIAL</option>
                    </select>
                </div>
                <div class="col col-6">
                    <label>Filtrar Marcadas</label>
                    <select class="form-control" [(ngModel)]="marcadas">
                        <option value="TODOS">TODOS</option>
                        <option value="marcadas">MARCADAS</option>
                        <option value="nao">NÃO MARCADAS</option>
                    </select>
                </div>
                <div class="col col-6">
                    <label>Exibir Somente</label>
                    <select class="form-control" [(ngModel)]="tipoSegurado">
                        <option value="TITULAR">TITULAR</option>
                        <option value="DEPENDENTE">DEPENDENTE</option>
                    </select>
                </div> 
            </div>
        </div>
        <div class="col col-4">
            <div class="row">
                <div class="col" style="text-align: center;">
                    <label>Nome do Plano</label>
                    <select class="form-control" [(ngModel)]="nomePlanoSeguros" multiple>
                        <option [value]="plano.nome" *ngFor="let plano of planosSegurado">{{plano.nome}}</option>
                    </select>
                     <small>Segure <strong>CTRL</strong> para escolher mais que um plano</small>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <button class="btn btn-primary btn-block" (click)="buscarSegurados()" [disabled]="!dataSeguradoInicio || !dataSeguradoFim">BUSCAR</button>
                </div>
            </div>
        </div>
    </div>
<br>
<div id="divToPrintRel">
    <div class="row">
        <div class="col" style="text-align: center; color:#c7444a; font-weight: bold;">
            <h2>RELATÓRIOS SEGUROS</h2>
        </div>
    </div>
    <br>
    <table class="table table-striped">
        <thead class="thead-dark">
            <tr>
                <th>#</th>
                <th>Adesão</th>
                <th>Matricula</th>
                <th>Nome Completo</th>
                <th>Sexo</th>
                <th>Nascimento</th>
                <th>CPF</th>
                <th>Nº Sorte</th>
                <th>Capital Segurado</th>
                <th>motivo</th>
            </tr>
        </thead>
        <tbody *ngFor="let rel of relatorio; let i = index">
            <tr *ngIf="tipoSegurado == 'TITULAR'">
                <td>{{i+1}}</td>
                <td>{{ rel?.dataAdesao | date: 'dd/MM/yyyy' }}</td>
                <td>{{ rel?.matricula }}</td>
                <td>{{ rel?.nome }}</td>
                <td>{{ rel?.sexo }}</td>
                <td>{{ rel?.nascimento | date: 'dd/MM/yyyy' }}</td>
                <td>{{ rel?.cpf }}</td>
                <td>{{ rel?.numSorte }}</td>
                <td>{{ rel?.valorCapitalSegurado | currency:'BRL'}}</td>
                <td>{{ rel?.motivoPendencia }}</td>
            </tr>
         </tbody>   
         <tbody *ngFor="let dependente of relatorioDependentes; let i = index">
             <tr *ngIf="tipoSegurado == 'DEPENDENTE'">
                <td>{{i+1}}</td>
                <td>{{ dependente?.dataAdesao | date: 'dd/MM/yyyy' }}</td>
                <td>{{ dependente?.matricula }}</td>
                <td>{{ dependente?.nome }}</td>
                <td>
                <span *ngIf="dependente?.tipo == 'FILHO' || dependente?.tipo == 'PAI' || dependente?.tipo == 'SOGRO'">MASCULINO</span>
                <span *ngIf="dependente?.tipo == 'FILHA' || dependente?.tipo == 'MÃE' || dependente?.tipo == 'SOGRA'">FEMININO</span>
                <span *ngIf="dependente?.tipo == 'CÔNJUGE' && dependente?.sexoTitular == 'MASCULINO'">FEMININO</span>
                <span *ngIf="dependente?.tipo == 'CÔNJUGE' && dependente?.sexoTitular == 'FEMININO'">MASCULINO</span>
                </td>
                <td>{{ dependente?.nascimento }}</td>
                <td>{{ dependente?.cpf }}</td>
                <td>{{ dependente?.numSorte }}</td>
                <td>{{ dependente?.valorCapitalSegurado | currency:'BRL'}}</td>
            </tr>
        </tbody> 
    </table>
    </div>
</div>

</div>