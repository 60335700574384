import { Component, OnInit } from '@angular/core';
import { DataBaseService } from '../provider.service';
import { MASKS } from 'ng-brazil';
import { AppComponent } from '../app.component';

@Component({
  selector: 'app-marketing',
  templateUrl: './marketing.component.html',
  styleUrls: ['./marketing.component.css']
})

export class MarketingComponent implements OnInit {
  public MASKS = MASKS; 
  arrayUsuarios:any = [];
  arrayEnvio:any = [];
  dtInicio:any = "";
  dtFim:any = "";
  cidade:any = "";
  origemEmpresa:any = "";
  empresa:any = "";
  subContrato:any = "";
  tipoPlano:any = "";
  plano:any = "";
  checkAll:boolean = false;
  tituloMensagem:any = "";
  mensagem:any = "";
  subContratos:any = [];
  empresas:any = [];
  planos:any = [];
  
  constructor(public db:DataBaseService, public app:AppComponent) { }

  ngOnInit(): void {
    this.db.getSubContratos().subscribe((dataSub:any) => {
      this.subContratos = dataSub;
      this.db.getEmpresas().subscribe((dataEmp:any) => {
        this.empresas = dataEmp;
        this.db.getPlanos().subscribe((dataPlan:any) => {
          this.planos = dataPlan;
        })
      })
    })
  }
  
  buscarByData(dtI:any,dtF:any){
    this.db.getAssociadosByAdesaoMkt(dtI,dtF).subscribe((data:any) => {
      this.arrayUsuarios = data;
    })
  }

  buscarByCidade(cidade:any){
    this.db.getAssociadosByCidadeMkt(cidade).subscribe((data:any) => {
      this.arrayUsuarios = data;
    })
  }

  buscarByOrigemEmpresa(origem:any){
    this.db.getAssociadoByOrigemEmpresaMkt(origem).subscribe((data:any) => {
      this.arrayUsuarios = data;
    })
  }

  buscarByEmpresa(cod:any){
    this.db.getAssociadoByEmpresaMkt(cod).subscribe((data:any) => {
      this.arrayUsuarios = data;
    })
  }

  buscarByPlanos(plano:any){
    this.db.getAssociadoByPlanoMkt(plano).subscribe((data:any) => {
      this.arrayUsuarios = data;
    })
  }

  buscarByTipoPlano(tipoPlano:any){
    this.db.getAssociadosByTipoPlanoMkt(tipoPlano).subscribe((data:any) => {
      this.arrayUsuarios = data;
    })
  }

  buscarBySubContrato(subContrato:any){
    this.db.getAssociadoBySubContratooMkt(subContrato).subscribe((data:any) => {
      this.arrayUsuarios = data;
    })
  }

  checkUsuario(e,id){
   let marcado = e.srcElement.checked;
   let i = this.arrayUsuarios.map(function(item){
     return item._id.$oid;
   }).indexOf(id)
   if(i >= 0){
     if(marcado == true){
       this.arrayUsuarios[i].checked = true
     }else{
      this.arrayUsuarios[i].checked = false
     }
   }
  }

  checkTable(e){
    let all = e.srcElement.checked;
    if(all){
      this.arrayUsuarios.map((assoc:any) => {
        return assoc.checked = true;
      })
    }else{
      this.arrayUsuarios.map((assoc:any) => {
        return assoc.checked = false;
      })
    }
  }

  enviarSMS(){
   /*  if(window.confirm("Deseja realmente enviar SMS para "+this.arrayEnvio.length+" Associados?")){
      let control = 1;
      let mensagem = this.mensagem.replace(/\s/g, '%20');
      let array = [];
      this.arrayEnvio.forEach(function(item){
         array.push(
          item.celular.replace('(','').replace(')','').replace('-','').replace(' ','')
         )
         if(this.arrayEnvio.length == control){
          this.db.envioSMSMassa(array,mensagem).subscribe(res => {
            console.log(res);
            this.app.openAlert("Envio Realizado com Sucesso!");
          }, err => console.log(err))
         }else{
           control++
         }
      }.bind(this));
    } */
  }

  enviarPush(){
    if(window.confirm("Deseja realmente enviar Notificação Push para "+this.arrayEnvio.length+" Associados?")){
      let ctrl = 1;
      this.arrayEnvio.forEach((item:any) => {
        if(item.arnDevice){
          this.db.envioPush(item.arnDevice, this.mensagem, this.tituloMensagem).subscribe(res => {
            console.log(res);
          }, err => {
            console.log(err);
          });
        }
        if(ctrl == this.arrayEnvio.length){
          this.app.openAlert("Envio Realizado com Sucesso!");
          this.closeEnviarMassa();
        }else{
          ctrl++
        }
      });
    }
  }

  enviarPushMassa(){
    if(window.confirm("Deseja realmente enviar Notificação Para Todos Usuários?")){
      this.db.envioPushMassa(this.mensagem, this.tituloMensagem).subscribe(res => {
        console.log(res);
        this.app.openAlert("Envio Realizado com Sucesso!");
        this.closeEnviarMassa();
      }, err => {
        console.log(err);
        this.app.openAlert("Falha ao enviar: "+err)
      });
    }
  }

  enviarMassa(){
    this.arrayEnvio = [];
    this.arrayUsuarios.forEach(function(item){
      if(item.checked == true){
        this.arrayEnvio.push(item)
      }
    }.bind(this))
    let modal = document.getElementById('modalMassa');
    modal.style.display = "block";
  }

  closeEnviarMassa(){
    let modal = document.getElementById('modalMassa');
    modal.style.display = "none"
    this.arrayEnvio = [];
    this.mensagem = "";
    this.tituloMensagem = "";
  }


}
