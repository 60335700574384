import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { DataBaseService } from '../provider.service';
import * as moment from 'moment';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})

export class DashboardComponent implements OnInit {
  dataHoje:any = "";
  mes:any="";
  ano:any = "";
  associados:any = [];
  totalAtivos:number = 0;
  totalPendentes:number = 0;
  ativosMes:number = 0;
  ativosMesAnterior:number = 0;
  ativosGeral:number = 0;
  totalCancelados:number = 0;
  ticketMedioMes:number = 0;
  ticketMedio:number = 0;
  totalMensalidades:number = 0;
  totalMensalidadesMes:number = 0;
  totalPlanos:number = 0;
  barChartOptions = {
    scaleShowVerticalLines: false,
    responsive: true,
    maintainAspectRatio: false
  };
  barChartType = 'line';
  barChartLegend = true;
  barChartData = [];
  barChartLabels = []; 
  totalEntradasMes:number = 0;
  totalSaidasMes:number = 0;
  totalLucroMes:number = 0;
  totalEntradasMesAnterior:number = 0;
  totalSaidasMesAnterior:number = 0;
  totalLucroMesAnterior:number = 0;
  percentualTotalEntradasMes:number = 0;
  percentualTotalSaidasMes:number = 0;
  percentualTotalLucroMes:number = 0;
  percentualAtivos:number = 0;
  cancelados:any = [];
  todosCancelamentos:any = [];
  motivosCancelamento:any = [];
  totalCancelamentos:number = 0;
  dataInicio:any = "";
  dataFim:any = "";
  totalCancelamentosAno:number = 0;
  totalFaturamentoAcumulado:number = 0;
  totalAdesoes:number = 0;
  totalResultadoAdesoes:number = 0;
  totalFaturamento:number = 0;
  relatorioFaturamento:any = [];
  relFaturamentoFisico:any = [];
  subContratos:any = [];
  totalCancelamentosAnoFisico:number = 0;
  totalFaturamentoAcumuladoFisico:number = 0;
  totalAdesoesFisico:number = 0;
  totalResultadoAdesoesFisico:number = 0;
  totalFaturamentoFisico:number = 0;
  todosAtivosMesAnterior:number = 0;
  todosAtivosMes:number = 0;
  constructor(public auth:AuthService, public db:DataBaseService) { }

  ngOnInit(){
    this.associados = [];
    this.cancelados = [];
    this.motivosCancelamento = [];
    this.db.getConfigs().subscribe((z:any) => {
      this.motivosCancelamento = z[13].motivosCancelamento;
      this.dataInicio = moment().startOf('year').format("YYYY-MM-DD");
      this.dataFim = moment().endOf('month').format("YYYY-MM-DD");
      this.dataHoje = new Date(Date.now() - 10800000).toISOString().split('T')[0];
      let mes:any = this.dataHoje.split('-')[1]
      let ano:any = this.dataHoje.split('-')[0]
      let ctrl = 1;
      this.totalMensalidades = 0;
      this.totalMensalidadesMes = 0;
      this.totalAtivos = 0;
      this.totalCancelados = 0;
      this.ativosMes = 0;
      this.totalPendentes = 0;
      this.ativosGeral = 0;
      switch(mes){
        case '01': this.mes = "JANEIRO";break;
        case '02': this.mes = "FEVEREIRO";break;
        case '03': this.mes = "MARÇO";break;
        case '04': this.mes = "ABRIL";break;
        case '05': this.mes = "MAIO";break;
        case '06': this.mes = "JUNHO";break;
        case '07': this.mes = "JULHO";break;
        case '08': this.mes = "AGOSTO";break;
        case '09': this.mes = "SETEMBRO";break;
        case '10': this.mes = "OUTUBRO";break;
        case '11': this.mes = "NOVEMBRO";break;
        case '12': this.mes = "DEZEMBRO";break;
        default: break;
      }
      this.db.getAssociadosProjection('"dataAdesao":1,"situacao":1,"totalMensalidade":1,"motivoCancelamento":1,"origem":1,"dataCancelamento":1,"subContrato":1').subscribe((data:any) => { 
        this.associados = data;
        this.associados.forEach((item:any) => {
          let dtAdesao:any = moment(item.dataAdesao).format('YYYY-MM-DD');
          if(item.situacao != "CANCELADO"){
            if(item.situacao != "PENDENTE"){
              this.totalAtivos++;
              if(dtAdesao >= moment().startOf('month').format('YYYY-MM-DD') && dtAdesao <= moment().endOf('month').format('YYYY-MM-DD')){
                this.totalMensalidadesMes += item?.totalMensalidade;
                this.ativosMes++;
              }
              if(item?.totalMensalidade){
                this.totalMensalidades += item?.totalMensalidade;
              }
              this.ativosGeral++;
            }
            if(item.situacao == "PENDENTE"){
              this.totalPendentes++;
            }
          }else{
              if(item.situacao == "CANCELADO" || item.situacao == "ALTERAÇÃO"){
                this.cancelados.push(item)
              }
              if(item.motivoCancelamento != "MOVIMENTAÇÃO - EXCLUSÃO" && item.motivoCancelamento != "FALTA DO 1º PAGAMENTO" && item.motivoCancelamento != "ABANDONO DE CARRINHO" && item.motivoCancelamento != "EXCLUSÃO POR FALTA DE BAIXA DE PENDÊNCIA" && item.motivoCancelamento != "MIGRAÇÃO VIVA MAX NOVOS PRODUTOS"){
                  this.totalCancelados++;
              }
            }
          if(ctrl == this.associados.length){
            this.ticketMedioMes = this.totalMensalidadesMes / this.ativosMes;
            this.ticketMedio = this.totalMensalidades / this.ativosGeral;
            this.db.getSubContratosProjection('"regiao":1,"nome":1').subscribe((resData:any) => {
              this.subContratos = resData;
              this.getChart(ano);
            this.buscarPercentual();
            })
          }else{
            ctrl++
          }
        }) 
      });
      this.todosAtivosMes = 0;
      this.todosAtivosMesAnterior = 0;
      this.db.getMetricas().subscribe((dataM:any) => {
        this.todosAtivosMesAnterior = dataM[1].associadosAtivos;
        this.todosAtivosMes = dataM[0].associadosAtivos;
        this.percentualAtivos = this.totalAtivos < this.todosAtivosMesAnterior ? ((this.todosAtivosMesAnterior / this.totalAtivos)+1) * 100 : ((this.totalAtivos / this.todosAtivosMesAnterior)-1) * 100;
     })
    })
  }
  
  buscarPercentual(){
    let ctrl = 1;
    this.totalCancelamentos = 0;
    this.todosCancelamentos = this.cancelados.filter((u:any) => {
      return u.dataCancelamento >= this.dataInicio && u.dataCancelamento <= this.dataFim;
    });
    this.todosCancelamentos.forEach((user:any) => {
      let index = this.motivosCancelamento.map((m:any) => {
        return m.nome;
      }).indexOf(user.motivoCancelamento)
      if(index >= 0){
        if(this.motivosCancelamento[index].total){
          this.motivosCancelamento[index].total += 1;
        }else{
          this.motivosCancelamento[index].total = 1;
        }
        this.totalCancelamentos++;
      }
      if(this.todosCancelamentos.length == ctrl){
        this.motivosCancelamento.sort((a:any,b:any) => {
          if(a.total > b.total){
            return -1;
          }
          return 1;
        })
      }else{
        ctrl++;
      }
    })
  }
  
  getChart(ano){
    this.ano = Number(ano);
    this.relatorioFaturamento = [];
    this.relFaturamentoFisico = [];
    let ctrl = 1;
    this.totalCancelamentosAno = 0;
    this.totalFaturamentoAcumulado = 0;
    this.totalCancelamentosAnoFisico = 0;
    this.totalFaturamentoAcumuladoFisico = 0;
    this.totalAdesoes = 0;
    this.totalFaturamento = 0;
    this.totalAdesoesFisico = 0;
    this.totalFaturamentoFisico = 0;
    let arrayAdesao = [0,0,0,0,0,0,0,0,0,0,0,0];
    let arrayCancelamento = [0,0,0,0,0,0,0,0,0,0,0,0];
    let arrayAgendamento = [0,0,0,0,0,0,0,0,0,0,0,0];
    let arrayPendentes = [0,0,0,0,0,0,0,0,0,0,0,0];
    let arrayAlteracao = [0,0,0,0,0,0,0,0,0,0,0,0];
    let arrayTotalPlanos = [0,0,0,0,0,0,0,0,0,0,0,0];
    this.associados.forEach((item:any) => {
      let anoAdesao = item.dataAdesao.split('-')[0];
      if(ano == anoAdesao && ((item.situacao == "ATIVO" || item.situacao == "CANCELADO" ) && item.origem != "INCLUSÃO EMPRESA")){
        let dateAdesao = moment(item.dataAdesao);
        let n = dateAdesao.month();
        switch(n){
          case 0 : arrayTotalPlanos[0] = arrayTotalPlanos[0] + 1;break;
            case 1 : arrayTotalPlanos[1] = arrayTotalPlanos[1] + 1;break;
            case 2 : arrayTotalPlanos[2] = arrayTotalPlanos[2] + 1;break;
            case 3 : arrayTotalPlanos[3] = arrayTotalPlanos[3] + 1;break;
            case 4 : arrayTotalPlanos[4] = arrayTotalPlanos[4] + 1;break;
            case 5 : arrayTotalPlanos[5] = arrayTotalPlanos[5] + 1;break;
            case 6 : arrayTotalPlanos[6] = arrayTotalPlanos[6] + 1;break;
            case 7 : arrayTotalPlanos[7] = arrayTotalPlanos[7] + 1;break;
            case 8 : arrayTotalPlanos[8] = arrayTotalPlanos[8] + 1;break;
            case 9 : arrayTotalPlanos[9] = arrayTotalPlanos[9] + 1;break;
            case 10 : arrayTotalPlanos[10] = arrayTotalPlanos[10] + 1;break;
            case 11 : arrayTotalPlanos[11] = arrayTotalPlanos[11] + 1;break;
            default: break;
        }
      }
      if(ano == anoAdesao && (item.situacao == "ATIVO")){
        let dateAdesao = moment(item.dataAdesao)
        let n = dateAdesao.month();
        switch (n) {
          case 0 : arrayAdesao[0] = arrayAdesao[0] + 1;break;
          case 1 : arrayAdesao[1] = arrayAdesao[1] + 1;break;
          case 2 : arrayAdesao[2] = arrayAdesao[2] + 1;break;
          case 3 : arrayAdesao[3] = arrayAdesao[3] + 1;break;
          case 4 : arrayAdesao[4] = arrayAdesao[4] + 1;break;
          case 5 : arrayAdesao[5] = arrayAdesao[5] + 1;break;
          case 6 : arrayAdesao[6] = arrayAdesao[6] + 1;break;
          case 7 : arrayAdesao[7] = arrayAdesao[7] + 1;break;
          case 8 : arrayAdesao[8] = arrayAdesao[8] + 1;break;
          case 9 : arrayAdesao[9] = arrayAdesao[9] + 1;break;
          case 10 : arrayAdesao[10] = arrayAdesao[10] + 1;break;
          case 11 : arrayAdesao[11] = arrayAdesao[11] + 1;break;
          default: break;
        }
      }
      if(ano == anoAdesao && item.situacao == "PENDENTE"){
        let dateAdesao = moment(item.dataAdesao)
        let u = dateAdesao.month();
        switch(u){
          case 0 : arrayPendentes[0] = arrayPendentes[0] + 1;break;
          case 1 : arrayPendentes[1] = arrayPendentes[1] + 1;break;
          case 2 : arrayPendentes[2] = arrayPendentes[2] + 1;break;
          case 3 : arrayPendentes[3] = arrayPendentes[3] + 1;break;
          case 4 : arrayPendentes[4] = arrayPendentes[4] + 1;break;
          case 5 : arrayPendentes[5] = arrayPendentes[5] + 1;break;
          case 6 : arrayPendentes[6] = arrayPendentes[6] + 1;break;
          case 7 : arrayPendentes[7] = arrayPendentes[7] + 1;break;
          case 8 : arrayPendentes[8] = arrayPendentes[8] + 1;break;
          case 9 : arrayPendentes[9] = arrayPendentes[9] + 1;break;
          case 10 : arrayPendentes[10] = arrayPendentes[10] + 1;break;
          case 11 : arrayPendentes[11] = arrayPendentes[11] + 1;break;
          default: break;
        }
      }
      if(item.situacao == "CANCELADO" && (item.motivoCancelamento != "MOVIMENTAÇÃO - EXCLUSÃO" && item.motivoCancelamento != "MIGROU DE PLANO - VIVA MAX" && item.motivoCancelamento != "EXCLUSÃO DE PLANO POR FALTA DE CADASTRO DE CARTÃO" && item.motivoCancelamento != "ABANDONO DE CARRINHO" && item.motivoCancelamento != "EXCLUSÃO POR FALTA DE BAIXA DE PENDÊNCIA" && item.motivoCancelamento != "MIGRAÇÃO VIVA MAX NOVOS PRODUTOS")){
      let anoCancelamento = item.dataCancelamento.split('-')[0];
      if(ano == anoCancelamento){
        let dateCancelamento = moment(item.dataCancelamento)
        let c = dateCancelamento.month();
        switch(c){
          case 0 : arrayCancelamento[0] = arrayCancelamento[0] + 1;break;
            case 1 : arrayCancelamento[1] = arrayCancelamento[1] + 1;break;
            case 2 : arrayCancelamento[2] = arrayCancelamento[2] + 1;break;
            case 3 : arrayCancelamento[3] = arrayCancelamento[3] + 1;break;
            case 4 : arrayCancelamento[4] = arrayCancelamento[4] + 1;break;
            case 5 : arrayCancelamento[5] = arrayCancelamento[5] + 1;break;
            case 6 : arrayCancelamento[6] = arrayCancelamento[6] + 1;break;
            case 7 : arrayCancelamento[7] = arrayCancelamento[7] + 1;break;
            case 8 : arrayCancelamento[8] = arrayCancelamento[8] + 1;break;
            case 9 : arrayCancelamento[9] = arrayCancelamento[9] + 1;break;
            case 10 : arrayCancelamento[10] = arrayCancelamento[10] + 1;break;
            case 11 : arrayCancelamento[11] = arrayCancelamento[11] + 1;break;
            default: break;
        }
        if(item.subContrato){
          let idxFat = this.relatorioFaturamento.map((o:any) => {
            return o.subContrato;
          }).indexOf(item.subContrato);
          if(idxFat >= 0){
            this.relatorioFaturamento[idxFat].cancelados += 1;
            this.relatorioFaturamento[idxFat].fatCancelados += item.totalMensalidade;
          }else{
            let regiaoResp:any = "";
            let idxRegiao = this.subContratos.map((s:any) => {
              return s.nome;
            }).indexOf(item.subContrato);
            if(idxRegiao >= 0){
              regiaoResp = this.subContratos[idxRegiao].regiao;
            }
            let dTemp = {
              subContrato : item.subContrato,
              cancelados : 1,
              adesoes: 0,
              fatCancelados : item.totalMensalidade,
              faturamento:0,
              responsavel: regiaoResp
            }
            this.relatorioFaturamento.push(dTemp);
          }
          this.totalCancelamentosAno++;
          this.totalFaturamentoAcumulado += item.totalMensalidade;
        }else{
          let idxFatF = this.relFaturamentoFisico.map((o:any) => {
            return o.subContrato;
          }).indexOf("PLANOS FÍSICOS");
          if(idxFatF >= 0){
            this.relFaturamentoFisico[idxFatF].cancelados += 1;
            this.relFaturamentoFisico[idxFatF].fatCancelados += item.totalMensalidade;
          }else{
            let dTempFisico = {
              subContrato : "PLANOS FÍSICOS",
              cancelados : 1,
              adesoes: 0,
              fatCancelados : item.totalMensalidade,
              faturamento:0
            }
            this.relFaturamentoFisico.push(dTempFisico);
          }
          this.totalCancelamentosAnoFisico++;
          this.totalFaturamentoAcumuladoFisico += item.totalMensalidade;
        }
      }
    }
    if(ano == anoAdesao && item.situacao == "ALTERAÇÃO"){
      let dateAdesao = moment(item.dataAdesao)
      let n = dateAdesao.month();
      switch(n){
        case 0 : arrayAlteracao[0] = arrayAlteracao[0] + 1;break;
        case 1 : arrayAlteracao[1] = arrayAlteracao[1] + 1;break;
        case 2 : arrayAlteracao[2] = arrayAlteracao[2] + 1;break;
        case 3 : arrayAlteracao[3] = arrayAlteracao[3] + 1;break;
        case 4 : arrayAlteracao[4] = arrayAlteracao[4] + 1;break;
        case 5 : arrayAlteracao[5] = arrayAlteracao[5] + 1;break;
        case 6 : arrayAlteracao[6] = arrayAlteracao[6] + 1;break;
        case 7 : arrayAlteracao[7] = arrayAlteracao[7] + 1;break;
        case 8 : arrayAlteracao[8] = arrayAlteracao[8] + 1;break;
        case 9 : arrayAlteracao[9] = arrayAlteracao[9] + 1;break;
        case 10 : arrayAlteracao[10] = arrayAlteracao[10] + 1;break;
        case 11 : arrayAlteracao[11] = arrayAlteracao[11] + 1;break;
        default: break;
      }
      if(item.subContrato){
        let idxFat = this.relatorioFaturamento.map((o:any) => {
          return o.subContrato;
        }).indexOf(item.subContrato);
        if(idxFat >= 0){
          this.relatorioFaturamento[idxFat].adesoes += 1;
          this.relatorioFaturamento[idxFat].faturamento += item.totalMensalidade;
        }else{
          let idxRegiao = this.subContratos.map((s:any) => {
            return s.nome;
          }).indexOf(item.subContrato);
          let dTemp = {
            subContrato : item.subContrato,
            adesoes : 1,
            cancelados : 0,
            fatCancelados : 0,
            totalFaturamento : 0,
            faturamento: item.totalMensalidade,
            responsavel: this.subContratos[idxRegiao].regiao
          }
          this.relatorioFaturamento.push(dTemp);
        }
        this.totalAdesoes++;
        this.totalFaturamento += item.totalMensalidade;
      }else{
        let idxFatF = this.relFaturamentoFisico.map((o:any) => {
          return o.subContrato;
        }).indexOf("PLANOS FÍSICOS");
        if(idxFatF >= 0){
          this.relFaturamentoFisico[idxFatF].adesoes += 1;
          this.relFaturamentoFisico[idxFatF].faturamento += item.totalMensalidade;
        }else{
          let dTempFisico = {
            subContrato : "PLANOS FÍSICOS",
            adesoes : 1,
            cancelados: 0,
            fatCancelados : 0,
            faturamento : item.totalMensalidade
          }
          this.relFaturamentoFisico.push(dTempFisico);
        }
        this.totalAdesoesFisico++;
        this.totalFaturamentoFisico += item.totalMensalidade;
      }
    }
      if(ctrl == this.associados.length){
        this.db.getAgendamentosTodosProjection('"agendamentos":1').subscribe((data:any) => {
          let controle = 1;
          data.forEach((item:any) => {
            item.agendamentos.forEach((agenda) => {
              let anoAgendamento = agenda.agendadoParaMedico.split('-')[0];
              if(ano == anoAgendamento){
                let dateAgendamento = moment(agenda.agendadoParaMedico)
                let d = dateAgendamento.month();
                switch(d){
                  case 0 : arrayAgendamento[0] = arrayAgendamento[0] + 1;break;
                  case 1 : arrayAgendamento[1] = arrayAgendamento[1] + 1;break;
                  case 2 : arrayAgendamento[2] = arrayAgendamento[2] + 1;break;
                  case 3 : arrayAgendamento[3] = arrayAgendamento[3] + 1;break;
                  case 4 : arrayAgendamento[4] = arrayAgendamento[4] + 1;break;
                  case 5 : arrayAgendamento[5] = arrayAgendamento[5] + 1;break;
                  case 6 : arrayAgendamento[6] = arrayAgendamento[6] + 1;break;
                  case 7 : arrayAgendamento[7] = arrayAgendamento[7] + 1;break;
                  case 8 : arrayAgendamento[8] = arrayAgendamento[8] + 1;break;
                  case 9 : arrayAgendamento[9] = arrayAgendamento[9] + 1;break;
                  case 10 : arrayAgendamento[10] = arrayAgendamento[10] + 1;break;
                  case 11 : arrayAgendamento[11] = arrayAgendamento[11] + 1;break;
                  default: break;
                }
              }
            })
          if(controle == data.length){
            this.barChartLabels = ["Jan/"+ano,"Fev/"+ano,"Mar/"+ano,"Abr/"+ano,"Mai/"+ano,"Jun/"+ano,"Jul/"+ano,"Ago/"+ano,"Set/"+ano,"Out/"+ano,"Nov/"+ano,"Dez/"+ano,]
            this.barChartData = [
              {data: arrayCancelamento, label: 'Cancelamentos'},
              {data: arrayAdesao, label: 'Ativos'},
              {data: arrayAgendamento, label: 'Agendamentos'},
              {data: arrayPendentes, label: 'Pendentes', backgroundColor: "rgba(18, 204, 64, 0.5)", borderColor: "rgba(6, 143, 41, 0.6)"},
              {data: arrayTotalPlanos, label: 'Vendas', backgroundColor: "rgba(204, 18, 173, 0.5)", borderColor: "rgba(141, 8, 119, 0.6)"},
              {data: arrayAlteracao, label: 'Alteração'}
            ]
            this.relatorioFaturamento = this.relatorioFaturamento.sort((a:any,b:any) => {
              if(a.regiao < b.regiao){return -1;}
              return 1;
            })
          }else{
            controle++
          }
        })
      })
      }else{
        ctrl++
      }
    })
    this.db.getCaixaDash(moment().subtract(1,'month').startOf('month').format('YYYY-MM-DD'),moment().endOf('month').format('YYYY-MM-DD')).subscribe((data:any) => {
      this.totalSaidasMes = 0;
      this.totalEntradasMes = 0;
      this.totalLucroMes = 0;
      this.totalSaidasMesAnterior = 0;
      this.totalEntradasMesAnterior = 0;
      this.totalLucroMesAnterior = 0;
      let control = 1;
      let inicioMesAnterior = moment().subtract(1,'month').startOf('month').format('YYYY-MM-DD');
      let fimMesAnterior = moment().subtract(1,'month').endOf('month').format('YYYY-MM-DD');
      data.forEach((item:any) => {
        if(item.dataPagamento >= inicioMesAnterior && item.dataPagamento <= fimMesAnterior){
          if(item.tipo == "Saida"){
            this.totalSaidasMesAnterior += item.valorTotal;
          }
          if(item.tipo == "Entrada"){
            this.totalEntradasMesAnterior += item.valorTotal;
          }
        }else{
          if(item.tipo == "Saida"){
            this.totalSaidasMes += item.valorTotal;
          }
          if(item.tipo == "Entrada"){
            this.totalEntradasMes += item.valorTotal;
          }
        }
        if(control == data.length){
          this.totalLucroMes = this.totalEntradasMes - this.totalSaidasMes;
          this.totalLucroMesAnterior = this.totalEntradasMesAnterior - this.totalSaidasMesAnterior;
          this.percentualTotalEntradasMes = ((this.totalEntradasMes / this.totalEntradasMesAnterior)-1) * 100;
          this.percentualTotalSaidasMes = ((this.totalSaidasMes / this.totalSaidasMesAnterior)-1) * 100;
          this.percentualTotalLucroMes = ((this.totalLucroMes / this.totalLucroMesAnterior)-1) * 100;
        }else{
          control++
        }
      })
    })
  }

}
