import { Component, OnInit } from '@angular/core';
import { AppComponent } from '../app.component';
import { DataBaseService } from '../provider.service';

@Component({
  selector: 'app-tarefas',
  templateUrl: './tarefas.component.html',
  styleUrls: ['./tarefas.component.css']
})
export class TarefasComponent implements OnInit {
  dataHoje:any = "";
  user:any = "";
  userId:any = "";
  tarefas:any = [];
  nomeUsuario:any = "";
  dataInicio:any = "";
  dataFim:any = "";
  todasTarefas:any = [];
  usuarios:any = [];
  tituloTarefa:any = "";
  descricaoTarefa:any = "";
  idResponsavelTarefa:any = "";
  responsavelTarefa:any = "";
  prioridadeTarefa:any = "";
  taskSelecionada:any = [];
  idAntigo:any = "";
  idNovo:any = "";
  responsavelAntigo:any = "";
  observacaoTarefa:any = "";

  constructor(public db:DataBaseService, public app:AppComponent){
    this.dataHoje = new Date(Date.now() - 10800000).toISOString().split('T')[0];
    this.user = localStorage.getItem('usuarioVivamax');
    this.userId = localStorage.getItem('idUserVivamax');
   }

  ngOnInit(){
      this.buscarTodasTarefasPendentes();
  }
  
  buscarTodasTarefasPendentes(){
    this.db.getAllUsuariosComTarefas().subscribe((data:any) => {
      this.usuarios = data;
      this.todasTarefas = [];
      this.tarefas = [];
      let ctrl = 1;
      this.usuarios.forEach((u) => {
        if(u.tarefas.length > 0){
          u.tarefas.forEach((t) => {
            this.todasTarefas.push(t)
          })
        }
        if(this.usuarios.length == ctrl){
          this.tarefas = this.todasTarefas.filter(item => {
            return item.resolvido == false;
          });
          this.tarefas = this.tarefas.sort((a,b) => {
            if(a.data > b.data){return -1}
            if(a.data < b.data){return 1}
            if(a.hora < b.hora){return 1}
            if(a.hora > b.hora){return -1}
            return 0;
          })
        }else{
          ctrl++
        }
      })
    });
  }

  buscarTodasTarefas(){
    this.db.getAllUsuariosComTarefas().subscribe((data:any) => {
    this.usuarios = data;
    this.todasTarefas = [];
    this.tarefas = [];
    let ctrl = 1;
      this.usuarios.forEach((u) => {
        if(u.tarefas.length > 0){
          u.tarefas.forEach((t) => {
            this.todasTarefas.push(t)
          })
        }
        if(this.usuarios.length == ctrl){
          this.tarefas = this.todasTarefas.sort((a,b) => {
            if(a.data > b.data){return -1}
            if(a.data < b.data){return 1}
            if(a.hora < b.hora){return 1}
            if(a.hora > b.hora){return -1}
            return 0;
          })
        }else{
          ctrl++
        }
      })
    });
  }

  buscarTarefasData(dtI,dtF){
    this.db.getAllUsuariosComTarefas().subscribe((data:any) => {
      this.usuarios = data;
      this.tarefas = this.todasTarefas.filter(item => {
        return item.data >= dtI && item.data <= dtF;
      });
      this.tarefas = this.tarefas.sort((a,b) => {
        if(a.data > b.data){return -1}
        if(a.data < b.data){return 1}
        if(a.hora < b.hora){return 1}
        if(a.hora > b.hora){return -1}
        return 0;
      })
    });
  }

  buscarTarefas(key){
    if(this.nomeUsuario == "" && this.dataInicio == "" && this.dataFim == ""){
      this.tarefas = this.todasTarefas.filter(item => {
        return item.resolvido == key;
      });
    }
    if(this.nomeUsuario != "" && this.dataInicio == "" && this.dataFim == "" ){
      this.tarefas = this.todasTarefas.filter(item => {
        return item.resolvido == key && item.responsavel == this.nomeUsuario;
      });
    }
    if(this.nomeUsuario == "" && this.dataInicio != "" && this.dataFim != "" ){
      this.tarefas = this.todasTarefas.filter(item => {
        return item.resolvido == key && item.data >= this.dataInicio && item.data <= this.dataFim;
      });
    }
    if(this.nomeUsuario != "" && this.dataInicio != "" && this.dataFim != "" ){
      this.tarefas = this.todasTarefas.filter(item => {
        return item.resolvido == key && item.responsavel == this.nomeUsuario && item.data >= this.dataInicio && item.data <= this.dataFim;
      });
    }
    this.tarefas = this.tarefas.sort((a,b) => {
      if(a.data > b.data){return -1}
      if(a.data < b.data){return 1}
      if(a.hora < b.hora){return 1}
      if(a.hora > b.hora){return -1}
      return 0;
    })
  }

  selectUsuario(nome){
    if(nome == ""){
      this.tarefas = this.todasTarefas;
      this.tarefas = this.tarefas.sort((a,b) => {
        if(a.data > b.data){return -1}
        if(a.data < b.data){return 1}
        if(a.hora < b.hora){return 1}
        if(a.hora > b.hora){return -1}
        return 0;
      })
    }else{
      this.tarefas = this.todasTarefas.filter(item => {
        return item.responsavel == nome;
      });
      this.tarefas = this.tarefas.sort((a,b) => {
          if(a.data > b.data){return -1}
          if(a.data < b.data){return 1}
          if(a.hora < b.hora){return 1}
          if(a.hora > b.hora){return -1}
          return 0;
        })
    }
  }

  resolverTarefa(task){
    if(window.confirm("Deseja realmente marcar como RESOLVIDO esta tarefa?")){
      let dataHoje = new Date(Date.now() - 10800000).toISOString().split('T')[0];
      let jsonDate = new Date().toJSON();
      let hora = new Date(jsonDate).toLocaleTimeString();
      task.resolvido = true;
      task.dataResolucao = dataHoje;
      task.horaResolucao = hora;
      this.db.patchTarefa(task.idResponsavel,task.id,task).subscribe(res => {
        console.log(res);
        if(task.origem == "Usuario" && task.idUserSolicitacao != ''){
          let rdm = Math.random().toString(36).slice(-8);
          let avisoDados = {
            id : rdm,
            data: dataHoje,
            hora: hora,
            titulo : task.titulo,
            descricao : task.responsavel+" resolveu a Tarefa: "+task.descricao,
            visto: false,
            observacao: task.observacao,
            idResponsavel : task.idResponsavelTarefa,
            responsavel :  task.responsavelTarefa,
            idUserSolicitacao: task.idUserSolicitacao,
            nomeSolicitante: task.nomeSolicitante
          }
          this.db.postAviso(task.idUserSolicitacao,avisoDados).subscribe(res => console.log(res),err => console.log(err))
        }
        let oLogs = {
          "menu" : "TAREFAS", 
          "descricao" : "Tarefa Resolvida: "+task.titulo+" - "+task.descricao, 
          "data" : this.dataHoje, 
          "hora" : hora, 
          "usuario" : this.user
        }
        this.db.postLogs(oLogs).subscribe(res => {
          console.log(res);
          this.buscarTodasTarefas();
        }, err => console.log(err))
      }, err => {
        console.log(err)
      })
    }
  }

  selectUserTarefa(user){
    this.idResponsavelTarefa = "";
    let i = this.usuarios.map((u) => {
      return u.nome
    }).indexOf(user);
    if(i >= 0){
      this.idResponsavelTarefa = this.usuarios[i]._id;
    }
  }

  criarTarefa(){
    if(window.confirm("Deseja realmente criar essa tarefa?")){
      let randomGeraLink = Math.random().toString(36).slice(-8);
      let dataHoje = new Date(Date.now() - 10800000).toISOString().split('T')[0];
      let jsonDate = new Date().toJSON();
      let hora = new Date(jsonDate).toLocaleTimeString();
      let dados = {
      "id" : randomGeraLink,
      "data" : dataHoje,
      "hora" : hora,
      "titulo" : this.tituloTarefa,
      "descricao" : this.descricaoTarefa,
      "idResponsavel" : this.idResponsavelTarefa,
      "responsavel" :  this.responsavelTarefa,
      "prioridade" : this.prioridadeTarefa,
      "resolvido" : false,
      "dataResolucao" : "",
      "horaResolucao" : "",
      "observacao":this.observacaoTarefa,
      "idUserSolicitacao":localStorage.getItem('idUserVivamax'),
      "nomeSolicitante":localStorage.getItem('usuarioVivamax'),
      "origem":"Usuario"
      }
      this.db.postTarefa(dados).subscribe(res => {
        console.log(res);
        let oLogs = {
          "menu" : "TAREFAS", 
          "descricao" : "Tarefa Criada: "+this.tituloTarefa+" - "+this.descricaoTarefa, 
          "data" : this.dataHoje, 
          "hora" : hora, 
          "usuario" : this.user
        }
        this.db.postLogs(oLogs).subscribe(ress => {
          console.log(ress);
          this.app.openAlert("Tarefa Criada com Sucesso!");
          this.closeModalCriarTarefa();
          this.buscarTodasTarefas();
        }, errr => console.log(errr))
      },err => console.log(err))
    }
  }

  openModalCriarTarefa(){
    let modTarefa = document.getElementById('newTarefa');
    modTarefa.style.display = "block";
  }

  closeModalCriarTarefa(){
    let modTarefa = document.getElementById('newTarefa');
    modTarefa.style.display = "none";
    this.tituloTarefa = "";
    this.descricaoTarefa = "";
    this.idResponsavelTarefa = "";
    this.responsavelTarefa = "";
    this.prioridadeTarefa = "";
    this.observacaoTarefa = "";
  }

  removerTarefa(task){
    if(window.confirm("Deseja realmente excluir essa tarefa?")){
      let jsonDate = new Date().toJSON();
      let hora = new Date(jsonDate).toLocaleTimeString();
      this.db.deleteTarefa(task.idResponsavel,task.id).subscribe(res => {
        console.log(res);
        let body = {
          "menu" : "TAREFAS", 
          "descricao" : "Tarefa Excluida: "+task.titulo+" - "+task.descricao, 
          "data" : this.dataHoje, 
          "hora" : hora, 
          "usuario" : this.user
        }
        this.db.postLogs(body).subscribe(res => {
          console.log(res);
        this.app.openAlert("Tarefa Excluida com Sucesso!");
        this.buscarTodasTarefasPendentes();
        }, err => console.log(err))
      }, err => console.log(err))
   }
  }

  openModalEdit(task){
    this.taskSelecionada = task;
    this.idAntigo = task.idResponsavel;
    this.responsavelAntigo = task.responsavel;
    let modEditTarefa = document.getElementById('editTarefa');
    modEditTarefa.style.display = "block";
  }

  closeModalEdit(){
    let modEditTarefa = document.getElementById('editTarefa');
    modEditTarefa.style.display = "none";
    this.taskSelecionada = [];
    this.idNovo = "";
    this.idAntigo = "";
  }

  selectUserTarefaEditar(user){
    let i = this.usuarios.map((u) => {
      return u.nome
    }).indexOf(user);
    if(i >= 0){
      this.taskSelecionada.idResponsavel = this.usuarios[i]._id;
      this.idNovo = this.usuarios[i]._id;
    }
  }

  editarTarefa(){
    if(window.confirm("Deseja realmente Editar essa tarefa?")){
      let jsonDate = new Date().toJSON();
      let hora = new Date(jsonDate).toLocaleTimeString();
      if(this.idAntigo != this.idNovo && this.idNovo != ""){
        this.db.postTarefa(this.taskSelecionada).subscribe(res => {
          console.log(res);
          let oLogs = {
            "menu" : "TAREFAS", 
            "descricao" : "Responsável pela Tarefa: "+this.taskSelecionada.titulo+" - "+this.taskSelecionada.descricao+" alterado de "+this.responsavelAntigo+" para "+this.taskSelecionada.responsavel, 
            "data" : this.dataHoje, 
            "hora" : hora, 
            "usuario" : this.user
          }
          this.db.postLogs(oLogs).subscribe(ress => {
            console.log(ress);
            this.app.openAlert("Tarefa Alterada com Sucesso!");
            this.db.deleteTarefa(this.idAntigo,this.taskSelecionada.id).subscribe(resss => {
              console.log(resss);
              this.closeModalEdit();
              this.buscarTodasTarefas();
            }, errrr => {
              console.log(errrr)
            })
          }, errr => console.log(errr))
        },err => console.log(err))
      }else{
        this.db.patchTarefa(this.taskSelecionada.idResponsavel,this.taskSelecionada.id,this.taskSelecionada).subscribe(res => {
          console.log(res);
          let oLogs = {
            "menu" : "TAREFAS", 
            "descricao" : "Tarefa Alterada: "+this.taskSelecionada.titulo+" - "+this.taskSelecionada.descricao, 
            "data" : this.dataHoje, 
            "hora" : hora, 
            "usuario" : this.user
          }
          this.db.postLogs(oLogs).subscribe(ress => {
            console.log(ress);
            this.app.openAlert("Tarefa Alterada com Sucesso!");
            this.closeModalEdit();
            this.buscarTodasTarefas();
          }, errr => console.log(errr))
        },err => console.log(err))
      }      
    }
  }



}
