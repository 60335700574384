import { Component, OnInit,ViewChild } from '@angular/core';
import { DataBaseService } from '../provider.service';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MASKS } from 'ng-brazil';
import * as moment from 'moment';
import { AppComponent } from '../app.component';

@Component({
  selector: 'app-contas-pagar',
  templateUrl: './contas-pagar.component.html',
  styleUrls: ['./contas-pagar.component.css']
})
export class ContasPagarComponent implements OnInit {
  public MASKS = MASKS;
  @ViewChild('pdfViewerContasPagar') pdfViewerOnDemand;
  usuario:any = "";
  dataHoje:any = "";
  acessos:any = [];
  permissao:any = "";
  idPagamentos:any = "";
  fornecedorEditar:any = [];
  razaoSocial:any = "";
  nomeFantasia:any = "";
  cnpj:any = "";
  endereco:any = "";
  numero:any = "";
  bairro:any = "";
  cidade:any = "";
  estado:any = "";
  telefone:any = "";
  celular:any = "";
  responsavel:any = "";
  email:any = "";
  fornecedorConta:any = "";
  valorConta:any = "";
  tipoVencimentoConta:any = "";
  vencimentoConta:any = "";
  observacaoConta:any = "";
  formaPagamentoContaVer:any = "";
  bancoContaVer:any = "";
  todasFormasPagamento:any = [];
  parcelados:FormGroup;
  reembolsos:FormGroup;
  reembolsosEditar:FormGroup;
  formasPagamento:FormGroup;
  cc:FormGroup;
  ccEditar:FormGroup;
  public formaPagamanetoList: FormArray;
  public parceladosList: FormArray;
  public reembolsoList: FormArray;
  public reembolsoListEditar: FormArray;
  public ccList: FormArray;
  public ccListEditar: FormArray;
  get ccFormGroup(){return this.cc.get('centroCusto') as FormArray;}
  get ccFormGroupEditar(){return this.ccEditar.get('centroCusto') as FormArray;}
  get formasPagamentoFormGroup(){return this.formasPagamento.get('forma') as FormArray;}
  get parcelasFormGroup(){return this.parcelados.get('parcela') as FormArray;}
  get reembolsoFormGroup(){return this.reembolsos.get('reembolso') as FormArray;}
  get reembolsoEditarFormGroup(){return this.reembolsosEditar.get('reembolso') as FormArray;}
  selectedFilesContaPagar:any = "";
  filesContaPagar:any = [];
  contasPagar:any = [];
  todasContasPagar:any = [];
  totalContasPagar:number = 0;
  dataInicioConta:any = "";
  dataFimConta:any = "";
  docsConta:any = [];
  pdfsConta:any = [];
  imagesConta:any = [];
  idConta:any = "";
  formaPagamento:any = "";
  aguardandoAprovacao:boolean = false;
  indexImage:any = "";
  imagemSelecionada:any = "";
  images:any = [];
  valorContaEditar:number = 0;
  observacaoContaEditar:any = "";
  tipoVencimentoContaEditar:any = "";
  vencimentoContaEditar:any = "";
  parcelaConta:any = "";
  formaPagamentoEditar:any = "";
  aguardandoAprovacaoEditar:boolean = false;
  selectedFilesContaPagarEditar:any = "";
  filesContaPagarEditar:any = "";
  juros:number = 0;
  desconto:number = 0;
  valorTotal:number = 0;
  jurosContaEditar:number = 0;
  taxasContaEditar:number = 0;
  descontoContaEditar:number = 0;
  valorTotalEditar:number = 0;
  tipoDeConta:any = "";
  tipoDeContaEditar:any = "";
  colaboradores:any = [];
  tipoReembolso:any = "";
  motivoReembolso:any = "";
  colaboradorReembolso:any = "";
  dataReembolso:any = "";
  dataReembolsoEditar:any = "";
  motivoReembolsoEditar:any = "";
  colaboradorReembolsoEditar:any = "";
  situacaoReembolsoEditar:any = "";
  observacaoReembolso:any = "";
  dataSolicitacao:any = "";
  dataSolicitacaoEditar:any = "";
  dataVencimento:any = "";
  dataVencimentoEditar:any = "";
  colaboradorVencimentoEditar:any = "";
  tipoVencimentoCadastroEditar:any = "";
  colaboradorVencimento:any = "";
  tipoVencimentoCadastro:any = "";
  observacaoEditar:any = "";
  fornecedorBusca:any = "";
  banco:any = "";
  bancoEditar:any = "";
  agencia:number;
  agenciaDigito:any = "";
  contaCorrente:number;
  contaCorrenteDigito:any = "";
  tipoPix:any = "";
  pix:any = "";
  codigoBarras:any = "";
  codigoBarrasEditar:any = "";
  dadosBancario:any = "";
  agenciaEditar:number;
  contaCorrenteEditar:number;
  contaSelecionada:any = [];
  tipoLancamento:any = "";
  tipoLancamentoEditar:any = "";
  repeticoes:number = 0;
  listaDeCC:any = [];
  bancos:any = [];
  fornecedores:any = [];

  constructor(public db:DataBaseService, private formBuilder: FormBuilder, public app:AppComponent){}

  ngOnInit(){
    this.usuario = localStorage.getItem('usuarioVivamax');
    this.dataHoje = new Date(Date.now() - 10800000).toISOString().split('T')[0];
    this.dataSolicitacao = this.dataHoje;
    this.formasPagamento = this.formBuilder.group({forma: this.formBuilder.array([this.createFormaPagamento()])}); 
    this.formaPagamanetoList = this.formasPagamento.get('forma') as FormArray;
    this.parcelados = this.formBuilder.group({parcela: this.formBuilder.array([this.createParcela()])});
    this.parceladosList = this.parcelados.get('parcela') as FormArray;
    this.reembolsos = this.formBuilder.group({reembolso: this.formBuilder.array([this.createReembolso()])});
    this.reembolsoList = this.reembolsos.get('reembolso') as FormArray;
    this.reembolsosEditar = this.formBuilder.group({reembolso: this.formBuilder.array([this.createReembolso()])});
    this.reembolsoListEditar = this.reembolsosEditar.get('reembolso') as FormArray;
    this.cc = this.formBuilder.group({centroCusto: this.formBuilder.array([this.createCC()])});
    this.ccList = this.cc.get('centroCusto') as FormArray;
    this.ccEditar = this.formBuilder.group({centroCusto: this.formBuilder.array([this.createCCEditar()])});
    this.ccListEditar = this.ccEditar.get('centroCusto') as FormArray;
    this.db.getAllUsuariosAtivos().subscribe((data:any) => {
      this.colaboradores = data.sort((a,b) => {
        if(a.nome < b.nome)return -1;
        if(b.nome < a.nome)return 1;
      });
    });
    this.db.getConfigs().subscribe((z:any) => {
      this.idPagamentos = z[5]._id;
      this.todasFormasPagamento = z[5].pagamentos;
      this.bancos = z[25].bancos;
      let pgtos = z[5].pagamentos;
      if(pgtos.length > 0){
        this.formaPagamanetoList.clear();
        pgtos.forEach((item) => {
          this.addForma(item);
        })
      }
      this.db.getAllFornecedores().subscribe((data:any) => {
        this.fornecedores = data;
      })
    });
    this.getTodasContas();
    this.getCCs();
  }

  getCCs(){
    this.db.getCentrosCusto().subscribe((data:any) => {
      this.listaDeCC = data;
    })
  }

  getTodasContas(){
    this.totalContasPagar = 0;
    this.db.getTodasContasPagar().subscribe((data:any) => {
      this.contasPagar = data;
      this.todasContasPagar = this.contasPagar;
      this.somarContas();
    })
  }

  limparFormaPagamento(){
    this.formaPagamentoContaVer = "";
  }

  limparFornecedor(){
    this.fornecedorBusca = "";
  }

  limparBanco(){
    this.bancoContaVer = "";
  }

  somarContas(){
    this.totalContasPagar = 0;
    if(this.todasContasPagar.length > 0){
      this.todasContasPagar.forEach((item:any) => {
        if(item.valor){
          this.totalContasPagar += Number(item.valor)
        }
      });
    }
  }

  getContas(dtInicio,dtFim){
    this.totalContasPagar = 0;
    this.db.getContasPagar(dtInicio,dtFim).subscribe((data:any) => {
      this.contasPagar = data;
      this.todasContasPagar = this.contasPagar.sort((a,b) => {
        if(a.vencimento < b.vencimento){return -1}
        if(a.vencimento > b.vencimento){return 1}
      });
      this.somarContas();
    })
  }

  createCC(): FormGroup {
    return this.formBuilder.group({
      centroDeCusto: ["", Validators.compose([Validators.required])],
      contaOrcamentaria: ["", Validators.compose([Validators.required])],
      contaContabil: ["", Validators.compose([Validators.required])],
      valor: [null, Validators.compose([Validators.required])]
    });
  }
  
  addCC() {
    this.ccList.push(this.createCC());
  }

  removeCC(index) {
    this.ccList.removeAt(index);
  }

  createCCEditarOpen(item): FormGroup {
    return this.formBuilder.group({
      centroDeCusto: [item.centroDeCusto, Validators.compose([Validators.required])],
      contaOrcamentaria: [item.contaOrcamentaria, Validators.compose([Validators.required])],
      contaContabil: [item.contaContabil, Validators.compose([Validators.required])],
      valor: [item.valor, Validators.compose([Validators.required])]
    });
  }

  createCCEditar(): FormGroup {
    return this.formBuilder.group({
      centroDeCusto: ["", Validators.compose([Validators.required])],
      contaOrcamentaria: ["", Validators.compose([Validators.required])],
      contaContabil: ["", Validators.compose([Validators.required])],
      valor: [null, Validators.compose([Validators.required])]
    });
  }
  
  addCCEditarOpen(item) {
    this.ccListEditar.push(this.createCCEditarOpen(item));
  }

  addCCEditar() {
    this.ccListEditar.push(this.createCCEditar());
  }

  removeCCEditar(index) {
    this.ccListEditar.removeAt(index);
  }

  createReembolso(): FormGroup {
    return this.formBuilder.group({
      tipo: [null, Validators.compose([Validators.required])],
      valor: [null, Validators.compose([Validators.required])]
    });
  }
  
  addReembolso() {
    this.reembolsoList.push(this.createReembolso());
  }

  addReembolsoEditar() {
    this.reembolsoListEditar.push(this.createReembolsoEditar());
  }

  addReembolsoEditarLoad(item) {
    this.reembolsoListEditar.push(this.createReembolsoEditarLoad(item));
  }
  
  removeReembolso(index) {
    this.reembolsoList.removeAt(index);
  }

  removeReembolsoEditar(index) {
    this.reembolsoListEditar.removeAt(index);
  }
  
  getReembolsosFormGroup(index): FormGroup {
    const formGroup = this.reembolsoList.controls[index] as FormGroup;
    return formGroup;
  }

  createReembolsoEditar(): FormGroup {
    return this.formBuilder.group({
      tipo: [null, Validators.compose([Validators.required])],
      valor: [null, Validators.compose([Validators.required])]
    });
  }

  createReembolsoEditarLoad(item): FormGroup {
    return this.formBuilder.group({
      tipo: [item.tipo, Validators.compose([Validators.required])],
      valor: [item.valor, Validators.compose([Validators.required])]
    });
  }
  
  getReembolsosFormGroupEditar(index): FormGroup {
    const formGroup = this.reembolsoList.controls[index] as FormGroup;
    return formGroup;
  }

  createParcela(): FormGroup {
    return this.formBuilder.group({
      valor: [null, Validators.compose([Validators.required])],
      vencimento: [null, Validators.compose([Validators.required])]
    });
  }
  
  addParcela() {
    this.parceladosList.push(this.createParcela());
  }

  addParcelaNova(i){
    this.parceladosList.push(this.createParcelaNova(i));
  }

  createParcelaNova(i): FormGroup {
    let novoVencimento = moment(i.vencimento).add(1,'M').toISOString();
    let ven = novoVencimento.split('T')[0];
    return this.formBuilder.group({
      valor: [i.valor, Validators.compose([Validators.required])],
      vencimento: [ven, Validators.compose([Validators.required])]
    });
  }
  
  removeParcela(index) {
    this.parceladosList.removeAt(index);
  }
  
  getContactsFormGroup(index): FormGroup {
    const formGroup = this.parceladosList.controls[index] as FormGroup;
    return formGroup;
  }
  
  addForma(item){
    this.formaPagamanetoList.push(this.createForma(item));
  }

  createForma(item):FormGroup{
    return this.formBuilder.group({
      forma: [item.forma, Validators.compose([Validators.required])]
    });
  }

  createFormaPagamento():FormGroup{
    return this.formBuilder.group({
      forma: [null, Validators.compose([Validators.required])]
    });
  }

  addPagamento(){
    this.formaPagamanetoList.push(this.createFormaPagamento());
  }

  removePagamento(index) {
    this.formaPagamanetoList.removeAt(index);
  }

  salvarFormas(){
    let dados = {pagamentos: this.formaPagamanetoList.value};
    this.db.patchConfiguracoes(this.idPagamentos,dados).subscribe(res => {
      console.log(res);
      this.app.openAlert("Formas de Pagamento salva com sucesso!");
      this.closeModalFormas();
      this.ngOnInit();
    }, err => {
      console.log(err)
    })
  }

  openModalFormas(){
    let modal = document.getElementById('modalFormasPagamento');
    modal.style.display = "block"
  }

  closeModalFormas(){
    let modal = document.getElementById('modalFormasPagamento');
    modal.style.display = "none"
  }

  pegarBanco(f){
    let idx = this.fornecedores.map((item) => {
      return item.razaoSocial;
    }).indexOf(f);
    if(idx >= 0){
        this.dadosBancario = {
          banco: this.fornecedores[idx].banco,
          agenciaDigito: this.fornecedores[idx].agenciaDigito,
          contaCorrenteDigito: this.fornecedores[idx].contaCorrenteDigito,
          tipoPix: this.fornecedores[idx].tipoPix,
          pix: this.fornecedores[idx].pix,
          agencia: this.fornecedores[idx].agencia,
          contaCorrente: this.fornecedores[idx].contaCorrente,
      }
    }
  }

  openModalVerConta(conta){
    let idx = this.fornecedores.map((item) => {
      return item.razaoSocial;
    }).indexOf(conta.fornecedor);
    if(idx >= 0){
        this.dadosBancario = {
          banco: this.fornecedores[idx].banco,
          agenciaDigito: this.fornecedores[idx].agenciaDigito,
          contaCorrenteDigito: this.fornecedores[idx].contaCorrenteDigito,
          tipoPix: this.fornecedores[idx].tipoPix,
          pix: this.fornecedores[idx].pix,
          agencia: this.fornecedores[idx].agencia,
          contaCorrente: this.fornecedores[idx].contaCorrente,
      }
    }
    this.contaSelecionada = conta;
    this.tipoDeContaEditar = conta.tipoDeConta;
    this.idConta = conta._id;
    this.bancoEditar = conta.banco;
    this.fornecedorEditar = conta.fornecedor;
    this.valorContaEditar = conta.valor;
    this.observacaoContaEditar = conta.observacao;
    this.tipoVencimentoContaEditar = conta.tipoVencimento;
    this.vencimentoContaEditar = conta.vencimento;
    this.parcelaConta = conta.parcela;
    this.formaPagamentoEditar = conta.formaPagamento;
    this.tipoLancamentoEditar = conta.tipoLancamento;
    this.aguardandoAprovacaoEditar = conta.aguardandoAprovacao;
    this.codigoBarrasEditar = conta.codigoBarras;
    if(conta.juros){
      this.jurosContaEditar = conta.juros;
    }else{
      this.jurosContaEditar = 0;
    }
    if(conta.desconto){
      this.descontoContaEditar = conta.desconto;
    }else{
      this.descontoContaEditar = 0;
    }
    if(conta.taxas){
      this.taxasContaEditar = conta.taxas;
    }else{
      this.taxasContaEditar = 0;
    }
    this.valorTotalEditar = conta.valorTotal;
    this.docsConta = conta.anexos;
    if(this.docsConta && this.docsConta.length > 0){
      this.pdfsConta = [];
      this.imagesConta = [];
      this.docsConta.forEach((item) => {
      let ext = item.url.split('.').pop();
        if(ext == 'pdf' || ext == 'PDF'){
          this.pdfsConta.push(item);
        }else{
          this.imagesConta.push(item);
        }
      })
    }
    this.tipoDeContaEditar = conta.tipoDeConta;
    if(this.tipoDeContaEditar == "REEMBOLSO"){
      this.reembolsoListEditar.clear();
      this.dataReembolsoEditar = conta.dataReembolso;
      this.dataSolicitacaoEditar = conta.solicitacaoReembolso;
      this.dataVencimentoEditar = conta.vencimento
      this.motivoReembolsoEditar = conta.observacao;
      this.colaboradorReembolsoEditar = conta.fornecedor;
      conta.reembolsos.forEach((item) => {
        this.addReembolsoEditarLoad(item);
      })
    }
    if(this.tipoDeContaEditar == "VENCIMENTOS"){
      this.colaboradorVencimentoEditar = conta.fornecedor;
      this.tipoVencimentoCadastroEditar = conta.tipoVencimento;
      this.vencimentoContaEditar = conta.vencimento;
      this.valorContaEditar = conta.valor;
      this.formaPagamentoEditar = conta.formaPagamento;
      this.observacaoEditar = conta.observacao;
    }
    this.ccListEditar.clear();
    if(conta.centrosCusto){
      conta.centrosCusto.forEach((item)=> {
        this.addCCEditarOpen(item);
      })
    }
    let modal = document.getElementById('modalVerContaPagar');
    modal.style.display = "block"
  }

  closeModalVerConta(){
    let modal = document.getElementById('modalVerContaPagar');
    modal.style.display = "none";
    this.pdfsConta = [];
    this.imagesConta = [];
    this.filesContaPagarEditar = [];
    this.contaSelecionada = []
    this.dadosBancario = "";
    this.tipoDeContaEditar = "";
    this.bancoEditar = "";
    if(this.dataInicioConta == ""){
      this.getTodasContas();
    }else{
      this.getContas(this.dataInicioConta,this.dataFimConta);
    }
  }

  openModalNovaConta(){
    this.selectedFilesContaPagar = "";
    this.filesContaPagar = [];
    let modal = document.getElementById('modalContaPagar');
    modal.style.display = "block";
  }

  closeModalNovaConta(){
    let modal = document.getElementById('modalContaPagar');
    modal.style.display = "none";
    this.fornecedorConta = "";
    this.repeticoes = 0;
    this.observacaoConta = "";
    this.tipoVencimentoConta = "";
    this.valorConta = null;
    this.vencimentoConta = null;
    this.codigoBarras = "";
    this.parceladosList.clear();
    this.addParcela();
    this.reembolsoList.clear();
    this.addReembolso();
    this.ccList.clear();
    this.addCC();
    this.colaboradorReembolso = "";
    this.tipoDeConta = "";
    this.dadosBancario = "";
    this.dataSolicitacao = this.dataHoje;
    this.dataVencimento = "";
    this.motivoReembolso = "";
    this.colaboradorVencimento = "";
    this.tipoVencimentoCadastro = "";
    this.banco = "";
    if(this.dataInicioConta == ""){
      this.getTodasContas();
    }else{
          this.getContas(this.dataInicioConta,this.dataFimConta);
    }
  }
  
  onChangeClickContaPagar(event){
  let arrayTypes = ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'];
  this.selectedFilesContaPagar = <FileList>event.srcElement.files;
  for(let i=0; i<this.selectedFilesContaPagar.length; i++){
      if(!arrayTypes.includes(this.selectedFilesContaPagar[i].type)){
      this.app.openAlert("Tipo de Arquivo Não Suportado!\nEscolha uma imagem com a extensão .JPG, .JPEG, .PNG ou PDF")
      this.selectedFilesContaPagar = "";
    }} 
    this.filesContaPagar = [];
    for(let i=0; i<this.selectedFilesContaPagar.length; i++){
      this.filesContaPagar.push(this.selectedFilesContaPagar[i].name);
    }
}

onChangeContaPagar(event){
  let arrayTypes = ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'];
  this.selectedFilesContaPagar = <FileList>event;
  for(let i=0; i<this.selectedFilesContaPagar.length; i++){
      if(!arrayTypes.includes(this.selectedFilesContaPagar[i].type)){
      this.app.openAlert("Tipo de Arquivo Não Suportado!\nEscolha uma imagem com a extensão .JPG, .JPEG, .PNG ou PDF")
      this.selectedFilesContaPagar = "";
    }} 
    this.filesContaPagar = [];
    for(let i=0; i<this.selectedFilesContaPagar.length; i++){
      this.filesContaPagar.push(this.selectedFilesContaPagar[i].name);
    }
}

  cadastrarReembolso(){
    let jsonDate = new Date().toJSON();
    let hora = new Date(jsonDate).toLocaleTimeString();
    let totalReembolso = 0;
    let ctrl = 1;
    this.reembolsoList.value.forEach((item) => {
      totalReembolso += item.valor
      if(this.reembolsoList.value.length == ctrl){
        let dados = {
          usuario:this.usuario,
          data:this.dataHoje,
          hora: hora,
          fornecedor:this.colaboradorReembolso,
          observacao:this.motivoReembolso,
          tipoVencimento:"REEMBOLSO",
          valor: totalReembolso,
          vencimento:this.dataVencimento,
          solicitacaoReembolso:this.dataSolicitacao,
          dataReembolso:"",
          status:"Pendente",
          parcela:"",
          anexos:[],
          juros: 0,
          desconto: 0,
          banco:this.banco,
          valorTotal: totalReembolso,
          tipo:"Saida",
          reembolsos: this.reembolsoList.value,
          tipoDeConta: this.tipoDeConta,
          situacaoReembolso: "",
          observacaoReembolso: ""
        }
        this.db.postFinanceiro(dados).subscribe(res => {
          console.log(res);
          if(this.selectedFilesContaPagar.length > 0){
            const imagedata = new FormData();
            for(let i=0; i<this.selectedFilesContaPagar.length; i++){imagedata.append('files[]', this.selectedFilesContaPagar[i], this.selectedFilesContaPagar[i].name)};
             this.db.enviarDocFinanceiro( res['insertedId'], imagedata).subscribe((result) => {
                 console.log(result);
               },(error) => {
                 console.log(error);
               });
          }
          let jsonDate = new Date().toJSON();
          let hora = new Date(jsonDate).toLocaleTimeString();
          let body = {
          "menu" : "CONTAS A PAGAR", 
          "descricao" : "Reembolso Cadastrado: "+this.fornecedorConta+" - R$"+this.valorTotal, 
          "data" : this.dataHoje, 
          "hora" : hora, 
          "usuario" : this.usuario
        }
        this.db.postLogs(body).subscribe(res => {
          console.log(res);
          this.app.openAlert("Reembolso Cadastrado com Sucesso!");
          this.closeModalNovaConta();
        })
        }, err => {
          console.log(err);
          this.app.openAlert("Erro ao Cadastrar Reembolso, Tente Novamente!")
        });
      }else{
        ctrl++
      }
    })
  }
  
  salvarReembolso(){
    let jsonDate = new Date().toJSON();
    let hora = new Date(jsonDate).toLocaleTimeString();
    let totalReembolso = 0;
    let ctrl = 1;
    this.reembolsoListEditar.value.forEach((item) => {
      totalReembolso += item.valor
      if(this.reembolsoListEditar.value.length == ctrl){
        let dados = {
          usuario:this.usuario,
          data:this.dataHoje,
          hora: hora,
          fornecedor:this.colaboradorReembolsoEditar,
          observacao:this.motivoReembolsoEditar,
          tipoVencimento:"REEMBOLSO",
          valor: totalReembolso,
          vencimento:this.dataVencimentoEditar,
          solicitacaoReembolso:this.dataSolicitacaoEditar,
          status:"Pendente",
          parcela:"",
          anexos:this.docsConta,
          banco:this.bancoEditar,
          juros: 0,
          desconto: 0,
          valorTotal: totalReembolso,
          tipo:"Saida",
          reembolsos: this.reembolsoListEditar.value,
          tipoDeConta: this.tipoDeContaEditar
        }
        this.db.editarContaFinanceiro(this.idConta,dados).subscribe(res => {
          console.log(res);
          this.app.openAlert("Reembolso Editado com Sucesso!");
          this.closeModalVerConta();
        }, err => {
          console.log(err);
          this.app.openAlert("Erro ao Editar Reembolso, Tente Novamente!")
        });
      }else{
        ctrl++
      }
    })
  }


  arredonda(valor: number): number {
    return Math.round((valor + Number.EPSILON) * 100) / 100;
  }

  cadastrarConta(){
    let ctrl:number = 1;
    let total:number = 0;
    if(this.tipoVencimentoConta == "Parcelado"){
      this.valorConta = 0;
      let sumvlr =0;
      for(let i = 0; i < this.parceladosList.value.length; i++){
        sumvlr += this.parceladosList.value[i].valor;
         this.valorConta = this.arredonda(sumvlr)
      }
    }
    this.ccList.value.forEach((cc:any) => {
      total += cc.valor;
      if(this.ccList.value.length == ctrl){
        let fix = total.toFixed(2);
        total = Number(fix)
        if(this.valorConta == total){ 
          let jsonDate = new Date().toJSON();
          let hora = new Date(jsonDate).toLocaleTimeString();
          if(this.tipoDeConta == "VENCIMENTOS"){
            let dados = {
              usuario:this.usuario,
              data:this.dataHoje,
              hora: hora,
              fornecedor:this.colaboradorVencimento,
              observacao:this.observacaoConta,
              tipoVencimento:this.tipoVencimentoCadastro,
              valor: this.valorConta,
              vencimento:this.vencimentoConta,
              status:"Pendente",
              parcela:"",
              anexos:[],
              banco:this.banco,
              juros: 0,
              desconto: 0,
              valorTotal: this.valorConta,
              tipo:"Saida",
              formaPagamento:this.formaPagamento,
              tipoDeConta: this.tipoDeConta,
              tipoVencimentoCadastro:this.tipoVencimentoCadastro,
              centrosCusto: this.ccList.value
            }
            this.db.postFinanceiro(dados).subscribe(res => {
              console.log(res);
              if(this.selectedFilesContaPagar.length > 0){
                const imagedata = new FormData();
                for(let i=0; i<this.selectedFilesContaPagar.length; i++){imagedata.append('files[]', this.selectedFilesContaPagar[i], this.selectedFilesContaPagar[i].name)};
                this.db.enviarDocFinanceiro( res['insertedId'], imagedata).subscribe((result) => {
                    console.log(result);
                  },(error) => {
                    console.log(error);
                  });
              }
              let jsonDate = new Date().toJSON();
              let hora = new Date(jsonDate).toLocaleTimeString();
              let body = {
              "menu" : "CONTAS A PAGAR", 
              "descricao" : "Conta Cadastrada: "+this.fornecedorConta+" - R$ "+this.valorConta, 
              "data" : this.dataHoje, 
              "hora" : hora, 
              "usuario" : this.usuario
            }
            this.db.postLogs(body).subscribe(res => {
              console.log(res);
              this.app.openAlert("Conta Cadastrada com Sucesso!");
              this.closeModalNovaConta();
            })
            }, err => {
              console.log(err);
              this.app.openAlert("Erro ao Cadastrar Conta, Tente Novamente!")
            });
          }
          if(this.tipoVencimentoConta == "À Vista"){
            let dados = {
              usuario:this.usuario,
              data:this.dataHoje,
              hora: hora,
              fornecedor:this.fornecedorConta,
              observacao:this.observacaoConta,
              tipoVencimento:this.tipoVencimentoConta,
              valor: this.valorConta,
              vencimento:this.vencimentoConta,
              status:"Pendente",
              parcela:"1/1",
              anexos:[],
              banco:this.banco,
              juros: 0,
              desconto: 0,
              valorTotal: this.valorConta,
              tipo:"Saida",
              formaPagamento:this.formaPagamento,
              tipoLancamento:this.tipoLancamento,
              aguardandoAprovacao:this.aguardandoAprovacao,
              tipoDeConta: this.tipoDeConta,
              codigoBarras:this.codigoBarras,
              centrosCusto: this.ccList.value
            }
            this.db.postFinanceiro(dados).subscribe(res => {
              console.log(res);
              if(this.selectedFilesContaPagar.length > 0){
                const imagedata = new FormData();
                for(let i=0; i<this.selectedFilesContaPagar.length; i++){imagedata.append('files[]', this.selectedFilesContaPagar[i], this.selectedFilesContaPagar[i].name)};
                this.db.enviarDocFinanceiro( res['insertedId'], imagedata).subscribe((result) => {
                    console.log(result);
                  },(error) => {
                    console.log(error);
                  });
              }
              let jsonDate = new Date().toJSON();
              let hora = new Date(jsonDate).toLocaleTimeString();
              let body = {
              "menu" : "CONTAS A PAGAR", 
              "descricao" : "Conta Cadastrada: "+this.fornecedorConta+" - R$"+this.valorConta, 
              "data" : this.dataHoje, 
              "hora" : hora, 
              "usuario" : this.usuario
            }
            this.db.postLogs(body).subscribe(res => {
              console.log(res);
              this.app.openAlert("Conta Cadastrada com Sucesso!");
              this.closeModalNovaConta();
            })
            }, err => {
              console.log(err);
              this.app.openAlert("Erro ao Cadastrar Conta, Tente Novamente!")
            });
          }
          if(this.tipoVencimentoConta == "Parcelado"){
            let control = 1;
            this.parceladosList.value.forEach((item,idx) => {
              let parcela = (idx+1)+'/'+this.parceladosList.value.length;
              let dados = {
                usuario:this.usuario,
                data:this.dataHoje,
                hora: hora,
                fornecedor:this.fornecedorConta,
                observacao:this.observacaoConta,
                tipoVencimento:this.tipoVencimentoConta,
                valor:item.valor,
                vencimento:item.vencimento,
                status:"Pendente",
                parcela:parcela,
                anexos:[],
                banco:this.banco,
                juros: 0,
                desconto: 0,
                valorTotal: item.valor,
                tipo:"Saida",
                formaPagamento:this.formaPagamento,
                tipoLancamento:this.tipoLancamento,
                aguardandoAprovacao:this.aguardandoAprovacao,
                tipoDeConta: this.tipoDeConta,
                codigoBarras: this.codigoBarras,
                centrosCusto: this.ccList.value
              }
              this.db.postFinanceiro(dados).subscribe((r:any) => {
                console.log(r);
                if(this.selectedFilesContaPagar.length > 0){
                  const imagedata = new FormData();
                  for(let i=0; i<this.selectedFilesContaPagar.length; i++){imagedata.append('files[]', this.selectedFilesContaPagar[i], this.selectedFilesContaPagar[i].name)};
                  this.db.enviarDocFinanceiro(r['insertedId'], imagedata).subscribe((result) => {
                      console.log(result);
                    },(error) => {
                      console.log(error);
                    });
                  }
                }, err => {
                  console.log(err);
                  this.app.openAlert("Erro ao Cadastrar Conta, Tente Novamente!")
                })
              if(control == this.parceladosList.value.length){
                let jsonDate = new Date().toJSON();
                let hora = new Date(jsonDate).toLocaleTimeString();
                let body = {
                "menu" : "CONTAS A PAGAR", 
                "descricao" : "Conta Cadastrada: "+this.fornecedorConta+" - R$ "+item.valor, 
                "data" : this.dataHoje, 
                "hora" : hora, 
                "usuario" : this.usuario
              }
              this.db.postLogs(body).subscribe(res => {
                console.log(res);
                this.app.openAlert("Contas Cadastradas com Sucesso!");
                this.closeModalNovaConta();
              })
              }else{
                control++
              }
            })
          }
          if(this.tipoVencimentoConta == "Semanal" || this.tipoVencimentoConta == "Mensal" || this.tipoVencimentoConta == "Anual"){
            let dados = {
              usuario:this.usuario,
              data:this.dataHoje,
              hora: hora,
              fornecedor:this.fornecedorConta,
              observacao:this.observacaoConta,
              tipoVencimento:this.tipoVencimentoConta,
              valor:this.valorConta,
              vencimento:this.vencimentoConta,
              status:"Pendente",
              parcela:"",
              anexos:[],
              banco:this.banco,
              juros: 0,
              desconto: 0,
              valorTotal: this.valorConta,
              tipo:"Saida",
              formaPagamento:this.formaPagamento,
              tipoLancamento:this.tipoLancamento,
              aguardandoAprovacao:this.aguardandoAprovacao,
              tipoDeConta: this.tipoDeConta,
              codigoBarras: this.codigoBarras,
              centrosCusto: this.ccList.value
            }
            this.db.postFinanceiro(dados).subscribe((r:any) => {
              console.log(r);
              if(this.selectedFilesContaPagar.length > 0){
                const imagedata = new FormData();
                for(let i=0; i<this.selectedFilesContaPagar.length; i++){imagedata.append('files[]', this.selectedFilesContaPagar[i], this.selectedFilesContaPagar[i].name)};
                this.db.enviarDocFinanceiro(r['insertedId'], imagedata).subscribe((result) => {
                    console.log(result);
                  },(error) => {
                    console.log(error);
                  });
                }
            if(this.tipoVencimentoConta == "Semanal"){
              for(let i = 1; i < this.repeticoes;i++){
                let control = i;
                let dadosSemanal = {
                  usuario:this.usuario,
                  data:this.dataHoje,
                  hora: hora,
                  fornecedor:this.fornecedorConta,
                  observacao:this.observacaoConta,
                  tipoVencimento:this.tipoVencimentoConta,
                  valor:this.valorConta,
                  vencimento:moment(this.vencimentoConta).add(control,'week'),
                  status:"Pendente",
                  parcela:"",
                  anexos:[],
                  banco:this.banco,
                  juros: 0,
                  desconto: 0,
                  valorTotal: this.valorConta,
                  tipo:"Saida",
                  formaPagamento:this.formaPagamento,
                  tipoLancamento:this.tipoLancamento,
                  aguardandoAprovacao:this.aguardandoAprovacao,
                  tipoDeConta: this.tipoDeConta,
                  codigoBarras: this.codigoBarras,
                  centrosCusto: this.ccList.value
                }
              this.db.postFinanceiro(dadosSemanal).subscribe((rr:any) => {
                console.log(rr);
                if(this.selectedFilesContaPagar.length > 0){
                  const imagedata = new FormData();
                  for(let i=0; i<this.selectedFilesContaPagar.length; i++){imagedata.append('files[]', this.selectedFilesContaPagar[i], this.selectedFilesContaPagar[i].name)};
                  this.db.enviarDocFinanceiro(rr['insertedId'], imagedata).subscribe((result) => {
                      console.log(result);
                    },(error) => {
                      console.log(error);
                    });
                  }
                }, eer => {
                  console.log(eer);
                  this.app.openAlert("Erro ao Cadastrar Conta, Tente Novamente!")
                })
              if(this.repeticoes == i+1){
                 let jsonDate = new Date().toJSON();
                let hora = new Date(jsonDate).toLocaleTimeString();
                let body = {
                "menu" : "CONTAS A PAGAR", 
                "descricao" : "Conta Cadastrada: "+this.fornecedorConta+" - R$"+this.valorConta, 
                "data" : this.dataHoje, 
                "hora" : hora, 
                "usuario" : this.usuario
              }
              this.db.postLogs(body).subscribe(res => {
                console.log(res);
                this.app.openAlert("Contas Cadastradas com Sucesso!");
                this.closeModalNovaConta();
              })
              }
            }
          }
          if(this.tipoVencimentoConta == "Mensal"){
            for(let i = 1; i < this.repeticoes;i++){
              let control = i;
              let dadosMensal = {
                usuario:this.usuario,
                data:this.dataHoje,
                hora: hora,
                fornecedor:this.fornecedorConta,
                observacao:this.observacaoConta,
                tipoVencimento:this.tipoVencimentoConta,
                valor:this.valorConta,
                vencimento:moment(this.vencimentoConta).add(control,'month'),
                status:"Pendente",
                parcela:"",
                anexos:[],
                banco:this.banco,
                juros: 0,
                desconto: 0,
                valorTotal: this.valorConta,
                tipo:"Saida",
                formaPagamento:this.formaPagamento,
                tipoLancamento:this.tipoLancamento,
                aguardandoAprovacao:this.aguardandoAprovacao,
                tipoDeConta: this.tipoDeConta,
                codigoBarras: this.codigoBarras,
                centrosCusto: this.ccList.value
              }
            this.db.postFinanceiro(dadosMensal).subscribe((rr:any) => {
              console.log(rr);
              if(this.selectedFilesContaPagar.length > 0){
                const imagedata = new FormData();
                for(let i=0; i<this.selectedFilesContaPagar.length; i++){imagedata.append('files[]', this.selectedFilesContaPagar[i], this.selectedFilesContaPagar[i].name)};
                this.db.enviarDocFinanceiro(rr['insertedId'], imagedata).subscribe((result) => {
                    console.log(result);
                  },(error) => {
                    console.log(error);
                  });
                }
              }, eer => {
                console.log(eer);
                this.app.openAlert("Erro ao Cadastrar Conta, Tente Novamente!")
              })
            if(this.repeticoes == i+1){
              let jsonDate = new Date().toJSON();
              let hora = new Date(jsonDate).toLocaleTimeString();
              let body = {
              "menu" : "CONTAS A PAGAR", 
              "descricao" : "Conta Cadastrada: "+this.fornecedorConta+" - R$"+this.valorConta, 
              "data" : this.dataHoje, 
              "hora" : hora, 
              "usuario" : this.usuario
            }
            this.db.postLogs(body).subscribe(res => {
              console.log(res);
              this.app.openAlert("Contas Cadastradas com Sucesso!");
              this.closeModalNovaConta();
            })
            }
          }
        }
        if(this.tipoVencimentoConta == "Anual"){
          for(let i = 1; i < this.repeticoes;i++){
            let control = i;
            let dadosAnual = {
              usuario:this.usuario,
              data:this.dataHoje,
              hora: hora,
              fornecedor:this.fornecedorConta,
              observacao:this.observacaoConta,
              tipoVencimento:this.tipoVencimentoConta,
              valor:this.valorConta,
              vencimento:moment(this.vencimentoConta).add(control,'year'),
              status:"Pendente",
              parcela:"",
              anexos:[],
              banco:this.banco,
              juros: 0,
              desconto: 0,
              valorTotal: this.valorConta,
              tipo:"Saida",
              formaPagamento:this.formaPagamento,
              tipoLancamento:this.tipoLancamento,
              aguardandoAprovacao:this.aguardandoAprovacao,
              tipoDeConta: this.tipoDeConta,
              codigoBarras: this.codigoBarras,
              centrosCusto: this.ccList.value
            }
            this.db.postFinanceiro(dadosAnual).subscribe((rr:any) => {
              console.log(rr);
              if(this.selectedFilesContaPagar.length > 0){
                const imagedata = new FormData();
                for(let i=0; i<this.selectedFilesContaPagar.length; i++){imagedata.append('files[]', this.selectedFilesContaPagar[i], this.selectedFilesContaPagar[i].name)};
                this.db.enviarDocFinanceiro(rr['insertedId'], imagedata).subscribe((result) => {
                    console.log(result);
                  },(error) => {
                    console.log(error);
                  });
                }
              }, eer => {
                console.log(eer);
                this.app.openAlert("Erro ao Cadastrar Conta, Tente Novamente!")
              })
              if(this.repeticoes == i+1){
                let jsonDate = new Date().toJSON();
                let hora = new Date(jsonDate).toLocaleTimeString();
                let body = {
                "menu" : "CONTAS A PAGAR", 
                "descricao" : "Conta Cadastrada: "+this.fornecedorConta+" - R$"+this.valorConta, 
                "data" : this.dataHoje, 
                "hora" : hora, 
                "usuario" : this.usuario
              }
              this.db.postLogs(body).subscribe(res => {
                console.log(res);
                this.app.openAlert("Contas Cadastradas com Sucesso!");
                this.closeModalNovaConta();
              })
              }
            }
          }
          });
        }
      }else{
        this.app.openAlert("A Soma do Rateio dos Centros de Custos é diferente do Valor da Conta!");
      }
    }else{
      ctrl++;
    }
  })
}

  excluirConta(idConta:any,i:any){
    if(window.confirm("Deseja realmente excluir essa Conta?")){
      let jsonDate = new Date().toJSON();
      let hora = new Date(jsonDate).toLocaleTimeString();
      this.db.deleteContaFinanceiro(idConta).subscribe(res => {
        console.log(res);
        let jsonDate = new Date().toJSON();
      let hora = new Date(jsonDate).toLocaleTimeString();
      let body = {
       "menu" : "CONTAS A PAGAR", 
       "descricao" : "Conta Excluida: "+this.todasContasPagar[i].fornecedor+" - R$ "+this.todasContasPagar[i].valorTotal, 
       "data" : this.dataHoje, 
       "hora" : hora, 
       "usuario" : this.usuario
     }
     this.db.postLogs(body).subscribe(res => {
        console.log(res);
        this.app.openAlert("Conta Excluida com Sucesso!");
        if(this.dataInicioConta == ""){
          this.getTodasContas();
        }else{
          this.getContas(this.dataInicioConta,this.dataFimConta);
        }
      })
      },err => {
      console.log(err)
    })
  }
}

getDocsConta(){
  this.docsConta = [];
  this.filesContaPagarEditar = [];
  this.db.getContasById(this.idConta).subscribe((result:any) => {
    this.docsConta = result.anexos;
    this.pdfsConta = [];
    this.imagesConta = [];
    this.selectedFilesContaPagar = "";
    this.filesContaPagar = [];
    if(this.docsConta && this.docsConta.length > 0){
      this.pdfsConta = [];
      this.imagesConta = [];
      this.docsConta.forEach((item) => {
      let ext = item.url.split('.').pop();
        if(ext == 'pdf' || ext == 'PDF'){
          this.pdfsConta.push(item);
        }else{
          this.imagesConta.push(item);
        }
      })
    }
  })
}

  delDocConta(url){
    if(window.confirm('Deseja Realmente Deletar esse Documento?')){
     this.db.removeDoc(url).subscribe(res => {
       console.log(res);
       this.db.removeDocConta(this.idConta,url).subscribe(result => {
         console.log(result);
         this.getDocsConta();
       }, error => {
         console.log(error);
       })
      },err => {
        console.log(err);
         this.db.removeDocConta(this.idConta,url).subscribe(result => {
         console.log(result);
         this.getDocsConta();
       }, error => {
         console.log(error);
       })
     })
   }
 }
 
 openModalPdfViewer(url){
  this.pdfViewerOnDemand.pdfSrc = url;
  this.pdfViewerOnDemand.refresh();
}

openModalLightBox(url, i){
  this.indexImage = i;
  this.imagemSelecionada = url;
  let modal = document.getElementById('myModal')
  modal.style.display = "block";
}

nextImg(){
  let i = this.indexImage
  if((this.images.length - 1) == i){
    this.imagemSelecionada = this.images[0].url;
    this.indexImage = 0;
  }else{
    i++
    this.imagemSelecionada = this.images[i].url;
    this.indexImage++
  }
}

prevImg(){
  let i = this.indexImage
  if(i == 0){
    this.imagemSelecionada = this.images[this.images.length - 1].url;
    this.indexImage = this.images.length - 1;
  }else{
    i--
    this.imagemSelecionada = this.images[i].url;
    this.indexImage--
  }
}

closeModal(){
  let modal = document.getElementById('myModal')
  modal.style.display = "none";
}

onChangeClickContaPagarEditar(event){
  let arrayTypes = ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'];
  this.selectedFilesContaPagarEditar = <FileList>event.srcElement.files;
  for(let i=0; i<this.selectedFilesContaPagarEditar.length; i++){
      if(!arrayTypes.includes(this.selectedFilesContaPagarEditar[i].type)){
      this.app.openAlert("Tipo de Arquivo Não Suportado!\nEscolha uma imagem com a extensão .JPG, .JPEG, .PNG ou PDF")
      this.selectedFilesContaPagarEditar = "";
    }} 
    this.filesContaPagarEditar = [];
    for(let i=0; i<this.selectedFilesContaPagarEditar.length; i++){
      this.filesContaPagarEditar.push(this.selectedFilesContaPagarEditar[i].name);
    }
}

onChangeContaPagarEditar(event){
  let arrayTypes = ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'];
  this.selectedFilesContaPagarEditar = <FileList>event;
  for(let i=0; i<this.selectedFilesContaPagarEditar.length; i++){
      if(!arrayTypes.includes(this.selectedFilesContaPagarEditar[i].type)){
      this.app.openAlert("Tipo de Arquivo Não Suportado!\nEscolha uma imagem com a extensão .JPG, .JPEG, .PNG ou PDF")
      this.selectedFilesContaPagarEditar = "";
    }} 
    this.filesContaPagarEditar = [];
    for(let i=0; i<this.selectedFilesContaPagarEditar.length; i++){
      this.filesContaPagarEditar.push(this.selectedFilesContaPagarEditar[i].name);
    }
}

anexarDoc(){
  if(this.filesContaPagarEditar.length > 0){
    const imagedata = new FormData();
    for(let i=0; i<this.selectedFilesContaPagarEditar.length; i++){imagedata.append('files[]', this.selectedFilesContaPagarEditar[i], this.selectedFilesContaPagarEditar[i].name)};
     this.db.enviarDocFinanceiro(this.idConta, imagedata).subscribe((res) => {
         console.log(res);
         this.getDocsConta();
       },(error) => {
         console.log(error);
         this.getDocsConta();
       });
  }
}

 editarConta(){
  let ctrl:number = 1;
  let total:number = 0;
  this.ccListEditar.value.forEach((cc:any) => {
    total += cc.valor;
    if(this.ccListEditar.value.length == ctrl){
      let fix = total.toFixed(2);
      total = Number(fix)
      if(this.valorTotalEditar == total){ 
      let dados = {
        fornecedor:this.fornecedorEditar,
        observacao:this.observacaoContaEditar,
        tipoVencimento:this.tipoVencimentoContaEditar,
        valor:this.valorContaEditar,
        vencimento:this.vencimentoContaEditar,
        status:"Pendente",
        parcela:this.parcelaConta,
        anexos:this.docsConta,
        formaPagamento:this.formaPagamentoEditar,
        tipoLancamento:this.tipoLancamentoEditar,
        aguardandoAprovacao:this.aguardandoAprovacaoEditar,
        juros: this.jurosContaEditar,
        taxas: this.taxasContaEditar,
        desconto: this.descontoContaEditar,
        valorTotal: this.valorTotalEditar,
        tipo:"Saida",
        reembolsos:"",
        tipoDeConta: this.tipoDeContaEditar,
        colaboradorVencimento:this.colaboradorVencimentoEditar,
        tipoVencimentoCadastro:this.tipoVencimentoCadastroEditar,
        codigoBarras: this.codigoBarrasEditar,
        centrosCusto: this.ccListEditar.value,
        banco:this.bancoEditar
      }
      this.db.editarContaFinanceiro(this.idConta,dados).subscribe(res => {
          console.log(res);
          let jsonDate = new Date().toJSON();
          let hora = new Date(jsonDate).toLocaleTimeString();
          let body = {
          "menu" : "CONTAS A PAGAR", 
          "descricao" : "Conta Editada: "+this.fornecedorEditar+" - R$"+this.valorTotalEditar, 
          "data" : this.dataHoje, 
          "hora" : hora, 
          "usuario" : this.usuario
        }
        this.db.postLogs(body).subscribe(res => {
          console.log(res);
          this.app.openAlert("Conta Editada com Sucesso!");
          this.closeModalVerConta();
        })
        },err => {
          console.log(err);
          this.app.openAlert("Erro ao Editar, Tente Novamente!")
        })
      }else{
        this.app.openAlert("A Soma do Rateio dos Centros de Custos é diferente do Valor da Conta!");
      }
    }else{
      ctrl++;
    }
  })
 }

 pagarConta(){
  let ctrl:number = 1;
  let total:number = 0;
  this.ccListEditar.value.forEach((cc:any) => {
    total += cc.valor;
    if(this.ccListEditar.value.length == ctrl){
      let fix = total.toFixed(2);
      total = Number(fix)
      if(this.valorTotalEditar == total){ 
      let jsonDate = new Date().toJSON();
      let hora = new Date(jsonDate).toLocaleTimeString();
      let dados = {
        status:"Pago",
        formaPagamento:this.formaPagamentoEditar,
        tipoLancamento:this.tipoLancamentoEditar,
        aguardandoAprovacao:this.aguardandoAprovacaoEditar,
        juros: this.jurosContaEditar,
        taxas: this.taxasContaEditar,
        desconto: this.descontoContaEditar,
        valorTotal: this.valorTotalEditar,
        dataPagamento:this.dataHoje,
        horaPagamento:hora,
        centrosCusto: this.ccListEditar.value,
        tipo:"Saida",
        banco:this.bancoEditar
      }
      this.db.editarContaFinanceiro(this.idConta,dados).subscribe(res => {
          console.log(res);
          this.app.openAlert("Conta Paga com Sucesso!");
          this.closeModalVerConta();
        },err => {
          console.log(err);
          this.app.openAlert("Erro ao Pagar, Tente Novamente!")
        })
      }else{
        this.app.openAlert("A Soma do Rateio dos Centros de Custos é diferente do Valor da Conta!");
      }
      }else{
        ctrl++;
      }
    })
  }

pagarReembolso(){
  let jsonDate = new Date().toJSON();
  let hora = new Date(jsonDate).toLocaleTimeString();
  let dados = {
    status:"Pago",
    formaPagamento:this.formaPagamentoEditar,
    juros: this.jurosContaEditar,
    taxas: this.taxasContaEditar,
    desconto: this.descontoContaEditar,
    valorTotal: this.valorTotalEditar,
    dataPagamento:this.dataReembolso,
    horaPagamento:hora,
    tipo:"Saida",
    situacaoReembolso:this.situacaoReembolsoEditar,
    observacaoReembolso:this.observacaoReembolso,
    banco:this.bancoEditar
  }
  this.db.editarContaFinanceiro(this.idConta,dados).subscribe(res => {
      console.log(res);
      this.app.openAlert("Conta Paga com Sucesso!");
      this.closeModalVerConta();
    },err => {
      console.log(err);
      this.app.openAlert("Erro ao Pagar, Tente Novamente!")
    })
}

calcularTotalEditar(){
  this.valorTotalEditar = Number((this.valorContaEditar + this.jurosContaEditar + this.taxasContaEditar - this.descontoContaEditar).toFixed(2));
}

filtro(forma){
  this.todasContasPagar = this.contasPagar.filter(item => {
    return item.formaPagamento.toLowerCase().indexOf(forma.toLowerCase()) > -1;
  });
  this.somarContas();
}

filtroFornecedor(fornecedorBusca){
  let f = (fornecedorBusca == '' || fornecedorBusca == null) ? "" : fornecedorBusca.razaoSocial;
  this.todasContasPagar = this.contasPagar.filter(item => {
    return item.fornecedor.toLowerCase().indexOf(f.toLowerCase()) > -1;
  });
  this.somarContas();
}

filtroBanco(banco){
  this.todasContasPagar = this.contasPagar.filter(item => {
    return item.banco.toLowerCase().indexOf(banco.toLowerCase()) > -1;
  });
  this.somarContas();
}


}
