import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'dataVendaFilter',
    pure: false
})

export class DataVendaFilter implements PipeTransform {
    transform(arr: any,filter:any): any {
        if(!arr){
            return arr
        }
        return arr.filter(item => item.data.indexOf(filter) !== -1).filter(item => item.alteracao !== true);
    }
}