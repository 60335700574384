import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'mesFilter',
    pure: false
})

export class DataMesFilter implements PipeTransform {
    transform(arr: any,filter:any): any {
        if(!arr){
            return arr
        }
        return arr.filter(item => item.data.split('-')[1].indexOf(filter) !== -1);
    }
}