import { Component } from '@angular/core';
import { AuthService } from 'src/app/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent {
  show:boolean = false;
  email:any = "";
  senha:any = "";

  constructor(public authService: AuthService) {}

  showSenha(){
    this.show = !this.show
  }

  confereSenha(senha: string): boolean {
      return senha.includes('%') || senha.includes('$') || senha.includes('&') || senha.includes('#');
    }

}
