<div class="row  align-items-center header">
    <div class="col col-1">
        &nbsp;
    </div>
    <div class="col titulo" style="text-align: center;">
        PAINEL CELESC
    </div>
</div>
<br>
<tabset #staticTabs>
    <tab heading="ARQUIVO RETORNO" style="padding:10px;">
        <div style="text-align:center;font-size:1.5em">Clique ou Arraste o Arquivo para Leitura (.PAD)</div>
        <div class="uploadfilecontainer" (click)="fileRetorno.click()" appDragDrop (onFileDropped)="getFile($event,'drop')">
            <input hidden type="file" #fileRetorno (change)="getFile($event,'click')"  accept=".PAD">
        </div>
        <div style="height:3em"></div>
        <div *ngIf="arrayRetorno.length > 0">
            <div class="table-responsive">
                <table class="table table-striped">
                    <thead class="thead-dark">
                        <tr>
                            <th>Tipo</th>
                            <th>Nº Registro</th>
                            <th>Data do Registro</th>
                            <th>Unidade Consumidora</th>
                            <th>Descrição da Cobertura/Ocorrência</th>
                            <th>CPF do Cliente</th>
                            <th>Valor Lançamento</th>
                            <th>Mês de Vigência</th>
                            <th>Comando do Movimento</th>
                            <th>Cobertura/Ocorrência</th>
                            <th>Matricula</th>
                            <th>Código da Origem da Fatura</th>
                            <th>Informativo do Registro</th>
                            <th>Número da Fatura</th>
                            <th>Data de vencimento/baixa</th>
                            <th>Valor base de cálculo</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let file of arrayRetorno">
                            <td style="font-weight:bold">
                                <span *ngIf="file?.identificacao == 2">Remessa</span>
                                <span *ngIf="file?.identificacao == 6">Retorno</span>
                            </td>
                            <td>{{file?.numSeqRegistro}}</td>
                            <td>{{file?.dataRegLancamento}}</td>
                            <td>
                                <span [style.background]="file?.bgColor" [style.color]="file?.fontColor" style="padding:10px;border-radius:15px">
                                    {{file?.unidadeConsumidora}}
                                </span>
                            </td>
                            <td>{{file?.descricaoCoberturaOcorrencia}}</td>
                            <td>{{file?.cpfCliente}}</td>
                            <td>{{file?.valorLancamento | currency:'BRL'}}</td>
                            <td>{{file?.mesVigencia}}</td>
                            <td>{{file?.comandoMovimento}}</td>
                            <td>{{file?.coberturaOcorrencia}}</td>
                            <td>{{file?.idClienteConveniado}}</td>
                            <td>{{file?.codigoOrigemFatura}}</td>
                            <td>{{file?.informativoRegistro}}</td>
                            <td>{{file?.numeroFatura}}</td>
                            <td>{{file?.dataVencimentoBaixa}}</td>
                            <td>{{file?.valorBaseCalculo | currency:'BRL'}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </tab>
    <tab heading="ARQUIVO REMESSA" style="padding:10px">
        <div class="row" style="text-align: right;padding: 2em; width:98%">
            <div class="col">
                <button class="btn btn-danger" (click)="buscarAssociados()">BUSCAR ASSOCIADOS PENDENTES CELESC</button>
            </div>
        </div>
        <table class="table table-striped" style="text-align: center;" *ngIf="associados.length > 0">
            <thead class="thead-dark">
                <tr>
                    <th>Remessa</th>
                    <th>Adesão</th>
                    <th>Matricula</th>
                    <th>Associado</th>
                    <th>CPF</th>
                    <th>Plano</th>
                    <th>Mensalidade</th>
                    <th>Celular</th>
                    <th>Status Celesc</th>
                    <th>Situação</th>
                    <th>Unidade Consumidora</th>
                </tr>
            </thead>
            <tbody *ngFor="let associado of associados" >
                <tr>
                    <td>
                        <input class="form-check-input" type="checkbox" [(ngModel)]="associado.array" (change)="checkArray($event, associado)">
                    </td>
                    <td>{{ associado?.dataAdesao | date: 'dd/MM/yyyy' }}</td>
                    <td>{{ associado?.matricula }}</td>
                    <td>{{ associado?.nome }}</td>
                    <td>{{ associado?.cpf }}</td>
                    <td>{{ associado?.plano }}</td>
                    <td>{{ associado?.totalMensalidade | currency:'BRL' }}</td>
                    <td>{{ associado?.celular }}</td>
                    <td>{{ associado?.celescStatus }}</td>
                    <td>{{ associado?.situacao }}</td>
                    <td>{{ associado?.unidadeConsumidora }}</td>
                </tr>
            </tbody>
        </table>
        <div class="row" style="width:98%; margin-top:3em; text-align: right;" *ngIf="arrayRemessasSelecionadas.length > 0">
            <div class="col">
                <button class="btn btn-danger" (click)="gerarArquivoRemessa()">GERAR ARQUIVO REMESSA</button>
            </div>
        </div>
    </tab>
    <tab heading="CONFIGURAÇÕES" style="padding:30px">
        <div class="row">
            <div class="col col-12">
                <label>Contrato</label>
                <input type="text" class="form-control" [(ngModel)]="contrato" maxlength="56" [ngStyle]="{'border-color': (!contrato || contrato.length < 56) ? '#dd443e' : '#3cb42c'}">
                <small>O Contrato deve conter 56 caracteres, preencher com espaços até o final</small>
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col col-6">
                <label>Código da Concessionaria</label>
                <input type="text" class="form-control" [(ngModel)]="codigoConcessionaria" maxlength="4" [ngStyle]="{'border-color': (!codigoConcessionaria || codigoConcessionaria.length < 4) ? '#dd443e' : '#3cb42c'}">
                <small>O Contrato deve conter 4 caracteres, ex: 0001</small>
            </div>
            <div class="col col-6">
                <label>Nome do Cliente Contratante</label>
                <input type="text" class="form-control" [(ngModel)]="nomeClienteContratante" maxlength="20" [ngStyle]="{'border-color': (!nomeClienteContratante || nomeClienteContratante.length < 20) ? '#dd443e' : '#3cb42c'}">
                <small>O Nome deve conter 20 caracteres, preencher com espaços até o final</small>
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col col-6">
                <label>Código da Conta Gerencial</label>
                <input type="text" class="form-control" [(ngModel)]="codigoContaGerencial" maxlength="8" [ngStyle]="{'border-color': (!codigoContaGerencial || codigoContaGerencial.length < 8) ? '#dd443e' : '#3cb42c'}">
                <small>O Código deve conter 8 caracteres, preencher com espaços até o final</small>
            </div>
            <div class="col col-6">
                <label>Número de Envio</label>
                <input type="number" class="form-control" [(ngModel)]="numSequencialEnvio" min="1" [ngStyle]="{'border-color': !numSequencialEnvio ? '#dd443e' : '#3cb42c'}">
                <small>Sequência dos Envios feitos para a Celesc</small>
            </div>
        </div>
        <br>
        <div class="row" style="width:98%; margin-top:3em; text-align: right;">
            <div class="col">
                <button class="btn btn-danger" (click)="salvarConfigs()" [disabled]="(!contrato || contrato.length < 56) || (!codigoConcessionaria || codigoConcessionaria.length < 4) || (!nomeClienteContratante || nomeClienteContratante.length < 20)  || (!codigoContaGerencial || codigoContaGerencial.length < 8) || !numSequencialEnvio">SALVAR</button>
            </div>
        </div>
    </tab>
</tabset>