<!-- MODAL EDITAR CONTA ORÇAMENTÁRIA -->
<div class="modal" id="modalContaOrcamentaria">
    <div class="row align-items-center header">
        <div class="col titulo">
            NOVA CONTA ORÇAMENTÁRIA
        </div>
        <div class="col botoes">
            <button class="btn btn-outline-light" (click)="closeModalNovo()">
                <fa-icon [icon]="['fas', 'window-close']"></fa-icon>&nbsp;CANCELAR
            </button>
            &nbsp;&nbsp;
            <button class="btn btn-outline-light" (click)="novaContaOrcamentaria()" [disabled]="contaOrcamentariaList.invalid || !nomeContaOrcamentaria">
                <fa-icon [icon]="['fas', 'save']"></fa-icon>&nbsp;CADASTRAR
            </button>
        </div>
    </div>
    <div class="corpo">
        <div class="row">
            <div class="col">
                <label>Conta Orçamentária</label><br>
                <input type="text" [(ngModel)]="nomeContaOrcamentaria" class="form-control">
            </div>
            <div class="col">
                <label>Centros de Custo</label>
                <div style="display:flex;justify-content: space-evenly">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" [(ngModel)]="ccAdministrativo" id="ccadministrativo">
                        <label class="form-check-label" for="ccadministrativo">&nbsp;&nbsp;Administrativo</label>
                    </div>
                    <br>
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" [(ngModel)]="ccComercial" id="cccomercial">
                        <label class="form-check-label" for="cccomercial">&nbsp;&nbsp;Comercial</label>
                    </div>
                    <br>
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" [(ngModel)]="ccPublicidade" id="ccPublicidade">
                        <label class="form-check-label" for="ccPublicidade">&nbsp;&nbsp;Publicidade</label>
                    </div>
                </div>               
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col">
                <form [formGroup]="contaOrcamentaria">
                    <div class="row" formArrayName="orcamentaria">
                        <div class="col col-12" style="font-size: 0.85em" *ngFor="let conta of contaOrcamentariaFormGroup.controls; let i = index;">
                            <div [formGroupName]="i" class="row align-items-center">
                                <div class="form-group col-6">
                                    <label>Conta Contábil:</label>
                                    <input type="text" class="form-control" formControlName="conta">
                                </div>
                                <div class="form-group col-3">
                                    <div class="form-check">
                                        <br>
                                        <input class="form-check-input" type="checkbox" formControlName="programacao">
                                        <label>Exibir na Programação</label>
                                    </div>
                                </div>
                                <div class="form-group col-2 text-right">
                                <br>
                                    <button class="btn btn-danger btn-sm" type="button" (click)="removeConta(i)" *ngIf="contaOrcamentariaList.length > 1">
                                        <fa-icon [icon]="['fas', 'minus-circle']" size="1x"></fa-icon>
                                    </button>&nbsp;
                                    <button class="btn btn-success btn-sm" type="button" (click)="addConta()" [disabled]="contaOrcamentariaList.invalid">
                                        <fa-icon [icon]="['fas', 'plus-circle']" size="1x"></fa-icon>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>   
                </form>
            </div>
        </div>
    </div>
</div>
<!-- MODAL EDITAR CONTA ORÇAMENTÁRIA -->
<div class="modal" id="modalContaOrcamentariaEditar">
    <div class="row align-items-center header">
        <div class="col titulo">
            EDITAR CONTA ORÇAMENTÁRIA
        </div>
        <div class="col botoes">
            <button class="btn btn-outline-light" (click)="closeModalEditar()">
                <fa-icon [icon]="['fas', 'window-close']"></fa-icon>&nbsp;CANCELAR
            </button>
            &nbsp;&nbsp;
            <button class="btn btn-outline-light" (click)="editarContaOrcamentaria()" [disabled]="contaOrcamentariaListEditar.invalid || !conta.contaOrcamentaria">
                <fa-icon [icon]="['fas', 'save']"></fa-icon>&nbsp;SALVAR
            </button>
        </div>
    </div>
    <div class="corpo">
        <div class="row">
            <div class="col">
                <label>Conta Orçamentária</label><br>
                <input type="text" [(ngModel)]="conta.contaOrcamentaria" class="form-control">
            </div>
            <div class="col">
                <label>Centros de Custo</label>
                <div style="display:flex;justify-content: space-evenly">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" [checked]="conta?.centrosCusto?.Administrativo" id="administrativo" (change)="checkCCEditar($event)">
                        <label class="form-check-label" for="administrativo">&nbsp;&nbsp;Administrativo</label>
                    </div>
                    <br>
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" [checked]="conta?.centrosCusto?.Comercial" id="comercial" (change)="checkCCEditar($event)">
                        <label class="form-check-label" for="comercial">&nbsp;&nbsp;Comercial</label>
                    </div>
                    <br>
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" [checked]="conta?.centrosCusto?.Publicidade" id="publicidade" (change)="checkCCEditar($event)">
                        <label class="form-check-label" for="publicidade">&nbsp;&nbsp;Publicidade</label>
                    </div>   
                </div>            
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col">
                <form [formGroup]="contaOrcamentariaEditar">
                    <div class="row" formArrayName="orcamentariaEditar">
                        <div class="col col-12" style="font-size: 0.85em" *ngFor="let conta of contaOrcamentariaFormGroupEditar.controls; let i = index;">
                            <div [formGroupName]="i" class="row align-items-center">
                                <div class="form-group col-6">
                                    <label>Conta Contábil:</label>
                                    <input type="text" class="form-control" formControlName="conta">
                                </div>
                                <div class="form-group col-3">
                                    <div class="form-check">
                                        <br>
                                        <input class="form-check-input" type="checkbox" formControlName="programacao">
                                        <label>Exibir na Programação</label>
                                    </div>
                                </div>
                                <div class="form-group col-2 text-right">
                                <br>
                                    <button class="btn btn-danger btn-sm" type="button" (click)="removeContaEditar(i)" *ngIf="contaOrcamentariaListEditar.length > 1">
                                        <fa-icon [icon]="['fas', 'minus-circle']" size="1x"></fa-icon>
                                    </button>&nbsp;
                                    <button class="btn btn-success btn-sm" type="button" (click)="addContaEditar()" [disabled]="contaOrcamentariaListEditar.invalid">
                                        <fa-icon [icon]="['fas', 'plus-circle']" size="1x"></fa-icon>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>   
                </form>
            </div>
        </div>
    </div>
</div>
<div class="row  align-items-center header">
    <div class="col titulo" style="text-align: left;">
        CENTROS DE CUSTO
    </div>
    <div class="col col-md-7 titulo" style="text-align: right;">
        <div class="row">
            <div class="col">
                <button class="btn btn-outline-light" (click)="openModalNovo()">
                    <fa-icon [icon]="['fas', 'plus']"></fa-icon>&nbsp;CADASTRAR NOVO
                </button>
            </div>
        </div>
    </div>
</div>
<br>
<table class="table table-striped" style="text-align: center;">
    <thead class="thead-dark">
        <tr>
            <th>Conta Orçamentária</th>
            <th>Centros de Custo</th>
            <th>Contas Contábeis</th>
            <th *ngIf="db?.usuario?.permissoes['centroCusto'] == 'EDITAR' || db?.usuario?.permissoes['centroCusto'] == 'EDITAR'">Editar</th>
            <th *ngIf="db?.usuario?.permissoes['centroCusto'] == 'EDITAR' || db?.usuario?.permissoes['centroCusto'] == 'EDITAR'">Excluir</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let cc of centrosCusto; let i = index">
            <td><strong>{{ cc?.contaOrcamentaria }}</strong></td>
            <td style="text-align:left">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" [checked]="cc.centrosCusto.Administrativo" id="Administrativo-{{i}}" (change)="centroCusto($event,cc.contaOrcamentaria)">
                    <label class="form-check-label" for="Administrativo-{{i}}">&nbsp;&nbsp;Administrativo</label>
                </div>
                <br>
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" [checked]="cc.centrosCusto.Comercial" id="Comercial-{{i}}" (change)="centroCusto($event,cc.contaOrcamentaria)">
                    <label class="form-check-label" for="Comercial-{{i}}">&nbsp;&nbsp;Comercial</label>
                </div>
                <br>
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" [checked]="cc.centrosCusto.Publicidade" id="Publicidade-{{i}}" (change)="centroCusto($event,cc.contaOrcamentaria)">
                    <label class="form-check-label" for="Publicidade-{{i}}">&nbsp;&nbsp;Publicidade</label>
                </div>                    
            </td>
            <td>
                <ul *ngFor="let cContabeis of cc?.contasContabeis">
                    <li><span [style.color]="cContabeis?.programacao ? '#1f3d02' : '#5f0707'">{{ cContabeis?.programacao ? '&#10003;' : 'X'}}</span>{{ cContabeis.conta }}</li>
                </ul>
            </td>
            <td *ngIf="db?.usuario?.permissoes['centroCusto'] == 'EDITAR' || db?.usuario?.permissoes['centroCusto'] == 'EDITAR'">
                <button class="btn btn-outline-success" (click)="openModalEditar(cc)">
                    <fa-icon [icon]="['fas', 'edit']"></fa-icon>
                </button>
            </td>
            <td *ngIf="db?.usuario?.permissoes['centroCusto'] == 'EDITAR' || db?.usuario?.permissoes['centroCusto'] == 'EDITAR'">
                <button class="btn btn-outline-danger" (click)="excluirCC(cc)">
                    <fa-icon [icon]="['fas', 'trash']"></fa-icon>
                </button>
            </td>
        </tr>
    </tbody>
</table>
