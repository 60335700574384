import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CaixaComponent } from './caixa/caixa.component';
import { ContasPagarComponent } from './contas-pagar/contas-pagar.component';
import { ContasReceberComponent } from './contas-receber/contas-receber.component';
import { CredenciadosComponent } from './credenciados/credenciados.component';
import { AuthGuard } from './guard/auth.guard';
import { InicioComponent } from './inicio/inicio.component';
import { LoginComponent } from './login/login.component';
import { LogsComponent } from './logs/logs.component';
import { PlanosComponent } from './planos/planos.component';
import { AssociadosComponent } from './associados/associados.component';
import { RelatoriosComponent } from './relatorios/relatorios.component';
import { ColaboradoresComponent } from './colaboradores/colaboradores.component';
import { SubContratosComponent } from './sub-contratos/sub-contratos.component';
import { ConfiguracoesComponent } from './configuracoes/configuracoes.component';
import { ControleVendasComponent } from './controle-vendas/controle-vendas.component';
import { RelatoriosFinanceiroComponent } from './relatorios-financeiro/relatorios-financeiro.component';
import { EmpresasComponent } from './empresas/empresas.component';
import { FaturamentoComponent } from './faturamento/faturamento.component';
import { TarefasComponent } from './tarefas/tarefas.component';
import { CelescComponent } from './celesc/celesc.component';
import { CentroCustoComponent } from './centro-custo/centro-custo.component';
import { ProgramacaoComponent } from './programacao/programacao.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ComissoesComponent } from './comissoes/comissoes.component';
import { MarketingComponent } from './marketing/marketing.component';
import { FornecedoresComponent } from './fornecedores/fornecedores.component';
import { RelatorioComissoesComponent } from './relatorio-comissoes/relatorio-comissoes.component';
import { VendasComponent } from './vendas/vendas.component';

const routes: Routes = [
  { path: '', redirectTo: '/inicio', pathMatch: 'full' },
  { path: 'login', component: LoginComponent},
  { path: 'inicio', component: InicioComponent, canActivate: [AuthGuard] },
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'contas-pagar', component: ContasPagarComponent, canActivate: [AuthGuard] },
  { path: 'contas-receber', component: ContasReceberComponent, canActivate: [AuthGuard] },
  { path: 'credenciados', component: CredenciadosComponent, canActivate: [AuthGuard] },
  { path: 'associados', component: AssociadosComponent, canActivate: [AuthGuard] },
  { path: 'associados/:key', component: AssociadosComponent, canActivate: [AuthGuard] },
  { path: 'relatorios', component: RelatoriosComponent, canActivate: [AuthGuard] },
  { path: 'colaboradores', component: ColaboradoresComponent, canActivate: [AuthGuard] },
  { path: 'caixa', component: CaixaComponent, canActivate: [AuthGuard] },
  { path: 'planos', component: PlanosComponent, canActivate: [AuthGuard] },
  { path: 'configuracoes', component: ConfiguracoesComponent, canActivate: [AuthGuard] },
  { path: 'sub-contratos', component: SubContratosComponent, canActivate: [AuthGuard] },
  { path: 'controle-vendas', component: ControleVendasComponent, canActivate: [AuthGuard] },
  { path: 'centro-custo', component: CentroCustoComponent, canActivate: [AuthGuard] },
  { path: 'relatorios-financeiro', component: RelatoriosFinanceiroComponent, canActivate: [AuthGuard] },
  { path: 'logs', component: LogsComponent, canActivate: [AuthGuard] },
  { path: 'empresas', component: EmpresasComponent, canActivate: [AuthGuard] },
  { path: 'faturamento', component: FaturamentoComponent, canActivate: [AuthGuard] },
  { path: 'tarefas', component: TarefasComponent, canActivate: [AuthGuard] },
  { path: 'celesc', component: CelescComponent, canActivate: [AuthGuard] },
  { path: 'programacao', component: ProgramacaoComponent, canActivate: [AuthGuard] },
  { path: 'comissoes', component: ComissoesComponent, canActivate: [AuthGuard] },
  { path: 'relatorio-comissoes', component: RelatorioComissoesComponent, canActivate: [AuthGuard] },
  { path: 'fornecedores', component: FornecedoresComponent, canActivate: [AuthGuard] },
  { path: 'marketing', component: MarketingComponent, canActivate: [AuthGuard] },
  { path: 'vendas', component: VendasComponent, canActivate: [AuthGuard] }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
