<div class="container-fluid">
    <div id="myModal" class="modalLightbox">
        <span (click)="closeModal()" class="close">&times;</span>
        <div class="prev" (click)="prevImg()">&lt;</div>
        <img [src]="imagemSelecionada" class="imagemLightbox">
        <div class="next" (click)="nextImg()">&gt;</div>
    </div> 
    <div style="width: 800px; height: 400px;display: none;">
        <ng2-pdfjs-viewer 
          #pdfViewerCredenciados
          [externalWindow]="true"
          [downloadFileName]="'vivamax.pdf'"
          [openFile]="false"
          [viewBookmark]="false"
          [download]="true"></ng2-pdfjs-viewer>
    </div>
    <!-- MODAL QRCODE -->
    <div class="modalSenha" id="modalExcluir">
        <div class="box">
            <h2 style="color:brown">ATENÇÃO!</h2><br>
            <h4 style="line-height: 1.8em;">POR FAVOR DESCREVA O MOTIVO DA EXCLUSÃO ANTES DE PROSSEGUIR</h4><br>
            <br>
            <textarea class="form-control" [(ngModel)]="motivoExclusao" rows="4"></textarea>
            <br>
            <div class="row" style="text-align: center;">
                <div class="col">
                    <button class="btn btn-dark btn-lg" (click)="closeModalExcluirCredenciado()">FECHAR</button>
                </div>
                <div class="col">
                    <button class="btn btn-danger btn-lg" (click)="excluirCredenciado()" [disabled]="!motivoExclusao">EXCLUIR</button>
                </div>
            </div>
        </div>
    </div>
    <!-- MODAL EDITAR CREDENCIADO -->
    <div class="modal" id="editarCredenciado">
        <div style="padding:50px">
            <div class="row">
                <div class="col col-3">
                    <label>Situação</label>
                    <select class="form-control" [(ngModel)]="situacaoEditar">
                        <option value="ATIVO">ATIVO</option>
                        <option value="INATIVO">INATIVO</option>
                    </select>
                </div> 
                <div class="col col-3">
                    <label>Data Inicio do Contrato</label>
                    <input type="date" class="form-control" [(ngModel)]="dataContratoEditar">
                </div>
                <div class="col col-6">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="checkSindilojasEditar" [(ngModel)]="sindilojasEditar">
                        <label class="form-check-label" for="checkSindilojasEditar" style="margin-left:15px">REDE PARCEIRA SINDILOJAS SAÚDE</label>
                    </div>
                    <br>
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="checkAmicEditar" [(ngModel)]="amicEditar">
                        <label class="form-check-label" for="checkAmicEditar" style="margin-left:15px">REDE PARCEIRA AMIC</label>
                    </div>
                    <br>
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="checkGuiaMedicaEditar" [(ngModel)]="guiaMedicaEditar">
                        <label class="form-check-label" for="checkGuiaMedicaEditar" style="margin-left:15px">NECESSIDADE GUIA MÉDICA</label>
                    </div>
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col col-3">
                    <label>Razão Social</label>
                    <input type="text" class="form-control" [(ngModel)]="razaoCredenciadoEditar" [ngStyle]="{'border-color': !razaoCredenciadoEditar ? '#dd443e' : '#3cb42c'}" oninput="this.value = this.value.toUpperCase()">
                </div>
                <div class="col col-3">
                    <label>Nome Fantasia</label>
                    <input type="text" class="form-control" [(ngModel)]="fantasiaCredenciadoEditar" [ngStyle]="{'border-color': !fantasiaCredenciadoEditar ? '#dd443e' : '#3cb42c'}" oninput="this.value = this.value.toUpperCase()">
                </div>
                <div class="col col-3">
                    <label>CNPJ</label>
                    <input type="text" class="form-control" cnpj [textMask]="{mask: MASKS.cnpj.textMask}"[(ngModel)]="cnpjCredenciadoEditar" [ngStyle]="{'border-color': !cnpjCredenciadoEditar ? '#dd443e' : '#3cb42c'}">
                </div>
                <div class="col col-3">
                    <label>CPF</label>
                    <input type="text" class="form-control" cpf [textMask]="{mask: MASKS.cpf.textMask}"[(ngModel)]="cpfCredenciadoEditar">
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col col-2">
                    <label>CEP:</label>
                    <input type="text" cep [textMask]="{mask: MASKS.cep.textMask}" class="form-control" [(ngModel)]="cepEditar" (keyup)="buscarCepEditar(cepEditar)">
                </div>
                <div class="col col-5">
                    <label>Rua:</label>
                    <input type="text" class="form-control" [(ngModel)]="ruaEditar" oninput="this.value = this.value.toUpperCase()" [ngStyle]="{'border-color': !ruaEditar ? '#dd443e' : '#3cb42c'}">
                </div>
                <div class="col col-2">
                    <label>Número:</label>
                    <input type="text" class="form-control" [(ngModel)]="numeroEditar" [ngStyle]="{'border-color': !numeroEditar ? '#dd443e' : '#3cb42c'}">
                </div>
                <div class="col col-3">
                    <label>Bairro:</label>
                    <input type="text" class="form-control" [(ngModel)]="bairroEditar" oninput="this.value = this.value.toUpperCase()" [ngStyle]="{'border-color': !bairroEditar ? '#dd443e' : '#3cb42c'}">
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col col-3">
                    <label>Complemento:</label>
                    <input type="text" class="form-control" [(ngModel)]="complementoEditar" oninput="this.value = this.value.toUpperCase()" [ngStyle]="{'border-color': !complementoEditar ? '#dd443e' : '#3cb42c'}">
                </div>
                <div class="col col-4">
                    <label>Município:</label>
                    <select [(ngModel)]="municipioEditar" (change)="getUfEditar(municipioEditar)" class="form-control" [ngStyle]="{'border-color': !municipioEditar ? '#dd443e' : '#3cb42c'}">
                        <option selected value=""></option>
                        <option *ngFor="let esp of todasCidades" [value]="esp.cidade">{{esp?.cidade}}</option>
                    </select>
                </div>
                <div class="col col-1">
                    <label>UF:</label>
                    <input type="text" class="form-control" [(ngModel)]="estadoEditar" oninput="this.value = this.value.toUpperCase()" [ngStyle]="{'border-color': !estadoEditar ? '#dd443e' : '#3cb42c'}">
                </div>
                <div class="col col-4">
                    <label>Ponto de Referência:</label>
                    <input type="text" class="form-control" [(ngModel)]="pontoReferenciaEditar" oninput="this.value = this.value.toUpperCase()">
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col col-4">
                    <label>Telefone:</label>
                    <input type="text" class="form-control" [textMask]="{mask: MASKS.telefone.textMaskFunction}" [(ngModel)]="telefoneEditar" [ngStyle]="{'border-color': !telefoneEditar ? '#dd443e' : '#3cb42c'}">
                </div>
                <div class="col col-2">
                    <label>Exibir Telefone:</label>
                    <div style="text-align: center;">
                        <input class="form-check-input" type="checkbox" [(ngModel)]="telefoneExibirEditar">
                    </div>
                </div>
                <div class="col col-4">
                    <label>Telefone 2:</label>
                    <input type="text" class="form-control" [textMask]="{mask: MASKS.telefone.textMaskFunction}" [(ngModel)]="telefone2Editar">
                </div>
                <div class="col col-2">
                    <label>Exibir Telefone 2:</label>
                    <div style="text-align: center;">
                        <input class="form-check-input" type="checkbox" [(ngModel)]="telefone2ExibirEditar">
                    </div>
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col col-6">
                    <label>Nome Contato:</label>
                    <input type="text" class="form-control" [(ngModel)]="nomeContatoEditar" oninput="this.value = this.value.toUpperCase()">
                </div>
                <div class="col col-6">
                    <label>E-mail:</label>
                    <input type="text" class="form-control" [(ngModel)]="emailEditar" oninput="this.value = this.value.toUpperCase()" [ngStyle]="{'border-color': !emailEditar ? '#dd443e' : '#3cb42c'}">
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="tabelaUnicaEditar" [(ngModel)]="tabelaUnicaCheckEditar">
                        <label class="form-check-label" for="tabelaUnicaEditar" style="margin-left:15px">TABELA ÚNICA PARA TODOS OS PROFISSIONAIS</label>
                    </div>
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="laboratorioEditar" [(ngModel)]="laboratorioCheckEditar">
                        <label class="form-check-label" for="laboratorioEditar" style="margin-left:15px">LABORATÓRIO</label>
                    </div>
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="precoVariavelEditar" [(ngModel)]="precoVariavelCheckEditar">
                        <label class="form-check-label" for="precoVariavelEditar" style="margin-left:15px">TABELA DE PREÇOS VARIÁVEL</label>
                    </div>
                </div>
            </div>
            <br>
            <div class="row" *ngIf="tabelaUnicaCheckEditar">
                <div class="col col-6">
                    <label>Especialidade</label>
                    <select [(ngModel)]="especialidadeCredenciadoEditar" class="form-control" [ngStyle]="{'border-color': !especialidadeCredenciadoEditar ? '#dd443e' : '#3cb42c'}">
                        <option selected value=""></option>
                        <option *ngFor="let esp of todasEspecialidades" [value]="esp.nome">{{esp?.nome}}</option>
                    </select>
                </div>
                <div class="col col-3">
                    <label>Valor</label>
                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                        <span class="input-group-text" id="basic-addon1">R$</span>
                        </div>
                        <input type="number" class="form-control" [(ngModel)]="valorEspecialidadeEditar" [disabled]="percentualEspecialidadeEditar">
                    </div>   
                </div>
                <div class="col col-3">
                    <label>Percentual</label>
                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                          <span class="input-group-text" id="basic-addon1">%</span>
                        </div>
                        <input type="number" class="form-control" [(ngModel)]="percentualEspecialidadeEditar" [disabled]="valorEspecialidadeEditar">
                    </div>   
                </div>
            </div>
            <div class="row" *ngIf="laboratorioCheckEditar">
                <div class="col col-8">
                    <label>Tipo</label>
                    <input type="text" [(ngModel)]="tipoLaboratorioEditar" class="form-control" oninput="this.value = this.value.toUpperCase()" [ngStyle]="{'border-color': !tipoLaboratorioEditar ? '#dd443e' : '#3cb42c'}">
                </div>
                <div class="col col-4">
                    <label>Valor</label>
                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                        <span class="input-group-text" id="basic-addon1">R$</span>
                        </div>
                        <input type="number" class="form-control" [(ngModel)]="valorLaboratorioEditar" [ngStyle]="{'border-color': !valorLaboratorioEditar ? '#dd443e' : '#3cb42c'}">
                    </div>   
                </div>
            </div>
            <div class="row" *ngIf="precoVariavelCheckEditar">
                <div class="col col-3">
                    <label>Variável De</label>
                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon1">R$</span>
                        </div>
                        <input type="number" [(ngModel)]="variavelDeEditar" class="form-control" [ngStyle]="{'border-color': !variavelDeEditar ? '#dd443e' : '#3cb42c'}">
                    </div>
                </div>
                <div class="col col-3">
                    <label>Variável Até</label>
                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon1">R$</span>
                        </div>
                        <input type="number" [(ngModel)]="variavelAteEditar" class="form-control" [ngStyle]="{'border-color': !variavelAteEditar ? '#dd443e' : '#3cb42c'}">
                    </div>
                </div>
            </div>
            <br>
            <label>PROFISSIONAIS:</label>
            <br>
            <form [formGroup]="profissionaisCredenciadoEditar">
                <div class="row" formArrayName="profissionalEditar">
                    <div class="col col-12" style="font-size: 0.85em" *ngFor="let profissionalEditar of profissionaisCredenciadoFormGroupEditar.controls; let i = index;">
                        <div [formGroupName]="i" class="row">
                            <div class="form-group col-3">
                                <label>Nome:</label>
                                <input type="text" class="form-control" formControlName="profissional" oninput="this.value = this.value.toUpperCase()">
                            </div>
                            <div class="form-group col-3">
                                <label>Especialidade:</label>
                                <select formControlName="especialidade" class="form-control">
                                    <option selected value=""></option>
                                    <option *ngFor="let espe of todasEspecialidades" [value]="espe.nome">{{espe?.nome}}</option>
                                </select>
                            </div>
                             <div class="form-group col-2">
                                <label>Particular:</label>
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="basic-addon1">R$</span>
                                    </div>
                                    <input type="number" class="form-control" formControlName="particular">
                                </div>
                            </div>
                            <div class="form-group col-2">
                                <label>Viva Max:</label>
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="basic-addon1">R$</span>
                                    </div>
                                    <input type="number" class="form-control" formControlName="valor">
                                </div>
                            </div>
                            <div class="form-group col-2 text-right">
                                <br>
                                <button class="btn btn-danger btn-sm" type="button" (click)="removeProfissionalEditar(i)" *ngIf="this.profissionaisCredenciadoListEditar.length > 1 && db?.usuario?.permissoes['credenciados'] == 'EDITAR'">
                                <fa-icon [icon]="['fas', 'minus-circle']" size="1x"></fa-icon>
                                </button>&nbsp;
                                <button class="btn btn-success btn-sm" type="button" (click)="addProfissionalEditar()" *ngIf="db?.usuario?.permissoes['credenciados'] == 'EDITAR'">
                                <fa-icon [icon]="['fas', 'plus-circle']" size="1x"></fa-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>   
            </form>
            <br>
            <label>EXAMES:</label>
            <br>
            <form [formGroup]="examesCredenciadoEditar">
                <div class="row" formArrayName="exameEditar">
                    <div class="col col-12" style="font-size: 0.85em" *ngFor="let exames of examesCredenciadoFormGroupEditar.controls; let i = index;">
                        <div [formGroupName]="i" class="row">
                            <div class="form-group col-4">
                                <label>Nome:</label>
                                <input type="text" class="form-control" formControlName="nomeExame" oninput="this.value = this.value.toUpperCase()">
                            </div>
                            <div class="form-group col-3" style="text-align: center;">
                                <label>Particular</label>
                                <div class="row">
                                    <div class="col col-6">
                                        <div class="input-group mb-3">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text" id="basic-addon1">R$</span>
                                            </div>
                                            <input type="number" min=0 class="form-control" formControlName="particular" [readonly]="examesCredenciadoEditar.controls.exameEditar.value[i].percentualParticular">
                                        </div>
                                    </div>
                                    <div class="col col-6">
                                        <div class="input-group mb-3">
                                            <input type="number" class="form-control" min=0 formControlName="percentualParticular" [readonly]="examesCredenciadoEditar.controls.exameEditar.value[i].particular">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text" id="basic-addon1">%</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-3" style="text-align: center;">
                                <label>Viva Max</label>
                                <div class="row">
                                    <div class="col col-6">
                                        <div class="input-group mb-3">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text" id="basic-addon1">R$</span>
                                            </div>
                                            <input type="number" class="form-control" formControlName="valor" min=0 [readonly]="examesCredenciadoEditar.controls.exameEditar.value[i].percentualValor">
                                        </div>
                                    </div>
                                    <div class="col col-6">
                                        <div class="input-group mb-3">
                                            <input type="number" class="form-control" formControlName="percentualValor" min=0 [readonly]="examesCredenciadoEditar.controls.exameEditar.value[i].valor">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text" id="basic-addon1">%</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-2 text-right">
                            <br>
                                <button class="btn btn-danger btn-sm" type="button" (click)="removeExameEditar(i)" *ngIf="this.examesCredenciadoListEditar.length > 1 && db?.usuario?.permissoes['credenciados'] == 'EDITAR'">
                                     <fa-icon [icon]="['fas', 'minus-circle']" size="1x"></fa-icon>
                                </button>&nbsp;
                                <button class="btn btn-success btn-sm" type="button" (click)="addExameEditar()">
                                      <fa-icon [icon]="['fas', 'plus-circle']" size="1x"></fa-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>   
            </form>
            <br>
            <div class="row">
                <div class="col">
                    <label>Observações Interna</label>
                    <textarea class="form-control" style="height: 300px;" [(ngModel)]="observacoesInternaEditar" oninput="this.value = this.value.toUpperCase()" rows="2"></textarea>
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col">
                    <label>Observações Cliente (Aparece na Área do Cliente)</label>
                    <textarea class="form-control" style="height: 300px;" [(ngModel)]="observacoesClienteEditar" oninput="this.value = this.value.toUpperCase()" rows="2"></textarea>
                </div>
            </div>
            <br>
            <div class="row justify-content-md-center" *ngIf="db?.usuario?.permissoes['credenciados'] == 'EDITAR'">
                <div class="col col-3" *ngFor="let pdf of pdfs;" style="margin: 10px; background-color:#ce2626; color:white; height: 250px; width:100%; text-align: center; border-radius: 10px;">
                    <div class="delButton" name="delButton">
                        <button class="btn btn-dark btn-sm" (click)="delDoc(pdf.url)"><strong>X</strong></button>
                    </div>
                    <div (click)="openModalPdfViewer(pdf.url)" style="position: relative;left:50%;top:50%;transform: translate(-50%,-50%);cursor:pointer">
                        <fa-icon [icon]="['fas', 'file-pdf']" style="font-size: 5em;"></fa-icon>
                            <br>DOCUMENTO PDF
                    </div>
                </div>
                <div class="col col-3 img-container" *ngFor="let img of images; let i = index" style="margin: 10px; text-align: center;">
                    <div class="delButton" name="delButton">
                        <button class="btn btn-danger btn-sm" (click)="delDoc(img.url)"><strong>X</strong></button>
                    </div>
                    <img [src]="img.url" (click)="openModalLightBox(img.url, i)" style="border-radius: 10px; height: 250px; width:100%;" crossorigin="anonymous">
                </div>
            </div>
            <br>
            <div class="row" *ngIf="db?.usuario?.permissoes['credenciados'] == 'EDITAR'">
                <div class="col">
                    <div class="uploadfilecontainer" (click)="fileDocumentosEditar.click()" appDragDrop (onFileDropped)="onChangeEditar($event)">
                        <input hidden type="file" #fileDocumentosEditar (change)="onChangeClickEditar($event)" multiple>
                    </div>
                    <div class="row align-items-center">
                        <div class="col col-1">
                            &nbsp;
                        </div>
                        <div class="col">
                            <div class="files-list" *ngFor="let fileEditar of filesEditar; let i= index">
                                <p>{{ fileEditar }}</p>
                            </div> 
                        </div>
                        <div class="col col-2" [hidden]="filesEditar.length < 1">
                            <button class="btn btn-primary btn-block" (click)="anexarDoc()">ANEXAR</button>
                        </div>
                        <div class="col col-1">
                            &nbsp;
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" style="margin-top: 80px;">
                <div class="col col-4">
                    &nbsp;
                </div>
                <div class="col col-2">
                    <button class="btn btn-danger btn-block" (click)="closeModalEditar()">CANCELAR</button>
                </div>
                <div class="col col-2">
                        <button class="btn btn-success btn-block" (click)="editarCredenciado()" *ngIf="db?.usuario?.permissoes['credenciados'] == 'EDITAR'">EDITAR</button>
                </div>
                <div class="col col-4">
                    &nbsp;
                </div>
            </div>
        </div>
    <!-- FIM NOVO EDITAR -->
    </div>   
    <!-- MODAL NOVO CREDENCIADO -->
    <div class="modal" id="novoCredenciado">
        <div style="padding:50px">
            <div class="row">
                <div class="col col-3">
                    <label>Situação</label>
                    <select class="form-control" [(ngModel)]="situacao">
                        <option value="ATIVO">ATIVO</option>
                        <option value="INATIVO">INATIVO</option>
                    </select>
                </div> 
                <div class="col col-3">
                    <label>Data Inicio do Contrato</label>
                    <input type="date" class="form-control" [(ngModel)]="dataContrato">
                </div>
                <div class="col col-6">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="checkSindilojas" [(ngModel)]="sindilojas">
                        <label class="form-check-label" for="checkSindilojas" style="margin-left:15px">REDE PARCEIRA SINDILOJAS SAÚDE</label>
                    </div>
                    <br>
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="checkAmic" [(ngModel)]="amic">
                        <label class="form-check-label" for="checkAmic" style="margin-left:15px">REDE PARCEIRA AMIC</label>
                    </div>
                    <br>
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="checkGuiaMedica" [(ngModel)]="guiaMedica">
                        <label class="form-check-label" for="checkGuiaMedica" style="margin-left:15px">NECESSIDADE GUIA MÉDICA</label>
                    </div>
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col col-3">
                    <label>Razão Social</label>
                    <input type="text" class="form-control" [(ngModel)]="razaoCredenciado" [ngStyle]="{'border-color': !razaoCredenciado ? '#dd443e' : '#3cb42c'}" oninput="this.value = this.value.toUpperCase()">
                </div>
                <div class="col col-3">
                    <label>Nome Fantasia</label>
                    <input type="text" class="form-control" [(ngModel)]="fantasiaCredenciado" [ngStyle]="{'border-color': !fantasiaCredenciado ? '#dd443e' : '#3cb42c'}" oninput="this.value = this.value.toUpperCase()">
                </div>
                <div class="col col-3">
                    <label>CNPJ</label>
                    <input type="text" class="form-control" cnpj [textMask]="{mask: MASKS.cnpj.textMask}" [(ngModel)]="cnpjCredenciado" [ngStyle]="{'border-color': !cpfCredenciado && !cnpjCredenciado ? '#dd443e' : '#3cb42c'}">
                </div>
                <div class="col col-3">
                    <label>CPF</label>
                    <input type="text" class="form-control" cpf [textMask]="{mask: MASKS.cpf.textMask}" [(ngModel)]="cpfCredenciado" [ngStyle]="{'border-color': !cnpjCredenciado && !cpfCredenciado ? '#dd443e' : '#3cb42c'}">
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col col-2">
                    <label>CEP:</label>
                    <input type="text" cep [textMask]="{mask: MASKS.cep.textMask}" class="form-control" [(ngModel)]="cep" (keyup)="buscarCep(cep)">
                </div>
                <div class="col col-5">
                    <label>Rua:</label>
                    <input type="text" class="form-control" [(ngModel)]="rua" oninput="this.value = this.value.toUpperCase()" [ngStyle]="{'border-color': !rua ? '#dd443e' : '#3cb42c'}">
                </div>
                <div class="col col-2">
                    <label>Número:</label>
                    <input type="text" class="form-control" [(ngModel)]="numero" [ngStyle]="{'border-color': !numero ? '#dd443e' : '#3cb42c'}">
                </div>
                <div class="col col-3">
                    <label>Bairro:</label>
                    <input type="text" class="form-control" [(ngModel)]="bairro" oninput="this.value = this.value.toUpperCase()" [ngStyle]="{'border-color': !bairro ? '#dd443e' : '#3cb42c'}">
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col col-3">
                    <label>Complemento:</label>
                    <input type="text" class="form-control" [(ngModel)]="complemento" oninput="this.value = this.value.toUpperCase()" [ngStyle]="{'border-color': !complemento ? '#dd443e' : '#3cb42c'}">
                </div>
                <div class="col col-4">
                    <label>Município:</label>
                    <select [(ngModel)]="municipio" (change)="getUf(municipio)" class="form-control" [ngStyle]="{'border-color': !municipio ? '#dd443e' : '#3cb42c'}">
                        <option selected value=""></option>
                        <option *ngFor="let esp of todasCidades" [value]="esp.cidade">{{esp.cidade}}</option>
                    </select>
                </div>
                <div class="col col-1">
                    <label>UF:</label>
                    <input type="text" class="form-control" [(ngModel)]="estado" oninput="this.value = this.value.toUpperCase()" [ngStyle]="{'border-color': !estado ? '#dd443e' : '#3cb42c'}">
                </div>
                <div class="col col-4">
                    <label>Ponto de Referência:</label>
                    <input type="text" class="form-control" [(ngModel)]="pontoReferencia" oninput="this.value = this.value.toUpperCase()">
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col col-4">
                    <label>Telefone:</label>
                    <input type="text" class="form-control" [textMask]="{mask: MASKS.telefone.textMaskFunction}" [(ngModel)]="telefone" [ngStyle]="{'border-color': !telefone ? '#dd443e' : '#3cb42c'}">
                </div>
                <div class="col col-2">
                    <label>Exibir Telefone:</label>
                    <div style="text-align: center;">
                        <input class="form-check-input" type="checkbox" [(ngModel)]="telefoneExibir">
                    </div>
                </div>
                <div class="col col-4">
                    <label>Telefone 2:</label>
                    <input type="text" class="form-control" [textMask]="{mask: MASKS.telefone.textMaskFunction}" [(ngModel)]="telefone2">
                </div>
                <div class="col col-2">
                    <label>Exibir Telefone 2:</label>
                    <div style="text-align: center;">
                        <input class="form-check-input" type="checkbox" [(ngModel)]="telefone2Exibir">
                    </div>
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col col-6">
                    <label>Nome Contato:</label>
                    <input type="text" class="form-control" [(ngModel)]="nomeContato" oninput="this.value = this.value.toUpperCase()">
                </div>
                <div class="col col-6">
                    <label>E-mail:</label>
                    <input type="text" class="form-control" [(ngModel)]="email" oninput="this.value = this.value.toUpperCase()" [ngStyle]="{'border-color': !email ? '#dd443e' : '#3cb42c'}">
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="tabelaUnica" [(ngModel)]="tabelaUnicaCheck">
                        <label class="form-check-label" for="tabelaUnica" style="margin-left:15px">TABELA ÚNICA PARA TODOS OS PROFISSIONAIS</label>
                    </div>
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="laboratorio" [(ngModel)]="laboratorioCheck">
                        <label class="form-check-label" for="laboratorio" style="margin-left:15px">LABORATÓRIO</label>
                    </div>
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="precoVariavel" [(ngModel)]="precoVariavelCheck">
                        <label class="form-check-label" for="precoVariavel" style="margin-left:15px">TABELA DE PREÇOS VARIÁVEL</label>
                    </div>
                </div>
            </div>
            <br>
            <div class="row" *ngIf="tabelaUnicaCheck">
                <div class="col col-6">
                    <label>Especialidade</label>
                    <select [(ngModel)]="especialidadeCredenciado" class="form-control" [ngStyle]="{'border-color': !especialidadeCredenciado ? '#dd443e' : '#3cb42c'}">
                        <option selected value=""></option>
                        <option *ngFor="let esp of todasEspecialidades" [value]="esp.nome">{{esp.nome}}</option>
                    </select>
                </div>
                <div class="col col-3">
                    <label>Valor</label>
                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                          <span class="input-group-text" id="basic-addon1">R$</span>
                        </div>
                        <input type="number" class="form-control" [(ngModel)]="valorEspecialidade" [disabled]="percentualEspecialidade">
                    </div>   
                </div>
                <div class="col col-3">
                    <label>Percentual</label>
                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                          <span class="input-group-text" id="basic-addon1">%</span>
                        </div>
                        <input type="number" class="form-control" [(ngModel)]="percentualEspecialidade" [disabled]="valorEspecialidade">
                    </div>   
                </div>
            </div>
            <div class="row" *ngIf="laboratorioCheck">
                <div class="col col-8">
                    <label>Tipo</label>
                    <input type="text" [(ngModel)]="tipoLaboratorio" class="form-control" [ngStyle]="{'border-color': !tipoLaboratorio ? '#dd443e' : '#3cb42c'}" oninput="this.value = this.value.toUpperCase()">
                </div>
                <div class="col col-4">
                    <label>Valor</label>
                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                          <span class="input-group-text" id="basic-addon1">R$</span>
                        </div>
                        <input type="number" class="form-control" [(ngModel)]="valorLaboratorio" [ngStyle]="{'border-color': !valorLaboratorio ? '#dd443e' : '#3cb42c'}">
                    </div>   
                </div>
            </div>
            <div class="row" *ngIf="precoVariavelCheck">
                <div class="col col-3">
                    <label>Variável De</label>
                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                          <span class="input-group-text" id="basic-addon1">R$</span>
                        </div>
                        <input type="number" [(ngModel)]="variavelDe" class="form-control" [ngStyle]="{'border-color': !variavelDe ? '#dd443e' : '#3cb42c'}">
                    </div>  
                </div>
                <div class="col col-3">
                    <label>Variável Até</label>
                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                          <span class="input-group-text" id="basic-addon1">R$</span>
                        </div>
                        <input type="number" [(ngModel)]="variavelAte" class="form-control" [ngStyle]="{'border-color': !variavelAte ? '#dd443e' : '#3cb42c'}">
                    </div>  
                </div>
            </div>
            <br>
            <label>PROFISSIONAIS:</label>
            <br>
            <form [formGroup]="profissionaisCredenciado">
                <div class="row" formArrayName="profissional">
                    <div class="col col-12" style="font-size: 0.85em" *ngFor="let profissional of profissionaisCredenciadoFormGroup.controls; let i = index;">
                        <div [formGroupName]="i" class="row">
                            <div class="form-group col-3">
                                <label>Nome:</label>
                                <input type="text" class="form-control" formControlName="profissional" oninput="this.value = this.value.toUpperCase()">
                            </div>
                            <div class="form-group col-3">
                                <label>Especialidade:</label>
                                <select formControlName="especialidade" class="form-control">
                                    <option selected value=""></option>
                                    <option *ngFor="let esp of todasEspecialidades" [value]="esp.nome">{{esp.nome}}</option>
                                </select>
                            </div>
                            <div class="form-group col-2">
                                <label>Particular:</label>
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="basic-addon1">R$</span>
                                    </div>
                                    <input type="number" class="form-control" formControlName="particular">
                                </div>
                            </div>
                            <div class="form-group col-2">
                                <label>Viva Max:</label>
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="basic-addon1">R$</span>
                                    </div>
                                    <input type="number" class="form-control" formControlName="valor">
                                </div>
                            </div>
                            <div class="form-group col-2 text-right">
                                <br>
                                <button class="btn btn-danger btn-sm" type="button" (click)="removeProfissional(i)" *ngIf="this.profissionaisCredenciadoList.length > 1">
                                <fa-icon [icon]="['fas', 'minus-circle']" size="1x"></fa-icon>
                                </button>&nbsp;
                                <button class="btn btn-success btn-sm" type="button" (click)="addProfissional()">
                                <fa-icon [icon]="['fas', 'plus-circle']" size="1x"></fa-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>   
            </form>
            <br>
            <label>EXAMES:</label>
            <br>
            <form [formGroup]="examesCredenciado">
                <div class="row" formArrayName="exame">
                    <div class="col col-12" style="font-size: 0.85em" *ngFor="let exames of examesCredenciadoFormGroup.controls; let i = index;">
                        <div [formGroupName]="i" class="row">
                            <div class="form-group col-4">
                                <label>Nome:</label>
                                <input type="text" class="form-control" formControlName="nomeExame" oninput="this.value = this.value.toUpperCase()">
                            </div>
                            <div class="form-group col-3" style="text-align: center;">
                                <label>Particular</label>
                                <div class="row">
                                    <div class="col col-6">
                                        <div class="input-group mb-3">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text" id="basic-addon1">R$</span>
                                            </div>
                                            <input type="number" min=0 class="form-control" formControlName="particular" [readonly]="examesCredenciado.controls.exame.value[i].percentualParticular">
                                        </div>
                                    </div>
                                    <div class="col col-6">
                                        <div class="input-group mb-3">
                                            <input type="number" class="form-control" min=0 formControlName="percentualParticular" [readonly]="examesCredenciado.controls.exame.value[i].particular">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text" id="basic-addon1">%</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-3" style="text-align: center;">
                                <label>Viva Max</label>
                                <div class="row">
                                    <div class="col col-6">
                                        <div class="input-group mb-3">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text" id="basic-addon1">R$</span>
                                            </div>
                                            <input type="number" class="form-control" formControlName="valor" min=0 [readonly]="examesCredenciado.controls.exame.value[i].percentualValor">
                                        </div>
                                    </div>
                                    <div class="col col-6">
                                        <div class="input-group mb-3">
                                            <input type="number" class="form-control" formControlName="percentualValor" min=0 [readonly]="examesCredenciado.controls.exame.value[i].valor">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text" id="basic-addon1">%</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-2 text-right">
                            <br>
                                <button class="btn btn-danger btn-sm" type="button" (click)="removeExame(i)" *ngIf="this.examesCredenciadoList.length > 1">
                                     <fa-icon [icon]="['fas', 'minus-circle']" size="1x"></fa-icon>
                                </button>&nbsp;
                                <button class="btn btn-success btn-sm" type="button" (click)="addExame()">
                                      <fa-icon [icon]="['fas', 'plus-circle']" size="1x"></fa-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>   
            </form>
            <br>
            <div class="row">
                <div class="col">
                    <label>Observações Interna</label>
                    <textarea class="form-control" style="height: 300px;" [(ngModel)]="observacoesInterna" oninput="this.value = this.value.toUpperCase()" rows="2"></textarea>
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col">
                    <label>Observações Cliente (Aparece na Área do Cliente)</label>
                    <textarea class="form-control" style="height: 300px;" [(ngModel)]="observacoesCliente" oninput="this.value = this.value.toUpperCase()" rows="2"></textarea>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <div class="uploadfilecontainer" (click)="fileDocumentos.click()" appDragDrop (onFileDropped)="onChange($event)">
                        <input hidden type="file" #fileDocumentos (change)="onChangeClick($event)" multiple>
                    </div>
                    <div class="row align-items-center">
                        <div class="col col-1">
                            &nbsp;
                        </div>
                        <div class="col">
                            <div class="files-list" *ngFor="let file of files; let i= index">
                                <p>{{ file }}</p>
                            </div> 
                        </div>
                        <div class="col col-1">
                            &nbsp;
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" style="margin-top: 80px;">
                <div class="col col-4">
                    &nbsp;
                </div>
                <div class="col col-2">
                     <button class="btn btn-danger btn-block" (click)="closeModalNovo()">CANCELAR</button>
                </div>
                <div class="col col-2">
                     <button class="btn btn-success btn-block" (click)="cadastrarCredenciado()">SALVAR</button>
                </div>
                <div class="col col-4">
                     &nbsp;
                 </div>
            </div>
        </div>
    </div>   
<!-- MODAL CADASTRO FORMA PAGAMENTO -->
<div class="modal" id="modalEspecialidadesMedica">
    <div style="padding:50px">
        <form [formGroup]="EspecialidadesMedica">
            <div class="row" formArrayName="nome">
                <div class="col col-12" style="font-size: 0.85em" *ngFor="let nome of EspecialidadesMedicaFormGroup.controls; let i = index;">
                    <div [formGroupName]="i" class="row">
                        <div class="form-group col-9">
                            <input type="text" class="form-control" formControlName="nome" placeholder="Especialidade Médica" oninput="this.value = this.value.toUpperCase()">
                        </div>
                        <div class="form-group col-3 text-right">
                            <button class="btn btn-danger btn-sm" type="button" (click)="removeEspecialidadesMedica(i)" *ngIf="this.especialidadeMedicaList.length > 1">
                            <fa-icon [icon]="['fas', 'minus-circle']" size="1x"></fa-icon>
                            </button>&nbsp;
                            <button class="btn btn-success btn-sm" type="button" (click)="addEspecialidadesMedica()">
                            <fa-icon [icon]="['fas', 'plus-circle']" size="1x"></fa-icon>
                            </button>
                        </div>
                    </div>
                </div>
            </div>   
        </form>
       <br>
       <div class="row">
           <div class="col col-4">
               &nbsp;
           </div>
           <div class="col col-2">
                <button class="btn btn-danger btn-block" (click)="closeModalEspecialidade()">CANCELAR</button>
           </div>
           <div class="col col-2">
                <button class="btn btn-success btn-block" (click)="salvarEspecialidade()">SALVAR</button>
           </div>
           <div class="col col-4">
                &nbsp;
            </div>
       </div>
    </div>
</div>
<!-- MODAL CADASTRO CIDADES -->
<div class="modal" id="modalCidades">
    <div style="padding:50px">
        <form [formGroup]="cidades">
            <div class="row" formArrayName="cidade">
                <div class="col col-12" style="font-size: 0.85em" *ngFor="let cidade of cidadesFormGroup.controls; let i = index;">
                    <div [formGroupName]="i" class="row">
                        <div class="form-group col-3">
                            <input type="text" class="form-control" formControlName="cidade" placeholder="Cidade" oninput="this.value = this.value.toUpperCase()">
                        </div>
                        <div class="form-group col-2">
                            <input type="text" class="form-control" formControlName="estado" placeholder="UF" oninput="this.value = this.value.toUpperCase()">
                        </div>
                        <div class="form-group col-2">
                            <input type="text" class="form-control" formControlName="latitude" placeholder="Latitude">
                        </div>
                        <div class="form-group col-2">
                            <input type="text" class="form-control" formControlName="longitude" placeholder="Longitude">
                        </div>
                        <div class="form-group col-2 text-right">
                            <button class="btn btn-danger btn-sm" type="button" (click)="removeCidade(i)" *ngIf="this.cidadesList.length > 1">
                            <fa-icon [icon]="['fas', 'minus-circle']" size="1x"></fa-icon>
                            </button>&nbsp;
                            <button class="btn btn-success btn-sm" type="button" (click)="addCidade()" [disabled]="!cidades.controls.cidade.value[i].cidade || !cidades.controls.cidade.value[i].estado || !cidades.controls.cidade.value[i].latitude || !cidades.controls.cidade.value[i].longitude">
                            <fa-icon [icon]="['fas', 'plus-circle']" size="1x"></fa-icon>
                            </button>
                        </div>
                    </div>
                </div>
            </div>   
        </form>
       <br>
       <div class="row">
           <div class="col col-4">
               &nbsp;
           </div>
           <div class="col col-2">
                <button class="btn btn-danger btn-block" (click)="closeModalCidades()">CANCELAR</button>
           </div>
           <div class="col col-2">
                <button class="btn btn-success btn-block" (click)="salvarCidades()">SALVAR</button>
           </div>
           <div class="col col-4">
                &nbsp;
            </div>
       </div>
    </div>
</div>
<div class="row  align-items-center header">
    <div class="col titulo" style="text-align: left;">
        CREDENCIADOS
    </div>
    <div class="col" style="text-align: right;" *ngIf="db?.usuario?.permissoes['credenciados'] == 'EDITAR'">
        <button class="btn btn-sm btn-outline-light" (click)="openModalCidades()">
            CADASTRAR CIDADES
        </button>
    </div>
    <div class="col" style="text-align: right;" *ngIf="db?.usuario?.permissoes['credenciados'] == 'EDITAR'">
        <button class="btn btn-sm btn-outline-light" (click)="openModalEspecialidade()">
            CADASTRAR ESPECIALIDADE MÉDICA
        </button>
    </div>
    <div class="col" style="text-align: right;" *ngIf="db?.usuario?.permissoes['credenciados'] == 'EDITAR'">
        <button class="btn btn-sm btn-outline-light" (click)="openModalNovo()">
            CADASTRAR CREDENCIADO 
        </button>
    </div>
    <div class="col">
        <button class="btn btn-sm btn-outline-light" (click)="getTodosCredenciados()">
            EXIBIR TODOS 
        </button>
    </div>
</div>
<br>
<div class="row align-items-center">
    <div class="col col-3">
        <label>Credenciado</label>
        <input type="text" class="form-control" [(ngModel)]="nomeBusca">
    </div>
    <div class="col col-3">
        <label>Especialidade</label>
        <select [(ngModel)]="especialidadeBusca" class="form-control">
            <option selected value=""></option>
            <option *ngFor="let esp of todasEspecialidades" [value]="esp.nome">{{esp.nome}}</option>
        </select>
    </div>
    <div class="col col-2">
        <label>Exame / Procedimento</label>
        <input type="text" class="form-control" [(ngModel)]="exameBusca">
    </div>
    <div class="col col-3">
        <label>Cidade</label>
        <select [(ngModel)]="cidadeBusca" class="form-control">
            <option selected value=""></option>
            <option *ngFor="let esp of todasCidades" [value]="esp.cidade">{{esp.cidade}}</option>
        </select>
    </div>
    <div class="col col-1">
        <br>
        <button class="btn btn-primary" (click)="buscarCredenciado(nomeBusca,especialidadeBusca,cidadeBusca,exameBusca)"><fa-icon [icon]="['fas', 'search']"></fa-icon></button>
    </div>
</div>
<br>
<table class="table table-striped" style="text-align: center;">
    <thead class="thead-dark">
        <tr>
            <th>Credenciado</th>
            <th>Endereço</th>
            <th>Cidade</th>
            <th>UF</th>
            <th>Telefone</th>
            <th>
                <select class="form-control" [(ngModel)]="filtroSituacao" (change)="filterCredenciados()">
                    <option value=""></option>
                    <option value="ATIVO">ATIVO</option>
                    <option value="INATIVO">INATIVO</option>
                </select>
            </th>
            <th>Editar</th>
            <th *ngIf="db?.usuario?.permissoes['credenciados'] == 'EDITAR'">Excluir</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let cred of credenciados">
            <td>{{ cred?.fantasiaCredenciado }}</td>
            <td>{{ cred?.rua }}, {{ cred?.numero }} - {{ cred?.bairro }}</td>
            <td>{{ cred?.municipio }}</td>
            <td>{{ cred?.estado }}</td>
            <td>{{ cred?.telefone }}</td>
            <td>{{ cred?.situacao }}</td>
            <td>
                <button class="btn btn-outline-success" (click)="openModalEditar(cred)">
                    <fa-icon [icon]="['fas', 'edit']"></fa-icon>
                </button>
            </td>
            <td>
                <button class="btn btn-outline-danger" (click)="openModalExcluirCredenciado(cred)" *ngIf="db?.usuario?.permissoes['credenciados'] == 'EDITAR'">
                    <fa-icon [icon]="['fas', 'trash']"></fa-icon>
                </button>
            </td>
        </tr>
    </tbody>
</table>
</div>