<div class="modal">
    <div id="imprimir" class="container-fluid">
        <div class="row" style="font-size:18px; text-align:center; font-weight: 600;">
            <div class="col">
                CONTROLE DE VENDAS - {{vendaSelecionada?.vendedor}}
            </div>
        </div>
        <div class="row" style="font-size:12px; text-align:center">
            <div class="col">
                Inicio Cadastro: {{vendaSelecionada?.data | date:'dd/MM/yyyy'}} - Cadastro Finalizado em: {{vendaSelecionada?.dataPDF | date:'dd/MM/yyyy'}}
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col col-12 col-md-12">
                <label>Município:</label> {{vendaSelecionada?.municipio}}
            </div>
        </div>
        <div class="row">
            <div class="col col-12 col-md-12">
                <label>Locais Visitados:</label> {{vendaSelecionada?.locais}}
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col col-9"><strong>Adicional Seguro R$5.000,00 Novo</strong></div>
            <div class="col col-3">
                Total: {{ vendaSelecionada?.qtdeSeguro5 }}
            </div>
        </div>
        <div class="row">
            <div class="col col-9"><strong>Adicional Seguro R$5.000,00 Migração</strong></div>
            <div class="col col-3">
                Total: {{ vendaSelecionada?.qtdeSeguro5Migracao }}
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col col-9"><strong>Adicional Seguro R$10.000,00 Novo</strong></div>
            <div class="col col-3">
                Total: {{ vendaSelecionada?.qtdeSeguro10 }}
            </div>
        </div>
        <div class="row">
            <div class="col col-9"><strong>Adicional Seguro R$10.000,00 Migração</strong></div>
            <div class="col col-3">
                Total: {{ vendaSelecionada?.qtdeSeguro10Migracao }}
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col col-9"><strong>Adicional Seguro R$15.000,00 Novo</strong></div>
            <div class="col col-3">
                Total: {{ vendaSelecionada?.qtdeSeguro15 }}
            </div>
        </div>
        <div class="row">
            <div class="col col-9"><strong>Adicional Seguro R$15.000,00 Migração</strong></div>
            <div class="col col-3">
                Total: {{ vendaSelecionada?.qtdeSeguro15Migracao }}
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col col-9"><strong>Adicional Seguro R$20.000,00 Novo</strong></div>
            <div class="col col-3">
                Total: {{ vendaSelecionada?.qtdeSeguro20 }}
            </div>
        </div>
        <div class="row">
            <div class="col col-9"><strong>Adicional Seguro R$20.000,00 Migração</strong></div>
            <div class="col col-3">
                Total: {{ vendaSelecionada?.qtdeSeguro20Migracao }}
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col col-9"><strong>Adicional Seguro R$25.000,00 Novo</strong></div>
            <div class="col col-3">
                Total: {{ vendaSelecionada?.qtdeSeguro25 }}
            </div>
        </div>
        <div class="row">
            <div class="col col-9"><strong>Adicional Seguro R$25.000,00 Migração</strong></div>
            <div class="col col-3">
                Total: {{ vendaSelecionada?.qtdeSeguro25Migracao }}
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col col-9"><strong>Adicional Seguro R$35.000,00 Novo</strong></div>
            <div class="col col-3">
                Total: {{ vendaSelecionada?.qtdeSeguro35 }}
            </div>
        </div>
        <div class="row">
            <div class="col col-9"><strong>Adicional Seguro R$35.000,00 Migração</strong></div>
            <div class="col col-3">
                Total: {{ vendaSelecionada?.qtdeSeguro35Migracao }}
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col col-9"><strong>Adicional Seguro R$ 50.000,00 Novo</strong></div>
            <div class="col col-3">
                Total: {{ vendaSelecionada?.qtdeSeguro50 }}
            </div>
        </div>
        <div class="row">
            <div class="col col-9"><strong>Adicional Seguro R$ 50.000,00 Migração</strong></div>
            <div class="col col-3">
                Total: {{ vendaSelecionada?.qtdeSeguro50Migracao }}
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col col-9"><strong>Adicional Funeral Extra Novo</strong></div>
            <div class="col col-3">
                Total: {{ vendaSelecionada?.qtdeFuneralExtra }}
            </div>
        </div>
        <div class="row">
            <div class="col col-9"><strong>Adicional Funeral Extra Migração</strong></div>
            <div class="col col-3">
                Total: {{ vendaSelecionada?.qtdeFuneralExtraMigracao }}
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col col-9"><strong>Adicional Funeral Complementar Novo</strong></div>
            <div class="col col-3">
                Total: {{ vendaSelecionada?.qtdefuneralComplementar }}
            </div>
        </div>
        <div class="row">
            <div class="col col-9"><strong>Adicional Funeral Complementar Migração</strong></div>
            <div class="col col-3">
                Total: {{ vendaSelecionada?.qtdefuneralComplementarMigracao }}
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col col-9"><strong>Adicional Convênio de Saúde Novo</strong></div>
            <div class="col col-3">
                Total: {{ vendaSelecionada?.qtdeConvenioSaude }}
            </div>
        </div>
        <div class="row">
            <div class="col col-9"><strong>Adicional Convênio de Saúde Migração</strong></div>
            <div class="col col-3">
                Total: {{ vendaSelecionada?.qtdeConvenioSaudeMigracao }}
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col col-9"><strong>Adicional Telemedicina Novo</strong></div>
            <div class="col col-3">
                Total: {{ vendaSelecionada?.qtdeTelemedicina }}
            </div>
        </div>
        <div class="row">
            <div class="col col-9"><strong>Adicional Telemedicina Migração</strong></div>
            <div class="col col-3">
                Total: {{ vendaSelecionada?.qtdeTelemedicinaMigracao }}
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col col-5"><strong>Quantidade de Filiações</strong></div>
            <div class="col col-2">
                Total: {{ vendaSelecionada?.qtdeFiliacoes }}
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col col-12"><strong>Novas Filiações</strong></div>
            <div class="col col-3">
                {{ vendaSelecionada?.filiacoes }}
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col col-12 col-md-12">
                <label>Faturamento: {{ vendaSelecionada?.faturamento | currency: 'BRL'}}</label>
            </div>
        </div>
        <br>
    </div>
</div>
<div class="modal" id="modalVendaEditar">
    <div class="container-fluid">
        <div class="row">
            <div class="col col-12 col-md-12">
                <label>Município</label>
                <input type="text" class="form-control" [(ngModel)]="vendaSelecionada.municipio" oninput="this.value = this.value.toUpperCase()">
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col col-12 col-md-12">
                <label>Locais Visitados</label>
                <textarea row="3" class="form-control" [(ngModel)]="vendaSelecionada.locais"></textarea>
            </div>
        </div>
        <br>
        <div class="row g-2 align-items-center">
            <div class="col col-12"><strong>Adicional Seguro R$5.000,00</strong></div>
            <div class="col col-12">
                <div class="row" style="text-align: center;">
                    <div class="col col-6 bordered">
                        <div class="row">
                            <div class="col ">
                                NOVO
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                {{vendaSelecionada.qtdeSeguro5}}
                            </div>
                        </div>
                    </div>
                    <div class="col col-6 bordered">
                        <div class="row" style="text-align: center;">
                            <div class="col ">
                                MIGRAÇÃO
                            </div>
                        </div>
                        <div class="row"  style="text-align: center;">
                            <div class="col">
                                {{vendaSelecionada.qtdeSeguro5Migracao}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>       
        </div>
        <br>
        <div class="row g-2 align-items-center">
            <div class="col col-12"><strong>Adicional Seguro R$10.000,00</strong></div>
            <div class="col col-12">
                <div class="row" style="text-align: center;">
                    <div class="col col-6 bordered">
                        <div class="row">
                            <div class="col ">
                                NOVO
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                {{vendaSelecionada.qtdeSeguro10}}
                            </div>
                        </div>
                    </div>
                    <div class="col col-6 bordered">
                        <div class="row" style="text-align: center;">
                            <div class="col ">
                                MIGRAÇÃO
                            </div>
                        </div>
                        <div class="row"  style="text-align: center;">
                            <div class="col">
                                {{vendaSelecionada.qtdeSeguro10Migracao}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>       
        </div>
        <br>
        <div class="row g-2 align-items-center">
            <div class="col col-12"><strong>Adicional Seguro R$15.000,00</strong></div>
            <div class="col col-12">
                <div class="row" style="text-align: center;">
                    <div class="col col-6 bordered">
                        <div class="row">
                            <div class="col ">
                                NOVO
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                {{vendaSelecionada.qtdeSeguro15}}
                            </div>
                        </div>
                    </div>
                    <div class="col col-6 bordered">
                        <div class="row" style="text-align: center;">
                            <div class="col ">
                                MIGRAÇÃO
                            </div>
                        </div>
                        <div class="row"  style="text-align: center;">
                            <div class="col">
                                {{vendaSelecionada.qtdeSeguro15Migracao}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>       
        </div>
        <br>
        <div class="row g-2 align-items-center">
            <div class="col col-12"><strong>Adicional Seguro R$20.000,00</strong></div>
            <div class="col col-12">
                <div class="row" style="text-align: center;">
                    <div class="col col-6 bordered">
                        <div class="row">
                            <div class="col ">
                                NOVO
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                {{vendaSelecionada.qtdeSeguro20}}
                            </div>
                        </div>
                    </div>
                    <div class="col col-6 bordered">
                        <div class="row" style="text-align: center;">
                            <div class="col ">
                                MIGRAÇÃO
                            </div>
                        </div>
                        <div class="row"  style="text-align: center;">
                            <div class="col">
                                {{vendaSelecionada.qtdeSeguro20Migracao}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>       
        </div>
        <br>
        <div class="row g-2 align-items-center">
            <div class="col col-12"><strong>Adicional Seguro R$25.000,00</strong></div>
            <div class="col col-12">
                <div class="row" style="text-align: center;">
                    <div class="col col-6 bordered">
                        <div class="row">
                            <div class="col ">
                                NOVO
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                {{vendaSelecionada.qtdeSeguro25}}
                            </div>
                        </div>
                    </div>
                    <div class="col col-6 bordered">
                        <div class="row" style="text-align: center;">
                            <div class="col ">
                                MIGRAÇÃO
                            </div>
                        </div>
                        <div class="row"  style="text-align: center;">
                            <div class="col">
                                {{vendaSelecionada.qtdeSeguro25Migracao}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>       
        </div>
        <br>
        <div class="row g-2 align-items-center">
            <div class="col col-12"><strong>Adicional Seguro R$35.000,00</strong></div>
            <div class="col col-12">
                <div class="row" style="text-align: center;">
                    <div class="col col-6 bordered">
                        <div class="row">
                            <div class="col ">
                                NOVO
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                {{vendaSelecionada.qtdeSeguro35}}
                            </div>
                        </div>
                    </div>
                    <div class="col col-6 bordered">
                        <div class="row" style="text-align: center;">
                            <div class="col ">
                                MIGRAÇÃO
                            </div>
                        </div>
                        <div class="row"  style="text-align: center;">
                            <div class="col">
                                {{vendaSelecionada.qtdeSeguro35Migracao}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>       
        </div>
        <br>
        <div class="row g-2 align-items-center">
            <div class="col col-12"><strong>Adicional Seguro R$50.000,00</strong></div>
            <div class="col col-12">
                <div class="row" style="text-align: center;">
                    <div class="col col-6 bordered">
                        <div class="row">
                            <div class="col ">
                                NOVO
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                {{vendaSelecionada.qtdeSeguro50}}
                            </div>
                        </div>
                    </div>
                    <div class="col col-6 bordered">
                        <div class="row" style="text-align: center;">
                            <div class="col ">
                                MIGRAÇÃO
                            </div>
                        </div>
                        <div class="row"  style="text-align: center;">
                            <div class="col">
                                {{vendaSelecionada.qtdeSeguro50Migracao}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>       
        </div>
        <br>
        <div class="row g-2 align-items-center">
            <div class="col col-12"><strong>Adicional Funeral Extra</strong></div>
            <div class="col col-12">
                <div class="row" style="text-align: center;">
                    <div class="col col-6 bordered">
                        <div class="row">
                            <div class="col ">
                                NOVO
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                {{vendaSelecionada.qtdeFuneralExtra}}
                            </div>
                        </div>
                    </div>
                    <div class="col col-6 bordered">
                        <div class="row" style="text-align: center;">
                            <div class="col ">
                                MIGRAÇÃO
                            </div>
                        </div>
                        <div class="row"  style="text-align: center;">
                            <div class="col">
                                {{vendaSelecionada.qtdeFuneralExtraMigracao}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>       
        </div>
        <br>
        <div class="row g-2 align-items-center">
            <div class="col col-12"><strong>Adicional Funeral Complementar</strong></div>
            <div class="col col-12">
                <div class="row" style="text-align: center;">
                    <div class="col col-6 bordered">
                        <div class="row">
                            <div class="col ">
                                NOVO
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                {{vendaSelecionada.qtdefuneralComplementar}}
                            </div>
                        </div>
                    </div>
                    <div class="col col-6 bordered">
                        <div class="row" style="text-align: center;">
                            <div class="col ">
                                MIGRAÇÃO
                            </div>
                        </div>
                        <div class="row"  style="text-align: center;">
                            <div class="col">
                                {{vendaSelecionada.qtdefuneralComplementarMigracao}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>       
        </div>
        <br>
        <div class="row g-2 align-items-center">
            <div class="col col-12"><strong>Adicional Convênio de Saúde</strong></div>
            <div class="col col-12">
                <div class="row" style="text-align: center;">
                    <div class="col col-6 bordered">
                        <div class="row">
                            <div class="col ">
                                NOVO
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                {{vendaSelecionada.qtdeConvenioSaude}}
                            </div>
                        </div>
                    </div>
                    <div class="col col-6 bordered">
                        <div class="row" style="text-align: center;">
                            <div class="col ">
                                MIGRAÇÃO
                            </div>
                        </div>
                        <div class="row"  style="text-align: center;">
                            <div class="col">
                                {{vendaSelecionada.qtdeConvenioSaudeMigracao}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>       
        </div>
        <br>
        <div class="row g-2 align-items-center">
            <div class="col col-12"><strong>Adicional Telemedicina</strong></div>
            <div class="col col-12">
                <div class="row" style="text-align: center;">
                    <div class="col col-6 bordered">
                        <div class="row">
                            <div class="col ">
                                NOVO
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                {{vendaSelecionada.qtdeTelemedicina}}
                            </div>
                        </div>
                    </div>
                    <div class="col col-6 bordered">
                        <div class="row" style="text-align: center;">
                            <div class="col ">
                                MIGRAÇÃO
                            </div>
                        </div>
                        <div class="row"  style="text-align: center;">
                            <div class="col">
                                {{vendaSelecionada.qtdeTelemedicinaMigracao}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>       
        </div>
        <br>
        <div class="row">
            <div class="row" style="text-align: center;">
                <div class="col ">
                    Novas Filiações
                </div>
            </div>
            <div class="row"  style="text-align: center;">
                <div class="col">
                    {{vendaSelecionada.qtdeFiliacoes}}
                </div>
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col col-12 col-md-12">
                <label>Faturamento</label><br>
                {{ vendaSelecionada.faturamento | currency: 'BRL'}}
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col col-12 col-md-12">
                <label>Filiações Novas</label>
                <textarea row="3" class="form-control" [(ngModel)]="vendaSelecionada.filiacoes"></textarea>
            </div>
        </div>
        <br>
        <div class="row" style="text-align: right;">
            <div class="col" style="text-align: right;">
                <button class="btn btn-danger btn-block" (click)="closeVendaEditar()">CANCELAR</button>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid">
    <div class="row align-items-center header">
        <div class="col titulo">
            CONTROLE DE VENDAS
        </div>
    </div>
    <div class="corpo">
        <div class="row align-items-end">
            <div class="col col-2">
                <label>Data Inicio</label>
                <input type="date" class="form-control" [(ngModel)]="dataInicio">
            </div>
            <div class="col col-2">
                <label>Data Fim</label>
                <input type="date" class="form-control" [(ngModel)]="dataFim">
            </div>
            <div class="col col-4">
                <label>Vendedor</label>
                <select class="form-control" [(ngModel)]="vendedor">
                    <option value=""></option>
                    <option *ngFor="let v of vendedores" [value]="v.nome">{{v.nome}}</option>
                </select>
            </div>
            <div class="col col-1">
                <button class="btn btn-primary" (click)="getVendas()">
                    <fa-icon [icon]="['fas', 'search']"></fa-icon>
                </button>
            </div>
        </div>
        <br>
        <table class="table table-striped">
            <thead class="thead-dark">
                <tr>
                    <th>#</th>
                    <th>Criação</th>
                    <th>Vendedor</th>
                    <th>Município</th>
                    <th>Vidas</th>
                    <th>Faturamento</th>
                    <th>Situação</th>
                    <th>Ver</th>
                    <th>PDF</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let v of vendas; let i = index">
                    <td>{{i + 1}}</td>
                    <td>{{v?.data | date: 'dd/MM/yyyy'}}</td>
                    <td>{{v?.vendedor}}</td>
                    <td>{{v?.municipio}}</td>
                    <td>
                        {{ v?.qtdeSeguro5 + v?.qtdeSeguro10 + v?.qtdeSeguro15 + v?.qtdeSeguro20 + v?.qtdeSeguro25 + v?.qtdeSeguro35 + v?.qtdeSeguro50 + v?.qtdeFuneralExtra + v?.qtdefuneralComplementar + v?.qtdeConvenioSaude + v?.qtdeTelemedicina + v?.qtdeFiliacoes}}
                    </td>
                    <td>{{v?.faturamento | currency: 'BRL'}}</td>
                    <td>{{v?.situacao}}</td>
                    <td>
                        <button class="btn btn-primary" (click)="openVendaEditar(v)">
                            <fa-icon [icon]="['fas', 'edit']"></fa-icon>
                        </button>
                    </td>
                    <td>
                    <button class="btn btn-success" (click)="gerarPDF(v)">
                        <fa-icon [icon]="['fas', 'check']"></fa-icon>
                    </button></td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
