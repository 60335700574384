import { Component, OnInit } from '@angular/core';
import { DataBaseService } from '../provider.service';
import * as XLSX from 'xlsx';
import { AppComponent } from '../app.component';

@Component({
  selector: 'app-relatorios-financeiro',
  templateUrl: './relatorios-financeiro.component.html',
  styleUrls: ['./relatorios-financeiro.component.css']
})

export class RelatoriosFinanceiroComponent implements OnInit {
  totalCaixaRecebido:number = 0;
  totalCaixaPago:number = 0;
  relatorio:any = [];
  dataInicio:any = '';
  dataFim:any = '';
  centroDeCusto:any = '';
  contaOrcamentaria:any = '';
  contaContabil:any = '';
  page = 1;
  contas = [];
  todoCaixa = [];
  totalContasReceber:number = 0;
  contasRecebidas:any = [];
  contasReceber:any = [];
  listaDeCC:any = [];
  fornecedores:any = [];
  fornecedorConta:any = "";
  todasFormasPagamento:any = [];
  formaPagamento:any = "";
  favorecidos:any = [];

  constructor(public db:DataBaseService, public app:AppComponent) { }

  ngOnInit(){
    this.getFornecedor();
    this.getFavorecidos();
    this.db.getConfigs().subscribe((z:any) => {
      this.todasFormasPagamento = z[5].pagamentos;
      this.db.getCentrosCusto().subscribe((data:any) => {
        this.listaDeCC = data;
      })
    });
  }
  
  getFornecedor(){
    this.fornecedores = [];
    this.db.getSubContratos().subscribe((df:any) => {
      let c = 1;
      df.forEach((s) => {
        s.razaoSocial = s.nome;
      if(df.length == c){
        this.db.getAllFornecedores().subscribe((data:any) => {
          this.db.getAllFavorecidos().subscribe((dt:any) => {
              this.fornecedores = [...df,...data,...dt];
              this.fornecedores.sort((a,b) => {if(a.razaoSocial < b.razaoSocial){return -1}});
            });
          });
        }else{
          c++
        }
      });
    });
  }

  getFavorecidos(){
    this.favorecidos = [];
    let controle = 1;
    let ctrl = 1;
    this.db.getAllFavorecidos().subscribe((data:any) => {
      this.favorecidos = data;
      this.db.getSubContratos().subscribe((subs:any) => {
        subs.forEach((item) => {
          item.razaoSocial = item.nome;
          item.imagem = true;
          this.favorecidos.push(item);
          if(subs.length == controle){
            this.db.getEmpresas().subscribe((emp:any) => {
              emp.forEach((e) => {
                e.razaoSocial = e.fantasiaEmpresa;
                e.imagem = true;
                this.favorecidos.push(e);
                if(emp.length == ctrl){
                  this.favorecidos = this.favorecidos.sort((a,b)=>{if(a.razaoSocial < b.razaoSocial)return -1});
                }else{
                  ctrl++
                }
              })
            })
          }else{
            controle++
          }
        })
      })
    })
  }

  somar(filtro:any){
    this.relatorio = [];
    this.totalCaixaPago = 0;
    this.totalCaixaRecebido = 0;
    this.todoCaixa.forEach((conta:any) => {
        if(conta.centrosCusto && conta.centrosCusto.length > 0){
          for(let i = 0; i < conta.centrosCusto.length; i++){
            let novaConta = {
              tipo:"",
              dataPagamento:"",
              formaPagamento:"",
              fornecedor:"",
              observacao:"",
              vencimento:"",
              tipoVencimento:"",
              parcela:"",
              valor:0,
              valorTotal:0,
              tipoEntrada:"",
              centroDeCusto:"",
              contaOrcamentaria:"",
              contaContabil:"",
              duplicado:"",
            };
            novaConta.tipo = conta.tipo;
            novaConta.dataPagamento = conta.dataPagamento;
            novaConta.formaPagamento = conta.formaPagamento;
            novaConta.fornecedor = conta.fornecedor;
            novaConta.observacao = conta.observacao;
            novaConta.vencimento = conta.vencimento;
            novaConta.tipoVencimento = conta.tipoVencimento;
            novaConta.parcela = conta.parcela;
            novaConta.valorTotal = conta.valorTotal;
            novaConta.tipoEntrada = conta.tipoEntrada;
            novaConta.centroDeCusto = conta.centrosCusto[i].centroDeCusto;
            novaConta.contaOrcamentaria = conta.centrosCusto[i].contaOrcamentaria;
            novaConta.contaContabil = conta.centrosCusto[i].contaContabil;
            novaConta.valor = conta.centrosCusto[i].valor;
            novaConta.duplicado = 'SIM';
            if(conta.tipo == "Saida" && (filtro == '' || filtro == "Saida") && (this.contaContabil == '' || conta.centrosCusto[i].contaContabil == this.contaContabil) && (this.contaOrcamentaria == '' || conta.centrosCusto[i].contaOrcamentaria == this.contaOrcamentaria) && (this.centroDeCusto == '' || conta.centrosCusto[i].centroDeCusto == this.centroDeCusto)){
                this.totalCaixaPago += conta.centrosCusto[i].valor;
                this.relatorio.push(novaConta);
              }
            if(conta.tipo == "Entrada" && (filtro == '' || filtro == "Entrada") && (this.contaContabil == '' || conta.centrosCusto[i].contaContabil == this.contaContabil) && (this.contaOrcamentaria == '' || conta.centrosCusto[i].contaOrcamentaria == this.contaOrcamentaria) && (this.centroDeCusto == '' || conta.centrosCusto[i].centroDeCusto == this.centroDeCusto)){
              this.totalCaixaRecebido += conta.valorTotal;
              this.relatorio.push(novaConta);
              }
          }
        }else if(conta.centrosCusto && conta.centrosCusto.length == 1 && conta.centrosCusto[0].valor){
            conta.centroDeCusto = conta.centrosCusto[0].centroDeCusto ;
            conta.contaContabil = conta.centrosCusto[0].contaContabil ;
            conta.contaOrcamentaria = conta.centrosCusto[0].contaOrcamentaria ;
            conta.duplicado = "NÃO";
            if(conta.tipo == "Saida" && (filtro == '' || filtro == "Saida") && (this.contaContabil == '' || conta.centrosCusto[0].contaContabil == this.contaContabil) && (this.contaOrcamentaria == '' || conta.centrosCusto[0].contaOrcamentaria == this.contaOrcamentaria) && (this.centroDeCusto == '' || conta.centrosCusto[0].centroDeCusto == this.centroDeCusto)){
              this.totalCaixaPago += conta.centrosCusto[0].valor;
              this.relatorio.push(conta);
            }
            if(conta.tipo == "Entrada" && (filtro == '' || filtro == "Entrada") && (this.contaContabil == '' || conta.centrosCusto[0].contaContabil == this.contaContabil) && (this.contaOrcamentaria == '' || conta.centrosCusto[0].contaOrcamentaria == this.contaOrcamentaria) && (this.centroDeCusto == '' || conta.centrosCusto[0].centroDeCusto == this.centroDeCusto)){
              this.totalCaixaRecebido += conta.valorTotal;
              this.relatorio.push(conta);
            }
        }else{
           conta.duplicado = "NÃO";
           if(conta.tipo == "Saida" && (filtro == '' || filtro == "Saida")){
            this.totalCaixaPago += conta.valorTotal;
            this.relatorio.push(conta);
          }
          if(conta.tipo == "Entrada" && (filtro == '' || filtro == "Entrada")){
            this.totalCaixaRecebido += conta.valorTotal;
            this.relatorio.push(conta);
          }
        }
    })
  }

getContas(dtInicio:any,dtFim:any,centroCusto:any,contaOrcamentaria:any,contaContabil:any,formaPagamento:any,fornecedorConta:any){
  this.todoCaixa = [];
  let fornecedor = fornecedorConta ? fornecedorConta.razaoSocial : '';
  this.db.getFinanceiroCC(dtInicio,dtFim,centroCusto,contaOrcamentaria,contaContabil,formaPagamento,fornecedor).subscribe((data:any) => {
    this.todoCaixa = data;
    this.somar('');
  })
}

print(){
  var tab = document.getElementById('divToPrintRelFinanceiro');
  var style = "<style>";
      style = style + "table {width: 100%;font: 17px Calibri;}";
      style = style + "table, th, td {border: solid 1px #DDD; border-collapse: collapse;";
      style = style + "padding: 2px 3px;text-align: center;}";
      style = style + "</style>";
  var win = window.open('', '', 'height=auto,width=100%');
  win.document.write(style); 
  win.document.write(tab.outerHTML);
  win.document.close();
  win.print();
}

printXsl(){
  var ws = XLSX.utils.table_to_sheet(document.getElementById('divToPrintRelFinanceiro'),{raw:true});
  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  XLSX.writeFile(wb, 'vivamax.xlsx',{cellStyles:true});
}

limparCCs(centroDeCusto){
  this.contaOrcamentaria = "";
  this.contaContabil = "";
  this.centroDeCusto = centroDeCusto;
}

}
