import { Component, OnInit } from '@angular/core';
import { AppComponent } from '../app.component';
import { DataBaseService } from '../provider.service';

@Component({
  selector: 'app-logs',
  templateUrl: './logs.component.html',
  styleUrls: ['./logs.component.css']
})
export class LogsComponent implements OnInit {
logs:any = [];
dataInicio:any = "";
dataFim:any = "";
menu:any = "";
usuario:any = "";
dataHoje:any = "";

  constructor(private db:DataBaseService, public app:AppComponent) { 
    this.dataHoje = new Date(Date.now() - 10800000).toISOString().split('T')[0];
    this.dataInicio = this.dataHoje;
    this.dataFim = this.dataHoje;
  }

  ngOnInit(){
    this.buscarData(this.dataHoje,this.dataHoje)
  }

  buscarData(dtInicio,dtFim){
    this.db.getLogsData(dtInicio,dtFim).subscribe((data:any) => {
      this.logs = data
    })
  }

  buscarMenu(dtInicio,dtFim,menu){
    this.db.getLogsDataMenu(dtInicio,dtFim,menu).subscribe((data:any) => {
      this.logs = data
    })
  }

  buscarUsuario(dtInicio,dtFim,user){
    this.db.getLogsDataUsuario(dtInicio,dtFim,user).subscribe((data:any) => {
      this.logs = data
    })
  }

  verificaData(data){
    let ano = data.split('-')[0];
    if(Number(ano) > 9999){
      this.app.openAlert("ATENÇÃO! O ANO NÃO PODE TER MAIS QUE 4 DIGITOS!")
    }
  }
}
