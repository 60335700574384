<div class="container-fluid">
    <div class="row align-items-center header">
        <div class="col titulo">
            RELATÓRIOS FINANCEIROS
        </div>
        <div class="col col-2" style="text-align: right;">
            <button class="btn btn-sm btn-outline-light" (click)="printXsl()" [disabled]="relatorio.length <= 0">
                <fa-icon [icon]="['fas', 'file-excel']"></fa-icon>
            </button>
            &nbsp;
            <button class="btn btn-sm btn-outline-light" (click)="print()" [disabled]="relatorio.length <= 0">
                <fa-icon [icon]="['fas', 'print']"></fa-icon>
            </button>
        </div>
    </div>
    <div class="row">
        <div class="col col-3">
            <label>Data Inicio</label>
            <input type="date" class="form-control" [(ngModel)]="dataInicio">
        </div>
        <div class="col col-3">
            <label>Data Fim</label>
            <input type="date" class="form-control" [(ngModel)]="dataFim">
        </div>
        <div class="col-6">
            <label>Centro de Custo</label><br>
            <select [(ngModel)]="centroDeCusto" class="form-control" (change)="limparCCs(centroDeCusto)">
                <option selected [value]=""></option>
                <option value="Administrativo">Administrativo</option>
                <option value="Comercial">Comercial</option>
                <option value="Publicidade">Publicidade</option>
            </select>
        </div> 
    </div>
    <div class="row align-items-center"> 
        <div class="col-6">
            <label>Conta Orçamentária</label><br>
            <select [(ngModel)]="contaOrcamentaria" class="form-control">
                <option selected [value]=""></option>
                <option *ngFor="let cC of (listaDeCC | filterCC: centroDeCusto)" [value]="cC.contaOrcamentaria">{{cC.contaOrcamentaria}}</option>
            </select>
        </div>
        <div class="col-6">
            <label>Conta Contabil</label><br>
            <select [(ngModel)]="contaContabil" class="form-control" *ngFor="let cO of (listaDeCC | filterCO: contaOrcamentaria)">
                <option selected [value]=""></option>
                <option *ngFor="let c of cO.contasContabeis" [value]="c.conta">{{c.conta}}</option>
            </select>
        </div>
    </div>
    <div class="row align-items-center">
        <div class="col col-5">
            <label>Fornecedor / Favorecido</label>
            <ng-select class="form-control" [items]="fornecedores" bindLabel="razaoSocial" appendTo="body" [(ngModel)]="fornecedorConta"></ng-select>
        </div>
        <div class="col col-3">
            <label>Forma de Pagamento</label>
            <select [(ngModel)]="formaPagamento" class="form-control">
                <option [value]="" selected></option>
                <option *ngFor="let forma of todasFormasPagamento" [value]="forma.forma">{{forma.forma}}</option>
            </select>
        </div>
        <div class="col col-4" style="bottom:-15px">
            <button class="btn btn-primary" (click)="somar('')" [disabled]="relatorio.length < 1">Todos</button>
            &nbsp;&nbsp;
            <button class="btn btn-primary" (click)="somar('Saida')" [disabled]="relatorio.length < 1">Saidas</button>
            &nbsp;&nbsp;
            <button class="btn btn-primary" (click)="somar('Entrada')" [disabled]="relatorio.length < 1">Entradas</button>
            &nbsp;&nbsp;
            <button class="btn btn-primary" (click)="getContas(dataInicio,dataFim,centroDeCusto,contaOrcamentaria,contaContabil,formaPagamento,fornecedorConta)" [disabled]="!dataInicio || !dataFim"><fa-icon [icon]="['fas', 'search']"></fa-icon></button>
        </div>
    </div>
    <div id="divToPrintRelFinanceiro">
        <div class="table-responsive" [hidden]="relatorio.length < 1">
            <table class="table table-striped" style="text-align: center; font-size: 0.9em;">
                <thead class="thead-dark">
                    <tr>
                        <th>Forma</th>
                        <th>Data Pagamento</th>
                        <th>Pagamento</th>
                        <th>Fornecedor / Favorecido</th>
                        <th>Observação</th>
                        <th>Vencimento</th>
                        <th>Tipo</th>
                        <th>Valor Original</th>
                        <th>Valor Pago</th>
                        <th>Parcela</th>
                        <th>Tipo de Entrada</th>
                        <th>Centro Custo</th> 
                        <th>Conta Orçamentária</th> 
                        <th>Conta Contábil</th>
                        <th>Rateio</th> 
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let conta of relatorio" [style.fontWeight]="conta.duplicado == 'SIM' ? '700' : '500'" [style.color]="conta.duplicado == 'SIM' ? '#c7444a' : ''">
                        <td [ngStyle]="{'color': conta?.tipo == 'Entrada' ? 'green' : 'red'}"><strong>{{ conta?.tipo }}</strong></td>
                        <td>{{ conta?.dataPagamento | date:'dd/MM/yyyy' }}</td>
                        <td>{{ conta?.formaPagamento }}</td>
                        <td>{{ conta?.fornecedor }}{{ conta?.favorecido }}</td>
                        <td>{{ conta?.observacao }}</td>
                        <td>{{ conta?.vencimento | date:'dd/MM/yyyy' }}</td>
                        <td>{{ conta?.tipoVencimento }}</td>
                        <td>{{ conta?.valor | currency:'BRL' }}</td>
                        <td><span *ngIf="conta?.tipo == 'Saida'">-</span>{{ conta?.valorTotal | currency:'BRL' }}</td>
                        <td>{{ conta?.parcela }}</td>
                        <td>{{ conta?.tipoEntrada }}</td>
                        <td>{{ conta?.centroDeCusto}}</td>
                        <td>{{ conta?.contaOrcamentaria}}</td>
                        <td>{{ conta?.contaContabil}}</td>
                        <td>{{ conta?.duplicado }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <br>
        <div class="row" style="font-weight: bold; text-align: right;">
            <div class="col col-6">
                &nbsp;
            </div>
            <div class="col">
                Entradas<br> {{totalCaixaRecebido | currency:'BRL'}}
            </div>
            <div class="col">
                Saidas<br> {{totalCaixaPago | currency:'BRL'}}
            </div>
            <div class="col">
                Saldo<br> {{totalCaixaRecebido - totalCaixaPago | currency:'BRL'}}
            </div>
        </div>
        <div style="height:3em"></div>
    </div>
</div>