import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filterCC',
    pure: false
})
export class filterCCPipe implements PipeTransform {
    transform(items: Array<any>, cc: string): Array<any> {
        if(items){
            return items.filter(item => item.centrosCusto[cc]);
        }
    }
}