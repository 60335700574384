<div class="container-fluid">
    <div style="width: 800px; height: 400px;display: none;">
        <ng2-pdfjs-viewer 
            #pdfViewerSubContrato
            [externalWindow]="true"
            [downloadFileName]="'vivamax.pdf'"
            [openFile]="false"
            [viewBookmark]="false"
            [download]="true"></ng2-pdfjs-viewer>
    </div>
<!-- MODAL NOVO SUB CONTRATO -->
<div class="modal" id="modalNova">
    <div class="row  align-items-center header">
        <div class="col titulo">
            CADASTRAR SUB-CONTRATO
        </div>
        <div class="col botoes">
            <button class="btn btn-outline-light" (click)="fecharModalNovo()">
                <fa-icon [icon]="['fas', 'window-close']"></fa-icon>&nbsp;CANCELAR
            </button>
            &nbsp;&nbsp;
            <button class="btn btn-outline-light" (click)="cadastrarSubContrato()" [disabled]="!nome || !telefone || !rua || !numero || !bairro || !cidade || !estado || listaDeContatos?.length < 1">
                <fa-icon [icon]="['fas', 'save']"></fa-icon>&nbsp;SALVAR
            </button>
        </div>
    </div>
    <br>
    <div class="corpo">
            <div class="row">
                <div class="col col-8">
                    <label>Nome:</label>
                    <input type="text" class="form-control" [(ngModel)]="nome" oninput="this.value = this.value.toUpperCase()" [ngStyle]="{'border-color': !nome ? '#dd443e' : '#3cb42c'}">
                </div>
                <div class="col col-4"> 
                    <label>Telefone:</label>
                    <input type="text" class="form-control" [(ngModel)]="telefone" [textMask]="{mask: MASKS.telefone.textMaskFunction}" [ngStyle]="{'border-color': !telefone ? '#dd443e' : '#3cb42c'}">
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col col-6">
                    <label>CNPJ:</label>
                    <input type="text" class="form-control" [(ngModel)]="cnpj" cnpj [textMask]="{mask: MASKS.cnpj.textMask}">
                </div>
                <div class="col col-4">
                    <label>Inscrição Estadual:</label>
                    <input type="text" class="form-control" [(ngModel)]="ie">
                </div>
                <div class="col col-4">
                    <label>Região:</label>
                    <select class="form-control" [(ngModel)]="regiao">
                        <option value="OESTE">OESTE</option>
                        <option value="SUDOESTE">SUDOESTE</option>
                        <option value="NORTE">NORTE</option>
                        <option value="SUL">SUL</option>
                        <option value="LESTE">LESTE</option>
                    </select>
                </div>
            </div>
            <br>
            <div class="row">               
                <div class="col col-3">
                    <label>CEP:</label>
                    <input type="text" cep [textMask]="{mask: MASKS.cep.textMask}" class="form-control" [(ngModel)]="cep" (keyup)="buscarCep(cep)">
                </div>
                <div class="col col-6">
                    <label>Rua:</label>
                    <input type="text" class="form-control" [(ngModel)]="rua" oninput="this.value = this.value.toUpperCase()" [ngStyle]="{'border-color': !rua ? '#dd443e' : '#3cb42c'}">
                </div>
                <div class="col col-3">
                    <label>Número:</label>
                    <input type="text" class="form-control" [(ngModel)]="numero" [ngStyle]="{'border-color': !numero ? '#dd443e' : '#3cb42c'}">
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col col-2">
                    <label>Complemento:</label>
                    <input type="text" class="form-control" [(ngModel)]="complemento" oninput="this.value = this.value.toUpperCase()">
                </div>
                <div class="col col-4">
                    <label>Bairro:</label>
                    <input type="text" class="form-control" [(ngModel)]="bairro" oninput="this.value = this.value.toUpperCase()" [ngStyle]="{'border-color': !bairro ? '#dd443e' : '#3cb42c'}">
                </div>
                <div class="col col-4">
                    <label>Cidade:</label>
                    <input type="text" class="form-control" [(ngModel)]="cidade" oninput="this.value = this.value.toUpperCase()" [ngStyle]="{'border-color': !cidade ? '#dd443e' : '#3cb42c'}">
                </div>
                <div class="col col-2">
                    <label>Estado:</label>
                    <input type="text" class="form-control" [(ngModel)]="estado" oninput="this.value = this.value.toUpperCase()" [ngStyle]="{'border-color': !estado ? '#dd443e' : '#3cb42c'}">
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col col-3">
                    <label>Data de Corte:</label>
                    <input type="date" class="form-control" [(ngModel)]="dataVencimento" (change)="calcularFaturamento(dataVencimento)" [ngStyle]="{'border-color': !dataVencimento ? '#dd443e' : '#3cb42c'}">
                </div>
                <div class="col col-3">
                    <label>Data de Faturamento:</label>
                    <input type="date" class="form-control" [(ngModel)]="dataFaturamento" [ngStyle]="{'border-color': !dataFaturamento ? '#dd443e' : '#3cb42c'}">
                </div>
                <div class="col col-6">
                    <label>E-mail:</label>
                    <input type="text" class="form-control" [(ngModel)]="email" oninput="this.value = this.value.toUpperCase()" [ngStyle]="{'border-color': !email ? '#dd443e' : '#3cb42c'}">
                </div>
            </div>
            <br>
            <div class="row align-items-center">
                <div class="col col-3">
                    <label>Desconto Faturamento:</label>
                    <div class="input-group mb-3">
                        <input type="numer" class="form-control" [(ngModel)]="descontoEmpresa">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon1">%</span>
                        </div>
                    </div>
                </div>
                <div class="col col-3">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="checkConsignetSub" [(ngModel)]="checkConsignet">
                        <label class="form-check-label" for="checkConsignetSub" style="margin-left:20px">Gerar Tarefa Consignet
                        </label>
                    </div>
                </div>
            </div>
           <br>
           <label>CONTATOS:</label>
           <form [formGroup]="contatos">
            <div class="row" formArrayName="contato" [ngStyle]="{'border-color': listaDeContatos.length < 1 ? '#dd443e' : '#3cb42c'}">
              <div class="col col-12" *ngFor="let contact of contatosFormGroup.controls; let i = index;">
                <div class="row align-items-center" [formGroupName]="i" [ngClass]="{'is-invalid':!contatos.controls.contato.valid}">
                  <div class="form-group col-4">
                    <label>Nome:</label>
                    <input type="text" class="form-control" formControlName="nome" oninput="this.value = this.value.toUpperCase()">
                  </div>
                  <div class="form-group col-2">
                    <label>Data Nascimento:</label>
                    <input type="text" class="form-control" formControlName="nascimento" maxlength="10" onkeyup="var v = this.value;if(v.match(/^\d{2}$/) !== null){this.value = v + '/';}else if(v.match(/^\d{2}\/\d{2}$/) !== null){this.value = v + '/';}">
                  </div>  
                  <div class="form-group col-3">
                    <label>Cargo:</label>
                    <select class="form-control" formControlName="cargo">
                        <option value=""></option>
                        <option value="PRESIDENTE">PRESIDENTE</option>
                        <option value="SECRETÁRIO">SECRETÁRIO</option>
                        <option value="TESOUREIRO">TESOUREIRO</option>
                        <option value="VICE-PRESIDENTE">VICE-PRESIDENTE</option>
                    </select>
                  </div>
                  <div class="form-group col-2">
                      <br>
                    <button class="btn btn-outline-danger" type="button" (click)="removeContato(i)" *ngIf="listaDeContatos?.length > 1">
                      <fa-icon [icon]="['fas', 'minus-circle']" size="1x"></fa-icon>
                    </button>&nbsp;
                    <button class="btn btn-outline-success" type="button" (click)="addContato()">
                      <fa-icon [icon]="['fas', 'plus-circle']" size="1x"></fa-icon>
                    </button>
                  </div>
                </div>
              </div>
            </div>   
         </form>
         <br>
         <label>RESPONSÁVEIS PELO SUB CONTRATO</label>
         <br>
         <form [formGroup]="responsaveis">
          <div class="row" formArrayName="responsaveis">
            <div class="col col-12" *ngFor="let resp of responsaveisFormGroup.controls; let i = index;">
              <div class="row align-items-center" [formGroupName]="i" [ngClass]="{'is-invalid':!responsaveis.controls.responsaveis.valid}">
                <div class="form-group col-6">
                  <label>Nome:</label>
                  <input type="text" class="form-control" formControlName="nome" oninput="this.value = this.value.toUpperCase()">
                </div>
                <div class="form-group col-3">
                    <br>
                  <button class="btn btn-outline-danger" type="button" (click)="removeResponsavel(i)" *ngIf="listaResponsaveis?.length > 1">
                    <fa-icon [icon]="['fas', 'minus-circle']" size="1x"></fa-icon>
                  </button>&nbsp;
                  <button class="btn btn-outline-success" type="button" (click)="addResponsavel()">
                    <fa-icon [icon]="['fas', 'plus-circle']" size="1x"></fa-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>   
        </form>
    </div>
    <div class="row">
        <div class="col" style="text-align: center; color:#5e5e5e">
            <h5>Clique ou Arraste para Enviar o Contrato</h5>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <div class="uploadfilecontainer" (click)="fileInputContrato.click()" appDragDrop (onFileDropped)="onChangeContrato($event)">
                <input hidden type="file" #fileInputContrato (change)="onChangeClickContrato($event)">
            </div>
            <div class="files-list" *ngFor="let file of filesContrato; let i= index">
                <p>{{ file }}</p>
            </div> 
        </div>
    </div>
    <div style="height: 3em;"></div>
</div>
<!-- FIM MODAL NOVO SUB CONTRATO -->
<!-- MODAL EDITAR SUB CONTRATO -->
<div class="modal" id="modalEditar">
    <div id="myModal" class="modalLightbox">
        <span (click)="closeModal()" class="close">&times;</span>
        <div class="prev" (click)="prevImg()">&lt;</div>
        <img [src]="imagemSelecionada" class="imagemLightbox">
        <div class="next" (click)="nextImg()">&gt;</div>
    </div>
    <div class="row  align-items-center header">
        <div class="col titulo">
            EDITAR SUB-CONTRATO
        </div>
        <div class="col botoes">
            <button class="btn btn-outline-light" (click)="fecharModalEditar()">
                <fa-icon [icon]="['fas', 'window-close']"></fa-icon>&nbsp;CANCELAR
            </button>
            &nbsp;&nbsp;
            <button class="btn btn-outline-light" (click)="editarSubContratos()" [disabled]="!nomeEditar || !telefoneEditar || !ruaEditar || !numeroEditar || !bairroEditar || !cidadeEditar || !estadoEditar || listaDeContatosEditar?.length < 1">
                <fa-icon [icon]="['fas', 'save']"></fa-icon>&nbsp;SALVAR
            </button>
        </div>
    </div>
    <br>
    <div class="corpo">
            <div class="row">
                <div class="col col-6">
                    <label>Nome:</label>
                    <input type="text" class="form-control" [(ngModel)]="nomeEditar" oninput="this.value = this.value.toUpperCase()">
                </div>
                <div class="col col-3">
                    <label>Telefone:</label>
                    <input type="text" class="form-control" [(ngModel)]="telefoneEditar" [textMask]="{mask: MASKS.telefone.textMaskFunction}">
                </div>
                <div class="col col-3">
                    <label>Situação:</label>
                    <select class="form-control" [(ngModel)]="situacaoEditar" (change)="mudarSituacao(situacaoEditar)">
                        <option value="ATIVO">ATIVO</option>
                        <option value="INADIMPLENTE I">INADIMPLENTE I</option>
                        <option value="INADIMPLENTE II">INADIMPLENTE II</option>
                        <option value="CANCELADO">CANCELADO</option>
                    </select>
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col col-4">
                    <label>CNPJ:</label>
                    <input type="text" class="form-control" [(ngModel)]="cnpjEditar" cnpj [textMask]="{mask: MASKS.cnpj.textMask}">
                </div>
                <div class="col col-4">
                    <label>Inscrição Estadual:</label>
                    <input type="text" class="form-control" [(ngModel)]="ieEditar">
                </div>
                <div class="col col-4">
                    <label>Região:</label>
                    <select class="form-control" [(ngModel)]="regiaoEditar">
                        <option value="OESTE">OESTE</option>
                        <option value="SUDOESTE">SUDOESTE</option>
                        <option value="NORTE">NORTE</option>
                        <option value="SUL">SUL</option>
                        <option value="LESTE">LESTE</option>
                    </select>
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col col-3">
                    <label>CEP:</label>
                    <input type="text" cep [textMask]="{mask: MASKS.cep.textMask}" class="form-control" [(ngModel)]="cepEditar" (keyup)="buscarCepEditar(cepEditar)">
                </div>
                <div class="col col-6">
                    <label>Rua:</label>
                    <input type="text" class="form-control" [(ngModel)]="ruaEditar" oninput="this.value = this.value.toUpperCase()">
                </div>
                <div class="col col-3">
                    <label>Número:</label>
                    <input type="text" class="form-control" [(ngModel)]="numeroEditar">
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col col-2">
                    <label>Complemento:</label>
                    <input type="text" class="form-control" [(ngModel)]="complementoEditar" oninput="this.value = this.value.toUpperCase()">
                </div>
                <div class="col col-4">
                    <label>Bairro:</label>
                    <input type="text" class="form-control" [(ngModel)]="bairroEditar" oninput="this.value = this.value.toUpperCase()">
                </div>
                <div class="col col-4">
                    <label>Cidade:</label>
                    <input type="text" class="form-control" [(ngModel)]="cidadeEditar" oninput="this.value = this.value.toUpperCase()">
                </div>
                <div class="col col-2">
                    <label>Estado:</label>
                    <input type="text" class="form-control" [(ngModel)]="estadoEditar" oninput="this.value = this.value.toUpperCase()">
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col col-3">
                    <label>Data de Corte:</label>
                    <input type="date" class="form-control" [(ngModel)]="dataVencimentoEditar" (change)="calcularFaturamentoEditar(dataVencimentoEditar)" [ngStyle]="{'border-color': !dataVencimentoEditar ? '#dd443e' : '#3cb42c'}">
                </div>
                <div class="col col-3">
                    <label>Data de Faturamento:</label>
                    <input type="date" class="form-control" [(ngModel)]="dataFaturamentoEditar" [ngStyle]="{'border-color': !dataFaturamentoEditar ? '#dd443e' : '#3cb42c'}">
                </div>
                <div class="col col-6">
                    <label>E-mail:</label>
                    <input type="text" class="form-control" [(ngModel)]="emailEditar" oninput="this.value = this.value.toUpperCase()">
                </div>
            </div>
            <br>
            <div class="row align-items-center">
                <div class="col col-3">
                    <label>Desconto Faturamento:</label>
                    <div class="input-group mb-3">
                        <input type="numer" class="form-control" [(ngModel)]="descontoEmpresaEditar">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon1">%</span>
                        </div>
                    </div>
                </div>
                <div class="col col-3">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="checkConsignetSubEditar" [(ngModel)]="checkConsignetEditar">
                        <label class="form-check-label" for="checkConsignetSubEditar" style="margin-left:20px">Gerar Tarefa Consignet e eConsig
                        </label>
                    </div>
                </div>
            </div>
           <br>
           <label>CONTATOS</label>
           <form [formGroup]="contatosEditar">
            <div class="row" formArrayName="contatoEditar">
              <div class="col col-12" *ngFor="let contact of contatosEditarFormGroup.controls; let i = index;">
                <div class="row align-items-center" [formGroupName]="i" [ngClass]="{'is-invalid':!contatosEditar.controls.contatoEditar.valid}"> 
                  <div class="form-group col-4">
                    <label>Nome:</label>
                    <input type="text" class="form-control" formControlName="nome" oninput="this.value = this.value.toUpperCase()">
                  </div>
                  <div class="form-group col-2">
                    <label>Data Nascimento:</label>
                    <input type="text" class="form-control" formControlName="nascimento" maxlength="10" onkeyup="var v = this.value;if(v.match(/^\d{2}$/) !== null){this.value = v + '/';}else if(v.match(/^\d{2}\/\d{2}$/) !== null){this.value = v + '/';}">
                  </div>  
                  <div class="form-group col-3">
                    <label>Cargo:</label>
                    <select class="form-control" formControlName="cargo">
                        <option value=""></option>
                        <option value="PRESIDENTE">PRESIDENTE</option>
                        <option value="SECRETÁRIO">SECRETÁRIO</option>
                        <option value="TESOUREIRO">TESOUREIRO</option>
                        <option value="VICE-PRESIDENTE">VICE-PRESIDENTE</option>
                    </select>
                  </div>
                  <div class="form-group col-2">
                      <br>
                    <button class="btn btn-outline-danger" type="button" (click)="removeContatoEditar(i)" *ngIf="listaDeContatosEditar?.length > 1">
                      <fa-icon [icon]="['fas', 'minus-circle']" size="1x"></fa-icon>
                    </button>&nbsp;
                    <button class="btn btn-outline-success" type="button" (click)="addContatoEditar('')">
                      <fa-icon [icon]="['fas', 'plus-circle']" size="1x"></fa-icon>
                    </button>
                  </div>
                </div>
              </div>
            </div>   
        </form>
        <br>
        <label>RESPONSÁVEIS PELO SUB CONTRATO</label>
        <br>
        <form [formGroup]="responsaveisEditar">
         <div class="row" formArrayName="responsaveisEditar">
           <div class="col col-12" *ngFor="let respEditar of responsaveisEditarFormGroup.controls; let i = index;">
             <div class="row align-items-center" [formGroupName]="i" [ngClass]="{'is-invalid':!responsaveisEditar.controls.responsaveisEditar.valid}">
               <div class="form-group col-6">
                 <label>Nome:</label>
                 <input type="text" class="form-control" formControlName="nome" oninput="this.value = this.value.toUpperCase()">
               </div>
               <div class="form-group col-3">
                   <br>
                 <button class="btn btn-outline-danger" type="button" (click)="removeResponsavelEditar(i)" *ngIf="listaResponsaveisEditar?.length > 1">
                   <fa-icon [icon]="['fas', 'minus-circle']" size="1x"></fa-icon>
                 </button>&nbsp;
                 <button class="btn btn-outline-success" type="button" (click)="addResponsavelEditar('')">
                   <fa-icon [icon]="['fas', 'plus-circle']" size="1x"></fa-icon>
                 </button>
               </div>
             </div>
           </div>
         </div>   
       </form>
        <br>
        <div class="row">
            <div class="col col-10">
                <label>Nova Observação</label>
                <textarea class="form-control" rows="2" [(ngModel)]="observacao"></textarea>
            </div>
            <div class="col col-2">
                <br><br>
                <button class="btn btn-success" (click)="cadastrarObservacao()" [disabled]="!observacao">CADASTRAR</button>
            </div>
        </div>
        <br>
        <h5>OBSERVAÇÕES:</h5>
        <table class="table table-striped">
            <thead class="thead-dark">
                <tr>
                    <th>#</th>
                    <th>Data</th>
                    <th>Hora</th>
                    <th>Usuário</th>
                    <th>Observação</th>
                </tr>
            </thead>
            <tbody *ngFor="let obs of observacoes; let i = index">
                <tr>
                    <td>{{i+1}}</td>
                    <td>{{ obs?.data | date: 'dd/MM/yyyy' }}</td>
                    <td>{{ obs?.hora }}</td>
                    <td>{{ obs?.usuario }}</td>
                    <td>{{ obs?.observacao }}</td>
                </tr>
             </tbody>
        </table>   
    </div>
    <div class="row">
        <div class="col" style="text-align: center; color:#5e5e5e">
            <h5>Clique ou Arraste para Enviar o Contrato</h5>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <div class="uploadfilecontainer" (click)="fileInputContratoEditar.click()" appDragDrop (onFileDropped)="onChangeContratoEditar($event)">
                <input hidden type="file" #fileInputContratoEditar (change)="onChangeClickContratoEditar($event)">
            </div>
            <div class="files-list" *ngFor="let file of filesContratoEditar; let i= index">
                <p>{{ file }}</p>
            </div> 
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col col-1">
            <br>
        </div>
        <div class="col">
            <button class="btn btn-primary btn-block" (click)="uploadFileContrato()" [disabled]="filesContratoEditar.length < 1">
                <fa-icon [icon]="['fas', 'upload']"></fa-icon>&nbsp;&nbsp;ENVIAR
            </button>
        </div>
        <div class="col col-1">
            <br>
        </div>
    </div>
    <br><br>
    <br><br><br>
    <div class="row justify-content-md-center">
        <div class="col col-3" *ngFor="let pdf of pdfs;"
            style="margin: 10px; background-color:#ce2626; color:white; height: 250px; width:100%; text-align: center; border-radius: 10px;">
            <div class="delButton" name="delButton"
                *ngIf="db?.usuario?.permissoes['documentos'] == 'EDITAR'">
                <button class="btn btn-dark btn-sm" (click)="delDoc(pdf.url)"><strong>X</strong></button>
            </div>
            <div (click)="openModalPdfViewer(pdf.url)"
                style="position: relative;left:50%;top:50%;transform: translate(-50%,-50%);cursor:pointer">
                <fa-icon [icon]="['fas', 'file-pdf']" style="font-size: 5em;"></fa-icon>
                <br>DOCUMENTO PDF
            </div>
        </div>
        <div class="col col-3 img-container" *ngFor="let img of images; let i = index"
            style="margin: 10px; text-align: center;">
            <div class="delButton" name="delButton"
                *ngIf="db?.usuario?.permissoes['documentos'] == 'EDITAR'">
                <button class="btn btn-danger btn-sm" (click)="delDoc(img.url)"><strong>X</strong></button>
            </div>
            <img [src]="img.url" (click)="openModalLightBox(img.url, i)" style="border-radius: 10px; height: 250px; width:100%; cursor: pointer;">
        </div>
    </div>
    <br><br><br>
    <br><br>
</div>
<!-- FIM MODAL EDITAR SUB CONTRATO -->
<div class="row  align-items-center header">
    <div class="col titulo" style="text-align: left;">
        SUB-CONTRATOS
    </div>
    <div class="col" style="text-align: right;">
        <button class="btn btn-outline-light" (click)="openModalNovo()" *ngIf="db?.usuario?.permissoes['contratos']  == 'EDITAR'">
            <fa-icon [icon]="['fas', 'plus']"></fa-icon>&nbsp;NOVO
        </button>
    </div>
</div>
<table class="table table-striped" style="text-align: center;">
    <thead class="thead-dark">
        <tr>
            <th>Sub Contrato</th>
            <th>Telefone</th>
            <th>Cidade</th>
            <th>Estado</th>
            <th *ngIf="db?.usuario?.permissoes['contratos']  == 'EDITAR'"></th>
            <th *ngIf="db?.usuario?.permissoes['contratos']  == 'EDITAR'"></th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let sub of subContratos">
            <td>{{ sub?.nome }}</td>
            <td>{{ sub?.telefone }}</td>
            <td>{{ sub?.cidade }}</td>
            <td>{{ sub?.estado }}</td>
            <td *ngIf="db?.usuario?.permissoes['contratos']  == 'EDITAR'">
                <button class="btn btn-outline-success" (click)="openModalEditar(sub)">
                    <fa-icon [icon]="['fas', 'edit']"></fa-icon>
                </button>
            </td>
            <td *ngIf="db?.usuario?.permissoes['contratos']  == 'EDITAR'">
                <button class="btn btn-outline-danger" (click)="excluirSubContratos(sub?._id)">
                    <fa-icon [icon]="['fas', 'trash']"></fa-icon>
                </button>
            </td>
        </tr>
    </tbody>
</table>
</div>