import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filterShow',
    pure: false
})

export class filterShowPipe implements PipeTransform {
    transform(items: Array<any>): Array<any> {
        if(items){
            return items.filter(item => item.programacao);
        }
    }
}