import { Component, OnInit } from '@angular/core';
import { DataBaseService } from '../provider.service';
import * as moment from 'moment';
import * as XLSX from 'xlsx';
import { AppComponent } from '../app.component';

@Component({
  selector: 'app-programacao',
  templateUrl: './programacao.component.html',
  styleUrls: ['./programacao.component.css']
})

export class ProgramacaoComponent implements OnInit {
 programacoes:any = [];
 programacao:any = [];
 centrosCusto:any = [];
 anosProgramacao:any = [];
 anoSelect:any = "";
 anoProg:any = "";
 motivo:any = "";
 anoOk:boolean = false;
 janCheck:boolean = true;
 fevCheck:boolean = true;
 marCheck:boolean = true;
 abrCheck:boolean = true;
 maiCheck:boolean = true;
 junCheck:boolean = true;
 julCheck:boolean = true;
 agoCheck:boolean = true;
 setCheck:boolean = true;
 outCheck:boolean = true;
 novCheck:boolean = true;
 dezCheck:boolean = true;
 associados:any = [];
 arrayLogs:any = [];
 logsProgramacao:any = [];

  constructor(public db:DataBaseService, public app:AppComponent) { }

  ngOnInit(){
    this.getAnos();
    this.anoOk = false;
    this.db.getAssociadosProjection('"situacao":1,"origem":1,"dataAdesao":1,"motivoCancelamento":1,"dataCancelamento":1,"totalMensalidade":1').subscribe((data:any) => {
      this.associados = data;
    });
  }

  getAnos(){
    this.db.getProgramacoesAnos('"ano":1').subscribe((dataPA:any) => {
      if(dataPA){
         dataPA.forEach((aP:any) => {
        this.anosProgramacao.push(aP.ano);
       })
      }
    })
  }

confereAno(){
  if(!this.anoSelect){
    this.anoOk = false;
    return;
  }
  this.db.getProgramacaoByAno(this.anoSelect).subscribe((dataProgs:any) => {
    this.programacao = dataProgs[0];
    this.programacao.janAdmPrevTotal = 0;
    this.programacao.fevAdmPrevTotal = 0;
    this.programacao.marAdmPrevTotal = 0;
    this.programacao.abrAdmPrevTotal = 0;
    this.programacao.maiAdmPrevTotal = 0;
    this.programacao.junAdmPrevTotal = 0;
    this.programacao.julAdmPrevTotal = 0;
    this.programacao.agoAdmPrevTotal = 0;
    this.programacao.setAdmPrevTotal = 0;
    this.programacao.outAdmPrevTotal = 0;
    this.programacao.novAdmPrevTotal = 0;
    this.programacao.dezAdmPrevTotal = 0;
    this.programacao.janComPrevTotal = 0;
    this.programacao.fevComPrevTotal = 0;
    this.programacao.marComPrevTotal = 0;
    this.programacao.abrComPrevTotal = 0;
    this.programacao.maiComPrevTotal = 0;
    this.programacao.junComPrevTotal = 0;
    this.programacao.julComPrevTotal = 0;
    this.programacao.agoComPrevTotal = 0;
    this.programacao.setComPrevTotal = 0;
    this.programacao.outComPrevTotal = 0;
    this.programacao.novComPrevTotal = 0;
    this.programacao.dezComPrevTotal = 0;
    this.programacao.janPubPrevTotal = 0;
    this.programacao.fevPubPrevTotal = 0;
    this.programacao.marPubPrevTotal = 0;
    this.programacao.abrPubPrevTotal = 0;
    this.programacao.maiPubPrevTotal = 0;
    this.programacao.junPubPrevTotal = 0;
    this.programacao.julPubPrevTotal = 0;
    this.programacao.agoPubPrevTotal = 0;
    this.programacao.setPubPrevTotal = 0;
    this.programacao.outPubPrevTotal = 0;
    this.programacao.novPubPrevTotal = 0;
    this.programacao.dezPubPrevTotal = 0;
    this.db.getLogsProgramacao(this.anoSelect).subscribe((dataLogs:any) => {
      this.logsProgramacao = dataLogs;
    })
    this.db.getCentrosCusto().subscribe((dataCC:any) => {
      let ctrl = 1;
      let meses = [{janPrev : 0,fevPrev : 0,marPrev : 0,abrPrev : 0,maiPrev : 0,junPrev : 0,julPrev : 0,agoPrev : 0,setPrev : 0,outPrev : 0,novPrev : 0,dezPrev : 0,janReal : 0,fevReal : 0,marReal : 0,abrReal : 0,maiReal : 0,junReal : 0,julReal : 0,agoReal : 0,setReal : 0,outReal : 0,novReal : 0,dezReal : 0}];
      dataCC.forEach((p:any) => {
        let controleAdm = 1;
        let controleCom = 1;
        let controlePub = 1;
        if(p.centrosCusto.Administrativo){
          p.contasContabeis.forEach((aC:any) => {
            for(let iA = 0; iA < meses.length; iA++){
              Object.assign(aC,meses[iA]);
            }
          if(p.contasContabeis.length == controleAdm){
          let obj = {
            contaOrcamentaria: p.contaOrcamentaria,
            contasContabeis: p.contasContabeis
           }
           if(this.programacao.administrativo){
            let idx = this.programacao.administrativo.map((pA:any) => {
              return pA.contaOrcamentaria;
            }).indexOf(p.contaOrcamentaria);
            if(idx < 0){
              this.programacao.administrativo.push(obj);  
            }else{
              p.contasContabeis.forEach((cCa:any) => {
                let idxAdm = this.programacao.administrativo[idx].contasContabeis.map((cCmapAdm:any) => {
                    return cCmapAdm.conta;
                }).indexOf(cCa.conta)
                if(idxAdm < 0){
                  for(let iA = 0; iA < meses.length; iA++){
                    Object.assign(cCa,meses[iA]);
                  }
                  this.programacao.administrativo[idx].contasContabeis.push(cCa)
                }else{
                  this.programacao.administrativo[idx].contasContabeis[idxAdm].programacao = cCa.programacao;
                }
              })
            }
           }else{
            this.programacao.administrativo.push(obj);  
           }
          }else{
            controleAdm++;
          }
          })
        }
         if(p.centrosCusto.Comercial){
          p.contasContabeis.forEach((cCC:any) => {
            for(let iC = 0; iC < meses.length; iC++){
              Object.assign(cCC,meses[iC]);
            }
          if(p.contasContabeis.length == controleCom){
           let objC = {
              contaOrcamentaria: p.contaOrcamentaria,
              contasContabeis: p.contasContabeis
             }
             if(this.programacao.comercial){
              let idxC = this.programacao.comercial.map((pC:any) => {
                return pC.contaOrcamentaria;
              }).indexOf(p.contaOrcamentaria);
              if(idxC < 0){
                this.programacao.comercial.push(objC);
              }else{
                p.contasContabeis.forEach((cCC:any) => {
                  let idxCom = this.programacao.comercial[idxC].contasContabeis.map((cCmapCom:any) => {
                      return cCmapCom.conta;
                  }).indexOf(cCC.conta)
                  if(idxCom < 0){
                    for(let iA = 0; iA < meses.length; iA++){
                      Object.assign(cCC,meses[iA]);
                    }
                    this.programacao.comercial[idxC].contasContabeis.push(cCC)
                  }else{
                    this.programacao.administrativo[idxC].contasContabeis[idxCom].programacao = cCC.programacao;
                  }
                })
              }
             }else{
              this.programacao.comercial.push(objC);
             }
            }else{
            controleCom++;
            }
          })
        } 
        if(p.centrosCusto.Publicidade){
          p.contasContabeis.forEach((pC:any) => {
             for(let iP = 0; iP < meses.length; iP++){
              Object.assign(pC,meses[iP]);
            }
          if(p.contasContabeis.length == controlePub){
           let objP = {
              contaOrcamentaria: p.contaOrcamentaria,
              contasContabeis: p.contasContabeis
             }
             if(this.programacao.publicidade){
              let idxP = this.programacao.publicidade.map((pP:any) => {
                return pP.contaOrcamentaria;
              }).indexOf(p.contaOrcamentaria);
              if(idxP < 0){
                this.programacao.publicidade.push(objP);  
              }else{
                p.contasContabeis.forEach((cCP:any) => {
                  let idxPub = this.programacao.publicidade[idxP].contasContabeis.map((cCmapPub:any) => {
                      return cCmapPub.conta;
                  }).indexOf(cCP.conta)
                  if(idxPub < 0){
                    for(let iA = 0; iA < meses.length; iA++){
                      Object.assign(cCP,meses[iA]);
                    }
                    this.programacao.publicidade[idxP].contasContabeis.push(cCP)
                  }else{
                    this.programacao.administrativo[idxP].contasContabeis[idxPub].programacao = cCP.programacao;
                  }
                })
              }
             }else{
              this.programacao.publicidade.push(objP);  
             }
          }else{
            controlePub++;
          }
          })
        } 
        if(dataCC.length == ctrl){
        /* PLANOS */
        this.programacao.realPlanJan = 0;
        this.programacao.realPlanFev = 0;
        this.programacao.realPlanMar = 0;
        this.programacao.realPlanAbr = 0;
        this.programacao.realPlanMai = 0;
        this.programacao.realPlanJun = 0;
        this.programacao.realPlanJul = 0;
        this.programacao.realPlanAgo = 0;
        this.programacao.realPlanSet = 0;
        this.programacao.realPlanOut = 0;
        this.programacao.realPlanNov = 0;
        this.programacao.realPlanDez = 0;
        /* VALOR DOS PLANOS */
        this.programacao.realValorPlanJan = 0;
        this.programacao.realValorPlanFev = 0;
        this.programacao.realValorPlanMar = 0;
        this.programacao.realValorPlanAbr = 0;
        this.programacao.realValorPlanMai = 0;
        this.programacao.realValorPlanJun = 0;
        this.programacao.realValorPlanJul = 0;
        this.programacao.realValorPlanAgo = 0;
        this.programacao.realValorPlanSet = 0;
        this.programacao.realValorPlanOut = 0;
        this.programacao.realValorPlanNov = 0;
        this.programacao.realValorPlanDez = 0;
        /* CANCELAMENTOS */
        this.programacao.realCanJan = 0;
        this.programacao.realCanFev = 0;
        this.programacao.realCanMar = 0;
        this.programacao.realCanAbr = 0;
        this.programacao.realCanMai = 0;
        this.programacao.realCanJun = 0;
        this.programacao.realCanJul = 0;
        this.programacao.realCanAgo = 0;
        this.programacao.realCanSet = 0;
        this.programacao.realCanOut = 0;
        this.programacao.realCanNov = 0;
        this.programacao.realCanDez = 0;
        /* VALOR DOS CANCELAMENTOS */
        this.programacao.realValorCanJan = 0;
        this.programacao.realValorCanFev = 0;
        this.programacao.realValorCanMar = 0;
        this.programacao.realValorCanAbr = 0;
        this.programacao.realValorCanMai = 0;
        this.programacao.realValorCanJun = 0;
        this.programacao.realValorCanJul = 0;
        this.programacao.realValorCanAgo = 0;
        this.programacao.realValorCanSet = 0;
        this.programacao.realValorCanOut = 0;
        this.programacao.realValorCanNov = 0;
        this.programacao.realValorCanDez = 0;
        /* RECEITAS */
        this.programacao.realReceitasJan = 0;
        this.programacao.realReceitasFev = 0;
        this.programacao.realReceitasMar = 0;
        this.programacao.realReceitasAbr = 0;
        this.programacao.realReceitasMai = 0;
        this.programacao.realReceitasJun = 0;
        this.programacao.realReceitasJul = 0;
        this.programacao.realReceitasAgo = 0;
        this.programacao.realReceitasSet = 0;
        this.programacao.realReceitasOut = 0;
        this.programacao.realReceitasNov = 0;
        this.programacao.realReceitasDez = 0;
        /* RECEITAS */
        this.programacao.realGuiasJan = 0;
        this.programacao.realGuiasFev = 0;
        this.programacao.realGuiasMar = 0;
        this.programacao.realGuiasAbr = 0;
        this.programacao.realGuiasMai = 0;
        this.programacao.realGuiasJun = 0;
        this.programacao.realGuiasJul = 0;
        this.programacao.realGuiasAgo = 0;
        this.programacao.realGuiasSet = 0;
        this.programacao.realGuiasOut = 0;
        this.programacao.realGuiasNov = 0;
        this.programacao.realGuiasDez = 0;
        /* RENDIMENTO */
        this.programacao.realRendimentosJan = 0;
        this.programacao.realRendimentosFev = 0;
        this.programacao.realRendimentosMar = 0;
        this.programacao.realRendimentosAbr = 0;
        this.programacao.realRendimentosMai = 0;
        this.programacao.realRendimentosJun = 0;
        this.programacao.realRendimentosJul = 0;
        this.programacao.realRendimentosAgo = 0;
        this.programacao.realRendimentosSet = 0;
        this.programacao.realRendimentosOut = 0;
        this.programacao.realRendimentosNov = 0;
        this.programacao.realRendimentosDez = 0;
        /* TOTAL ADM */
        this.programacao.janAdmRealTotal = 0;
        this.programacao.fevAdmRealTotal = 0;
        this.programacao.marAdmRealTotal = 0;
        this.programacao.abrAdmRealTotal = 0;
        this.programacao.maiAdmRealTotal = 0;
        this.programacao.junAdmRealTotal = 0;
        this.programacao.julAdmRealTotal = 0;
        this.programacao.agoAdmRealTotal = 0;
        this.programacao.setAdmRealTotal = 0;
        this.programacao.outAdmRealTotal = 0;
        this.programacao.novAdmRealTotal = 0;
        this.programacao.dezAdmRealTotal = 0;
        /* TOTAL COMERCIAL */
        this.programacao.janComRealTotal = 0;
        this.programacao.fevComRealTotal = 0;
        this.programacao.marComRealTotal = 0;
        this.programacao.abrComRealTotal = 0;
        this.programacao.maiComRealTotal = 0;
        this.programacao.junComRealTotal = 0;
        this.programacao.julComRealTotal = 0;
        this.programacao.agoComRealTotal = 0;
        this.programacao.setComRealTotal = 0;
        this.programacao.outComRealTotal = 0;
        this.programacao.novComRealTotal = 0;
        this.programacao.dezComRealTotal = 0;
        /* TOTAL PUBLICIDADE */
        this.programacao.janPubRealTotal = 0;
        this.programacao.fevPubRealTotal = 0;
        this.programacao.marPubRealTotal = 0;
        this.programacao.abrPubRealTotal = 0;
        this.programacao.maiPubRealTotal = 0;
        this.programacao.junPubRealTotal = 0;
        this.programacao.julPubRealTotal = 0;
        this.programacao.agoPubRealTotal = 0;
        this.programacao.setPubRealTotal = 0;
        this.programacao.outPubRealTotal = 0;
        this.programacao.novPubRealTotal = 0;
        this.programacao.dezPubRealTotal = 0;
        this.associados.forEach((item:any) => {
          let anoAdesao = item.dataAdesao.split('-')[0];
          if(this.anoSelect == anoAdesao && ((item.situacao == "ATIVO" || item.situacao == "ALTERAÇÃO" || item.situacao == "CANCELADO" ) && item.origem != "INCLUSÃO EMPRESA")){
          let dateAdesao = moment(item.dataAdesao);
          let n = dateAdesao.month();
          switch(n){
            case 0 : this.programacao.realPlanJan++; this.programacao.realValorPlanJan += item.totalMensalidade;break;
            case 1 : this.programacao.realPlanFev++; this.programacao.realValorPlanFev += item.totalMensalidade;break;
            case 2 : this.programacao.realPlanMar++; this.programacao.realValorPlanMar += item.totalMensalidade;break;
            case 3 : this.programacao.realPlanAbr++; this.programacao.realValorPlanAbr += item.totalMensalidade;break;
            case 4 : this.programacao.realPlanMai++; this.programacao.realValorPlanMai += item.totalMensalidade;break;
            case 5 : this.programacao.realPlanJun++; this.programacao.realValorPlanJun += item.totalMensalidade;break;
            case 6 : this.programacao.realPlanJul++; this.programacao.realValorPlanJul += item.totalMensalidade;break;
            case 7 : this.programacao.realPlanAgo++; this.programacao.realValorPlanAgo += item.totalMensalidade;break;
            case 8 : this.programacao.realPlanSet++; this.programacao.realValorPlanSet += item.totalMensalidade;break;
            case 9 : this.programacao.realPlanOut++; this.programacao.realValorPlanOut += item.totalMensalidade;break;
            case 10 : this.programacao.realPlanNov++; this.programacao.realValorPlanNov += item.totalMensalidade;break;
            case 11 : this.programacao.realPlanDez++; this.programacao.realValorPlanDez += item.totalMensalidade;break;
            default:break;
            }
          }
          if(item.situacao == "CANCELADO" && (item.motivoCancelamento != "MOVIMENTAÇÃO - EXCLUSÃO" && item.motivoCancelamento != "MIGROU DE PLANO - VIVA MAX" && item.motivoCancelamento != "EXCLUSÃO DE PLANO POR FALTA DE CADASTRO DE CARTÃO" && item.motivoCancelamento != "MIGRAÇÃO VIVA MAX NOVOS PRODUTOS")){
            let anoCancelamento = item.dataCancelamento?.split('-')[0];
            if(this.anoSelect == anoCancelamento){
              let dateCancelamento = moment(item.dataCancelamento)
              let c = dateCancelamento.month();
              switch(c){
                case 0 : this.programacao.realCanJan++;this.programacao.realValorCanJan += item.totalMensalidade;break;
                case 1 : this.programacao.realCanFev++;this.programacao.realValorCanFev += item.totalMensalidade;break;
                case 2 : this.programacao.realCanMar++;this.programacao.realValorCanMar += item.totalMensalidade;break;
                case 3 : this.programacao.realCanAbr++;this.programacao.realValorCanAbr += item.totalMensalidade;break;
                case 4 : this.programacao.realCanMai++;this.programacao.realValorCanMai += item.totalMensalidade;break;
                case 5 : this.programacao.realCanJun++;this.programacao.realValorCanJun += item.totalMensalidade;break;
                case 6 : this.programacao.realCanJul++;this.programacao.realValorCanJul += item.totalMensalidade;break;
                case 7 : this.programacao.realCanAgo++;this.programacao.realValorCanAgo += item.totalMensalidade;break;
                case 8 : this.programacao.realCanSet++;this.programacao.realValorCanSet += item.totalMensalidade;break;
                case 9 : this.programacao.realCanOut++;this.programacao.realValorCanOut += item.totalMensalidade;break;
                case 10 : this.programacao.realCanNov++;this.programacao.realValorCanNov += item.totalMensalidade;break;
                case 11 : this.programacao.realCanDez++;this.programacao.realValorCanDez += item.totalMensalidade;break;
                  default: break;
              }
            }
          }
        })      
        this.db.getCaixa(this.anoSelect+'-01-01',this.anoSelect+'-12-31').subscribe((dataCaixa:any) => {
          dataCaixa.forEach((conta:any) => {
            if(conta.tipo == "Entrada"){
              let dataConta = moment(conta.dataPagamento);
              let n = dataConta.month();
              if(conta.tipoEntrada != 'RENDIMENTOS' && conta.tipoEntrada != 'GUIAS SINDILOJAS E AMIC'){
                switch(n){
                  case 0 : this.programacao.realReceitasJan += conta.valorTotal;break;
                  case 1 : this.programacao.realReceitasFev += conta.valorTotal;break;
                  case 2 : this.programacao.realReceitasMar += conta.valorTotal;break;
                  case 3 : this.programacao.realReceitasAbr += conta.valorTotal;break;
                  case 4 : this.programacao.realReceitasMai += conta.valorTotal;break;
                  case 5 : this.programacao.realReceitasJun += conta.valorTotal;break;
                  case 6 : this.programacao.realReceitasJul += conta.valorTotal;break;
                  case 7 : this.programacao.realReceitasAgo += conta.valorTotal;break;
                  case 8 : this.programacao.realReceitasSet += conta.valorTotal;break;
                  case 9 : this.programacao.realReceitasOut += conta.valorTotal;break;
                  case 10 : this.programacao.realReceitasNov += conta.valorTotal;break;
                  case 11 : this.programacao.realReceitasDez += conta.valorTotal;break;
                }
              }
              if(conta.tipoEntrada == 'RENDIMENTOS'){
                switch(n){
                  case 0 : this.programacao.realRendimentosJan += conta.valorTotal;break;
                  case 1 : this.programacao.realRendimentosFev += conta.valorTotal;break;
                  case 2 : this.programacao.realRendimentosMar += conta.valorTotal;break;
                  case 3 : this.programacao.realRendimentosAbr += conta.valorTotal;break;
                  case 4 : this.programacao.realRendimentosMai += conta.valorTotal;break;
                  case 5 : this.programacao.realRendimentosJun += conta.valorTotal;break;
                  case 6 : this.programacao.realRendimentosJul += conta.valorTotal;break;
                  case 7 : this.programacao.realRendimentosAgo += conta.valorTotal;break;
                  case 8 : this.programacao.realRendimentosSet += conta.valorTotal;break;
                  case 9 : this.programacao.realRendimentosOut += conta.valorTotal;break;
                  case 10 : this.programacao.realRendimentosNov += conta.valorTotal;break;
                  case 11 : this.programacao.realRendimentosDez += conta.valorTotal;break;
                }
              }
              if(conta.tipoEntrada == 'GUIAS SINDILOJAS E AMIC'){
                switch(n){
                  case 0 : this.programacao.realGuiasJan += conta.valorTotal;break;
                  case 1 : this.programacao.realGuiasFev += conta.valorTotal;break;
                  case 2 : this.programacao.realGuiasMar += conta.valorTotal;break;
                  case 3 : this.programacao.realGuiasAbr += conta.valorTotal;break;
                  case 4 : this.programacao.realGuiasMai += conta.valorTotal;break;
                  case 5 : this.programacao.realGuiasJun += conta.valorTotal;break;
                  case 6 : this.programacao.realGuiasJul += conta.valorTotal;break;
                  case 7 : this.programacao.realGuiasAgo += conta.valorTotal;break;
                  case 8 : this.programacao.realGuiasSet += conta.valorTotal;break;
                  case 9 : this.programacao.realGuiasOut += conta.valorTotal;break;
                  case 10 : this.programacao.realGuiasNov += conta.valorTotal;break;
                  case 11 : this.programacao.realGuiasDez += conta.valorTotal;break;
                }
              }
            }else{
            if(conta.centrosCusto){
              let mesConta:any = conta.dataPagamento.split('-')[1];
              conta.centrosCusto.forEach((cc:any) => {
                /* ADM */
                if(cc.centroDeCusto == 'Administrativo'){
                  if(mesConta == '01'){
                    this.programacao.janAdmRealTotal += cc.valor;
                    let idxAdmJan = this.programacao.administrativo.map((item:any) => {
                      return item.contaOrcamentaria;
                    }).indexOf(cc.contaOrcamentaria);
                    if(idxAdmJan >= 0 ){
                      let indiceAdmJan = this.programacao.administrativo[idxAdmJan].contasContabeis.map((itemConta:any) => {
                        return itemConta.conta;
                      }).indexOf(cc.contaContabil);
                      if(indiceAdmJan >= 0){
                        if(!this.programacao.administrativo[idxAdmJan].contasContabeis[indiceAdmJan].janReal){
                          this.programacao.administrativo[idxAdmJan].contasContabeis[indiceAdmJan].janReal = 0;
                        }
                        this.programacao.administrativo[idxAdmJan].contasContabeis[indiceAdmJan].janReal += cc.valor;
                      }
                    }
                  }
                  if(mesConta == '02'){
                    this.programacao.fevAdmRealTotal += cc.valor;
                    let idxAdmFev = this.programacao.administrativo.map((item:any) => {
                      return item.contaOrcamentaria;
                    }).indexOf(cc.contaOrcamentaria);
                    if(idxAdmFev >= 0 ){
                      let indiceAdmFev = this.programacao.administrativo[idxAdmFev].contasContabeis.map((itemConta:any) => {
                        return itemConta.conta;
                      }).indexOf(cc.contaContabil);
                      if(indiceAdmFev >= 0){
                        if(!this.programacao.administrativo[idxAdmFev].contasContabeis[indiceAdmFev].fevReal){
                          this.programacao.administrativo[idxAdmFev].contasContabeis[indiceAdmFev].fevReal = 0;
                        }
                        this.programacao.administrativo[idxAdmFev].contasContabeis[indiceAdmFev].fevReal += cc.valor;
                      }
                    }
                  }
                  if(mesConta == '03'){
                    this.programacao.marAdmRealTotal += cc.valor;
                    let idxAdmMar = this.programacao.administrativo.map((item:any) => {
                      return item.contaOrcamentaria;
                    }).indexOf(cc.contaOrcamentaria);
                    if(idxAdmMar >= 0 ){
                      let indiceAdmMar = this.programacao.administrativo[idxAdmMar].contasContabeis.map((itemConta:any) => {
                        return itemConta.conta;
                      }).indexOf(cc.contaContabil);
                      if(indiceAdmMar >= 0){
                        if(!this.programacao.administrativo[idxAdmMar].contasContabeis[indiceAdmMar].marReal){
                          this.programacao.administrativo[idxAdmMar].contasContabeis[indiceAdmMar].marReal = 0;
                        }
                        this.programacao.administrativo[idxAdmMar].contasContabeis[indiceAdmMar].marReal += cc.valor;
                      }
                    }
                  }
                  if(mesConta == '04'){
                    this.programacao.abrAdmRealTotal += cc.valor;
                    let idxAdmAbr = this.programacao.administrativo.map((item:any) => {
                      return item.contaOrcamentaria;
                    }).indexOf(cc.contaOrcamentaria);
                    if(idxAdmAbr >= 0 ){
                      let indiceAdmAbr = this.programacao.administrativo[idxAdmAbr].contasContabeis.map((itemConta:any) => {
                        return itemConta.conta;
                      }).indexOf(cc.contaContabil);
                      if(indiceAdmAbr >= 0){
                        if(!this.programacao.administrativo[idxAdmAbr].contasContabeis[indiceAdmAbr].abrReal){
                          this.programacao.administrativo[idxAdmAbr].contasContabeis[indiceAdmAbr].abrReal = 0;
                        }
                        this.programacao.administrativo[idxAdmAbr].contasContabeis[indiceAdmAbr].abrReal += cc.valor;
                      }
                    }
                  }
                  if(mesConta == '05'){
                    this.programacao.maiAdmRealTotal += cc.valor;
                    let idxAdmMai = this.programacao.administrativo.map((item:any) => {
                      return item.contaOrcamentaria;
                    }).indexOf(cc.contaOrcamentaria);
                    if(idxAdmMai >= 0 ){
                      let indiceAdmMai = this.programacao.administrativo[idxAdmMai].contasContabeis.map((itemConta:any) => {
                        return itemConta.conta;
                      }).indexOf(cc.contaContabil);
                      if(indiceAdmMai >= 0){
                        if(!this.programacao.administrativo[idxAdmMai].contasContabeis[indiceAdmMai].maiReal){
                          this.programacao.administrativo[idxAdmMai].contasContabeis[indiceAdmMai].maiReal = 0;
                        }
                        this.programacao.administrativo[idxAdmMai].contasContabeis[indiceAdmMai].maiReal += cc.valor;
                      }
                    }
                  }
                  if(mesConta == '06'){
                    this.programacao.junAdmRealTotal += cc.valor;
                    let idxAdmJun = this.programacao.administrativo.map((item:any) => {
                      return item.contaOrcamentaria;
                    }).indexOf(cc.contaOrcamentaria);
                    if(idxAdmJun >= 0 ){
                      let indiceAdmJun = this.programacao.administrativo[idxAdmJun].contasContabeis.map((itemConta:any) => {
                        return itemConta.conta;
                      }).indexOf(cc.contaContabil);
                      if(indiceAdmJun >= 0){
                        if(!this.programacao.administrativo[idxAdmJun].contasContabeis[indiceAdmJun].junReal){
                          this.programacao.administrativo[idxAdmJun].contasContabeis[indiceAdmJun].junReal = 0;
                        }
                        this.programacao.administrativo[idxAdmJun].contasContabeis[indiceAdmJun].junReal += cc.valor;
                      }
                    }
                  }
                  if(mesConta == '07'){
                    this.programacao.julAdmRealTotal += cc.valor;
                    let idxAdmJul = this.programacao.administrativo.map((item:any) => {
                      return item.contaOrcamentaria;
                    }).indexOf(cc.contaOrcamentaria);
                    if(idxAdmJul >= 0 ){
                      let indiceAdmJul = this.programacao.administrativo[idxAdmJul].contasContabeis.map((itemConta:any) => {
                        return itemConta.conta;
                      }).indexOf(cc.contaContabil);
                      if(indiceAdmJul >= 0){
                        if(!this.programacao.administrativo[idxAdmJul].contasContabeis[indiceAdmJul].julReal){
                          this.programacao.administrativo[idxAdmJul].contasContabeis[indiceAdmJul].julReal = 0;
                        }
                        this.programacao.administrativo[idxAdmJul].contasContabeis[indiceAdmJul].julReal += cc.valor;
                      }
                    }
                  }
                  if(mesConta == '08'){
                    this.programacao.agoAdmRealTotal += cc.valor;
                    let idxAdmAgo = this.programacao.administrativo.map((item:any) => {
                      return item.contaOrcamentaria;
                    }).indexOf(cc.contaOrcamentaria);
                    if(idxAdmAgo >= 0 ){
                      let indiceAdmAgo = this.programacao.administrativo[idxAdmAgo].contasContabeis.map((itemConta:any) => {
                        return itemConta.conta;
                      }).indexOf(cc.contaContabil);
                      if(indiceAdmAgo >= 0){
                        if(!this.programacao.administrativo[idxAdmAgo].contasContabeis[indiceAdmAgo].agoReal){
                          this.programacao.administrativo[idxAdmAgo].contasContabeis[indiceAdmAgo].agoReal = 0;
                        }
                        this.programacao.administrativo[idxAdmAgo].contasContabeis[indiceAdmAgo].agoReal += cc.valor;
                      }
                    }
                  }
                  if(mesConta == '09'){
                    this.programacao.setAdmRealTotal += cc.valor;
                    let idxAdmSet = this.programacao.administrativo.map((item:any) => {
                      return item.contaOrcamentaria;
                    }).indexOf(cc.contaOrcamentaria);
                    if(idxAdmSet >= 0 ){
                      let indiceAdmSet = this.programacao.administrativo[idxAdmSet].contasContabeis.map((itemConta:any) => {
                        return itemConta.conta;
                      }).indexOf(cc.contaContabil);
                      if(indiceAdmSet >= 0){
                        if(!this.programacao.administrativo[idxAdmSet].contasContabeis[indiceAdmSet].setReal){
                          this.programacao.administrativo[idxAdmSet].contasContabeis[indiceAdmSet].setReal = 0;
                        }
                        this.programacao.administrativo[idxAdmSet].contasContabeis[indiceAdmSet].setReal += cc.valor;
                      }
                    }
                  }
                  if(mesConta == '10'){
                    this.programacao.outAdmRealTotal += cc.valor;
                    let idxAdmOut = this.programacao.administrativo.map((item:any) => {
                      return item.contaOrcamentaria;
                    }).indexOf(cc.contaOrcamentaria);
                    if(idxAdmOut >= 0 ){
                      let indiceAdmOut = this.programacao.administrativo[idxAdmOut].contasContabeis.map((itemConta:any) => {
                        return itemConta.conta;
                      }).indexOf(cc.contaContabil);
                      if(indiceAdmOut >= 0){
                        if(!this.programacao.administrativo[idxAdmOut].contasContabeis[indiceAdmOut].outReal){
                          this.programacao.administrativo[idxAdmOut].contasContabeis[indiceAdmOut].outReal = 0;
                        }
                        this.programacao.administrativo[idxAdmOut].contasContabeis[indiceAdmOut].outReal += cc.valor;
                      }
                    }
                  }
                  if(mesConta == '11'){
                    this.programacao.novAdmRealTotal += cc.valor;
                    let idxAdmNov = this.programacao.administrativo.map((item:any) => {
                      return item.contaOrcamentaria;
                    }).indexOf(cc.contaOrcamentaria);
                    if(idxAdmNov >= 0 ){
                      let indiceAdmNov = this.programacao.administrativo[idxAdmNov].contasContabeis.map((itemConta:any) => {
                        return itemConta.conta;
                      }).indexOf(cc.contaContabil);
                      if(indiceAdmNov >= 0){
                        if(!this.programacao.administrativo[idxAdmNov].contasContabeis[indiceAdmNov].novReal){
                          this.programacao.administrativo[idxAdmNov].contasContabeis[indiceAdmNov].novReal = 0;
                        }
                        this.programacao.administrativo[idxAdmNov].contasContabeis[indiceAdmNov].novReal += cc.valor;
                      }
                    }
                  }
                  if(mesConta == '12'){
                    this.programacao.dezAdmRealTotal += cc.valor;
                    let idxAdmDez = this.programacao.administrativo.map((item:any) => {
                      return item.contaOrcamentaria;
                    }).indexOf(cc.contaOrcamentaria);
                    if(idxAdmDez >= 0 ){
                      let indiceAdmDez = this.programacao.administrativo[idxAdmDez].contasContabeis.map((itemConta:any) => {
                        return itemConta.conta;
                      }).indexOf(cc.contaContabil);
                      if(indiceAdmDez >= 0){
                        if(!this.programacao.administrativo[idxAdmDez].contasContabeis[indiceAdmDez].dezReal){
                          this.programacao.administrativo[idxAdmDez].contasContabeis[indiceAdmDez].dezReal = 0;
                        }
                        this.programacao.administrativo[idxAdmDez].contasContabeis[indiceAdmDez].dezReal += cc.valor;
                      }
                    }
                  }
                }
               /* COM  */
                if(cc.centroDeCusto == 'Comercial'){
                  if(mesConta == '01'){
                    this.programacao.janComRealTotal += cc.valor;
                    let idx = this.programacao.comercial.map((item:any) => {
                      return item.contaOrcamentaria;
                    }).indexOf(cc.contaOrcamentaria);
                    if(idx >= 0 ){
                      let indice = this.programacao.comercial[idx].contasContabeis.map((itemConta:any) => {
                        return itemConta.conta;
                      }).indexOf(cc.contaContabil);
                      if(indice >= 0){
                        if(!this.programacao.comercial[idx].contasContabeis[indice].janReal){
                          this.programacao.comercial[idx].contasContabeis[indice].janReal = 0;
                        }
                        this.programacao.comercial[idx].contasContabeis[indice].janReal += cc.valor;
                      }
                    }
                  }
                  if(mesConta == '02'){
                    this.programacao.fevComRealTotal += cc.valor;
                    let idx = this.programacao.comercial.map((item:any) => {
                      return item.contaOrcamentaria;
                    }).indexOf(cc.contaOrcamentaria);
                    if(idx >= 0 ){
                      let indice = this.programacao.comercial[idx].contasContabeis.map((itemConta:any) => {
                        return itemConta.conta;
                      }).indexOf(cc.contaContabil);
                      if(indice >= 0){
                        if(!this.programacao.comercial[idx].contasContabeis[indice].fevReal){
                          this.programacao.comercial[idx].contasContabeis[indice].fevReal = 0;
                        }
                        this.programacao.comercial[idx].contasContabeis[indice].fevReal += cc.valor;
                      }
                    }
                  }
                  if(mesConta == '03'){
                    this.programacao.marComRealTotal += cc.valor;
                    let idx = this.programacao.comercial.map((item:any) => {
                      return item.contaOrcamentaria;
                    }).indexOf(cc.contaOrcamentaria);
                    if(idx >= 0 ){
                      let indice = this.programacao.comercial[idx].contasContabeis.map((itemConta:any) => {
                        return itemConta.conta;
                      }).indexOf(cc.contaContabil);
                      if(indice >= 0){
                        if(!this.programacao.comercial[idx].contasContabeis[indice].marReal){
                          this.programacao.comercial[idx].contasContabeis[indice].marReal = 0;
                        }
                        this.programacao.comercial[idx].contasContabeis[indice].marReal += cc.valor;
                      }
                    }
                  }
                  if(mesConta == '04'){
                    this.programacao.abrComRealTotal += cc.valor;
                    let idx = this.programacao.comercial.map((item:any) => {
                      return item.contaOrcamentaria;
                    }).indexOf(cc.contaOrcamentaria);
                    if(idx >= 0 ){
                      let indice = this.programacao.comercial[idx].contasContabeis.map((itemConta:any) => {
                        return itemConta.conta;
                      }).indexOf(cc.contaContabil);
                      if(indice >= 0){
                        if(!this.programacao.comercial[idx].contasContabeis[indice].abrReal){
                          this.programacao.comercial[idx].contasContabeis[indice].abrReal = 0;
                        }
                        this.programacao.comercial[idx].contasContabeis[indice].abrReal += cc.valor;
                      }
                    }
                  }
                  if(mesConta == '05'){
                    this.programacao.maiComRealTotal += cc.valor;
                    let idx = this.programacao.comercial.map((item:any) => {
                      return item.contaOrcamentaria;
                    }).indexOf(cc.contaOrcamentaria);
                    if(idx >= 0 ){
                      let indice = this.programacao.comercial[idx].contasContabeis.map((itemConta:any) => {
                        return itemConta.conta;
                      }).indexOf(cc.contaContabil);
                      if(indice >= 0){
                        if(!this.programacao.comercial[idx].contasContabeis[indice].maiReal){
                          this.programacao.comercial[idx].contasContabeis[indice].maiReal = 0;
                        }
                        this.programacao.comercial[idx].contasContabeis[indice].maiReal += cc.valor;
                      }
                    }
                  }
                  if(mesConta == '06'){
                    this.programacao.junComRealTotal += cc.valor;
                    let idx = this.programacao.comercial.map((item:any) => {
                      return item.contaOrcamentaria;
                    }).indexOf(cc.contaOrcamentaria);
                    if(idx >= 0 ){
                      let indice = this.programacao.comercial[idx].contasContabeis.map((itemConta:any) => {
                        return itemConta.conta;
                      }).indexOf(cc.contaContabil);
                      if(indice >= 0){
                        if(!this.programacao.comercial[idx].contasContabeis[indice].junReal){
                          this.programacao.comercial[idx].contasContabeis[indice].junReal = 0;
                        }
                        this.programacao.comercial[idx].contasContabeis[indice].junReal += cc.valor;
                      }
                    }
                  }
                  if(mesConta == '07'){
                    this.programacao.julComRealTotal += cc.valor;
                    let idx = this.programacao.comercial.map((item:any) => {
                      return item.contaOrcamentaria;
                    }).indexOf(cc.contaOrcamentaria);
                    if(idx >= 0 ){
                      let indice = this.programacao.comercial[idx].contasContabeis.map((itemConta:any) => {
                        return itemConta.conta;
                      }).indexOf(cc.contaContabil);
                      if(indice >= 0){
                        if(!this.programacao.comercial[idx].contasContabeis[indice].julReal){
                          this.programacao.comercial[idx].contasContabeis[indice].julReal = 0;
                        }
                        this.programacao.comercial[idx].contasContabeis[indice].julReal += cc.valor;
                      }
                    }
                  }
                  if(mesConta == '08'){
                    this.programacao.agoComRealTotal += cc.valor;
                    let idx = this.programacao.comercial.map((item:any) => {
                      return item.contaOrcamentaria;
                    }).indexOf(cc.contaOrcamentaria);
                    if(idx >= 0 ){
                      let indice = this.programacao.comercial[idx].contasContabeis.map((itemConta:any) => {
                        return itemConta.conta;
                      }).indexOf(cc.contaContabil);
                      if(indice >= 0){
                        if(!this.programacao.comercial[idx].contasContabeis[indice].agoReal){
                          this.programacao.comercial[idx].contasContabeis[indice].agoReal = 0;
                        }
                        this.programacao.comercial[idx].contasContabeis[indice].agoReal += cc.valor;
                      }
                    }
                  }
                  if(mesConta == '09'){
                    this.programacao.setComRealTotal += cc.valor;
                    let idx = this.programacao.comercial.map((item:any) => {
                      return item.contaOrcamentaria;
                    }).indexOf(cc.contaOrcamentaria);
                    if(idx >= 0 ){
                      let indice = this.programacao.comercial[idx].contasContabeis.map((itemConta:any) => {
                        return itemConta.conta;
                      }).indexOf(cc.contaContabil);
                      if(indice >= 0){
                        if(!this.programacao.comercial[idx].contasContabeis[indice].setReal){
                          this.programacao.comercial[idx].contasContabeis[indice].setReal = 0;
                        }
                        this.programacao.comercial[idx].contasContabeis[indice].setReal += cc.valor;
                      }
                    }
                  }
                  if(mesConta == '10'){
                    this.programacao.outComRealTotal += cc.valor;
                    let idx = this.programacao.comercial.map((item:any) => {
                      return item.contaOrcamentaria;
                    }).indexOf(cc.contaOrcamentaria);
                    if(idx >= 0 ){
                      let indice = this.programacao.comercial[idx].contasContabeis.map((itemConta:any) => {
                        return itemConta.conta;
                      }).indexOf(cc.contaContabil);
                      if(indice >= 0){
                        if(!this.programacao.comercial[idx].contasContabeis[indice].outReal){
                          this.programacao.comercial[idx].contasContabeis[indice].outReal = 0;
                        }
                        this.programacao.comercial[idx].contasContabeis[indice].outReal += cc.valor;
                      }
                    }
                  }
                  if(mesConta == '11'){
                    this.programacao.novComRealTotal += cc.valor;
                    let idx = this.programacao.comercial.map((item:any) => {
                      return item.contaOrcamentaria;
                    }).indexOf(cc.contaOrcamentaria);
                    if(idx >= 0 ){
                      let indice = this.programacao.comercial[idx].contasContabeis.map((itemConta:any) => {
                        return itemConta.conta;
                      }).indexOf(cc.contaContabil);
                      if(indice >= 0){
                        if(!this.programacao.comercial[idx].contasContabeis[indice].novReal){
                          this.programacao.comercial[idx].contasContabeis[indice].novReal = 0;
                        }
                        this.programacao.comercial[idx].contasContabeis[indice].novReal += cc.valor;
                      }
                    }
                  }
                  if(mesConta == '12'){
                    this.programacao.dezComRealTotal += cc.valor;
                    let idx = this.programacao.comercial.map((item:any) => {
                      return item.contaOrcamentaria;
                    }).indexOf(cc.contaOrcamentaria);
                    if(idx >= 0 ){
                      let indice = this.programacao.comercial[idx].contasContabeis.map((itemConta:any) => {
                        return itemConta.conta;
                      }).indexOf(cc.contaContabil);
                      if(indice >= 0){
                        if(!this.programacao.comercial[idx].contasContabeis[indice].dezReal){
                          this.programacao.comercial[idx].contasContabeis[indice].dezReal = 0;
                        }
                        this.programacao.comercial[idx].contasContabeis[indice].dezReal += cc.valor;
                      }
                    }
                  }
                }
                /*  PUBLI */
                if(cc.centroDeCusto == 'Publicidade'){
                  if(mesConta == '01'){
                    this.programacao.janPubRealTotal += cc.valor;
                    let idx = this.programacao.publicidade.map((item:any) => {
                      return item.contaOrcamentaria;
                    }).indexOf(cc.contaOrcamentaria);
                    if(idx >= 0 ){
                      let indice = this.programacao.publicidade[idx].contasContabeis.map((itemConta:any) => {
                        return itemConta.conta;
                      }).indexOf(cc.contaContabil);
                      if(indice >= 0){
                        if(!this.programacao.publicidade[idx].contasContabeis[indice].janReal){
                          this.programacao.publicidade[idx].contasContabeis[indice].janReal = 0;
                        }
                        this.programacao.publicidade[idx].contasContabeis[indice].janReal += cc.valor;
                      }
                    }
                  }
                  if(mesConta == '02'){
                    this.programacao.fevPubRealTotal += cc.valor;
                    let idx = this.programacao.publicidade.map((item:any) => {
                      return item.contaOrcamentaria;
                    }).indexOf(cc.contaOrcamentaria);
                    if(idx >= 0 ){
                      let indice = this.programacao.publicidade[idx].contasContabeis.map((itemConta:any) => {
                        return itemConta.conta;
                      }).indexOf(cc.contaContabil);
                      if(indice >= 0){
                        if(!this.programacao.publicidade[idx].contasContabeis[indice].fevReal){
                          this.programacao.publicidade[idx].contasContabeis[indice].fevReal = 0;
                        }
                        this.programacao.publicidade[idx].contasContabeis[indice].fevReal += cc.valor;
                      }
                    }
                  }
                  if(mesConta == '03'){
                    this.programacao.marPubRealTotal += cc.valor;
                    let idx = this.programacao.publicidade.map((item:any) => {
                      return item.contaOrcamentaria;
                    }).indexOf(cc.contaOrcamentaria);
                    if(idx >= 0 ){
                      let indice = this.programacao.publicidade[idx].contasContabeis.map((itemConta:any) => {
                        return itemConta.conta;
                      }).indexOf(cc.contaContabil);
                      if(indice >= 0){
                        if(!this.programacao.publicidade[idx].contasContabeis[indice].marReal){
                          this.programacao.publicidade[idx].contasContabeis[indice].marReal = 0;
                        }
                        this.programacao.publicidade[idx].contasContabeis[indice].marReal += cc.valor;
                      }
                    }
                  }
                  if(mesConta == '04'){
                    this.programacao.abrPubRealTotal += cc.valor;
                    let idx = this.programacao.publicidade.map((item:any) => {
                      return item.contaOrcamentaria;
                    }).indexOf(cc.contaOrcamentaria);
                    if(idx >= 0 ){
                      let indice = this.programacao.publicidade[idx].contasContabeis.map((itemConta:any) => {
                        return itemConta.conta;
                      }).indexOf(cc.contaContabil);
                      if(indice >= 0){
                        if(!this.programacao.publicidade[idx].contasContabeis[indice].abrReal){
                          this.programacao.publicidade[idx].contasContabeis[indice].abrReal = 0;
                        }
                        this.programacao.publicidade[idx].contasContabeis[indice].abrReal += cc.valor;
                      }
                    }
                  }
                  if(mesConta == '05'){
                    this.programacao.maiPubRealTotal += cc.valor;
                    let idx = this.programacao.publicidade.map((item:any) => {
                      return item.contaOrcamentaria;
                    }).indexOf(cc.contaOrcamentaria);
                    if(idx >= 0 ){
                      let indice = this.programacao.publicidade[idx].contasContabeis.map((itemConta:any) => {
                        return itemConta.conta;
                      }).indexOf(cc.contaContabil);
                      if(indice >= 0){
                        if(!this.programacao.publicidade[idx].contasContabeis[indice].maiReal){
                          this.programacao.publicidade[idx].contasContabeis[indice].maiReal = 0;
                        }
                        this.programacao.publicidade[idx].contasContabeis[indice].maiReal += cc.valor;
                      }
                    }
                  }
                  if(mesConta == '06'){
                    this.programacao.junPubRealTotal += cc.valor;
                    let idx = this.programacao.publicidade.map((item:any) => {
                      return item.contaOrcamentaria;
                    }).indexOf(cc.contaOrcamentaria);
                    if(idx >= 0 ){
                      let indice = this.programacao.publicidade[idx].contasContabeis.map((itemConta:any) => {
                        return itemConta.conta;
                      }).indexOf(cc.contaContabil);
                      if(indice >= 0){
                        if(!this.programacao.publicidade[idx].contasContabeis[indice].junReal){
                          this.programacao.publicidade[idx].contasContabeis[indice].junReal = 0;
                        }
                        this.programacao.publicidade[idx].contasContabeis[indice].junReal += cc.valor;
                      }
                    }
                  }
                  if(mesConta == '07'){
                    this.programacao.julPubRealTotal += cc.valor;
                    let idx = this.programacao.publicidade.map((item:any) => {
                      return item.contaOrcamentaria;
                    }).indexOf(cc.contaOrcamentaria);
                    if(idx >= 0 ){
                      let indice = this.programacao.publicidade[idx].contasContabeis.map((itemConta:any) => {
                        return itemConta.conta;
                      }).indexOf(cc.contaContabil);
                      if(indice >= 0){
                        if(!this.programacao.publicidade[idx].contasContabeis[indice].julReal){
                          this.programacao.publicidade[idx].contasContabeis[indice].julReal = 0;
                        }
                        this.programacao.publicidade[idx].contasContabeis[indice].julReal += cc.valor;
                      }
                    }
                  }
                  if(mesConta == '08'){
                    this.programacao.agoPubRealTotal += cc.valor;
                    let idx = this.programacao.publicidade.map((item:any) => {
                      return item.contaOrcamentaria;
                    }).indexOf(cc.contaOrcamentaria);
                    if(idx >= 0 ){
                      let indice = this.programacao.publicidade[idx].contasContabeis.map((itemConta:any) => {
                        return itemConta.conta;
                      }).indexOf(cc.contaContabil);
                      if(indice >= 0){
                        if(!this.programacao.publicidade[idx].contasContabeis[indice].agoReal){
                          this.programacao.publicidade[idx].contasContabeis[indice].agoReal = 0;
                        }
                        this.programacao.publicidade[idx].contasContabeis[indice].agoReal += cc.valor;
                      }
                    }
                  }
                  if(mesConta == '09'){
                    this.programacao.setPubRealTotal += cc.valor;
                    let idx = this.programacao.publicidade.map((item:any) => {
                      return item.contaOrcamentaria;
                    }).indexOf(cc.contaOrcamentaria);
                    if(idx >= 0 ){
                      let indice = this.programacao.publicidade[idx].contasContabeis.map((itemConta:any) => {
                        return itemConta.conta;
                      }).indexOf(cc.contaContabil);
                      if(indice >= 0){
                        if(!this.programacao.publicidade[idx].contasContabeis[indice].setReal){
                          this.programacao.publicidade[idx].contasContabeis[indice].setReal = 0;
                        }
                        this.programacao.publicidade[idx].contasContabeis[indice].setReal += cc.valor;
                      }
                    }
                  }
                  if(mesConta == '10'){
                    this.programacao.outPubRealTotal += cc.valor;
                    let idx = this.programacao.publicidade.map((item:any) => {
                      return item.contaOrcamentaria;
                    }).indexOf(cc.contaOrcamentaria);
                    if(idx >= 0 ){
                      let indice = this.programacao.publicidade[idx].contasContabeis.map((itemConta:any) => {
                        return itemConta.conta;
                      }).indexOf(cc.contaContabil);
                      if(indice >= 0){
                        if(!this.programacao.publicidade[idx].contasContabeis[indice].outReal){
                          this.programacao.publicidade[idx].contasContabeis[indice].outReal = 0;
                        }
                        this.programacao.publicidade[idx].contasContabeis[indice].outReal += cc.valor;
                      }
                    }
                  }
                  if(mesConta == '11'){
                    this.programacao.novPubRealTotal += cc.valor;
                    let idx = this.programacao.publicidade.map((item:any) => {
                      return item.contaOrcamentaria;
                    }).indexOf(cc.contaOrcamentaria);
                    if(idx >= 0 ){
                      let indice = this.programacao.publicidade[idx].contasContabeis.map((itemConta:any) => {
                        return itemConta.conta;
                      }).indexOf(cc.contaContabil);
                      if(indice >= 0){
                        if(!this.programacao.publicidade[idx].contasContabeis[indice].novReal){
                          this.programacao.publicidade[idx].contasContabeis[indice].novReal = 0;
                        }
                        this.programacao.publicidade[idx].contasContabeis[indice].novReal += cc.valor;
                      }
                    }
                  }
                  if(mesConta == '12'){
                    this.programacao.dezPubRealTotal += cc.valor;
                    let idx = this.programacao.publicidade.map((item:any) => {
                      return item.contaOrcamentaria;
                    }).indexOf(cc.contaOrcamentaria);
                    if(idx >= 0 ){
                      let indice = this.programacao.publicidade[idx].contasContabeis.map((itemConta:any) => {
                        return itemConta.conta;
                      }).indexOf(cc.contaContabil);
                      if(indice >= 0){
                        if(!this.programacao.publicidade[idx].contasContabeis[indice].dezReal){
                          this.programacao.publicidade[idx].contasContabeis[indice].dezReal = 0;
                        }
                        this.programacao.publicidade[idx].contasContabeis[indice].dezReal += cc.valor;
                      }
                    }
                  }
                }
              })
            }
          }
        })
      })
      this.somar('','','','','');
      this.anoOk = true;
        }else{
          ctrl++;
        }
      });
    })
})
}


somar(oCentroCusto:any,oContaOrcamentaria,oContaContabil:any,oValor:any,oMes:any){
  if(oCentroCusto != ''){
    let jsonDate = new Date().toJSON();
    let hora = new Date(jsonDate).toLocaleTimeString();
     let objLogs = {
      data: new Date(Date.now() - 10800000).toISOString().split('T')[0],
      hora: hora,
      anoProg: this.anoSelect,
      motivo:"",
      usuario: localStorage.getItem('usuarioVivamax'),
      alteracao: oCentroCusto+" - "+oContaOrcamentaria+" - "+oContaContabil+" - Mês: "+oMes+" | Valor: R$"+oValor
    }
    this.arrayLogs.push(objLogs);
  }
  this.programacao.janAdmPrevTotal = 0;
  this.programacao.fevAdmPrevTotal = 0;
  this.programacao.marAdmPrevTotal = 0;
  this.programacao.abrAdmPrevTotal = 0;
  this.programacao.maiAdmPrevTotal = 0;
  this.programacao.junAdmPrevTotal = 0;
  this.programacao.julAdmPrevTotal = 0;
  this.programacao.agoAdmPrevTotal = 0;
  this.programacao.setAdmPrevTotal = 0;
  this.programacao.outAdmPrevTotal = 0;
  this.programacao.novAdmPrevTotal = 0;
  this.programacao.dezAdmPrevTotal = 0;
  this.programacao.janComPrevTotal = 0;
  this.programacao.fevComPrevTotal = 0;
  this.programacao.marComPrevTotal = 0;
  this.programacao.abrComPrevTotal = 0;
  this.programacao.maiComPrevTotal = 0;
  this.programacao.junComPrevTotal = 0;
  this.programacao.julComPrevTotal = 0;
  this.programacao.agoComPrevTotal = 0;
  this.programacao.setComPrevTotal = 0;
  this.programacao.outComPrevTotal = 0;
  this.programacao.novComPrevTotal = 0;
  this.programacao.dezComPrevTotal = 0;
  this.programacao.janPubPrevTotal = 0;
  this.programacao.fevPubPrevTotal = 0;
  this.programacao.marPubPrevTotal = 0;
  this.programacao.abrPubPrevTotal = 0;
  this.programacao.maiPubPrevTotal = 0;
  this.programacao.junPubPrevTotal = 0;
  this.programacao.julPubPrevTotal = 0;
  this.programacao.agoPubPrevTotal = 0;
  this.programacao.setPubPrevTotal = 0;
  this.programacao.outPubPrevTotal = 0;
  this.programacao.novPubPrevTotal = 0;
  this.programacao.dezPubPrevTotal = 0;
  this.programacao.administrativo.forEach((itemAdm:any) => {
    itemAdm.contasContabeis.forEach((contaAdm:any) => {
    if(contaAdm.janPrev > 0){this.programacao.janAdmPrevTotal += contaAdm.janPrev;}
    if(contaAdm.fevPrev > 0){this.programacao.fevAdmPrevTotal += contaAdm.fevPrev;}
    if(contaAdm.marPrev > 0){this.programacao.marAdmPrevTotal += contaAdm.marPrev;}
    if(contaAdm.abrPrev > 0){this.programacao.abrAdmPrevTotal += contaAdm.abrPrev;}
    if(contaAdm.maiPrev > 0){this.programacao.maiAdmPrevTotal += contaAdm.maiPrev;}
    if(contaAdm.junPrev > 0){this.programacao.junAdmPrevTotal += contaAdm.junPrev;}
    if(contaAdm.julPrev > 0){this.programacao.julAdmPrevTotal += contaAdm.julPrev;}
    if(contaAdm.agoPrev > 0){this.programacao.agoAdmPrevTotal += contaAdm.agoPrev;}
    if(contaAdm.setPrev > 0){this.programacao.setAdmPrevTotal += contaAdm.setPrev;}
    if(contaAdm.outPrev > 0){this.programacao.outAdmPrevTotal += contaAdm.outPrev;}
    if(contaAdm.novPrev > 0){this.programacao.novAdmPrevTotal += contaAdm.novPrev;}
    if(contaAdm.dezPrev > 0){this.programacao.dezAdmPrevTotal += contaAdm.dezPrev;}
    })
  })
  this.programacao.comercial.forEach((itemCom:any) => {
    itemCom.contasContabeis.forEach((contaCom:any) => {
    if(contaCom.janPrev > 0){this.programacao.janComPrevTotal += contaCom.janPrev;}
    if(contaCom.fevPrev > 0){this.programacao.fevComPrevTotal += contaCom.fevPrev;}
    if(contaCom.marPrev > 0){this.programacao.marComPrevTotal += contaCom.marPrev;}
    if(contaCom.abrPrev > 0){this.programacao.abrComPrevTotal += contaCom.abrPrev;}
    if(contaCom.maiPrev > 0){this.programacao.maiComPrevTotal += contaCom.maiPrev;}
    if(contaCom.junPrev > 0){this.programacao.junComPrevTotal += contaCom.junPrev;}
    if(contaCom.julPrev > 0){this.programacao.julComPrevTotal += contaCom.julPrev;}
    if(contaCom.agoPrev > 0){this.programacao.agoComPrevTotal += contaCom.agoPrev;}
    if(contaCom.setPrev > 0){this.programacao.setComPrevTotal += contaCom.setPrev;}
    if(contaCom.outPrev > 0){this.programacao.outComPrevTotal += contaCom.outPrev;}
    if(contaCom.novPrev > 0){this.programacao.novComPrevTotal += contaCom.novPrev;}
    if(contaCom.dezPrev > 0){this.programacao.dezComPrevTotal += contaCom.dezPrev;}
    })
  })
  this.programacao.publicidade.forEach((itemPub:any) => {
    itemPub.contasContabeis.forEach((contaPub:any) => {
    if(contaPub.janPrev > 0){this.programacao.janPubPrevTotal += contaPub.janPrev;}
    if(contaPub.fevPrev > 0){this.programacao.fevPubPrevTotal += contaPub.fevPrev;}
    if(contaPub.marPrev > 0){this.programacao.marPubPrevTotal += contaPub.marPrev;}
    if(contaPub.abrPrev > 0){this.programacao.abrPubPrevTotal += contaPub.abrPrev;}
    if(contaPub.maiPrev > 0){this.programacao.maiPubPrevTotal += contaPub.maiPrev;}
    if(contaPub.junPrev > 0){this.programacao.junPubPrevTotal += contaPub.junPrev;}
    if(contaPub.julPrev > 0){this.programacao.julPubPrevTotal += contaPub.julPrev;}
    if(contaPub.agoPrev > 0){this.programacao.agoPubPrevTotal += contaPub.agoPrev;}
    if(contaPub.setPrev > 0){this.programacao.setPubPrevTotal += contaPub.setPrev;}
    if(contaPub.outPrev > 0){this.programacao.outPubPrevTotal += contaPub.outPrev;}
    if(contaPub.novPrev > 0){this.programacao.novPubPrevTotal += contaPub.novPrev;}
    if(contaPub.dezPrev > 0){this.programacao.dezPubPrevTotal += contaPub.dezPrev;}
    })
  })
}

openModalMotivo(){
  let modal = document.getElementById('motivoModal');
  modal.style.display = "block";
}

closeModalMotivo(){
  let modal = document.getElementById('motivoModal');
  modal.style.display = "none";
}

  openModalNovo(){
    this.anoOk = false;
    delete this.programacao;
    let modal = document.getElementById('novoAno');
    modal.style.display = "block";
  }

  closeModalNovo(){
    let modal = document.getElementById('novoAno');
    modal.style.display = "none";
    this.anoProg = "";
  }

  cadastrarNovaProgramacao(ano:any){
    let i = this.programacoes.map((item:any) => {
      return item.ano;
    }).indexOf(ano);
    if(i >= 0){
      this.app.openAlert("Já existe uma programação para o ano "+ano)
    }else{
      let dados = {
        ano: ano,
        prevPlanJan : 0,
        prevPlanFev : 0,
        prevPlanMar : 0,
        prevPlanAbr : 0,
        prevPlanMai : 0,
        prevPlanJun : 0,
        prevPlanJul : 0,
        prevPlanAgo : 0,
        prevPlanSet : 0,
        prevPlanOut : 0,
        prevPlanNov : 0,
        prevPlanDez : 0,
        prevCanJan : 0,
        prevCanFev : 0,
        prevCanMar : 0,
        prevCanAbr : 0,
        prevCanMai : 0,
        prevCanJun : 0,
        prevCanJul : 0,
        prevCanAgo : 0,
        prevCanSet : 0,
        prevCanOut : 0,
        prevCanNov : 0,
        prevCanDez : 0,
        prevReceitasJan : 0,
        prevReceitasFev : 0,
        prevReceitasMar : 0,
        prevReceitasAbr : 0,
        prevReceitasMai : 0,
        prevReceitasJun : 0,
        prevReceitasJul : 0,
        prevReceitasAgo : 0,
        prevReceitasSet : 0,
        prevReceitasOut : 0,
        prevReceitasNov : 0,
        prevReceitasDez : 0,
        prevGuiasJan : 0,
        prevGuiasFev : 0,
        prevGuiasMar : 0,
        prevGuiasAbr : 0,
        prevGuiasMai : 0,
        prevGuiasJun : 0,
        prevGuiasJul : 0,
        prevGuiasAgo : 0,
        prevGuiasSet : 0,
        prevGuiasOut : 0,
        prevGuiasNov : 0,
        prevGuiasDez : 0,
        prevRendimentosJan : 0,
        prevRendimentosFev : 0,
        prevRendimentosMar : 0,
        prevRendimentosAbr : 0,
        prevRendimentosMai : 0,
        prevRendimentosJun : 0,
        prevRendimentosJul : 0,
        prevRendimentosAgo : 0,
        prevRendimentosSet : 0,
        prevRendimentosOut : 0,
        prevRendimentosNov : 0,
        prevRendimentosDez : 0,
        administrativo:[],
        comercial:[],
        publicidade:[]
      }
      this.db.postProgramacao(dados).subscribe((res:any) => {
        console.log(res);
        this.app.openAlert("Programação Criada com Sucesso!");
        this.anoProg = "";
        this.anosProgramacao.push(ano)
        this.closeModalNovo();
      })
    }
  }

  salvarProgramacao(){
    let provId = this.programacao._id;
    delete this.programacao._id;
    let obj = {
        prevPlanJan : this.programacao.prevPlanJan,
        prevPlanFev : this.programacao.prevPlanFev,
        prevPlanMar : this.programacao.prevPlanMar,
        prevPlanAbr : this.programacao.prevPlanAbr,
        prevPlanMai : this.programacao.prevPlanMai,
        prevPlanJun : this.programacao.prevPlanJun,
        prevPlanJul : this.programacao.prevPlanJul,
        prevPlanAgo : this.programacao.prevPlanAgo,
        prevPlanSet : this.programacao.prevPlanSet,
        prevPlanOut : this.programacao.prevPlanOut,
        prevPlanNov : this.programacao.prevPlanNov,
        prevPlanDez : this.programacao.prevPlanDez,
        prevCanJan : this.programacao.prevCanJan,
        prevCanFev : this.programacao.prevCanFev,
        prevCanMar : this.programacao.prevCanMar,
        prevCanAbr : this.programacao.prevCanAbr,
        prevCanMai : this.programacao.prevCanMai,
        prevCanJun : this.programacao.prevCanJun,
        prevCanJul : this.programacao.prevCanJul,
        prevCanAgo : this.programacao.prevCanAgo,
        prevCanSet : this.programacao.prevCanSet,
        prevCanOut : this.programacao.prevCanOut,
        prevCanNov : this.programacao.prevCanNov,
        prevCanDez : this.programacao.prevCanDez,
        prevValorPlanJan : this.programacao.prevValorPlanJan,
        prevValorPlanFev : this.programacao.prevValorPlanFev,
        prevValorPlanMar : this.programacao.prevValorPlanMar,
        prevValorPlanAbr : this.programacao.prevValorPlanAbr,
        prevValorPlanMai : this.programacao.prevValorPlanMai,
        prevValorPlanJun : this.programacao.prevValorPlanJun,
        prevValorPlanJul : this.programacao.prevValorPlanJul,
        prevValorPlanAgo : this.programacao.prevValorPlanAgo,
        prevValorPlanSet : this.programacao.prevValorPlanSet,
        prevValorPlanOut : this.programacao.prevValorPlanOut,
        prevValorPlanNov : this.programacao.prevValorPlanNov,
        prevValorPlanDez : this.programacao.prevValorPlanDez,
        prevValorCanJan : this.programacao.prevValorCanJan,
        prevValorCanFev : this.programacao.prevValorCanFev,
        prevValorCanMar : this.programacao.prevValorCanMar,
        prevValorCanAbr : this.programacao.prevValorCanAbr,
        prevValorCanMai : this.programacao.prevValorCanMai,
        prevValorCanJun : this.programacao.prevValorCanJun,
        prevValorCanJul : this.programacao.prevValorCanJul,
        prevValorCanAgo : this.programacao.prevValorCanAgo,
        prevValorCanSet : this.programacao.prevValorCanSet,
        prevValorCanOut : this.programacao.prevValorCanOut,
        prevValorCanNov : this.programacao.prevValorCanNov,
        prevValorCanDez : this.programacao.prevValorCanDez,
        prevGuiasJan : this.programacao.prevGuiasJan,
        prevGuiasFev : this.programacao.prevGuiasFev,
        prevGuiasMar : this.programacao.prevGuiasMar,
        prevGuiasAbr : this.programacao.prevGuiasAbr,
        prevGuiasMai : this.programacao.prevGuiasMai,
        prevGuiasJun : this.programacao.prevGuiasJun,
        prevGuiasJul : this.programacao.prevGuiasJul,
        prevGuiasAgo : this.programacao.prevGuiasAgo,
        prevGuiasSet : this.programacao.prevGuiasSet,
        prevGuiasOut : this.programacao.prevGuiasOut,
        prevGuiasNov : this.programacao.prevGuiasNov,
        prevGuiasDez : this.programacao.prevGuiasDez,
        prevRendimentosJan : this.programacao.prevRendimentosJan,
        prevRendimentosFev : this.programacao.prevRendimentosFev,
        prevRendimentosMar : this.programacao.prevRendimentosMar,
        prevRendimentosAbr : this.programacao.prevRendimentosAbr,
        prevRendimentosMai : this.programacao.prevRendimentosMai,
        prevRendimentosJun : this.programacao.prevRendimentosJun,
        prevRendimentosJul : this.programacao.prevRendimentosJul,
        prevRendimentosAgo : this.programacao.prevRendimentosAgo,
        prevRendimentosSet : this.programacao.prevRendimentosSet,
        prevRendimentosOut : this.programacao.prevRendimentosOut,
        prevRendimentosNov : this.programacao.prevRendimentosNov,
        prevRendimentosDez : this.programacao.prevRendimentosDez,
        prevReceitasJan : this.programacao.prevReceitasJan,
        prevReceitasFev : this.programacao.prevReceitasFev,
        prevReceitasMar : this.programacao.prevReceitasMar,
        prevReceitasAbr : this.programacao.prevReceitasAbr,
        prevReceitasMai : this.programacao.prevReceitasMai,
        prevReceitasJun : this.programacao.prevReceitasJun,
        prevReceitasJul : this.programacao.prevReceitasJul,
        prevReceitasAgo : this.programacao.prevReceitasAgo,
        prevReceitasSet : this.programacao.prevReceitasSet,
        prevReceitasOut : this.programacao.prevReceitasOut,
        prevReceitasNov : this.programacao.prevReceitasNov,
        prevReceitasDez : this.programacao.prevReceitasDez
      }
      for(let i = 0; i < this.programacao.administrativo.length; i++){
        this.programacao.administrativo[i].contasContabeis.forEach((obj) => {
          delete obj['janReal'];
          delete obj['fevReal'];
          delete obj['marReal'];
          delete obj['abrReal'];
          delete obj['maiReal'];
          delete obj['junReal'];
          delete obj['julReal'];
          delete obj['agoReal'];
          delete obj['setReal'];
          delete obj['outReal'];
          delete obj['novReal'];
          delete obj['dezReal'];
        });
      }
      for(let i = 0; i < this.programacao.comercial.length; i++){
        this.programacao.comercial[i].contasContabeis.forEach((obj) => {
          delete obj['janReal'];
          delete obj['fevReal'];
          delete obj['marReal'];
          delete obj['abrReal'];
          delete obj['maiReal'];
          delete obj['junReal'];
          delete obj['julReal'];
          delete obj['agoReal'];
          delete obj['setReal'];
          delete obj['outReal'];
          delete obj['novReal'];
          delete obj['dezReal'];
        });
      }
      for(let i = 0; i < this.programacao.publicidade.length; i++){
        this.programacao.publicidade[i].contasContabeis.forEach((obj) => {
          delete obj['janReal'];
          delete obj['fevReal'];
          delete obj['marReal'];
          delete obj['abrReal'];
          delete obj['maiReal'];
          delete obj['junReal'];
          delete obj['julReal'];
          delete obj['agoReal'];
          delete obj['setReal'];
          delete obj['outReal'];
          delete obj['novReal'];
          delete obj['dezReal'];
        });
      }
      this.closeModalMotivo();
      this.db.patchProgramacao(provId,obj).subscribe((res:any) => {
       console.log(res);
          this.db.patchProgramacaoAdm(provId,this.programacao.administrativo).subscribe((res:any) => {
            console.log(res);
              this.db.patchProgramacaoCom(provId,this.programacao.comercial).subscribe((res:any) => {
                console.log(res);
                  this.db.patchProgramacaoPub(provId,this.programacao.publicidade).subscribe((res:any) => {
                    console.log(res);
                    this.confereAno();
                    if(this.arrayLogs.length > 0){
                      this.arrayLogs.forEach((l:any) => {
                        l.motivo = this.motivo;
                        this.db.postLogsProgramacao(l).subscribe((resLogs:any) => {
                          console.log(resLogs)
                        }, (err:any) => {
                          console.log(err);
                        })
                      })
                    }
                    this.app.openAlert("Programação Salva com Sucesso!");
                  }, (err:any) => {
                    console.log(err);
                  })
              }, (err:any) => {
                console.log(err);
              })
            }, (err:any) => {
              console.log(err);
            })
      }, (err:any) => {
        console.log(err);
      })
    }
  
  print(){
    let divToPrint = document.getElementById('divToPrint').innerHTML;
    let newWindow = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    newWindow.document.open();
    newWindow.document.write(`
    <html>
        <head>
          <title>Viva Max - Programação</title>
          <link rel="stylesheet" type="text/css" href="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css">
          <script type="text/javascript" src="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/js/bootstrap.min.js"></script>
        </head>
        <body onload="window.print()">${divToPrint}   
        </body>
      </html>
    `);
    newWindow.document.close();
  }

  printXsl(){
    var ws = XLSX.utils.table_to_sheet(document.getElementById('divToPrint'),{raw:true});
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'programacao.xlsx',{cellStyles:true});
  }

/*   separar(itens:any, maximo:any){
    return itens.reduce((acumulador, item, indice) => {
      const grupo = Math.floor(indice / maximo);
      acumulador[grupo] = [...(acumulador[grupo] || []), item];
      return acumulador;
    }, []);
  }; */
  
  
}
