import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppComponent } from '../app.component';
import { DataBaseService } from '../provider.service';

@Component({
  selector: 'app-centro-custo',
  templateUrl: './centro-custo.component.html',
  styleUrls: ['./centro-custo.component.css']
})

export class CentroCustoComponent implements OnInit {
  dataHoje = new Date(Date.now() - 10800000).toISOString().split('T')[0];
  centrosCusto:any = [];
  conta:any = [];
  nomeContaOrcamentaria:any = "";
  ccAdministrativo:boolean = false;
  ccComercial:boolean = false;
  ccPublicidade:boolean = false;
  contaOrcamentariaEditar:FormGroup;
  public contaOrcamentariaListEditar: FormArray;
  get contaOrcamentariaFormGroupEditar(){return this.contaOrcamentariaEditar.get('orcamentariaEditar') as FormArray;}
  contaOrcamentaria:FormGroup;
  public contaOrcamentariaList: FormArray;
  get contaOrcamentariaFormGroup(){return this.contaOrcamentaria.get('orcamentaria') as FormArray;}

  constructor(public db:DataBaseService, public formBuilder: FormBuilder, public app:AppComponent) { }

  ngOnInit(): void {
   this.getCCs();
   this.contaOrcamentariaEditar = this.formBuilder.group({
      orcamentariaEditar: this.formBuilder.array([])
    });
    this.contaOrcamentariaListEditar = this.contaOrcamentariaEditar.get('orcamentariaEditar') as FormArray;
    this.contaOrcamentaria = this.formBuilder.group({
      orcamentaria: this.formBuilder.array([])
    });
    this.contaOrcamentariaList = this.contaOrcamentaria.get('orcamentaria') as FormArray;
  }

  getCCs(){
    this.db.getCentrosCusto().subscribe((data:any) => {
      this.centrosCusto = data;
    })
  }

  centroCusto(e:any,cOrcamentaria:any){
    let idx = e.srcElement.id.split('-');
    let id = this.centrosCusto[idx[1]]._id;
    let checked = e.srcElement.checked;
    this.centrosCusto[idx[1]]['centrosCusto'][idx[0]] = checked;
    delete this.centrosCusto[idx[1]]._id;
    this.db.patchCentroCusto(id,this.centrosCusto[idx[1]]).subscribe(res => {
      console.log(res);
      let jsonDate = new Date().toJSON();
      let hora = new Date(jsonDate).toLocaleTimeString();
      let body = {
        "menu" : "CENTRO DE CUSTOS", 
        "descricao" : "Centro de Custo Alterado: "+cOrcamentaria, 
        "data" : this.dataHoje, 
        "hora" : hora, 
        "usuario" : localStorage.getItem('usuarioVivamax')
        }
        this.db.postLogs(body).subscribe(res => {
          console.log(res);
          this.getCCs();
      }, err => {
        console.log(err);
      });
    }, err => {
      console.log(err);
    });
  }
  
  createConta():FormGroup{
    return this.formBuilder.group({
      conta: [null, Validators.compose([Validators.required])],
      programacao : [true, Validators.compose([Validators.required])]
    });
  }
  
  addConta(){
    this.contaOrcamentariaList.push(this.createConta());
  }

  removeConta(index) {
    this.contaOrcamentariaList.removeAt(index);
  }

  createContaEditar():FormGroup{
    return this.formBuilder.group({
      conta: [null, Validators.compose([Validators.required])],
      programacao : [true, Validators.compose([Validators.required])]
    });
  }
  
  addContaEditar(){
    this.contaOrcamentariaListEditar.push(this.createContaEditar());
  }

  removeContaEditar(index) {
    this.contaOrcamentariaListEditar.removeAt(index);
  }

  createContaStartEditar(item):FormGroup{
    return this.formBuilder.group({
      conta: item.conta,
      programacao: item.programacao
    });
  }

  addContaStartEditar(item){
    this.contaOrcamentariaListEditar.push(this.createContaStartEditar(item));
  }

  openModalEditar(cc:any){
    this.conta = cc;
    this.conta.contasContabeis.forEach((item:any) => {
      this.addContaStartEditar(item);
    })
    let modal = document.getElementById('modalContaOrcamentariaEditar');
    modal.style.display = "block";
  }

  closeModalEditar(){
    let modal = document.getElementById('modalContaOrcamentariaEditar');
    modal.style.display = "none";
    this.conta = [];
    this.contaOrcamentariaListEditar.clear();
    this.getCCs();
  }
  
  checkCCEditar(e:any){
    let idx = e.srcElement.id.split('-');
    let key = idx[0][0].toUpperCase() + idx[0].substring(1);
    let checked = e.srcElement.checked;
    this.conta['centrosCusto'][key] = checked;
  }

  editarContaOrcamentaria(){
    if(window.confirm("Deseja realmente Editar essa Conta Orçamentária?")){
      this.conta.contasContabeis = this.contaOrcamentariaListEditar.value;
      let id = this.conta._id;
      let idx = this.centrosCusto.map((item:any) => {
        return item.contaOrcamentaria;
      }).indexOf(this.conta.contaOrcamentaria);
      this.centrosCusto[idx] = this.conta;
      delete this.centrosCusto[idx]._id;
      this.db.patchCentroCusto(id,this.centrosCusto[idx]).subscribe(res => {
        console.log(res);
        let jsonDate = new Date().toJSON();
        let hora = new Date(jsonDate).toLocaleTimeString();
        let body = {
          "menu" : "CENTRO DE CUSTOS", 
          "descricao" : "Conta Orçamentaria Alterado: "+this.conta.contaOrcamentaria, 
          "data" : this.dataHoje, 
          "hora" : hora, 
          "usuario" : localStorage.getItem('usuarioVivamax')
          }
          this.db.postLogs(body).subscribe(res => {
            console.log(res);
            this.app.openAlert("Conta Orçamentária Alterada com Sucesso");
            this.closeModalEditar();
          }, err => {
            console.log(err);
          });
      }, err => {
        console.log(err);
      });
    }
  }

  excluirCC(cc:any){
    if(window.confirm("Deseja realmente excluir essa Conta Orçamentária?")){
      this.db.deleteContaOrcamentaria(cc._id).subscribe(res => {
        console.log(res);
        let jsonDate = new Date().toJSON();
        let hora = new Date(jsonDate).toLocaleTimeString();
        let body = {
          "menu" : "CENTRO DE CUSTOS", 
          "descricao" : "Conta Orçamentaria Excluida: "+cc.contaOrcamentaria, 
          "data" : this.dataHoje, 
          "hora" : hora, 
          "usuario" : localStorage.getItem('usuarioVivamax')
          }
          this.db.postLogs(body).subscribe(res => {
            console.log(res);
            this.app.openAlert("Conta Orçamentária Excluida com Sucesso");
            this.getCCs();
          }, err => {
            console.log(err)
          })
      }, err => {
        console.log(err)
      })
    }
  }

  openModalNovo(){
    this.addConta();
    let modal = document.getElementById('modalContaOrcamentaria');
    modal.style.display = "block";
  }

  closeModalNovo(){
    let modal = document.getElementById('modalContaOrcamentaria');
    modal.style.display = "none";
    this.contaOrcamentariaList.clear();
    this.ccAdministrativo = false;
    this.ccComercial = false;
    this.ccPublicidade = false;
    this.nomeContaOrcamentaria = "";
    this.getCCs();
  }  

  novaContaOrcamentaria(){
    if(window.confirm("Deseja realmente Cadastrar essa Conta Orçamentária?")){
      let obj = {
          centrosCusto : {
            Administrativo : this.ccAdministrativo,
            Comercial : this.ccComercial,
            Publicidade : this.ccPublicidade
          },
          contaOrcamentaria : this.nomeContaOrcamentaria,
          contasContabeis : this.contaOrcamentariaList.value
      }
      this.db.postCentroCusto(obj).subscribe(res => {
        console.log(res);
        let jsonDate = new Date().toJSON();
        let hora = new Date(jsonDate).toLocaleTimeString();
        let body = {
          "menu" : "CENTRO DE CUSTOS", 
          "descricao" : "Conta Orçamentaria Criada: "+this.nomeContaOrcamentaria, 
          "data" : this.dataHoje, 
          "hora" : hora, 
          "usuario" : localStorage.getItem('usuarioVivamax')
          }
          this.db.postLogs(body).subscribe(res => {
            console.log(res);
            this.app.openAlert("Conta Orçamentária Criada com Sucesso");
            this.closeModalNovo();
        }, err => {
          console.log(err);
        })
      }, err => {
        console.log(err);
      })
    }
  }

}
