import { Component, OnInit, ViewChild} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppComponent } from '../app.component';
import { DataBaseService } from '../provider.service';

@Component({
  selector: 'app-planos',
  templateUrl: './planos.component.html',
  styleUrls: ['./planos.component.css']
})
export class PlanosComponent implements OnInit {
  @ViewChild('pdfViewerPlanos') pdfViewerOnDemand;
  user:any = "";
  dataHoje:any = "";
  tipo:any = "";
  nome:any = "";
  mensalidade:any = 0;
  adesao:any = 0;
  planos:any = [];
  idPlano:any = "";
  tipoEditar:any = "";
  nomeEditar:any = "";
  mensalidadeAntiga:number = 0;
  capitalSeguradoAntigo:number = 0;
  valorFuneralConjugeAntigo:number = 0;
  valorFuneralFilhosMaiorAntigo:number = 0;
  valorFuneralExtraAntigo:number = 0;
  mensalidadeEditar:any = 0;
  adesaoEditar:any = 0;
  funeralConjuge:any = "";
  funeralFilhosMenor:any = "";
  funeralFilhosMaior:any = "";
  funeralExtra:any = "";
  ordemSite:number = 0;
  ordemSiteEditar:number = 0;
  valorFuneralConjuge:number = 0;
  valorFuneralFilhosMenor:number = 0;
  valorFuneralFilhosMaior:number = 0;
  valorFuneralExtra:number = 0;
  capitalSegurado:any = "";
  valorCapitalSegurado:number = 0;
  funeralConjugeEditar:any = "";
  funeralFilhosMenorEditar:any = "";
  funeralFilhosMaiorEditar:any = "";
  funeralExtraEditar:any = "";
  valorFuneralConjugeEditar:number = 0;
  valorFuneralFilhosMenorEditar:number = 0;
  valorFuneralFilhosMaiorEditar:number = 0;
  valorFuneralExtraEditar:number = 0;
  capitalSeguradoEditar:any = "";
  valorCapitalSeguradoEditar:number = 0;
  editaMensalidade:any = "";
  editaAdesao:any = "";
  editaTotal:any = "";
  editaMensalidadeEditar:any = "";
  editaAdesaoEditar:any = "";
  editaTotalEditar:any = "";
  contrato:any = "";
  filesContrato:any = [];
  filesContratoEditar:any = [];
  selectedFilesContrato:any = "";
  selectedFilesContratoEditar:any = "";
  limiteASF:number = null;
  limiteASFEditar:number = null;
  comissao:number = 0;
  comissaoEditar:number = 0;
  areaCliente:boolean = false;
  areaClienteEditar:boolean = false;
  hibrido:boolean = false;
  hibridoEditar:boolean = false;
  corretoraStarr:boolean = false;
  corretoraStarrEditar:boolean = false;
  codigoPlano:number = 0;
  codigoPlanoEditar:number = 0;
  planoVendas:boolean = true;
  planoVendasEditar:boolean = true;
  temDependentesExtra:any = "SIM";
  temDependentesExtraEditar:any = "SIM";
  empresaOrigem:any = "";
  empresaOrigemEditar:any = "";
  descricoes:FormGroup;
  conectaInclusos:number = 0;
  valorConectaExtra:number = 0;
  maxDependentesConecta:number = 0;
  conectaInclusosEditar:number = 0;
  valorConectaExtraEditar:number = 0;
  maxDependentesConectaEditar:number = 0;
  public descricoesList: FormArray;
  get descricoesFormGroup(){return this.descricoes.get('descricao') as FormArray;}
  descricoesEditar:FormGroup;
  public descricoesEditarList: FormArray;
  get descricoesEditarFormGroup(){return this.descricoesEditar.get('descricao') as FormArray;}
  
  constructor(public db:DataBaseService, private formBuilder: FormBuilder, public app:AppComponent){
    this.user = localStorage.getItem('usuarioVivamax');
    this.dataHoje = new Date(Date.now() - 10800000).toISOString().split('T')[0];
  }

  ngOnInit(){
    this.db.getPlanos().subscribe((data:any) => {
      this.planos = data;
  })
    this.descricoes= this.formBuilder.group({
      descricao: this.formBuilder.array([this.createDescricao()])
    });
    this.descricoesList = this.descricoes.get('descricao') as FormArray;
    this.descricoesEditar = this.formBuilder.group({
      descricao: this.formBuilder.array([])
    });
    this.descricoesEditarList = this.descricoesEditar.get('descricao') as FormArray;
  }
  
  mostrarTodosPlanos(){
    this.db.getTodosPlanos().subscribe((data:any) => {
      this.planos = data;
    })
  }

  mostraPlanosAtivos(){
    this.db.getPlanos().subscribe((data:any) => {
      this.planos = data;
    })
  }

  createDescricaoStart(item):FormGroup{
    return this.formBuilder.group({
      descricao: [item.descricao, Validators.compose([Validators.required])]
    });
  }

  addDescricaoStart(item){
    this.descricoesList.push(this.createDescricaoStart(item));
  }
  
  createDescricao():FormGroup{
    return this.formBuilder.group({
      descricao: [null, Validators.compose([Validators.required])]
    });
  }

  addDescricao(){
    this.descricoesList.push(this.createDescricao());
  }

  removeDescricao(index) {
    this.descricoesList.removeAt(index);
  }

  createDescricaoEditarStart(item):FormGroup{
    return this.formBuilder.group({
      descricao: [item.descricao, Validators.compose([Validators.required])]
    });
  }

  addDescricaoEditarStart(item){
    this.descricoesEditarList.push(this.createDescricaoEditarStart(item));
  }
  
  createDescricaoEditar():FormGroup{
    return this.formBuilder.group({
      descricao: [null, Validators.compose([Validators.required])]
    });
  }

  addDescricaoEditar(){
    this.descricoesEditarList.push(this.createDescricaoEditar());
  }

  removeDescricaoEditar(index) {
    this.descricoesEditarList.removeAt(index);
  }

  openModal(){
    let modal = document.getElementById('modalNovoPlano');
    modal.style.display = "block";
    this.descricoes = this.formBuilder.group({
      descricao: this.formBuilder.array([])
    });
    this.descricoesList = this.descricoes.get('descricao') as FormArray;
    this.addDescricao();
  }

  closeModal(){
    this.tipo = "";
    this.nome = "";
    this.mensalidade = 0;
    this.adesao = 0;
    this.funeralConjuge = "";
    this.funeralFilhosMenor = "";
    this.funeralFilhosMaior = "";
    this.funeralExtra = "";
    this.comissao = null;
    this.valorFuneralConjuge = null;
    this.valorFuneralFilhosMenor = null;
    this.valorFuneralFilhosMaior = null;
    this.valorFuneralExtra = null;
    this.capitalSegurado = "";
    this.valorCapitalSegurado = null; 
    this.editaMensalidade = "";
    this.editaAdesao = "";
    this.editaTotal = "";
    this.contrato = "";
    this.filesContrato = [];
    this.selectedFilesContrato = [];
    this.temDependentesExtra = "SIM";
    this.areaCliente = false;
    this.codigoPlano = null;
    this.empresaOrigem = "";
    this.ordemSite = null;
    this.planoVendas = true;
    this.limiteASF = null;
    this.corretoraStarr = false;
    this.conectaInclusos = 0;
    this.valorConectaExtra = 0;
    this.maxDependentesConecta = 0;
    let modal = document.getElementById('modalNovoPlano');
    modal.style.display = "none";
  }

  openEditar(plano:any){
    this.idPlano = plano._id;
    this.tipoEditar = plano.tipo;
    this.nomeEditar = plano.nome;
    this.empresaOrigemEditar = plano.empresaOrigem;
    this.mensalidadeEditar = plano.mensalidade;
    this.mensalidadeAntiga = this.mensalidadeEditar;
    this.adesaoEditar = plano.adesao;
    this.planoVendasEditar = plano.planoVendas;
    this.funeralConjugeEditar = plano.funeralConjuge;
    this.funeralFilhosMenorEditar = plano.funeralFilhosMenor;
    this.funeralFilhosMaiorEditar = plano.funeralFilhosMaior;
    this.funeralExtraEditar = plano.funeralExtra;
    this.capitalSeguradoEditar = plano.capitalSegurado;
    this.valorFuneralConjugeEditar = plano.valorFuneralConjuge;
    this.valorFuneralFilhosMenorEditar = plano.valorFuneralFilhosMenor;
    this.valorFuneralFilhosMaiorEditar = plano.valorFuneralFilhosMaior;
    this.valorFuneralExtraEditar = plano.valorFuneralExtra;
    this.valorCapitalSeguradoEditar = plano.valorCapitalSegurado;
    this.capitalSeguradoAntigo = this.valorCapitalSeguradoEditar;
    this.valorFuneralConjugeAntigo = this.valorFuneralConjugeEditar;
    this.valorFuneralFilhosMaiorAntigo = this.valorFuneralFilhosMaiorEditar;
    this.valorFuneralExtraAntigo = this.valorFuneralExtraEditar;
    this.comissaoEditar = plano.comissao;
    this.editaMensalidadeEditar = plano.editaMensalidade;
    this.temDependentesExtraEditar = plano.temDependentesExtra;
    this.editaAdesaoEditar = plano.editaAdesao;
    this.editaTotalEditar = plano.editaTotal;
    this.codigoPlanoEditar = plano.codigoPlano;
    this.contrato = plano.contrato;
    this.areaClienteEditar = plano.areaCliente;
    this.hibridoEditar = plano.hibrido;
    this.ordemSiteEditar = plano.ordem;
    this.limiteASFEditar = plano.limiteASF;
    this.corretoraStarrEditar = plano.corretoraStarr;
    let descricoesEditar:any = plano.descricoes ? plano.descricoes : [];
    this.descricoesEditar = this.formBuilder.group({
      descricao: this.formBuilder.array([])
    });
    this.descricoesEditarList = this.descricoesEditar.get('descricao') as FormArray;
    if(descricoesEditar != undefined && descricoesEditar.length > 0){
      descricoesEditar.forEach((item) => {
        this.addDescricaoEditarStart(item);
      })
    }else{
      this.addDescricaoEditar();
    }
    let modal = document.getElementById('modalEditarPlano');
    modal.style.display = "block";
  }

  closeModalEditar(){
    this.contrato = "";
    this.idPlano = "";
    this.tipoEditar = "";
    this.nomeEditar = "";
    this.empresaOrigemEditar = "";
    this.mensalidadeEditar = 0;
    this.adesaoEditar = 0;
    this.funeralConjugeEditar = "";
    this.funeralFilhosMenorEditar = "";
    this.funeralFilhosMaiorEditar = "";
    this.funeralExtraEditar = "";
    this.planoVendasEditar = true;
    this.valorFuneralConjugeEditar = null;
    this.valorFuneralFilhosMenorEditar = null;
    this.valorFuneralFilhosMaiorEditar = null;
    this.valorFuneralExtraEditar = null;
    this.capitalSeguradoEditar = "";
    this.valorCapitalSeguradoEditar = null; 
    this.comissaoEditar = null;
    this.editaMensalidadeEditar = "";
    this.editaAdesaoEditar = "";
    this.editaTotalEditar = "";
    this.filesContratoEditar = []
    this.selectedFilesContratoEditar = [];
    this.areaClienteEditar = false;
    this.descricoesEditar = null;
    this.codigoPlanoEditar = null;
    this.ordemSiteEditar = null;
    this.limiteASFEditar = null;
    this.corretoraStarrEditar = false;
    this.temDependentesExtraEditar = "SIM";
    this.conectaInclusosEditar = 0;
    this.valorConectaExtraEditar = 0;
    this.maxDependentesConectaEditar = 0;
    let modal = document.getElementById('modalEditarPlano');
    modal.style.display = "none"
  }
  
  salvarEditar(){
    if(window.confirm("Deseja realmente editar esse plano?")){
    let jsonDate = new Date().toJSON();
    let hora = new Date(jsonDate).toLocaleTimeString();
    if(this.valorFuneralConjugeEditar == null){this.valorFuneralConjugeEditar = 0;}
    if(this.valorFuneralFilhosMenorEditar == null){this.valorFuneralFilhosMenorEditar = 0;}
    if(this.valorFuneralFilhosMaiorEditar == null){this.valorFuneralFilhosMaiorEditar = 0;}
    if(this.valorCapitalSeguradoEditar == null){this.valorCapitalSeguradoEditar = 0;}
    if(this.comissaoEditar == null){this.comissaoEditar = 0;}
    let obj = {
      tipo:this.tipoEditar,
      nome:this.nomeEditar,
      mensalidade:this.mensalidadeEditar,
      planoVendas:this.planoVendasEditar,
      adesao:this.adesaoEditar,
      temDependentesExtra: this.temDependentesExtraEditar,
      funeralConjuge: this.funeralConjugeEditar,
      funeralFilhosMenor: this.funeralFilhosMenorEditar,
      funeralFilhosMaior: this.funeralFilhosMaiorEditar,
      funeralExtra: this.funeralExtraEditar,
      empresaOrigem:this.empresaOrigemEditar,
      valorFuneralConjuge: this.valorFuneralConjugeEditar,
      valorFuneralFilhosMenor: this.valorFuneralFilhosMenorEditar,
      valorFuneralFilhosMaior: this.valorFuneralFilhosMaiorEditar,
      valorFuneralExtra: this.valorFuneralExtraEditar,
      capitalSegurado: this.capitalSeguradoEditar,
      valorCapitalSegurado: this.valorCapitalSeguradoEditar,
      editaMensalidade : this.editaMensalidadeEditar,
      editaAdesao : this.editaAdesaoEditar,
      editaTotal: this.editaTotalEditar,
      contrato: this.contrato,
      comissao: this.comissaoEditar,
      descricoes: this.descricoesEditarList.value,
      areaCliente:this.areaClienteEditar,
      hibrido:this.hibridoEditar,
      codigoPlano:this.codigoPlanoEditar,
      ordem:this.ordemSiteEditar,
      limiteASF: this.limiteASFEditar,
      corretoraStarr: this.corretoraStarrEditar,
      conectaInclusos: this.conectaInclusosEditar,
      valorConectaExtra: this.valorConectaExtraEditar,
      maxDependentesConecta: this.maxDependentesConectaEditar
    }
    this.db.patchPlanos(this.idPlano,obj).subscribe(res => {
      console.log(res);
      if(this.mensalidadeEditar != this.mensalidadeAntiga || this.capitalSeguradoAntigo != this.valorCapitalSeguradoEditar || this.valorFuneralConjugeAntigo != this.valorFuneralConjugeEditar || this.valorFuneralFilhosMaiorAntigo != this.valorFuneralFilhosMaiorEditar || this.valorFuneralExtraAntigo != this.valorFuneralExtraEditar){
        this.db.getAssociadoByPlano(this.nomeEditar).subscribe((data:any) => {
          if(data.length > 0){
            let usersComPlano = data;
            let dados:any;
            let controlAssociados = 1;
            usersComPlano.forEach((associado:any) => {
              if(!associado.valorPersonalizado){
              setTimeout(() => {
              let controlDependentes = 1;
              let controlDependentesExtra = 1;
              let totalAsfDependentes = 0;
              let totalAsfDependentesExtras = 0;
              let totalMensalidade = 0;
              if(associado.dependentes.length > 0){
                  associado.dependentes.forEach((item:any) => {
                    if(item.tipo == "CÔNJUGE"){
                      if(this.funeralConjugeEditar == "AUTOMATICO"){
                        item.asf = "FUNERAL AUTOMÁTICO VITALÍCIO"
                      }
                    }else{
                      if(this.funeralFilhosMaiorEditar == "AUTOMATICO"){
                        item.asf = "FUNERAL AUTOMÁTICO VITALÍCIO"
                      }
                    }
                  if(item.asf == "FUNERAL CONTRATADO" && item.tipo != "CÔNJUGE" && item.situacao == "ATIVO"){
                    if(this.funeralFilhosMaiorEditar == "SIM"){
                      totalAsfDependentes += this.valorFuneralFilhosMaiorEditar;
                    }
                  }
                  if(item.asf == "FUNERAL CONTRATADO" && item.tipo == "CÔNJUGE" && item.situacao == "ATIVO"){
                    if(this.funeralConjugeEditar == "SIM"){
                      totalAsfDependentes += this.valorFuneralConjugeEditar;
                    }
                  }
                  if(controlDependentes == associado.dependentes.length){
                    if(associado.dependentesExtra.length > 0){
                        associado.dependentesExtra.forEach((itemExtra) => {
                          if(this.funeralExtraEditar == "AUTOMATICO"){
                            itemExtra.asf = "AUTOMÁTICO SEM CUSTO"
                          }
                        if(itemExtra.asf == "FUNERAL CONTRATADO" && item.situacao == "ATIVO"){ 
                          totalAsfDependentesExtras += this.valorFuneralExtraEditar;
                        }
                        if(controlDependentesExtra == associado.dependentesExtra.length){
                          totalMensalidade = totalAsfDependentes + totalAsfDependentesExtras + this.mensalidadeEditar;
                          dados = {
                            mensalidade:this.mensalidadeEditar,
                            valorCapitalSegurado: this.valorCapitalSeguradoEditar,
                            totalMensalidade: totalMensalidade
                          }
                          this.db.patchAssociado(associado._id,dados).subscribe(res => {
                            console.log(res);
                            let body = {
                              "menu" : "EDITAR PLANOS", 
                              "descricao" : "Valor Total da Mensalidade do Plano do Associado: "+associado.nome+" foi alterado para R$"+totalMensalidade, 
                              "data" : this.dataHoje, 
                              "hora" : hora, 
                              "usuario" : this.user
                            }
                            this.db.postLogs(body).subscribe(res => console.log(res), err => console.log(err))
                          }, err => {
                            console.log(err)
                          })
                        }else{
                          controlDependentesExtra++;
                        }
                      })
                    }else{
                      totalMensalidade = totalAsfDependentes + this.mensalidadeEditar;
                      dados = {
                        mensalidade:this.mensalidadeEditar,
                        valorCapitalSegurado: this.valorCapitalSeguradoEditar,
                        totalMensalidade: totalMensalidade
                      }
                      this.db.patchAssociado(associado._id,dados).subscribe(res => {
                        console.log(res);
                        let body = {
                          "menu" : "EDITAR PLANOS", 
                          "descricao" : "Valor Total da Mensalidade do Plano do Associado: "+associado.nome+" foi alterado para R$"+totalMensalidade, 
                          "data" : this.dataHoje, 
                          "hora" : hora, 
                          "usuario" : this.user
                        }
                        this.db.postLogs(body).subscribe(res => console.log(res), err => console.log(err))
                      }, err => {
                        console.log(err)
                      })
                    }
                  }else{
                    controlDependentes++;
                  }
                })
              }else{
                if(associado.dependentesExtra.length > 0){
                  associado.dependentesExtra.forEach((itExtra) => {
                    if(this.funeralExtraEditar == "AUTOMATICO"){
                      itExtra.asf = "AUTOMÁTICO SEM CUSTO"
                    }
                  if(itExtra.asf == "FUNERAL CONTRATADO" && itExtra.situacao == "ATIVO"){
                    totalAsfDependentesExtras += this.valorFuneralExtraEditar;
                  }
                  if(controlDependentesExtra == associado.dependentesExtra.length){
                    totalMensalidade = totalAsfDependentesExtras + this.mensalidadeEditar;
                    dados = {
                      mensalidade:this.mensalidadeEditar,
                      valorCapitalSegurado: this.valorCapitalSeguradoEditar,
                      totalMensalidade: totalMensalidade
                    }
                    this.db.patchAssociado(associado._id,dados).subscribe(res => {
                      console.log(res);
                      let body = {
                        "menu" : "EDITAR PLANOS", 
                        "descricao" : "Valor Total da Mensalidade do Plano do Associado: "+associado.nome+" foi alterado para R$"+totalMensalidade, 
                        "data" : this.dataHoje, 
                        "hora" : hora, 
                        "usuario" : this.user
                      }
                      this.db.postLogs(body).subscribe(res => console.log(res), err => console.log(err))
                    }, err => {
                      console.log(err)
                    })
                  }else{
                    controlDependentesExtra++;
                  }
                })
              }else{
                dados = {
                  mensalidade:this.mensalidadeEditar,
                  valorCapitalSegurado: this.valorCapitalSeguradoEditar,
                  totalMensalidade: this.mensalidadeEditar
                }
                this.db.patchAssociado(associado._id,dados).subscribe(res => {
                  console.log(res);
                  let body = {
                    "menu" : "EDITAR PLANOS", 
                    "descricao" : "Valor Total da Mensalidade do Plano do Associado: "+associado.nome+" foi alterado para R$"+totalMensalidade, 
                    "data" : this.dataHoje, 
                    "hora" : hora, 
                    "usuario" : this.user
                  }
                  this.db.postLogs(body).subscribe(res => console.log(res), err => console.log(err))
                }, err => {
                  console.log(err)
                })
              }
            }
            if(usersComPlano.length == controlAssociados){
              let corpo = {
                "menu" : "EDITAR PLANOS", 
                "descricao" : "Plano: "+this.nomeEditar+" editado.",
                "data" : this.dataHoje, 
                "hora" : hora, 
                "usuario" : this.user
              }
              this.db.postLogs(corpo).subscribe(res => console.log(res), err => console.log(err))
              this.app.openAlert("Plano editado com Sucesso!")
              this.closeModalEditar();
              this.ngOnInit();
            }else{
              controlAssociados++;
            }
              })
        }
        },200)
          }else{
            this.app.openAlert("Plano editado com Sucesso!")
            this.closeModalEditar();
            this.ngOnInit();
          }
        })
      }else{
        let bodyLogs = {
          "menu" : "EDITAR PLANOS", 
          "descricao" : "Plano editado: "+this.nomeEditar, 
          "data" : this.dataHoje, 
          "hora" : hora, 
          "usuario" : this.user
        }
        this.db.postLogs(bodyLogs).subscribe(rres => {
          console.log(rres);
          this.app.openAlert("Plano editado com Sucesso!")
          this.closeModalEditar();
          this.ngOnInit();
        }, errr => {
          console.log(errr);
      })
      
      }
    }, err => {
      console.log(err);
      this.app.openAlert("Erro ao editar, tente novamente!")
    })
  }
}

  cadastrarPlano(){
    if(window.confirm("Deseja realmente cadastrar esse plano?")){
    let jsonDate = new Date().toJSON();
    let hora = new Date(jsonDate).toLocaleTimeString();
    let dados = {
      tipo: this.tipo,
      nome: this.nome,
      mensalidade: this.mensalidade,
      adesao: this.adesao,
      empresaOrigem:this.empresaOrigem,
      planoVendas:this.planoVendas,
      temDependentesExtra: this.temDependentesExtra,
      funeralConjuge: this.funeralConjuge,
      funeralFilhosMenor: this.funeralFilhosMenor,
      funeralFilhosMaior: this.funeralFilhosMaior,
      funeralExtra: this.funeralExtra,
      valorFuneralConjuge: this.valorFuneralConjuge,
      valorFuneralFilhosMenor: this.valorFuneralFilhosMenor,
      valorFuneralFilhosMaior: this.valorFuneralFilhosMaior,
      valorFuneralExtra: this.valorFuneralExtra,
      capitalSegurado: this.capitalSegurado,
      valorCapitalSegurado: this.valorCapitalSegurado,
      editaAdesao : this.editaAdesao,
      editaMensalidade : this.editaMensalidade,
      editaTotal: this.editaTotal,
      contrato:this.contrato,
      comissao:this.comissao,
      descricoes:this.descricoesList.value,
      areaCliente:this.areaCliente,
      hibrido:this.hibrido,
      codigoPlano:this.codigoPlano,
      ordem:this.ordemSite,
      limiteASF:this.limiteASF,
      corretoraStarr: this.corretoraStarr,
      valorConectaExtra: this.valorConectaExtra,
      conectaIncluso: this.conectaInclusos,
      maxDependentesConecta: this.maxDependentesConecta
    }
    this.db.postPlanos(dados).subscribe(res => {
      console.log(res);
      if(this.selectedFilesContrato.length > 0){
        const imagedata = new FormData();
        for(let i=0; i<this.selectedFilesContrato.length; i++){imagedata.append('files[]', this.selectedFilesContrato[i], this.selectedFilesContrato[i].name)};
         this.db.enviarContrato(res['insertedId'], imagedata).subscribe((res) => {
             console.log(res);
           },(error) => {
             console.log(error);
           });
      }
      let corpo = {
        "menu" : "PLANOS", 
        "descricao" : "Plano "+this.nome+" cadastrado.",
        "data" : this.dataHoje, 
        "hora" : hora, 
        "usuario" : this.user
      }
      this.db.postLogs(corpo).subscribe(res => {
        console.log(res);
        this.app.openAlert("Plano Cadastrado com Sucesso!")
        this.closeModal();
        this.ngOnInit();
      }, err => console.log(err))
    }, err => {
      console.log(err);
      this.app.openAlert("Erro ao Cadastrar, tente novamente!")
    })
  }
}

  delete(plano){
    if(window.confirm("Deseja realmente excluir esse plano?")){
      let jsonDate = new Date().toJSON();
      let hora = new Date(jsonDate).toLocaleTimeString();
      let id = plano._id;
      this.db.deletePlano(id).subscribe(res => {
        console.log(res);
        let corpo = {
          "menu" : "PLANOS", 
          "descricao" : "Plano "+plano.nome+" excluido.",
          "data" : this.dataHoje, 
          "hora" : hora, 
          "usuario" : this.user
        }
        this.db.postLogs(corpo).subscribe(res => {
          console.log(res);
          this.app.openAlert("Plano excluido com Sucesso!");
          this.ngOnInit();
        }, err => console.log(err))
      }, err => {
        console.log(err);
        this.app.openAlert("Erro ao Exluir, tente novamente!")
      })
    }
  }

  setValorCapital(k){
    if(k != "SIM"){
      this.valorCapitalSegurado = null;
    }
  }

  setValorConjuge(k){
    if(k != "SIM"){
      this.valorFuneralConjuge = null;
    }
  }

  setValorMenor(k){
    if(k != "SIM"){
      this.valorFuneralFilhosMenor = null;
    }
  }

  setValorMaior(k){
    if(k != "SIM"){
      this.valorFuneralFilhosMaior = null;
    }
  }

  setValorExtra(k){
    if(k != "SIM"){
      this.valorFuneralExtra = null;
    }
  }
  
  setValorCapitalEditar(k){
    if(k != "SIM"){
      this.valorCapitalSeguradoEditar = null;
    }
  }

  setValorConjugeEditar(k){
    if(k != "SIM"){
      this.valorFuneralConjugeEditar = null;
    }
  }

  setValorMenorEditar(k){
    if(k != "SIM"){
      this.valorFuneralFilhosMenorEditar = null;
    }
  }

  setValorMaiorEditar(k){
    if(k != "SIM"){
      this.valorFuneralFilhosMaiorEditar = null;
    }
  }

  setValorExtraEditar(k){
    if(k != "SIM"){
      this.valorFuneralExtraEditar = null;
    }
  }

  onChangeContrato(event){
    let arrayTypes = ['application/pdf'];
    this.selectedFilesContrato = <FileList>event;
    for(let i=0; i<this.selectedFilesContrato.length; i++){
        if(!arrayTypes.includes(this.selectedFilesContrato[i].type)){
          this.app.openAlert("Tipo de Arquivo Não Suportado!\nEscolha um documento em PDF")
          this.selectedFilesContrato = "";
      }} 
      this.filesContrato = [];
      for(let i=0; i<this.selectedFilesContrato.length; i++){
        this.filesContrato.push(this.selectedFilesContrato[i].name);
      }
  }
  
  onChangeClickContrato(event){
    let arrayTypes = ['application/pdf'];
    this.selectedFilesContrato = <FileList>event.srcElement.files;
    for(let i=0; i<this.selectedFilesContrato.length; i++){
        if(!arrayTypes.includes(this.selectedFilesContrato[i].type)){
        this.app.openAlert("Tipo de Arquivo Não Suportado!\nEscolha um documento em PDF")
        this.selectedFilesContrato = "";
      }} 
      this.filesContrato = [];
      for(let i=0; i<this.selectedFilesContrato.length; i++){
        this.filesContrato.push(this.selectedFilesContrato[i].name);
      }
  }  

  onChangeContratoEditar(event){
    let arrayTypes = ['application/pdf'];
    this.selectedFilesContratoEditar = <FileList>event;
    for(let i=0; i<this.selectedFilesContratoEditar.length; i++){
        if(!arrayTypes.includes(this.selectedFilesContratoEditar[i].type)){
          this.app.openAlert("Tipo de Arquivo Não Suportado!\nEscolha um documento em PDF")
          this.selectedFilesContratoEditar = "";
      }} 
      this.filesContratoEditar = [];
      for(let i=0; i<this.selectedFilesContratoEditar.length; i++){
        this.filesContratoEditar.push(this.selectedFilesContratoEditar[i].name);
      }
  }
  
  onChangeClickContratoEditar(event){
    let arrayTypes = ['application/pdf'];
    this.selectedFilesContratoEditar = <FileList>event.srcElement.files;
    for(let i=0; i<this.selectedFilesContratoEditar.length; i++){
        if(!arrayTypes.includes(this.selectedFilesContratoEditar[i].type)){
        this.app.openAlert("Tipo de Arquivo Não Suportado!\nEscolha um documento em PDF")
        this.selectedFilesContratoEditar = "";
      }} 
      this.filesContratoEditar = [];
      for(let i=0; i<this.selectedFilesContratoEditar.length; i++){
        this.filesContratoEditar.push(this.selectedFilesContratoEditar[i].name);
      }
  }
  
  uploadFileContrato(){
    if(this.contrato != ''){
      this.db.removeDoc(this.contrato).subscribe(res => {
        console.log(res), err => console.log(err);
      })
    }
    const imagedata = new FormData();
    for(let i=0; i<this.selectedFilesContratoEditar.length; i++){imagedata.append('files[]', this.selectedFilesContratoEditar[i], this.selectedFilesContratoEditar[i].name)};
     this.db.enviarContrato(this.idPlano, imagedata).subscribe((res) => {
         console.log(res);
         this.app.openAlert("Contrato Enviado Com Sucesso!");
         this.getDocsContrato();
       },(error) => {
         console.log(error);
         this.app.openAlert("Contrato Enviado Com Sucesso!");
         this.getDocsContrato();
       });
   }
   
  getDocsContrato(){
    this.db.getPlanosById(this.idPlano).subscribe((result:any) => {
      this.contrato = result.contrato;
      this.filesContratoEditar = [];
    })
  }

  delDocContrato(url){
    if(window.confirm('Deseja Realmente Deletar esse Contrato?')){
      let body = {
        contrato : ""
      }
     this.db.removeDoc(url).subscribe(res => {
       console.log(res);
       this.db.patchPlanos(this.idPlano,body).subscribe(result => {
         console.log(result);
         this.contrato = "";
       }, error => {
         console.log(error);
         this.contrato = "";
       })
      },err => {
        console.log(err);
        this.db.patchPlanos(this.idPlano,body).subscribe(result => {
          console.log(result);
          this.contrato = "";
        }, error => {
          console.log(error);
          this.contrato = "";
        })
     })
   }
 }

  openModalPdfViewer(url){
    this.pdfViewerOnDemand.pdfSrc = url;
    this.pdfViewerOnDemand.refresh();
  }

  openModalCodPlano(){
    let mCodPlano = document.getElementById('modCodPlano');
    mCodPlano.style.display = 'block';
  }

  closeModalCodPlano(){
    let mCodPlano = document.getElementById('modCodPlano');
    mCodPlano.style.display = 'none';
  }
  
  openModalCodPlanoEditar(){
    let mCodPlanoEditar = document.getElementById('modCodPlanoEditar');
    mCodPlanoEditar.style.display = 'block';
  }

  closeModalCodPlanoEditar(){
    let mCodPlanoEditar = document.getElementById('modCodPlanoEditar');
    mCodPlanoEditar.style.display = 'none';
  }

}
