import { Component, OnInit } from '@angular/core';
import { AppComponent } from 'src/app/app.component';
import { DataBaseService } from 'src/app/provider.service';
import * as html2pdf from 'html2pdf.js';

@Component({
  selector: 'app-vendas',
  templateUrl: './vendas.component.html',
  styleUrls: ['./vendas.component.scss']
})

export class VendasComponent implements OnInit {
  vendas:any = [];
  vendedores:any = [];
  vendaSelecionada:any = {};
  dataHoje:any = "";
  dataInicio:any = "";
  dataFim:any = "";
  vendedor:any = "";

  constructor(public db:DataBaseService, public app:AppComponent) { }

  ngOnInit(): void {
    this.dataHoje = new Date(Date.now() - 10800000).toISOString().split('T')[0];
    this.db.getAllUsuarios().subscribe((dataUser:any) => {
      let v = dataUser.filter((item:any) => {return item.colaboradorComercial == true && (item.situacao == "EFETIVADO" || item.situacao == "EXPERIÊNCIA")});
      this.vendedores = v.sort((a:any,b:any) => {if(a.nome < b.nome)return -1});
      this.getVendas();
    });
  }

  getVendas(){
    let dtInicio:any = this.dataInicio ? this.dataInicio : "1900-01-01";
    let dtFim:any = this.dataFim ? this.dataFim : "5000-01-01";
    this.db.getVendas(dtInicio,dtFim,this.vendedor).subscribe((data:any) => {
      this.vendas = data;
    })
  }

  openVendaEditar(venda:any){
    this.vendaSelecionada = venda;
    let mod:any = document.getElementById('modalVendaEditar');
    mod.style.display = "block";
  }

  closeVendaEditar(){
    let mod:any = document.getElementById('modalVendaEditar');
    mod.style.display = "none";
    this.vendaSelecionada = {};
  }

  gerarPDF(venda:any){
    this.vendaSelecionada = venda;
    const element: HTMLElement | null = document.getElementById('imprimir');
    if (!element) {
      console.error('Elemento não encontrado!');
      return;
    }
    const options: any = {
      margin: [1, 1, 1, 1],
      filename: 'vendas.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2, useCORS: true },
      jsPDF: { unit: 'cm', format: 'a4', orientation: 'portrait' }
    };
    setTimeout(() => {
      html2pdf()
        .from(element)
        .set(options)
        .save().then(() => {this.getVendas()})
    }, 500);
  }
 
  

}
