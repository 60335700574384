<div class="modal">
    <div id="imprimir">
        <div class="row" style="font-size:18px; text-align:center; font-weight: 600;">
            <div class="col">
                CONTROLE DE VENDAS - {{vendaSelecionada?.vendedor}}
            </div>
        </div>
        <div class="row" style="font-size:12px; text-align:center">
            <div class="col">
                Inicio Cadastro: {{vendaSelecionada?.data | date:'dd/MM/yyyy'}} - Cadastro Finalizado em: {{vendaSelecionada?.dataPDF | date:'dd/MM/yyyy'}}
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col col-12 col-md-12">
                <label>Município:</label> {{vendaSelecionada?.municipio}}
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col col-12 col-md-12">
                <label>Locais Visitados:</label> {{vendaSelecionada?.locais}}
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col col-5"><strong>Adicional Seguro R$5.000,00</strong></div>
            <div class="col col-2">
                Total: {{ vendaSelecionada?.qtdeSeguro5 }}
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col col-5"><strong>Adicional Seguro R$10.000,00</strong></div>
            <div class="col col-2">
                Total: {{ vendaSelecionada?.qtdeSeguro10 }}
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col col-5"><strong>Adicional Seguro R$15.000,00</strong></div>
            <div class="col col-2">
                Total: {{ vendaSelecionada?.qtdeSeguro15 }}
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col col-5"><strong>Adicional Seguro R$20.000,00</strong></div>
            <div class="col col-2">
                Total: {{ vendaSelecionada?.qtdeSeguro20 }}
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col col-5"><strong>Adicional Seguro R$25.000,00</strong></div>
            <div class="col col-2">
                Total: {{ vendaSelecionada?.qtdeSeguro25 }}
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col col-5"><strong>Adicional Seguro R$35.000,00</strong></div>
            <div class="col col-2">
                Total: {{ vendaSelecionada?.qtdeSeguro35 }}
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col col-5"><strong>Adicional Seguro R$ 50.000,00</strong></div>
            <div class="col col-2">
                Total: {{ vendaSelecionada?.qtdeSeguro50 }}
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col col-5"><strong>Adicional Funeral Extra</strong></div>
            <div class="col col-2">
                Total: {{ vendaSelecionada?.qtdeFuneralExtra }}
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col col-5"><strong>Adicional Funeral Complementar</strong></div>
            <div class="col col-2">
                Total: {{ vendaSelecionada?.qtdefuneralComplementar }}
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col col-5"><strong>Adicional Convênio de Saúde</strong></div>
            <div class="col col-2">
                Total: {{ vendaSelecionada?.qtdeConvenioSaude }}
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col col-5"><strong>Adicional Telemedicina</strong></div>
            <div class="col col-2">
                Total: {{ vendaSelecionada?.qtdeTelemedicina }}
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col col-5"><strong>Novas Filiações</strong></div>
            <div class="col col-2">
                Total: {{ vendaSelecionada?.qtdeFiliacoes }}
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col col-12 col-md-12">
                <label>Faturamento: {{ vendaSelecionada?.faturamento | currency: 'BRL'}}</label>
            </div>
        </div>
        <br>
    </div>
</div>
<div class="modal" id="modalVendaEditar">
    <div class="row" style="font-size:18px; text-align:center; font-weight: 600;">
        <div class="col">
            CONTROLE DE VENDAS - {{vendaSelecionada?.vendedor}}
        </div>
    </div>
    <div class="row" style="font-size:12px; text-align:center">
        <div class="col">
            Inicio Cadastro: {{vendaSelecionada?.data | date:'dd/MM/yyyy'}} - Cadastro Finalizado em: {{vendaSelecionada?.dataPDF | date:'dd/MM/yyyy'}}
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col col-12 col-md-12">
            <label>Município:</label>
            {{vendaSelecionada.municipio}}
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col col-12 col-md-12">
            <label>Locais Visitados:</label>
            {{vendaSelecionada.locais}}
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col col-5"><strong>Adicional Seguro R$5.000,00</strong></div>
        <div class="col col-2">
            Total: {{ vendaSelecionada?.qtdeSeguro5 }}
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col col-5"><strong>Adicional Seguro R$10.000,00</strong></div>
        <div class="col col-2">
            Total: {{ vendaSelecionada?.qtdeSeguro10 }}
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col col-5"><strong>Adicional Seguro R$15.000,00</strong></div>
        <div class="col col-2">
            Total: {{ vendaSelecionada?.qtdeSeguro15 }}
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col col-5"><strong>Adicional Seguro R$20.000,00</strong></div>
        <div class="col col-2">
            Total: {{ vendaSelecionada?.qtdeSeguro20 }}
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col col-5"><strong>Adicional Seguro R$25.000,00</strong></div>
        <div class="col col-2">
            Total: {{ vendaSelecionada?.qtdeSeguro25 }}
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col col-5"><strong>Adicional Seguro R$35.000,00</strong></div>
        <div class="col col-2">
            Total: {{ vendaSelecionada?.qtdeSeguro35 }}
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col col-5"><strong>Adicional Seguro R$ 50.000,00</strong></div>
        <div class="col col-2">
            Total: {{ vendaSelecionada?.qtdeSeguro50 }}
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col col-5"><strong>Adicional Funeral Extra</strong></div>
        <div class="col col-2">
            Total: {{ vendaSelecionada?.qtdeFuneralExtra }}
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col col-5"><strong>Adicional Funeral Complementar</strong></div>
        <div class="col col-2">
            Total: {{ vendaSelecionada?.qtdefuneralComplementar }}
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col col-5"><strong>Adicional Convênio de Saúde</strong></div>
        <div class="col col-2">
            Total: {{ vendaSelecionada?.qtdeConvenioSaude }}
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col col-5"><strong>Adicional Telemedicina</strong></div>
        <div class="col col-2">
            Total: {{ vendaSelecionada?.qtdeTelemedicina }}
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col col-5"><strong>Novas Filiações</strong></div>
        <div class="col col-2">
            Total: {{ vendaSelecionada?.qtdeFiliacoes }}
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col col-12 col-md-12">
            <label>Faturamento</label><br>
            {{ vendaSelecionada.faturamento | currency: 'BRL'}}
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col col-12 col-md-12">
            <label>Filiações Novas:</label>
            {{vendaSelecionada.filiacoes}}
        </div>
    </div>
    <br>
    <div class="row" style="text-align: right;">
        <div class="col col-12 col-md-12">
            <button class="btn btn-danger btn-block" (click)="closeVendaEditar()">FECHAR</button>
        </div>
    </div>
</div>
<div class="container-fluid">
    <div class="row align-items-center header">
        <div class="col titulo">
            CONTROLE DE VENDAS
        </div>
    </div>
    <div class="corpo">
        <div class="row align-items-end">
            <div class="col col-2">
                <label>Data Inicio</label>
                <input type="date" class="form-control" [(ngModel)]="dataInicio">
            </div>
            <div class="col col-2">
                <label>Data Fim</label>
                <input type="date" class="form-control" [(ngModel)]="dataFim">
            </div>
            <div class="col col-4">
                <label>Vendedor</label>
                <select class="form-control" [(ngModel)]="vendedor">
                    <option value=""></option>
                    <option *ngFor="let v of vendedores" [value]="v.nome">{{v.nome}}</option>
                </select>
            </div>
            <div class="col col-1">
                <button class="btn btn-primary" (click)="getVendas()">
                    <fa-icon [icon]="['fas', 'search']"></fa-icon>
                </button>
            </div>
        </div>
        <br>
        <table class="table table-striped">
            <thead class="thead-dark">
                <tr>
                    <th>#</th>
                    <th>Criação</th>
                    <th>Vendedor</th>
                    <th>Município</th>
                    <th>Faturamento</th>
                    <th>Situação</th>
                    <th>Ver</th>
                    <th>PDF</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let v of vendas; let i = index">
                    <td>{{i + 1}}</td>
                    <td>{{v?.data | date: 'dd/MM/yyyy'}}</td>
                    <td>{{v?.vendedor}}</td>
                    <td>{{v?.municipio}}</td>
                    <td>{{v?.faturamento | currency: 'BRL'}}</td>
                    <td>{{v?.situacao}}</td>
                    <td>
                        <button class="btn btn-primary" (click)="openVendaEditar(v)">
                            <fa-icon [icon]="['fas', 'edit']"></fa-icon>
                        </button>
                    </td>
                    <td>
                    <button class="btn btn-success" (click)="gerarPDF(v)">
                        <fa-icon [icon]="['fas', 'check']"></fa-icon>
                    </button></td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
