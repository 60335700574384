import { Component, OnInit } from '@angular/core';
import { DataBaseService } from '../provider.service';
import * as moment from 'moment';
import * as XLSX from 'xlsx';
import { AppComponent } from '../app.component';

@Component({
  selector: 'app-comissoes',
  templateUrl: './comissoes.component.html',
  styleUrls: ['./comissoes.component.css']
})

export class ComissoesComponent implements OnInit {
  dtInicio:any = "";
  dtFim:any = "";
  mesComissao:any = "";
  gerentes:any = [];
  colaborador:any = "";
  colaboradores:any = [];
  associados:any = [];
  dataHoje:any = "";
  agenciamentoMes:any = [];
  associadosDoMes:any = [];
  cancelados:any = [];
  canceladosNoMes:any = [];
  associadosCancelados:any = [];
  valorTotalCancelados:number = 0;
  valorTotalVendas:number = 0;
  totalVendasAlteracao:number = 0;
  metaColaborador:number = 0;
  comissaoColaborador:number = 0;
  vlrAssociadosCanceladosMes:number = 0;
  bonusColaborador:number = 0;
  percentualAtingido:number = 0;
  debitoAnterior:any = 0;
  caixa:any = [];
  subContratos:any = [];

  constructor(private db: DataBaseService, public app:AppComponent) { }

  ngOnInit(): void {
    this.dataHoje = new Date(Date.now() - 10800000).toISOString().split('T')[0];
    this.db.getUsuariosComercialComissao('"nome":1,"comissaoSobreVendasCheck":1,"bonus":1,"meta":1').subscribe((vends:any) => {
      this.colaboradores = vends;
      this.gerentes = vends.filter((v:any) => {return v.comissaoSobreVendasCheck})
      this.db.getSubContratosProjection('"nome":1,"dataVencimento":1').subscribe((subs:any) => {
        this.subContratos = subs;
      })
    })
  }

  mes(data:any){
    let m = Number(data.split('-')[1]) - 1;
    let arr = ["Janeiro","Fevereiro","Março","Abril","Maio","Junho","Julho","Agosto","Setembro","Outubro","Novembro","Dezembro"];
    return arr[m];
  }

  replaceStrings(string:any){
    if(!string){return}
    return string.replace(/\%/gi,'%25').replace(/\+/gi,'%2B').replace(/\!/gi,'%21').replace(/\&/gi,'%26').replace(/\;/gi,'%3B').replace(/\#/gi,'%23').replace(/\$/gi,'%24');
  }
  
  reverseReplaceStrings(string:any){
    if(!string){return}
    return string.replace(/%25/gi,'%').replace(/%2B/gi,'+').replace(/%21/gi,'!').replace(/%26/gi,'&').replace(/%3B/gi,';').replace(/\%23/gi,'#').replace(/\%24/gi,'$');
  } 

  getComissao(){
    this.associados = [];
    this.cancelados = [];
    this.canceladosNoMes = [];
    this.associadosDoMes = [];
    this.associadosCancelados = [];
    this.agenciamentoMes = [];
    this.comissaoColaborador = 0;
    this.bonusColaborador = 0;
    this.valorTotalCancelados = 0;
    this.valorTotalVendas = 0;
    this.totalVendasAlteracao = 0;
    this.debitoAnterior = 0;
    this.vlrAssociadosCanceladosMes = 0;
    this.metaColaborador = this.colaborador.meta;
    this.dtInicio = moment(this.mesComissao).subtract(1,'month').date(25).format("YYYY-MM-DD");
    this.dtFim = moment(this.mesComissao).date(24).format("YYYY-MM-DD");
    let mesAnterior:any =  moment(this.mesComissao).subtract(1,'month').format("YYYY-MM");
    this.db.getComissoesByDataVendedor(mesAnterior,this.colaborador.nome).subscribe((com:any) => {
      this.debitoAnterior = com[0]?.debitosAnteriores ? com[0]?.debitosAnteriores : 0;
    })
    this.db.getCaixaComissao(moment(this.dtInicio).subtract(4,'months').format('YYYY-MM-DD'),this.dtFim,'"favorecido":1,"status":1,"documentoFavorecido":1,"dataPagamento":1,"tipoEntrada":1').subscribe((caixa:any) => {
      this.caixa = caixa;
      this.db.getAssociadosByVendedorProjection(this.colaborador.nome,'"_id":1,"nome":1,"situacao":1,"dataAdesao":1,"valorComissao":1,"rolagem":1,"dataPgtoComissao":1,"dataCancelamento":1,"motivoCancelamento":1,"formaPagamento":1,"origem":1,"plano":1,"subContrato":1,"cpf":1,"planoEmpresarialCheck":1,"planoFisicoCheck":1,"codigoEmpresa":1,"matricula":1,"mesFolhaPagamento":1,"localTrabalho":1,"totalMensalidade":1').subscribe((associados:any) => {
        this.associados = associados;
        if(associados){
          let associadosGeral = associados.filter((a:any) => {return ((a.situacao == "CANCELADO" && (a.motivoCancelamento != "FALTA DO 1º PAGAMENTO" && a.motivoCancelamento != "MOVIMENTAÇÃO - EXCLUSÃO" && a.motivoCancelamento != "ABANDONO DE CARRINHO" && a.motivoCancelamento != "EXCLUSÃO POR FALTA DE BAIXA DE PENDÊNCIA" && a.motivoCancelamento != "EXCLUSÃO DE PLANO POR FALTA DE CADASTRO DE CARTÃO" && a.motivoCancelamento != "MIGRAÇÃO VIVA MAX NOVOS PRODUTOS")) || a.situacao =="ATIVO" || a.situacao =="ALTERAÇÃO" || a.situacao == "INADIMPLENTE I") && a.origem != "INCLUSÃO EMPRESA"});
          this.associadosDoMes = associadosGeral.filter((a:any) => {return a.dataAdesao >= this.dtInicio && a.dataAdesao <= this.dtFim});
          this.associadosCancelados = associados.filter((a:any) => {return (a.dataCancelamento >= this.dtInicio && a.dataCancelamento <= this.dtFim) && (a.dataAdesao >= moment(this.dtInicio).subtract(4,'months').format('YYYY-MM-DD') && a.dataAdesao <= this.dtFim) && (a.situacao =="CANCELADO" && (a.motivoCancelamento != "FALTA DO 1º PAGAMENTO" && a.motivoCancelamento != "MOVIMENTAÇÃO - EXCLUSÃO" && a.motivoCancelamento != "ABANDONO DE CARRINHO" && a.motivoCancelamento != "EXCLUSÃO POR FALTA DE BAIXA DE PENDÊNCIA" && a.motivoCancelamento != "EXCLUSÃO DE PLANO POR FALTA DE CADASTRO DE CARTÃO" && a.motivoCancelamento != "MIGRAÇÃO VIVA MAX NOVOS PRODUTOS"))});
          let ctrlAssociados = 0;
          let ctrlCancelados = 0;
          this.associadosDoMes.forEach((aM:any) => {
            if(!aM.planoEmpresarialCheck){
              aM.origem = aM.situacao;
            }
            if(aM.planoFisicoCheck){
              if(aM.formaPagamento == "BOLETO" || aM.formaPagamento == "CARNÊ"){
                aM.pago = true;
              }
              if(aM.formaPagamento == "DEBITO RECORRENTE"){
                  this.db.getVindiByCpf(aM.cpf.replace(/_/g,'').replace(/[\s.-]*/igm, '')).subscribe((retCpfVindi:any) => {
                    if(retCpfVindi.customers.length > 0){
                      let iVindi:any = retCpfVindi.customers.map((m:any) => {
                        return m.status;
                      }).indexOf('active');
                      if(iVindi >=0){
                        this.db.getFaturasByIdDate(retCpfVindi.customers[iVindi].id,this.dtFim).subscribe((retIdVindi:any) => {
                          if(retIdVindi.bills.length > 0){
                            aM.pago = true;
                          }
                        })
                      }
                     }
                   })
                }
            }
            if(aM.subContrato != ""){
              let idxSub = this.subContratos.map((s:any) => {
                return s.nome;
              }).indexOf(aM.subContrato)
              if(idxSub >= 0){       
                 if(aM.dataAdesao <= moment(this.dtFim).date(this.subContratos[idxSub].dataVencimento.split("-")[2]).format('YYYY-MM-DD')){
                 aM.pago = true;
                 }
              }
            }
            if(aM.planoEmpresarialCheck){
              let idxCaixa = this.caixa.map((cS:any) => {
                return cS.favorecido;
              }).indexOf(aM.localTrabalho);
              if(idxCaixa >= 0){
                if(this.caixa[idxCaixa].status == "Pago"){
                  aM.pago = true;
                }
              }
            }
            if(aM.situacao == "ALTERAÇÃO"){
              this.totalVendasAlteracao += 0.4 * aM.totalMensalidade
            }else{
              this.valorTotalVendas += aM.totalMensalidade;
            }
            this.agenciamentoMes.push(aM);
            ctrlAssociados++;
            if(ctrlAssociados == this.associadosDoMes.length){
              this.agenciamentoMes.sort((a:any,b:any) => {
                if(a.dataAdesao < b.dataAdesao){return -1;}
                if(a.dataAdesao > b.dataAdesao){return 1;} 
              })
            }
              this.percentualAtingido = (this.valorTotalVendas / this.metaColaborador) * 100;
              if (this.percentualAtingido < 100) {
                this.comissaoColaborador = 0.9 * this.valorTotalVendas;
              } else if (this.percentualAtingido <= 129) {
                this.comissaoColaborador = this.valorTotalVendas;
              } else {
                this.comissaoColaborador = 1.2 *this.valorTotalVendas;
              }
              if(this.valorTotalVendas >= this.metaColaborador){
                this.bonusColaborador = this.colaborador.bonus;
              }
          })
          this.associadosCancelados.forEach((aC:any) => {          
          let idxCancel = this.agenciamentoMes.map((u:any) => {
            return u.matricula;
          }).indexOf(aC.matricula)
          if(idxCancel >= 0){
            this.canceladosNoMes.push(aC);
            this.vlrAssociadosCanceladosMes += aC.totalMensalidade;
          }else{
            if(aC.motivoCancelamento == "INADIMPLÊNCIA"){
              this.cancelados.push(aC);
              this.valorTotalCancelados += aC.valorComissao;
            }else{
              if(aC.formaPagamento == "BOLETO" || aC.formaPagamento == "CARNÊ" || aC.formaPagamento == "DEBITO RECORRENTE"){
                this.db.getVindiByCpf(aC.cpf.replace(/_/g,'').replace(/[\s.-]*/igm, '')).subscribe((resCpfVindi:any) => {
                  if(resCpfVindi.customers.length > 0){
                    let iVin:any = resCpfVindi.customers.map((m:any) => {
                      return m.status;
                    }).indexOf('active');
                    if(iVin >=0){                   
                      this.db.getFaturasByIdDate(resCpfVindi.customers[iVin].id,this.dtFim).subscribe((resIdVindi:any) => {
                        if(resIdVindi.bills.length < 3){
                          this.cancelados.push(aC);
                          this.valorTotalCancelados += aC.valorComissao;
                        }
                      })
                    }
                  }else{
                    let pgtos = this.caixa.filter((c:any) => {return c.documentoFavorecido == aC.cpf && c.tipoEntrada != "GUIAS SINDILOJAS E AMIC" && c.tipoEntrada != "OUTROS"});
                    if(pgtos.length < 3){
                      this.cancelados.push(aC);
                      this.valorTotalCancelados += aC.valorComissao;
                    }
                  }
                })
              }
              if(aC.subContrato != "" || aC.planoEmpresarialCheck){
                if(aC.dataAdesao >= moment(this.dtFim).subtract(3,'months').format('YYYY-MM-DD')){
                  this.cancelados.push(aC);
                  this.valorTotalCancelados += aC.valorComissao;
                }
              }
            }
          }
            ctrlCancelados++;
            if(ctrlCancelados == this.associadosCancelados.length){
              this.cancelados.sort((a:any,b:any) => {
                if(a.dataCancelamento < b.dataCancelamento){return -1;}
                if(a.dataCancelamento > b.dataCancelamento){return 1;}
              })
            }
          })
        }
      })
    })
  }

  gravarComissao(){
    this.db.getComissoesByDataVendedor(this.mesComissao,this.colaborador.nome).subscribe((com:any) => {
      if(com.length > 0){
        this.app.openAlert("Está comissão já foi registrada! Não é possivel gerar duas comissões para o mesmo mês com o mesmo colaborador")
      }else{
        let totalComissaoVendedor:any = this.comissaoColaborador + this.totalVendasAlteracao + this.bonusColaborador - this.valorTotalCancelados - this.vlrAssociadosCanceladosMes - this.debitoAnterior;
        let debitosAnteriores:any = 0;
        if(totalComissaoVendedor < 0){
          debitosAnteriores = totalComissaoVendedor * -1;
        }
        let dados:any = {
          dataCriacao: this.dataHoje,
          mesComissao: this.mesComissao,
          vendedor:this.colaborador.nome,
          agenciamentoMes: this.agenciamentoMes,
          canceladosNoMes: this.canceladosNoMes,
          valorTotalVendas:this.valorTotalVendas,
          vlrAssociadosCanceladosMes:this.vlrAssociadosCanceladosMes,
          cancelados: this.cancelados,
          debitoAnterior:this.debitoAnterior,
          comissaoColaborador: this.comissaoColaborador,
          valorTotalCancelados: this.valorTotalCancelados,
          bonusColaborador: this.bonusColaborador,
          vendasAlteracao: this.totalVendasAlteracao,
          totalComissaoPaga: totalComissaoVendedor,
          metaColaborador: this.metaColaborador,
          debitosAnteriores: debitosAnteriores,
          percentualAtingido: this.percentualAtingido,
        }
        this.db.postComissao(dados).subscribe(res => {
          console.log(res);
          let ctrl = 1;
          this.agenciamentoMes.forEach((user:any) => {
              this.db.patchAssociado(user._id,{valorComissao:user.totalMensalidade,dataPgtoComissao:this.dataHoje}).subscribe((res:any) => {
                console.log(res);
              }, (err:any) => {
                console.log(err);
              })
              if(this.agenciamentoMes.length == ctrl){
                let jsonDate = new Date().toJSON();
                let hora = new Date(jsonDate).toLocaleTimeString();
                let body = {
                  "menu" : "COMISSÕES", 
                  "descricao" : "Comissão Gerada do Vendedor: "+this.colaborador.nome, 
                  "data" : this.dataHoje, 
                  "hora" : hora, 
                  "usuario" : localStorage.getItem('usuarioVivamax')
                }
                this.db.postLogs(body).subscribe((res:any) => {
                  console.log(res);
                  this.app.openAlert("Comissão Registrada com Sucesso!");
                }, (err:any) => {
                  console.log(err);
                })
              }else{
                ctrl++;
              }
            })
        }, err => {
          console.log(err);
          this.app.openAlert("Erro ao gravar comissão")
        })
      }      
    }) 
  }

  print(){
    let div = document.getElementById('divToPrintCom').innerHTML;
    let newWindow = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    newWindow.document.open();
    newWindow.document.write(`
    <html>
        <head>
          <title>Viva Max - Proposta</title>
          <link rel="stylesheet" type="text/css" href="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css">
          <script type="text/javascript" src="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/js/bootstrap.min.js"></script>
        </head>
        <body onload="window.print()">
        ${div}     
        </body>
      </html>
    `);
    newWindow.document.close();
  }

  printXsl(){
    var ws = XLSX.utils.table_to_sheet(document.getElementById('divToPrintCom'),{raw:true});
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'vivamax.xlsx',{cellStyles:true});
  }

mudouVendedor(){
  this.associados = [];
  this.cancelados = [];
  this.canceladosNoMes = [];
  this.associadosDoMes = [];
  this.associadosCancelados = [];
  this.agenciamentoMes = [];
  this.comissaoColaborador = 0;
  this.bonusColaborador = 0;
  this.valorTotalCancelados = 0;
  this.valorTotalVendas = 0;
}

}