<div class="modal" id="modalDocs" style="padding:10px">
    <div class="row" style="text-align: center; color:#c7444a">
        <div class="col">
            <h4>ANEXOS</h4>
        </div>
    </div>
    <div class="row justify-content-md-center">
        <div class="col col-3" *ngFor="let pdf of pdfs;" style="margin: 5px; font-size:0.7em; background-color:#ce2626; color:white; text-align: center; border-radius: 10px;">
            <div class="delButton" name="delButton">
                <button class="btn btn-dark btn-sm" (click)="delDocConta(pdf.url)"><strong>X</strong></button>
            </div>
            <div (click)="openModalPdfViewer(pdf.url)" style="position: relative;left:50%;top:50%;transform: translate(-50%,-50%);cursor:pointer">
                <fa-icon [icon]="['fas', 'file-pdf']" style="font-size: 5em;"></fa-icon>
                    <br>DOCUMENTO PDF
            </div>
        </div>
        <div class="col col-3 img-container" *ngFor="let img of images; let i = index" style="margin: 10px; text-align: center;">
            <div class="delButton" name="delButton">
                <button class="btn btn-danger btn-sm" (click)="delDocConta(img.url)"><strong>X</strong></button>
            </div>
            <img [src]="img.url" (click)="openModalLightBox(img.url, i)" style="border-radius: 10px; height: 250px; width:100%;" crossorigin="anonymous">
        </div>
    </div>
    <div class="row">
        <div class="col">
            <div class="uploadfilecontainer" (click)="fileDocumentosEditar.click()" appDragDrop (onFileDropped)="onChangeEditar($event)">
                <input hidden type="file" #fileDocumentosEditar (change)="onChangeClickEditar($event)" multiple>
            </div>
            <div class="row align-items-center">
                <div class="col col-1">
                    &nbsp;
                </div>
                <div class="col">
                    <div class="files-list" *ngFor="let file of filesVendasEditar; let i= index">
                        <p>{{ file }}</p>
                    </div> 
                </div>
                <div class="col col-2" [hidden]="filesVendasEditar.length < 1" (click)="enviarDocVenda()">
                    <button class="btn btn-primary btn-block">ANEXAR</button>
                </div>
                <div class="col col-1">
                    &nbsp;
                </div>
            </div>
        </div>
    </div>
    <div class="row" style="text-align: center;">
        <div class="col">
            <button class="btn btn-danger" (click)="closeModalDocs()">FECHAR</button>
        </div>
    </div>
</div>
<div class="modal" id="modalNovaConta" style="padding:10px">
    <div class="row" style="text-align: center; color:#c7444a">
        <div class="col">
            <h4>CADASTRAR NOVA VENDA</h4>
        </div>
    </div>
    <div class="row">
        <div class="col col-8">
            <label>Associado</label>
            <input type="text" class="form-control" [(ngModel)]="associado" oninput="this.value = this.value.toUpperCase()">
        </div>
        <div class="col col-4">
            <label>Matricula</label>
            <input type="number" class="form-control" [(ngModel)]="matricula">
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col col-12" style="text-align: center;">
            <div class="form-check">
                <input class="form-check-input" type="checkbox" id="alte" [(ngModel)]="alteracao">&nbsp;&nbsp;
                <label class="form-check-label" for="alte">Alteração</label>
            </div>
         </div>
    </div>
    <br>
    <div class="row">
        <div class="col col-12">
            <label>Observação</label>
            <input type="text" class="form-control" [(ngModel)]="observacao" oninput="this.value = this.value.toUpperCase()">
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col">
            <div class="uploadfilecontainer" (click)="fileDocumentos.click()" appDragDrop (onFileDropped)="onChange($event)">
                <input hidden type="file" #fileDocumentos (change)="onChangeClick($event)" multiple>
            </div>
            <div class="row align-items-center">
                <div class="col col-1">
                    &nbsp;
                </div>
                <div class="col">
                    <div class="files-list" *ngFor="let file of filesVendas; let i= index">
                        <p>{{ file }}</p>
                    </div> 
                </div>
                <div class="col col-1">
                    &nbsp;
                </div>
            </div>
        </div>
    </div>
    <br>
    <div class="row" style="text-align: center;">
        <div class="col">
            <button class="btn btn-danger" (click)="closeModalVenda()">CANCELAR</button>
        </div>
        <div class="col">
            <button class="btn btn-success" (click)="cadastrarVenda()" [disabled]="!associado || !matricula">CADASTRAR</button>
        </div>
    </div>
</div>
<div id="myModal" class="modalLightbox">
    <span (click)="closeModal()" class="close">&times;</span>
    <div class="prev" (click)="prevImg()">&lt;</div>
    <img [src]="imagemSelecionada" class="imagemLightbox">
    <div class="next" (click)="nextImg()">&gt;</div>
</div> 
<div style="width: 800px; height: 400px;display: none;">
    <ng2-pdfjs-viewer 
      #pdfViewerVendas
      [externalWindow]="true"
      [downloadFileName]="'vivamax.pdf'"
      [openFile]="false"
      [viewBookmark]="false"
      [download]="true"></ng2-pdfjs-viewer>
  </div>
<div class="container-fluid">
    <div class="row align-items-center header">
        <div class="col col-10 titulo">
            CONTROLE DE VENDAS
        </div>
        <div class="col col-2" style="text-align: right;">
            <button class="btn btn-outline-light" (click)="ngOnInit()">
                <fa-icon [icon]="['fas', 'sync']" size="1x"></fa-icon>&nbsp;RESET
            </button>
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col">
            <input type="date" [(ngModel)]="dataVendas" class="form-control" (change)="mudaMes()">
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col col-12 col-md-6">
            <h3>EXTERNO</h3><br>
            <accordion [isAnimated]="true" *ngFor="let time of timesExterno">
                <accordion-group [heading]="'TIME '+time?.time+' - Total no Mês: '+time.total" [panelClass]="customClass">
                    <accordion [isAnimated]="true" *ngFor="let vendedor of time?.vendedores">
                        <accordion-group [heading]="vendedor.nome+' - '+(vendedor?.vendas  | dataVendaFilter:dataVendas).length">
                            <table class="table table-responsive" style="text-align: center; font-size: 0.85em;">
                                <thead>
                                    <th scope="col">Data</th>
                                    <th scope="col">Associado</th>
                                    <th scope="col">Matricula</th>
                                    <th scope="col">Observação</th>
                                    <th scope="col">Tipo</th>
                                    <th scope="col">Anexos</th>
                                    <th scope="col">Excluir</th>
                                </thead>
                                <tbody *ngFor="let venda of vendedor?.vendas | dataFilter:dataVendas">
                                    <tr [ngStyle]="{'background-color': venda?.alteracao ? '#df9898' : null}">
                                        <td>{{venda?.data | date:"dd/MM/yyyy"}}</td>
                                        <td>{{venda?.associado}}</td>
                                        <td>{{venda?.matricula}}</td>
                                        <td>{{venda?.observacao}}</td>
                                        <td>
                                            <span *ngIf="venda?.alteracao">ALTERAÇÃO</span>
                                            <span *ngIf="!venda?.alteracao">VENDA</span>
                                        <td>
                                            <button class="btn-primary" (click)="openModalDocs(vendedor._id,venda.id)">
                                                <fa-icon [icon]="['fas', 'paperclip']" size="1x"></fa-icon>
                                            </button>
                                        </td>
                                        <td>
                                            <button class="btn-danger" (click)="excluirVenda(vendedor._id,venda)">
                                                <fa-icon [icon]="['fas', 'trash']" size="1x"></fa-icon>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                                <tr>
                                    <td colspan="7">
                                        <button class="btn-success" (click)="novaVenda(vendedor)">CADASTRAR NOVA VENDA</button>
                                    </td>
                                </tr>
                            </table>
                        </accordion-group>
                    </accordion>
                </accordion-group>
              </accordion>
        </div>
        <div class="col col-12 col-md-6">
            <h3>INTERNO</h3><br>
            <accordion [isAnimated]="true" *ngFor="let time of timesInterno">
                <accordion-group [heading]="'TIME '+time?.time+' - Total no Mês: '+time.total" [panelClass]="customClass">
                    <accordion [isAnimated]="true" *ngFor="let vendedor of time?.vendedores">
                        <accordion-group [heading]="vendedor.nome+' - '+(vendedor?.vendas  | dataVendaFilter:dataVendas).length">
                            <table class="table table-responsive" style="text-align: center; font-size: 0.85em;">
                                <thead>
                                    <th scope="col">Data</th>
                                    <th scope="col">Associado</th>
                                    <th scope="col">Matricula</th>
                                    <th scope="col">Observação</th>
                                    <th scope="col">Tipo</th>
                                    <th scope="col">Anexos</th>
                                    <th scope="col">Excluir</th>
                                </thead>
                                <tbody *ngFor="let venda of vendedor?.vendas | dataFilter:dataVendas">
                                    <tr [ngStyle]="{'background-color': venda?.alteracao ? '#df9898' : null}">
                                        <td>{{venda?.data | date:"dd/MM/yyyy"}}</td>
                                        <td>{{venda?.associado}}</td>
                                        <td>{{venda?.matricula}}</td>
                                        <td>{{venda?.observacao}}</td>
                                        <td>
                                            <span *ngIf="venda?.alteracao">ALTERAÇÃO</span>
                                            <span *ngIf="!venda?.alteracao">VENDA</span>
                                        <td>
                                            <button class="btn-primary" (click)="openModalDocs(vendedor._id,venda.id)">
                                                <fa-icon [icon]="['fas', 'paperclip']" size="1x"></fa-icon>
                                            </button>
                                        </td>
                                        <td>
                                            <button class="btn-danger" (click)="excluirVenda(vendedor._id,venda)">
                                                <fa-icon [icon]="['fas', 'trash']" size="1x"></fa-icon>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                                <tr>
                                    <td colspan="7">
                                        <button class="btn-success" (click)="novaVenda(vendedor)">CADASTRAR NOVA VENDA</button>
                                    </td>
                                </tr>
                            </table>
                        </accordion-group>
                    </accordion>
                </accordion-group>
              </accordion>
        </div>
    </div>
   
      <br><br>
      <div *ngIf="db?.usuario?.permissoes['vendas'] == 'EDITAR'">
        <div class="row" style="text-align: center;font-weight: bold;color:#c7444a">
            <div class="col">
                RANKING VENDAS DO MÊS
            </div>
        </div>
        <br>
        <table class="table table-hover">
            <thead>
                <td>Nome</td>
                <td>Qtde. Vendas</td>
            </thead>
            <tbody>
                <tr *ngFor="let vend of vendedoresRanking">
                    <td>{{vend?.nome}}</td>
                    <td>{{vend?.vendasMes?.length}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>