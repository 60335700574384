import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DataBaseService } from './provider.service';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class AuthService {
  constructor(public db: DataBaseService, public route:Router, public http:HttpClient) {}

  login(mail, pwd){
    this.db.getLoginByUsuarioProjection(mail.toUpperCase(),pwd,'"nome":1,"razaoEmpresa":1,"tipo":1').subscribe((res:any) => {
        if(res.length < 1){
          alert("Email não cadastrado ou Senha não confere, tente novamente!")
          return;
        }else{
         let user = res[0];
         let storage = Math.random().toString(36).slice(-8);
         let usuario = user.nome;
         if(!usuario){
           usuario = user.razaoEmpresa;
         }
         let idUser = user._id;
         let tipo = user.tipo;
         localStorage.setItem('tokenVivamax', JSON.stringify(storage));
         localStorage.setItem('usuarioVivamax', usuario);
         localStorage.setItem('idUserVivamax', idUser);
         localStorage.setItem('tipoVivamax', tipo);
         this.isLoggedIn();
         this.route.navigate(['/inicio']);
         setTimeout(() => {
          location.reload();
         },1000)
      }
    },
      err => {
        if(err.status < 200){
          alert("Por Favor Verifique Sua Conexão com a Internet")
        }
      }
    )
}

  logOut(){
    localStorage.clear();
    this.isLoggedIn();
    this.route.navigate(['/login']);
  }

  isLoggedIn(): boolean {
    var token: String = this.getToken();
    return token && token.length > 0;
}

getToken(): String {
  var token = localStorage.getItem('tokenVivamax');
  return token ? token : "";
}



}   