import { BrowserModule } from '@angular/platform-browser';
import { NgModule,LOCALE_ID } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { RelatoriosComponent } from './relatorios/relatorios.component';
import { ColaboradoresComponent } from './colaboradores/colaboradores.component';
import { AssociadosComponent } from './associados/associados.component';
import { CredenciadosComponent } from './credenciados/credenciados.component';
import { ContasPagarComponent } from './contas-pagar/contas-pagar.component';
import { ContasReceberComponent } from './contas-receber/contas-receber.component';
import { InicioComponent } from './inicio/inicio.component';
import { DataBaseService } from './provider.service';
import { CaixaComponent } from './caixa/caixa.component';
import { SubContratosComponent } from './sub-contratos/sub-contratos.component';
import { PlanosComponent } from './planos/planos.component';
import { LogsComponent } from './logs/logs.component';
import { AuthService } from './auth.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { FontAwesomeModule,FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from "@fortawesome/free-brands-svg-icons";
import { NgBrazil } from 'ng-brazil' 
import { TextMaskModule } from 'angular2-text-mask';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { ConfiguracoesComponent } from './configuracoes/configuracoes.component';
import { DragDropDirective } from './drag-drop.directive';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { ChartsModule } from 'ng2-charts';
import { ControleVendasComponent } from './controle-vendas/controle-vendas.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import { DataMesFilter } from './data-mes-filter.pipe';
import { DataFilter } from './data-filter.pipe';
import { DataVendaFilter } from './data-venda-filter.pipe';
import { ReplacePipe } from './replace.pipe';
import { RelatoriosFinanceiroComponent } from './relatorios-financeiro/relatorios-financeiro.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ColorPickerModule } from 'ngx-color-picker';
import { FaturamentoComponent } from './faturamento/faturamento.component';
import { EmpresasComponent } from './empresas/empresas.component';
import { TarefasComponent } from './tarefas/tarefas.component';
import { CelescComponent } from './celesc/celesc.component';
import { CentroCustoComponent } from './centro-custo/centro-custo.component';
import { filterCCPipe } from './filterCC.pipe';
import { filterCOPipe } from './filterCO.pipe';
import { filterShowPipe } from './filterShow.pipe';
import { ProgramacaoComponent } from './programacao/programacao.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ComissoesComponent } from './comissoes/comissoes.component';
import { MarketingComponent } from './marketing/marketing.component';
import { SortDirective } from './sort.directive';
import { FornecedoresComponent } from './fornecedores/fornecedores.component';
import { RelatorioComissoesComponent } from './relatorio-comissoes/relatorio-comissoes.component';
import { VendasComponent } from './vendas/vendas.component';

registerLocaleData(localePt);

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RelatoriosComponent,
    RelatoriosFinanceiroComponent,
    ColaboradoresComponent,
    AssociadosComponent,
    CredenciadosComponent,
    ContasPagarComponent,
    ContasReceberComponent,
    InicioComponent,
    DashboardComponent,
    CaixaComponent,
    SubContratosComponent,
    PlanosComponent,
    LogsComponent,
    DragDropDirective,
    ConfiguracoesComponent,
    ControleVendasComponent,
    DataMesFilter,
    DataFilter,
    DataVendaFilter,
    ReplacePipe,
    filterCCPipe,
    filterCOPipe,
    filterShowPipe,
    FaturamentoComponent,
    EmpresasComponent,
    TarefasComponent,
    CelescComponent,
    CentroCustoComponent,
    ProgramacaoComponent,
    ComissoesComponent,
    RelatorioComissoesComponent,
    MarketingComponent,
    FornecedoresComponent,
    SortDirective,
    VendasComponent
  ],
  imports: [
    NgSelectModule,
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    HttpClientModule,
    NgHttpLoaderModule.forRoot(),
    TabsModule.forRoot(),
    BrowserAnimationsModule,
    AccordionModule.forRoot(),
    FormsModule,
    TextMaskModule,
    NgBrazil,
    PdfJsViewerModule,
    ChartsModule,
    ColorPickerModule
    ],
  providers: [DataBaseService, AuthService,{
    provide: LOCALE_ID,
    useValue: "pt-BR"
  }],
  bootstrap: [AppComponent]
})
export class AppModule { 
  constructor(library: FaIconLibrary) {
    library.addIconPacks(fas, fab);
   }
}
