<div class="container-fluid">
  <div class="row" style="text-align: center; font-weight: bold; font-size: 1.2em; color:#505050;margin-top:10px">
    <div class="col">
      <strong>DASHBOARD</strong>
    </div>
    <div class="col col-2" style="text-align:right">
      <button class="btn btn-dark" (click)="ngOnInit()">ATUALIZAR</button>
    </div>
  </div>
  <br><br>
  <div class="row">
    <div class="col botaoEstatistica clean">
      <div class="conteudoBotao">
        <fa-icon [icon]="['fas', 'user-friends']" size="2x" style="color: white"></fa-icon><br>
          <span>{{totalAtivos}}</span>
          <p>ASSOCIADOS ATIVOS</p>
      </div>
    </div>  
    <div class="col botaoEstatistica clean">
      <div class="conteudoBotao">
        <fa-icon [icon]="['fas', 'users-slash']" size="2x" style="color: white"></fa-icon><br>
        <span>{{totalCancelados}}</span>
        <p>ASSOCIADOS CANCELADOS</p>
      </div>
    </div> 
    <div class="col botaoEstatistica clean">
      <div class="conteudoBotao">
        <fa-icon [icon]="['fas', 'user-clock']" size="2x" style="color: white"></fa-icon><br>
        <span>{{totalPendentes}}</span>
        <p>ASSOCIADOS PENDENTES</p>
      </div>
    </div> 
    <div class="col botaoEstatistica clean">
      <div class="conteudoBotao">
        <fa-icon [icon]="['fas', 'hand-holding-usd']" size="2x" style="color: white"></fa-icon><br>
        <span>{{ticketMedioMes | currency:'BRL'}}</span>
        <p>TICKET MÉDIO {{mes}}</p>
      </div>
    </div> 
    <div class="col botaoEstatistica clean">
      <div class="conteudoBotao">
        <fa-icon [icon]="['fas', 'hand-holding-usd']" size="2x" style="color: white"></fa-icon><br>
        <span>{{ticketMedio | currency:'BRL'}}</span>
        <p>TOTAL TICKET MÉDIO</p>
      </div>
    </div> 
  </div>
  <br>
  <div class="row" *ngIf="db?.usuario?.permissoes['dashboardAdm'] == 'EDITAR' || db?.usuario?.permissoes['dashboardAdm'] == 'VER'">
    <div class="col col-3 dashCol">
      <div class="dashFinanceiro">
          <p>ENTRADAS MÊS</p>
          <div style="font-size:14px">
            <span>{{totalEntradasMes | currency:'BRL'}}</span>
          </div>
          <div style="font-size:10px">
            <span [style.color]="percentualTotalEntradasMes < 0 ? 'red' : 'green'">{{percentualTotalEntradasMes | number : '2.0-2'}} %</span>
          </div>
      </div>
    </div>  
    <div class="col col-3 dashCol">
      <div class="dashFinanceiro">
          <p>SAIDAS MÊS</p>
          <div style="font-size:14px">
            <span>{{totalSaidasMes | currency:'BRL'}}</span>
          </div>
          <div style="font-size:10px">
            <span [style.color]="percentualTotalSaidasMes > 0 ? 'red' : 'green'">{{percentualTotalSaidasMes | number : '2.0-2'}} %</span>
          </div>
      </div>
    </div> 
    <div class="col col-3 dashCol">
      <div class="dashFinanceiro">
          <p>LUCRO LÍQUIDO MÊS</p>
          <div style="font-size:14px">
            <span>{{totalLucroMes | currency:'BRL'}}</span>
          </div>
          <div style="font-size:10px">
            <span [style.color]="percentualTotalLucroMes < 0 ? 'red' : 'green'">{{percentualTotalLucroMes | number : '2.0-2'}} %</span>
          </div>
      </div>
    </div>
    <div class="col col-3 dashCol">
      <div class="dashFinanceiro">
          <p>PLANOS ATIVOS MÊS</p>
          <div style="font-size:14px">
            <span style="font-size:12px">{{todosAtivosMesAnterior }}</span> /
            <span>{{totalAtivos }}</span>
          </div>
          <div style="font-size:10px">
            <span [style.color]="todosAtivosMesAnterior < totalAtivos ? 'red' : 'green'">{{percentualAtivos | number : '2.0-2'}} %</span>
          </div>
      </div>
    </div>
  <!--   <div class="col col-3 dashCol">
      <div class="dashFinanceiro">
          <p>PLANOS CANCELADOS MÊS</p>
          <div style="font-size:14px">
            <span>{{todosCanceladosMes }}</span>
          </div>
          <div style="font-size:10px">
            <span [style.color]="percentualAtivos < 0 ? 'red' : 'green'">{{percentualAtivos | number : '2.0-2'}} %</span>
          </div>
      </div>
    </div> -->
  </div>
  <br>
  <div *ngIf="barChartData.length > 0">
    <div class="row" style="max-height: 500px;">
      <div class="col col-2" (click)="getChart(ano-1)">
        <div class="nav">
          <fa-icon [icon]="['fas', 'backward']" size="1x"></fa-icon>&nbsp;&nbsp;
          <strong style="font-size:0.8em">Anterior</strong>
        </div>
      </div>
      <div class="col col-8" style="text-align: center;" >
        <strong>{{ano}}</strong>
      </div>
      <div class="col col-2" (click)="getChart(ano+1)">
        <div class="nav">
          <strong style="font-size:0.8em">Próximo</strong>&nbsp;&nbsp;
          <fa-icon [icon]="['fas', 'forward']" size="1x"></fa-icon>
        </div>
      </div>
    </div>
    <br>
      <canvas baseChart [datasets]="barChartData" [labels]="barChartLabels" [options]="barChartOptions" [legend]="barChartLegend" [chartType]="barChartType"></canvas>
  </div>
  <br>
  <div *ngIf="db?.usuario?.permissoes['dashboardAdm'] == 'EDITAR' || db?.usuario?.permissoes['dashboardAdm'] == 'VER'">
  <div style="background-color:#d25959; color:#fff; width: 100%;text-align: center;padding:10px;margin-bottom: 15px;">
      <strong>PERCENTUAL CANCELAMENTO</strong>
  </div>
  <div class="row align-items-center">
      <div class="col col-3">
          <label>Data Inicio</label>
          <input type="date" class="form-control" [(ngModel)]="dataInicio">
      </div>
      <div class="col col-3">
          <label>Data Fim</label>
          <input type="date" class="form-control" [(ngModel)]="dataFim">
      </div>
      <div class="col col-2">
          <br>
          <button class="btn btn-primary" (click)="buscarPercentual()">BUSCAR</button>
      </div>
  </div>
  <br>
  <table class="table table-striped" style="text-align: center;">
      <thead class="thead-dark">
          <tr>
              <th>Motivo</th>
              <th>Total</th>
              <th>Percentual</th>
          </tr>
      </thead>
      <tbody>
          <tr *ngFor="let motivo of motivosCancelamento; let i = index">
              <td>{{ motivo?.nome }}</td>
              <td>{{ motivo?.total }}</td>
              <td>{{ (motivo?.total / totalCancelamentos) *100 | number : '2.0-2' }}%</td>
          </tr>
          <tr style="font-weight: 700;">
              <td>TOTAIS</td>
              <td>{{ totalCancelamentos }}</td>
              <td>100%</td>
          </tr>
      </tbody>
  </table>
  <br>
  <table class="table table-striped" style="text-align: center;">
    <thead class="thead-dark">
        <tr>
            <th>Responsável</th>
            <th>Sub-Contrato</th>
            <th>Cancelados</th>
            <th>Faturamento</th>
            <th>Adesões</th>
            <th>Resultado Adesões</th>
            <th>Faturamento</th>
            <th>Resultado Total</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let relFat of relatorioFaturamento; let i = index">
            <td>{{ relFat?.responsavel }}</td>
            <td>{{ relFat?.subContrato }}</td>
            <td>{{ relFat?.cancelados }}</td>
            <td>{{ relFat?.fatCancelados | currency : 'BRL' }}</td>
            <td>{{ relFat?.adesoes }}</td>
            <td [style.color]="relFat?.adesoes- relFat?.cancelados< 0 ? 'red' : 'green'">{{ relFat?.adesoes - relFat?.cancelados}}</td>
            <td>{{ relFat?.faturamento | currency : 'BRL' }}</td>
            <td [style.color]="relFat?.faturamento - relFat?.fatCancelados < 0 ? 'red' : 'green'">{{ relFat?.faturamento - relFat?.fatCancelados  | currency : 'BRL'}}</td>
        </tr>
        <tr style="font-weight: 700;">
          <td colspan="2">TOTAIS DE PLANOS SERVIDORES</td>
            <td>{{ totalCancelamentosAno }}</td>
            <td>{{ totalFaturamentoAcumulado | currency : 'BRL'}}</td>
            <td>{{ totalAdesoes }}</td>
            <td [style.color]="totalAdesoes - totalCancelamentosAno< 0 ? 'red' : 'green'">{{ totalAdesoes - totalCancelamentosAno}}</td>
            <td>{{ totalFaturamento | currency : 'BRL'}}</td>
            <td [style.color]="totalFaturamento - totalFaturamentoAcumulado < 0 ? 'red' : 'green'">{{ totalFaturamento - totalFaturamentoAcumulado | currency : 'BRL'}}</td>
        </tr>
    </tbody>
    <tbody>
      <tr *ngFor="let relFatFis of relFaturamentoFisico; let i = index" style="font-weight: 700;">
          <td colspan="2">TOTAIS DE PLANOS FÍSICOS</td>
          <td>{{ relFatFis?.cancelados }}</td>
          <td>{{ relFatFis?.fatCancelados | currency : 'BRL' }}</td>
          <td>{{ relFatFis?.adesoes }}</td>
          <td [style.color]="relFatFis?.adesoes- relFatFis?.cancelados< 0 ? 'red' : 'green'">{{ relFatFis?.adesoes - relFatFis?.cancelados}}</td>
          <td>{{ relFatFis?.faturamento | currency : 'BRL' }}</td>
          <td [style.color]="relFatFis?.faturamento - relFatFis?.fatCancelados < 0 ? 'red' : 'green'">{{ relFatFis?.faturamento - relFatFis?.fatCancelados | currency : 'BRL' }}</td>
      </tr>
  </tbody>
</table>
</div>
  <div style="height: 4em;"></div>
</div>