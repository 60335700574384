import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DataBaseService } from '../provider.service';
import { MASKS } from 'ng-brazil';
import * as moment from 'moment';
import { TabsetComponent } from 'ngx-bootstrap/tabs';
import { Router } from '@angular/router';
import { AppComponent } from '../app.component';

@Component({
  selector: 'app-empresas',
  templateUrl: './empresas.component.html',
  styleUrls: ['./empresas.component.css']
})

export class EmpresasComponent implements OnInit {
  public MASKS = MASKS;
  @ViewChild('pdfViewerEmpresas') pdfViewerOnDemand;
  empresas:any = [];
  docs:any = [];
  pdfs:any = [];
  images:any = [];
  cep:any = "";
  rua:any = "";
  numero:any = "";
  bairro:any = "";
  municipio:any = "";
  estado:any = "";
  complemento:any = "";
  telefone:any = "";
  cargoResponsavel:any = "";
  razaoEmpresa:any = "";
  fantasiaEmpresa:any = "";
  cnpj:any = "";
  atividadeEmpresa:any = "";
  nomeContato:any = "";
  email:any = "";
  email2:any = "";
  email3:any = "";
  seguradora:any = "";
  seguradoraEditar:any = "";
  dataAdesao:any = "";
  dataAdesaoEditar:any = "";
  dataVencimento:any = "";
  dataVencimentoEditars:any = "";
  dataFaturamento:any = "";
  dataFaturamentoEditar:any = "";
  cepEditar:any = "";
  ruaEditar:any = "";
  numeroEditar:any = "";
  bairroEditar:any = "";
  municipioEditar:any = "";
  estadoEditar:any = "";
  complementoEditar:any = "";
  telefoneEditar:any = "";
  responsavelEditar:any = "";
  razaoEmpresaEditar:any = "";
  cargoResponsavelEditar:any = "";
  fantasiaEmpresaEditar:any = "";
  cnpjEditar:any = "";
  atividadeEmpresaEditar:any = "";
  nomeContatoEditar:any = "";
  emailEditar:any = "";
  email2Editar:any = "";
  email3Editar:any = "";
  dataContratoEditar:any = "";
  idEmpresa:any = "";
  nomeBusca:any = "";
  cidadeBusca:any = "";
  planosEmpresariais:any = [];
  plano:any = "";
  funeralConjuge:any = "";
  funeralFilhosMenor:any = "";
  funeralFilhosMaior:any = "NÃO";
  funeralExtra:any = "NÃO";
  valorFuneralFilhosMenor:number = 0;
  valorFuneralFilhosMaior:number = 0;
  valorFuneralConjuge:number = 0;
  valorFuneralExtra:number = 0;
  capitalSegurado:FormGroup;
  capitalSeguradoEditar:FormGroup;
  public capitalSeguradoList: FormArray;
  public capitalSeguradoListEditar: FormArray;
  get capitalSeguradoFormGroup(){return this.capitalSegurado.get('capital') as FormArray;}
  get capitalSeguradoFormGroupEditar(){return this.capitalSeguradoEditar.get('capitalEditar') as FormArray;}
  valorPlano:number = 0;
  valorSeguro:number = 0;
  valorComissao:number = 0;
  dataHoje:any = "";
  user:any = "";
  funeralConjugeEditar:any = "";
  funeralFilhosMenorEditar:any = "";
  funeralFilhosMaiorEditar:any = "NÃO";
  funeralExtraEditar:any = "NÃO";
  valorFuneralFilhosMenorEditar:any = null;
  valorFuneralFilhosMaiorEditar:any = null;
  valorFuneralConjugeEditar:any = null;
  valorFuneralExtraEditar:any = null;
  valorPlanoEditar:any = null;
  valorSeguroEditar:any = null;
  valorComissaoEditar:any = null;
  dataVencimentoEditar:any = "";
  planoEditar:any = "";
  associadosEmpresa:any = [];
  todosAssociados:any = [];
  situacaoEditar:any = "";
  situacaoOriginal:any = "";
  idAssociado:any = "";
  idVendedorNovo:any = "";
  gerenteNovo:any = "";
  dataAdesaoNovo:any = "";
  origemNovo:any = "";
  vendedorNovo:any = "";
  cpfNovo:any = "";
  cpfCheck:boolean = false;
  nascimentoNovo:any = "";
  nomeNovo:any = "";
  estadoCivilNovo:any = "";
  sexoNovo:any = "";
  telefoneNovo:any = "";
  celularNovo:any = "";
  whatsappNovo:any = "";
  cepNovo:any = "";
  ruaNovo:any = "";
  numeroNovo:any = "";
  bairroNovo:any = "";
  complementoNovo:any = "";
  municipioNovo:any = "";
  estadoNovo:any = "";
  funcaoNovo:any = "";
  emailNovo:any = "";
  doencaNovo:any = "";
  especificarDoencaNovo:any = "";
  empresa:any = [];
  vendedores:any = [];
  vendedor:any = "";
  idVendedor:any = "";
  gerente:any = "";
  files:any = [];
  selectedFiles:any = [];
  filesEditar:any = [];
  selectedFilesEditar:any = [];
  matriculaNovo:number = 0;
  idVendedorEditar:any = "";
  gerenteEditar:any = "";
  vendedorEditar:any = "";
  randomico:any = "";
  digitado:any = "";
  allEmpresas:any = [];
  indexImage:any = "";
  imagemSelecionada:any = "";
  descontoEmpresa:number = 0;
  descontoEmpresaEditar:number = 0;
  financeiro:any = [];
  urlPdf:any = "";
  dependentes:FormGroup;
  dependentesExtra:FormGroup;
  public listaDeDependentes:FormArray;
  public listaDeDependentesExtra:FormArray;
  get dependentesFormGroup(){return this.dependentes.get('dependente') as FormArray;};
  get dependentesExtraFormGroup(){return this.dependentesExtra.get('dependenteExtra') as FormArray;};

  constructor(public db:DataBaseService, private formBuilder: FormBuilder, public _router: Router, public app:AppComponent){ }

  @ViewChild('staticTabs', { static: false }) staticTabs: TabsetComponent;

  selectTab(tabId: number){
    this.staticTabs.tabs[tabId].active = true;
 }

  ngOnInit(){
    this.dataHoje = new Date(Date.now() - 10800000).toISOString().split('T')[0];
    this.user = localStorage.getItem('usuarioVivamax');
    this.db.getEmpresas().subscribe((data:any) => {
      this.empresas = data;
      this.allEmpresas = data;
      this.db.getPlanosEmpresariais().subscribe((p:any) => {
        this.planosEmpresariais = p;
      });
    });
    this.capitalSegurado = this.formBuilder.group({
      capital: this.formBuilder.array([this.createCapitalSegurado()])
    });
    this.capitalSeguradoList = this.capitalSegurado.get('capital') as FormArray;
    this.capitalSeguradoEditar = this.formBuilder.group({
      capitalEditar: this.formBuilder.array([this.createCapitalSeguradoEditar()])
    });
    this.capitalSeguradoListEditar = this.capitalSeguradoEditar.get('capitalEditar') as FormArray;
    this.dependentes = this.formBuilder.group({
      dependente: this.formBuilder.array([])
      });
    this.dependentesExtra = this.formBuilder.group({
      dependenteExtra: this.formBuilder.array([])
    });
    this.listaDeDependentes = this.dependentes.get('dependente') as FormArray;
    this.listaDeDependentesExtra = this.dependentesExtra.get('dependenteExtra') as FormArray;
    this.db.getVendedoresAtivos().subscribe((vend:any) => {
      this.vendedores = vend;
    });
}

createDependente():FormGroup{
  return this.formBuilder.group({
    tipo:[null, Validators.compose([Validators.required])],
    nome: [null, Validators.compose([Validators.required])],
    nascimento:[null, Validators.compose([Validators.required])],
    cpf: [null],
    asf:[null],
    situacao:'ATIVO'
  });
}

addDependente(){
  this.listaDeDependentes.push(this.createDependente());
}

removeDependente(index){
  this.listaDeDependentes.removeAt(index);
}

createDependenteExtra():FormGroup{
  return this.formBuilder.group({
    nome: [null, Validators.compose([Validators.required])],
    nascimento:[null, Validators.compose([Validators.required])],
    cpf: [null],
    tipo:[null, Validators.compose([Validators.required])],
    asf:[null],
    situacao:'ATIVO'
  });
}

addDependenteExtra(){
  this.listaDeDependentesExtra.push(this.createDependenteExtra());
}

removeDependenteExtra(index){
  this.listaDeDependentesExtra.removeAt(index);
}

createCapitalSegurado():FormGroup{
  return this.formBuilder.group({
    cobertura: [null, Validators.compose([Validators.required])],
    capital: [null, Validators.compose([Validators.required])]
  });
}

addCapitalSegurado(){
  this.capitalSeguradoList.push(this.createCapitalSegurado());
}

  removeCapitalSegurado(index) {
    this.capitalSeguradoList.removeAt(index);
  }

  createCapitalSeguradoEditar():FormGroup{
    return this.formBuilder.group({
      cobertura: [null, Validators.compose([Validators.required])],
      capital: [null, Validators.compose([Validators.required])]
    });
  }

  createCapitalSeguradoEditarStart(item):FormGroup{
    return this.formBuilder.group({
      cobertura: [item.cobertura, Validators.compose([Validators.required])],
      capital: [item.capital, Validators.compose([Validators.required])]
    });
  }

  addCapitalSeguradoEditar(){
    this.capitalSeguradoListEditar.push(this.createCapitalSeguradoEditar());
  }

  addCapitalSeguradoEditarStart(item){
    this.capitalSeguradoListEditar.push(this.createCapitalSeguradoEditarStart(item));
  }

  removeCapitalSeguradoEditar(index) {
    this.capitalSeguradoListEditar.removeAt(index);
  }

  selectPlano(plano){
    if(plano != 'PERSONALIZADO'){
      let idx = this.planosEmpresariais.map((e) => {
        return e.nome;
      }).indexOf(plano);
      if(idx >=0){
        this.funeralConjuge = this.planosEmpresariais[idx].funeralConjuge;
        this.funeralFilhosMenor = this.planosEmpresariais[idx].funeralFilhosMenor;
        this.funeralFilhosMaior = this.planosEmpresariais[idx].funeralFilhosMaior;
        this.funeralExtra = this.planosEmpresariais[idx].funeralExtra;
        this.valorFuneralFilhosMenor = this.planosEmpresariais[idx].valorFuneralFilhosMenor;
        this.valorFuneralFilhosMaior = this.planosEmpresariais[idx].valorFuneralFilhosMaior;
        this.valorFuneralConjuge = this.planosEmpresariais[idx].valorFuneralConjuge;
        this.valorFuneralExtra = this.planosEmpresariais[idx].valorFuneralExtra;
        this.valorPlano = this.planosEmpresariais[idx].mensalidade;
        this.valorSeguro = this.planosEmpresariais[idx].valorCapitalSegurado;
        this.valorComissao = this.planosEmpresariais[idx].comissao;
      }
    }
  }

  selectPlanoEditar(plano){
    if(plano != 'PERSONALIZADO'){
      let idx = this.planosEmpresariais.map((e) => {
        return e.nome;
      }).indexOf(plano);
      if(idx >=0){
        this.funeralConjugeEditar = this.planosEmpresariais[idx].funeralConjuge;
        this.funeralFilhosMenorEditar = this.planosEmpresariais[idx].funeralFilhosMenor;
        this.funeralFilhosMaiorEditar = this.planosEmpresariais[idx].funeralFilhosMaior;
        this.funeralExtraEditar = this.planosEmpresariais[idx].funeralExtra;
        this.valorFuneralFilhosMenorEditar = this.planosEmpresariais[idx].valorFuneralFilhosMenor;
        this.valorFuneralFilhosMaiorEditar = this.planosEmpresariais[idx].valorFuneralFilhosMaior;
        this.valorFuneralConjugeEditar = this.planosEmpresariais[idx].valorFuneralConjuge;
        this.valorFuneralExtraEditar = this.planosEmpresariais[idx].valorFuneralExtra;
        this.valorPlanoEditar = this.planosEmpresariais[idx].mensalidade;
        this.valorSeguroEditar = this.planosEmpresariais[idx].valorCapitalSegurado;
        this.valorComissaoEditar = this.planosEmpresariais[idx].comissao;
      }
    }else{
      if(this.capitalSeguradoListEditar.length < 1){
        this.capitalSeguradoEditar = this.formBuilder.group({
          capitalEditar: this.formBuilder.array([this.createCapitalSeguradoEditar()])
        });
      }
    }
  }

  setValorConjuge(k){
    if(k != "SIM"){
      this.valorFuneralConjuge = null;
    }
  }

  setValorConjugeEditar(k){
    if(k != "SIM"){
      this.valorFuneralConjugeEditar = null;
    }
  }

  setValorMenor(k){
    if(k != "SIM"){
      this.valorFuneralFilhosMenor = null;
    }
  }

  setValorMenorEditar(k){
    if(k != "SIM"){
      this.valorFuneralFilhosMenorEditar = null;
    }
  }

  setValorMaior(k){
    if(k != "SIM"){
      this.valorFuneralFilhosMaior = null;
    }
  }

  setValorMaiorEditar(k){
    if(k != "SIM"){
      this.valorFuneralFilhosMaiorEditar = null;
    }
  }

  setValorExtra(k){
    if(k != "SIM"){
      this.valorFuneralExtra = null;
    }
  }

  setValorExtraEditar(k){
    if(k != "SIM"){
      this.valorFuneralExtraEditar = null;
    }
  }

  calcularFaturamento(dataVencimento){
    this.dataFaturamento = moment(dataVencimento).subtract(10,'days').toISOString().split('T')[0];
  }

  calcularFaturamentoEditar(dataVencimento){
    this.dataFaturamentoEditar = moment(dataVencimento).subtract(10,'days').toISOString().split('T')[0];
  }

  openModalNovo(){
    let modalNovaEmpresa = document.getElementById('novaEmpresa');
    modalNovaEmpresa.style.display = "block";
    modalNovaEmpresa.scroll({ 
      top: 0, 
      left: 0, 
      behavior: 'smooth' 
    });
  }

  closeModalNovo(){
    let modalNovaEmpresa = document.getElementById('novaEmpresa');
    modalNovaEmpresa.style.display = "none";
    this.cep = "";
    this.rua = "";
    this.numero = "";
    this.bairro = "";
    this.municipio = "";
    this.estado = "";
    this.complemento = "";
    this.telefone = "";
    this.razaoEmpresa = "";
    this.fantasiaEmpresa = "";
    this.cnpj = "";
    this.atividadeEmpresa = "";
    this.nomeContato = "";
    this.cargoResponsavel = "";
    this.email = "";
    this.email2 = "";
    this.email3 = "";
    this.descontoEmpresa = 0;
    this.funeralConjuge = "";
    this.funeralFilhosMenor = "";
    this.funeralFilhosMaior = "NÃO";
    this.funeralExtra = "NÃO";
    this.valorFuneralFilhosMenor = 0;
    this.valorFuneralFilhosMaior = 0;
    this.valorFuneralConjuge = 0;
    this.valorFuneralExtra = 0;
    this.valorPlano = 0;
    this.valorSeguro = 0;
    this.valorComissao = 0;
    this.seguradora = "";
    this.dataAdesao = "";
    this.dataVencimento = "";
    this.dataFaturamento = "";
    this.plano = "";
    this.capitalSeguradoList.clear();
    this.addCapitalSegurado();
  }

  buscarCep(cepGet){
    let c = cepGet.replace(/_/gi,'').replace('.','').replace('-','');
    if(c.length > 7){
      this.db.getCep(c).subscribe((data:any) => {
        let cep = data;
         if(cep.erro == true){
           this.app.openAlert("CEP NAO ENCONTRADO");
         }else{
           this.rua = cep.logradouro.toUpperCase();
           this.bairro = cep.bairro.toUpperCase();
           this.municipio = cep.localidade.toUpperCase();
           this.estado = cep.uf.toUpperCase();
         }
      });
    }
  }

  buscarCepEditar(cepGet){
    let c = cepGet.replace(/_/gi,'').replace('.','').replace('-','');
    if(c.length > 7){
      this.db.getCep(c).subscribe((data:any) => {
        let cep = data;
         if(cep.erro == true){
           this.app.openAlert("CEP NAO ENCONTRADO");
         }else{
           this.ruaEditar = cep.logradouro.toUpperCase();
           this.bairroEditar = cep.bairro.toUpperCase();
           this.municipioEditar = cep.localidade.toUpperCase();
           this.estadoEditar = cep.uf.toUpperCase();
         }
      });
    }
  }

  buscarCepNovo(cepGet){
    let c = cepGet.replace(/_/gi,'').replace('.','').replace('-','');
    if(c.length > 7){
      this.db.getCep(c).subscribe((data:any) => {
        let cep = data;
         if(cep.erro == true){
           this.app.openAlert("CEP NAO ENCONTRADO");
         }else{
           this.ruaNovo = cep.logradouro.toUpperCase();
           this.bairroNovo = cep.bairro.toUpperCase();
           this.municipioNovo = cep.localidade.toUpperCase();
           this.estadoNovo = cep.uf.toUpperCase();
         }
      });
    }
  }

  cadastrarEmpresa(){
    let random = (Math.random() * 200).toString().split('.')[1];
    let initCode = this.dataAdesao.split('-')[1] + this.dataAdesao.split('-')[0];
    let code = initCode + random.slice(2,7);
    let cobArray = [];
    if(this.plano == 'PERSONALIZADO' && this.capitalSeguradoList.length > 0){
      cobArray = this.capitalSeguradoList.value;
    }
    let dados = {
      codigo:code,
      cep : this.cep,
      rua : this.rua,
      numero : this.numero,
      bairro : this.bairro,
      municipio : this.municipio,
      estado : this.estado,
      complemento : this.complemento,
      telefone : this.telefone,
      razaoEmpresa : this.razaoEmpresa,
      fantasiaEmpresa : this.fantasiaEmpresa,
      cnpj : this.cnpj,
      atividadeEmpresa : this.atividadeEmpresa,
      nomeContato : this.nomeContato,
      email : this.email,
      email2 : this.email2,
      email3 : this.email3,
      desconto: this.descontoEmpresa,
      cargoResponsavel: this.cargoResponsavel,
      funeralConjuge: this.funeralConjuge,
      funeralFilhosMenor: this.funeralFilhosMenor,
      funeralFilhosMaior: this.funeralFilhosMaior,
      funeralExtra: this.funeralExtra,
      valorFuneralFilhosMenor: this.valorFuneralFilhosMenor,
      valorFuneralFilhosMaior: this.valorFuneralFilhosMaior,
      valorFuneralConjuge: this.valorFuneralConjuge,
      valorFuneralExtra: this.valorFuneralExtra,
      valorPlano: this.valorPlano,
      valorSeguro: this.valorSeguro,
      comissao: this.valorComissao,
      seguradora: this.seguradora,
      dataAdesao: this.dataAdesao,
      dataVencimento: this.dataVencimento,
      dataFaturamento: this.dataFaturamento,
      plano: this.plano,
      coberturas:cobArray,
      situacao:"ATIVO",
      vendedor: this.vendedor.split(',')[0],
      idVendedor : this.idVendedor,
      gerenteResponsavel : this.gerente,
      anexos:[],
      senha:this.cnpj.replace(/[^0-9]/g, "")
    }
    this.db.postEmpresa(dados).subscribe(res => {
      console.log(res);
      let jsonDate = new Date().toJSON();
      let hora = new Date(jsonDate).toLocaleTimeString();
      let body = {
        "menu" : "EMPRESAS", 
        "descricao" : "Empresa "+this.fantasiaEmpresa+" cadastrada!", 
        "data" : this.dataHoje, 
        "hora" : hora, 
        "usuario" : this.user
      }
      this.db.postLogs(body).subscribe(result => {
        console.log(result);
        let idTaskEmpresa:any = "";
        let responsavelTaskEmpresa:any ="";
        let idxEmpresa = this.db.emails.map((e:any) => {
          return JSON.stringify(Object.keys(e));
        }).indexOf('["cadastroEmpresa"]');
        if(idxEmpresa >= 0){
          let dadosTaskEmpresa:any = Object.values(this.db.emails[idxEmpresa])[0];
          idTaskEmpresa = dadosTaskEmpresa.idResponsavel;
          responsavelTaskEmpresa = dadosTaskEmpresa.nomeResponsavel;
        }
        let randomGeraLink = Math.random().toString(36).slice(-8);
        let taskEmpresa = {
          "id": randomGeraLink,
          "data" : this.dataHoje,
          "hora" : hora,
          "titulo" : "CONFERIR DADOS DA EMPRESA",
          "descricao" : "Empresa Cadastrada: "+ this.razaoEmpresa,
          "idResponsavel" : idTaskEmpresa,
          "responsavel" : responsavelTaskEmpresa,
          "resolvido":false,
          "dataResolucao" : "",
          "horaResolucao" : "",
          "prioridade" : "ALTA",
          "origem":"Sistema",
          "idUserSolicitacao":localStorage.getItem('idUserVivamax'),
          "nomeSolicitante":localStorage.getItem('usuarioVivamax')
        }
        this.db.postTarefa(taskEmpresa).subscribe(resTask => {
          console.log(resTask);
          if(this.selectedFiles.length > 0){
            const imagedata = new FormData();
            for(let i=0; i<this.selectedFiles.length; i++){imagedata.append('files[]', this.selectedFiles[i], this.selectedFiles[i].name)};
             this.db.enviarDocEmpresa(res['insertedId'], imagedata).subscribe((result) => {
                 console.log(result);
                 this.app.openAlert("Empresa Cadastrada com Sucesso!");
                 this.todasEmpresas();
                 this.closeModalNovo();
               },(error) => {
                 console.log(error);
                 this.app.openAlert("Empresa Cadastrada com Sucesso!");
                 this.todasEmpresas();
                 this.closeModalNovo();
               });
          }else{
            this.app.openAlert("Empresa Cadastrada com Sucesso!");
            this.todasEmpresas();
            this.closeModalNovo();
          }
        })
        }, err => console.log(err));
      }, err => {
      console.log(err);
      this.app.openAlert("Erro ao cadastrar Empresa, por favor tente novamente!")
    })
  }

  editEmpresaModal(){
    if(this.situacaoEditar != this.situacaoOriginal){
      this.digitado = "";
      let r = (Math.random() * 200).toString().split('.')[1];
      this.randomico =r.slice(2,10);
      let modSenha = document.getElementById('senhaModal');
      modSenha.style.display = "block";
    }else{
      this.closeModalSenha();
      this.editarEmpresa();
    }
  }

  closeModalSenha(){
    let modSenha = document.getElementById('senhaModal');
    modSenha.style.display = "none";
    this.digitado = "";
    this.randomico = "";
  }

  editarEmpresa(){
    let cobArrayEditar = [];
    if(this.planoEditar == 'PERSONALIZADO' && this.capitalSeguradoListEditar.length > 0){
      cobArrayEditar = this.capitalSeguradoListEditar.value;
    }
    let dados = {
      cep : this.cepEditar,
      rua : this.ruaEditar,
      desconto: this.descontoEmpresaEditar,
      numero : this.numeroEditar,
      bairro : this.bairroEditar,
      municipio : this.municipioEditar,
      estado : this.estadoEditar,
      complemento : this.complementoEditar,
      telefone : this.telefoneEditar,
      razaoEmpresa : this.razaoEmpresaEditar,
      fantasiaEmpresa : this.fantasiaEmpresaEditar,
      cnpj : this.cnpjEditar,
      atividadeEmpresa : this.atividadeEmpresaEditar,
      nomeContato : this.nomeContatoEditar,
      email : this.emailEditar,
      email2: this.email2Editar,
      email3: this.email3Editar,
      cargoResponsavel: this.cargoResponsavelEditar,
      funeralConjuge: this.funeralConjugeEditar,
      funeralFilhosMenor: this.funeralFilhosMenorEditar,
      funeralFilhosMaior: this.funeralFilhosMaiorEditar,
      funeralExtra: this.funeralExtraEditar,
      valorFuneralFilhosMenor: this.valorFuneralFilhosMenorEditar,
      valorFuneralFilhosMaior: this.valorFuneralFilhosMaiorEditar,
      valorFuneralConjuge: this.valorFuneralConjugeEditar,
      valorFuneralExtra: this.valorFuneralExtraEditar,
      valorPlano: this.valorPlanoEditar,
      valorSeguro: this.valorSeguroEditar,
      comissao: this.valorComissaoEditar,
      seguradora: this.seguradoraEditar,
      dataAdesao: this.dataAdesaoEditar,
      dataVencimento: this.dataVencimentoEditar,
      dataFaturamento: this.dataFaturamentoEditar,
      plano: this.planoEditar,
      coberturas:cobArrayEditar,
      situacao:this.situacaoEditar,
      idVendedor : this.idVendedorEditar,
      gerenteResponsavel : this.gerenteEditar,
      vendedor : this.vendedorEditar.split(',')[0]
    }
    let jsonDate = new Date().toJSON();
    let hora = new Date(jsonDate).toLocaleTimeString();
    this.db.patchEmpresa(this.idEmpresa,dados).subscribe(res => {
      console.log(res);
      let body = {
        "menu" : "EMPRESAS", 
        "descricao" : "Empresa "+this.fantasiaEmpresaEditar+" alterada!", 
        "data" : this.dataHoje, 
        "hora" : hora, 
        "usuario" : this.user
      }
      this.db.postLogs(body).subscribe(result => {
        console.log(result);
        this.app.openAlert("Empresa Editada com Sucesso!");
        if(this.situacaoOriginal != this.situacaoEditar){
          let modSenha = document.getElementById('senhaModal');
          modSenha.style.display = "none";
          if(this.situacaoEditar == "CANCELADO"){
              if(this.todosAssociados && this.todosAssociados.length > 0){
                this.todosAssociados.forEach((associado) => {
                  if(associado.situacao != "CANCELADO"){
                  let dados = {
                    motivoCancelamento : "CANCELAMENTO DE CONTRATO EMPRESARIAL",
                    observacoesCancelamento : "CANCELAMENTO DE CONTRATO EMPRESARIAL",
                    spcCancelamento : false,
                    situacao : "CANCELADO",
                    dataCancelamento: this.dataHoje
                  }
                  this.db.patchAssociado(associado._id,dados).subscribe(res => {
                    console.log(res);
                    let bodyC = {
                      "menu" : "EDITAR EMPRESA", 
                      "descricao" : associado.nome+" - Status alterado para: CANCELADO. Motivo: Empresa Cancelada!", 
                      "data" : this.dataHoje, 
                      "hora" : hora, 
                      "usuario" : this.user
                    }
                    this.db.postLogs(bodyC).subscribe(result => {
                      console.log(result);
                      let dadosH = {
                        "data" : this.dataHoje, 
                        "hora" : hora, 
                        "motivo" : "CANCELAMENTO DE CONTRATO EMPRESARIAL", 
                        "msg" : "CANCELAMENTO DE CONTRATO EMPRESARIAL", 
                        "usuario" : localStorage.getItem('usuarioVivamax')
                      }
                      this.db.newHistorico(associado._id,dadosH).subscribe(res => {
                        console.log(res);
                        this.cancelarDependentes(associado._id);
                    }, error => {
                      console.log(error)
                    });
                  }, err => {
                    console.log(err)
                  });
                });
              }
              });
          }
        }else if(this.situacaoEditar == "ATIVO"){
          if(this.todosAssociados && this.todosAssociados.length > 0){
            this.todosAssociados.forEach((associado) => {
              if(associado.situacao != "CANCELADO"){
              let dados = {
                motivoCancelamento : "",
                observacoesCancelamento : "",
                spcCancelamento : false,
                situacao : "ATIVO",
                dataCancelamento: ""
              }
              this.db.patchAssociado(associado._id,dados).subscribe(res => {
                console.log(res);
                let bodyC = {
                  "menu" : "EDITAR EMPRESA", 
                  "descricao" : associado.nome+" - Status alterado para: ATIVO. Motivo: Empresa Ativada!", 
                  "data" : this.dataHoje, 
                  "hora" : hora, 
                  "usuario" : this.user
                }
                this.db.postLogs(bodyC).subscribe(result => {
                  console.log(result);
                  let dadosH = {
                    "data" : this.dataHoje, 
                    "hora" : hora, 
                    "motivo" : "REATIVAÇÃO", 
                    "msg" : "REATIVAÇÃO DA EMPRESA", 
                    "usuario" : localStorage.getItem('usuarioVivamax')
                  }
                  this.db.newHistorico(associado._id,dadosH).subscribe(res => {
                    console.log(res);
                    this.ativarDependentes(associado._id);
                }, error => {
                  console.log(error)
                });
              }, err => {
                console.log(err)
              });
            });
            }
          });
          }
        }else if(this.situacaoEditar == "INADIMPLENTE I" || this.situacaoEditar == "INADIMPLENTE II"){
          if(this.todosAssociados && this.todosAssociados.length > 0){
            this.todosAssociados.forEach((associado) => {
              if(associado.situacao != "CANCELADO"){
              let dados = {
                motivoCancelamento : "",
                observacoesCancelamento : "",
                spcCancelamento : false,
                situacao : this.situacaoEditar,
                dataCancelamento: ""
              }
              this.db.patchAssociado(associado._id,dados).subscribe(res => {
                console.log(res);
                let bodyC = {
                  "menu" : "EDITAR EMPRESA", 
                  "descricao" : associado.nome+" - Status alterado para: "+this.situacaoEditar+". Motivo: Empresa Alterada para Inadimplente!", 
                  "data" : this.dataHoje, 
                  "hora" : hora, 
                  "usuario" : this.user
                }
                this.db.postLogs(bodyC).subscribe(result => {
                  console.log(result);
                  let dadosH = {
                    "data" : this.dataHoje, 
                    "hora" : hora, 
                    "motivo" : "INADIMPLENTE", 
                    "msg" : "INADIMPLÊNCIA", 
                    "usuario" : localStorage.getItem('usuarioVivamax')
                  }
                  this.db.newHistorico(associado._id,dadosH).subscribe(res => {
                    console.log(res);
                }, error => {
                  console.log(error)
                });
              }, err => {
                console.log(err)
              });
            });
            }
          });
          }
        }
        this.todasEmpresas();
        this.closeModalEditar();
        }else{
          this.todasEmpresas();
          this.closeModalEditar();
        }
      }, errr => console.log(errr));
    }, err => {
      console.log(err);
      this.app.openAlert("Erro ao Editar Empresa, por favor tente novamente!")
    })
  }

  excluirEmpresa(empresa){
    if(window.confirm("Deseja realmente excluir essa Empresa?")){
      let jsonDate = new Date().toJSON();
      let hora = new Date(jsonDate).toLocaleTimeString();
      this.db.deleteEmpresa(empresa._id).subscribe(res => {
        console.log(res);
        let body = {
          "menu" : "EMPRESAS", 
          "descricao" : "Empresa "+empresa.fantasiaEmpresa+" excluida!", 
          "data" : this.dataHoje, 
          "hora" : hora, 
          "usuario" : this.user
        }
        this.db.postLogs(body).subscribe(result => {
          console.log(result);
          this.app.openAlert("Empresa Excluida com Sucesso!");
          this.todasEmpresas();
          }, errr => console.log(errr));
        },err => {
        console.log(err)
      });
    }
  }
  
  openModalEditar(empresa){
    this.db.getFinanceiro(empresa.razaoEmpresa).subscribe((result:any) => {
      this.financeiro = result.sort((a,b) => {if(a.data > b.data)return -1});
      this.descontoEmpresaEditar = 0;
      this.cepEditar = "";
      this.ruaEditar = "";
      this.numeroEditar = "";
      this.bairroEditar = "";
      this.municipioEditar = "";
      this.estadoEditar = "";
      this.complementoEditar = "";
      this.telefoneEditar = "";
      this.razaoEmpresaEditar = "";
      this.fantasiaEmpresaEditar = "";
      this.cnpjEditar = "";
      this.atividadeEmpresaEditar = "";
      this.nomeContatoEditar = "";
      this.cargoResponsavelEditar = "";
      this.emailEditar = "";
      this.email2Editar = "";
      this.email3Editar = "";
      this.funeralConjugeEditar = "";
      this.funeralFilhosMenorEditar = "";
      this.funeralFilhosMaiorEditar = "NÃO";
      this.funeralExtraEditar = "NÃO";
      this.valorFuneralFilhosMenorEditar = 0;
      this.valorFuneralFilhosMaiorEditar = 0;
      this.valorFuneralConjugeEditar = 0;
      this.valorFuneralExtraEditar = 0;
      this.valorPlanoEditar = 0;
      this.valorSeguroEditar = 0;
      this.valorComissaoEditar = 0;
      this.seguradoraEditar = "";
      this.dataAdesaoEditar = "";
      this.dataVencimentoEditar = "";
      this.dataFaturamentoEditar = "";
      this.planoEditar = "";
      this.situacaoEditar = "";
      this.vendedorEditar = "";
      this.idVendedorEditar = "";
      this.empresa = empresa;
      this.idEmpresa = empresa._id;
      this.cepEditar = empresa.cep;
      this.ruaEditar = empresa.rua;
      this.numeroEditar = empresa.numero;
      this.bairroEditar = empresa.bairro;
      this.municipioEditar = empresa.municipio;
      this.estadoEditar = empresa.estado;
      this.complementoEditar = empresa.complemento;
      this.telefoneEditar = empresa.telefone;
      this.razaoEmpresaEditar = empresa.razaoEmpresa;
      this.fantasiaEmpresaEditar = empresa.fantasiaEmpresa;
      this.cnpjEditar = empresa.cnpj;
      this.atividadeEmpresaEditar = empresa.atividadeEmpresa;
      this.nomeContatoEditar = empresa.nomeContato;
      this.emailEditar = empresa.email;
      this.email2Editar = empresa.email2;
      this.email3Editar = empresa.email3;
      this.descontoEmpresaEditar = empresa.desconto;
      this.cargoResponsavelEditar = empresa.cargoResponsavel;
      this.funeralConjugeEditar = empresa.funeralConjuge;
      this.funeralFilhosMenorEditar = empresa.funeralFilhosMenor;
      this.funeralFilhosMaiorEditar = "NÃO";
      this.funeralExtraEditar = "NÃO";
      this.valorFuneralFilhosMenorEditar = empresa.valorFuneralFilhosMenor;
      this.valorFuneralFilhosMaiorEditar = null;
      this.valorFuneralConjugeEditar = empresa.valorFuneralConjuge;
      this.valorFuneralExtraEditar = null;
      this.valorPlanoEditar = empresa.valorPlano;
      this.valorSeguroEditar = empresa.valorSeguro;
      this.valorComissaoEditar = empresa.comissao;
      this.seguradoraEditar = empresa.seguradora;
      this.dataAdesaoEditar = empresa.dataAdesao;
      this.dataVencimentoEditar = empresa.dataVencimento;
      this.dataFaturamentoEditar = empresa.dataFaturamento;
      this.planoEditar = empresa.plano;
      this.situacaoEditar = empresa.situacao,
      this.situacaoOriginal = empresa.situacao;
      this.idVendedorEditar = empresa.idVendedor;
      this.gerenteEditar = empresa.gerenteResponsavel;
      this.vendedorEditar = empresa.vendedor;
      this.docs = empresa.anexos;
      this.pdfs = [];
      this.images = [];
      this.selectedFilesEditar = "";
      this.filesEditar = [];
      if(this.docs && this.docs.length > 0){
        this.docs.forEach((item) => {
        let ext = item.url.split('.').pop();
          if(ext == 'pdf' || ext == 'PDF'){
            this.pdfs.push(item);
          }else{
            this.images.push(item);
          }
        })
      }
      if(empresa.coberturas.length > 0){
        this.capitalSeguradoListEditar.clear();
          empresa.coberturas.forEach((item) => {
            this.addCapitalSeguradoEditarStart(item);
          })
        }else{
          this.capitalSeguradoListEditar.clear();
          this.addCapitalSeguradoEditar();
        }
        this.db.getAssociadosByCodigoEmpresa(empresa.codigo).subscribe((data:any) => {
          this.todosAssociados = data;
          this.showAtivos();
        })
        let modal = document.getElementById('editarEmpresaModal');
        modal.style.display = "block";
        modal.scroll({ 
          top: 0, 
          left: 0, 
          behavior: 'smooth' 
        });
    })
  }

  closeModalEditar(){
    this.files = [];
    this.docs = [];
    this.pdfs = [];
    let modal = document.getElementById('editarEmpresaModal');
    modal.style.display = "none";
  }

  buscarEmpresa(nome,cidade){
    this.db.getEmpresasSearch(nome,cidade).subscribe((data:any) => {
      this.empresas = data
    })
  }

  todasEmpresas(){
    this.db.getEmpresas().subscribe((data:any) => {
      this.allEmpresas = data;
      this.empresas = data.sort((a,b)=>{if(a.nome < b.nome)return -1});
      this.nomeBusca = "";
      this.cidadeBusca = "";
    })
  }
  
  replaceStrings(string:any){
    if(!string){return}
    return string.replace(/\%/gi,'%25').replace(/\+/gi,'%2B').replace(/\!/gi,'%21').replace(/\&/gi,'%26').replace(/\;/gi,'%3B');
  }
  
  reverseReplaceStrings(string:any){
    if(!string){return}
    return string.replace(/%2B/gi,'+').replace(/%21/gi,'!').replace(/%25/gi,'%').replace(/%26/gi,'&').replace(/%3B/gi,';');
  }

  verAssociado(key){
    this._router.navigate(['/associados', key])
  }

   verAssociadoConecta(key){
    this._router.navigate(['/associados-conecta', key])
  }
  
 openModalPdfViewer(url){
  this.pdfViewerOnDemand.pdfSrc = url;
  this.pdfViewerOnDemand.refresh();
}

showAtivos(){
  this.associadosEmpresa = this.todosAssociados.filter(item => {
    return item.situacao == "ATIVO";
  });
}

showCancelados(){
  this.associadosEmpresa = this.todosAssociados.filter(item => {
    return item.situacao == "CANCELADO";
  });
}

showInadimplentes(){
  this.associadosEmpresa = this.todosAssociados.filter(item => {
    return item.situacao == "INADIMPLENTE I" || item.situacao == "INADIMPLENTE II";
  });
}

showTodos(){
  this.associadosEmpresa = this.todosAssociados;
}

showAtivosEmpresa(){
  console.log(this.allEmpresas)
  this.empresas = this.allEmpresas.filter(item => {
    return item.situacao == "ATIVO";
  });
}

showCanceladosEmpresa(){
  this.empresas = this.allEmpresas.filter(item => {
    return item.situacao == "CANCELADO";
  });
}

showInadimplentesEmpresa(){
  this.empresas = this.allEmpresas.filter(item => {
    return item.situacao == "INADIMPLENTE I" || item.situacao == "INADIMPLENTE II";
  });
}

cancelarAssociado(associado,i){
  if(window.confirm("Deseja realmente Cancelar esse Associado?")){
    this.idAssociado = associado._id;
    let jsonDate = new Date().toJSON();
    let horaAgora = new Date(jsonDate).toLocaleTimeString();
    let body = {
      motivoCancelamento : "MOVIMENTAÇÃO - EXCLUSÃO",
      observacoesCancelamento : "MOVIMENTAÇÃO - EXCLUSÃO",
      spcCancelamento : false,
      situacao : "CANCELADO",
      dataCancelamento: this.dataHoje
    }
    this.db.patchAssociado(this.idAssociado,body).subscribe(res => {
      console.log(res);
      let dados = {
        "data" : this.dataHoje, 
        "hora" : horaAgora, 
        "motivo" : "CANCELAMENTO", 
        "msg" : "MOVIMENTAÇÃO - EXCLUSÃO", 
        "usuario" : localStorage.getItem('usuarioVivamax')
      }
      this.db.newHistorico(this.idAssociado,dados).subscribe(res => {
        console.log(res);
        let jsonDate = new Date().toJSON();
        let horaAgora = new Date(jsonDate).toLocaleTimeString();
        let body = {
          "menu" : "EMPRESAS", 
          "descricao" : "Cancelamento do Associado: "+associado.nome+" matricula nº "+associado.matricula+", através do menu EMPRESAS", 
          "data" : this.dataHoje, 
          "hora" : horaAgora, 
          "usuario" : this.user
        }
      this.db.postLogs(body).subscribe(res => {
        console.log(res);
        this.associadosEmpresa[i].situacao = "CANCELADO";
        this.cancelarDependentes(this.idAssociado);
      }, err => {
        console.log(err)
      })
      }, err => {
        console.log(err)
      })
    }, err => {
      console.log(err)
    })
  }
}

cancelarDependentes(id){
  this.db.getAssociadosById(id).subscribe((data:any) => {
    let todos = data;
    if(todos.dependentes && todos.dependentes.length > 0){
      let controlDependentes = 1;
      todos.dependentes.forEach((item) => {
        if(item.situacao != "SUSPENSO"){
          item.situacao = "SUSPENSO"
        }
        if(item.sinistro && item.sinistro.numeroSinistro != null){
          item.sinistro.numeroSinistro = item.sinistro.numeroSinistro
        }
        if(item.sinistro && item.sinistro.valorSinistro != null){
          item.sinistro.valorSinistro = item.sinistro.valorSinistro
        }
        if(controlDependentes == todos.dependentes.length){
          let body = {
            dependentes: todos.dependentes
          }
          this.db.patchAssociado(id,body).subscribe(res => console.log(res), err => console.log(err))
        }else{
          controlDependentes++
        }
      })
    }
    if(todos.dependentesExtra && todos.dependentesExtra.length > 0){
      let controlDependentesExtra = 1;
      todos.dependentesExtra.forEach((item) => {
        if(item.situacao != "SUSPENSO"){
          item.situacao = "SUSPENSO"
        }
        if(item.sinistro && item.sinistro.numeroSinistro != null){
          item.sinistro.numeroSinistro = item.sinistro.numeroSinistro
        }
        if(item.sinistro && item.sinistro.valorSinistro != null){
          item.sinistro.valorSinistro = item.sinistro.valorSinistro
        }
        if(controlDependentesExtra == todos.dependentesExtra.length){
          let body = {
            dependentesExtra: todos.dependentesExtra
          }
          this.db.patchAssociado(id,body).subscribe(res => console.log(res), err => console.log(err))
        }else{
          controlDependentesExtra++
        }
      })
    }
  })
}

ativarDependentes(id){
  this.db.getAssociadosById(id).subscribe((data:any) => {
    let todos = data;
    if(todos.dependentes && todos.dependentes.length > 0){
      let controlDependentes = 1;
      todos.dependentes.forEach((item) => {
        if(item.situacao != "ATIVO"){
          item.situacao = "ATIVO"
        }
        if(item.sinistro && item.sinistro.numeroSinistro != null){
          item.sinistro.numeroSinistro = item.sinistro.numeroSinistro
        }
        if(item.sinistro && item.sinistro.valorSinistro != null){
          item.sinistro.valorSinistro = item.sinistro.valorSinistro
        }
        if(controlDependentes == todos.dependentes.length){
          let body = {
            dependentes: todos.dependentes
          }
          this.db.patchAssociado(id,body).subscribe(res => console.log(res), err => console.log(err))
        }else{
          controlDependentes++
        }
      })
    }
    if(todos.dependentesExtra && todos.dependentesExtra.length > 0){
      let controlDependentesExtra = 1;
      todos.dependentesExtra.forEach((item) => {
        if(item.situacao != "ATIVO"){
          item.situacao = "ATIVO"
        }
        if(item.sinistro && item.sinistro.numeroSinistro != null){
          item.sinistro.numeroSinistro = item.sinistro.numeroSinistro
        }
        if(item.sinistro && item.sinistro.valorSinistro != null){
          item.sinistro.valorSinistro = item.sinistro.valorSinistro
        }
        if(controlDependentesExtra == todos.dependentesExtra.length){
          let body = {
            dependentesExtra: todos.dependentesExtra
          }
          this.db.patchAssociado(id,body).subscribe(res => console.log(res), err => console.log(err))
        }else{
          controlDependentesExtra++
        }
      })
    }
  })
}

openModalNovoPj(){
  let modalNew = document.getElementById('modalNovoPjEmpresa');
  modalNew.style.display = "block";
  let modalOver = document.getElementById('editarEmpresaModal');
  modalOver.style.overflow = "hidden";
  this.idVendedorNovo = this.empresa.idVendedor;
  this.gerenteNovo = this.empresa.gerenteResponsavel;
  this.vendedorNovo = this.empresa.vendedor;
}

fecharModalNovoPj(){
  let modalOver = document.getElementById('editarEmpresaModal');
  modalOver.style.overflow = "auto";
  this.cpfNovo = "";
  this.cpfCheck = null;
  this.estadoCivilNovo = "";
  this.nascimentoNovo = "";
  this.nomeNovo = "";
  this.telefoneNovo = "";
  this.celularNovo = "";
  this.ruaNovo = "";
  this.numeroNovo = "";
  this.complementoNovo = "";
  this.bairroNovo = "";
  this.municipioNovo = "";
  this.estadoNovo = "";
  this.emailNovo = "";
  this.funcaoNovo = "";
  this.doencaNovo = "";
  this.especificarDoencaNovo = "";
  this.whatsappNovo = false;
  this.cepNovo = "";
  this.sexoNovo = "";
  this.listaDeDependentes.clear();
  this.listaDeDependentesExtra.clear();
  this.dataAdesaoNovo = "";
  this.vendedorNovo = "";
  this.files = [];
  this.origemNovo = "";
  this.idVendedorNovo = "";
  this.gerenteNovo = "";
  let modalNew = document.getElementById('modalNovoPjEmpresa');
  modalNew.style.display = "none";
}

cadastrarAssociadoPj(){
  if(window.confirm("Deseja realmente cadastrar esse Associado?")){
    let jsonDate = new Date().toJSON();
    let hora = new Date(jsonDate).toLocaleTimeString();
    let descr = "Novo Associado: "+this.nomeNovo+" cadastrado na Empresa: "+this.empresa.fantasiaEmpresa;
    let msg = "ASSOCIADO CADASTRADO!";
    this.db.getConfigs().subscribe((x:any) => {
      let matricula = x[10].matricula;
      let idMatricula = x[10]._id;
      this.db.getSorteLivre().subscribe((dataNum:any) => {
        let idNumSorte = dataNum[0]._id;
        let numSorte = null;
        if(this.plano.indexOf("PLUS") >= 0 || this.empresa.fantasiaEmpresa.indexOf("APCS") >= 0){
          numSorte = dataNum[0].numero;
        }
      this.db.getAssociadosMatricula(matricula,"VIVA MAX").subscribe((m:any) => {
        if(m && m.length > 0){
          matricula = matricula+10
        }
    let capital = this.empresa.valorSeguro ? this.empresa.valorSeguro : 0;
    let dados = {
      codigoEmpresa:this.empresa.codigo,
      matricula:matricula,
      data: this.dataHoje,
      hora: hora,
      plano : this.empresa.plano,
      cpf : this.cpfNovo,
      estadoCivil : this.estadoCivilNovo,
      nascimento : this.nascimentoNovo.split('/').reverse().join('-'),
      nome : this.nomeNovo,
      sexo: this.sexoNovo,
      telefone : this.telefoneNovo,
      celular : this.celularNovo,
      cep: this.cepNovo,
      rua : this.ruaNovo,
      numero : this.numeroNovo,
      complemento : this.complementoNovo,
      bairro : this.bairroNovo,
      municipio : this.municipioNovo,
      estado : this.estadoNovo,
      email:this.emailNovo,
      localTrabalho :  this.empresa.fantasiaEmpresa,
      funcao : this.funcaoNovo,
      doenca : this.doencaNovo,
      origem: this.origemNovo,
      especificarDoenca :  this.especificarDoencaNovo,
      formaPagamento :  "EMPRESARIAL",
      mensalidade : this.empresa.valorPlano,
      taxaAdesao :  0,
      diaVencimento :  "",
      mesFolhaPagamento :  "",
      motivoPendencia : "",
      numSorte:numSorte,
      semAssinatura : false,
      senha:this.matriculaNovo.toString(),
      semAutorizacao : false,
      vendedorEmpresa: this.vendedorEditar.split(',')[0],
      historicos:[{
        "data" : this.dataHoje, 
        "hora" : hora, 
        "motivo" : "CADASTRO", 
        "msg" : msg, 
        "usuario" : localStorage.getItem('usuarioVivamax')
       }
      ],
      agendamentos:[],
      dataAdesao:this.dataAdesaoNovo,
      whatsapp: this.whatsappNovo,
      dependentes:this.dependentes.get('dependente').value,
      dependentesExtra:this.dependentesExtra.get('dependenteExtra').value,
      nomeCartao : "",
      numeroCartao : "",
      validadeCartao : "",
      codigoCartao : "",
      idVendedor:this.idVendedorNovo,
      vendedor:this.vendedorNovo.split(',')[0],
      totalMensalidade: this.empresa.valorPlano,
      subContrato : "",
      planoFisicoCheck: false,
      planoEmpresarialCheck: true,
      cartaoSelecionado: "",
      valorCapitalSegurado: capital,
      gerenteResponsavel:this.gerenteNovo,
      documentos:[],
      situacao:"ATIVO",
      empresa:"VIVA MAX",
      observacaoPendente : "",
      checkSolicitouCancelamento:false,
      motivoSolicitacaoCancelamento:""
    } 
    this.db.cadastroProposta(dados).subscribe(res => {
      console.log(res);
      if(this.files.length > 0){
        const imagedata = new FormData();
        for(let i=0; i<this.selectedFiles.length; i++){imagedata.append('files[]', this.selectedFiles[i], this.selectedFiles[i].name)};
         this.db.enviarDocAssociado( res['insertedId'], imagedata).subscribe((res) => {
             console.log(res);
           },(error) => {
             console.log(error);
           });
      }
      let body = {
        "menu" : "CADASTRAR ASSOCIADOS EMPRESA", 
        "descricao" : descr, 
        "data" : this.dataHoje, 
        "hora" : hora, 
        "usuario" : this.user
      }
      this.db.postLogs(body).subscribe(res => {
        console.log(res);
        this.db.getAssociadosByCodigoEmpresa(this.empresa.codigo).subscribe((data:any) => {
          this.todosAssociados = data;
          if(this.plano.indexOf("PLUS") >= 0 || this.empresa.fantasiaEmpresa.indexOf("APCS") >= 0){
            this.db.patchSorte(idNumSorte,{usado:true}).subscribe(resN => {
              console.log(resN)
            }, errN => {console.log(errN)})
          }
          this.db.patchMatricula(idMatricula,{'matricula':matricula+1}).subscribe(res => console.log(res), err => console.log(err));
          this.showAtivos();
          this.fecharModalNovoPj();
          this.app.openAlert("Cadastro Realizado com Sucesso");
        })
      }, err => {
        console.log(err)
      })
      }, err => {
        console.log(err)
      })
      }, err => {
        console.log(err)
      })
      }, err => {
        console.log(err)
      })
    }, err => {
      console.log(err);
      this.app.openAlert("Não foi possivel cadastrar o Associado, por favor tente novamente!");
    })
  }
}

verificaData(data){
  let ano = data.split('-')[0];
  if(Number(ano) > 9999){
    this.app.openAlert("ATENÇÃO! O ANO NÃO PODE TER MAIS QUE 4 DIGITOS!")
  }
}

selectVendedor(vendedor){
  let i = this.vendedores.map((item) => {
    return item.nome
  }).indexOf(vendedor);
  this.idVendedorNovo = this.vendedores[i]._id;
  this.gerenteNovo = this.vendedores[i].gerenteResponsavel;
  this.vendedorNovo = this.vendedores[i].nome;
}

selectVendedorEmpresa(key){
  let vendedor = key.split(',')
  let v = vendedor[0] != '' ? vendedor[0] : vendedor[1];
  let i = this.vendedores.map((item) => {
    return item.nome || item.fantasiaEmpresa;
  }).indexOf(v);
  this.idVendedor = this.vendedores[i]._id;
  this.gerente = this.vendedores[i].gerenteResponsavel;
}

selectVendedorEditar(key){
  let vendedor = key.split(',')
  let v = vendedor[0] != '' ? vendedor[0] : vendedor[1];
  let i = this.vendedores.map((item) => {
    return item.nome || item.fantasiaEmpresa;
  }).indexOf(vendedor);
  this.idVendedorEditar = this.vendedores[i]._id;
  this.gerenteEditar = this.vendedores[i].gerenteResponsavel;
  this.vendedorEditar = this.vendedores[i].nome;
}

cpfUnico(cpf){
  this.cpfCheck = true;
 /*  let key = cpf.replace(/\D/g,"");
  if(key.length == 11){
    this.db.getAssociadosCpf(cpf).subscribe((data:any) => {
      if(data && data.length > 0){
        this.cpfCheck = false;
        this.app.openAlert("ESTE CPF JÁ ESTA CADASTRADO!");
      }else{
        this.cpfCheck = true;
      }
    })
  } */
}


tipoDependentePj(i){
  let tipoInput = (<HTMLInputElement>document.getElementById('tipoPjNovo'+i)).value;
  let asfInput = (<HTMLInputElement>document.getElementById('asfPjNovo'+i));
  if(tipoInput == "CÔNJUGE" && this.empresa.funeralConjuge == "AUTOMATICO"){
      asfInput.value = "FUNERAL AUTOMÁTICO VITALÍCIO";
      this.dependentes.get('dependente.'+i+'.asf').patchValue('FUNERAL AUTOMÁTICO VITALÍCIO')
  }else{
    asfInput.value = "";
    this.dependentes.get('dependente.'+i+'.asf').patchValue('')
  }
  if((tipoInput == "FILHO" || tipoInput == "FILHA") && this.empresa.funeralFilhosMenor == "AUTOMATICO"){
    asfInput.value = "FUNERAL AUTOMÁTICO ATÉ 18 ANOS";
    this.dependentes.get('dependente.'+i+'.asf').patchValue('FUNERAL AUTOMÁTICO ATÉ 18 ANOS')
 }
}

verificaNascimentoPj(i){
  let tipoInput = (<HTMLInputElement>document.getElementById('tipoPjNovo'+i)).value;
  let asfInput = (<HTMLInputElement>document.getElementById('asfPjNovo'+i));
  let nascimentoInput = (<HTMLInputElement>document.getElementById('nascimentoPjNovo'+i));
  if(tipoInput == "FILHO" || tipoInput == 'FILHA'){
    if(!this.verificaIdade(nascimentoInput.value)){
         asfInput.value = "FUNERAL AUTOMÁTICO ATÉ 18 ANOS";
         this.dependentes.get('dependente.'+i+'.asf').patchValue('FUNERAL AUTOMÁTICO ATÉ 18 ANOS')
   }else{
    asfInput.value = "NÃO HÁ FUNERAL CONTRATADO";
    this.dependentes.get('dependente.'+i+'.asf').patchValue('NÃO HÁ FUNERAL CONTRATADO')
   }      
 }else{
   return
 }
}

verificaIdade(e){
  if(e){
    var d = new Date;
    let dt = e.split('/').reverse().join('-');
    let data = new Date(dt.split('-')[0],dt.split('-')[1]-1,dt.split('-')[2])
    let ano_atual = d.getFullYear();
    let mes_atual = d.getMonth() + 1;
    let dia_atual = d.getDate();
    let ano_aniversario = data.getFullYear();;
    let mes_aniversario = data.getMonth() + 1;
    let dia_aniversario = data.getDate();
    let idade = ano_atual - ano_aniversario;
    if(mes_atual < mes_aniversario || mes_atual == mes_aniversario && dia_atual < dia_aniversario){
        idade--;
    }
    if(idade >= 18){
      return true;
    }else{
      return false;
    }
  }else{
    return false;
  }
}

selectDoenca(e){
  let inner = e.srcElement.labels[0].innerText;
  let checked = e.srcElement.checked;
  let c;
  if(inner == "SIM" && checked == true){
      this.doencaNovo = inner;
      c = <HTMLInputElement>document.getElementById('doencaNaoNovo');
      c.checked = false
  }
  if(inner == "NÃO" && checked == true){
      this.doencaNovo = inner;
      c = <HTMLInputElement>document.getElementById('doencaSimNovo');
      c.checked = false
  }
}

onChangeCadastro(event){
  let arrayTypes = ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'];
  this.selectedFiles = <FileList>event;
  for(let i=0; i<this.selectedFiles.length; i++){
      if(!arrayTypes.includes(this.selectedFiles[i].type)){
      this.app.openAlert("Tipo de Arquivo Não Suportado!\nEscolha uma imagem com a extensão .JPG, .JPEG, .PNG ou PDF")
      this.selectedFiles = "";
    }} 
    this.files = [];
    for(let i=0; i<this.selectedFiles.length; i++){
      this.files.push(this.selectedFiles[i].name);
    }
}

onChangeClickCadastro(event){
  let arrayTypes = ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'];
  this.selectedFiles = <FileList>event.srcElement.files;
  for(let i=0; i<this.selectedFiles.length; i++){
      if(!arrayTypes.includes(this.selectedFiles[i].type)){
      this.app.openAlert("Tipo de Arquivo Não Suportado!\nEscolha uma imagem com a extensão .JPG, .JPEG, .PNG ou PDF")
      this.selectedFiles = "";
    }} 
    this.files = [];
    for(let i=0; i<this.selectedFiles.length; i++){
      this.files.push(this.selectedFiles[i].name);
    }
}

deleteDocFiles(idx){
  this.files.splice(idx,1);
  let fileArray = Array.from(this.selectedFiles);
  fileArray.splice(idx,1);
  this.selectedFiles = fileArray;
}


onChangeClickEmpresa(event){
  let arrayTypes = ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'];
  this.selectedFiles = <FileList>event.srcElement.files;
  for(let i=0; i<this.selectedFiles.length; i++){
      if(!arrayTypes.includes(this.selectedFiles[i].type)){
      this.app.openAlert("Tipo de Arquivo Não Suportado!\nEscolha uma imagem com a extensão .JPG, .JPEG, .PNG ou PDF")
      this.selectedFiles = "";
    }} 
    this.files = [];
    for(let i=0; i<this.selectedFiles.length; i++){
      this.files.push(this.selectedFiles[i].name);
    }
}

onChangeEmpresa(event){
  let arrayTypes = ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'];
  this.selectedFiles = <FileList>event;
  for(let i=0; i<this.selectedFiles.length; i++){
      if(!arrayTypes.includes(this.selectedFiles[i].type)){
      this.app.openAlert("Tipo de Arquivo Não Suportado!\nEscolha uma imagem com a extensão .JPG, .JPEG, .PNG ou PDF")
      this.selectedFiles = "";
    }} 
    this.files = [];
    for(let i=0; i<this.selectedFiles.length; i++){
      this.files.push(this.selectedFiles[i].name);
    }
}

onChangeClickEmpresaEditar(event){
  let arrayTypes = ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'];
  this.selectedFilesEditar = <FileList>event.srcElement.files;
  for(let i=0; i<this.selectedFilesEditar.length; i++){
      if(!arrayTypes.includes(this.selectedFilesEditar[i].type)){
      this.app.openAlert("Tipo de Arquivo Não Suportado!\nEscolha uma imagem com a extensão .JPG, .JPEG, .PNG ou PDF")
      this.selectedFilesEditar = "";
    }} 
    this.filesEditar = [];
    for(let i=0; i<this.selectedFilesEditar.length; i++){
      this.filesEditar.push(this.selectedFilesEditar[i].name);
    }
}

onChangeEmpresaEditar(event){
  let arrayTypes = ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'];
  this.selectedFilesEditar = <FileList>event;
  for(let i=0; i<this.selectedFilesEditar.length; i++){
      if(!arrayTypes.includes(this.selectedFilesEditar[i].type)){
      this.app.openAlert("Tipo de Arquivo Não Suportado!\nEscolha uma imagem com a extensão .JPG, .JPEG, .PNG ou PDF")
      this.selectedFilesEditar = "";
    }} 
    this.filesEditar = [];
    for(let i=0; i<this.selectedFilesEditar.length; i++){
      this.filesEditar.push(this.selectedFilesEditar[i].name);
    }
}

anexarDoc(){
  if(this.selectedFilesEditar.length > 0){
    const imagedata = new FormData();
    for(let i=0; i<this.selectedFilesEditar.length; i++){imagedata.append('files[]', this.selectedFilesEditar[i], this.selectedFilesEditar[i].name)};
     this.db.enviarDocEmpresa(this.idEmpresa, imagedata).subscribe((result) => {
         console.log(result);
         this.getDocsEditar()
       },(error) => {
         console.log(error);
         this.getDocsEditar()
       });
  }
}

getDocsEditar(){
  this.docs = [];
  this.db.getAnexosEmpresasById(this.idEmpresa).subscribe((result:any) => {
    this.docs = result.anexos;
    this.pdfs = [];
    this.images = [];
    this.selectedFiles = "";
    this.filesEditar = [];
    if(this.docs && this.docs.length > 0){
      this.pdfs = [];
      this.images = [];
      this.docs.forEach((item) => {
      let ext = item.url.split('.').pop();
        if(ext == 'pdf' || ext == 'PDF'){
          this.pdfs.push(item);
        }else{
          this.images.push(item);
        }
      })
    }
  })
}


openModalLightBox(url, i){
  this.indexImage = i;
  this.imagemSelecionada = url;
  let modal = document.getElementById('myModal')
  modal.style.display = "block";
}


closeModal(){
  let modal = document.getElementById('myModal')
  modal.style.display = "none";
}

nextImg(){
  let i = this.indexImage;
  if((this.images.length - 1) == i){
    this.imagemSelecionada = this.images[0].url;
    this.indexImage = 0;
  }else{
    i++
    this.imagemSelecionada = this.images[i].url;
    this.indexImage++
  }
}

prevImg(){
  let i = this.indexImage
  if(i == 0){
    this.imagemSelecionada = this.images[this.images.length - 1].url;
    this.indexImage = this.images.length - 1;
  }else{
    i--
    this.imagemSelecionada = this.images[i].url;
    this.indexImage--
  }
}

delDoc(url){
  if(window.confirm('Deseja Realmente Deletar esse Documento?')){
   this.db.removeDoc(url).subscribe(res => {
     console.log(res);
     this.db.removeDocEmpresa(this.idEmpresa,url).subscribe(result => {
       console.log(result);
       this.getDocsEditar();
     }, error => {
       console.log(error);
     })
    },err => {
      console.log(err);
       this.db.removeDocEmpresa(this.idEmpresa,url).subscribe(result => {
       console.log(result);
       this.getDocsEditar();
     }, error => {
       console.log(error);
     })
   })
 }
}

}