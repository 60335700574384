<div class="container-fluid">
<div style="width: 800px; height: 400px;display: none;">
    <ng2-pdfjs-viewer 
        #pdfViewerPlanos
        [externalWindow]="true"
        [downloadFileName]="'vivamax.pdf'"
        [openFile]="false"
        [viewBookmark]="false"
        [download]="true"></ng2-pdfjs-viewer>
</div>
<!-- MODAL NOVO PLANO -->
<div class="modal" id="modalNovoPlano">
    <div class="modal-tutorial" id="modCodPlano">
        <div class="inner">
            <input type="radio" class="slide-controller" name="slide" id="slide1" checked />
            <input type="radio" class="slide-controller" name="slide" id="slide2"/>
            <input type="radio" class="slide-controller" name="slide" id="slide3"/>
            <input type="radio" class="slide-controller" name="slide" id="slide4"/>
            <div class="slide-show" >
                <ul class="slides-list" >
                  <li class="slide" >
                    <img src="../../assets/img/gif01.gif" /><br>
                    <label for="slide1">Escolher no menu lateral <strong>PLANOS</strong></label>
                  </li>
                  <li class="slide" >
                    <img src="../../assets/img/gif02.gif" /><br>
                    <label for="slide2">Clicar no simbolo de 3 pontinhos ao lado do nome do Plano</label>
                  </li>
                  <li class="slide" >
                    <img src="../../assets/img/gif03.gif" /><br>
                    <label for="slide3">Selecionar a opção <strong>Detalhes</strong></label>
                  </li>
                  <li class="slide" >
                    <img src="../../assets/img/gif04.gif" /><br>
                    <label for="slide4">Este número na URL da página corresponde ao Código do Plano</label>
                  </li>
                </ul>
              </div>
            <button class="btn btn-danger" (click)="closeModalCodPlano()">FECHAR</button>
          </div>
      </div>
    <div class="row  align-items-center header">
        <div class="col titulo">
            CADASTRAR NOVO PLANO
        </div>
        <div class="col botoes">
            <button class="btn btn-outline-light" (click)="closeModal()">
                <fa-icon [icon]="['fas', 'window-close']"></fa-icon>&nbsp;CANCELAR
            </button>
            &nbsp;&nbsp;
            <button class="btn btn-outline-light" (click)="cadastrarPlano()" [disabled]="!tipo || !nome || (mensalidade < 0 || mensalidade == null) || (adesao < 0 || adesao == null) || !empresaOrigem || (areaCliente && descricoesList?.value?.length < 1) || (areaCliente && !codigoPlano)">
                <fa-icon [icon]="['fas', 'save']"></fa-icon>&nbsp;SALVAR
            </button>
        </div>
    </div>
    <br>
    <div class="corpo">
        <div class="row">
            <div class="col col-3">
                <label>Empresa:</label>
                <select class="form-control" [(ngModel)]="empresaOrigem" [ngStyle]="{'border-color': !empresaOrigem ? 'red' : null}">
                    <option value="VIVA MAX">VIVA MAX</option>
                    <option value="SEGUROS">SEGUROS</option>
                    <option value="CONECTA">CONECTA</option>
                </select>
            </div>
            <div class="col col-3">
                <label>Tipo:</label>
                <select class="form-control" [(ngModel)]="tipo" [ngStyle]="{'border-color': !tipo ? 'red' : null}">
                    <option value="PLANO FISICO">PLANO FISICO</option>
                    <option value="PLANO SERVIDOR">PLANO SERVIDOR</option>
                    <option value="PLANO EMPRESARIAL">PLANO EMPRESARIAL</option>
                </select>
            </div>
            <div class="col col-6">
                <label>Nome:</label>
                <input type="text" class="form-control" [(ngModel)]="nome" oninput="this.value = this.value.toUpperCase()" [ngStyle]="{'border-color': !nome ? 'red' : null}">
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col col-3">
                <label>Mensalidade:</label>
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon1">R$</span>
                    </div>
                    <input type="number" class="form-control" [(ngModel)]="mensalidade" [ngStyle]="{'border-color': mensalidade < 0 || mensalidade == null ? 'red' : null}">
                </div>
            </div>
            <div class="col col-3">
                <label>Adesão:</label>
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon1">R$</span>
                    </div>
                    <input type="number" class="form-control" [(ngModel)]="adesao" [ngStyle]="{'border-color': adesao < 0 || adesao == null ? 'red' : null}">
                </div>
            </div>
            <div class="col col-2" style="text-align: center;" *ngIf="empresaOrigem == 'CONECTA'">
                <div class="form-check" style="padding-left:2em">
                    <input class="form-check-input" type="checkbox" id="checkHibrido" [(ngModel)]="hibrido">
                    <label class="form-check-label" for="checkHibrido" style="padding-left:2em">
                        Rede Viva Max
                    </label>
                </div>
            </div>
            <div class="col col-2" style="text-align: center;">
                <div class="form-check" style="padding-left:2em">
                    <input class="form-check-input" type="checkbox" id="vendasPlano" [(ngModel)]="planoVendas">
                    <label class="form-check-label" for="vendasPlano" style="padding-left:2em">
                        Vendas
                    </label>
                </div>
            </div>
            <div class="col col-2" style="text-align: center;">
                <div class="form-check" style="padding-left:2em">
                    <input class="form-check-input" type="checkbox" id="corretoraStarr" [(ngModel)]="corretoraStarr">
                    <label class="form-check-label" for="corretoraStarr" style="padding-left:2em">
                        Corretora Starr
                    </label>
                </div>
            </div>
            <div class="col col-2" *ngIf="areaCliente">
                <label>Cód. Plano <span class="info" (click)="openModalCodPlano()">&nbsp;&nbsp;<fa-icon [icon]="['fas', 'info']" size="1x"></fa-icon>&nbsp;&nbsp;</span></label>
                <input type="number" class="form-control" [(ngModel)]="codigoPlano" [ngStyle]="{'border-color': !codigoPlano ? 'red' : null}">
            </div>
            <div class="col col-2" *ngIf="areaCliente">
                <label>Ordem</label>
                <input type="number" class="form-control" [(ngModel)]="ordemSite" [ngStyle]="{'border-color': !ordemSite ? 'red' : null}">
            </div>
        </div>
        <br>
        <div *ngIf="areaCliente">
            <h3>As descrições abaixo estarão visiveis no site para o visitante:</h3><br>
            <form [formGroup]="descricoes">
                <div class="row" formArrayName="descricao">
                    <div class="col col-12" style="font-size: 0.85em" *ngFor="let descricao of descricoesFormGroup.controls; let i = index;">
                        <div [formGroupName]="i" class="row">
                            <div class="form-group col-9">
                                <input type="text" class="form-control" formControlName="descricao" placeholder="Descrição">
                            </div>
                            <div class="form-group col-3 text-right">
                                <button class="btn btn-danger btn-sm" type="button" (click)="removeDescricao(i)" *ngIf="this.descricoesList.length > 1">
                                <fa-icon [icon]="['fas', 'minus-circle']" size="1x"></fa-icon>
                                </button>&nbsp;
                                <button class="btn btn-success btn-sm" type="button" (click)="addDescricao()" [disabled]="!descricoes.controls.descricao.valid">
                                <fa-icon [icon]="['fas', 'plus-circle']" size="1x"></fa-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>   
            </form>
        </div>
        <br>
        <div class="row" *ngIf="empresaOrigem == 'CONECTA'">
            <div class="col col-3">
                <label>Nº Máximo de Dependentes Conecta:</label>
                <input type="number" class="form-control" min="0" [(ngModel)]="maxDependentesConecta">
            </div>
            <div class="col col-3">
                <label>Dependentes Conecta Inclusos:</label>
                <input type="number" class="form-control" min="0" [(ngModel)]="conectaInclusos">
            </div>
            <div class="col col-3">
                <label>Valor Conecta Extra:</label>
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon1">R$</span>
                    </div>
                    <input type="number" class="form-control" min="0" [(ngModel)]="valorConectaExtra">
                </div>
            </div>
        </div>
        <div class="row" *ngIf="(empresaOrigem == 'VIVA MAX' || hibrido) && empresaOrigem != 'SEGUROS'">
            <div class="col col-3">
                <label>Funeral Cônjuge:</label>
                <select class="form-control" [(ngModel)]="funeralConjuge" (change)="setValorConjuge(funeralConjuge)">
                    <option value="AUTOMATICO">AUTOMÁTICO SEM CUSTO</option>
                    <option value="SIM">SIM</option>
                    <option value="NÃO">NÃO</option>
                </select>
            </div>
            <div class="col col-2">
                <label>Valor:</label>
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon1">R$</span>
                    </div>
                    <input type="number" class="form-control" [(ngModel)]="valorFuneralConjuge" [disabled]="funeralConjuge != 'SIM'">
                </div>
            </div>
            <div class="col col-3">
                <label>Funeral Filhos -18:</label>
                <select class="form-control" [(ngModel)]="funeralFilhosMenor" (change)="setValorMenor(funeralFilhosMenor)">
                    <option value="AUTOMATICO">AUTOMÁTICO SEM CUSTO</option>
                    <option value="SIM">SIM</option>
                    <option value="NÃO">NÃO</option>
                </select>
            </div>
            <div class="col col-2">
                <label>Valor:</label>
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon1">R$</span>
                    </div>
                    <input type="number" class="form-control" [(ngModel)]="valorFuneralFilhosMenor" [disabled]="funeralFilhosMenor != 'SIM'">
                </div>
            </div>
            <div class="col col-2">
                <label>Dependente Extra:</label>
                <select class="form-control" [(ngModel)]="temDependentesExtra">
                    <option value="SIM">SIM</option>
                    <option value="NÃO">NÃO</option>
                </select>
            </div>  
        </div>
        <br>
        <div class="row" *ngIf="(empresaOrigem == 'VIVA MAX' || hibrido) && empresaOrigem != 'SEGUROS'">
            <div class="col col-3">
                <label>Funeral Filhos +18:</label>
                <select class="form-control" [(ngModel)]="funeralFilhosMaior" (change)="setValorMaior(funeralFilhosMaior)">
                    <option value="AUTOMATICO">AUTOMÁTICO SEM CUSTO</option>
                    <option value="SIM">SIM</option>
                    <option value="NÃO">NÃO</option>
                </select>
            </div>
            <div class="col col-2">
                <label>Valor:</label>
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon1">R$</span>
                    </div>
                    <input type="number" class="form-control" [(ngModel)]="valorFuneralFilhosMaior" [disabled]="funeralFilhosMaior != 'SIM'">
                </div>
            </div>
            <div class="col col-3">
                <label>Funeral Extra:</label>
                <select class="form-control" [(ngModel)]="funeralExtra" (change)="setValorExtra(funeralExtra)">
                    <option value="AUTOMATICO">AUTOMÁTICO SEM CUSTO</option>
                    <option value="SIM">SIM</option>
                    <option value="NÃO">NÃO</option>
                </select>
            </div>            
            <div class="col col-2">
                <label>Valor:</label>
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon1">R$</span>
                    </div>
                    <input type="number" class="form-control" [(ngModel)]="valorFuneralExtra" [disabled]="funeralExtra != 'SIM'">
                </div>
            </div>
            <div class="col col-2">
                <label>Editar Adesão:</label>
                <select class="form-control" [(ngModel)]="editaAdesao">
                    <option value="SIM">SIM</option>
                    <option value="NÃO">NÃO</option>
                </select>
            </div>
        </div>
        <br>
        <div class="row" *ngIf="empresaOrigem == 'SEGUROS'">
            <div class="col col-3">
                <label>Capital Segurado:</label>
                <select class="form-control" [(ngModel)]="capitalSegurado" (change)="setValorCapital(capitalSegurado)">
                    <option value="SIM">SIM</option>
                    <option value="NÃO">NÃO</option>
                </select>
            </div>
            <div class="col col-3"> 
                <label>Valor Capital:</label>
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon1">R$</span>
                    </div>
                    <input type="number" class="form-control" [(ngModel)]="valorCapitalSegurado" [disabled]="capitalSegurado != 'SIM'">
                </div>
            </div>
        </div>
        <div class="row" *ngIf="(empresaOrigem == 'VIVA MAX' || hibrido) && empresaOrigem != 'SEGUROS'">
            <div class="col col-3">
                <label>Capital Segurado:</label>
                <select class="form-control" [(ngModel)]="capitalSegurado" (change)="setValorCapital(capitalSegurado)">
                    <option value="SIM">SIM</option>
                    <option value="NÃO">NÃO</option>
                </select>
            </div>
            <div class="col col-2"> 
                <label>Valor Capital:</label>
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon1">R$</span>
                    </div>
                    <input type="number" class="form-control" [(ngModel)]="valorCapitalSegurado" [disabled]="capitalSegurado != 'SIM'">
                </div>
            </div>
            <div class="col col-3">
                <label>Limite de Idade ASF</label>
                <input type="number" class="form-control" [(ngModel)]="limiteASF" [ngStyle]="{'border-color': limiteASF < 0 || limiteASF == null ? 'red' : null}">
            </div> 
            <div class="col col-2">
                <label>Editar Mensalidade:</label>
                <select class="form-control" [(ngModel)]="editaMensalidade">
                    <option value="SIM">SIM</option>
                    <option value="NÃO">NÃO</option>
                </select>
            </div> 
            <div class="col col-2">
                <label>Editar Valor Total:</label>
                <select class="form-control" [(ngModel)]="editaTotal">
                    <option value="SIM">SIM</option>
                    <option value="NÃO">NÃO</option>
                </select>
            </div> 
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col" style="text-align: center; color:#5e5e5e">
            <h5>Clique ou Arraste para Enviar o Contrato</h5>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <div class="uploadfilecontainer" (click)="fileInputContrato.click()" appDragDrop (onFileDropped)="onChangeContrato($event)">
                <input hidden type="file" #fileInputContrato (change)="onChangeClickContrato($event)">
            </div>
            <div class="files-list" *ngFor="let file of filesContrato; let i= index">
                <p>{{ file }}</p>
            </div> 
        </div>
    </div>
    <div style="height: 3em;"></div>
</div>
<!-- FIM MODAL NOVO PLANO -->
<!-- MODAL EDITAR PLANO -->
<div class="modal" id="modalEditarPlano">
    <div class="modal-tutorial" id="modCodPlanoEditar">
        <div class="inner">
            <input type="radio" class="slide-controller" name="slideEditar" id="slideEditar1" checked />
            <input type="radio" class="slide-controller" name="slideEditar" id="slideEditar2"/>
            <input type="radio" class="slide-controller" name="slideEditar" id="slideEditar3"/>
            <input type="radio" class="slide-controller" name="slideEditar" id="slideEditar4"/>
            <div class="slide-show" >
                <ul class="slides-list" >
                  <li class="slide" >
                    <img src="../../assets/img/gif01.gif" /><br>
                    <label for="slideEditar1">Escolher no menu lateral <strong>PLANOS</strong></label>
                  </li>
                  <li class="slide" >
                    <img src="../../assets/img/gif02.gif" /><br>
                    <label for="slideEditar2">Clicar no simbolo de 3 pontinhos ao lado do nome do Plano</label>
                  </li>
                  <li class="slide" >
                    <img src="../../assets/img/gif03.gif" /><br>
                    <label for="slideEditar3">Selecionar a opção <strong>Detalhes</strong></label>
                  </li>
                  <li class="slide" >
                    <img src="../../assets/img/gif04.gif" /><br>
                    <label for="slideEditar4">Este número na URL da página corresponde ao Código do Plano</label>
                  </li>
                </ul>
              </div>
            <button class="btn btn-danger" (click)="closeModalCodPlanoEditar()">FECHAR</button>
          </div>
      </div>
    <div class="row  align-items-center header">
        <div class="col titulo">
            EDITAR PLANO
        </div>
        <div class="col botoes">
            <button class="btn btn-outline-light" (click)="closeModalEditar()">
                <fa-icon [icon]="['fas', 'window-close']"></fa-icon>&nbsp;CANCELAR
            </button>
            &nbsp;&nbsp;
            <button class="btn btn-outline-light" (click)="salvarEditar()" [disabled]="!tipoEditar || !empresaOrigemEditar || !nomeEditar || (mensalidadeEditar < 0 || mensalidadeEditar == null) || (adesaoEditar < 0 || adesaoEditar == null) || (areaClienteEditar && descricoesEditarList?.value?.length < 1) || (areaClienteEditar && !codigoPlanoEditar)">
                <fa-icon [icon]="['fas', 'save']"></fa-icon>&nbsp;SALVAR
            </button>
        </div>
    </div>
    <br>
    <div class="corpo">
        <div class="row">
            <div class="col col-3">
                <label>Empresa:</label>
                <select class="form-control" [(ngModel)]="empresaOrigemEditar" [ngStyle]="{'border-color': !empresaOrigemEditar ? 'red' : null}">
                    <option value="VIVA MAX">VIVA MAX</option>
                    <option value="SEGUROS">SEGUROS</option>
                    <option value="CONECTA">CONECTA</option>
                </select>
            </div>
            <div class="col col-3">
                <label>Tipo:</label>
                <select class="form-control" [(ngModel)]="tipoEditar" [ngStyle]="{'border-color': !tipoEditar ? 'red' : null}">
                    <option value="PLANO FISICO">PLANO FISICO</option>
                    <option value="PLANO SERVIDOR">PLANO SERVIDOR</option>
                    <option value="PLANO EMPRESARIAL">PLANO EMPRESARIAL</option>
                </select>
            </div>
            <div class="col col-6">
                <label>Nome:</label>
                <input type="text" class="form-control" [(ngModel)]="nomeEditar" oninput="this.value = this.value.toUpperCase()" [ngStyle]="{'border-color': !nomeEditar ? 'red' : null}">
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col col-2">
                <label>Mensalidade:</label>
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon1">R$</span>
                    </div>
                    <input type="number" class="form-control" [(ngModel)]="mensalidadeEditar" [ngStyle]="{'border-color': mensalidadeEditar < 0 || mensalidadeEditar == null ? 'red' : null}">
                </div>
            </div>
            <div class="col col-2">
                <label>Adesão:</label>
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon1">R$</span>
                    </div>
                    <input type="number" class="form-control" [(ngModel)]="adesaoEditar" [ngStyle]="{'border-color': adesaoEditar < 0 || adesaoEditar == null ? 'red' : null}">
                </div>
            </div>
            <div class="col col-2" style="text-align: center;" *ngIf="empresaOrigemEditar == 'CONECTA'">
                <div class="form-check" style="padding-left:2em">
                    <input class="form-check-input" type="checkbox" id="checkHibridoEditar" [(ngModel)]="hibridoEditar">
                    <label class="form-check-label" for="checkHibridoEditar" style="padding-left:2em">
                        Rede Viva Max
                    </label>
                </div>
            </div>
            <div class="col col-2" style="text-align: center;">
                <div class="form-check" style="padding-left:2em">
                    <input class="form-check-input" type="checkbox" id="vendasPlanoEditar" [(ngModel)]="planoVendasEditar">
                    <label class="form-check-label" for="vendasPlanoEditar" style="padding-left:2em">
                        Vendas
                    </label>
                </div>
            </div>
            <div class="col col-2" style="text-align: center;">
                <div class="form-check" style="padding-left:2em">
                    <input class="form-check-input" type="checkbox" id="corretoraStarrEditar" [(ngModel)]="corretoraStarrEditar">
                    <label class="form-check-label" for="corretoraStarrEditar" style="padding-left:2em">
                        Corretora Starr
                    </label>
                </div>
            </div>
            <div class="col col-2" *ngIf="areaClienteEditar">
                <label>Cód. Plano <span class="info" (click)="openModalCodPlanoEditar()">&nbsp;&nbsp;<fa-icon [icon]="['fas', 'info']" size="1x"></fa-icon>&nbsp;&nbsp;</span></label>
                <input type="number" class="form-control" [(ngModel)]="codigoPlanoEditar" [ngStyle]="{'border-color': !codigoPlanoEditar ? 'red' : null}">
            </div>
            <div class="col col-2" *ngIf="areaClienteEditar">
                <label>Ordem</label>
                <input type="number" class="form-control" [(ngModel)]="ordemSiteEditar" [ngStyle]="{'border-color': !ordemSiteEditar ? 'red' : null}">
            </div>
        </div>
        <br>
        <div *ngIf="areaClienteEditar">
            <h3>As descrições abaixo estarão visiveis no site para o visitante:</h3><br>
            <form [formGroup]="descricoesEditar">
                <div class="row" formArrayName="descricao">
                    <div class="col col-12" style="font-size: 0.85em" *ngFor="let descricao of descricoesEditarFormGroup.controls; let i = index;">
                        <div [formGroupName]="i" class="row">
                            <div class="form-group col-9">
                                <input type="text" class="form-control" formControlName="descricao" placeholder="Descrição">
                            </div>
                            <div class="form-group col-3 text-right">
                                <button class="btn btn-danger btn-sm" type="button" (click)="removeDescricaoEditar(i)" *ngIf="this.descricoesEditarList.length > 1">
                                <fa-icon [icon]="['fas', 'minus-circle']" size="1x"></fa-icon>
                                </button>&nbsp;
                                <button class="btn btn-success btn-sm" type="button" (click)="addDescricaoEditar()" [disabled]="!descricoesEditar.controls.descricao.valid">
                                <fa-icon [icon]="['fas', 'plus-circle']" size="1x"></fa-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>   
            </form>           
        </div>
        <br>
        <div class="row" *ngIf="empresaOrigemEditar == 'CONECTA'">
            <div class="col col-3">
                <label>Nº Máximo de Dependentes Conecta:</label>
                <input type="number" class="form-control" min="0" [(ngModel)]="maxDependentesConectaEditar">
            </div>
            <div class="col col-3">
                <label>Dependentes Conecta Inclusos:</label>
                <input type="number" min="0" class="form-control" [(ngModel)]="conectaInclusosEditar">
            </div>
            <div class="col col-3">
                <label>Valor Conecta Extra:</label>
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon1">R$</span>
                    </div>
                    <input type="number" class="form-control" min="0" [(ngModel)]="valorConectaExtraEditar">
                </div>
            </div>
        </div>
        <div class="row" *ngIf="(empresaOrigemEditar == 'VIVA MAX' || hibridoEditar) && empresaOrigemEditar != 'SEGUROS'">
            <div class="col col-3">
                <label>Funeral Cônjuge:</label>
                <select class="form-control" [(ngModel)]="funeralConjugeEditar" (change)="setValorConjugeEditar(funeralConjugeEditar)">
                    <option value="AUTOMATICO">AUTOMÁTICO SEM CUSTO</option>
                    <option value="SIM">SIM</option>
                    <option value="NÃO">NÃO</option>
                </select>
            </div>
            <div class="col col-2">
                <label>Valor:</label>
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon1">R$</span>
                    </div>
                    <input type="number" class="form-control" [(ngModel)]="valorFuneralConjugeEditar" [disabled]="funeralConjugeEditar != 'SIM'">
                </div>
            </div>
            <div class="col col-3">
                <label>Funeral Filhos -18:</label>
                <select class="form-control" [(ngModel)]="funeralFilhosMenorEditar" (change)="setValorMenorEditar(funeralFilhosMenorEditar)">
                    <option value="AUTOMATICO">AUTOMÁTICO SEM CUSTO</option>
                    <option value="SIM">SIM</option>
                    <option value="NÃO">NÃO</option>
                </select>
            </div>
            <div class="col col-2">
                <label>Valor:</label>
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon1">R$</span>
                    </div>
                    <input type="number" class="form-control" [(ngModel)]="valorFuneralFilhosMenorEditar" [disabled]="funeralFilhosMenorEditar != 'SIM'">
                </div>
            </div>
            <div class="col col-2">
                <label>Dependente Extra:</label>
                <select class="form-control" [(ngModel)]="temDependentesExtraEditar">
                    <option value="SIM">SIM</option>
                    <option value="NÃO">NÃO</option>
                </select>
            </div>
        </div>
        <br>
        <div class="row" *ngIf="(empresaOrigemEditar == 'VIVA MAX' || hibridoEditar) && empresaOrigemEditar != 'SEGUROS'">
            <div class="col col-3">
                <label>Funeral Filhos +18:</label>
                <select class="form-control" [(ngModel)]="funeralFilhosMaiorEditar" (change)="setValorMaiorEditar(funeralFilhosMaiorEditar)">
                    <option value="AUTOMATICO">AUTOMÁTICO SEM CUSTO</option>
                    <option value="SIM">SIM</option>
                    <option value="NÃO">NÃO</option>
                </select>
            </div>
            <div class="col col-2">
                <label>Valor:</label>
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon1">R$</span>
                    </div>
                    <input type="number" class="form-control" [(ngModel)]="valorFuneralFilhosMaiorEditar" [disabled]="funeralFilhosMaiorEditar != 'SIM'">
                </div>
            </div>
            <div class="col col-3">
                <label>Funeral Extra:</label>
                <select class="form-control" [(ngModel)]="funeralExtraEditar" (change)="setValorExtraEditar(funeralExtraEditar)">
                    <option value="AUTOMATICO">AUTOMÁTICO SEM CUSTO</option>
                    <option value="SIM">SIM</option>
                    <option value="NÃO">NÃO</option>
                </select>
            </div>            
            <div class="col col-2">
                <label>Valor:</label>
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon1">R$</span>
                    </div>
                    <input type="number" class="form-control" [(ngModel)]="valorFuneralExtraEditar" [disabled]="funeralExtraEditar != 'SIM'">
                </div>
            </div>
            <div class="col col-2">
                <label>Editar Adesão:</label>
                <select class="form-control" [(ngModel)]="editaAdesaoEditar">
                    <option value="SIM">SIM</option>
                    <option value="NÃO">NÃO</option>
                </select>
            </div> 
        </div>
        <br>
        <div class="row" *ngIf="empresaOrigemEditar == 'SEGUROS'">
            <div class="col col-3">
                <label>Capital Segurado:</label>
                <select class="form-control" [(ngModel)]="capitalSeguradoEditar" (change)="setValorCapitalEditar(capitalSeguradoEditar)">
                    <option value="SIM">SIM</option>
                    <option value="NÃO">NÃO</option>
                </select>
            </div>
            <div class="col col-2"> 
                <label>Valor Capital:</label>
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon1">R$</span>
                    </div>
                    <input type="number" class="form-control" [(ngModel)]="valorCapitalSeguradoEditar" [disabled]="capitalSeguradoEditar != 'SIM'">
                </div>
            </div>
        </div>
        <div class="row" *ngIf="(empresaOrigemEditar == 'VIVA MAX' || hibridoEditar) && empresaOrigemEditar != 'SEGUROS'">
            <div class="col col-3">
                <label>Capital Segurado:</label>
                <select class="form-control" [(ngModel)]="capitalSeguradoEditar" (change)="setValorCapitalEditar(capitalSeguradoEditar)">
                    <option value="SIM">SIM</option>
                    <option value="NÃO">NÃO</option>
                </select>
            </div>
            <div class="col col-2"> 
                <label>Valor Capital:</label>
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon1">R$</span>
                    </div>
                    <input type="number" class="form-control" [(ngModel)]="valorCapitalSeguradoEditar" [disabled]="capitalSeguradoEditar != 'SIM'">
                </div>
            </div>
            <div class="col col-3">
                <label>Limite de Idade ASF</label>
                <input type="number" class="form-control" [(ngModel)]="limiteASFEditar" [ngStyle]="{'border-color': limiteASFEditar < 0 || limiteASFEditar == null ? 'red' : null}">
            </div> 
            <div class="col col-2">
                <label>Editar Mensalidade:</label>
                <select class="form-control" [(ngModel)]="editaMensalidadeEditar">
                    <option value="SIM">SIM</option>
                    <option value="NÃO">NÃO</option>
                </select>
            </div> 
            <div class="col col-2">
                <label>Editar Valor Total:</label>
                <select class="form-control" [(ngModel)]="editaTotalEditar">
                    <option value="SIM">SIM</option>
                    <option value="NÃO">NÃO</option>
                </select>
            </div> 
        </div>
        <br><br>
        <div class="row">
            <div class="col" style="text-align: center; color:#5e5e5e">
                <h5>Clique ou Arraste para Enviar o Contrato</h5>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="uploadfilecontainer" (click)="fileInputContratoEditar.click()" appDragDrop (onFileDropped)="onChangeContratoEditar($event)">
                    <input hidden type="file" #fileInputContratoEditar (change)="onChangeClickContratoEditar($event)">
                </div>
                <div class="files-list" *ngFor="let file of filesContratoEditar; let i= index">
                    <p>{{ file }}</p>
                </div> 
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col col-1">
                <br>
            </div>
            <div class="col">
                <button class="btn btn-primary btn-block" (click)="uploadFileContrato()" [disabled]="filesContratoEditar.length < 1">
                    <fa-icon [icon]="['fas', 'upload']"></fa-icon>&nbsp;&nbsp;ENVIAR
                </button>
            </div>
            <div class="col col-1">
                <br>
            </div>
        </div>
        <br><br>
        <div class="row justify-content-md-center" *ngIf="contrato != ''">
            <div class="col col-3" style="margin: 10px; background-color:#ce2626; color:white; height: 250px; width:100%; text-align: center; border-radius: 10px;">
                <div class="delButton" name="delButton">
                    <button class="btn btn-dark btn-sm" (click)="delDocContrato(contrato)"><strong>X</strong></button>
                </div>
                <div (click)="openModalPdfViewer(contrato)" style="position: relative;left:50%;top:50%;transform: translate(-50%,-50%);cursor:pointer">
                    <fa-icon [icon]="['fas', 'file-pdf']" style="font-size: 5em;"></fa-icon>
                        <br>CONTRATO PDF
                </div>
            </div>
        </div>
        <br><br>
    </div> 
</div>
<!-- FIM MODAL EDITAR PLANO -->
<div class="row  align-items-center header">
    <div class="col titulo" style="text-align: left;">
        PLANOS
    </div>
    <div class="col" style="text-align: right;">
        <button class="btn btn-outline-light" (click)="mostraPlanosAtivos()">
            MOSTRAR ATIVOS
        </button>
    </div>
    <div class="col" style="text-align: right;">
        <button class="btn btn-outline-light" (click)="mostrarTodosPlanos()">
            MOSTRAR TODOS
        </button>
    </div>
    <div class="col" style="text-align: right;">
        <button class="btn btn-outline-light" (click)="openModal()" *ngIf="db?.usuario?.permissoes['planos']  == 'EDITAR'">
            <fa-icon [icon]="['fas', 'plus']"></fa-icon>&nbsp;NOVO
        </button>
    </div>
</div>
<table class="table table-striped" style="text-align: center;">
    <thead class="thead-dark">
        <tr>
            <th>Nome</th>
            <th>Empresa</th>
            <th>Tipo</th>
            <th>Mensalidade</th>
            <th>Vendas</th>
            <th *ngIf="db?.usuario?.permissoes['planos']  == 'EDITAR'">Editar</th>
            <th *ngIf="db?.usuario?.permissoes['planos']  == 'EDITAR'">Excluir</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let plano of planos; let i = index">
            <td>{{ plano?.nome }}</td>
            <td [ngStyle]="{'color': plano?.empresaOrigem == 'VIVA MAX' ? '#e64249' : plano?.empresaOrigem == 'SEGUROS' ? '#99131a' : '#00e0c2'}" style="font-weight: bold;">{{ plano?.empresaOrigem }}</td>
            <td>{{ plano?.tipo }}</td>
            <td>{{ plano?.mensalidade | currency:'BRL' }}</td>
            <td>{{ plano?.planoVendas ? 'SIM' : 'NÃO' }}</td>
            <td *ngIf="db?.usuario?.permissoes['planos']  == 'EDITAR'">
                <button class="btn btn-outline-success" (click)="openEditar(plano)">
                    <fa-icon [icon]="['fas', 'edit']"></fa-icon>
                </button>
            </td>
            <td *ngIf="db?.usuario?.permissoes['planos']  == 'EDITAR'">
                <button class="btn btn-outline-danger" (click)="delete(plano)">
                    <fa-icon [icon]="['fas', 'trash']"></fa-icon>
                </button>
            </td>
        </tr>
    </tbody>
</table>
</div>