<div class="container-fluid">
    <div class="modalCartao" id="modalCartao">
        <div class="box">
        <h3>CADASTRAR NOVO BANCO</h3>
        <br>
        <div class="row">
            <div class="col">
                <input type="text" class="form-control" [(ngModel)]="novoBanco" oninput="this.value = this.value.toUpperCase()">
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col">
                <button class="btn btn-dark btn-lg" (click)="closemodalCartao()">FECHAR</button>
            </div>
            <div class="col">
                <button class="btn btn-success btn-lg" (click)="cadastrarBanco()">CADASTRAR</button>
            </div>
        </div>
        </div>
    </div>
    <div class="modalCartao" id="modalTags">
        <div class="box">
        <h3>CADASTRAR NOVA TAG</h3>
        <br>
        <div class="row">
            <div class="col">
                <input type="text" class="form-control" [(ngModel)]="novaTag" oninput="this.value = this.value.toUpperCase()">
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col">
                <button class="btn btn-dark btn-lg" (click)="closeModalTags()">FECHAR</button>
            </div>
            <div class="col">
                <button class="btn btn-success btn-lg" (click)="cadastrarTags()">CADASTRAR</button>
            </div>
        </div>
        </div>
    </div>
    <div class="modalCartao" id="modalNomesNegociacao">
        <div class="box">
        <h3>CADASTRAR NOVO NOME DE NEGOCIAÇÃO</h3>
        <br>
        <div class="row">
            <div class="col">
                <input type="text" class="form-control" [(ngModel)]="novoNomeNegociacao" oninput="this.value = this.value.toUpperCase()">
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col">
                <button class="btn btn-dark btn-lg" (click)="closeModalNomesNegociacao()">FECHAR</button>
            </div>
            <div class="col">
                <button class="btn btn-success btn-lg" (click)="cadastrarNomesNegociacao()">CADASTRAR</button>
            </div>
        </div>
        </div>
    </div>
    <div class="row  align-items-center header">
        <div class="col titulo" style="text-align: left;">
            CONFIGURAÇÕES
        </div>
    </div>
    <br>
    <tabset>    
        <tab heading="BANCOS" style="padding:10px">
            <table class="table table-striped" style="text-align: center;">
                <thead class="thead-dark">
                    <tr>
                        <th>Nome Do Banco</th>
                        <th style="text-align: right;">
                            <button class="btn btn-outline-light" (click)="openmodalCartao()">
                                <fa-icon [icon]="['fas', 'save']"></fa-icon>&nbsp;NOVO
                            </button>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let banco of bancos; let i = index">
                        <td>{{ banco?.nome }}</td>
                        <td>
                            <button class="btn btn-outline-danger" (click)="excluirBanco(banco.nome)">
                                <fa-icon [icon]="['fas', 'trash']"></fa-icon>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </tab>
        <tab heading="MENSAGENS PADRÕES" style="padding:10px">
            <div class="row">
                <div class="col">
                    <label>Mensagem Aniversário</label>
                    <textarea  class="form-control" [(ngModel)]="msgNiver"></textarea>
                </div>
                <div class="col col-2">
                    <br>
                    <button class="btn btn-success btn-lg" (click)="salvarMsg()">SALVAR</button>
                </div>
            </div>
        </tab>
        <tab heading="SORTEADO DO MÊS" style="padding:10px">
            <div class="row">
                <div class="col col-3">
                    <label>Data</label>
                    <input type="date" class="form-control"[(ngModel)]="dataSorteado">
                </div>
                <div class="col col-3">
                    <label>Número</label>
                    <input type="number" class="form-control"[(ngModel)]="numeroSorteado">
                </div>
                <div class="col col-2">
                    <br>
                    <button class="btn btn-success btn-lg" (click)="salvarSorteado()">INCLUIR</button>
                </div>
            </div>
            <div style="height: 2em;"></div>
            <table class="table table-striped">
                <thead>
                    <tr>
                        <td>Data</td>
                        <td>Número</td>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let sorteio of sorteios">
                        <td>{{ sorteio?.data | date:'dd/MM/yyyy' }}</td>
                        <td>{{ sorteio?.numero }}</td>
                    </tr>
                </tbody>
            </table>
        </tab>
        <tab heading="FORMULÁRIOS ÁREA DO CLIENTE" style="padding:10px">
            <div style="width: 800px; height: 400px;display: none;">
                <ng2-pdfjs-viewer 
                #pdfViewerConfiguracoes
                [externalWindow]="true"
                [downloadFileName]="'vivamax.pdf'"
                [openFile]="false"
                [viewBookmark]="false"
                [download]="true"></ng2-pdfjs-viewer>
            </div>
            <br>
        <div class="row">
                <div class="col">
                    <div class="col">
                        <h4>Formulário Seguros</h4>
                    </div>
                </div>
            </div>
            <div class="row align-items-center" style="width: 80%">
                <div class="col col-4">
                    <div class="row justify-content-md-center">
                        <div class="col col-12" style="margin: 10px; background-color:#ce2626; color:white; height: 250px; width:100%; text-align: center; border-radius: 10px;">
                            <div (click)="openModalPdfViewer(formularioSeguro)" style="position: relative;left:50%;top:50%;transform: translate(-50%,-50%);cursor:pointer">
                                <fa-icon [icon]="['fas', 'file-pdf']" style="font-size: 5em;"></fa-icon>
                                    <br>DOCUMENTO PDF
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col col-8" style="text-align:center">
                    <div class="uploadfilecontainer" (click)="fileInputSeguro.click()" appDragDrop (onFileDropped)="onChangeSeguro($event)">
                        <input hidden type="file" #fileInputSeguro (change)="onChangeSeguroClick($event)">
                    </div>
                    <div class="files-list" *ngFor="let file of filesSeguro;let i= index">
                        <div class="row align-items-center" style="width: 100%;">
                            <div class="col col-10">
                                <p>{{ file }}</p>
                            </div>
                            <div class="col col-2 del" (click)="deleteDocSeguro(i)" style="cursor: pointer;">
                                X
                            </div>
                        </div>
                    </div><br>
                    <div style="width: 70%;margin:auto;">
                        <button class="btn btn-primary btn-block" (click)="uploadFileSeguro()" [disabled]="selectedfilesSeguro?.length < 1">
                            <fa-icon [icon]="['fas', 'upload']"></fa-icon>&nbsp;&nbsp;ATUALIZAR FORMULÁRIO DE SEGUROS
                        </button>
                    </div>
                </div>
            </div>
            <br>
            <hr>
            <br>
            <div class="row">
                <div class="col">
                    <div class="col">
                        <h4>Formulário Reembolso</h4>
                    </div>
                </div>
            </div>
            <div class="row" style="width: 80%;">
                <div class="col col-4">
                    <div class="row justify-content-md-center">
                        <div class="col col-12" style="margin: 10px; background-color:#ce2626; color:white; height: 250px; width:100%; text-align: center; border-radius: 10px;">
                            <div (click)="openModalPdfViewer(formularioReembolso)" style="position: relative;left:50%;top:50%;transform: translate(-50%,-50%);cursor:pointer">
                                <fa-icon [icon]="['fas', 'file-pdf']" style="font-size: 5em;"></fa-icon>
                                    <br>DOCUMENTO PDF
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col col-8">
                    <div class="uploadfilecontainer" (click)="fileInputReembolso.click()" appDragDrop (onFileDropped)="onChangeReembolso($event)">
                        <input hidden type="file" #fileInputReembolso (change)="onChangeReembolsoClick($event)">
                    </div>
                    <div class="files-list" *ngFor="let file of filesReembolso;let i= index">
                        <div class="row align-items-center" style="width: 100%;">
                            <div class="col col-10">
                                <p>{{ file }}</p>
                            </div>
                            <div class="col col-2 del" (click)="deleteDocReembolso(i)" style="cursor: pointer;">
                                X
                            </div>
                        </div>
                    </div><br>
                    <div style="width: 70%;margin:auto;">
                        <button class="btn btn-primary btn-block" (click)="uploadFileReembolso()" [disabled]="selectedfilesReembolso?.length < 1">
                            <fa-icon [icon]="['fas', 'upload']"></fa-icon>&nbsp;&nbsp;ATUALIZAR FORMULÁRIO DE REEMBOLSO
                        </button>
                    </div>
                </div>
            </div>
            <br>
            <hr>
            <br>
            <div class="row">
                <div class="col">
                    <div class="col">
                        <h4>Formulário Cancelamento</h4>
                    </div>
                </div>
            </div>
            <div class="row" style="width: 80%;">
                <div class="col col-4">
                    <div class="row justify-content-md-center">
                        <div class="col col-12" style="margin: 10px; background-color:#ce2626; color:white; height: 250px; width:100%; text-align: center; border-radius: 10px;">
                            <div (click)="openModalPdfViewer(formularioCancelamento)" style="position: relative;left:50%;top:50%;transform: translate(-50%,-50%);cursor:pointer">
                                <fa-icon [icon]="['fas', 'file-pdf']" style="font-size: 5em;"></fa-icon>
                                    <br>DOCUMENTO PDF
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col col-8">
                    <div class="uploadfilecontainer" (click)="fileInputCancelamento.click()" appDragDrop (onFileDropped)="onChangeCancelamento($event)">
                        <input hidden type="file" #fileInputCancelamento (change)="onChangeCancelamentoClick($event)">
                    </div>
                    <div class="files-list" *ngFor="let file of filesCancelamento;let i= index">
                        <div class="row align-items-center" style="width: 100%;">
                            <div class="col col-10">
                                <p>{{ file }}</p>
                            </div>
                            <div class="col col-2 del" (click)="deleteDocCancelamento(i)" style="cursor: pointer;">
                                X
                            </div>
                        </div>
                    </div><br>
                    <div style="width: 70%;margin:auto;">
                        <button class="btn btn-primary btn-block" (click)="uploadFileCancelamento()" [disabled]="selectedfilesCancelamento?.length < 1">
                            <fa-icon [icon]="['fas', 'upload']"></fa-icon>&nbsp;&nbsp;ATUALIZAR FORMULÁRIO DE CANCELAMENTO
                        </button>
                    </div>
                </div>
            </div>
            <br><br>
        </tab>
        <tab heading="TIPOS DE ENTRADA" style="padding:10px">
            <div style="padding:50px">
                <form [formGroup]="tiposEntradaGroup">
                    <div class="row" formArrayName="tiposEntrada">
                        <div class="col col-12" style="font-size: 0.85em" *ngFor="let tipo of tiposEntradaFormGroup.controls; let i = index;">
                            <div [formGroupName]="i" class="row">
                                <div class="form-group col-9">
                                    <input type="text" class="form-control" formControlName="nome" placeholder="Tipo de Entrada" oninput="this.value = this.value.toUpperCase()">
                                </div>
                                <div class="form-group col-3 text-right">
                                    <button class="btn btn-danger btn-sm" type="button" (click)="removeTiposEntrada(i)" *ngIf="this.tiposEntradaList.length > 1">
                                    <fa-icon [icon]="['fas', 'minus-circle']" size="1x"></fa-icon>
                                    </button>&nbsp;
                                    <button class="btn btn-success btn-sm" type="button" (click)="addTiposEntrada()">
                                    <fa-icon [icon]="['fas', 'plus-circle']" size="1x"></fa-icon>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>   
                </form>
            <br>
            <div class="row">
                <div class="col col-4">
                    &nbsp;
                </div>
                <div class="col col-4">
                        <button class="btn btn-success btn-block" (click)="salvarTiposEntrada()">SALVAR</button>
                </div>
                <div class="col col-4">
                        &nbsp;
                    </div>
            </div>
            </div>
        </tab>
        <tab heading="MOTIVOS ATENDIMENTO" style="padding:10px">
            <div style="padding:50px">
                <form [formGroup]="motivoAtendimentoGroup">
                    <div class="row" formArrayName="motivoAtendimento">
                        <div class="col col-12" style="font-size: 0.85em" *ngFor="let motivo of motivosAtendimentoFormGroup.controls; let i = index;">
                            <div [formGroupName]="i" class="row">
                                <div class="form-group col-9">
                                    <input type="text" class="form-control" formControlName="nome" placeholder="Motivo da Pendência" oninput="this.value = this.value.toUpperCase()">
                                </div>
                                <div class="form-group col-3 text-right">
                                    <button class="btn btn-danger btn-sm" type="button" (click)="removeMotivoAtendimento(i)" *ngIf="this.motivosAtendimentoList.length > 1">
                                    <fa-icon [icon]="['fas', 'minus-circle']" size="1x"></fa-icon>
                                    </button>&nbsp;
                                    <button class="btn btn-success btn-sm" type="button" (click)="addMotivoAtendimento()">
                                    <fa-icon [icon]="['fas', 'plus-circle']" size="1x"></fa-icon>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>   
                </form>
            <br>
            <div class="row">
                <div class="col col-4">
                    &nbsp;
                </div>
                <div class="col col-4">
                        <button class="btn btn-success btn-block" (click)="salvarMotivosAtendimento()">SALVAR</button>
                </div>
                <div class="col col-4">
                        &nbsp;
                    </div>
            </div>
            </div>
        </tab>
        <tab heading="MOTIVOS PENDÊNCIA" style="padding:10px">
            <div style="padding:50px">
                <form [formGroup]="motivoGroup">
                    <div class="row" formArrayName="motivo">
                        <div class="col col-12" style="font-size: 0.85em" *ngFor="let motivo of motivosPendenciaFormGroup.controls; let i = index;">
                            <div [formGroupName]="i" class="row">
                                <div class="form-group col-9">
                                    <input type="text" class="form-control" formControlName="nome" placeholder="Motivo da Pendência" oninput="this.value = this.value.toUpperCase()">
                                </div>
                                <div class="form-group col-3 text-right">
                                    <button class="btn btn-danger btn-sm" type="button" (click)="removeMotivo(i)" *ngIf="this.motivosPendenciaList.length > 1">
                                    <fa-icon [icon]="['fas', 'minus-circle']" size="1x"></fa-icon>
                                    </button>&nbsp;
                                    <button class="btn btn-success btn-sm" type="button" (click)="addMotivo()">
                                    <fa-icon [icon]="['fas', 'plus-circle']" size="1x"></fa-icon>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>   
                </form>
            <br>
            <div class="row">
                <div class="col col-4">
                    &nbsp;
                </div>
                <div class="col col-4">
                        <button class="btn btn-success btn-block" (click)="salvarMotivosPendencia()">SALVAR</button>
                </div>
                <div class="col col-4">
                        &nbsp;
                    </div>
            </div>
            </div>
        </tab>
        <tab heading="MOTIVOS CANCELAMENTO" style="padding:10px">
            <div style="padding:50px">
                <form [formGroup]="motivoCancelamentoGroup">
                    <div class="row" formArrayName="motivoCancelamento">
                        <div class="col col-12" style="font-size: 0.85em" *ngFor="let motivoCancelamento of motivosCancelamentoFormGroup.controls; let i = index;">
                            <div [formGroupName]="i" class="row">
                                <div class="form-group col-9">
                                    <input type="text" class="form-control" formControlName="nome" placeholder="Motivo do Cancelamento" oninput="this.value = this.value.toUpperCase()">
                                </div>
                                <div class="form-group col-3 text-right">
                                    <button class="btn btn-danger btn-sm" type="button" (click)="removeMotivoCancelamento(i)" *ngIf="this.motivosCancelamentoList.length > 1">
                                    <fa-icon [icon]="['fas', 'minus-circle']" size="1x"></fa-icon>
                                    </button>&nbsp;
                                    <button class="btn btn-success btn-sm" type="button" (click)="addMotivoCancelamento()">
                                    <fa-icon [icon]="['fas', 'plus-circle']" size="1x"></fa-icon>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>   
                </form>
            <br>
            <div class="row">
                <div class="col col-4">
                    &nbsp;
                </div>
                <div class="col col-4">
                        <button class="btn btn-success btn-block" (click)="salvarMotivosCancelamento()">SALVAR</button>
                </div>
                <div class="col col-4">
                        &nbsp;
                    </div>
            </div>
            </div>
        </tab>
        <tab heading="MOTIVOS INADIMPLENTES" style="padding:10px">
            <div style="padding:50px">
                <form [formGroup]="motivoInadimplenteGroup">
                    <div class="row" formArrayName="motivoInadimplente">
                        <div class="col col-12" style="font-size: 0.85em" *ngFor="let motivoInadimplente of motivosInadimplenteFormGroup.controls; let i = index;">
                            <div [formGroupName]="i" class="row">
                                <div class="form-group col-9">
                                    <input type="text" class="form-control" formControlName="nome" placeholder="Motivo da Inadimplência" oninput="this.value = this.value.toUpperCase()">
                                </div>
                                <div class="form-group col-3 text-right">
                                    <button class="btn btn-danger btn-sm" type="button" (click)="removeMotivoInadimplente(i)" *ngIf="this.motivosInadimplenteList.length > 1">
                                    <fa-icon [icon]="['fas', 'minus-circle']" size="1x"></fa-icon>
                                    </button>&nbsp;
                                    <button class="btn btn-success btn-sm" type="button" (click)="addMotivoInadimplente()">
                                    <fa-icon [icon]="['fas', 'plus-circle']" size="1x"></fa-icon>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>   
                </form>
            <br>
            <div class="row">
                <div class="col col-4">
                    &nbsp;
                </div>
                <div class="col col-4">
                        <button class="btn btn-success btn-block" (click)="salvarMotivosInadimplente()">SALVAR</button>
                </div>
                <div class="col col-4">
                        &nbsp;
                    </div>
            </div>
            </div>
        </tab>
        <tab heading="FATURAMENTO" style="padding:10px">
            <div style="padding:50px">
            <div class="row">
                <div class="col col-4">
                    <label>Valor de Faturamento Minimo</label>
                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                        <span class="input-group-text" id="bsic-addon66">R$</span>
                        </div>
                        <input type="number" class="form-control" [(ngModel)]="valorMinimoFaturamento">
                    </div>
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col col-4">
                    &nbsp;
                </div>
                <div class="col col-4">
                        <button class="btn btn-success btn-block" (click)="salvarValorMinimoFaturamento()">SALVAR</button>
                </div>
                <div class="col col-4">
                        &nbsp;
                    </div>
            </div>
            </div>
        </tab>
        <tab heading="AVISOS E ALERTAS" style="padding:10px">
            <div style="padding:50px">
                <div class="row">
                    <div class="col">
                        <label>Contas a Pagar</label><br>      
                        <div class="row align-items-start" style="padding:5px;">
                            <div class="col">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="vCheckCP" [(ngModel)]="arrayAlertas.contasPagar.vendedor">
                                    <label class="form-check-label" for="vCheckCP">
                                        VENDEDOR
                                    </label>
                                </div>
                            </div>
                            <div class="col">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="cCheckCP" [(ngModel)]="arrayAlertas.contasPagar.coordenador">
                                    <label class="form-check-label" for="cCheckCP">
                                        COORDENADOR
                                    </label>
                                </div>
                            </div>
                            <div class="col">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="gCheckCP" [(ngModel)]="arrayAlertas.contasPagar.gerente">
                                    <label class="form-check-label" for="gCheckCP">
                                        GERENTE
                                    </label>
                                </div>
                            </div>
                            <div class="col">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="aACheckCP" [(ngModel)]="arrayAlertas.contasPagar.auxAdministrativo">
                                    <label class="form-check-label" for="aACheckCP">
                                        AUX. ADMINISTRATIVO
                                    </label>
                                </div>
                            </div>
                            <div class="col">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="aCheckCP" [(ngModel)]="arrayAlertas.contasPagar.administrador">
                                    <label class="form-check-label" for="aCheckCP">
                                        ADMINISTRADOR
                                    </label>
                                </div>
                            </div>
                            <div class="col">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="mCheckCP" [(ngModel)]="arrayAlertas.contasPagar.master">
                                    <label class="form-check-label" for="mCheckCP">
                                        MASTER
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br>
                <hr>
                <br>
                <div class="row">
                    <div class="col">
                        <label>Contas a Receber</label><br>      
                        <div class="row align-items-start" style="padding:5px;">
                            <div class="col">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="vCheckCR" [(ngModel)]="arrayAlertas.contasReceber.vendedor">
                                    <label class="form-check-label" for="vCheckCR">
                                        VENDEDOR
                                    </label>
                                </div>
                            </div>
                            <div class="col">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="cCheckCR" [(ngModel)]="arrayAlertas.contasReceber.coordenador">
                                    <label class="form-check-label" for="cCheckCR">
                                        COORDENADOR
                                    </label>
                                </div>
                            </div>
                            <div class="col">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="gCheckCR" [(ngModel)]="arrayAlertas.contasReceber.gerente">
                                    <label class="form-check-label" for="gCheckCR">
                                        GERENTE
                                    </label>
                                </div>
                            </div>
                            <div class="col">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="aACheckCR" [(ngModel)]="arrayAlertas.contasReceber.auxAdministrativo">
                                    <label class="form-check-label" for="aACheckCR">
                                        AUX. ADMINISTRATIVO
                                    </label>
                                </div>
                            </div>
                            <div class="col">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="aCheckCR" [(ngModel)]="arrayAlertas.contasReceber.administrador">
                                    <label class="form-check-label" for="aCheckCR">
                                        ADMINISTRADOR
                                    </label>
                                </div>
                            </div>
                            <div class="col">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="mCheckCR" [(ngModel)]="arrayAlertas.contasReceber.master">
                                    <label class="form-check-label" for="mCheckCR">
                                        MASTER
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br>
                <hr>
                <br>
                <div class="row">
                    <div class="col">
                        <label>Faturamento</label><br>      
                        <div class="row align-items-start" style="padding:5px;">
                            <div class="col">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="vCheckF" [(ngModel)]="arrayAlertas.faturamento.vendedor">
                                    <label class="form-check-label" for="vCheckF">
                                        VENDEDOR
                                    </label>
                                </div>
                            </div>
                            <div class="col">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="cCheckF" [(ngModel)]="arrayAlertas.faturamento.coordenador">
                                    <label class="form-check-label" for="cCheckF">
                                        COORDENADOR
                                    </label>
                                </div>
                            </div>
                            <div class="col">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="gCheckF" [(ngModel)]="arrayAlertas.faturamento.gerente">
                                    <label class="form-check-label" for="gCheckF">
                                        GERENTE
                                    </label>
                                </div>
                            </div>
                            <div class="col">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="aACheckF" [(ngModel)]="arrayAlertas.faturamento.auxAdministrativo">
                                    <label class="form-check-label" for="aACheckF">
                                        AUX. ADMINISTRATIVO
                                    </label>
                                </div>
                            </div>
                            <div class="col">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="aCheckF" [(ngModel)]="arrayAlertas.faturamento.administrador">
                                    <label class="form-check-label" for="aCheckF">
                                        ADMINISTRADOR
                                    </label>
                                </div>
                            </div>
                            <div class="col">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="mCheckF" [(ngModel)]="arrayAlertas.faturamento.master">
                                    <label class="form-check-label" for="mCheckF">
                                        MASTER
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br>
                <hr>
                <br>
                <div class="row">
                    <div class="col">
                        <label>Agendamentos</label><br>      
                        <div class="row align-items-start" style="padding:5px;">
                            <div class="col">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="vCheckAg" [(ngModel)]="arrayAlertas.agendamento.vendedor">
                                    <label class="form-check-label" for="vCheckAg">
                                        VENDEDOR
                                    </label>
                                </div>
                            </div>
                            <div class="col">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="cCheckAg" [(ngModel)]="arrayAlertas.agendamento.coordenador">
                                    <label class="form-check-label" for="cCheckAg">
                                        COORDENADOR
                                    </label>
                                </div>
                            </div>
                            <div class="col">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="gCheckAg" [(ngModel)]="arrayAlertas.agendamento.gerente">
                                    <label class="form-check-label" for="gCheckAg">
                                        GERENTE
                                    </label>
                                </div>
                            </div>
                            <div class="col">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="aACheckAg" [(ngModel)]="arrayAlertas.agendamento.auxAdministrativo">
                                    <label class="form-check-label" for="aACheckAg">
                                        AUX. ADMINISTRATIVO
                                    </label>
                                </div>
                            </div>
                            <div class="col">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="aCheckAg" [(ngModel)]="arrayAlertas.agendamento.administrador">
                                    <label class="form-check-label" for="aCheckAg">
                                        ADMINISTRADOR
                                    </label>
                                </div>
                            </div>
                            <div class="col">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="mCheckAg" [(ngModel)]="arrayAlertas.agendamento.master">
                                    <label class="form-check-label" for="mCheckAg">
                                        MASTER
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br>
                <hr>
                <br>
                <div class="row">
                    <div class="col">
                        <label>Tarefas</label><br>      
                        <div class="row align-items-start" style="padding:5px;">
                            <div class="col">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="vCheckT" [(ngModel)]="arrayAlertas.tarefas.vendedor">
                                    <label class="form-check-label" for="vCheckT">
                                        VENDEDOR
                                    </label>
                                </div>
                            </div>
                            <div class="col">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="cCheckT" [(ngModel)]="arrayAlertas.tarefas.coordenador">
                                    <label class="form-check-label" for="cCheckT">
                                        COORDENADOR
                                    </label>
                                </div>
                            </div>
                            <div class="col">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="gCheckT" [(ngModel)]="arrayAlertas.tarefas.gerente">
                                    <label class="form-check-label" for="gCheckT">
                                        GERENTE
                                    </label>
                                </div>
                            </div>
                            <div class="col">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="aACheckT" [(ngModel)]="arrayAlertas.tarefas.auxAdministrativo">
                                    <label class="form-check-label" for="aACheckT">
                                        AUX. ADMINISTRATIVO
                                    </label>
                                </div>
                            </div>
                            <div class="col">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="aCheckT" [(ngModel)]="arrayAlertas.tarefas.administrador">
                                    <label class="form-check-label" for="aCheckT">
                                        ADMINISTRADOR
                                    </label>
                                </div>
                            </div>
                            <div class="col">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="mCheckT" [(ngModel)]="arrayAlertas.tarefas.master">
                                    <label class="form-check-label" for="mCheckT">
                                        MASTER
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            <br>
            <div class="row">
                <div class="col col-4">
                    &nbsp;
                </div>
                <div class="col col-4">
                        <button class="btn btn-success btn-block" (click)="salvarAlertas()">SALVAR</button>
                </div>
                <div class="col col-4">
                        &nbsp;
                    </div>
            </div>
            </div>
        </tab>
        <!-- <tab heading="FUNIL DE VENDAS - TAGS" style="padding:10px">
            <table class="table table-striped" style="text-align: center;">
                <thead class="thead-dark">
                    <tr>
                        <th>TAGS</th>
                        <th style="text-align: right;">
                            <button class="btn btn-outline-light" (click)="openModalTags()">
                                <fa-icon [icon]="['fas', 'save']"></fa-icon>&nbsp;NOVO
                            </button>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let tag of tags; let i = index">
                        <td>{{ tag?.nome }}</td>
                        <td>
                            <button class="btn btn-outline-danger" (click)="excluirTags(tag.nome)">
                                <fa-icon [icon]="['fas', 'trash']"></fa-icon>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </tab>
        <tab heading="FUNIL DE VENDAS - NOMES DE NEGOCIAÇÃO" style="padding:10px">
            <table class="table table-striped" style="text-align: center;">
                <thead class="thead-dark">
                    <tr>
                        <th>Nome de Negociação</th>
                        <th style="text-align: right;">
                            <button class="btn btn-outline-light" (click)="openModalNomesNegociacao()">
                                <fa-icon [icon]="['fas', 'save']"></fa-icon>&nbsp;NOVO
                            </button>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let nn of nomesNegociacao; let i = index">
                        <td>{{ nn?.nome }}</td>
                        <td>
                            <button class="btn btn-outline-danger" (click)="excluirNomesNegociacao(nn.nome)">
                                <fa-icon [icon]="['fas', 'trash']"></fa-icon>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </tab> -->
        <tab heading="TELEFONE WHATSAPP - SITE" style="padding:10px">
            <div class="row">
                <div class="col">
                    <label>Celular:</label>
                    <input type="text" class="form-control" [(ngModel)]="celularWhatsSite">
                </div>
                <div class="col col-2">
                    <br>
                    <button class="btn btn-danger" (click)="salvarTelefoneWhats()">Salvar</button>
                </div>
            </div>
        </tab>
        <tab heading="NUMERO DA SORTE" style="padding:10px">
            <div class="row align-items-center">
                <div class="col">
                    <label>Buscar por Número</label>
                    <input type="number" class="form-control" [(ngModel)]="numeroSorteBuscar">
                </div>
                <div class="col">
                    <br>
                    <button class="btn btn-primary" (click)="buscarPorNumeroSorte(numeroSorteBuscar)">BUSCAR</button>
                </div>
                <div class="col">
                    <label>Filtro</label>
                    <select class="form-control" [(ngModel)]="situacaoNumeroSorte" (change)="filtrarNumSorte(situacaoNumeroSorte)">
                        <option value="TODOS">TODOS</option>
                        <option value="LIVRES">LIVRES</option>
                        <option value="USADOS">USADOS</option>
                    </select>
                </div>
            </div>
            <br>
            <table class="table table-striped">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Número</th>
                        <th>Usado</th>
                        <th>Mudar Situação</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let n of numerosSorteFiltrados; let i = index">
                        <td>{{i+1}}</td>
                        <td>{{n.numero}}</td>
                        <td>{{n.usado ? 'USADO' : 'LIVRE'}}</td>
                        <td> 
                            <label class="switch" (change)="mudarStatusNumSorte($event,n,i)">
                                <input type="checkbox" [checked]="n.usado">
                                <span class="slider round"></span>
                            </label>
                        </td>
                    </tr>
                </tbody>
            </table>
        </tab>
        <tab heading="TELEFONE PLANTÃO" style="padding:10px">
            <div class="row">
                <div class="col">
                    <label>Número do Telefone de Plantão:</label>
                    <input type="text" class="form-control" [(ngModel)]="telPlantao">
                </div>
                <div class="col col-2">
                    <br>
                    <button class="btn btn-danger" (click)="salvarTelefonePlantao()">Salvar</button>
                </div>
            </div>
        </tab>
    </tabset>
</div>