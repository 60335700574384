<div class="bgInicio">
  <div class="wrapper fadeInDown">
      <div id="formContent">
          <div class="fadeIn fourth">
            <br><br>
              <div style="text-align: center;">
                <img src="../../assets/img/logo.png">
              </div>
            <br><br>
          </div>
        <div style="padding:20px">
          <form (ngSubmit)="authService.login(email, senha)">
            <input type="email" class="form-control" [(ngModel)]="email" placeholder="E-mail" [ngModelOptions]="{standalone: true}">
            <br>
            <div class="input-group mb-3">
              <input class="form-control" [(ngModel)]="senha" placeholder="Senha" [type]="show ? 'text' : 'password'" [ngModelOptions]="{standalone: true}">
              <div class="input-group-prepend" (click)="showSenha()">
                <span class="input-group-text" id="basic-addon1"><fa-icon [icon]="['fas', 'eye']"></fa-icon></span>               
              </div>
            </div>
            <div *ngIf="confereSenha(senha)" class="msgAlert">NÃO UTILIZE OS CARACTERES (%, $, # e &)</div>
            <br>
            <button type="submit" class="btn btn-success btn-lg btn-block" [disabled]="confereSenha(senha)">ENTRAR</button>
        </form> 
        </div>
      </div>
    </div>
</div>