<!-- MODAL FATURAR -->
<div class="modalFat" id="modFaturar">
    <div class="box">
      <h2 style="color:brown">FATURAMENTO - ANEXAR BOLETO</h2><br>
     <br>
     <div class="row">
        <div class="col">
            <div class="uploadfilecontainer" (click)="fileDocumentosFat.click()" appDragDrop (onFileDropped)="onChange($event)">
                <input hidden type="file" #fileDocumentosFat (change)="onChangeClick($event)">
            </div>
            <div class="row align-items-center">
                <div class="col col-1">
                    &nbsp;
                </div>
                <div class="col">
                    <div class="files-list" *ngFor="let file of files; let i= index">
                        <p>{{ file }}</p>
                    </div> 
                </div>
                <div class="col col-1">
                    &nbsp;
                </div>
            </div>
        </div>
    </div>
     <br>
      <div class="row" style="text-align: center;">
          <div class="col">
            <button class="btn btn-dark btn-lg" (click)="closeModalFaturar()">FECHAR</button>
          </div>
          <div class="col">
            <button class="btn btn-danger btn-lg" (click)="faturar()" [disabled]="faturando">FATURAR</button>
          </div>
      </div>
    </div>
  </div>
  <!-- FIM MODAL FATURAR -->
  <!-- MODAL ASSOCIADOS -->
<div class="modalAssoc" id="modAssoc">
    <div class="box">
      <h2 style="color:brown">ASSOCIADOS</h2>
     <br>
    <table class="table table-striped" style="text-align: center;" id="divToPrintFaturamento">
        <thead class="thead-dark">
            <tr>
                <th>#</th>
                <th>Adesão</th>
                <th>Matricula</th>
                <th>Nome</th>
                <th>CPF</th>
                <th>Mensalidade</th>
                <th>Situação</th>
                <th *ngIf="empresaSelecionada?.tipo == 'Afastado'">Empresa</th>
                <th *ngIf="empresaSelecionada?.tipo == 'Afastado'">Afastado Desde</th>
                <th [hidden]="empresaSelecionada?.tipo == 'Afastado' || empresaSelecionada?.status == 'FATURADO'">Faturar</th>
            </tr>
        </thead>
        <tbody *ngFor="let ass of associados; let i = index">
            <tr>
                <td>{{ i + 1 }}</td>
                <td>{{ ass?.adesao | date:'dd/MM/yyyy' }}</td>
                <td>{{ ass?.matricula }}</td>
                <td>{{ ass?.associado }}</td>
                <td>{{ ass?.cpf }}</td>
                <td>{{ ass?.mensalidade | currency:'BRL' }}</td>
                <td>{{ ass?.situacao }}</td>
                <td *ngIf="empresaSelecionada?.tipo == 'Afastado'">{{ ass?.empresa }}</td>
                <td *ngIf="empresaSelecionada?.tipo == 'Afastado'">{{ ass?.afastadoDesde | date:'dd/MM/yyyy' }}</td>
                <td [hidden]="empresaSelecionada?.tipo == 'Afastado' || empresaSelecionada?.status == 'FATURADO'">
                    <input class="form-check-input" type="checkbox" [checked]="ass?.marcado" (change)="checkAssociado($event,i)">
                </td>
            </tr>
        </tbody>
    </table>
     <br>
     <div class="row" style="text-align: center;">
        <div class="col">
          <button class="btn btn-dark btn-lg" (click)="closeModalAssociados()">FECHAR</button>
        </div>
        <div class="col" [hidden]="empresaSelecionada?.tipo  == 'Afastado'">
          <button class="btn btn-danger btn-lg" (click)="salvarAssociadosFaturamento(associados)">SALVAR</button>
        </div>
        <div class="col">
            <button class="btn btn-success btn-lg" (click)="printXsl()">EXPORTAR EXCEL</button>
        </div>
    </div>
     <br>
    </div>
  </div>
  <!-- FIM MODAL VER ASSOCIADOS -->
<div class="row align-items-center header">
    <div class="col titulo" style="text-align: left;">
        FATURAMENTO
    </div>
    <div class="col">
        <button class="btn btn-outline-light" (click)="mostrarNaoFaturados()">NÃO FATURADOS</button>
    </div>
    <div class="col">
        <button class="btn btn-outline-light" (click)="mostrarFaturados()">FATURADOS</button>
    </div>
    <div class="col">
        <button class="btn btn-outline-light" (click)="mostrarTodos()">MOSTRAR TODOS</button>
    </div>
</div>
<br>
<div class="row" style="align-items: center;padding:10px">
    <div class="col col-3">
        <label>MÊS FATURAMENTO</label>
        <input type="month" [(ngModel)]="dataFat" class="form-control">
    </div>
    <div class="col col-1">
        <br>
        <button class="btn btn-primary" (click)="getFaturados()" [disabled]="!dataFat">
            <fa-icon [icon]="['fas', 'search']"></fa-icon>
        </button>
    </div>
</div>
<br>
    <table class="table table-striped" style="text-align: center;">
        <thead class="thead-dark">
            <tr>
                <th>Nome</th>
                <th>CNPJ</th>
                <th>Plano</th>
                <th>Vidas Ativas</th>
                <th>Faturamento</th>
                <th>Vencimento</th>
                <th>Total</th>
                <th>Status</th>
                <th>Faturar</th>
                <th>Associados</th>
                <th *ngIf="db.tipoUsuario == 'MASTER'">Excluir</th>
            </tr>
        </thead>
        <tbody *ngFor="let fat of faturamentos; let i = index">
            <tr>
                <td>{{ fat?.nome }}</td>
                <td>{{ fat?.cnpj }}</td>
                <td>{{ fat?.plano }}</td>
                <td>
                    <input type="number" min="0" class="form-control" [(ngModel)]="fat.vidasAtivas" [disabled]="fat?.status == 'FATURADO' || fat?.tipo == 'Afastado'" style="text-align: center;" (change)="mudarVidasAtivas(i,fat.vidasAtivas)">
                </td>
                <td>{{ fat?.dataFaturamento | date: 'dd/MM/yyy'}}</td>
                <td>
                    <input type="date" class="form-control" [(ngModel)]="fat.dataVencimento" [disabled]="fat?.status == 'FATURADO'" style="text-align: center;" (change)="mudarData(fat)">
                </td>
                <td>
                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                        <span class="input-group-text">R$</span>
                        </div>
                        <input type="number" min="0" class="form-control" [(ngModel)]="fat.total" [disabled]="fat?.status == 'FATURADO'" style="text-align: center;" (change)="mudarTotal(fat)">
                    </div> 
                </td>
                <td [ngStyle]="{'background-color': fat?.status == 'FATURADO' ? '#15e649' : fat?.status == 'NÃO FATURADO' && fat?.dataFaturamento > dataHoje ? '#e2e615' : fat?.status == 'NÃO FATURADO' && fat?.dataFaturamento <= dataHoje ? '#d52525' : null, 'color': fat?.status == 'FATURADO' ? '#000' : fat?.status == 'NÃO FATURADO' && fat?.dataFaturamento > dataHoje ? '#000' : '#fff'}"><strong>{{ fat?.status }}</strong></td>
                <td>
                    <button class="btn btn-outline-danger" (click)="openModalFaturar(fat)" *ngIf="fat?.status != 'FATURADO'">
                        FATURAR
                    </button>
                </td>
                <td>
                    <button class="btn btn-outline-success" (click)="openModalAssociados(fat)">
                        <fa-icon [icon]="['fas', 'edit']"></fa-icon>
                    </button>
                </td>
                <td *ngIf="db.tipoUsuario == 'MASTER'">
                    <button class="btn btn-outline-danger" (click)="excluirFaturamento(fat)">
                        <fa-icon [icon]="['fas', 'trash']"></fa-icon>
                    </button>
                </td>
            </tr>
        </tbody>
    </table>
<br>
<div class="row" style="text-align: center;">
    <div class="col col-2">
        &nbsp;
    </div>
    <div class="col col-4">
        <label>TOTAL FATURADO</label><br>
        <strong>{{totalFaturado | currency:'BRL'}}</strong>
    </div>
    <div class="col col-4">
        <label>TOTAL A FATURAR</label><br>
        <strong>{{totalParaFaturar | currency:'BRL'}}</strong>
    </div>
    <div class="col col-2">
        &nbsp;
    </div>
</div>
<div style="height: 3em;"></div>
