import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DataBaseService } from '../provider.service';
import { MASKS } from 'ng-brazil';
import { AppComponent } from '../app.component';

@Component({
  selector: 'app-credenciados',
  templateUrl: './credenciados.component.html',
  styleUrls: ['./credenciados.component.css']
})

export class CredenciadosComponent implements OnInit {
  public MASKS = MASKS;
  @ViewChild('pdfViewerCredenciados') pdfViewerOnDemand;
  idEspecialidades:any = "";
  idCidades:any = "";
  //todosCredenciados:any = [];
  credenciados:any = [];
  todasEspecialidades:any = [];
  todasCidades:any = [];
  todosProfissionais:any = [];
  todosExames:any = [];
  EspecialidadesMedica:FormGroup;
  profissionaisCredenciado:FormGroup;
  profissionaisCredenciadoEditar:FormGroup;
  examesCredenciado:FormGroup;
  examesCredenciadoEditar:FormGroup;
  cidades:FormGroup;
  public especialidadeMedicaList: FormArray;
  public profissionaisCredenciadoList: FormArray;
  public profissionaisCredenciadoListEditar: FormArray;
  public examesCredenciadoList: FormArray;
  public examesCredenciadoListEditar: FormArray;
  public cidadesList: FormArray;
  get EspecialidadesMedicaFormGroup(){return this.EspecialidadesMedica.get('nome') as FormArray;}
  get profissionaisCredenciadoFormGroup(){return this.profissionaisCredenciado.get('profissional') as FormArray;}
  get profissionaisCredenciadoFormGroupEditar(){return this.profissionaisCredenciadoEditar.get('profissionalEditar') as FormArray;}
  get examesCredenciadoFormGroup(){return this.examesCredenciado.get('exame') as FormArray;}
  get examesCredenciadoFormGroupEditar(){return this.examesCredenciadoEditar.get('exameEditar') as FormArray;}
  get cidadesFormGroup(){return this.cidades.get('cidade') as FormArray;}
 
  especialidadeCredenciado:any = "";
  cep:any = "";
  rua:any = "";
  numero:any = "";
  bairro:any = "";
  municipio:any = "";
  estado:any = "";
  complemento:any = "";
  telefone:any = "";
  telefone2:any = "";
  razaoCredenciado:any = "";
  fantasiaCredenciado:any = "";
  cnpjCredenciado:any = "";
  pontoReferencia:any = "";
  nomeContato:any = "";
  email:any = "";
  dataContrato:any = "";
  tabelaUnicaCheck:boolean = false;
  valorEspecialidade:number;
  laboratorioCheck:boolean = false;
  tipoLaboratorio:any ="";
  valorLaboratorio:number;
  precoVariavelCheck:boolean = false;
  variavelDe:number;
  variavelAte:number;
  observacoesInterna:any = "";
  observacoesCliente:any = "";
  especialidadeCredenciadoEditar:any = "";
  cepEditar:any = "";
  ruaEditar:any = "";
  numeroEditar:any = "";
  bairroEditar:any = "";
  municipioEditar:any = "";
  estadoEditar:any = "";
  complementoEditar:any = "";
  telefoneEditar:any = "";
  telefone2Editar:any = "";
  razaoCredenciadoEditar:any = "";
  fantasiaCredenciadoEditar:any = "";
  cnpjCredenciadoEditar:any = "";
  pontoReferenciaEditar:any = "";
  nomeContatoEditar:any = "";
  emailEditar:any = "";
  dataContratoEditar:any = "";
  idCredenciado:any = "";
  nomeBusca:any = "";
  especialidadeBusca:any = "";
  cidadeBusca:any = "";
  exameBusca:any = "";
  tabelaUnicaCheckEditar:boolean = false;
  valorEspecialidadeEditar:number;
  laboratorioCheckEditar:boolean = false;
  tipoLaboratorioEditar:any ="";
  valorLaboratorioEditar:number;
  precoVariavelCheckEditar:boolean = false;
  variavelDeEditar:number;
  variavelAteEditar:number;
  observacoesInternaEditar:any = "";
  observacoesClienteEditar:any = "";
  selectedFiles:any = [];
  files:any = [];
  filesEditar:any = [];
  selectedFilesEditar:any = [];
  situacao:any = "ATIVO";
  situacaoEditar:any = "";
  docs:any = [];
  pdfs:any = [];
  images:any = [];
  indexImage:any = "";
  imagemSelecionada:any = "";
  sindilojasEditar:boolean = false;
  amicEditar:boolean = false;
  sindilojas:boolean = false;
  amic:boolean = false;
  guiaMedica:boolean = false;
  guiaMedicaEditar:boolean = false;
  telefoneExibir:boolean = false;
  telefone2Exibir:boolean = false;
  telefoneExibirEditar:boolean = false;
  telefone2ExibirEditar:boolean = false;
  cpfCredenciado:any = "";
  cpfCredenciadoEditar:any = "";
  percentualEspecialidade:number;
  percentualEspecialidadeEditar:number;
  todoCred:any = [];
  motivoExclusao:any = "";
  credenciado:any = "";
  filtroSituacao:any = "ATIVO";

  constructor(public db:DataBaseService, public formBuilder: FormBuilder, public app:AppComponent) { }

  ngOnInit(){
    this.EspecialidadesMedica = this.formBuilder.group({
      nome: this.formBuilder.array([this.createEspecialidadeMedica()])
    });
    this.especialidadeMedicaList = this.EspecialidadesMedica.get('nome') as FormArray;
    this.profissionaisCredenciado = this.formBuilder.group({
      profissional: this.formBuilder.array([this.createProfissional()])
    });
    this.profissionaisCredenciadoList = this.profissionaisCredenciado.get('profissional') as FormArray;
    this.profissionaisCredenciadoEditar = this.formBuilder.group({
      profissionalEditar: this.formBuilder.array([])
    });
    this.profissionaisCredenciadoListEditar = this.profissionaisCredenciadoEditar.get('profissionalEditar') as FormArray;
    this.examesCredenciado = this.formBuilder.group({
      exame: this.formBuilder.array([this.createExame()])
    });
    this.examesCredenciadoList = this.examesCredenciado.get('exame') as FormArray;
    this.examesCredenciadoEditar = this.formBuilder.group({
      exameEditar: this.formBuilder.array([])
    });
    this.examesCredenciadoListEditar = this.examesCredenciadoEditar.get('exameEditar') as FormArray;
    this.cidades= this.formBuilder.group({
      cidade: this.formBuilder.array([this.createCidade()])
    });
    this.cidadesList = this.cidades.get('cidade') as FormArray;
    this.db.getConfigs().subscribe((z:any) => {
      this.idEspecialidades = z[7]._id;
      this.idCidades = z[8]._id;
      this.todasEspecialidades = z[7].especialidades.sort((a,b) => {if(a.nome < b.nome){return -1}if(b.nome < a.nome){return 1}});
      this.todasCidades = z[8].cidades.sort((a,b) => {if(a.cidade < b.cidade){return -1}if(b.cidade < a.cidade){return 1}});
      let especialidades = z[7].especialidades;
      let cidades = z[8].cidades;
      if(especialidades.length > 0){
        this.especialidadeMedicaList.clear();
        especialidades.forEach((item) => {
          this.addEspecialidadesMedicaStart(item);
        })
      }
      if(cidades.length > 0){
        this.cidadesList.clear();
        cidades.forEach((item) => {
          this.addCidadeStart(item);
        })
      }
    });
    this.getTodosCredenciados();
  }

  createCidadeStart(item):FormGroup{
    return this.formBuilder.group({
      cidade: [item.cidade, Validators.compose([Validators.required])],
      estado: [item.estado, Validators.compose([Validators.required])],
      longitude: [item.longitude, Validators.compose([Validators.required])],
      latitude: [item.latitude, Validators.compose([Validators.required])]
    });
  }

  addCidadeStart(item){
    this.cidadesList.push(this.createCidadeStart(item));
  }
  
  createCidade():FormGroup{
    return this.formBuilder.group({
      cidade: [null, Validators.compose([Validators.required])],
      estado: [null, Validators.compose([Validators.required])],
      longitude: [null, Validators.compose([Validators.required])],
      latitude: [null, Validators.compose([Validators.required])]
    });
  }

  addCidade(){
    this.cidadesList.push(this.createCidade());
  }

  removeCidade(index) {
    this.cidadesList.removeAt(index);
  }

  createEspecialidadeMedicaStart(item):FormGroup{
    return this.formBuilder.group({
      nome: [item.nome, Validators.compose([Validators.required])]
    });
  }

  addEspecialidadesMedicaStart(item){
    this.especialidadeMedicaList.push(this.createEspecialidadeMedicaStart(item));
  }
  
  createEspecialidadeMedica():FormGroup{
    return this.formBuilder.group({
      nome: [null, Validators.compose([Validators.required])]
    });
  }

  addEspecialidadesMedica(){
    this.especialidadeMedicaList.push(this.createEspecialidadeMedica());
  }

  removeEspecialidadesMedica(index) {
    this.especialidadeMedicaList.removeAt(index);
  }

  createProfissionalStart(item):FormGroup{
    return this.formBuilder.group({
      profissional: [item.profissional, Validators.compose([Validators.required])],
      especialidade: [item.especialidade, Validators.compose([Validators.required])],
      valor: [item.valor, Validators.compose([Validators.required])],
      particular: [item.particular, Validators.compose([Validators.required])]
    });
  }

  addProfissionalStart(item){
    this.profissionaisCredenciadoList.push(this.createProfissionalStart(item));
  }
  
  createProfissional():FormGroup{
    return this.formBuilder.group({
      profissional: [null, Validators.compose([Validators.required])],
      especialidade: [null, Validators.compose([Validators.required])],
      valor: [null, Validators.compose([Validators.required])],
      particular: [null, Validators.compose([Validators.required])]
    });
  }

  addProfissional(){
    this.profissionaisCredenciadoList.push(this.createProfissional());
  }

  removeProfissional(index) {
    this.profissionaisCredenciadoList.removeAt(index);
  }

  createProfissionalEditar():FormGroup{
    return this.formBuilder.group({
      profissional: [null, Validators.compose([Validators.required])],
      especialidade: [null, Validators.compose([Validators.required])],
      valor: [null, Validators.compose([Validators.required])],
      particular: [null, Validators.compose([Validators.required])]
    });
  }

  addProfissionalEditar(){
    this.profissionaisCredenciadoListEditar.push(this.createProfissionalEditar());
  }

  removeProfissionalEditar(index) {
    this.profissionaisCredenciadoListEditar.removeAt(index);
  }

  createProfissionalStartEditar(item):FormGroup{
    return this.formBuilder.group({
      profissional: item.profissional,
      especialidade: item.especialidade,
      valor: item.valor,
      particular: item.particular
    });
  }

  addProfissionalStartEditar(item){
    this.profissionaisCredenciadoListEditar.push(this.createProfissionalStartEditar(item));
  }
  
  createExameStart(item):FormGroup{
    return this.formBuilder.group({
      nomeExame: [item.nomeExame, Validators.compose([Validators.required])],
      valor: [item.valor],
      percentualValor: [item.percentualValor],
      particular: [item.particular],
      percentualParticular: [item.percentualParticular]
    });
  }

  addExameStart(item){
    this.examesCredenciadoList.push(this.createExameStart(item));
  }
  
  createExame():FormGroup{
    return this.formBuilder.group({
      nomeExame: [null, Validators.compose([Validators.required])],
      valor: [null],
      percentualValor: [null],
      particular: [null],
      percentualParticular: [null]
    });
  }

  addExame(){
    this.examesCredenciadoList.push(this.createExame());
  }

  removeExame(index) {
    this.examesCredenciadoList.removeAt(index);
  }

  createExameEditar():FormGroup{
    return this.formBuilder.group({
      nomeExame: [null, Validators.compose([Validators.required])],
      valor: [null],
      percentualValor: [null],
      particular: [null],
      percentualParticular: [null]
    });
  }

  addExameEditar(){
    this.examesCredenciadoListEditar.push(this.createExameEditar());
  }

  removeExameEditar(index) {
    this.examesCredenciadoListEditar.removeAt(index);
  }

  createExameStartEditar(item):FormGroup{
    return this.formBuilder.group({
      nomeExame: item.nomeExame,
      valor:  item.valor,
      percentualValor: item.percentualValor,
      particular:  item.particular,
      percentualParticular: item.percentualParticular
    });
  }

  addExameStartEditar(item){
    this.examesCredenciadoListEditar.push(this.createExameStartEditar(item));
  }

  openModalEspecialidade(){
    let modal = document.getElementById('modalEspecialidadesMedica');
    modal.style.display = "block";
  }

  closeModalEspecialidade(){
    let modal = document.getElementById('modalEspecialidadesMedica');
    modal.style.display = "none";
    this.ngOnInit();
  }

  salvarEspecialidade(){
    let dados = {
      especialidades: this.especialidadeMedicaList.value
    }
    this.db.patchConfiguracoes(this.idEspecialidades,dados).subscribe(res => {
      console.log(res);
      this.app.openAlert("Especialidades Médicas salvas com sucesso!");
      this.closeModalEspecialidade();
      this.ngOnInit();
    }, err => {
      console.log(err)
    })
  }

  openModalNovo(){
    let modal = document.getElementById('novoCredenciado');
    modal.style.display = "block";
    modal.scroll({ 
      top: 0, 
      left: 0, 
      behavior: 'smooth' 
    });
  }

  closeModalNovo(){
    let modal = document.getElementById('novoCredenciado');
    modal.style.display = "none";
    this.especialidadeCredenciado = "";
    this.cep = "";
    this.rua = "";
    this.numero = "";
    this.bairro = "";
    this.municipio = "";
    this.estado = "";
    this.complemento = "";
    this.telefone = "";
    this.telefone2 = "";
    this.razaoCredenciado = "";
    this.fantasiaCredenciado = "";
    this.cnpjCredenciado = "";
    this.cpfCredenciado = "";
    this.pontoReferencia = "";
    this.nomeContato = "";
    this.email = "";
    this.dataContrato = "";
    this.tabelaUnicaCheck = false;
    this.valorEspecialidade = null;
    this.percentualEspecialidade = null;
    this.laboratorioCheck = false;
    this.tipoLaboratorio = "";
    this.valorLaboratorio = null;
    this.precoVariavelCheck = false;
    this.variavelDe = null;
    this.variavelAte = null;
    this.observacoesInterna = "";
    this.observacoesCliente = "";
    this.profissionaisCredenciadoList.clear();
    this.examesCredenciadoList.clear();
    this.addProfissional();
    this.addExame();
    this.situacao = "ATIVO";
    this.sindilojas = false;
    this.amic = false;
    this.guiaMedica = false;
    this.telefoneExibir = false;
    this.telefone2Exibir = false;
    this.selectedFiles = [];
    this.files = [];
  }

  buscarCep(cepGet){
    let c = cepGet.replace(/_/gi,'').replace('.','').replace('-','');
    if(c.length > 7){
      this.db.getCep(c).subscribe((data:any) => {
        let cep = data;
         if(cep.erro == true){
           this.app.openAlert("CEP NAO ENCONTRADO");
         }else{
           this.rua = cep.logradouro.toUpperCase();
           this.bairro = cep.bairro.toUpperCase();
           this.municipio = cep.localidade.toUpperCase();
           this.estado = cep.uf.toUpperCase();
         }
      });
    }
  }

  buscarCepEditar(cepGet){
    let c = cepGet.replace(/_/gi,'').replace('.','').replace('-','');
    if(c.length > 7){
      this.db.getCep(c).subscribe((data:any) => {
        let cep = data;
         if(cep.erro == true){
           this.app.openAlert("CEP NAO ENCONTRADO");
         }else{
           this.ruaEditar = cep.logradouro.toUpperCase();
           this.bairroEditar = cep.bairro.toUpperCase();
           this.municipioEditar = cep.localidade.toUpperCase();
           this.estadoEditar = cep.uf.toUpperCase();
         }
      });
    }
  }

  cadastrarCredenciado(){
    let dataHoje = new Date(Date.now() - 10800000).toISOString().split('T')[0];
    let jsonDate = new Date().toJSON();
    let hora = new Date(jsonDate).toLocaleTimeString();
    let dados = {
      especialidade : this.especialidadeCredenciado,
      cep : this.cep,
      rua : this.rua,
      numero : this.numero,
      bairro : this.bairro,
      municipio : this.municipio,
      estado : this.estado,
      complemento : this.complemento,
      telefone : this.telefone,
      telefone2 : this.telefone2,
      razaoCredenciado : this.razaoCredenciado,
      fantasiaCredenciado : this.fantasiaCredenciado,
      cnpjCredenciado : this.cnpjCredenciado,
      cpfCredenciado : this.cpfCredenciado,
      pontoReferencia : this.pontoReferencia,
      nomeContato : this.nomeContato,
      email : this.email,
      dataContrato : this.dataContrato,
      tabelaUnicaCheck: this.tabelaUnicaCheck,
      valorEspecialidade: this.valorEspecialidade,
      percentualEspecialidade : this.percentualEspecialidade,
      laboratorioCheck : this.laboratorioCheck,
      tipoLaboratorio : this.tipoLaboratorio,
      valorLaboratorio : this.valorLaboratorio,
      precoVariavelCheck: this.precoVariavelCheck,
      variavelDe: this.variavelDe,
      variavelAte: this.variavelAte,
      profissionais: this.profissionaisCredenciadoList.value,
      exames: this.examesCredenciadoList.value,
      observacoesInterna:this.replaceStrings(this.observacoesInterna),
      observacoesCliente:this.replaceStrings(this.observacoesCliente),
      situacao: this.situacao,
      sindilojas: this.sindilojas,
      amic: this.amic,
      guiaMedica: this.guiaMedica,
      telefoneExibir: this.telefoneExibir,
      telefone2Exibir: this.telefone2Exibir,
    }
    this.db.postCredenciado(dados).subscribe(res => {
      console.log(res);
      if(this.selectedFiles.length > 0){
        const imagedata = new FormData();
        for(let i=0; i<this.selectedFiles.length; i++){imagedata.append('files[]', this.selectedFiles[i], this.selectedFiles[i].name)};
         this.db.enviarDocCredenciado(res['insertedId'], imagedata).subscribe((result) => {
             console.log(result);
           },(error) => {
             console.log(error);
           });
           let randomGeraLink = Math.random().toString(36).slice(-8);
           let credTask = {
             "id": randomGeraLink,
             "data" : dataHoje,
             "hora" : hora,
             "titulo" : "NOVO CREDENCIADO",
             "descricao" : "Credenciado Cadastrado: "+ this.fantasiaCredenciado + " - "+this.fantasiaCredenciado,
             "idResponsavel" : "656e0e3ec490938df31f3b5e",
             "responsavel" : "TAILA TAISE ESPINDOLA",
             "resolvido":false,
             "dataResolucao" : "",
             "horaResolucao" : "",
             "prioridade" : "ALTA",
             "origem":"Sistema",
             "idUserSolicitacao":localStorage.getItem('idUserVivamax'),
             "nomeSolicitante":localStorage.getItem('usuarioVivamax')
           }
           this.db.postTarefa(credTask).subscribe(res => {
            console.log(res)
            this.app.openAlert("Credenciado Cadastrado com Sucesso!");
            this.ngOnInit();
            this.closeModalNovo();
           },err => console.log(err));
      }else{
        this.app.openAlert("Credenciado Cadastrado com Sucesso!");
        this.ngOnInit();
        this.closeModalNovo();
      }
    }, err => {
      console.log(err);
      this.app.openAlert("Erro ao cadastrar Credenciado, por favor tente novamente!")
    })
  }

  editarCredenciado(){
    let dados = {
      especialidade : this.especialidadeCredenciadoEditar,
      cep : this.cepEditar,
      rua : this.ruaEditar,
      numero : this.numeroEditar,
      bairro : this.bairroEditar,
      municipio : this.municipioEditar,
      estado : this.estadoEditar,
      complemento : this.complementoEditar,
      telefone : this.telefoneEditar,
      telefone2 : this.telefone2Editar,
      razaoCredenciado : this.razaoCredenciadoEditar,
      fantasiaCredenciado : this.fantasiaCredenciadoEditar,
      cnpjCredenciado : this.cnpjCredenciadoEditar,
      cpfCredenciado : this.cpfCredenciadoEditar,
      pontoReferencia : this.pontoReferenciaEditar,
      nomeContato : this.nomeContatoEditar,
      email : this.emailEditar,
      dataContrato : this.dataContratoEditar,
      tabelaUnicaCheck: this.tabelaUnicaCheckEditar,
      valorEspecialidade: this.valorEspecialidadeEditar,
      percentualEspecialidade : this.percentualEspecialidadeEditar,
      laboratorioCheck: this.laboratorioCheckEditar,
      tipoLaboratorio: this.tipoLaboratorioEditar,
      valorLaboratorio: this.valorLaboratorioEditar,
      precoVariavelCheck: this.precoVariavelCheckEditar,
      variavelDe: this.variavelDeEditar,
      variavelAte: this.variavelAteEditar,
      profissionais: this.profissionaisCredenciadoListEditar.value,
      exames: this.examesCredenciadoListEditar.value,
      observacoesInterna:this.replaceStrings(this.observacoesInternaEditar),
      observacoesCliente:this.replaceStrings(this.observacoesClienteEditar),
      situacao: this.situacaoEditar,
      sindilojas: this.sindilojasEditar,
      amic: this.amicEditar,
      guiaMedica: this.guiaMedicaEditar,
      telefoneExibir: this.telefoneExibirEditar,
      telefone2Exibir: this.telefone2ExibirEditar
    }
    this.db.patchCredenciado(this.idCredenciado,dados).subscribe(res => {
      console.log(res);
      this.app.openAlert("Credenciado Editado com Sucesso!");
      this.ngOnInit();
      this.closeModalEditar();
    }, err => {
      console.log(err);
      this.app.openAlert("Erro ao Editar Credenciado, por favor tente novamente!")
    })
  }

  openModalExcluirCredenciado(credenciado){
    let modal = document.getElementById('modalExcluir');
    modal.style.display = "block";
    this.credenciado = credenciado;
  }

  closeModalExcluirCredenciado(){
    let modal = document.getElementById('modalExcluir');
    modal.style.display = "none";
    this.motivoExclusao = "";
    this.credenciado = "";
  }

  excluirCredenciado(){
    if(window.confirm("Deseja realmente excluir esse Credenciado?")){
      let jsonDate = new Date().toJSON();
      let hora = new Date(jsonDate).toLocaleTimeString();
      this.db.deleteCredenciado(this.credenciado._id).subscribe(res => {
        console.log(res);
        let dataHoje = new Date(Date.now() - 10800000).toISOString().split('T')[0];
        let bodyLogs = {
          "menu" : "CREDENCIADOS", 
          "descricao" : this.credenciado.fantasiaCredenciado+" excluido pelo motivo: "+this.motivoExclusao, 
          "data" : dataHoje, 
          "hora" : hora, 
          "usuario" :localStorage.getItem('usuarioVivamax')
        }
        this.db.postLogs(bodyLogs).subscribe(res => {
          console.log(res);
          this.db.sendEmail("geraldo@vivamax.com.br","Credenciado Excluido",this.credenciado.fantasiaCredenciado+" excluido pelo motivo: "+this.motivoExclusao+" - Usuario: "+localStorage.getItem('usuarioVivamax')).subscribe(ress => {
              console.log(ress);
              this.app.openAlert("Credenciado Excluido com Sucesso!");
              this.closeModalExcluirCredenciado();
              this.ngOnInit();
            }, errr => {
              console.log(errr)
            })
          },err => {
            console.log(err)
          })
      },err => {
      console.log(err)
    })
    }
  }
  
  openModalEditar(credenciado){
    let modal = document.getElementById('editarCredenciado');
    modal.style.display = "block";
    modal.scroll({ 
      top: 0, 
      left: 0, 
      behavior: 'smooth' 
    });
    this.idCredenciado = credenciado._id;
    this.especialidadeCredenciadoEditar = credenciado.especialidade;
    this.cepEditar = credenciado.cep;
    this.ruaEditar = credenciado.rua;
    this.numeroEditar = credenciado.numero;
    this.bairroEditar = credenciado.bairro;
    this.municipioEditar = credenciado.municipio;
    this.estadoEditar = credenciado.estado;
    this.complementoEditar = credenciado.complemento;
    this.telefoneEditar = credenciado.telefone;
    this.telefone2Editar = credenciado.telefone2;
    this.razaoCredenciadoEditar = credenciado.razaoCredenciado;
    this.fantasiaCredenciadoEditar = credenciado.fantasiaCredenciado;
    this.cnpjCredenciadoEditar = credenciado.cnpjCredenciado;
    this.cpfCredenciadoEditar = credenciado.cpfCredenciado;
    this.pontoReferenciaEditar = credenciado.pontoReferencia;
    this.nomeContatoEditar = credenciado.nomeContato;
    this.emailEditar = credenciado.email;
    this.dataContratoEditar = credenciado.dataContrato;
    this.tabelaUnicaCheckEditar = credenciado.tabelaUnicaCheck;
    this.valorEspecialidadeEditar = credenciado.valorEspecialidade;
    this.percentualEspecialidadeEditar = credenciado.percentualEspecialidade;
    this.laboratorioCheckEditar = credenciado.laboratorioCheck;
    this.tipoLaboratorioEditar = credenciado.tipoLaboratorio;
    this.valorLaboratorioEditar = credenciado.valorLaboratorio;
    this.precoVariavelCheckEditar = credenciado.precoVariavelCheck;
    this.variavelDeEditar = credenciado.variavelDe;
    this.variavelAteEditar = credenciado.variavelAte;
    this.todosProfissionais = credenciado.profissionais;
    this.todosExames = credenciado.exames;
    this.observacoesClienteEditar = this.reverseReplaceStrings(credenciado.observacoesCliente);
    this.observacoesInternaEditar = this.reverseReplaceStrings(credenciado.observacoesInterna);
    this.situacaoEditar = credenciado.situacao;
    this.sindilojasEditar = credenciado.sindilojas;
    this.amicEditar = credenciado.amic;
    this.guiaMedicaEditar = credenciado.guiaMedica;
    this.telefoneExibirEditar = credenciado.telefoneExibir;
    this.telefone2ExibirEditar = credenciado.telefone2Exibir;
    if(this.todosProfissionais != undefined && this.todosProfissionais.length > 0){
      this.profissionaisCredenciadoListEditar.clear();
      this.todosProfissionais.forEach((item) => {
        this.addProfissionalStartEditar(item);
      })
    }else{
      this.addProfissionalEditar();
    }
    if(this.todosExames != undefined && this.todosExames.length > 0){
      this.examesCredenciadoListEditar.clear();
      this.todosExames.forEach((item) => {
        this.addExameStartEditar(item);
      })
    }else{
      this.addExameEditar();
    }
    this.docs = credenciado.anexos;
    this.pdfs = [];
    this.images = [];
    this.selectedFiles = "";
    this.filesEditar = [];
    if(this.docs && this.docs.length > 0){
      this.pdfs = [];
      this.images = [];
      this.docs.forEach((item) => {
      let ext = item.url.split('.').pop();
        if(ext == 'pdf' || ext == 'PDF'){
          this.pdfs.push(item);
        }else{
          this.images.push(item);
        }
      })
    }
  }

  closeModalEditar(){
    let modal = document.getElementById('editarCredenciado');
    modal.style.display = "none";
    this.idCredenciado = "";
    this.especialidadeCredenciadoEditar = "";
    this.cepEditar = "";
    this.ruaEditar = "";
    this.numeroEditar = "";
    this.bairroEditar = "";
    this.municipioEditar = "";
    this.estadoEditar = "";
    this.complementoEditar = "";
    this.telefoneEditar = "";
    this.telefone2Editar = "";
    this.razaoCredenciadoEditar = "";
    this.fantasiaCredenciadoEditar = "";
    this.cnpjCredenciadoEditar = "";
    this.cpfCredenciadoEditar = "";
    this.pontoReferenciaEditar = "";
    this.nomeContatoEditar = "";
    this.emailEditar = "";
    this.dataContratoEditar = "";
    this.tabelaUnicaCheckEditar = false;
    this.valorEspecialidadeEditar = null;
    this.percentualEspecialidadeEditar = null;
    this.laboratorioCheckEditar = false;
    this.tipoLaboratorioEditar = "";
    this.valorLaboratorioEditar = null;
    this.precoVariavelCheckEditar = false;
    this.variavelDeEditar = null;
    this.variavelAteEditar = null;
    this.todosProfissionais = [];
    this.todosExames = [];
    this.observacoesClienteEditar = "";
    this.observacoesInternaEditar = "";
    this.telefoneExibirEditar = false;
    this.telefone2ExibirEditar = false;
    this.profissionaisCredenciadoListEditar.clear();
    this.examesCredenciadoListEditar.clear();
  }

  buscarCredenciado(credenciado:any,especialidade:any,cidade:any,exame:any){
    this.credenciados = [];
    let lab:boolean = false;
    if(especialidade == "LABORATORIO"){
      lab = true;
    }else{
      lab = false;
    }
    this.db.getCredenciadosFilter(lab,credenciado,especialidade,exame,cidade).subscribe((data:any) => {
      this.credenciados = data;
      this.filterCredenciados();
    })
  }


  getTodosCredenciados(){
    this.db.getCredenciados().subscribe((data:any) => {
      this.credenciados = data;
      this.nomeBusca = "";
      this.especialidadeBusca = "";
      this.cidadeBusca = "";
      this.exameBusca = "";
      this.filterCredenciados();
    })
  }
  
  onChangeClick(event){
    let arrayTypes = ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'];
    this.selectedFiles = <FileList>event.srcElement.files;
    for(let i=0; i<this.selectedFiles.length; i++){
        if(!arrayTypes.includes(this.selectedFiles[i].type)){
        this.app.openAlert("Tipo de Arquivo Não Suportado!\nEscolha uma imagem com a extensão .JPG, .JPEG, .PNG ou PDF")
        this.selectedFiles = "";
      }} 
      this.files = [];
      for(let i=0; i<this.selectedFiles.length; i++){
        this.files.push(this.selectedFiles[i].name);
      }
  }
  
  onChange(event){
    let arrayTypes = ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'];
    this.selectedFiles = <FileList>event;
    for(let i=0; i<this.selectedFiles.length; i++){
        if(!arrayTypes.includes(this.selectedFiles[i].type)){
        this.app.openAlert("Tipo de Arquivo Não Suportado!\nEscolha uma imagem com a extensão .JPG, .JPEG, .PNG ou PDF")
        this.selectedFiles = "";
      }} 
      this.files = [];
      for(let i=0; i<this.selectedFiles.length; i++){
        this.files.push(this.selectedFiles[i].name);
      }
  }
  
  onChangeClickEditar(event){
    let arrayTypes = ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'];
    this.selectedFilesEditar = <FileList>event.srcElement.files;
    for(let i=0; i<this.selectedFilesEditar.length; i++){
        if(!arrayTypes.includes(this.selectedFilesEditar[i].type)){
        this.app.openAlert("Tipo de Arquivo Não Suportado!\nEscolha uma imagem com a extensão .JPG, .JPEG, .PNG ou PDF")
        this.selectedFilesEditar = "";
      }} 
      this.filesEditar = [];
      for(let i=0; i<this.selectedFilesEditar.length; i++){
        this.filesEditar.push(this.selectedFilesEditar[i].name);
      }
  }
  
  onChangeEditar(event){
    let arrayTypes = ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'];
    this.selectedFilesEditar = <FileList>event;
    for(let i=0; i<this.selectedFilesEditar.length; i++){
        if(!arrayTypes.includes(this.selectedFilesEditar[i].type)){
        this.app.openAlert("Tipo de Arquivo Não Suportado!\nEscolha uma imagem com a extensão .JPG, .JPEG, .PNG ou PDF")
        this.selectedFilesEditar = "";
      }} 
      this.filesEditar = [];
      for(let i=0; i<this.selectedFilesEditar.length; i++){
        this.filesEditar.push(this.selectedFilesEditar[i].name);
      }
  }

  getDocs(){
    this.docs = [];
    this.db.getAnexosCredenciadosById(this.idCredenciado).subscribe((result:any) => {
      this.docs = result.anexos;
      this.pdfs = [];
      this.images = [];
      this.selectedFiles = "";
      this.files = [];
      if(this.docs && this.docs.length > 0){
        this.pdfs = [];
        this.images = [];
        this.docs.forEach((item) => {
        let ext = item.url.split('.').pop();
          if(ext == 'pdf' || ext == 'PDF'){
            this.pdfs.push(item);
          }else{
            this.images.push(item);
          }
        })
      }
    })
  }

  getDocsEditar(){
    this.docs = [];
    this.db.getAnexosCredenciadosById(this.idCredenciado).subscribe((result:any) => {
      this.docs = result.anexos;
      this.pdfs = [];
      this.images = [];
      this.selectedFiles = "";
      this.filesEditar = [];
      if(this.docs && this.docs.length > 0){
        this.pdfs = [];
        this.images = [];
        this.docs.forEach((item) => {
        let ext = item.url.split('.').pop();
          if(ext == 'pdf' || ext == 'PDF'){
            this.pdfs.push(item);
          }else{
            this.images.push(item);
          }
        })
      }
    })
  }
  
    delDoc(url){
      if(window.confirm('Deseja Realmente Deletar esse Documento?')){
       this.db.removeDoc(url).subscribe(res => {
         console.log(res);
         this.db.removeDocCredenciado(this.idCredenciado,url).subscribe(result => {
           console.log(result);
           this.getDocs();
         }, error => {
           console.log(error);
         })
        },err => {
          console.log(err);
           this.db.removeDocCredenciado(this.idCredenciado,url).subscribe(result => {
           console.log(result);
           this.getDocs();
         }, error => {
           console.log(error);
         })
       })
     }
   }
   
   openModalPdfViewer(url){
    this.pdfViewerOnDemand.pdfSrc = url;
    this.pdfViewerOnDemand.refresh();
  }
  
  openModalLightBox(url, i){
    this.indexImage = i;
    this.imagemSelecionada = url;
    let modal = document.getElementById('myModal')
    modal.style.display = "block";
  }
  
  nextImg(){
    let i = this.indexImage
    if((this.images.length - 1) == i){
      this.imagemSelecionada = this.images[0].url;
      this.indexImage = 0;
    }else{
      i++
      this.imagemSelecionada = this.images[i].url;
      this.indexImage++
    }
  }
  
  prevImg(){
    let i = this.indexImage
    if(i == 0){
      this.imagemSelecionada = this.images[this.images.length - 1].url;
      this.indexImage = this.images.length - 1;
    }else{
      i--
      this.imagemSelecionada = this.images[i].url;
      this.indexImage--
    }
  }
  
  closeModal(){
    let modal = document.getElementById('myModal')
    modal.style.display = "none";
  }

  anexarDoc(){
    if(this.selectedFilesEditar.length > 0){
      const imagedata = new FormData();
      for(let i=0; i<this.selectedFilesEditar.length; i++){imagedata.append('files[]', this.selectedFilesEditar[i], this.selectedFilesEditar[i].name)};
       this.db.enviarDocCredenciado(this.idCredenciado, imagedata).subscribe((result) => {
           console.log(result);
           this.getDocsEditar()
         },(error) => {
           console.log(error);
           this.getDocsEditar()
         });
    }
  }

  openModalCidades(){
    let modal = document.getElementById('modalCidades');
    modal.style.display = "block";
  }

  closeModalCidades(){
    let modal = document.getElementById('modalCidades');
    modal.style.display = "none";
    this.ngOnInit()
  }

  salvarCidades(){
    let dados = {
      cidades: this.cidadesList.value
    }
    this.db.patchConfiguracoes(this.idCidades,dados).subscribe(res => {
      console.log(res);
      this.app.openAlert("Cidades salvas com sucesso!");
      this.closeModalCidades();
      this.ngOnInit();
    }, err => {
      console.log(err)
    })
  }

  getUf(municipio){
    if(municipio == ""){
      this.estado = "";
    }else{
      let i = this.todasCidades.map((item) => {
        return item.cidade
      }).indexOf(municipio)
      if(i >= 0){
        this.estado = this.todasCidades[i].estado;
      }
    }
  }

  getUfEditar(municipio){
    if(municipio == ""){
      this.estadoEditar = "";
    }else{
      let i = this.todasCidades.map((item) => {
        return item.cidade
      }).indexOf(municipio)
      if(i >= 0){
        this.estadoEditar = this.todasCidades[i].estado;
      }
    }
  }

  replaceStrings(string:any){
    if(!string){return}
    return string.replace(/\%/gi,'%25').replace(/\+/gi,'%2B').replace(/\!/gi,'%21').replace(/\&/gi,'%26').replace(/\;/gi,'%3B');
  }
  
  reverseReplaceStrings(string:any){
    if(!string){return}
    return string.replace(/%2B/gi,'+').replace(/%21/gi,'!').replace(/%25/gi,'%').replace(/%26/gi,'&').replace(/%3B/gi,';');
  }

  filterCredenciados(){
    if(this.filtroSituacao == ""){
      this.credenciados = this.credenciados.sort((a:any,b:any) => {if(a.fantasiaCredenciado < b.fantasiaCredenciado){return -1}return 1});
    }else{
      this.credenciados = this.credenciados.filter((x:any) => { return x.situacao == this.filtroSituacao}).sort((a:any,b:any) => {if(a.fantasiaCredenciado < b.fantasiaCredenciado){return -1}return 1});
    }
  }

}
