import { Component, OnInit } from '@angular/core';
import { AppComponent } from '../app.component';
import { DataBaseService } from '../provider.service';
import { MASKS } from 'ng-brazil';

@Component({
  selector: 'app-fornecedores',
  templateUrl: './fornecedores.component.html',
  styleUrls: ['./fornecedores.component.css']
})
export class FornecedoresComponent implements OnInit {
  public MASKS = MASKS;
  dataHoje:any = "";
  fornecedores:any = [];
  fornecedorEditar:any = "";
  fornecedorSearch:any = "";
  agenciaEditar:any = "";
  contaCorrenteEditar:any = "";
  razaoSocial:any = "";
  nomeFantasia:any = "";
  cnpj:any = "";
  endereco:any = "";
  numero:any = "";
  bairro:any = "";
  cidade:any = "";
  estado:any = "";
  telefone:any = "";
  celular:any = "";
  responsavel:any = "";
  email:any = "";
  banco:any = "";
  agencia:any = "";
  agenciaDigito:any = "";
  contaCorrente:any = "";
  contaCorrenteDigito:any = "";
  tipoPix:any = "";
  pix:any = "";
  todosFornecedores:any = "";
  vigencia:any = "";

  constructor(public db:DataBaseService, public app:AppComponent){}

  ngOnInit(){
    this.dataHoje = new Date(Date.now() - 10800000).toISOString().split('T')[0];
    this.getFornecedor();
  }

  getFornecedor(){
    this.db.getAllFornecedores().subscribe((data:any) => {
      this.fornecedores = data;
      this.todosFornecedores = data;
    })
  }

  buscarFornecedor(fornecedor){
    this.todosFornecedores = this.fornecedores.filter(item => {
      return item.razaoSocial.toLowerCase().indexOf(fornecedor.toLowerCase()) > -1;
    });
  }
  
  openModalEditarFornecedor(fornecedor){
    let modal = document.getElementById('modalEditarFornecedores');
    modal.style.display = "block"
    this.fornecedorEditar = fornecedor;
    this.agenciaEditar = fornecedor?.agencia;
    this.contaCorrenteEditar = fornecedor?.contaCorrente;
  }

  closeModalEditarFornecedor(){
    this.fornecedorEditar = "";
    this.agenciaEditar = null;
    this.contaCorrenteEditar = null;
    let modal = document.getElementById('modalEditarFornecedores');
    modal.style.display = "none"
  }
  
  salvarFornecedor(){
    let dados = {
      razaoSocial: this.razaoSocial,
      nomeFantasia: this.nomeFantasia,
      cnpj: this.cnpj,
      endereco: this.endereco,
      numero: this.numero,
      bairro: this.bairro,
      cidade: this.cidade,
      estado: this.estado,
      telefone: this.telefone,
      celular: this.celular,
      responsavel: this.responsavel,
      email: this.email,
      banco: this.banco,
      agencia: this.agencia,
      agenciaDigito: this.agenciaDigito,
      contaCorrente: this.contaCorrente,
      contaCorrenteDigito: this.contaCorrenteDigito,
      tipoPix:this.tipoPix,
      pix: this.pix,
      vigencia: this.vigencia
    };
    this.db.cadastroFornecedor(dados).subscribe(res => {
      console.log(res);
      this.app.openAlert("Fornecedor cadastrado com sucesso!");
      this.closeModalNovoFornecedor();
      this.getFornecedor();
    }, err => {
      console.log(err);
      this.app.openAlert("Erro ao cadastrar Fornecedor!");
    })
  }

  editarFornecedor(){
    let dados = {
      razaoSocial: this.fornecedorEditar.razaoSocial,
      nomeFantasia: this.fornecedorEditar.nomeFantasia,
      cnpj: this.fornecedorEditar.cnpj,
      endereco: this.fornecedorEditar.endereco,
      numero: this.fornecedorEditar.numero,
      bairro: this.fornecedorEditar.bairro,
      cidade: this.fornecedorEditar.cidade,
      estado: this.fornecedorEditar.estado,
      telefone: this.fornecedorEditar.telefone,
      celular: this.fornecedorEditar.celular,
      responsavel: this.fornecedorEditar.responsavel,
      email: this.fornecedorEditar.email,
      banco: this.fornecedorEditar.banco,
      agencia: this.agenciaEditar,
      contaCorrente: this.contaCorrenteEditar,
      pix: this.fornecedorEditar.pix,
      agenciaDigito: this.fornecedorEditar.agenciaDigito,
      contaCorrenteDigito: this.fornecedorEditar.contaCorrenteDigito,
      tipoPix:this.fornecedorEditar.tipoPix,
      vigencia:this.fornecedorEditar.vigencia
    };
    this.db.editarFornecedor(this.fornecedorEditar._id,dados).subscribe(res => {
      console.log(res);
      this.app.openAlert("Fornecedor editado com sucesso!");
      this.closeModalEditarFornecedor();
      this.getFornecedor();
    }, err => {
      console.log(err);
      this.app.openAlert("Erro ao cadastrar Fornecedor!");
    })
  }

  openModalNovoFornecedor(){
    let modal = document.getElementById('modalNovoFornecedores');
    modal.style.display = "block"
  }

  closeModalNovoFornecedor(){
    this.razaoSocial = "";
    this.nomeFantasia = "";
    this.cnpj = "";
    this.endereco = "";
    this.numero = "";
    this.bairro = "";
    this.cidade = "";
    this.estado = "";
    this.telefone = "";
    this.celular = "";
    this.responsavel = "";
    this.email = "";
    this.banco = "";
    this.agencia = null;
    this.agenciaDigito = "";
    this.contaCorrente = null;
    this.contaCorrenteDigito = "";
    this.tipoPix = "";
    this.pix = "";
    this.vigencia = "";
    let modal = document.getElementById('modalNovoFornecedores');
    modal.style.display = "none";
  }

  excluirFornecedor(fornecedor){
    if(window.confirm("Deseja realmente excluir esse Forncedor?")){
      let jsonDate = new Date().toJSON();
      let hora = new Date(jsonDate).toLocaleTimeString();
      this.db.deleteFornecedor(fornecedor._id).subscribe(res => {
        console.log(res);
        this.app.openAlert("Fornecedor Excluido com Sucesso!");
        this.getFornecedor();
      },err => {
      console.log(err)
    })
    }
  }

}
