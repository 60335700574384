<div class="modal" id="modalMassa">
    <div class="box">
        <div style="height:2em"></div>
        <div class="row">
            <div class="col col-4">
                <label>Lista de Envio</label>
            </div>
            <div class="col col-8">
                <select class="form-control" name="demonstrativo">
                    <option value="LISTA DE ENVIO" selected>LISTA DE ENVIO</option>
                    <option *ngFor="let usr of arrayEnvio" [value]="usr.nome" disabled>{{ usr.nome }}</option>
                </select>    
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col col-4">
                <label>Titulo</label><br>
                <small>Válido somente para Notificação Push</small>
            </div>
            <div class="col col-8">
                <input class="form-control" type="text" [(ngModel)]="tituloMensagem" maxlength="30">
                <small>Restam {{30 - tituloMensagem.length}} caracteres</small>
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col col-4">
                <label>Mensagem</label>
            </div>
            <div class="col col-8">
                <textarea [(ngModel)]="mensagem" class="form-control" maxlength="160" rows="6"></textarea>
                <small>Restam {{160 - mensagem.length}} caracteres</small>
            </div>
        </div>
        <small><a href="https://emojikeyboard.top/pt/" target="_blank">Lista de Emojis</a></small>
        <br><br>
        <div class="row">
            <div class="col col-4" style="text-align:center">
                <button class="btn btn-warning" (click)="enviarSMS()" [disabled]="!mensagem || arrayUsuarios?.length < 1">
                    <fa-icon [icon]="['fas', 'sms']"></fa-icon>&nbsp;SMS EM MASSA
                </button>
            </div>
            <div class="col col-4" style="text-align:center">
                <button class="btn btn-success" (click)="enviarPush()" [disabled]="!mensagem || !tituloMensagem || arrayUsuarios?.length < 1">
                    <fa-icon [icon]="['fas', 'comment-dots']"></fa-icon>&nbsp;PUSH USUÁRIOS
                </button>
            </div>
            <div class="col col-4" style="text-align:center">
                <button class="btn btn-primary" (click)="enviarPushMassa()" [disabled]="!mensagem || !tituloMensagem">
                    <fa-icon [icon]="['fas', 'comment-dots']"></fa-icon>&nbsp;PUSH EM MASSA
                </button>
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col col-3">
                &nbsp;
            </div>
            <div class="col col-6" style="text-align:center">
                <button class="btn btn-block btn-danger" (click)="closeEnviarMassa()">
                    <fa-icon [icon]="['fas', 'window-close']"></fa-icon>&nbsp;CANCELAR
                </button>
            </div>
            <div class="col col-3">
                &nbsp;
            </div>
        </div>
    </div>
</div>
<!-- FIM MODAL EM MASSA -->
<div class="row align-items-center header">
    <div class="col titulo" style="text-align: left;">
        MARKETING
    </div>
    <div class="col" style="text-align: right;">
        <button class="btn btn-outline-light" (click)="enviarMassa()">
            <fa-icon [icon]="['fas', 'sms']"></fa-icon>&nbsp;ENVIAR SMS / NOTIFICAÇÃO
        </button>
    </div>
</div>
<div class="corpo">
    <div class="row align-items-center">
        <div class="col col-3">
            <label>Data Inicio Adesão</label>
            <input type="date" class="form-control" [(ngModel)]="dtInicio">
        </div>
        <div class="col col-3">
            <label>Data Fim Adesão</label>
            <input type="date" class="form-control" [(ngModel)]="dtFim">
        </div>
        <div class="col col-1">
            <br>
            <button class="btn btn-primary" (click)="buscarByData(dtInicio,dtFim)" [disabled]="!dtInicio || !dtFim">
                <fa-icon [icon]="['fas', 'search']"></fa-icon>
            </button>
        </div>
        <div class="col col-4">
            <label>Empresa</label>
            <select class="form-control" [(ngModel)]="origemEmpresa">
                <option [value]=""></option>
                <option value="VIVA MAX">VIVA MAX</option>
                <option value="CONECTA">CONECTA</option>
            </select> 
        </div>
        <div class="col col-1">
            <br>
            <button class="btn btn-primary" (click)="buscarByOrigemEmpresa(origemEmpresa)" [disabled]="!origemEmpresa">
                <fa-icon [icon]="['fas', 'search']"></fa-icon>
            </button>
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col col-5">
            <label>Tipo do Plano</label>
            <select class="form-control" [(ngModel)]="tipoPlano">
                <option value="TODOS">TODOS</option>
                <option value="PLANO FISICO">PESSOA FÍSICA</option>
                <option value="PLANO SERVIDOR">SERVIDOR PÚBLICO</option>
                <option value="PLANO EMPRESARIAL">EMPRESARIAL</option>
            </select>
        </div>
        <div class="col col-1">
            <br>
            <button class="btn btn-primary" (click)="buscarByTipoPlano(tipoPlano)" [disabled]="!tipoPlano">
                <fa-icon [icon]="['fas', 'search']"></fa-icon>
            </button>
        </div>
        <div class="col col-5">
            <label>Plano</label>
            <ng-select class="form-control" [items]="planos" bindLabel="nome" appendTo="body" [(ngModel)]="plano"></ng-select>
        </div>
        <div class="col col-1">
            <br>
            <button class="btn btn-primary" (click)="buscarByPlanos(plano.nome)" [disabled]="!plano">
                <fa-icon [icon]="['fas', 'search']"></fa-icon>
            </button>
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col col-5">
            <label>Sub Contrato</label>
            <ng-select class="form-control" [items]="subContratos" bindLabel="nome" appendTo="body" [(ngModel)]="subContrato"></ng-select>
        </div>
        <div class="col col-1">
            <br>
            <button class="btn btn-primary" (click)="buscarBySubContrato(subContrato.nome)" [disabled]="!subContrato">
                <fa-icon [icon]="['fas', 'search']"></fa-icon>
            </button>
        </div>
        <div class="col col-5">
            <label>Empresa</label>
            <ng-select class="form-control" [items]="empresas" bindLabel="fantasiaEmpresa" appendTo="body" [(ngModel)]="empresa"></ng-select>
        </div>
        <div class="col col-1">
            <br>
            <button class="btn btn-primary" (click)="buscarByEmpresa(empresa.codigo)" [disabled]="!empresa">
                <fa-icon [icon]="['fas', 'search']"></fa-icon>
            </button>
        </div>
    </div>
    <br>
    <div class="row align-items-center">
        <div class="col col-5">
            <label>Cidade</label>
            <input type="text" class="form-control" [(ngModel)]="cidade">
        </div>
        <div class="col col-1">
            <br>
            <button class="btn btn-primary" (click)="buscarByCidade(cidade)" [disabled]="!cidade">
                <fa-icon [icon]="['fas', 'search']"></fa-icon>
            </button>
        </div>
       
    </div>
    <br>
    <table class="table table-striped" style="text-align: center;">
        <thead class="thead-dark">
            <tr>
                <th scolpe="col">
                    <input class="form-check-input" type="checkbox" [(ngModel)]="checkAll" (change)="checkTable($event)">
                </th>
                <th style="cursor: pointer;" scope="col" [appSort]="arrayUsuarios" data-order="desc" data-name="data" data-type="date"><fa-icon [icon]="['fas', 'sort']" style="height: 5px;width:5px"></fa-icon>Cadastro</th>
                <th style="cursor: pointer;" scope="col" [appSort]="arrayUsuarios" data-order="desc" data-name="nome"><fa-icon [icon]="['fas', 'sort']" style="height: 5px;width:5px"></fa-icon>Nome</th>
                <th style="cursor: pointer;" scope="col" [appSort]="arrayUsuarios" data-order="desc" data-name="celular"><fa-icon [icon]="['fas', 'sort']" style="height: 5px;width:5px"></fa-icon>Celular</th>
                <th style="cursor: pointer;" scope="col" [appSort]="arrayUsuarios" data-order="desc" data-name="municipio"><fa-icon [icon]="['fas', 'sort']" style="height: 5px;width:5px"></fa-icon>Cidade</th>
                <th style="cursor: pointer;" scope="col" [appSort]="arrayUsuarios" data-order="desc" data-name="plano"><fa-icon [icon]="['fas', 'sort']" style="height: 5px;width:5px"></fa-icon>Plano</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let user of arrayUsuarios; let i = index">
                <td>
                    <input class="form-check-input" type="checkbox" [(ngModel)]="user.checked" (change)="checkUsuario($event,user._id.$oid)">
                </td>
                <td>{{ user?.data | date:'dd/MM/yyyy' }}</td>
                <td>{{ user?.nome }}</td>
                <td>{{ user?.celular }}</td>
                <td>{{ user?.municipio }}</td>
                <td>{{ user?.plano }}</td>
            </tr>
        </tbody>
    </table>
</div>

