<div class="modal" id="modalEditarFornecedores">
    <div class="voltartopo">
        <button class="btn btn-outline-light" (click)="closeModalEditarFornecedor()"><fa-icon [icon]="['fas', 'arrow-left']"></fa-icon> Voltar</button>
    </div>
    <div style="margin-top:4em;padding:10px" class="container-fluid">
        <div class="row">
            <div class="col col-3">
                <label>Vigência</label>
                <input type="date" class="form-control" [(ngModel)]="fornecedorEditar.vigencia">
            </div>
            <div class="col col-3">
                <label>Vigência Fim</label>
                <input type="date" class="form-control" [(ngModel)]="fornecedorEditar.vigenciaFim">
            </div>
           <div class="col col-6">
               <label>Razão Social</label>
               <input type="text" class="form-control" [(ngModel)]="fornecedorEditar.razaoSocial" oninput="this.value = this.value.toUpperCase()">
           </div>
           <div class="col col-4">
                <label>Nome Fantasia</label>
                <input type="text" class="form-control" [(ngModel)]="fornecedorEditar.nomeFantasia" oninput="this.value = this.value.toUpperCase()">
           </div>
           <div class="col col-4">
                <label>CNPJ</label>
                <input type="text" class="form-control" [textMask]="{mask: MASKS.cnpj.textMask}" [(ngModel)]="fornecedorEditar.cnpj">
            </div>
            <div class="col col-4">
                <label>Endereço</label>
                <input type="text" class="form-control" [(ngModel)]="fornecedorEditar.endereco" oninput="this.value = this.value.toUpperCase()">
           </div>
           <div class="col col-2">
                <label>Nº</label>
                <input type="text" class="form-control" [(ngModel)]="fornecedorEditar.numero" oninput="this.value = this.value.toUpperCase()">
            </div>
           <div class="col col-4">
                <label>Bairro</label>
                <input type="text" class="form-control" [(ngModel)]="fornecedorEditar.bairro" oninput="this.value = this.value.toUpperCase()">
            </div>
            <div class="col col-4">
                <label>Cidade</label>
                <input type="text" class="form-control" [(ngModel)]="fornecedorEditar.cidade" oninput="this.value = this.value.toUpperCase()">
            </div>
            <div class="col col-2">
                <label>UF</label>
                <input type="text" class="form-control" [(ngModel)]="fornecedorEditar.estado" oninput="this.value = this.value.toUpperCase()">
            </div>
           <div class="col col-4">
                <label>Telefone</label>
                <input type="text" class="form-control" [textMask]="{mask: MASKS.telefone.textMaskFunction}" [(ngModel)]="fornecedorEditar.telefone">
            </div>
            <div class="col col-4">
                <label>Celular</label>
                <input type="text" class="form-control" [textMask]="{mask: MASKS.telefone.textMaskFunction}" [(ngModel)]="fornecedorEditar.celular">
           </div>
           <div class="col col-4">
                <label>Responsável</label>
                <input type="text" class="form-control" [(ngModel)]="fornecedorEditar.responsavel" oninput="this.value = this.value.toUpperCase()">
            </div>
            <div class="col col-12">
                <label>E-Mail</label>
                <input type="text" class="form-control" [(ngModel)]="fornecedorEditar.email" oninput="this.value = this.value.toUpperCase()">
            </div>
       </div>
       <br>
       <label>Dados Bancários</label>
       <div class="row">
           <div class="col col-6">
               <label>Banco</label>
               <input type="text" [(ngModel)]="fornecedorEditar.banco" class="form-control" oninput="this.value = this.value.toUpperCase()">
           </div>
           <div class="col col-2">
                <label>Agência</label>
                <input type="number" min="0" [(ngModel)]="agenciaEditar" class="form-control">
            </div>
            <div class="col col-1">
                <label>Digito</label>
                <input type="text" [(ngModel)]="fornecedorEditar.agenciaDigito" class="form-control">
            </div>
            <div class="col col-2">
                <label>Conta Corrente</label>
                <input type="number" min="0" [(ngModel)]="contaCorrenteEditar" class="form-control">
            </div>
            <div class="col col-1">
                <label>Digito</label>
                <input type="text" [(ngModel)]="fornecedorEditar.contaCorrenteDigito" class="form-control">
            </div>
       </div>
       <br>
       <label>Dados Bancários (Pix)</label>
       <div class="row">
           <div class="col col-4">
            <label>Tipo de Chave</label>
            <select [(ngModel)]="fornecedorEditar.tipoPix" class="form-control">
                <option selected></option>
                <option value="CNPJ">CNPJ</option>
                <option value="CPF">CPF</option>
                <option value="E-MAIL">E-MAIL</option>
                <option value="TELEFONE">TELEFONE</option>
            </select>
           </div>
           <div class="col col-8">
            <label>Chave Pix</label>
               <input type="text" class="form-control" [(ngModel)]="fornecedorEditar.pix" *ngIf="fornecedorEditar.tipoPix == 'CNPJ'" [textMask]="{mask: MASKS.cnpj.textMask}">
               <input type="text" class="form-control" [(ngModel)]="fornecedorEditar.pix" *ngIf="fornecedorEditar.tipoPix == 'CPF'" [textMask]="{mask: MASKS.cpf.textMask}">
               <input type="text" class="form-control" [(ngModel)]="fornecedorEditar.pix" *ngIf="fornecedorEditar.tipoPix == 'E-MAIL'">
               <input type="text" class="form-control" [(ngModel)]="fornecedorEditar.pix" *ngIf="fornecedorEditar.tipoPix == 'TELEFONE'" [textMask]="{mask: MASKS.telefone.textMaskFunction}">
            </div>
       </div>
       <br>
       <div class="row">
           <div class="col col-4">
               &nbsp;
           </div>
           <div class="col col-2">
                <button class="btn btn-danger btn-block" (click)="closeModalEditarFornecedor()">CANCELAR</button>
           </div>
           <div class="col col-2">
                <button class="btn btn-success btn-block" (click)="editarFornecedor()">SALVAR</button>
           </div>
           <div class="col col-4">
                &nbsp;
            </div>
       </div>
    </div>
</div>
<div class="modal" id="modalNovoFornecedores">
    <div class="voltartopo">
        <button class="btn btn-outline-light" (click)="closeModalNovoFornecedor()"><fa-icon [icon]="['fas', 'arrow-left']"></fa-icon> Voltar</button>
    </div>
    <div style="margin-top:4em;padding:10px" class="container-fluid">
       <div class="row">
            <div class="col col-3">
                <label>Vigência Inicio</label>
                <input type="date" class="form-control" [(ngModel)]="vigencia">
            </div>
            <div class="col col-3">
                <label>Vigência Fim</label>
                <input type="date" class="form-control" [(ngModel)]="vigenciaFim">
            </div>
            <div class="col col-6">
                <label>Razão Social</label>
                <input type="text" class="form-control" [(ngModel)]="razaoSocial" oninput="this.value = this.value.toUpperCase()">
           </div>
           <div class="col col-4">
                <label>Nome Fantasia</label>
                <input type="text" class="form-control" [(ngModel)]="nomeFantasia" oninput="this.value = this.value.toUpperCase()">
           </div>
           <div class="col col-4">
                <label>CNPJ</label>
                <input type="text" class="form-control" [textMask]="{mask: MASKS.cnpj.textMask}" [(ngModel)]="cnpj">
            </div>
            <div class="col col-4">
                <label>Endereço</label>
                <input type="text" class="form-control" [(ngModel)]="endereco" oninput="this.value = this.value.toUpperCase()">
           </div>
           <div class="col col-2">
                 <label>Nº</label>
                <input type="text" class="form-control" [(ngModel)]="numero" oninput="this.value = this.value.toUpperCase()">
           </div>
           <div class="col col-4">
                <label>Bairro</label>
                <input type="text" class="form-control" [(ngModel)]="bairro" oninput="this.value = this.value.toUpperCase()">
            </div>
            <div class="col col-4">
                <label>Cidade</label>
                <input type="text" class="form-control" [(ngModel)]="cidade" oninput="this.value = this.value.toUpperCase()">
            </div>
            <div class="col col-2">
                <label>UF</label>
                <input type="text" class="form-control" [(ngModel)]="estado" oninput="this.value = this.value.toUpperCase()">
            </div>
           <div class="col col-4">
                <label>Telefone</label>
                <input type="text" class="form-control" [textMask]="{mask: MASKS.telefone.textMaskFunction}" [(ngModel)]="telefone">
            </div>
            <div class="col col-4">
                <label>Celular</label>
                <input type="text" class="form-control" [textMask]="{mask: MASKS.telefone.textMaskFunction}" [(ngModel)]="celular">
           </div>
           <div class="col col-4">
                <label>Responsável</label>
                <input type="text" class="form-control" [(ngModel)]="responsavel" oninput="this.value = this.value.toUpperCase()">
            </div>
            <div class="col col-12">
                <label>E-Mail</label>
                <input type="text" class="form-control" [(ngModel)]="email" oninput="this.value = this.value.toUpperCase()">
            </div>
       </div>
       <br>
       <label>Dados Bancários</label>
       <div class="row">
           <div class="col col-6">
               <label>Banco</label>
               <input type="text" [(ngModel)]="banco" class="form-control" oninput="this.value = this.value.toUpperCase()">
           </div>
           <div class="col col-2">
                <label>Agência</label>
                <input type="number" min="0" [(ngModel)]="agencia" class="form-control">
            </div>
            <div class="col col-1">
                <label>Digito</label>
                <input type="text" [(ngModel)]="agenciaDigito" class="form-control">
            </div>
            <div class="col col-2">
                <label>Conta Corrente</label>
                <input type="number" min="0" [(ngModel)]="contaCorrente" class="form-control">
            </div>
            <div class="col col-1">
                <label>Digito</label>
                <input type="text" [(ngModel)]="contaCorrenteDigito" class="form-control">
            </div>
       </div>
       <br>
       <label>Dados Bancários (Pix)</label>
       <div class="row">
           <div class="col col-4">
            <label>Tipo de Chave</label>
            <select [(ngModel)]="tipoPix" class="form-control">
                <option selected></option>
                <option value="CNPJ">CNPJ</option>
                <option value="CPF">CPF</option>
                <option value="E-MAIL">E-MAIL</option>
                <option value="TELEFONE">TELEFONE</option>
            </select>
           </div>
           <div class="col col-8">
               <label>Chave Pix</label>
               <input type="text" class="form-control" [(ngModel)]="pix" *ngIf="tipoPix == 'CNPJ'" [textMask]="{mask: MASKS.cnpj.textMask}">
               <input type="text" class="form-control" [(ngModel)]="pix" *ngIf="tipoPix == 'CPF'" [textMask]="{mask: MASKS.cpf.textMask}">
               <input type="text" class="form-control" [(ngModel)]="pix" *ngIf="tipoPix == 'E-MAIL'">
               <input type="text" class="form-control" [(ngModel)]="pix" *ngIf="tipoPix == 'TELEFONE'" [textMask]="{mask: MASKS.telefone.textMaskFunction}">
            </div>
       </div>
       <br>
       <div class="row">
           <div class="col col-4">
               &nbsp;
           </div>
           <div class="col col-2">
                <button class="btn btn-danger btn-block" (click)="closeModalNovoFornecedor()">CANCELAR</button>
           </div>
           <div class="col col-2">
                <button class="btn btn-success btn-block" (click)="salvarFornecedor()" [disabled]="!razaoSocial">SALVAR</button>
           </div>
           <div class="col col-4">
                &nbsp;
            </div>
       </div>
    </div>
</div>
<div class="row align-items-center header">
    <div class="col titulo" style="text-align: left;">
        FORNECEDORES
    </div>
    <div class="col" style="text-align: right;">
        <button class="btn btn-outline-light" (click)="openModalNovoFornecedor()">
            <fa-icon [icon]="['fas', 'plus']"></fa-icon>&nbsp;CRIAR NOVO
        </button>
    </div>
</div>
<br>
<div class="row align-items-center">
    <div class="col col-12">
        <label>Buscar por Razão Social</label>
        <input type="text" class="form-control" [(ngModel)]="fornecedorSearch" (keyup)="buscarFornecedor(fornecedorSearch)">
    </div>
</div>
<br>
<table class="table table-striped">
    <thead class="thead-dark">
        <tr>
            <th>Razão Social</th>
            <th>Cidade</th>
            <th *ngIf="db?.usuario?.permissoes['fornecedores'] == 'EDITAR'"></th>
            <th *ngIf="db?.usuario?.permissoes['fornecedores'] == 'EDITAR'"></th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let fornecedor of todosFornecedores">
            <td>{{ fornecedor?.razaoSocial }}</td>
            <td>{{ fornecedor?.cidade }} - {{ fornecedor?.estado }}</td>
            <td *ngIf="db?.usuario?.permissoes['fornecedores'] == 'EDITAR'">
                <button class="btn btn-sm btn-outline-success" (click)="openModalEditarFornecedor(fornecedor)">
                    <fa-icon [icon]="['fas', 'edit']"></fa-icon>
                </button>
            </td>
            <td *ngIf="db?.usuario?.permissoes['fornecedores'] == 'EDITAR'">
                <button class="btn btn-sm btn-outline-danger" (click)="excluirFornecedor(fornecedor)">
                    <fa-icon [icon]="['fas', 'trash']"></fa-icon>
                </button>
            </td>
        </tr>
    </tbody>
</table>