import { Component, OnInit, ViewChild } from '@angular/core';
import { DataBaseService } from '../provider.service';
import { AccordionComponent } from 'ngx-bootstrap/accordion';
import { AppComponent } from '../app.component';

@Component({
  selector: 'app-controle-vendas',
  templateUrl: './controle-vendas.component.html',
  styleUrls: ['./controle-vendas.component.css']
})

export class ControleVendasComponent implements OnInit {
  @ViewChild('pdfViewerVendas') pdfViewerOnDemand;
  files:any = [];
  selectedFiles:any = [];
  pdfs:any = [];
  images:any = [];
  imagemSelecionada:any = "";
  indexImage:any = 0;
  vendedores:any =[];
  times:any = []
  timesInterno:any = []
  timesExterno:any = []
  customClass = 'customClass';
  associado:any = "";
  matricula:number;
  observacao:any = "";
  vendedor:any = "";
  dataLancamento:any = "";
  idVendedor:any = "";
  filesVendas:any = [];
  filesVendasEditar:any = [];
  idVenda:any = "";
  dataVendas:any = "";
  mesVigente:any = "";
  alteracao:boolean = false;
  vendedoresRanking:any = [];

  constructor(public db:DataBaseService, public app:AppComponent){
    this.dataVendas = new Date(Date.now() - 10800000).toISOString().split('T')[0];
    this.mesVigente = this.dataVendas.split('-')[1];
  }

  mudaMes(){
    this.mesVigente = this.dataVendas.split('-')[1];
    let ctrl = 1;
    this.times.forEach((item) => {
      item.total = 0;
      item.vendedores = [];
      if(ctrl == this.times.length){
        this.atualizaTabela();
      }else
        ctrl++
    })
  }

  ngOnInit(){
      this.vendedoresRanking = []
      this.times = [];
      this.timesExterno = [];
      this.timesInterno = [];
      this.db.getConfigs().subscribe((z:any) => {
        this.times = z[6].times;
        this.times.forEach((t) => {
          if(t.tipo == "EXTERNO"){
            this.timesExterno.push(t)
          }
          if(t.tipo == "INTERNO"){
            this.timesInterno.push(t)
          }
        })
        this.db.getVendedoresAtivos().subscribe((data:any) => {
          this.vendedores = data;
          data.forEach((item) => {
            item.vendasMes = [];
            item.vendas.forEach((o) => {
              if(o.data.split('-')[1] == this.mesVigente && o.alteracao == false){
                item.vendasMes.push(o)
              }
            })
          })
          this.atualizaTabela();
        })
      })
  }

  atualizaTabela(){
    this.vendedores.forEach((vendedor) => {
      let i = this.timesExterno.map((time) => {
        return time.time;
      }).indexOf(vendedor.time);
      if(i >= 0){
        this.timesExterno[i].vendedores.push(vendedor);
          if(this.timesExterno[i].total){
            this.timesExterno[i].total += Number(vendedor.vendas.filter(item => item.data.split('-')[1].indexOf(this.mesVigente) !== -1).filter(item => item.alteracao !== true).length);
          }else{
            this.timesExterno[i].total = 0;
            this.timesExterno[i].total += Number(vendedor.vendas.filter(item => item.data.split('-')[1].indexOf(this.mesVigente) !== -1).filter(item => item.alteracao !== true).length);
          }
      }
      let ii = this.timesInterno.map((timei) => {
        return timei.time;
      }).indexOf(vendedor.time);
      if(ii >= 0){
        this.timesInterno[ii].vendedores.push(vendedor);
          if(this.timesInterno[ii].total){
            this.timesInterno[ii].total += Number(vendedor.vendas.filter(item => item.data.split('-')[1].indexOf(this.mesVigente) !== -1).filter(item => item.alteracao !== true).length);
          }else{
            this.timesInterno[ii].total = 0;
            this.timesInterno[ii].total += Number(vendedor.vendas.filter(item => item.data.split('-')[1].indexOf(this.mesVigente) !== -1).filter(item => item.alteracao !== true).length);
          }
      }
    });
    this.vendedoresRanking = this.vendedores.sort((a,b) => {
      if(a.vendasMes > b.vendasMes){return -1}
      if(a.vendasMes < b.vendasMes){return 1}
    });
  }
  
 onChange(event){
  let arrayTypes = ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'];
  this.selectedFiles = <FileList>event.srcElement.files;
  for(let i=0; i<this.selectedFiles.length; i++){
      if(!arrayTypes.includes(this.selectedFiles[i].type)){
      this.app.openAlert("Tipo de Arquivo Não Suportado!\nEscolha uma imagem com a extensão .JPG, .JPEG, .PNG ou PDF")
      this.selectedFiles = "";
    }} 
    this.filesVendas = [];
    for(let i=0; i<this.selectedFiles.length; i++){
      this.filesVendas.push(this.selectedFiles[i].name);
    }
}

onChangeClick(event){
  let arrayTypes = ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'];
  this.selectedFiles = <FileList>event.srcElement.files;
  for(let i=0; i<this.selectedFiles.length; i++){
      if(!arrayTypes.includes(this.selectedFiles[i].type)){
      this.app.openAlert("Tipo de Arquivo Não Suportado!\nEscolha uma imagem com a extensão .JPG, .JPEG, .PNG ou PDF")
      this.selectedFiles = "";
    }} 
    this.filesVendas = [];
    for(let i=0; i<this.selectedFiles.length; i++){
      this.filesVendas.push(this.selectedFiles[i].name);
    }
}

onChangeEditar(event){
  let arrayTypes = ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'];
  this.selectedFiles = <FileList>event.srcElement.files;
  for(let i=0; i<this.selectedFiles.length; i++){
      if(!arrayTypes.includes(this.selectedFiles[i].type)){
      this.app.openAlert("Tipo de Arquivo Não Suportado!\nEscolha uma imagem com a extensão .JPG, .JPEG, .PNG ou PDF")
      this.selectedFiles = "";
    }} 
    this.filesVendasEditar = [];
    for(let i=0; i<this.selectedFiles.length; i++){
      this.filesVendasEditar.push(this.selectedFiles[i].name);
    }
}

onChangeClickEditar(event){
  let arrayTypes = ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'];
  this.selectedFiles = <FileList>event.srcElement.files;
  for(let i=0; i<this.selectedFiles.length; i++){
      if(!arrayTypes.includes(this.selectedFiles[i].type)){
      this.app.openAlert("Tipo de Arquivo Não Suportado!\nEscolha uma imagem com a extensão .JPG, .JPEG, .PNG ou PDF")
      this.selectedFiles = "";
    }} 
    this.filesVendasEditar = [];
    for(let i=0; i<this.selectedFiles.length; i++){
      this.filesVendasEditar.push(this.selectedFiles[i].name);
    }
}

atualizarDocs(){
  this.db.getUsuarioById(this.idVendedor).subscribe((result:any) => {
    let vendas = result.vendas;
    let i = vendas.map((item) => {
      return item.id
    }).indexOf(this.idVenda);
    if(vendas && vendas[i].anexos.length > 0){
      this.pdfs = [];
      this.images = [];
      vendas[i].anexos.forEach((item) => {
      let ext = item.url.split('.').pop();
        if(ext == 'pdf' || ext == 'PDF'){
          this.pdfs.push(item);
        }else{
          this.images.push(item);
        }
      })
    }
  })
}

openModalPdfViewer(url){
  this.pdfViewerOnDemand.pdfSrc = url;
  this.pdfViewerOnDemand.refresh();
}

openModalLightBox(url, i){
  console.log(url)
  this.indexImage = i;
  this.imagemSelecionada = url;
  let modal = document.getElementById('myModal')
  modal.style.display = "block";
}

closeModal(){
  let modal = document.getElementById('myModal')
  modal.style.display = "none";
}

nextImg(){
  let i = this.indexImage
  if((this.images.length - 1) == i){
    this.imagemSelecionada = this.images[0].url;
    this.indexImage = 0;
  }else{
    i++
    this.imagemSelecionada = this.images[i].url;
    this.indexImage++
  }
}

prevImg(){
  let i = this.indexImage
  if(i == 0){
    this.imagemSelecionada = this.images[this.images.length - 1].url;
    this.indexImage = this.images.length - 1;
  }else{
    i--
    this.imagemSelecionada = this.images[i].url;
    this.indexImage--
  }
}

delDocConta(url){
  if(window.confirm('Deseja Realmente Deletar esse Documento?')){
    this.db.removeDocVendaDB(this.idVendedor,url,this.idVenda).subscribe(result => {
      console.log(result);
      this.db.removeDoc(url).subscribe(res => {
        console.log(res);
        this.atualizarDocs();
      },err => {
          console.log(err);
          this.atualizarDocs();
        })
    }, error => {
      console.log(error);
    })
  }
}

  novaVenda(vendedor){
    let modal = document.getElementById('modalNovaConta');
    modal.style.display = "block";
    this.vendedor = vendedor.vendedor;
    this.idVendedor = vendedor._id;
  }

  closeModalVenda(){
    let modal = document.getElementById('modalNovaConta');
    modal.style.display = "none";
    this.associado = "";
    this.matricula = null;
    this.observacao = "";
    this.alteracao = false;
    this.vendedor = "";
    this.dataLancamento = "";
    this.idVendedor = "";
    this.files = [];
    this.selectedFiles = [];
  }

  cadastrarVenda(){
    let idRandom = Math.random().toString(32).substr(4, 14);
    let dataHoje = new Date(Date.now() - 10800000).toISOString().split('T')[0];
    let jsonDate = new Date().toJSON();
    let hora = new Date(jsonDate).toLocaleTimeString();
    let dados = {
      associado: this.associado,
      data: dataHoje,
      hora: hora,
      id: idRandom,
      matricula: this.matricula,
      observacao: this.observacao,
      alteracao:this.alteracao,
      anexos:[]
      }
    this.db.novaVenda(this.idVendedor,dados).subscribe(res => {
      console.log(res);
      if(this.selectedFiles.length > 0){
        const imagedata = new FormData();
        for(let i=0; i<this.selectedFiles.length; i++){imagedata.append('files[]', this.selectedFiles[i], this.selectedFiles[i].name)};
         this.db.enviarDocVendas(this.idVendedor, imagedata,idRandom).subscribe((result) => {
             console.log(result);
             this.ngOnInit();
             this.closeModalVenda();
           },(error) => {
             console.log(error);
           });
      }else{
        this.ngOnInit();
        this.closeModalVenda();
      }
     }, err => {
      console.log(err)
    })
  }

  openModalDocs(idVendedor,idVenda){
    this.idVendedor = idVendedor;
    this.idVenda = idVenda;
    this.pdfs = [];
    this.images = [];
    this.db.getUsuarioById(idVendedor).subscribe((result:any) => {
      let vendas = result.vendas;
      let i = vendas.map((item) => {
        return item.id
      }).indexOf(idVenda);
      if(vendas && vendas[i].anexos.length > 0){
        vendas[i].anexos.forEach((item) => {
        let ext = item.url.split('.').pop();
          if(ext == 'pdf' || ext == 'PDF'){
            this.pdfs.push(item);
          }else{
            this.images.push(item);
          }
        })
      }
    })
    let modal = document.getElementById('modalDocs')
    modal.style.display = "block";
  }

closeModalDocs(){
  this.idVendedor = "";
  this.idVenda = "";
  let modal = document.getElementById('modalDocs')
  modal.style.display = "none";
}
  
excluirVenda(id,venda){
  if(window.confirm("Deseja realmente excluir essa Venda?")){
    let jsonDate = new Date().toJSON();
    let hora = new Date(jsonDate).toLocaleTimeString();
    this.db.deleteVendaDB(id,venda.id).subscribe(res => {
      console.log(res);
      this.app.openAlert("Venda Excluida com Sucesso!");
      this.ngOnInit();
    },err => {
    console.log(err)
    })
  }
}

enviarDocVenda(){
  const imagedata = new FormData();
  for(let i=0; i<this.selectedFiles.length; i++){imagedata.append('files[]', this.selectedFiles[i], this.selectedFiles[i].name)};
   this.db.enviarDocVendas(this.idVendedor, imagedata,this.idVenda).subscribe((result) => {
       console.log(result);
       this.atualizarDocs();
       this.selectedFiles = [];
       this.filesVendasEditar = [];
     },(error) => {
       console.log(error);
    });
  }

}
