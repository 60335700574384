<div class="container-fluid">
<div id="myModal" class="modalLightbox">
    <span (click)="closeModal()" class="close">&times;</span>
    <div class="prev" (click)="prevImg()">&lt;</div>
    <img [src]="imagemSelecionada" class="imagemLightbox">
    <div class="next" (click)="nextImg()">&gt;</div>
</div>
<!-- MODAL VER CONTA RECEBER -->
<div class="modal" id="modalVerContaReceber">
    <div style="width: 800px; height: 400px;display: none;">
      <ng2-pdfjs-viewer 
          #pdfViewerContasReceber
          [externalWindow]="true"
          [downloadFileName]="'vivamax.pdf'"
          [openFile]="true"
          [viewBookmark]="false"
          [download]="true"></ng2-pdfjs-viewer>
    </div>
    <div class="voltartopo">
        <button class="btn btn-outline-light" (click)="closeModalVerConta()"><fa-icon [icon]="['fas', 'arrow-left']"></fa-icon> Voltar</button>
    </div>
    <div style="margin-top:3em;padding:50px">
        <div class="row">
            <div class="col col-3">
                <label>CPF</label>
                <input type="text" class="form-control" [(ngModel)]="cpfBusca" cpf [textMask]="{mask: MASKS.cpf.textMask}" (keyup)="buscarFavorecidoCPFEditar(cpfBusca)">
            </div>
            <div class="col col-9">
                <label>Favorecido</label>
                <select [(ngModel)]="favorecidoEditar" class="form-control">
                    <option selected disabled></option>
                    <option *ngFor="let favorecido of favorecidos">{{favorecido.razaoSocial}}</option>
                </select>
            </div>
        </div>
        <br>
        <div class="row align-items-center">
            <div class="col col-4">
                <label>Tipo de Entrada</label>
                <select class="form-control" [(ngModel)]="tipoEntradaContaEditar" [ngStyle]="{'border-color': !tipoEntradaContaEditar ? '#dd443e' : '#3cb42c'}">
                    <option [value]=""></option>
                    <option [value]="tC.nome" *ngFor="let tC of tiposEntrada">{{tC.nome}}</option>
                </select>
            </div>
            <div class="col col-4">
                <label>Tipo Vencimento</label>
                <select class="form-control" [(ngModel)]="tipoVencimentoContaEditar" readonly>
                    <option value="À Vista">À Vista</option>
                    <option value="Parcelado">Parcelado</option>
                </select>
            </div>
            <div class="col col-2">
                <br>
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" id="spcCheck" [(ngModel)]="spcEditar">
                    <label class="form-check-label" for="spcCheck" style="margin-left:10px">
                    SPC
                    </label>
                </div>
            </div>
            <div class="col col-2">
                <br>
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" id="vindiCheck" [(ngModel)]="vindiEditar">
                    <label class="form-check-label" for="vindiCheck" style="margin-left:10px">
                    VINDI
                    </label>
                </div>
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col col-12">
                <label>Observação</label>
                <input type="text" class="form-control" [(ngModel)]="observacaoContaEditar" oninput="this.value = this.value.toUpperCase()">
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col col-3">
                <label>Valor</label>
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon1">R$</span>
                    </div>
                    <input type="number" class="form-control" [(ngModel)]="valorContaEditar" (change)="calcularTotalEditar()" min="0">
                </div>    
            </div>
            <div class="col col-3">
                <label>Vencimento</label>
                <input type="date" class="form-control" [(ngModel)]="vencimentoContaEditar">
            </div>
            <div class="col col-3">
                <label>Parcela</label>
                <input type="text" class="form-control" [(ngModel)]="parcelaConta" readonly>
            </div>
            <div class="col col-3">
                <label>Forma de Recebimento</label>
                <select [(ngModel)]="formaPagamentoEditar" class="form-control" [ngStyle]="{'border-color': !formaPagamentoEditar ? '#dd443e' : '#3cb42c'}">
                    <option selected></option>
                    <option *ngFor="let forma of todasFormasPagamento">{{forma.forma}}</option>
                </select>
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col col-3">
                <label>Juros</label>
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon1">R$</span>
                    </div>
                    <input type="number" class="form-control" [(ngModel)]="jurosContaEditar" (change)="calcularTotalEditar()" min="0">
                </div>  
            </div>
            <div class="col col-3">
                <label>Desconto</label>
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon1">R$</span>
                    </div>
                    <input type="number" class="form-control" [(ngModel)]="descontoContaEditar" (change)="calcularTotalEditar()" min="0">
                </div>  
            </div>
            <div class="col col-3">
                <label>Total a Receber</label>
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon1">R$</span>
                    </div>
                    <input type="number" class="form-control" [(ngModel)]="valorTotalEditar" readonly>
                </div>  
            </div>
            <div class="col col-3">
                <label>Data Pagamento</label>
                <input type="date" class="form-control" [(ngModel)]="dataPagamento">
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col">
                <label>Banco:</label>
                <select [(ngModel)]="bancoEditar" class="form-control">
                    <option value=""></option>
                    <option *ngFor="let b of bancos">{{b.nome}}</option>
                </select>
            </div>
        </div>
        <br>
        <div class="row justify-content-md-center">
            <div class="col col-3" *ngFor="let pdf of pdfsConta;" style="margin: 10px; background-color:#ce2626; color:white; height: 250px; width:100%; text-align: center; border-radius: 10px;">
                <div class="delButton" name="delButton" *ngIf="db?.usuario?.permissoes['financeiro'] == 'EDITAR'">
                    <button class="btn btn-dark btn-sm" (click)="delDocConta(pdf.url)"><strong>X</strong></button>
                </div>
                <div (click)="openModalPdfViewer(pdf.url)" style="position: relative;left:50%;top:50%;transform: translate(-50%,-50%);cursor:pointer">
                    <fa-icon [icon]="['fas', 'file-pdf']" style="font-size: 5em;"></fa-icon>
                        <br>DOCUMENTO PDF
                </div>
            </div>
            <div class="col col-3 img-container" *ngFor="let img of imagesConta; let i = index" style="margin: 10px; text-align: center;">
                <div class="delButton" name="delButton" *ngIf="db?.usuario?.permissoes['financeiro'] == 'EDITAR'">
                    <button class="btn btn-danger btn-sm" (click)="delDocConta(img.url)"><strong>X</strong></button>
                </div>
                <img [src]="img.url" (click)="openModalLightBox(img.url, i)" style="border-radius: 10px; height: 250px; width:100%;" crossorigin="anonymous">
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col">
                <div class="uploadfilecontainer" (click)="fileDocumentosEditar.click()" appDragDrop (onFileDropped)="onChangeContaReceberEditar($event)">
                    <input hidden type="file" #fileDocumentosEditar (change)="onChangeClickContaReceberEditar($event)" multiple>
                </div>
                <div class="row align-items-center">
                    <div class="col col-1">
                        &nbsp;
                    </div>
                    <div class="col">
                        <div class="files-list" *ngFor="let file of filesContaReceberEditar; let i= index">
                            <p>{{ file }}</p>
                        </div> 
                    </div>
                    <div class="col col-2" [hidden]="filesContaReceberEditar.length < 1">
                        <button class="btn btn-primary btn-block" (click)="anexarDoc()">ANEXAR</button>
                    </div>
                    <div class="col col-1">
                        &nbsp;
                    </div>
                </div>
            </div>
        </div>
       <br>
       <div class="row">
           <div class="col">
               &nbsp;
           </div>
           <div class="col col-2">
                <button class="btn btn-danger btn-block" (click)="closeModalVerConta()">FECHAR</button>
           </div>
           <div class="col col-3">
                <button class="btn btn-primary btn-block" (click)="editarConta()" [disabled]="!tipoEntradaContaEditar || filesContaReceberEditar.length > 0">EDITAR CONTA</button>
           </div>
           <div class="col col-3">
             <button class="btn btn-success btn-block" (click)="receberConta()" [disabled]="!tipoEntradaContaEditar || !dataPagamento || !formaPagamentoEditar || formaPagamentoEditar == '' || filesContaReceberEditar.length > 0">RECEBER CONTA</button>
           </div>
          <div class="col">
            &nbsp;
          </div>
       </div>
    </div>
</div>
<!-- MODAL CONTA A RECEBER -->
<div class="modal" id="modalContaReceber">
    <div class="voltartopo">
        <button class="btn btn-outline-light" (click)="closeModalNovaConta()"><fa-icon [icon]="['fas', 'arrow-left']"></fa-icon> Voltar</button>
    </div>
    <div style="margin-top:3em;padding:50px">
        <div class="row">
            <div class="col col-3">
                <label>CPF</label>
                <input type="text" class="form-control" [(ngModel)]="cpfBusca" cpf [textMask]="{mask: MASKS.cpf.textMask}" (keyup)="buscarFavorecidoCPF(cpfBusca)">
            </div>
            <div class="col col-6">
                <label>Favorecido</label>
                <ng-select class="form-control" [items]="favorecidos" bindLabel="razaoSocial" appendTo="body" [(ngModel)]="favorecidoConta" (change)="escolheFavorecido(favorecidoConta)"></ng-select>
            </div>
            <div class="col col-3">
                <label>Tipo Vencimento</label>
                <select class="form-control" [(ngModel)]="tipoVencimentoConta">
                    <option value="À Vista">À Vista</option>
                    <option value="Parcelado">Parcelado</option>
                </select>
            </div>
        </div>
        <br>
        <div class="row">            
            <div class="col col-3">
                <label>Tipo de Entrada</label>
                <select class="form-control" [(ngModel)]="tipoEntradaConta" [ngStyle]="{'border-color': !tipoEntradaConta ? '#dd443e' : '#3cb42c'}">
                    <option [value]=""></option>
                    <option [value]="tC.nome" *ngFor="let tC of tiposEntrada">{{tC.nome}}</option>
                </select>
            </div>
            <div class="col col-9">
                <label>Observação</label>
                <input type="text" class="form-control" [(ngModel)]="observacaoConta" oninput="this.value = this.value.toUpperCase()">
            </div>
        </div>
        <div class="row" *ngIf="tipoVencimentoConta == 'À Vista'">
            <div class="col col-3">
                <label>Valor</label>
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon1">R$</span>
                    </div>
                    <input type="number" class="form-control" [(ngModel)]="valorConta">
                </div>    
            </div>
            <div class="col col-3">
                <label>Vencimento</label>
                <input type="date" class="form-control" [(ngModel)]="vencimentoConta">
            </div>
        </div>
        <form [formGroup]="parcelados" *ngIf="tipoVencimentoConta == 'Parcelado'">
            <div class="form-group col-3" style="text-align: left;" [hidden]="parceladosList?.length > 0">
                <br>
              <button class="btn btn-outline-success btn-sm" (click)="addParcela('')">
                <fa-icon [icon]="['fas', 'plus-circle']" size="1x"></fa-icon> ADICIONAR PARCELA
              </button>
            </div>
            <div class="card-body" formArrayName="parcela">
                <div [formGroupName]="i" class="row" *ngFor="let contact of parcelasFormGroup.controls; let i = index;">
                    <div class="form-group col-4">
                        <label>Vencimento da Parcela {{i+1}}</label><br>
                        <input type="date" class="form-control" placeholder="Vencimento" formControlName="vencimento">
                    </div>
                    <div class="form-group col-3">
                        <label>Valor da Parcela {{i+1}}</label><br>
                        <div class="input-group mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text" id="basic-addon1">R$</span>
                            </div>
                            <input type="number" class="form-control" placeholder="Valor" formControlName="valor">
                        </div> 
                    </div>
                    <div class="form-group col-2 text-right">
                        <br>
                        <button class="btn btn-danger" (click)="removeParcela(i)">
                            <fa-icon [icon]="['fas', 'minus-circle']" size="1x"></fa-icon>
                        </button>&nbsp;
                        <button class="btn btn-success" (click)="addParcelaNova({vencimento:parcelados.controls.parcela.value[i].vencimento,valor:parcelados.controls.parcela.value[i].valor})">
                            <fa-icon [icon]="['fas', 'plus-circle']" size="1x"></fa-icon>
                        </button>
                    </div>
                </div>   
            </div> 
        </form>
        <br>
        <div class="row">
            <div class="col">
                <label>Banco:</label>
                <select [(ngModel)]="banco" class="form-control">
                    <option value=""></option>
                    <option *ngFor="let b of bancos">{{b.nome}}</option>
                </select>
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col">
                <div class="uploadfilecontainer" (click)="fileDocumentos.click()" appDragDrop (onFileDropped)="onChangeContaReceber($event)">
                    <input hidden type="file" #fileDocumentos (change)="onChangeClickContaReceber($event)" multiple>
                </div>
                <div class="files-list" *ngFor="let file of filescontaReceber; let i= index">
                    <p>{{ file }}</p>
                </div> 
            </div>
        </div>
       <br>
       <div class="row">
           <div class="col col-4">
               &nbsp;
           </div>
           <div class="col col-2">
                <button class="btn btn-danger btn-block" (click)="closeModalNovaConta()">CANCELAR</button>
           </div>
           <div class="col col-2">
                <button class="btn btn-success btn-block" (click)="cadastrarConta()" [disabled]="!tipoVencimentoConta || !tipoEntradaConta">CADASTRAR</button>
           </div>
           <div class="col col-4">
                &nbsp;
            </div>
       </div>
    </div>
</div>
<!-- MODAL CADASTRO FORMA PAGAMENTO -->
<div class="modal" id="modalFormasPagamento">
    <div class="voltartopo">
        <button class="btn btn-outline-light" (click)="closeModalFormas()"><fa-icon [icon]="['fas', 'arrow-left']"></fa-icon> Voltar</button>
    </div>
    <div style="margin-top:3em;padding:50px">
        <form [formGroup]="formasPagamento">
            <div class="row" formArrayName="forma">
                <div class="col col-12" style="font-size: 0.85em" *ngFor="let forma of formasPagamentoFormGroup.controls; let i = index;">
                    <div [formGroupName]="i" class="row">
                        <div class="form-group col-9">
                            <input type="text" class="form-control" formControlName="forma" placeholder="Forma Pagamento" oninput="this.value = this.value.toUpperCase()">
                        </div>
                        <div class="form-group col-3 text-right">
                            <button class="btn btn-danger btn-sm" type="button" (click)="removePagamento(i)" *ngIf="this.formaPagamanetoList.length > 1">
                            <fa-icon [icon]="['fas', 'minus-circle']" size="1x"></fa-icon>
                            </button>&nbsp;
                            <button class="btn btn-success btn-sm" type="button" (click)="addPagamento()">
                            <fa-icon [icon]="['fas', 'plus-circle']" size="1x"></fa-icon>
                            </button>
                        </div>
                    </div>
                </div>
            </div>   
        </form>
       <br>
       <div class="row">
           <div class="col col-4">
               &nbsp;
           </div>
           <div class="col col-2">
                <button class="btn btn-danger btn-block" (click)="closeModalFormas()">CANCELAR</button>
           </div>
           <div class="col col-2">
                <button class="btn btn-success btn-block" (click)="salvarFormas()">SALVAR</button>
           </div>
           <div class="col col-4">
                &nbsp;
            </div>
       </div>
    </div>
</div>
<!-- MODAL CADASTRO FAVORECIDO -->
<div class="modal" id="modalNovoFavorecido">
    <div class="voltartopo">
        <button class="btn btn-outline-light" (click)="closeModalNovoFavorecido()"><fa-icon [icon]="['fas', 'arrow-left']"></fa-icon> Voltar</button>
    </div>
    <div style="margin-top:3em;padding:50px">
       <div class="row">
           <div class="col col-4">
               <label>Razão Social</label>
               <input type="text" class="form-control" [(ngModel)]="razaoSocial" oninput="this.value = this.value.toUpperCase()">
           </div>
           <div class="col col-4">
                <label>Nome Fantasia</label>
                <input type="text" class="form-control" [(ngModel)]="nomeFantasia" oninput="this.value = this.value.toUpperCase()">
           </div>
           <div class="col col-4">
                <label>CNPJ</label>
                <input type="text" class="form-control" [textMask]="{mask: MASKS.cnpj.textMask}" [(ngModel)]="cnpj">
            </div>
        </div>
        <div class="row">
            <div class="col col-3">
                <label>Endereço</label>
                <input type="text" class="form-control" [(ngModel)]="endereco" oninput="this.value = this.value.toUpperCase()">
           </div>
           <div class="col col-2">
                 <label>Nº</label>
                <input type="text" class="form-control" [(ngModel)]="numero" oninput="this.value = this.value.toUpperCase()">
           </div>
           <div class="col col-3">
                <label>Bairro</label>
                <input type="text" class="form-control" [(ngModel)]="bairro" oninput="this.value = this.value.toUpperCase()">
            </div>
            <div class="col col-3">
                <label>Cidade</label>
                <input type="text" class="form-control" [(ngModel)]="cidade" oninput="this.value = this.value.toUpperCase()">
            </div>
            <div class="col col-1">
                <label>UF</label>
                <input type="text" class="form-control" [(ngModel)]="estado" oninput="this.value = this.value.toUpperCase()">
            </div>
        </div>
        <div class="row">
           <div class="col col-3">
                <label>Telefone</label>
                <input type="text" class="form-control" [textMask]="{mask: MASKS.telefone.textMaskFunction}" [(ngModel)]="telefone">
            </div>
            <div class="col col-3">
                <label>Celular</label>
                <input type="text" class="form-control" [textMask]="{mask: MASKS.telefone.textMaskFunction}" [(ngModel)]="celular">
           </div>
           <div class="col col-3">
                <label>Responsável</label>
                <input type="text" class="form-control" [(ngModel)]="responsavel" oninput="this.value = this.value.toUpperCase()">
            </div>
            <div class="col col-3">
                <label>E-Mail</label>
                <input type="text" class="form-control" [(ngModel)]="email" oninput="this.value = this.value.toUpperCase()">
            </div>
       </div>
       <br>
       <div class="row">
           <div class="col col-4">
               &nbsp;
           </div>
           <div class="col col-2">
                <button class="btn btn-danger btn-block" (click)="closeModalNovoFavorecido()">CANCELAR</button>
           </div>
           <div class="col col-2">
                <button class="btn btn-success btn-block" (click)="salvarFavorecido()" [disabled]="!razaoSocial">SALVAR</button>
           </div>
           <div class="col col-4">
                &nbsp;
            </div>
       </div>
    </div>
</div>
<!-- MODAL EDITAR FAVORECIDOS -->
<div class="modal" id="modalEditarFavorecido">
    <div class="voltartopo">
        <button class="btn btn-outline-light" (click)="closeModalEditarFavorecido()"><fa-icon [icon]="['fas', 'arrow-left']"></fa-icon> Voltar</button>
    </div>
    <div style="margin-top:3em;padding:50px">
       <div class="row">
           <div class="col col-4">
               <label>Razão Social</label>
               <input type="text" class="form-control" [(ngModel)]="favorecidoEditar.razaoSocial" oninput="this.value = this.value.toUpperCase()">
           </div>
           <div class="col col-4">
                <label>Nome Fantasia</label>
                <input type="text" class="form-control" [(ngModel)]="favorecidoEditar.nomeFantasia" oninput="this.value = this.value.toUpperCase()">
           </div>
           <div class="col col-4">
                <label>CNPJ</label>
                <input type="text" class="form-control" [textMask]="{mask: MASKS.cnpj.textMask}" [(ngModel)]="favorecidoEditar.cnpj">
            </div>
        </div>
        <div class="row">
            <div class="col col-3">
                <label>Endereço</label>
                <input type="text" class="form-control" [(ngModel)]="favorecidoEditar.endereco" oninput="this.value = this.value.toUpperCase()">
           </div>
           <div class="col col-2">
                 <label>Nº</label>
                <input type="text" class="form-control" [(ngModel)]="favorecidoEditar.numero" oninput="this.value = this.value.toUpperCase()">
           </div>
           <div class="col col-3">
                <label>Bairro</label>
                <input type="text" class="form-control" [(ngModel)]="favorecidoEditar.bairro" oninput="this.value = this.value.toUpperCase()">
            </div>
            <div class="col col-3">
                <label>Cidade</label>
                <input type="text" class="form-control" [(ngModel)]="favorecidoEditar.cidade" oninput="this.value = this.value.toUpperCase()">
            </div>
            <div class="col col-1">
                <label>UF</label>
                <input type="text" class="form-control" [(ngModel)]="favorecidoEditar.estado" oninput="this.value = this.value.toUpperCase()">
            </div>
        </div>
        <div class="row">
           <div class="col col-3">
                <label>Telefone</label>
                <input type="text" class="form-control" [textMask]="{mask: MASKS.telefone.textMaskFunction}" [(ngModel)]="favorecidoEditar.telefone">
            </div>
            <div class="col col-3">
                <label>Celular</label>
                <input type="text" class="form-control" [textMask]="{mask: MASKS.telefone.textMaskFunction}" [(ngModel)]="favorecidoEditar.celular">
           </div>
           <div class="col col-3">
                <label>Responsável</label>
                <input type="text" class="form-control" [(ngModel)]="favorecidoEditar.responsavel" oninput="this.value = this.value.toUpperCase()">
            </div>
            <div class="col col-3">
                <label>E-Mail</label>
                <input type="text" class="form-control" [(ngModel)]="favorecidoEditar.email" oninput="this.value = this.value.toUpperCase()">
            </div>
       </div>
       <br>
       <div class="row">
           <div class="col col-4">
               &nbsp;
           </div>
           <div class="col col-2">
                <button class="btn btn-danger btn-block" (click)="closeModalEditarFavorecido()">CANCELAR</button>
           </div>
           <div class="col col-2">
                <button class="btn btn-success btn-block" (click)="editarFavorecido()">SALVAR</button>
           </div>
           <div class="col col-4">
                &nbsp;
            </div>
       </div>
    </div>
</div>
<!-- MODAL FAVORECIDOS -->
<div class="modal" id="modalFavorecidos">
    <div class="row voltartopo">
        <div class="col col-2" style="text-align:left">
            <button class="btn btn-outline-light" (click)="closeModalFavorecido()"><fa-icon [icon]="['fas', 'arrow-left']"></fa-icon> Voltar</button>
        </div>
        <div class="col col-10">
            <input type="text" class="form-control" placeholder="Buscar por Razão Social" [(ngModel)]="favorecidoSearch" (keyup)="buscarFavorecido(favorecidoSearch)">
        </div>
    </div>
    <div class="table-responsive" style="margin-top:4em;height:90vh">
        <table class="table table-striped">
            <thead class="thead-dark">
                <tr>
                    <th>Razão Social</th>
                    <th>Nome Fantasia</th>
                    <th>CNPJ</th>
                    <th>Celular</th>
                    <th>Cidade</th>
                    <th *ngIf="db?.usuario?.permissoes['financeiro'] == 'EDITAR'"></th>
                    <th *ngIf="db?.usuario?.permissoes['financeiro'] == 'EDITAR'"></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let favorecido of todosFavorecidos">
                    <td>{{ favorecido?.razaoSocial }}</td>
                    <td>{{ favorecido?.nomeFantasia }}</td>
                    <td>{{ favorecido?.cnpj }}</td>
                    <td>{{ favorecido?.celular }}</td>
                    <td>{{ favorecido?.cidade }} - {{ favorecido?.estado }}</td>
                    <td *ngIf="db?.usuario?.permissoes['financeiro'] == 'EDITAR'">
                        <button class="btn btn-outline-success" (click)="openModalEditarFavorecido(favorecido)" [disabled]="favorecido?.imagem">
                            <fa-icon [icon]="['fas', 'edit']"></fa-icon>
                        </button>
                    </td>
                    <td *ngIf="db?.usuario?.permissoes['financeiro'] == 'EDITAR'">
                        <button class="btn btn-outline-danger" (click)="excluirFavorecido(favorecido)" [disabled]="favorecido?.imagem">
                            <fa-icon [icon]="['fas', 'trash']"></fa-icon>
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
<div class="row  align-items-center header">
    <div class="col col-1">
        &nbsp;
    </div>
    <div class="col" style="text-align: right;">
        <button class="btn btn-sm btn-outline-light" (click)="openModalFavorecido()">
            FAVORECIDOS
        </button>&nbsp;&nbsp;
        <button class="btn btn-sm btn-outline-light" (click)="openModalNovoFavorecido()">
            CADASTRAR FAVORECIDO
        </button>&nbsp;&nbsp;
        <button class="btn btn-sm btn-outline-light" (click)="openModalFormas()">
            CADASTRAR FORMA DE PAGAMENTO
        </button>&nbsp;&nbsp;
        <button class="btn btn-sm btn-outline-light" (click)="openModalNovaConta()">
            CADASTRAR CONTA A RECEBER
        </button>
    </div>
</div>
<br>
<div class="row">
    <div class="col" style="text-align:right">
        <button class="btn btn-outline-danger" (click)="getInadimplentes()">
            INADIMPLENTES
        </button>&nbsp;&nbsp;
        <button class="btn btn-outline-danger" (click)="getSpc()">
            SPC
        </button>&nbsp;&nbsp;
        <button class="btn btn-outline-danger" (click)="getVindi()">
            VINDI
        </button>&nbsp;&nbsp;
    </div>
</div>
<div class="row align-items-end">
    <div class="col col-3">
        <label>Vencimento Inicio</label>
        <input type="date" class="form-control" [(ngModel)]="dataInicioConta">
    </div>
    <div class="col col-3">
        <label>Vencimento Fim</label>
        <input type="date" class="form-control" [(ngModel)]="dataFimConta">
    </div>
    <div class="col col-5">
        <label>Favorecido</label>
        <input type="text" class="form-control" [(ngModel)]="favorecidoBusca">
    </div>
    <div class="col col-1">
        <button class="btn btn-primary" (click)="getContas(dataInicioConta,dataFimConta,favorecidoBusca)" [disabled]="!dataInicioConta || !dataFimConta"><fa-icon [icon]="['fas', 'search']"></fa-icon></button>
    </div>
    
</div>
<div class="row align-items-end" style="padding:5px">
    <div class="col" style="text-align: right; font-weight: bold;">
        Total Receber: {{totalContasReceber | currency:'BRL'}}
     </div>
</div>
<br>
    <div *ngIf="contasReceber.length > 0">
        <table class="table table-striped" style="text-align: center;">
            <thead class="thead-dark">
                <tr>
                    <th>#</th>
                    <th>Matricula</th>
                    <th>Favorecido</th>
                    <th>Observação</th>
                    <th>Vencimento</th>
                    <th>Tipo</th>
                    <th>Valor</th>
                    <th>Total Receber</th>
                    <th>Parcela</th>
                    <th *ngIf="db?.usuario?.permissoes['financeiro'] == 'EDITAR'">Receber</th>
                    <th *ngIf="db?.usuario?.permissoes['financeiro'] == 'EDITAR'">Editar / Receber</th>
                    <th *ngIf="db?.usuario?.permissoes['financeiro'] == 'EDITAR'">Estornar</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let conta of contasReceber; let i = index">
                    <td>{{ i + 1 }}</td>
                    <td>{{ conta?.matricula }}</td>
                    <td>{{ conta?.favorecido }}</td>
                    <td>{{ conta?.observacao }}</td>
                    <td [ngStyle]="{'background-color': conta?.vencimento <= dataHoje ? '#e08383' : 'null'}" >{{ conta?.vencimento | date:'dd/MM/yyyy' }}</td>
                    <td>{{ conta?.tipoVencimento }}</td>
                    <td>{{ conta?.valor | currency:'BRL' }}</td>
                    <td>{{ conta?.valorTotal | currency:'BRL' }}</td>
                    <td>{{ conta?.parcela }}</td>
                    <td>
                        <button class="btn btn-outline-warning" (click)="receberRapido(conta,i)" [disabled]="conta?._id == undefined">
                            <fa-icon [icon]="['fas', 'hand-holding-usd']"></fa-icon>
                        </button>
                    </td>
                    <td>
                        <button class="btn btn-outline-success" (click)="openModalVerConta(conta)" [disabled]="conta?._id == undefined">
                            <fa-icon [icon]="['fas', 'edit']"></fa-icon>
                        </button>
                    </td>
                    <td *ngIf="db?.usuario?.permissoes['financeiro'] == 'EDITAR'">
                        <button class="btn btn-outline-danger" (click)="excluirConta(conta)" [disabled]="conta?._id == undefined">
                            <fa-icon [icon]="['fas', 'trash']"></fa-icon>
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
   
    <div style="height: 10em;"></div>
</div>