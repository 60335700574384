<div class="container-fluid">
    <div id="myModal" class="modalLightbox">
        <span (click)="closeModal()" class="close">&times;</span>
        <div class="prev" (click)="prevImg()">&lt;</div>
        <img [src]="imagemSelecionada" class="imagemLightbox">
        <div class="next" (click)="nextImg()">&gt;</div>
    </div> 
    <div style="width: 800px; height: 400px;display: none;">
        <ng2-pdfjs-viewer 
            #pdfViewerEmpresas
            [externalWindow]="true"
            [downloadFileName]="'boleto.pdf'"
            [openFile]="false"
            [viewBookmark]="false"
            [download]="true"></ng2-pdfjs-viewer>
    </div>
    <!-- MODAL EDITAR EMPRESA -->
    <div class="modal" id="editarEmpresaModal">
<!-- MODAL NOVO ASSOCIADO PJ-->
<div class="modalNovoPJ" id="modalNovoPjEmpresa">
    <div class="row  align-items-center header">
        <div class="col titulo">
            CADASTRAR ASSOCIADO PESSOA JURÍDICA
        </div>
        <div class="col botoes">
            <button class="btn btn-outline-light" (click)="fecharModalNovoPj()">
                <fa-icon [icon]="['fas', 'window-close']"></fa-icon>&nbsp;VOLTAR
            </button>
        </div>
    </div>
    <div class="corpo">
        <div class="row align-items-center">
            <div class="col col-3">
                <label>Data Adesão</label>
                <input type="date" (change)="verificaData(dataAdesaoNovo)" class="form-control" [(ngModel)]="dataAdesaoNovo" [ngStyle]="{'border-color': !dataAdesaoNovo ? '#dd443e' : '#3cb42c'}">
            </div>
            <div class="col col-4">
                <label>Tipo Cadastro:</label>
                <select class="form-control" [(ngModel)]="origemNovo" [ngStyle]="{'border-color': !origemNovo ? '#dd443e' : '#3cb42c'}">
                    <option value=""></option>
                    <option value="NOVO">NOVO</option>
                    <option value="INCLUSÃO EMPRESA">INCLUSÃO EMPRESA</option>
                </select>
            </div>
        </div>        
        <br>
        <div class="row">
            <div class="col col-3">
                <label>CPF:</label>
                <input type="text" class="form-control" [(ngModel)]="cpfNovo" cpf [textMask]="{mask: MASKS.cpf.textMask}" [ngStyle]="{'border-color': !cpfCheck ? '#dd443e' : '#3cb42c'}" (change)="cpfUnico(cpfNovo)">
            </div>
            <div class="col col-2">
                <label>Nascimento:</label>
                <input type="text" class="form-control" [(ngModel)]="nascimentoNovo" maxlength="10" onkeyup="var v = this.value;if(v.match(/^\d{2}$/) !== null){this.value = v + '/';}else if(v.match(/^\d{2}\/\d{2}$/) !== null){this.value = v + '/';}" [ngStyle]="{'border-color': nascimentoNovo.length != 10 ? '#dd443e' : '#3cb42c'}">
            </div>
            <div class="col col-4">
                <label>Nome Completo:</label>
                <input type="text" class="form-control" [(ngModel)]="nomeNovo" oninput="this.value = this.value.toUpperCase()" [ngStyle]="{'border-color': !nomeNovo ? '#dd443e' : '#3cb42c'}">
            </div>
            <div class="col col-3">
                <label>Estado Civil:</label>
                <select class="form-control" [(ngModel)]="estadoCivilNovo" [ngStyle]="{'border-color': !estadoCivilNovo ? '#dd443e' : '#3cb42c'}">
                    <option value="SOLTEIRO">SOLTEIRO</option>
                    <option value="CASADO">CASADO</option>
                    <option value="DIVORCIADO">DIVORCIADO</option>
                    <option value="VIÚVO">VIÚVO</option>
                    <option value="SEPARADO">SEPARADO</option>
                    <option value="UNIÃO ESTÁVEL">UNIÃO ESTÁVEL</option>
                </select>
            </div>
        </div>
        <br>
        <div [hidden]="nascimentoNovo.length != 10 || !cpfCheck || !nomeNovo || !estadoCivilNovo">
           <div class="row align-items-center">
                <div class="col col-2">
                    <label>Sexo:</label>
                    <select class="form-control" [(ngModel)]="sexoNovo" [ngStyle]="{'border-color': !sexoNovo ? '#dd443e' : '#3cb42c'}">
                        <option value="MASCULINO">MASCULINO</option>
                        <option value="FEMININO">FEMININO</option>
                    </select>
                </div>
                <div class="col col-3">
                    <label>Telefone:</label>
                    <input type="text" class="form-control" [textMask]="{mask: MASKS.telefone.textMaskFunction}" [(ngModel)]="telefoneNovo">
                </div>
                <div class="col col-3">
                    <label>Celular:</label>
                    <input type="text" class="form-control" [textMask]="{mask: MASKS.telefone.textMaskFunction}" [(ngModel)]="celularNovo" [ngStyle]="{'border-color': !celularNovo ? '#dd443e' : '#3cb42c'}">
                </div>
                <div class="col col-3">
                    <br>
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" [(ngModel)]="whatsappNovo" id="whatsNovo">
                        <label class="form-check-label" for="whatsNovo">&nbsp;&nbsp;Possui Whatsapp</label>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col col-2">
                    <label>CEP:</label>
                    <input type="text" cep [textMask]="{mask: MASKS.cep.textMask}" class="form-control" [(ngModel)]="cepNovo" (keyup)="buscarCepNovo(cepNovo)">
                </div>
                <div class="col col-5">
                    <label>Rua:</label>
                    <input type="text" class="form-control" [(ngModel)]="ruaNovo" oninput="this.value = this.value.toUpperCase()" [ngStyle]="{'border-color': !ruaNovo ? '#dd443e' : '#3cb42c'}">
                </div>
                <div class="col col-2">
                    <label>Número:</label>
                    <input type="text" class="form-control" [(ngModel)]="numeroNovo" [ngStyle]="{'border-color': !numeroNovo ? '#dd443e' : '#3cb42c'}">
                </div>
                <div class="col col-3">
                    <label>Bairro:</label>
                    <input type="text" class="form-control" [(ngModel)]="bairroNovo" oninput="this.value = this.value.toUpperCase()" [ngStyle]="{'border-color': !bairroNovo ? '#dd443e' : '#3cb42c'}">
                </div>
            </div>
            <div class="row">
                <div class="col col-2">
                    <label>Complemento:</label>
                    <input type="text" class="form-control" [(ngModel)]="complementoNovo" oninput="this.value = this.value.toUpperCase()">
                </div>
                <div class="col col-3">
                    <label>Município:</label>
                    <input type="text" class="form-control" [(ngModel)]="municipioNovo" oninput="this.value = this.value.toUpperCase()" [ngStyle]="{'border-color': !municipioNovo ? '#dd443e' : '#3cb42c'}">
                </div>
                <div class="col col-1">
                    <label>UF:</label>
                    <input type="text" class="form-control" [(ngModel)]="estadoNovo" oninput="this.value = this.value.toUpperCase()" [ngStyle]="{'border-color': !estadoNovo ? '#dd443e' : '#3cb42c'}">
                </div>
                <div class="col col-3">
                    <label>Função:</label>
                    <input type="text" class="form-control" [(ngModel)]="funcaoNovo" oninput="this.value = this.value.toUpperCase()">
                </div>
                <div class="col col-3">
                    <label>E-mail:</label>
                    <input type="text" class="form-control" [(ngModel)]="emailNovo" oninput="this.value = this.value.toUpperCase()">
                </div>
            </div>
        </div>
        <div [hidden]="!nomeNovo || !estadoCivilNovo || !ruaNovo || !numeroNovo || !bairroNovo || !municipioNovo || !estadoNovo">
        <br>
        <label>DADOS CADASTRAIS DOS DEPENDENTES</label>
        <br>
        <form [formGroup]="dependentes">
            <div class="row" formArrayName="dependente">
                <div class="form-group col-3" style="text-align: left;" [hidden]="listaDeDependentes?.length > 0">
                    <br>
                  <button class="btn btn-outline-success btn-sm" (click)="addDependente()">
                    <fa-icon [icon]="['fas', 'plus-circle']" size="1x"></fa-icon> ADICIONAR DEPENDENTE
                  </button>
                </div>
                <div class="form-group col-9" [hidden]="listaDeDependentes?.length > 0">
                    &nbsp;
                </div>
              <div class="col col-12" *ngFor="let dep of dependentesFormGroup.controls; let i = index;">
                <div [formGroupName]="i" class="row align-items-center">
                        <div class="form-group col-2">
                            <label>Tipo:</label>
                            <select class="form-control" formControlName="tipo" id="tipoPjNovo{{i}}" style="text-align: left;" (change)="tipoDependentePj(i)" [ngStyle]="{'border-color': (dependentes.controls.dependente.value[i].nome == '' || dependentes.controls.dependente.value[i].nome == null || dependentes.controls.dependente.value[i].tipo == '' || dependentes.controls.dependente.value[i].tipo == null) ? '#dd443e' : null}">
                                <option value=""></option>
                                <option value="CÔNJUGE">CÔNJUGE</option>
                                <option value="FILHO">FILHO</option>
                                <option value="FILHA">FILHA</option>
                            </select>
                        </div>
                        <div class="form-group col-3">
                            <label>Nome:</label>
                            <input type="text" class="form-control" formControlName="nome" oninput="this.value = this.value.toUpperCase()" [ngStyle]="{'border-color': (dependentes.controls.dependente.value[i].nome == '' || dependentes.controls.dependente.value[i].nome == null || dependentes.controls.dependente.value[i].tipo == '' || dependentes.controls.dependente.value[i].tipo == null) ? '#dd443e' : null}">
                        </div>
                        <div class="form-group col-2">
                            <label>Data Nascimento:</label>
                            <input type="text" class="form-control" formControlName="nascimento" (change)="verificaNascimentoPj(i)" id="nascimentoPjNovo{{i}}" maxlength="10" onkeyup="var v = this.value;if(v.match(/^\d{2}$/) !== null){this.value = v + '/';}else if(v.match(/^\d{2}\/\d{2}$/) !== null){this.value = v + '/';}" [ngStyle]="{'border-color': dependentes.controls.dependente.value[i].asf == 'FUNERAL CONTRATADO' && ((dependentes.controls.dependente.value[i].cpf == '' || dependentes.controls.dependente.value[i].cpf == null) || (dependentes.controls.dependente.value[i].nascimento == '' || dependentes.controls.dependente.value[i].nascimento == null) || (dependentes.controls.dependente.value[i].tipo == '' || dependentes.controls.dependente.value[i].tipo == null)) ? '#dd443e' : null}">
                        </div> 
                        <div class="form-group col-2">
                            <label>CPF:</label>
                            <input type="text" class="form-control" formControlName="cpf" cpf  id="cpfPjNovo{{i}}" [textMask]="{mask: MASKS.cpf.textMask}" [ngStyle]="{'border-color': dependentes.controls.dependente.value[i].asf == 'FUNERAL CONTRATADO' && ((dependentes.controls.dependente.value[i].cpf == '' || dependentes.controls.dependente.value[i].cpf == null) || (dependentes.controls.dependente.value[i].nascimento == '' || dependentes.controls.dependente.value[i].nascimento == null)) ? '#dd443e' : null}">
                        </div> 
                        <div class="form-group col-2">
                            <label>ASF:</label>
                            <select class="form-control" formControlName="asf" id="asfPjNovo{{i}}" disabled>
                                <option value="FUNERAL AUTOMÁTICO ATÉ 18 ANOS">FUNERAL AUTOMÁTICO ATÉ 18 ANOS</option>
                                <option value="FUNERAL AUTOMÁTICO VITALÍCIO">FUNERAL AUTOMÁTICO VITALÍCIO</option>
                                <option value="NÃO HÁ FUNERAL CONTRATADO">NÃO HÁ FUNERAL CONTRATADO</option>
                                <option value="FUNERAL CONTRATADO">FUNERAL CONTRATADO</option>
                            </select>
                        </div> 
                        <div class="form-group col-1" style="text-align: right;">
                            <br>
                          <button class="btn btn-outline-danger btn-sm" (click)="removeDependente(i)">
                            <fa-icon [icon]="['fas', 'minus-circle']" size="1x"></fa-icon>
                          </button>&nbsp;
                          <button class="btn btn-outline-success btn-sm" (click)="addDependente()" [disabled]="((dependentes.controls.dependente.value[i].tipo == '' || dependentes.controls.dependente.value[i].tipo == null) || (dependentes.controls.dependente.value[i].nome == '' || dependentes.controls.dependente.value[i].nome == null)) || (dependentes.controls.dependente.value[i].asf == 'FUNERAL CONTRATADO' && ((dependentes.controls.dependente.value[i].nascimento == '' || dependentes.controls.dependente.value[i].nascimento == null) || ((dependentes.controls.dependente.value[i].cpf == '' || dependentes.controls.dependente.value[i].cpf == null))))">
                            <fa-icon [icon]="['fas', 'plus-circle']" size="1x"></fa-icon>
                          </button>
                        </div>
                    </div>
                 </div>
            </div>   
     </form>
    <br>
    <label>DEPENDENTES EXTRAS</label>
        <form [formGroup]="dependentesExtra">
            <div class="row" formArrayName="dependenteExtra">
                <div class="form-group col-3" style="text-align: left;" [hidden]="listaDeDependentesExtra?.length > 0">
                    <br>
                  <button class="btn btn-outline-success btn-sm" (click)="addDependenteExtra()">
                    <fa-icon [icon]="['fas', 'plus-circle']" size="1x"></fa-icon> ADICIONAR DEPENDENTE EXTRA
                  </button>
                </div>
                <div class="form-group col-9" [hidden]="listaDeDependentesExtra?.length > 0">
                    &nbsp;
                </div>
              <div class="col col-12" *ngFor="let dependenteExtra of dependentesExtraFormGroup.controls; let i = index;">
                    <div [formGroupName]="i" class="row align-items-center">
                        <div class="form-group col-2">
                            <label>Tipo:</label>
                            <select class="form-control" formControlName="tipo" id="tipoPjNovo{{100+i}}" style="text-align: left;" [ngStyle]="{'border-color': (dependentesExtra.controls.dependenteExtra.value[i].nome == '' || dependentesExtra.controls.dependenteExtra.value[i].nome == null || dependentesExtra.controls.dependenteExtra.value[i].tipo == '' || dependentesExtra.controls.dependenteExtra.value[i].tipo == null) ? '#dd443e' : null}">
                                <option value=""></option>
                                <option value="MÃE">MÃE</option>
                                <option value="PAI">PAI</option>
                                <option value="SOGRO">SOGRO</option>
                                <option value="SOGRA">SOGRA</option>
                            </select>
                        </div>
                        <div class="form-group col-3">
                            <label>Nome:</label>
                            <input type="text" class="form-control" formControlName="nome" oninput="this.value = this.value.toUpperCase()" [ngStyle]="{'border-color': (dependentesExtra.controls.dependenteExtra.value[i].nome == '' || dependentesExtra.controls.dependenteExtra.value[i].nome == null || dependentesExtra.controls.dependenteExtra.value[i].tipo == '' || dependentesExtra.controls.dependenteExtra.value[i].tipo == null) ? '#dd443e' : null}">
                        </div>
                        <div class="form-group col-2">
                            <label>Data Nascimento:</label>
                            <input type="text" class="form-control" formControlName="nascimento" id="nascimentoPjNovo{{100+i}}" maxlength="10" onkeyup="var v = this.value;if(v.match(/^\d{2}$/) !== null){this.value = v + '/';}else if(v.match(/^\d{2}\/\d{2}$/) !== null){this.value = v + '/';}" [ngStyle]="{'border-color': dependentesExtra.controls.dependenteExtra.value[i].asf == 'FUNERAL CONTRATADO' && ((dependentesExtra.controls.dependenteExtra.value[i].cpf == '' || dependentesExtra.controls.dependenteExtra.value[i].cpf == null) || (dependentesExtra.controls.dependenteExtra.value[i].nascimento == '' || dependentesExtra.controls.dependenteExtra.value[i].nascimento == null) || (dependentesExtra.controls.dependenteExtra.value[i].tipo == '' || dependentesExtra.controls.dependenteExtra.value[i].tipo == null)) ? '#dd443e' : null}">
                        </div> 
                        <div class="form-group col-2">
                            <label>CPF:</label>
                            <input type="text" class="form-control" formControlName="cpf" cpf  id="cpfPjNovo{{100+i}}" [textMask]="{mask: MASKS.cpf.textMask}" [ngStyle]="{'border-color': dependentesExtra.controls.dependenteExtra.value[i].asf == 'FUNERAL CONTRATADO' && ((dependentesExtra.controls.dependenteExtra.value[i].cpf == '' || dependentesExtra.controls.dependenteExtra.value[i].cpf == null) || (dependentesExtra.controls.dependenteExtra.value[i].nascimento == '' || dependentesExtra.controls.dependenteExtra.value[i].nascimento == null)) ? '#dd443e' : null}">
                        </div> 
                        <div class="form-group col-2" style="display: none;">
                            <label>ASF:</label>
                            <select class="form-control" formControlName="asf" id="asfPjNovo{{100+i}}">
                                <option value=""></option>
                                <option value="NÃO HÁ FUNERAL CONTRATADO" selected>NÃO HÁ FUNERAL CONTRATADO</option>
                                <option value="FUNERAL CONTRATADO">FUNERAL CONTRATADO</option>
                            </select>
                        </div> 
                        <div class="form-group col-1" style="text-align: right;">
                            <br>
                          <button class="btn btn-outline-danger btn-sm" (click)="removeDependenteExtra(i)">
                            <fa-icon [icon]="['fas', 'minus-circle']" size="1x"></fa-icon>
                          </button>&nbsp;
                          <button class="btn btn-outline-success btn-sm" (click)="addDependenteExtra()" [disabled]="((dependentesExtra.controls.dependenteExtra.value[i].tipo == '' || dependentesExtra.controls.dependenteExtra.value[i].tipo == null) || (dependentesExtra.controls.dependenteExtra.value[i].nome == '' || dependentesExtra.controls.dependenteExtra.value[i].nome == null)) || dependentesExtra.controls.dependenteExtra.value[i].asf == 'FUNERAL CONTRATADO' && ((dependentesExtra.controls.dependenteExtra.value[i].cpf == '' || dependentesExtra.controls.dependenteExtra.value[i].cpf == null) || (dependentesExtra.controls.dependenteExtra.value[i].nascimento == '' || dependentesExtra.controls.dependenteExtra.value[i].nascimento == null))">
                            <fa-icon [icon]="['fas', 'plus-circle']" size="1x"></fa-icon>
                          </button>
                        </div>
                    </div>
                </div>
            </div>   
         </form>
        <br>
    <hr>
        <label>Proponente Possui Problemas Pré Existentes?</label>
        <div style="padding:10px">
            <div class="row">
                <div class="col col-2">
                    <br>
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="doencaSimNovo" (change)="selectDoenca($event)">
                        <label class="form-check-label" for="doencaSimNovo" style="margin-left:10px">SIM</label>
                    </div>
                </div>
                <div class="col col-2">
                    <br>
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="doencaNaoNovo" (change)="selectDoenca($event)">
                        <label class="form-check-label" for="doencaNaoNovo" style="margin-left:10px">NÃO</label>
                    </div>
                </div>
                <div class="col col-8" *ngIf="doencaNovo == 'SIM'">
                    <label>Especificar:</label>
                    <input type="text" class="form-control" [(ngModel)]="especificarDoencaNovo" oninput="this.value = this.value.toUpperCase()">
                </div>
            </div>     
        </div>
<div>
 <br><br> 
 <div class="row">
    <div class="col" style="text-align: center; color:#5e5e5e">
        <h5>Clique ou Arraste para Enviar Documentos ou Fotos</h5>
    </div>
</div>
<div class="row">
    <div class="col">
        <div class="uploadfilecontainer" (click)="fileInputNovo.click()" appDragDrop (onFileDropped)="onChangeCadastro($event)">
            <input hidden type="file" #fileInputNovo (change)="onChangeClickCadastro($event)" multiple>
        </div>
            <div class="files-list" *ngFor="let file of files;let i= index">
                <div class="row align-items-center" style="width: 100%;">
                    <div class="col col-11">
                        <p>{{ file }}</p>
                    </div>
                    <div class="col col-1 del" (click)="deleteDocFiles(i)">
                        X
                    </div>
                </div>
            </div> 
    </div>
</div>
 <br><br>
<div class="row" style="padding:10px">
    <div class="col col-12" style="text-align: center;">
        <button class="btn btn-success btn-block" (click)="cadastrarAssociadoPj()" [disabled]="!origemNovo || !cpfNovo || !nascimentoNovo || !estadoCivilNovo || !ruaNovo || !numeroNovo || !bairroNovo || !municipioNovo || !estadoNovo">CADASTRAR ASSOCIADO</button>
    </div>
</div>
</div>
<div style="height: 3em;"></div>
        </div>
    </div>     
</div>
<div class="modalSenha" id="senhaModal">
    <div class="box">
      <h2 style="color:brown">ATENÇÃO!</h2><br>
      <h4 style="line-height: 1.8em;">PARA ALTERAR A SITUAÇÃO DA EMPRESA É NECESSÁRIO VALIDAR COM O CÓDIGO TOKEN A SEGUIR, POIS TODOS OS ASSOCIADOS VINCULADOS A ESTA EMPRESA SERÃO ALTERADOS COM A MESMA SITUAÇÃO!</h4><br>
      <p style="font-size: 3em;"><strong>{{randomico}}</strong></p>
     <input type="text" class="form-control" style="font-weight: bold;font-size:1.5em; text-align: center;border-color:#000" [(ngModel)]="digitado">
      <br>
      <div class="row" style="text-align: center;">
          <div class="col">
            <button class="btn btn-dark btn-lg" (click)="closeModalSenha()">FECHAR</button>
          </div>
          <div class="col">
            <button class="btn btn-danger btn-lg" (click)="editarEmpresa()" [disabled]="randomico != digitado">CONTINUAR</button>
          </div>
      </div>
    </div>
  </div>
    <div class="row  align-items-center header">
        <div class="col titulo" style="text-align: left;">
            EDITAR EMPRESA
        </div>
        <div class="col col-3" style="text-align: right;">
            <button class="btn btn-sm btn-outline-light" (click)="closeModalEditar()">
                FECHAR 
            </button>
        </div>
    </div>
        <div style="padding:50px">
            <tabset #staticTabs>
                <tab heading="DADOS EMPRESA" style="padding:10px;">
                    <div class="row">
                        <div class="col col-3">
                            <label>Razão Social</label>
                            <input type="text" class="form-control" [(ngModel)]="razaoEmpresaEditar" [ngStyle]="{'border-color': !razaoEmpresaEditar ? '#dd443e' : '#3cb42c'}" oninput="this.value = this.value.toUpperCase()">
                        </div>
                        <div class="col col-3">
                            <label>Nome Fantasia</label>
                            <input type="text" class="form-control" [(ngModel)]="fantasiaEmpresaEditar" [ngStyle]="{'border-color': !fantasiaEmpresaEditar ? '#dd443e' : '#3cb42c'}" oninput="this.value = this.value.toUpperCase()">
                        </div>
                        <div class="col col-3">
                            <label>CNPJ</label>
                            <input type="text" class="form-control" cnpj [textMask]="{mask: MASKS.cnpj.textMask}" [(ngModel)]="cnpjEditar" [ngStyle]="{'border-color': !cnpjEditar ? '#dd443e' : '#3cb42c'}">
                        </div>
                        <div class="col col-3">
                            <label>STATUS</label>
                            <select class="form-control" [(ngModel)]="situacaoEditar" [disabled]="situacaoEditar == 'CANCELADO'">
                                <option value="ATIVO">ATIVO</option>
                                <option value="INADIMPLENTE I">INADIMPLENTE I</option>
                                <option value="INADIMPLENTE II">INADIMPLENTE II</option>
                                <option value="CANCELADO">CANCELADO</option>
                            </select>              
                        </div>
                    </div>
                    <br>
                    <div class="row">
                        <div class="col col-2">
                            <label>CEP:</label>
                            <input type="text" cep [textMask]="{mask: MASKS.cep.textMask}" class="form-control" [(ngModel)]="cepEditar" (keyup)="buscarCepEditar(cepEditar)">
                        </div>
                        <div class="col col-5">
                            <label>Rua:</label>
                            <input type="text" class="form-control" [(ngModel)]="ruaEditar" oninput="this.value = this.value.toUpperCase()" [ngStyle]="{'border-color': !ruaEditar ? '#dd443e' : '#3cb42c'}">
                        </div>
                        <div class="col col-2">
                            <label>Número:</label>
                            <input type="text" class="form-control" [(ngModel)]="numeroEditar" [ngStyle]="{'border-color': !numeroEditar ? '#dd443e' : '#3cb42c'}">
                        </div>
                        <div class="col col-3">
                            <label>Bairro:</label>
                            <input type="text" class="form-control" [(ngModel)]="bairroEditar" oninput="this.value = this.value.toUpperCase()" [ngStyle]="{'border-color': !bairroEditar ? '#dd443e' : '#3cb42c'}">
                        </div>
                    </div>
                    <br>
                    <div class="row">
                        <div class="col col-3">
                            <label>Complemento:</label>
                            <input type="text" class="form-control" [(ngModel)]="complementoEditar" oninput="this.value = this.value.toUpperCase()">
                        </div>
                        <div class="col col-4">
                            <label>Município:</label>
                            <input type="text" class="form-control" [(ngModel)]="municipioEditar" oninput="this.value = this.value.toUpperCase()" [ngStyle]="{'border-color': !municipioEditar ? '#dd443e' : '#3cb42c'}">
                        </div>
                        <div class="col col-1">
                            <label>UF:</label>
                            <input type="text" class="form-control" [(ngModel)]="estadoEditar" oninput="this.value = this.value.toUpperCase()" [ngStyle]="{'border-color': !estadoEditar ? '#dd443e' : '#3cb42c'}">
                        </div>
                        <div class="col col-4">
                            <label>Atividade da Empresa:</label>
                            <input type="text" class="form-control" [(ngModel)]="atividadeEmpresaEditar" oninput="this.value = this.value.toUpperCase()">
                        </div>
                    </div>
                    <br>
                    <div class="row">
                        <div class="col col-3">
                            <label>Telefone:</label>
                            <input type="text" class="form-control" [textMask]="{mask: MASKS.telefone.textMaskFunction}" [(ngModel)]="telefoneEditar" [ngStyle]="{'border-color': !telefoneEditar ? '#dd443e' : '#3cb42c'}">
                        </div>
                        <div class="col col-4">
                            <label>Responsável:</label>
                            <input type="text" class="form-control" [(ngModel)]="nomeContatoEditar" oninput="this.value = this.value.toUpperCase()" [ngStyle]="{'border-color': !nomeContatoEditar ? '#dd443e' : '#3cb42c'}">
                        </div>
                        <div class="col col-5">
                            <label>Cargo:</label>
                            <input type="text" class="form-control" [(ngModel)]="cargoResponsavelEditar" oninput="this.value = this.value.toUpperCase()" [ngStyle]="{'border-color': !cargoResponsavelEditar ? '#dd443e' : '#3cb42c'}">
                        </div>
                    </div>
                    <br>
                    <div class="row">
                        <div class="col col-4">
                            <label>E-mail Principal:</label>
                            <input type="text" class="form-control" [(ngModel)]="emailEditar" [ngStyle]="{'border-color': !emailEditar ? '#dd443e' : '#3cb42c'}">
                        </div>
                        <div class="col col-4">
                            <label>E-mail 2:</label>
                            <input type="text" class="form-control" [(ngModel)]="email2Editar">
                        </div>
                        <div class="col col-4">
                            <label>E-mail 3:</label>
                            <input type="text" class="form-control" [(ngModel)]="email3Editar">
                        </div>
                    </div>
                    <br>
                    <div class="row">
                        <div class="col col-4">
                            <label>Seguradora:</label>
                            <input type="text" class="form-control" [(ngModel)]="seguradoraEditar" oninput="this.value = this.value.toUpperCase()" [ngStyle]="{'border-color': !seguradoraEditar ? '#dd443e' : '#3cb42c'}">
                        </div>
                        <div class="col col-2">
                            <label>Inicio da Vigência:</label>
                            <input type="date" class="form-control" [(ngModel)]="dataAdesaoEditar" [ngStyle]="{'border-color': !dataAdesaoEditar ? '#dd443e' : '#3cb42c'}">
                        </div>
                        <div class="col col-3">
                            <label>Data 1º Vencimento:</label>
                            <input type="date" class="form-control" [(ngModel)]="dataVencimentoEditar" (change)="calcularFaturamentoEditar(dataVencimentoEditar)" [ngStyle]="{'border-color': !dataVencimentoEditar ? '#dd443e' : '#3cb42c'}">
                        </div>
                        <div class="col col-3">
                            <label>Limite de Faturamento:</label>
                            <input type="date" class="form-control" [(ngModel)]="dataFaturamentoEditar" [ngStyle]="{'border-color': !dataFaturamentoEditar ? '#dd443e' : '#3cb42c'}">
                        </div>
                    </div>
                    <br>
                    <div class="row">
                        <div class="col col-6">
                            <label>Vendedor</label>
                            <select class="form-control" [(ngModel)]="vendedorEditar" (change)="selectVendedorEditar(vendedorEditar)" [ngStyle]="{'border-color': !vendedorEditar ? '#dd443e' : '#3cb42c'}">
                                <option value=""></option>
                                <option *ngFor="let vendedor of vendedores" [value]="[vendedor.nome,vendedor.fantasiaEmpresa]">{{vendedor.nome}} {{vendedor.fantasiaEmpresa}}</option>
                            </select>
                        </div>
                        <div class="col col-3">
                            <label>Desconto Faturamento:</label>
                            <div class="input-group mb-3">
                                <input type="numer" class="form-control" [(ngModel)]="descontoEmpresaEditar">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon1">%</span>
                                </div>
                            </div>
                        </div>
                        <div class="col col-3">
                            <label>Comissão:</label>
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon12">R$</span>
                                </div>
                                <input type="number" min=0 class="form-control" [(ngModel)]="valorComissaoEditar">
                            </div>
                        </div>
                    </div>
                    <br>
                    <label>DADOS DO PLANO:</label>
                    <br>
                    <div class="row">
                        <div class="col">
                            <label>Plano:</label>
                            <select class="form-control" [(ngModel)]="planoEditar" (change)="selectPlanoEditar(planoEditar)" [ngStyle]="{'border-color': !planoEditar ? '#dd443e' : '#3cb42c'}">
                                <option value=""></option>
                                <option *ngFor="let planEditar of planosEmpresariais" [value]="planEditar.nome">{{planEditar.nome}}</option>
                                <option value="PERSONALIZADO">PERSONALIZADO</option>
                            </select>
                        </div>
                    </div>
                    <br>
                    <div class="row" *ngIf="planoEditar == 'PERSONALIZADO'">
                        <div class="col">
                            <label>Coberturas do Seguro:</label>
                            <form [formGroup]="capitalSeguradoEditar">
                                <div class="row" formArrayName="capitalEditar">
                                    <div class="col col-12" style="font-size: 0.85em" *ngFor="let capitalEditar of capitalSeguradoFormGroupEditar.controls; let i = index;">
                                        <div [formGroupName]="i" class="row">
                                            <div class="form-group col-5">
                                                <select class="form-control" formControlName="cobertura" placeholder="Nome Cobertura" [ngStyle]="{'border-color': capitalSeguradoEditar.controls.capitalEditar.value[i].cobertura == '' || capitalSeguradoEditar.controls.capitalEditar.value[i].cobertura == null ? '#dd443e' : null}">
                                                    <option value="ASSISTÊNCIA FUNERAL">ASSISTÊNCIA FUNERAL</option>
                                                    <option value="MORTE ACIDENTAL">MORTE ACIDENTAL</option>
                                                    <option value="MORTE NATURAL">MORTE NATURAL</option>
                                                </select>
                                            </div>
                                            <div class="form-group col-4">
                                                <input type="number" class="form-control" formControlName="capital" placeholder="Capital Segurado" min=0 [ngStyle]="{'border-color': capitalSeguradoEditar.controls.capitalEditar.value[i].capital < 0 || capitalSeguradoEditar.controls.capitalEditar.value[i].capital == null ? '#dd443e' : null}">
                                            </div>
                                            <div class="form-group col-3 text-right">
                                                <button class="btn btn-danger btn-sm" type="button" (click)="removeCapitalSeguradoEditar(i)" *ngIf="this.capitalSeguradoListEditar?.length > 1">
                                                <fa-icon [icon]="['fas', 'minus-circle']" size="1x"></fa-icon>
                                                </button>&nbsp;
                                                <button class="btn btn-success btn-sm" type="button" (click)="addCapitalSeguradoEditar()" [disabled]="capitalSeguradoEditar.invalid">
                                                <fa-icon [icon]="['fas', 'plus-circle']" size="1x"></fa-icon>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>   
                            </form>
                        </div>
                    </div>
                    <div class="row" *ngIf="planoEditar == 'PERSONALIZADO'">
                        <div class="col col-4">
                            <label>Funeral Cônjuge:</label>
                            <select class="form-control" [(ngModel)]="funeralConjugeEditar" (change)="setValorConjugeEditar(funeralConjugeEditar)">
                                <option value="AUTOMATICO">AUTOMÁTICO SEM CUSTO</option>
                                <option value="SIM">SIM</option>
                                <option value="NÃO">NÃO</option>
                            </select>
                        </div>
                        <div class="col col-2">
                            <label>Valor:</label>
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                  <span class="input-group-text" id="basic-addon2">R$</span>
                                </div>
                                <input type="number" min=0 class="form-control" [(ngModel)]="valorFuneralConjugeEditar" [disabled]="funeralConjugeEditar != 'SIM'">
                            </div>
                        </div>
                        <div class="col col-4">
                            <label>Funeral Filhos Menor 18:</label>
                            <select class="form-control" [(ngModel)]="funeralFilhosMenorEditar" (change)="setValorMenorEditar(funeralFilhosMenorEditar)">
                                <option value="AUTOMATICO">AUTOMÁTICO SEM CUSTO</option>
                                <option value="SIM">SIM</option>
                                <option value="NÃO">NÃO</option>
                            </select>
                        </div>
                        <div class="col col-2">
                            <label>Valor:</label>
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                  <span class="input-group-text" id="basic-addon3">R$</span>
                                </div>
                                <input type="number" min=0 class="form-control" [(ngModel)]="valorFuneralFilhosMenorEditar" [disabled]="funeralFilhosMenorEditar != 'SIM'">
                            </div>
                        </div>
                    </div>
                   <!--  <div class="row" *ngIf="planoEditar == 'PERSONALIZADO'">
                        <div class="col col-4">
                            <label>Funeral Filhos Maior 18:</label>
                            <select class="form-control" [(ngModel)]="funeralFilhosMaiorEditar" (change)="setValorMaiorEditar(funeralFilhosMaiorEditar)">
                                <option value="AUTOMATICO">AUTOMÁTICO SEM CUSTO</option>
                                <option value="SIM">SIM</option>
                                <option value="NÃO">NÃO</option>
                            </select>
                        </div>
                        <div class="col col-2">
                            <label>Valor:</label>
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                  <span class="input-group-text" id="basic-addon1">R$</span>
                                </div>
                                <input type="number" min=0 class="form-control" [(ngModel)]="valorFuneralFilhosMaiorEditar" [disabled]="funeralFilhosMaiorEditar != 'SIM'">
                            </div>
                        </div>
                        <div class="col col-4">
                            <label>Funeral Extra:</label>
                            <select class="form-control" [(ngModel)]="funeralExtraEditar" (change)="setValorExtraEditar(funeralExtraEditar)">
                                <option value="SIM">SIM</option>
                                <option value="NÃO">NÃO</option>
                            </select>
                        </div>            
                        <div class="col col-2">
                            <label>Valor:</label>
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                  <span class="input-group-text" id="basic-addon1">R$</span>
                                </div>
                                <input type="number" min=0 class="form-control" [(ngModel)]="valorFuneralExtraEditar" [disabled]="funeralExtraEditar != 'SIM'">
                            </div>
                        </div>
                    </div> -->
                    <div class="row" *ngIf="planoEditar == 'PERSONALIZADO'">
                        <div class="col col-4">
                            <label>Valor do Plano:</label>
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                  <span class="input-group-text" id="basic-addon4">R$</span>
                                </div>
                                <input type="number" min=0 class="form-control" [(ngModel)]="valorPlanoEditar">
                            </div>
                        </div>
                        <div class="col col-4">
                            <label>Valor do Seguro:</label>
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                  <span class="input-group-text" id="basic-addon5">R$</span>
                                </div>
                                <input type="number" min=0 class="form-control" [(ngModel)]="valorSeguroEditar">
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-md-center" *ngIf="db?.usuario?.permissoes['empresas'] == 'EDITAR'">
                        <div class="col col-3" *ngFor="let pdf of pdfs;" style="margin: 10px; background-color:#ce2626; color:white; height: 250px; width:100%; text-align: center; border-radius: 10px;">
                            <div class="delButton" name="delButton">
                                <button class="btn btn-dark btn-sm" (click)="delDoc(pdf.url)"><strong>X</strong></button>
                            </div>
                            <div (click)="openModalPdfViewer(pdf.url)" style="position: relative;left:50%;top:50%;transform: translate(-50%,-50%);cursor:pointer">
                                <fa-icon [icon]="['fas', 'file-pdf']" style="font-size: 5em;"></fa-icon>
                                    <br>DOCUMENTO PDF
                            </div>
                        </div>
                        <div class="col col-3 img-container" *ngFor="let img of images; let i = index" style="margin: 10px; text-align: center;">
                            <div class="delButton" name="delButton">
                                <button class="btn btn-danger btn-sm" (click)="delDoc(img.url)"><strong>X</strong></button>
                            </div>
                            <img [src]="img.url" (click)="openModalLightBox(img.url, i)" style="border-radius: 10px; height: 250px; width:100%;" crossorigin="anonymous">
                        </div>
                    </div>
                    <br>
                    <div class="row" *ngIf="db?.usuario?.permissoes['empresas'] == 'EDITAR'">
                        <div class="col">
                            <div class="uploadfilecontainer" (click)="fileEmpresaDocEditar.click()" appDragDrop (onFileDropped)="onChangeEmpresaEditar($event)">
                                <input hidden type="file" #fileEmpresaDocEditar (change)="onChangeClickEmpresaEditar($event)" multiple>
                            </div>
                            <div class="row align-items-center">
                                <div class="col col-1">
                                    &nbsp;
                                </div>
                                <div class="col">
                                    <div class="files-list" *ngFor="let fileEditar of filesEditar; let i= index">
                                        <p>{{ fileEditar }}</p>
                                    </div> 
                                </div>
                                <div class="col col-2" [hidden]="filesEditar.length < 1">
                                    <button class="btn btn-primary btn-block" (click)="anexarDoc()">ANEXAR</button>
                                </div>
                                <div class="col col-1">
                                    &nbsp;
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" style="margin-top: 80px;">
                        <div class="col col-4">
                            &nbsp;
                        </div>
                        <div class="col col-2">
                             <button class="btn btn-danger btn-block" (click)="closeModalEditar()">VOLTAR</button>
                        </div>
                        <div class="col col-2">
                             <button class="btn btn-success btn-block" (click)="editEmpresaModal()" [disabled]="!razaoEmpresaEditar || !fantasiaEmpresaEditar || !cnpjEditar || !ruaEditar || !numeroEditar || !bairroEditar || !municipioEditar || !estadoEditar || !telefoneEditar || !nomeContatoEditar || !cargoResponsavelEditar || !emailEditar || !seguradoraEditar || !dataAdesaoEditar || !dataVencimentoEditar || !dataFaturamentoEditar || !planoEditar || planoEditar == 'PERSONALIZADO' && (!funeralConjugeEditar || !funeralFilhosMenorEditar || !funeralFilhosMaiorEditar || !funeralExtraEditar || !valorPlanoEditar || !valorSeguroEditar || capitalSeguradoListEditar?.length < 1)">SALVAR</button>
                        </div>
                        <div class="col col-4">
                             &nbsp;
                         </div>
                    </div>
                </tab>
                <tab heading="ASSOCIADOS" style="padding:10px">
                    <div class="row" style="text-align: center;">
                        <div class="col">
                            <button class="btn btn-outline-danger" (click)="showAtivos()">ATIVOS</button>
                        </div>
                        <div class="col">
                            <button class="btn btn-outline-danger" (click)="showInadimplentes()">INADIMPLENTES</button>
                        </div>
                        <div class="col">
                            <button class="btn btn-outline-danger" (click)="showCancelados()">CANCELADOS</button>
                        </div>
                        <div class="col">
                            <button class="btn btn-outline-danger" (click)="showTodos()">MOSTRAR TODOS</button>
                        </div>
                        <div class="col col-3" style="text-align: right;">
                            <button class="btn btn-danger" (click)="openModalNovoPj()" [disabled]="empresa.situacao != 'ATIVO'">
                                CADASTRAR NOVO ASSOCIADO 
                            </button>
                        </div>
                    </div>
                    <br>
                    <table class="table table-striped" style="text-align: center;">
                        <thead class="thead-dark">
                            <tr>
                                <th></th>
                                <th>Plano</th>
                                <th>Adesão</th>
                                <th>Matricula</th>
                                <th>Associado</th>
                                <th>CPF</th>
                                <th>Contrato</th>
                                <th>Celular</th>
                                <th>Situação</th>
                                <th *ngIf="db?.usuario?.permissoes['associados']  == 'EDITAR' || db?.usuario?.permissoes['associados']  == 'VER'">Ver</th>
                                <th *ngIf="db?.usuario?.permissoes['associados']  == 'EDITAR'">VOLTAR</th>
                            </tr>
                        </thead>
                        <tbody *ngFor="let associado of associadosEmpresa;let i = index" >
                            <tr [ngStyle]="{'background-color': associado?.situacao == 'CANCELADO' ? '#c7444a' : null, 'color':associado?.situacao == 'CANCELADO' ?  '#ffffff' : '#000000'}">
                                <td>{{i + 1}}</td>
                                <td [ngStyle]="{'color': associado?.empresa == 'VIVA MAX' ? '#e64249' : '#00e0c2'}" style="font-weight: bold">{{ associado?.empresa }}</td>
                                <td>{{ associado?.dataAdesao | date: 'dd/MM/yyyy' }}</td>
                                <td>{{ associado?.matricula }}</td>
                                <td>{{ associado?.nome }}</td>
                                <td>{{ associado?.cpf }}</td>
                                <td>{{ associado?.subContrato }} <span *ngIf="associado?.planoFisicoCheck && !associado?.planoEmpresarialCheck">PLANO FISICO</span><span *ngIf="!associado?.planoFisicoCheck && associado?.planoEmpresarialCheck">PLANO EMPRESARIAL</span></td>
                                <td>{{ associado?.celular }}</td>
                                <td>{{ associado?.situacao }}</td>
                                <td *ngIf="db?.usuario?.permissoes['associados']  == 'EDITAR' || db?.usuario?.permissoes['associados']  == 'VER'">
                                    <button class="btn btn-outline-success" (click)="verAssociado(associado?.matricula)" *ngIf="associado?.empresa == 'VIVA MAX'">
                                        <fa-icon [icon]="['fas', 'sign-in-alt']"></fa-icon>
                                    </button>
                                    <button class="btn btn-outline-success" (click)="verAssociadoConecta(associado?.matricula)" *ngIf="associado?.empresa == 'CONECTA'">
                                        <fa-icon [icon]="['fas', 'sign-in-alt']"></fa-icon>
                                    </button>
                                </td>
                                <td *ngIf="db?.usuario?.permissoes['associados']  == 'EDITAR'">
                                    <button class="btn btn-outline-danger" (click)="cancelarAssociado(associado,i)" [disabled]="associado?.situacao == 'CANCELADO'">
                                        <fa-icon [icon]="['fas', 'user-slash']"></fa-icon>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </tab>
                <tab heading="FINANCEIRO" style="padding:10px">
                    <div style="height: 2em;"></div>
                    <div class="table-responsive">
                        <table class="table table-striped">
                            <thead>
                                <tr>
                                    <td>Vencimento</td>
                                    <td>Data Pagamento</td>
                                    <td>Valor</td>
                                    <td>Status</td>
                                    <td>Boleto</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let conta of financeiro">
                                    <td>{{conta?.vencimento  | date:'dd/MM/yyyy'}}</td>
                                    <td>{{conta?.dataPagamento  | date:'dd/MM/yyyy'}}</td>
                                    <td>{{conta?.valorTotal | currency: 'BRL'}}</td>
                                    <td style="font-weight:bold;" [ngStyle]="{'color': conta?.status == 'Pago' ? 'green' : 'red'}">{{conta?.status}}</td>
                                    <td>
                                        <button class="btn btn-danger" (click)="openModalPdfViewer(conta?.anexos[0]?.url)" *ngIf="conta?.status != 'Pago'">
                                            <fa-icon [icon]="['fas', 'download']"></fa-icon>
                                    </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </tab>
            </tabset>
        </div>
    </div>    
    <!-- FIM NOVO EDITAR -->
    <!-- MODAL NOVA EMPRESA -->
    <div class="modal" id="novaEmpresa">
        <div style="padding:50px">
            <div class="row">
                <div class="col col-3">
                    <label>Razão Social</label>
                    <input type="text" class="form-control" [(ngModel)]="razaoEmpresa" [ngStyle]="{'border-color': !razaoEmpresa ? '#dd443e' : '#3cb42c'}" oninput="this.value = this.value.toUpperCase()">
                </div>
                <div class="col col-3">
                    <label>Nome Fantasia</label>
                    <input type="text" class="form-control" [(ngModel)]="fantasiaEmpresa" [ngStyle]="{'border-color': !fantasiaEmpresa ? '#dd443e' : '#3cb42c'}" oninput="this.value = this.value.toUpperCase()">
                </div>
                <div class="col col-3">
                    <label>CNPJ</label>
                    <input type="text" class="form-control" cnpj [textMask]="{mask: MASKS.cnpj.textMask}" [(ngModel)]="cnpj" [ngStyle]="{'border-color': !cnpj ? '#dd443e' : '#3cb42c'}">
                </div>
                <div class="col col-3">
                    <label>Vendedor</label>
                    <select class="form-control" [(ngModel)]="vendedor" (change)="selectVendedorEmpresa(vendedor)" [ngStyle]="{'border-color': !vendedor ? '#dd443e' : '#3cb42c'}">
                        <option value=""></option>
                        <option *ngFor="let vendedor of vendedores" [value]="[vendedor.nome,vendedor.fantasiaEmpresa]">{{vendedor.nome}} {{vendedor.fantasiaEmpresa}}</option>
                    </select>
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col col-2">
                    <label>CEP:</label>
                    <input type="text" cep [textMask]="{mask: MASKS.cep.textMask}" class="form-control" [(ngModel)]="cep" (keyup)="buscarCep(cep)">
                </div>
                <div class="col col-5">
                    <label>Rua:</label>
                    <input type="text" class="form-control" [(ngModel)]="rua" oninput="this.value = this.value.toUpperCase()" [ngStyle]="{'border-color': !rua ? '#dd443e' : '#3cb42c'}">
                </div>
                <div class="col col-2">
                    <label>Número:</label>
                    <input type="text" class="form-control" [(ngModel)]="numero" [ngStyle]="{'border-color': !numero ? '#dd443e' : '#3cb42c'}">
                </div>
                <div class="col col-3">
                    <label>Bairro:</label>
                    <input type="text" class="form-control" [(ngModel)]="bairro" oninput="this.value = this.value.toUpperCase()" [ngStyle]="{'border-color': !bairro ? '#dd443e' : '#3cb42c'}">
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col col-3">
                    <label>Complemento:</label>
                    <input type="text" class="form-control" [(ngModel)]="complemento" oninput="this.value = this.value.toUpperCase()">
                </div>
                <div class="col col-4">
                    <label>Município:</label>
                    <input type="text" class="form-control" [(ngModel)]="municipio" oninput="this.value = this.value.toUpperCase()" [ngStyle]="{'border-color': !municipio ? '#dd443e' : '#3cb42c'}">
                </div>
                <div class="col col-1">
                    <label>UF:</label>
                    <input type="text" class="form-control" [(ngModel)]="estado" oninput="this.value = this.value.toUpperCase()" [ngStyle]="{'border-color': !estado ? '#dd443e' : '#3cb42c'}">
                </div>
                <div class="col col-4">
                    <label>Atividade da Empresa:</label>
                    <input type="text" class="form-control" [(ngModel)]="atividadeEmpresa" oninput="this.value = this.value.toUpperCase()">
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col col-3">
                    <label>Telefone:</label>
                    <input type="text" class="form-control" [textMask]="{mask: MASKS.telefone.textMaskFunction}" [(ngModel)]="telefone" [ngStyle]="{'border-color': !telefone ? '#dd443e' : '#3cb42c'}">
                </div>
                <div class="col col-4">
                    <label>Responsável:</label>
                    <input type="text" class="form-control" [(ngModel)]="nomeContato" oninput="this.value = this.value.toUpperCase()" [ngStyle]="{'border-color': !nomeContato ? '#dd443e' : '#3cb42c'}">
                </div>
                <div class="col col-5">
                    <label>Cargo:</label>
                    <input type="text" class="form-control" [(ngModel)]="cargoResponsavel" oninput="this.value = this.value.toUpperCase()" [ngStyle]="{'border-color': !cargoResponsavel ? '#dd443e' : '#3cb42c'}">
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col col-4">
                    <label>E-mail Principal:</label>
                    <input type="text" class="form-control" [(ngModel)]="email" [ngStyle]="{'border-color': !email ? '#dd443e' : '#3cb42c'}">
                </div>
                <div class="col col-4">
                    <label>E-mail 2:</label>
                    <input type="text" class="form-control" [(ngModel)]="email2">
                </div>
                <div class="col col-4">
                    <label>E-mail 3:</label>
                    <input type="text" class="form-control" [(ngModel)]="email3">
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col col-4">
                    <label>Seguradora:</label>
                    <input type="text" class="form-control" [(ngModel)]="seguradora" oninput="this.value = this.value.toUpperCase()" [ngStyle]="{'border-color': !seguradora ? '#dd443e' : '#3cb42c'}">
                </div>
                <div class="col col-2">
                    <label>Inicio da Vigência:</label>
                    <input type="date" class="form-control" [(ngModel)]="dataAdesao" [ngStyle]="{'border-color': !dataAdesao ? '#dd443e' : '#3cb42c'}">
                </div>
                <div class="col col-3">
                    <label>Data 1º Vencimento:</label>
                    <input type="date" class="form-control" [(ngModel)]="dataVencimento" (change)="calcularFaturamento(dataVencimento)" [ngStyle]="{'border-color': !dataVencimento ? '#dd443e' : '#3cb42c'}">
                </div>
                <div class="col col-3">
                    <label>Limite de Faturamento:</label>
                    <input type="date" class="form-control" [(ngModel)]="dataFaturamento" [ngStyle]="{'border-color': !dataFaturamento ? '#dd443e' : '#3cb42c'}">
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col col-3">
                    <label>Desconto Faturamento:</label>
                    <div class="input-group mb-3">
                        <input type="numer" class="form-control" [(ngModel)]="descontoEmpresa">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon1">%</span>
                        </div>
                    </div>
                </div>
                <div class="col col-2">
                    <label>Comissão:</label>
                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon12">R$</span>
                        </div>
                        <input type="number" min=0 class="form-control" [(ngModel)]="valorComissao">
                    </div>
                </div>
            </div>
            <br>
            <label>DADOS DO PLANO:</label>
            <br>
            <div class="row">
                <div class="col">
                    <label>Plano:</label>
                    <select class="form-control" [(ngModel)]="plano" (change)="selectPlano(plano)" [ngStyle]="{'border-color': !plano ? '#dd443e' : '#3cb42c'}">
                        <option value=""></option>
                        <option *ngFor="let plan of planosEmpresariais" [value]="plan.nome">{{plan.nome}}</option>
                        <option value="PERSONALIZADO">PERSONALIZADO</option>
                    </select>
                </div>
            </div>
            <br>
            <div class="row" *ngIf="plano == 'PERSONALIZADO'">
                <div class="col">
                    <label>Coberturas do Seguro:</label>
                    <form [formGroup]="capitalSegurado">
                        <div class="row" formArrayName="capital">
                            <div class="col col-12" style="font-size: 0.85em" *ngFor="let capital of capitalSeguradoFormGroup.controls; let i = index;">
                                <div [formGroupName]="i" class="row">
                                    <div class="form-group col-5">
                                        <select class="form-control" formControlName="cobertura" placeholder="Nome Cobertura" [ngStyle]="{'border-color': capitalSegurado.controls.capital.value[i].cobertura == '' || capitalSegurado.controls.capital.value[i].cobertura == null ? '#dd443e' : null}">
                                            <option value="ASSISTÊNCIA FUNERAL">ASSISTÊNCIA FUNERAL</option>
                                            <option value="MORTE ACIDENTAL">MORTE ACIDENTAL</option>
                                            <option value="MORTE NATURAL">MORTE NATURAL</option>
                                        </select>
                                    </div>
                                    <div class="form-group col-4">
                                        <input type="number" class="form-control" formControlName="capital" placeholder="Capital Segurado" min=0 [ngStyle]="{'border-color': capitalSegurado.controls.capital.value[i].capital < 0 || capitalSegurado.controls.capital.value[i].capital == null ? '#dd443e' : null}">
                                    </div>
                                    <div class="form-group col-3 text-right">
                                        <button class="btn btn-danger btn-sm" type="button" (click)="removeCapitalSegurado(i)" *ngIf="this.capitalSeguradoList.length > 1">
                                        <fa-icon [icon]="['fas', 'minus-circle']" size="1x"></fa-icon>
                                        </button>&nbsp;
                                        <button class="btn btn-success btn-sm" type="button" (click)="addCapitalSegurado()" [disabled]="capitalSegurado.invalid">
                                        <fa-icon [icon]="['fas', 'plus-circle']" size="1x"></fa-icon>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>   
                    </form>
                </div>
            </div>
            <div class="row" *ngIf="plano == 'PERSONALIZADO'">
                <div class="col col-4">
                    <label>Funeral Cônjuge:</label>
                    <select class="form-control" [(ngModel)]="funeralConjuge" (change)="setValorConjuge(funeralConjuge)">
                        <option value="AUTOMATICO">AUTOMÁTICO SEM CUSTO</option>
                        <option value="SIM">SIM</option>
                        <option value="NÃO">NÃO</option>
                    </select>
                </div>
                <div class="col col-2">
                    <label>Valor:</label>
                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                          <span class="input-group-text" id="basic-addon1">R$</span>
                        </div>
                        <input type="number" min=0 class="form-control" [(ngModel)]="valorFuneralConjuge" [disabled]="funeralConjuge != 'SIM'">
                    </div>
                </div>
                <div class="col col-4">
                    <label>Funeral Filhos Menor 18:</label>
                    <select class="form-control" [(ngModel)]="funeralFilhosMenor" (change)="setValorMenor(funeralFilhosMenor)">
                        <option value="AUTOMATICO">AUTOMÁTICO SEM CUSTO</option>
                        <option value="SIM">SIM</option>
                        <option value="NÃO">NÃO</option>
                    </select>
                </div>
                <div class="col col-2">
                    <label>Valor:</label>
                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                          <span class="input-group-text" id="basic-addon1">R$</span>
                        </div>
                        <input type="number" min=0 class="form-control" [(ngModel)]="valorFuneralFilhosMenor" [disabled]="funeralFilhosMenor != 'SIM'">
                    </div>
                </div>
            </div>
           <!--  <div class="row" *ngIf="plano == 'PERSONALIZADO'">
                <div class="col col-4">
                    <label>Funeral Filhos Maior 18:</label>
                    <select class="form-control" [(ngModel)]="funeralFilhosMaior" (change)="setValorMaior(funeralFilhosMaior)">
                        <option value="AUTOMATICO">AUTOMÁTICO SEM CUSTO</option>
                        <option value="SIM">SIM</option>
                        <option value="NÃO">NÃO</option>
                    </select>
                </div>
                <div class="col col-2">
                    <label>Valor:</label>
                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                          <span class="input-group-text" id="basic-addon1">R$</span>
                        </div>
                        <input type="number" min=0 class="form-control" [(ngModel)]="valorFuneralFilhosMaior" [disabled]="funeralFilhosMaior != 'SIM'">
                    </div>
                </div>
                <div class="col col-4">
                    <label>Funeral Extra:</label>
                    <select class="form-control" [(ngModel)]="funeralExtra" (change)="setValorExtra(funeralExtra)">
                        <option value="SIM">SIM</option>
                        <option value="NÃO">NÃO</option>
                    </select>
                </div>            
                <div class="col col-2">
                    <label>Valor:</label>
                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                          <span class="input-group-text" id="basic-addon1">R$</span>
                        </div>
                        <input type="number" min=0 class="form-control" [(ngModel)]="valorFuneralExtra" [disabled]="funeralExtra != 'SIM'">
                    </div>
                </div>
            </div> -->
            <div class="row" *ngIf="plano == 'PERSONALIZADO'">
                <div class="col col-4">
                    <label>Valor do Plano:</label>
                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                          <span class="input-group-text" id="basic-addon11">R$</span>
                        </div>
                        <input type="number" min=0 class="form-control" [(ngModel)]="valorPlano">
                    </div>
                </div>
                <div class="col col-4">
                    <label>Valor do Seguro:</label>
                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                          <span class="input-group-text" id="basic-addon12">R$</span>
                        </div>
                        <input type="number" min=0 class="form-control" [(ngModel)]="valorSeguro">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <div class="uploadfilecontainer" (click)="fileEmpresaDoc.click()" appDragDrop (onFileDropped)="onChangeEmpresa($event)">
                        <input hidden type="file" #fileEmpresaDoc (change)="onChangeClickEmpresa($event)" multiple>
                    </div>
                    <div class="row align-items-center">
                        <div class="col col-1">
                            &nbsp;
                        </div>
                        <div class="col">
                            <div class="files-list" *ngFor="let file of files; let i= index">
                                <p>{{ file }}</p>
                            </div> 
                        </div>
                        <div class="col col-1">
                            &nbsp;
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" style="margin-top: 80px;">
                <div class="col col-4">
                    &nbsp;
                </div>
                <div class="col col-2">
                     <button class="btn btn-danger btn-block" (click)="closeModalNovo()">CANCELAR</button>
                </div>
                <div class="col col-2">
                     <button class="btn btn-success btn-block" (click)="cadastrarEmpresa()" [disabled]="!vendedor || !razaoEmpresa || !fantasiaEmpresa || !cnpj || !rua || !numero || !bairro || !municipio || !estado || !telefone || !nomeContato || !cargoResponsavel || !email || !seguradora || !dataAdesao || !dataVencimento || !dataFaturamento || !plano || plano == 'PERSONALIZADO' && (!funeralConjuge || !funeralFilhosMenor || !funeralFilhosMaior || !funeralExtra || !valorPlano || !valorSeguro || capitalSeguradoList.length < 1)">SALVAR</button>
                </div>
                <div class="col col-4">
                     &nbsp;
                 </div>
            </div>
        </div>
    </div>   
<div class="row  align-items-center header">
    <div class="col col-3 titulo" style="text-align: left;">
        EMPRESAS
    </div>
    <div class="col" style="text-align: right;">
        <button class="btn btn-sm btn-outline-light" (click)="showAtivosEmpresa()">ATIVOS</button>&nbsp;&nbsp;
        <button class="btn btn-sm btn-outline-light" (click)="showInadimplentesEmpresa()">INADIMPLENTES</button>&nbsp;&nbsp;
        <button class="btn btn-sm btn-outline-light" (click)="showCanceladosEmpresa()">CANCELADOS</button>&nbsp;&nbsp;
        <button class="btn btn-sm btn-outline-light" (click)="todasEmpresas()">MOSTRAR TODOS</button>&nbsp;&nbsp;
        <button class="btn btn-sm btn-outline-light" (click)="openModalNovo()">CADASTRAR EMPRESA</button>
    </div>
</div>
<br>
<div class="row align-items-center">
    <div class="col col-8">
        <label>Nome Fantasia</label>
        <input type="text" class="form-control" [(ngModel)]="nomeBusca">
    </div>
    <div class="col col-3">
        <label>Cidade</label>
        <input type="text" class="form-control" [(ngModel)]="cidadeBusca">
    </div>
    <div class="col col-1">
        <br>
        <button class="btn btn-primary" (click)="buscarEmpresa(nomeBusca,cidadeBusca)"><fa-icon [icon]="['fas', 'search']"></fa-icon></button>
    </div>
</div>
<br>
<table class="table table-striped" style="text-align: center;">
    <thead class="thead-dark">
        <tr>
            <th>Código</th>
            <th>Razão Social</th>
            <th>Nome Fantasia</th>
            <th>Endereço</th>
            <th>Cidade</th>
            <th>UF</th>
            <th>Telefone</th>
            <th>Situação</th>
            <th>Editar</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let emp of empresas"[ngStyle]="{'background-color': emp?.situacao == 'CANCELADO' ? '#c7444a' : emp?.situacao == 'INADIMPLENTE' ? '#f08929' : null, 'color':emp?.situacao == 'CANCELADO' ?  '#ffffff' : '#000000'}">
            <td>{{ emp?.codigo }}</td>
            <td>{{ emp?.razaoEmpresa }}</td>
            <td>{{ emp?.fantasiaEmpresa }}</td>
            <td>{{ emp?.rua }}, {{ emp?.numero }} - {{ emp?.bairro }}</td>
            <td>{{ emp?.municipio }}</td>
            <td>{{ emp?.estado }}</td>
            <td>{{ emp?.telefone }}</td>
            <td>{{ emp?.situacao }}</td>
            <td>
                <button class="btn btn-outline-success" (click)="openModalEditar(emp)">
                    <fa-icon [icon]="['fas', 'edit']"></fa-icon>
                </button>
            </td>
        </tr>
    </tbody>
</table>
</div>