<!-- MODAL EDITAR TASK -->
<div class="modal" id="editTarefa">
    <div class="row align-items-center header">
        <div class="col titulo" style="text-align: left;">
            EDITAR TAREFA
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col col-3">
            <label>Titulo</label>
            <input type="text" class="form-control" [(ngModel)]="taskSelecionada.titulo" [ngStyle]="{'border-color': !taskSelecionada.titulo ? '#dd443e' : '#3cb42c','pointer-events': db.tipoUsuario == 'MASTER' ? 'all' : 'none'}" oninput="this.value = this.value.toUpperCase()">
        </div>
        <div class="col col-9">
            <label>Descrição</label>
            <input type="text" class="form-control" [(ngModel)]="taskSelecionada.descricao" [ngStyle]="{'border-color': !taskSelecionada.descricao ? '#dd443e' : '#3cb42c','pointer-events': db.tipoUsuario == 'MASTER' ? 'all' : 'none'}">
        </div>
    </div>
    <div class="row">
        <div class="col col-6">
            <label>Responsável</label>
            <select class="form-control" [(ngModel)]="taskSelecionada.responsavel" (change)="selectUserTarefaEditar(taskSelecionada.responsavel)" [ngStyle]="{'border-color': !taskSelecionada.responsavel ? '#dd443e' : '#3cb42c','pointer-events': db.tipoUsuario == 'MASTER' ? 'all' : 'none'}">
                <option value=""></option>
                <option *ngFor="let user of usuarios" [value]="user.nome">{{user.nome}}</option>
            </select>
        </div>
        <div class="col col-6">
            <label>Prioridade</label>
            <select class="form-control" [(ngModel)]="taskSelecionada.prioridade" [ngStyle]="{'border-color': !taskSelecionada.prioridade ? '#dd443e' : '#3cb42c','pointer-events': db.tipoUsuario == 'MASTER' ? 'all' : 'none'}">
                <option value="" selected></option>
                <option value="BAIXA">BAIXA</option>
                <option value="MÉDIA">MÉDIA</option>
                <option value="ALTA">ALTA</option>
            </select>
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col">
            <label>Observação</label>
            <textarea class="form-control" [(ngModel)]="taskSelecionada.observacao" [ngStyle]="{'pointer-events': (db.tipoUsuario == 'MASTER' || taskSelecionada.idResponsavel == userId) ? 'all' : 'none'}"></textarea>
        </div>
    </div>
    <br>
    <div class="row" style="text-align: center;">
        <div class="col">
            <button class="btn btn-dark btn-lg" (click)="closeModalEdit()">FECHAR</button>
        </div>
        <div class="col">
            <button class="btn btn-danger btn-lg" (click)="editarTarefa()">EDITAR TAREFA</button>
        </div>
    </div>
</div>
<!-- MODAL NOVA TASK -->
<div class="modal" id="newTarefa">
    <div class="row align-items-center header">
        <div class="col titulo" style="text-align: left;">
            CRIAR NOVA TAREFA
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col col-3">
            <label>Titulo</label>
            <input type="text" class="form-control" [(ngModel)]="tituloTarefa" [ngStyle]="{'border-color': !tituloTarefa ? '#dd443e' : '#3cb42c'}" oninput="this.value = this.value.toUpperCase()">
        </div>
        <div class="col col-9">
            <label>Descrição</label>
            <input type="text" class="form-control" [(ngModel)]="descricaoTarefa" [ngStyle]="{'border-color': !descricaoTarefa ? '#dd443e' : '#3cb42c'}">
        </div>
    </div>
    <div class="row">
        <div class="col col-6">
            <label>Responsável</label>
            <select class="form-control" [(ngModel)]="responsavelTarefa" (change)="selectUserTarefa(responsavelTarefa)" [ngStyle]="{'border-color': !responsavelTarefa ? '#dd443e' : '#3cb42c'}">
                <option value=""></option>
                <option *ngFor="let user of usuarios" [value]="user.nome">{{user.nome}} - {{user.tipo}}</option>
            </select>
        </div>
        <div class="col col-6">
            <label>Prioridade</label>
            <select class="form-control" [(ngModel)]="prioridadeTarefa" [ngStyle]="{'border-color': !prioridadeTarefa ? '#dd443e' : '#3cb42c'}">
                <option value="" selected></option>
                <option value="BAIXA">BAIXA</option>
                <option value="MÉDIA">MÉDIA</option>
                <option value="ALTA">ALTA</option>
            </select>
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col">
            <label>Observação</label>
            <textarea class="form-control" [(ngModel)]="observacaoTarefa"></textarea>
        </div>
    </div>
    <br>
    <div class="row" style="text-align: center;">
        <div class="col">
            <button class="btn btn-dark btn-lg" (click)="closeModalCriarTarefa()">FECHAR</button>
        </div>
        <div class="col">
            <button class="btn btn-danger btn-lg" (click)="criarTarefa()">CRIAR TAREFA</button>
        </div>
    </div>
</div>
<!-- FIM NOVA TASK -->
<div class="row align-items-center header">
    <div class="col titulo" style="text-align: left;">
        TAREFAS
    </div>
    <div class="col" style="text-align: right;">
        <button class="btn btn-outline-light" (click)="buscarTodasTarefas()">
            <fa-icon [icon]="['fas', 'users']"></fa-icon>&nbsp;EXIBIR TODAS TAREFAS
        </button>
    </div>
    <div class="col" style="text-align: right;">
        <button class="btn btn-outline-light" (click)="openModalCriarTarefa()">
            <fa-icon [icon]="['fas', 'users']"></fa-icon>&nbsp;CRIAR NOVA TAREFA
        </button>
    </div>
</div>
<br>
<div class="row align-items-end">
    <div class="col col-2">
        <label>Data Inicio</label>
        <input type="date" class="form-control" [(ngModel)]="dataInicio">
    </div>
    <div class="col col-2">
        <label>Data Fim</label>
        <input type="date" class="form-control" [(ngModel)]="dataFim">
    </div>
    <div class="col col-1">
        <button class="btn btn-primary" (click)="buscarTarefasData(dataInicio,dataFim)" [disabled]="!dataInicio || !dataFim">
            <fa-icon [icon]="['fas', 'search']"></fa-icon>
        </button>
    </div>
    <div class="col col-3">
        <label>Responsável</label>
        <select class="form-control" [(ngModel)]="nomeUsuario" (change)="selectUsuario(nomeUsuario)">
            <option value=""></option>
            <option *ngFor="let user of usuarios" [value]="user.nome">{{user.nome}}</option>
        </select> 
   </div>
   <div class="col col-2">
        <button class="btn btn-primary" (click)="buscarTarefas(true)">
        RESOLVIDAS
        </button>
    </div>
    <div class="col col-2">
        <button class="btn btn-primary" (click)="buscarTarefas(false)">
        PENDENTES
        </button>
    </div>
</div>
<br>
<div class="table-responsive">
    <table class="table table-striped" style="text-align: center;">
        <thead class="thead-dark">
            <tr>
                <th>Data</th>
                <th>Solicitante</th>
                <th>Responsável</th>
                <th>Titulo</th>
                <th>Descrição</th>
                <th>Observação</th>
                <th>Data Resolução</th>
                <th>Situação</th>
                <th></th>
                <th></th>
            </tr>
        </thead>
        <tbody *ngFor="let task of tarefas">
            <tr style="align-items: center; vertical-align: middle;">
                <td>{{ task?.data | date: 'dd/MM/yyyy' }}<br>{{ task?.hora }}</td>
                <td>{{ task?.nomeSolicitante }}</td>
                <td>{{ task?.responsavel }}</td>
                <td>{{ task?.titulo }}</td>
                <td>{{ task?.descricao }}</td>
                <td>{{ task?.observacao }}</td>
                <td>{{ task?.dataResolucao | date: 'dd/MM/yyyy' }}<br>{{ task?.horaResolucao }}</td>
                <td [ngStyle]="{'color': task.resolvido ? '#1c6631' : '#c7444a'}"><span *ngIf="task?.resolvido">RESOLVIDO</span><span *ngIf="!task?.resolvido">PENDENTE</span></td>
                <td class="botoes">
                    <button class="btn btn-outline-success" (click)="resolverTarefa(task)" *ngIf="!task.resolvido && (db.tipoUsuario == 'MASTER' || task?.idResponsavel == userId)">
                        <fa-icon [icon]="['fas', 'check']"></fa-icon>
                    </button>
                    <button class="btn btn-outline-primary" (click)="openModalEdit(task)" *ngIf="!task.resolvido && (db.tipoUsuario == 'MASTER' || task?.idResponsavel == userId)">
                        <fa-icon [icon]="['fas', 'edit']"></fa-icon>
                    </button>
                    <button class="btn btn-outline-danger" (click)="removerTarefa(task)" *ngIf="db.tipoUsuario == 'MASTER'">
                        <fa-icon [icon]="['fas', 'trash']"></fa-icon>
                    </button>
                </td>
            </tr>
        </tbody>
    </table>
</div>