import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DataBaseService } from '../provider.service';
import { MASKS } from 'ng-brazil';
import { AppComponent } from '../app.component';

@Component({
  selector: 'app-configuracoes',
  templateUrl: './configuracoes.component.html',
  styleUrls: ['./configuracoes.component.css']
})
export class ConfiguracoesComponent implements OnInit {
  public MASKS = MASKS;
  @ViewChild('pdfViewerConfiguracoes') pdfViewerOnDemand;
  dataHoje:any = "";
  user:any = "";
  bancos:any = [];
  novoBanco:any = "";
  idBancos:any = "";
  idComissoes:any = "";
  idMensagens:any = "";
  idSorteado:any = "";
  idPagamentos:any = "";
  idTiposEntrada:any = "";
  msgNiver:any = "";
  numeroSorteado:any = "";
  dataSorteado:any = "";
  formularios:any = [];
  filesReembolso:any = [];
  filesSeguro:any = [];
  filesCancelamento:any = [];
  selectedfilesReembolso:any = [];
  selectedfilesSeguro:any = [];
  selectedfilesCancelamento:any = [];
  formularioReembolso:any = "";
  formularioSeguro:any = "";
  formularioCancelamento:any = "";
  idFormReembolso:any = "";
  idFormSeguro:any = "";
  idFormCancelamento:any = "";
  idMotivosPendencia:any = "";
  idMotivosCancelamento:any = "";
  idMotivosInadimplente:any = "";
  idFaturamentoMinimo:any = "";
  idAlertas:any = "";
  novaTag:any = "";
  idTags:any = "";
  novoNomeNegociacao:any = "";
  idNomesNegociacao:any = "";
  sorteios:any = [];
  celularWhatsSite:any = "";
  idTelefoneWhats:any = "";
  idAtendimentos:any = "";
  arrayAlertas:any = {
    "faturamento" : {
      "vendedor" : false,
      "coordenador" : false,
      "gerente" : false,
      "auxAdministrativo" : false,
      "administrador" : false,
      "master" : false
    },
    "contasPagar" : {
      "vendedor" : false,
      "coordenador" : false,
      "gerente" : false,
      "auxAdministrativo" : false,
      "administrador" : false,
      "master" : false
    },
    "contasReceber" : {
      "vendedor" : false,
      "coordenador" : false,
      "gerente" : false,
      "auxAdministrativo" : false,
      "administrador" : false,
      "master" : false
    },
    "agendamento" : {
      "vendedor" : false,
      "coordenador" : false,
      "gerente" : false,
      "auxAdministrativo" : false,
      "administrador" : false,
      "master" : false
    },
    "tarefas" : {
      "vendedor" : false,
      "coordenador" : false,
      "gerente" : false,
      "auxAdministrativo" : false,
      "administrador" : false,
      "master" : false
    }
  };
  todasFormasPagamento:any = [];
  tags:any = [];
  numerosSorte:any = [];
  nomesNegociacao:any = [];
  valorMinimoFaturamento:number = 0;
  motivoGroup:FormGroup;
  motivoCancelamentoGroup:FormGroup;
  motivoInadimplenteGroup:FormGroup;
  motivoAtendimentoGroup:FormGroup;
  tiposEntradaGroup:FormGroup;
  public tiposEntradaList: FormArray;
  public motivosPendenciaList: FormArray;
  public motivosCancelamentoList: FormArray;
  public motivosInadimplenteList: FormArray;
  public motivosAtendimentoList: FormArray;
  get tiposEntradaFormGroup(){return this.tiposEntradaGroup.get('tiposEntrada') as FormArray;}
  get motivosPendenciaFormGroup(){return this.motivoGroup.get('motivo') as FormArray;}
  get motivosCancelamentoFormGroup(){return this.motivoCancelamentoGroup.get('motivoCancelamento') as FormArray;}
  get motivosInadimplenteFormGroup(){return this.motivoInadimplenteGroup.get('motivoInadimplente') as FormArray;}
  get motivosAtendimentoFormGroup(){return this.motivoAtendimentoGroup.get('motivoAtendimento') as FormArray;}
  objectKeys = Object.keys;
  situacaoNumeroSorte:any = "TODOS";
  numerosSorteFiltrados:any = [];
  numeroSorteBuscar:any = null;
  telPlantao:any = "";
  idTelefonePlantao:any = "";

  constructor(public db:DataBaseService, private formBuilder: FormBuilder, public app:AppComponent) { 
    this.dataHoje = new Date(Date.now() - 10800000).toISOString().split('T')[0];
    this.user = localStorage.getItem('usuarioVivamax');
  }

  openModalPdfViewer(url){
    this.pdfViewerOnDemand.pdfSrc = url;
    this.pdfViewerOnDemand.refresh();
  }

  ngOnInit(){
    this.motivoGroup = this.formBuilder.group({motivo: this.formBuilder.array([this.createMotivoInicial()])}); 
    this.motivoCancelamentoGroup = this.formBuilder.group({motivoCancelamento: this.formBuilder.array([this.createMotivoCancelamentoInicial()])}); 
    this.motivoInadimplenteGroup = this.formBuilder.group({motivoInadimplente: this.formBuilder.array([this.createMotivoInadimplenteInicial()])}); 
    this.motivoAtendimentoGroup = this.formBuilder.group({motivoAtendimento: this.formBuilder.array([this.createMotivoAtendimentoInicial()])}); 
    this.tiposEntradaGroup = this.formBuilder.group({tiposEntrada: this.formBuilder.array([this.createTiposEntradaInicial()])}); 
    this.motivosPendenciaList = this.motivoGroup.get('motivo') as FormArray;
    this.tiposEntradaList = this.tiposEntradaGroup.get('tiposEntrada') as FormArray;
    this.motivosCancelamentoList = this.motivoCancelamentoGroup.get('motivoCancelamento') as FormArray;
    this.motivosInadimplenteList = this.motivoInadimplenteGroup.get('motivoInadimplente') as FormArray;
    this.motivosAtendimentoList = this.motivoAtendimentoGroup.get('motivoAtendimento') as FormArray;
    this.db.getConfigs().subscribe((z:any) => {
      this.idBancos = z[25]._id;
      this.idComissoes = z[0]._id;
      this.idMensagens = z[2]._id;
      this.idSorteado = z[9]._id;
      this.idTiposEntrada = z[21]._id;
      this.idFaturamentoMinimo = z[14]._id;
      this.idTags = z[16]._id;
      this.idNomesNegociacao = z[17]._id;
      this.bancos = z[25].bancos;
      this.tags = z[16].tags;
      this.nomesNegociacao = z[17].nomesNegociacao;
      this.msgNiver = z[2].msgAniversario;
      this.sorteios = z[9].sorteios.sort((a,b) => {if(a.data > b.data)return -1});
      this.idMotivosPendencia = z[11]._id;
      let motivos = z[11].motivosPendencia;
      this.idMotivosInadimplente = z[12]._id;
      let motivosInadimplente = z[12].inadimplencias;
      this.idMotivosCancelamento = z[13]._id;
      let motivosCancelamento = z[13].motivosCancelamento;
      this.valorMinimoFaturamento = z[14].valorMinimoFaturamento;
      this.idAlertas = z[15]._id;
      this.arrayAlertas = z[15];
      this.celularWhatsSite = z[19].telefoneWhats;
      this.idTelefoneWhats = z[19]._id;
      let tiposEntrada = z[21].tiposEntrada;
      this.idAtendimentos = z[26]._id;
      let motivosAtendimento = z[26].motivosAtendimento;
      this.idTelefonePlantao = z[27]._id;
      this.telPlantao = z[27].telefonePlantao;
      if(motivosAtendimento.length > 0){
        this.motivosAtendimentoList.clear();
        motivosAtendimento.forEach((item:any) => {
          this.addMotivoAtendimento(item.nome);
        })
      }
      if(tiposEntrada.length > 0){
        this.tiposEntradaList.clear();
          tiposEntrada.forEach((item) => {
            this.addTiposEntrada(item.nome);
          })
      }
      if(motivos.length > 0){
        this.motivosPendenciaList.clear();
        motivos.forEach((item) => {
          this.addMotivo(item.nome);
        })
      }
      if(motivosCancelamento.length > 0){
        this.motivosCancelamentoList.clear();
        motivosCancelamento.forEach((item) => {
          this.addMotivoCancelamento(item.nome);
        })
      }
      if(motivosInadimplente.length > 0){
        this.motivosInadimplenteList.clear();
        motivosInadimplente.forEach((item) => {
          this.addMotivoInadimplente(item.nome);
        })
      }
    });
    this.db.getFormularios().subscribe((data:any) => {
      this.formularios = data;
      let idx = this.formularios.map((f) => {
        return f.nome;
      }).indexOf("seguro");
      this.formularioSeguro = this.formularios[idx].url;
      this.idFormSeguro = this.formularios[idx]._id;
      let ind = this.formularios.map((f) => {
        return f.nome;
      }).indexOf("reembolso");
      this.formularioReembolso = this.formularios[ind].url;
      this.idFormReembolso = this.formularios[ind]._id;
      let indice = this.formularios.map((f) => {
        return f.nome;
      }).indexOf("cancelamento");
      this.formularioCancelamento = this.formularios[indice].url;
      this.idFormCancelamento = this.formularios[indice]._id;
      this.filesReembolso = [];
      this.filesSeguro = [];
      this.filesCancelamento = [];
    })
    this.db.getSorte().subscribe((numData:any) => {
      this.numerosSorte = numData;
      this.numerosSorteFiltrados = this.numerosSorte;
    })
  }

  addTiposEntrada(item){
    this.tiposEntradaList.push(this.createTiposEntrada(item));
  }

  createTiposEntrada(item):FormGroup{
    return this.formBuilder.group({
      nome: [item, Validators.compose([Validators.required])]
    });
  }

  createTiposEntradaInicial():FormGroup{
    return this.formBuilder.group({
      nome: [null, Validators.compose([Validators.required])]
    });
  }

  removeTiposEntrada(index) {
    this.tiposEntradaList.removeAt(index);
  }

  salvarTiposEntrada(){
    let dados = {tiposEntrada: this.tiposEntradaList.value};
    this.db.patchConfiguracoes(this.idTiposEntrada,dados).subscribe(res => {
      console.log(res);
      let jsonDate = new Date().toJSON();
      let hora = new Date(jsonDate).toLocaleTimeString();
      let body = {
        "menu" : "CONFIGURAÇÕES", 
        "descricao" : "Tipos de Entrada Alterado", 
        "data" : this.dataHoje, 
        "hora" : hora, 
        "usuario" : this.user
      }
      this.db.postLogs(body).subscribe(res => {
        console.log(res);
        this.app.openAlert("Tipos de Entrada salvo com sucesso!");
        this.ngOnInit();
      }, err => {
        console.log(err)
      })
    }, err => {
      console.log(err)
    })
  }

  addMotivoAtendimento(item){
    this.motivosAtendimentoList.push(this.createMotivoAtendimento(item));
  }

  createMotivoAtendimento(item):FormGroup{
    return this.formBuilder.group({
      nome: [item, Validators.compose([Validators.required])]
    });
  }

  createMotivoAtendimentoInicial():FormGroup{
    return this.formBuilder.group({
      nome: [null, Validators.compose([Validators.required])]
    });
  }

  removeMotivoAtendimento(index) {
    this.motivosAtendimentoList.removeAt(index);
  }

  salvarMotivosAtendimento(){
    let dados = {motivosAtendimento: this.motivosAtendimentoList.value};
    this.db.patchConfiguracoes(this.idAtendimentos,dados).subscribe(res => {
      console.log(res);
      let jsonDate = new Date().toJSON();
      let hora = new Date(jsonDate).toLocaleTimeString();
      let body = {
        "menu" : "CONFIGURAÇÕES", 
        "descricao" : "Motivos de Atendimento Alterado", 
        "data" : this.dataHoje, 
        "hora" : hora, 
        "usuario" : this.user
      }
      this.db.postLogs(body).subscribe(res => {
        console.log(res);
        this.db.motivosAtendimento = this.motivosAtendimentoList.value;
        this.app.openAlert("Motivos de Atendimento salvo com sucesso!");
        this.ngOnInit();
      }, err => {
        console.log(err)
      })
    }, err => {
      console.log(err)
    })
  }

  addMotivo(item){
    this.motivosPendenciaList.push(this.createMotivo(item));
  }

  createMotivo(item):FormGroup{
    return this.formBuilder.group({
      nome: [item, Validators.compose([Validators.required])]
    });
  }

  createMotivoInicial():FormGroup{
    return this.formBuilder.group({
      nome: [null, Validators.compose([Validators.required])]
    });
  }

  removeMotivo(index) {
    this.motivosPendenciaList.removeAt(index);
  }

  salvarMotivosPendencia(){
    let dados = {motivosPendencia: this.motivosPendenciaList.value};
    this.db.patchConfiguracoes(this.idMotivosPendencia,dados).subscribe(res => {
      console.log(res);
      let jsonDate = new Date().toJSON();
      let hora = new Date(jsonDate).toLocaleTimeString();
      let body = {
        "menu" : "CONFIGURAÇÕES", 
        "descricao" : "Motivos de Pendência Alterado", 
        "data" : this.dataHoje, 
        "hora" : hora, 
        "usuario" : this.user
      }
      this.db.postLogs(body).subscribe(res => {
        console.log(res);
        this.db.motivosAtendimento = this.motivosAtendimentoList.value;
        this.app.openAlert("Motivos de Pendência salvo com sucesso!");
        this.ngOnInit();
      }, err => {
        console.log(err)
      })
    }, err => {
      console.log(err)
    })
  }

  addMotivoCancelamento(item){
    this.motivosCancelamentoList.push(this.createMotivo(item));
  }

  createMotivoCancelamento(item):FormGroup{
    return this.formBuilder.group({
      nome: [item, Validators.compose([Validators.required])]
    });
  }

  createMotivoCancelamentoInicial():FormGroup{
    return this.formBuilder.group({
      nome: [null, Validators.compose([Validators.required])]
    });
  }

  removeMotivoCancelamento(index) {
    this.motivosCancelamentoList.removeAt(index);
  }

  salvarMotivosCancelamento(){
    let dados = {motivosCancelamento: this.motivosCancelamentoList.value};
    this.db.patchConfiguracoes(this.idMotivosCancelamento,dados).subscribe(res => {
      console.log(res);
      let jsonDate = new Date().toJSON();
      let hora = new Date(jsonDate).toLocaleTimeString();
      let body = {
        "menu" : "CONFIGURAÇÕES", 
        "descricao" : "Motivos de Cancelamento Alterado", 
        "data" : this.dataHoje, 
        "hora" : hora, 
        "usuario" : this.user
      }
      this.db.postLogs(body).subscribe(res => {
        console.log(res);
        this.app.openAlert("Motivos de Cancelamento salvo com sucesso!");
        this.ngOnInit();
      }, err => {
        console.log(err)
      })
    }, err => {
      console.log(err)
    })
  }

  addMotivoInadimplente(item){
    this.motivosInadimplenteList.push(this.createMotivo(item));
  }

  createMotivoInadimplente(item):FormGroup{
    return this.formBuilder.group({
      nome: [item, Validators.compose([Validators.required])]
    });
  }

  createMotivoInadimplenteInicial():FormGroup{
    return this.formBuilder.group({
      nome: [null, Validators.compose([Validators.required])]
    });
  }

  removeMotivoInadimplente(index) {
    this.motivosInadimplenteList.removeAt(index);
  }

  salvarMotivosInadimplente(){
    let dados = {inadimplencias: this.motivosInadimplenteList.value};
    this.db.patchConfiguracoes(this.idMotivosInadimplente,dados).subscribe(res => {
      console.log(res);
      let jsonDate = new Date().toJSON();
      let hora = new Date(jsonDate).toLocaleTimeString();
      let body = {
        "menu" : "CONFIGURAÇÕES", 
        "descricao" : "Motivos de Inadimplente Alterado", 
        "data" : this.dataHoje, 
        "hora" : hora, 
        "usuario" : this.user
      }
      this.db.postLogs(body).subscribe(res => {
        console.log(res);
        this.app.openAlert("Motivos de Inadimplencias salvo com sucesso!");
        this.ngOnInit();
      }, err => {
        console.log(err)
      })
    }, err => {
      console.log(err)
    })
  }
  
  openModalTags(){
    let modal = document.getElementById('modalTags');
    modal.style.display = "block";
  }

  closeModalTags(){
    this.novaTag = "";
    let modal = document.getElementById('modalTags');
    modal.style.display = "none";
  }

  cadastrarTags(){
    if(window.confirm("Deseja cadastrar essa nova Tag?")){
      let dados = {
        "nome": this.novaTag
      }
      this.db.postTags(this.idTags,dados).subscribe(res => {
        console.log(res);
        let jsonDate = new Date().toJSON();
        let hora = new Date(jsonDate).toLocaleTimeString();
        let body = {
          "menu" : "CONFIGURAÇÕES", 
          "descricao" : "Motivos de Inadimplente Alterado", 
          "data" : this.dataHoje, 
          "hora" : hora, 
          "usuario" : this.user
        }
        this.db.postLogs(body).subscribe(res => {
          console.log(res);
          this.app.openAlert("Tag Cadastrada Com Sucesso!");
          this.closeModalTags();
          this.ngOnInit();
          }, err => {
            console.log(err)
          })
        }, err => {
        console.log(err);
      })
    }
  }
  
  excluirTags(tag){
    if(window.confirm("Deseja Excluir essa Tag?")){
      this.db.deleteTag(this.idTags,tag).subscribe(res => {
        this.app.openAlert("Tag Excluida Com Sucesso!");
        this.ngOnInit();
        console.log(res);
      }, err => {
        console.log(err);
      })
    }
  }
  
  openModalNomesNegociacao(){
    let modal = document.getElementById('modalNomesNegociacao');
    modal.style.display = "block";
  }

  closeModalNomesNegociacao(){
    this.novoNomeNegociacao = "";
    let modal = document.getElementById('modalNomesNegociacao');
    modal.style.display = "none";
  }

  cadastrarNomesNegociacao(){
    if(window.confirm("Deseja cadastrar esse Nome de Negociação?")){
      let dados = {
        "nome": this.novoNomeNegociacao
      }
      this.db.postNomesNegociacao(this.idNomesNegociacao,dados).subscribe(res => {
        this.app.openAlert("Nome de Negociação Cadastrado Com Sucesso!");
        this.closeModalNomesNegociacao();
        this.ngOnInit();
        console.log(res);
      }, err => {
        console.log(err);
      })
    }
  }
  
  excluirNomesNegociacao(nome){
    if(window.confirm("Deseja Excluir esse Nome de Negociação?")){
      this.db.deleteNomesNegociacao(this.idNomesNegociacao,nome).subscribe(res => {
        this.app.openAlert("Nome de Negociação Excluido Com Sucesso!");
        this.ngOnInit();
        console.log(res);
      }, err => {
        console.log(err);
      })
    }
  }

  openmodalCartao(){
    let modal = document.getElementById('modalCartao');
    modal.style.display = "block";
  }

  closemodalCartao(){
    this.novoBanco = "";
    let modal = document.getElementById('modalCartao');
    modal.style.display = "none";
  }

  cadastrarBanco(){
    if(window.confirm("Deseja cadastrar esse novo banco?")){
      let dados = {
        "nome": this.novoBanco
      }
      this.db.postBanco(this.idBancos,dados).subscribe(res => {
        this.app.openAlert("Banco Cadastrado Com Sucesso!");
        this.closemodalCartao();
        this.ngOnInit();
        console.log(res);
      }, err => {
        console.log(err);
      })
    }
  }

  excluirBanco(Banco){
    if(window.confirm("Deseja Excluir esse banco?")){
      this.db.deleteBanco(this.idBancos,Banco).subscribe(res => {
        this.app.openAlert("Banco Excluido Com Sucesso!");
        this.ngOnInit();
        console.log(res);
      }, err => {
        console.log(err);
      })
    }
  }

  salvarMsg(){
    let dados = {
      msgAniversario: this.msgNiver
    }
    this.db.patchConfiguracoes(this.idMensagens,dados).subscribe(res => {
      console.log(res);
      let jsonDate = new Date().toJSON();
      let hora = new Date(jsonDate).toLocaleTimeString();
      let body = {
        "menu" : "CONFIGURAÇÕES", 
        "descricao" : "Mensagens Alterado", 
        "data" : this.dataHoje, 
        "hora" : hora, 
        "usuario" : this.user
      }
      this.db.postLogs(body).subscribe(res => {
        console.log(res);
        this.app.openAlert("Mensagens salvas com sucesso!");
        this.ngOnInit();
      }, err => {
        console.log(err)
      })
    }, err => {
      console.log(err)
    })
  }

  salvarSorteado(){
    let rdm = Math.random().toString(32).substr(4, 14);
    let dados = {
      id:rdm,
      numero: this.numeroSorteado,
      data: this.dataSorteado
    }
    this.db.postSorteioConfiguracoes(this.idSorteado,dados).subscribe(res => {
      console.log(res);
      let jsonDate = new Date().toJSON();
      let hora = new Date(jsonDate).toLocaleTimeString();
      let body = {
        "menu" : "CONFIGURAÇÕES", 
        "descricao" : "Número do Sorteado do Mês: "+this.dataSorteado +" - "+this.numeroSorteado, 
        "data" : this.dataHoje, 
        "hora" : hora, 
        "usuario" : this.user
      }
      this.db.postLogs(body).subscribe(res => {
        console.log(res);
      this.app.openAlert("Numero de Sorteio salvo com sucesso!");
      this.ngOnInit();
      }, err => {
        console.log(err)
      })
    }, err => {
      console.log(err)
    })
  }

  /// FUNÇÕES SEGURO
  onChangeSeguro(event){
    let arrayTypes = ['application/pdf'];
    this.selectedfilesSeguro = <FileList>event;
    for(let i=0; i<this.selectedfilesSeguro.length; i++){
        if(!arrayTypes.includes(this.selectedfilesSeguro[i].type)){
        this.app.openAlert("Tipo de Arquivo Não Suportado!\nEscolha um arquivo com a extensão PDF")
        this.selectedfilesSeguro = "";
      }} 
      this.filesSeguro = [];
      for(let i=0; i<this.selectedfilesSeguro.length; i++){
        this.filesSeguro.push(this.selectedfilesSeguro[i].name);
      }
  }

  onChangeSeguroClick(event){
    console.log(event)
    let arrayTypes = ['application/pdf'];
    this.selectedfilesSeguro = <FileList>event.srcElement.files;
    for(let i=0; i<this.selectedfilesSeguro.length; i++){
        if(!arrayTypes.includes(this.selectedfilesSeguro[i].type)){
        this.app.openAlert("Tipo de Arquivo Não Suportado!\nEscolha um arquivo com a extensão PDF")
        this.selectedfilesSeguro = "";
      }} 
      this.filesSeguro = [];
      for(let i=0; i<this.selectedfilesSeguro.length; i++){
        this.filesSeguro.push(this.selectedfilesSeguro[i].name);
      }
  }

  uploadFileSeguro(){
    const imagedata = new FormData();
    for(let i=0; i<this.selectedfilesSeguro.length; i++){imagedata.append('files[]', this.selectedfilesSeguro[i], this.selectedfilesSeguro[i].name)};
     this.db.enviarForm(this.idFormSeguro, imagedata).subscribe((result) => {
       let jsonDate = new Date().toJSON();
       let horaAgora = new Date(jsonDate).toLocaleTimeString();
         console.log(result);
         let body = {
           "menu" : "CONFIGURAÇÕES", 
           "descricao" : "Formulário Seguro Enviado!", 
           "data" : this.dataHoje, 
           "hora" : horaAgora, 
           "usuario" : this.user
         }
         this.db.postLogs(body).subscribe(res => {
           console.log(res);
          this.ngOnInit();
         })
       },(error) => {
         let jsonDate = new Date().toJSON();
         let horaAgora = new Date(jsonDate).toLocaleTimeString();
         console.log(error);
         let body = {
          "menu" : "CONFIGURAÇÕES", 
          "descricao" : "Formulário Seguro Enviado!", 
          "data" : this.dataHoje, 
          "hora" : horaAgora, 
          "usuario" : this.user
         }
         this.db.postLogs(body).subscribe(res => {
         console.log(res);
         this.ngOnInit();
        })
       });
  }

  deleteDocSeguro(idx){
    this.filesSeguro.splice(idx,1);
    let fileArray = Array.from(this.selectedfilesSeguro);
    fileArray.splice(idx,1);
    this.selectedfilesSeguro = fileArray;
  }

  
/// FUNÇÕES REEMBOLSO
onChangeReembolso(event){
  let arrayTypes = ['application/pdf'];
  this.selectedfilesReembolso = <FileList>event;
  for(let i=0; i<this.selectedfilesReembolso.length; i++){
      if(!arrayTypes.includes(this.selectedfilesReembolso[i].type)){
      this.app.openAlert("Tipo de Arquivo Não Suportado!\nEscolha um arquivo com a extensão PDF")
      this.selectedfilesReembolso = "";
    }} 
    this.filesReembolso = [];
    for(let i=0; i<this.selectedfilesReembolso.length; i++){
      this.filesReembolso.push(this.selectedfilesReembolso[i].name);
    }
}

onChangeReembolsoClick(event){
  console.log(event)
  let arrayTypes = ['application/pdf'];
  this.selectedfilesReembolso = <FileList>event.srcElement.files;
  for(let i=0; i<this.selectedfilesReembolso.length; i++){
      if(!arrayTypes.includes(this.selectedfilesReembolso[i].type)){
      this.app.openAlert("Tipo de Arquivo Não Suportado!\nEscolha um arquivo com a extensão PDF")
      this.selectedfilesReembolso = "";
    }} 
    this.filesReembolso = [];
    for(let i=0; i<this.selectedfilesReembolso.length; i++){
      this.filesReembolso.push(this.selectedfilesReembolso[i].name);
    }
}

uploadFileReembolso(){
  const imagedata = new FormData();
  for(let i=0; i<this.selectedfilesReembolso.length; i++){imagedata.append('files[]', this.selectedfilesReembolso[i], this.selectedfilesReembolso[i].name)};
   this.db.enviarForm(this.idFormReembolso, imagedata).subscribe((result) => {
     let jsonDate = new Date().toJSON();
     let horaAgora = new Date(jsonDate).toLocaleTimeString();
       console.log(result);
       let body = {
         "menu" : "CONFIGURAÇÕES", 
         "descricao" : "Formulário Reembolso Enviado!", 
         "data" : this.dataHoje, 
         "hora" : horaAgora, 
         "usuario" : this.user
       }
       this.db.postLogs(body).subscribe(res => {
         console.log(res);
        this.ngOnInit();
       })
     },(error) => {
       let jsonDate = new Date().toJSON();
       let horaAgora = new Date(jsonDate).toLocaleTimeString();
       console.log(error);
       let body = {
        "menu" : "CONFIGURAÇÕES", 
        "descricao" : "Formulário Reembolso Enviado!", 
        "data" : this.dataHoje, 
        "hora" : horaAgora, 
        "usuario" : this.user
       }
       this.db.postLogs(body).subscribe(res => {
       console.log(res);
       this.ngOnInit();
      })
     });
}

deleteDocReembolso(idx){
  this.filesReembolso.splice(idx,1);
  let fileArray = Array.from(this.selectedfilesReembolso);
  fileArray.splice(idx,1);
  this.selectedfilesReembolso = fileArray;
}
  
/// FUNÇÕES CANCELAMENTO
onChangeCancelamento(event){
  let arrayTypes = ['application/pdf'];
  this.selectedfilesCancelamento = <FileList>event;
  for(let i=0; i<this.selectedfilesCancelamento.length; i++){
      if(!arrayTypes.includes(this.selectedfilesCancelamento[i].type)){
      this.app.openAlert("Tipo de Arquivo Não Suportado!\nEscolha um arquivo com a extensão PDF")
      this.selectedfilesCancelamento = "";
    }} 
    this.filesCancelamento = [];
    for(let i=0; i<this.selectedfilesCancelamento.length; i++){
      this.filesCancelamento.push(this.selectedfilesCancelamento[i].name);
    }
}

onChangeCancelamentoClick(event){
  console.log(event)
  let arrayTypes = ['application/pdf'];
  this.selectedfilesCancelamento = <FileList>event.srcElement.files;
  for(let i=0; i<this.selectedfilesCancelamento.length; i++){
      if(!arrayTypes.includes(this.selectedfilesCancelamento[i].type)){
      this.app.openAlert("Tipo de Arquivo Não Suportado!\nEscolha um arquivo com a extensão PDF")
      this.selectedfilesCancelamento = "";
    }} 
    this.filesCancelamento = [];
    for(let i=0; i<this.selectedfilesCancelamento.length; i++){
      this.filesCancelamento.push(this.selectedfilesCancelamento[i].name);
    }
}

uploadFileCancelamento(){
  const imagedata = new FormData();
  for(let i=0; i<this.selectedfilesCancelamento.length; i++){imagedata.append('files[]', this.selectedfilesCancelamento[i], this.selectedfilesCancelamento[i].name)};
   this.db.enviarForm(this.idFormCancelamento, imagedata).subscribe((result) => {
     let jsonDate = new Date().toJSON();
     let horaAgora = new Date(jsonDate).toLocaleTimeString();
       console.log(result);
       let body = {
         "menu" : "CONFIGURAÇÕES", 
         "descricao" : "Formulário Cancelamento Enviado!", 
         "data" : this.dataHoje, 
         "hora" : horaAgora, 
         "usuario" : this.user
       }
       this.db.postLogs(body).subscribe(res => {
         console.log(res);
        this.ngOnInit();
       })
     },(error) => {
       let jsonDate = new Date().toJSON();
       let horaAgora = new Date(jsonDate).toLocaleTimeString();
       console.log(error);
       let body = {
        "menu" : "CONFIGURAÇÕES", 
        "descricao" : "Formulário Cancelamento Enviado!", 
        "data" : this.dataHoje, 
        "hora" : horaAgora, 
        "usuario" : this.user
       }
       this.db.postLogs(body).subscribe(res => {
       console.log(res);
       this.ngOnInit();
      })
     });
}

deleteDocCancelamento(idx){
  this.filesCancelamento.splice(idx,1);
  let fileArray = Array.from(this.selectedfilesCancelamento);
  fileArray.splice(idx,1);
  this.selectedfilesCancelamento = fileArray;
}

salvarValorMinimoFaturamento(){
  if(window.confirm("Deseja Salvar o Valor de Faturamento Minimo?")){
    let dados = {
      valorMinimoFaturamento : this.valorMinimoFaturamento
    }
    this.db.patchConfiguracoes(this.idFaturamentoMinimo,dados).subscribe(res => {
      console.log(res);
      let jsonDate = new Date().toJSON();
      let hora = new Date(jsonDate).toLocaleTimeString();
      let body = {
        "menu" : "CONFIGURAÇÕES", 
        "descricao" : "Valor Minimo do Faturamento Alterado", 
        "data" : this.dataHoje, 
        "hora" : hora, 
        "usuario" : this.user
      }
      this.db.postLogs(body).subscribe(res => {
        console.log(res);
        this.app.openAlert("Valor de Faturamento Minimo salvo com sucesso!");
        this.ngOnInit();
      }, err => {
        console.log(err)
      })
    }, err => {
      console.log(err)
    })
  }
}

salvarAlertas(){
  if(window.confirm("Deseja Salvar os Alertas?")){
    let dados = {
      contasPagar : this.arrayAlertas.contasPagar,
      contasReceber : this.arrayAlertas.contasReceber,
      faturamento : this.arrayAlertas.faturamento,
      tarefas : this.arrayAlertas.tarefas,
      agendamento: this.arrayAlertas.agendamento
    }
    this.db.patchConfiguracoes(this.idAlertas,dados).subscribe(res => {
      console.log(res);
      let jsonDate = new Date().toJSON();
      let hora = new Date(jsonDate).toLocaleTimeString();
      let body = {
        "menu" : "CONFIGURAÇÕES", 
        "descricao" : "Alertas Alterado", 
        "data" : this.dataHoje, 
        "hora" : hora, 
        "usuario" : this.user
      }
      this.db.postLogs(body).subscribe(res => {
        console.log(res);
        this.app.openAlert("Alertas salvo com sucesso!");
        this.ngOnInit();
      }, err => {
        console.log(err)
      })
    }, err => {
      console.log(err)
    })
  }
}

salvarTelefoneWhats(){
  if(window.confirm("Deseja Salvar o Telefone do Whatsapp - Site?")){
    let dados = {
      telefoneWhats : this.celularWhatsSite
    }
    this.db.patchConfiguracoes(this.idTelefoneWhats,dados).subscribe(res => {
      console.log(res);
      let jsonDate = new Date().toJSON();
      let hora = new Date(jsonDate).toLocaleTimeString();
      let body = {
        "menu" : "CONFIGURAÇÕES", 
        "descricao" : "Telefone Whats Alterado", 
        "data" : this.dataHoje, 
        "hora" : hora, 
        "usuario" : this.user
      }
      this.db.postLogs(body).subscribe(res => {
        console.log(res);
        this.app.openAlert("Telefone do Whatsapp - Site salvo com sucesso!");
        this.ngOnInit();
      }, err => {
        console.log(err)
      });
    }, err => {
      console.log(err)
    });
  }
}

filtrarNumSorte(tipo:any){
  if(tipo == "TODOS"){
    this.numerosSorteFiltrados = this.numerosSorte;
  }else if(tipo == "LIVRES"){
    this.numerosSorteFiltrados = this.numerosSorte.filter((x:any) => {return x.usado == false});
  }else if(tipo == "USADOS"){
    this.numerosSorteFiltrados = this.numerosSorte.filter((x:any) => {return x.usado == true});
  }
}

buscarPorNumeroSorte(num:any){
  this.numerosSorteFiltrados = this.numerosSorte.filter((x:any) => {return x.numero == num})
}

mudarStatusNumSorte(e:any,num:any,i:any){
  this.db.patchSorte(num._id,{usado:e.target.checked}).subscribe(resN => {
    console.log(resN);
    let sit = e.target.checked ? 'USADO' : 'LIVRE';
    let dataHoje = new Date(Date.now() - 10800000).toISOString().split('T')[0];
    let jsonDate = new Date().toJSON();
    let hora = new Date(jsonDate).toLocaleTimeString();
    let obj = {
      "menu" : "CONFIGURAÇÔES", 
      "descricao" : "Número da Sorte: "+num.numero+" - Alterado para: "+sit, 
      "data" : dataHoje, 
      "hora" : hora, 
      "usuario" : localStorage.getItem('usuarioVivamax')
    }
    this.db.postLogs(obj).subscribe((res) => {
      console.log(res);
      this.app.openAlert("Situação do Número Alterada com Sucesso!");
      this.numerosSorteFiltrados[i].usado = e.target.checked;
    }, err => {
      console.log(err);
      this.app.openAlert("Erro ao Alterar Situação!");
    })
  }, errN => {console.log(errN)})
}

salvarTelefonePlantao(){
  if(window.confirm("Deseja Salvar o Telefone de Plantão?")){
    let dados = {
      telefonePlantao : this.telPlantao
    }
    this.db.patchConfiguracoes(this.idTelefonePlantao,dados).subscribe(res => {
      console.log(res);
      let jsonDate = new Date().toJSON();
      let hora = new Date(jsonDate).toLocaleTimeString();
      let body = {
        "menu" : "CONFIGURAÇÕES", 
        "descricao" : "Telefone do Plantão Alterado", 
        "data" : this.dataHoje, 
        "hora" : hora, 
        "usuario" : this.user
      }
      this.db.postLogs(body).subscribe(res => {
        console.log(res);
        this.app.openAlert("Telefone do Plantão salvo com sucesso!");
        this.ngOnInit();
      }, err => {
        console.log(err)
      });
    }, err => {
      console.log(err)
    });
  }
}

}