<div class="container-fluid">
    <div class="row align-items-center header">
        <div class="col titulo">
        VISUALIZADOR DE LOGS
    </div>
</div>
<br>
<div style="padding:5px">
    <div class="row  align-items-end">
        <div class="col col-2">
            <label>Data Inicio:</label>
            <input type="date" (change)="verificaData(dataInicio)" class="form-control" [(ngModel)]="dataInicio">
        </div>
        <div class="col col-2">
            <label>Data Fim:</label>
            <input type="date" (change)="verificaData(dataFim)" class="form-control" [(ngModel)]="dataFim">
        </div>
        <div class="col col-1">
            <button class="btn btn-primary" (click)="buscarData(dataInicio,dataFim)" [disabled]="!dataInicio || !dataFim">
                <fa-icon [icon]="['fas', 'search']"></fa-icon>
            </button>
        </div>
        <div class="col col-2">
            <label>Menu:</label>
            <input type="text" class="form-control" [(ngModel)]="menu"oninput="this.value = this.value.toUpperCase()">
        </div>
        <div class="col col-1">
            <button class="btn btn-primary" (click)="buscarMenu(dataInicio,dataFim,menu)" [disabled]="!dataInicio || !dataFim || !menu">
                <fa-icon [icon]="['fas', 'search']"></fa-icon>
            </button>
        </div>
        <div class="col col-2">
            <label>Usuário:</label>
            <input type="text" class="form-control" [(ngModel)]="usuario"oninput="this.value = this.value.toUpperCase()">
        </div>
        <div class="col col-1">
            <button class="btn btn-primary" (click)="buscarUsuario(dataInicio,dataFim,usuario)" [disabled]="!dataInicio || !dataFim || !usuario">
                <fa-icon [icon]="['fas', 'search']"></fa-icon>
            </button>
        </div>
    </div>
</div>
<br>
<table class="table table-striped" style="text-align: center;">
    <thead class="thead-dark">
        <tr>
            <th>Data</th>
            <th>Hora</th>
            <th>Menu</th>
            <th>Usuário</th>
            <th>Descrição</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let log of logs">
            <td>{{ log?.data | date: 'dd/MM/yyyy' }}</td>
            <td>{{ log?.hora }}</td>
            <td>{{ log?.menu }}</td>
            <td>{{ log?.usuario }}</td>
            <td>{{ log?.descricao }}</td>
        </tr>
    </tbody>
</table>
</div>
