import { Component, OnInit } from '@angular/core';
import { DataBaseService } from '../provider.service';
import * as moment from 'moment';
import * as XLSX from 'xlsx';
import { AppComponent } from '../app.component';

@Component({
  selector: 'app-faturamento',
  templateUrl: './faturamento.component.html',
  styleUrls: ['./faturamento.component.css']
})
export class FaturamentoComponent implements OnInit {
  faturamentos:any = [];
  idFaturamento:any = "";
  dataHoje:any = "";
  totalFaturado:number = 0;
  totalParaFaturar:number = 0;
  mesFat:any = "";
  selectedFiles:any = [];
  files:any = [];
  fat:any = [];
  ind:number = 0;
  associados:any = [];
  empresaSelecionada:any = [];
  indiceEmpresaSelecionada:number = 0;
  todosFaturamentos:any = [];
  dataFat:any = "";
  faturando:boolean = false;
  
  constructor(public db:DataBaseService, public app:AppComponent) { }

 ngOnInit(){
    this.dataHoje = new Date(Date.now() - 10800000).toISOString().split('T')[0];
    let dtTemp = "";
    if(Number(this.dataHoje.split('-')[2]) < 20){
      dtTemp = moment(this.dataHoje).subtract(1,'months').date(20).format().split('T')[0];
      this.dataFat = dtTemp.split('-')[0]+"-"+dtTemp.split('-')[1]
      this.getFaturados();
    }else{
      dtTemp = moment(this.dataHoje).date(20).format().split('T')[0];
      this.dataFat = dtTemp.split('-')[0]+"-"+dtTemp.split('-')[1]
      this.getFaturados();
    }
  } 

  getFaturados(){
    let dataFatu = this.dataFat+"-20";
    this.faturamentos = [];
    this.todosFaturamentos = [];
    this.totalFaturado = 0;
    this.totalParaFaturar = 0;
    this.db.getFaturamentoByData(dataFatu).subscribe((data:any) => {
      if(data && data[0]?.faturamentos.length > 0){
        this.idFaturamento = data[0]._id;
        data[0].faturamentos.forEach((f) => {
          f.vidasAtivas = f.vidasAtivas;
          f.total = f.total;
          if(f.status == "NÃO FATURADO"){
            if(f.total){
              this.totalParaFaturar +=f.total;
            }
            this.faturamentos.push(f);
          }
          if(f.status == "FATURADO"){
            if(f.total){
              this.totalFaturado += f.total;
            }
          }
          this.todosFaturamentos.push(f);
        })
      };
    });
  }

  mostrarNaoFaturados(){
    this.faturamentos = this.todosFaturamentos.filter(item => {
      return item.status == "NÃO FATURADO";
    });
  }

  mostrarFaturados(){
    this.faturamentos = this.todosFaturamentos.filter(item => {
      return item.status == "FATURADO";
    });
  }

  mostrarTodos(){
    this.faturamentos = this.todosFaturamentos;
  }

mudarVidasAtivas(i,vidasAtivas){
  this.db.patchFaturamentoValor(this.idFaturamento,i,'vidasAtivas',vidasAtivas).subscribe(res => {
    console.log(res)
  }, err => {
    console.log(err)
  })
}

mudarTotal(f){
  let index = this.todosFaturamentos.map((fat) => {
    return fat.id;
  }).indexOf(f.id);
  if(index >= 0){
    this.db.patchFaturamentoValor(this.idFaturamento,index,'total',f.total).subscribe(res => {
      console.log(res)
    }, err => {
      console.log(err)
    })
  }
}

mudarData(f){
  let index = this.todosFaturamentos.map((fat) => {
    return fat.id;
  }).indexOf(f.id);
  if(index >= 0){
    this.db.patchFaturamentoData(this.idFaturamento,index,f.dataVencimento).subscribe(res => {
      console.log(res)
    }, err => {
      console.log(err)
    })
  }
}

openModalAssociados(emp){
  let index = this.todosFaturamentos.map((fat) => {
    return fat.id;
  }).indexOf(emp.id);
  if(index >= 0){
    this.empresaSelecionada = emp;
    this.indiceEmpresaSelecionada = index;
    this.associados = emp.associados.sort((a:any,b:any) => {
      if(a.associado < b.associado){return -1}else{return 1};
    });
    let modalAssoc = document.getElementById('modAssoc');
    modalAssoc.style.display = "block";
  }
}

closeModalAssociados(){
  let modalAssocClose = document.getElementById('modAssoc');
  modalAssocClose.style.display = "none";
  this.associados = [];
}

  openModalFaturar(f){
    console.log(f)
    let dtFatu = this.dataFat+"-20";
    this.fat = [];
    this.ind = 0;
    this.db.getFaturamentoByData(dtFatu).subscribe((data:any) => {
      if(data && data[0].faturamentos.length > 0){
        let allFaturamentos = data[0].faturamentos;
        let index = allFaturamentos.map((fat) => {
          return fat.id;
        }).indexOf(f.id);
        if(index >= 0){
          this.fat = f;
          this.ind = index;
          let modalFaturar = document.getElementById('modFaturar');
          modalFaturar.style.display = "block";
        }
      };
    });
  }

  closeModalFaturar(){
    let modalFaturarClose = document.getElementById('modFaturar');
    modalFaturarClose.style.display = "none";
    this.files = [];
    this.selectedFiles = [];
  }

  faturar(){
    if(window.confirm("Deseja realmente Faturar essa Conta?")){
      this.faturando = true;
      let f = this.fat;
      if(!f.total){
        f.total = 0;
      }
      let valorDesconto = 0;
      let desc = 0;
      if(f.tipo == 'Empresa'){
        this.db.getEmpresasById(f.id).subscribe((data:any) => {
          if(data.desconto && data.desconto > 0 && f.total > 0){
            valorDesconto = Number((f.total * (data.desconto/100)).toFixed(2));
            desc = f.total - valorDesconto;
          }else{
            desc = f.total;
          }
         this.db.patchFaturamentoStatus(this.idFaturamento,f.id,"FATURADO").subscribe(res => {
            console.log(res);
            let jsonDate = new Date().toJSON();
            let hora = new Date(jsonDate).toLocaleTimeString();        
            let serverUrl = this.db.urlSite;
            let oid = Math.random().toString(36).slice(2, 12);
            let url = {'url': serverUrl+'/upload/'+oid+'.pdf'};
            let anexo = []
            if(this.selectedFiles.length > 0){
             anexo = [url]
            }
            let dados = {
              usuario:localStorage.getItem('usuarioVivamax'),
              data:this.dataHoje,
              hora: hora,
              favorecido:f.nome,
              observacao:null,
              tipoVencimento:"À Vista",
              valor: f.total,
              vencimento:f.dataVencimento,
              status:"Pendente",
              parcela:"1/1",
              anexos:anexo,
              juros: 0,
              desconto: valorDesconto,
              valorTotal: desc,
              tipo:"Entrada",
              tipoEntrada:f.plano,
              formaPagamento:"BOLETO BANCÁRIO",
              tipoLancamento:"",
              aguardandoAprovacao:"",
              tipoDeConta: "",
              codigoBarras:"",
              origem:"Faturamento",
              idFaturamento:this.idFaturamento,
              idFaturado:f.id,
              indiceFaturamento:this.ind,
            }
              this.db.postFinanceiro(dados).subscribe(ress => {
                console.log(ress);
                let bodyLogs = {
                  "menu" : "FATURAMENTO", 
                  "descricao" : f.nome+" Faturada com o valor de "+f.total, 
                  "data" : this.dataHoje, 
                  "hora" : hora, 
                  "usuario" :localStorage.getItem('usuarioVivamax')
                }
                this.db.postLogs(bodyLogs).subscribe(result => {
                  console.log(result);
                if(this.selectedFiles.length > 0){
                  const imagedata = new FormData();
                  for(let i=0; i<this.selectedFiles.length; i++){imagedata.append('files[]', this.selectedFiles[i], this.selectedFiles[i].name)};
                    this.db.enviarBoletoEmpresa(f.id, imagedata,oid).subscribe((res) => {
                      console.log(res);
                      /// this.enviarEmailComAnexoBoleto()
                      this.app.openAlert("Boleto Enviado Com Sucesso!");
                      this.getFaturados();
                      this.closeModalFaturar();
                      this.faturando = false;
                    },(error) => {
                      console.log(error);
                      this.app.openAlert("Boleto Enviado Com Sucesso!");
                      this.getFaturados();
                      this.closeModalFaturar();
                      this.faturando = false;
                    });      
                }else{
                  /// this.enviarEmailSemBoleto()
                  this.getFaturados();
                  this.closeModalFaturar();
                  this.faturando = false;
                }
              },errr => {
                console.log(errr)
              })
              },errr => {
                console.log(errr)
              }) 
          }, err => {
            console.log(err)
          });
        })
      }
      if(f.tipo == 'SubContrato'){
        this.db.getSubContratosById(f.id).subscribe((data:any) => {
          if(data.desconto && data.desconto > 0){
            valorDesconto = Number((f.total * (data.desconto/100)).toFixed(2));
            desc = f.total - valorDesconto;
          }else{
            desc = f.total
          }
         this.db.patchFaturamentoStatus(this.idFaturamento,f.id,"FATURADO").subscribe(res => {
            console.log(res);
            let jsonDate = new Date().toJSON();
            let hora = new Date(jsonDate).toLocaleTimeString();
            const d = new Date();
            let m:any = d.getMonth();
            if(m == 0){
              m = "01"
            }
            let serverUrl = this.db.urlSite;
            let oid = Math.random().toString(36).slice(2, 12);
            let url = {'url': serverUrl+'/upload/'+oid+'.pdf'};
            let anexo = []
            if(this.selectedFiles.length > 0){
             anexo = [url]
            }
            let dados = {
              usuario:localStorage.getItem('usuarioVivamax'),
              data:this.dataHoje,
              hora: hora,
              favorecido:f.nome,
              observacao:"",
              tipoVencimento:"À Vista",
              valor: f.total,
              vencimento:f.dataVencimento,
              status:"Pendente",
              parcela:"1/1",
              anexos:anexo,
              juros: 0,
              desconto: valorDesconto,
              valorTotal: desc,
              tipo:"Entrada",
              formaPagamento:"BOLETO BANCÁRIO",
              tipoLancamento:"",
              aguardandoAprovacao:"",
              tipoDeConta: "",
              codigoBarras:"",
              origem:"Faturamento",
              idFaturamento:this.idFaturamento,
              idFaturado:f.id,
              indiceFaturamento:this.ind,
            }
            this.db.postFinanceiro(dados).subscribe(ress => {
                console.log(ress);
                let bodyLogs = {
                  "menu" : "FATURAMENTO", 
                  "descricao" : f.nome+" Faturada com o valor de "+f.total, 
                  "data" : this.dataHoje, 
                  "hora" : hora, 
                  "usuario" :localStorage.getItem('usuarioVivamax')
                }
                this.db.postLogs(bodyLogs).subscribe(result => {
                  console.log(result);
                if(this.selectedFiles.length > 0){
                  const imagedata = new FormData();
                  for(let i=0; i<this.selectedFiles.length; i++){imagedata.append('files[]', this.selectedFiles[i], this.selectedFiles[i].name)};
                    this.db.enviarBoletoSubContrato(f.id, imagedata,oid).subscribe((res) => {
                      console.log(res);
                      /// this.enviarEmailComAnexoBoleto()
                      this.app.openAlert("Boleto Enviado Com Sucesso!");
                      this.getFaturados();
                      this.closeModalFaturar();
                      this.faturando = false;
                    },(error) => {
                      console.log(error);
                      this.app.openAlert("Boleto Enviado Com Sucesso!");
                      this.getFaturados();
                      this.closeModalFaturar();
                      this.faturando = false;
                    });     
                }else{
                  /// this.enviarEmailSemBoleto()
                  this.getFaturados();
                  this.closeModalFaturar();
                  this.faturando = false;
                }
              },errr => {
                console.log(errr)
              })
              },errr => {
                console.log(errr)
              })
          }, err => {
            console.log(err)
          });
        })
      }
      if(f.tipo == "Afastado"){
        this.db.patchFaturamentoStatus(this.idFaturamento,f.id,"FATURADO").subscribe(res => {
          console.log(res);
          let jsonDate = new Date().toJSON();
          let hora = new Date(jsonDate).toLocaleTimeString();
          const d = new Date();
          let m:any = d.getMonth();
          if(m == 0){
            m = "01"
          }
          let serverUrl = this.db.urlSite;
          let oid = Math.random().toString(36).slice(2, 12);
          let url = {'url': serverUrl+'/upload/'+oid+'.pdf'};
          let anexo = []
          if(this.selectedFiles.length > 0){
           anexo = [url]
          }
          let dados = {
            usuario:localStorage.getItem('usuarioVivamax'),
            data:this.dataHoje,
            hora: hora,
            favorecido:f.nome,
            observacao:"",
            tipoVencimento:"À Vista",
            valor: f.total,
            vencimento:f.dataVencimento,
            status:"Pendente",
            parcela:"1/1",
            anexos:anexo,
            juros: 0,
            desconto: 0,
            valorTotal: f.total,
            tipo:"Entrada",
            formaPagamento:"BOLETO BANCÁRIO",
            tipoLancamento:"",
            aguardandoAprovacao:"",
            tipoDeConta: "",
            codigoBarras:"",
            origem:"Faturamento",
            idFaturamento:this.idFaturamento,
            idFaturado:f.id,
            indiceFaturamento:this.ind,
          }
            this.db.postFinanceiro(dados).subscribe(ress => {
              console.log(ress);
              let bodyLogs = {
                "menu" : "FATURAMENTO", 
                "descricao" : f.nome+" Faturada com o valor de "+f.total, 
                "data" : this.dataHoje, 
                "hora" : hora, 
                "usuario" :localStorage.getItem('usuarioVivamax')
              }
              this.db.postLogs(bodyLogs).subscribe(result => {
                console.log(result);
                /// this.enviarEmailSemBoleto()
                this.getFaturados();
                this.closeModalFaturar();
                this.faturando = false;
            },errr => {
              console.log(errr)
            })
            },errr => {
              console.log(errr)
            })
        }, err => {
          console.log(err)
        });
      }
  }
}

onChangeClick(event){
  let arrayTypes = ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'];
  this.selectedFiles = <FileList>event.srcElement.files;
  for(let i=0; i<this.selectedFiles.length; i++){
      if(!arrayTypes.includes(this.selectedFiles[i].type)){
      this.app.openAlert("Tipo de Arquivo Não Suportado!\nEscolha uma imagem com a extensão .JPG, .JPEG, .PNG ou PDF")
      this.selectedFiles = "";
    }} 
    this.files = [];
    for(let i=0; i<this.selectedFiles.length; i++){
      this.files.push(this.selectedFiles[i].name);
    }
}

onChange(event){
  let arrayTypes = ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'];
  this.selectedFiles = <FileList>event;
  for(let i=0; i<this.selectedFiles.length; i++){
      if(!arrayTypes.includes(this.selectedFiles[i].type)){
      this.app.openAlert("Tipo de Arquivo Não Suportado!\nEscolha uma imagem com a extensão .JPG, .JPEG, .PNG ou PDF")
      this.selectedFiles = "";
    }} 
    this.files = [];
    for(let i=0; i<this.selectedFiles.length; i++){
      this.files.push(this.selectedFiles[i].name);
    }
}

checkAssociado(e,i){
  this.associados[i].marcado =  e.srcElement.checked;
}

salvarAssociadosFaturamento(associados){
  if(window.confirm("Deseja realmente Salvar os Associados")){
    let novoTotal:number = 0;
    let novaVida:number = 0;
    let ctrl = 1;
    associados.forEach((a) => {
      a.mensalidade = a.mensalidade
      if(a.marcado){
        novoTotal += a.mensalidade
        novaVida++
      }
      if(associados.length == ctrl){
        this.db.patchFaturamentoAssociados(this.idFaturamento,this.indiceEmpresaSelecionada,associados,novoTotal,novaVida).subscribe(res => {
          console.log(res);
          this.printXsl();
          this.getFaturados();
          this.closeModalAssociados();
        })
      }else{
        ctrl++;
      }
    })
  }
}

printXsl(){
  var ws = XLSX.utils.table_to_sheet(document.getElementById('divToPrintFaturamento'),{raw:true});
  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  XLSX.writeFile(wb, 'vivamax.xlsx',{cellStyles:true});
}

excluirFaturamento(fat){
   if(window.confirm("Deseja Realmente Excluir esse Faturamento? ESSA OPERAÇÂO NÂO PODE SER REVERTIDA!")){
    let jsonDate = new Date().toJSON();
    let hora = new Date(jsonDate).toLocaleTimeString();
    this.db.deleteFaturamento(this.idFaturamento,fat.id).subscribe(res => {
      console.log(res);
      let o = {
        "menu" : "FATURAMENTO", 
        "descricao" : "Faturamento Excluido: "+fat.nome, 
        "data" : this.dataHoje, 
        "hora" : hora, 
        "usuario" : localStorage.getItem('usuarioVivamax')
      }
      this.db.postLogs(o).subscribe(res => {
        console.log(res);
        this.app.openAlert("Faturamento Excluido com Sucesso!");
        this.getFaturados();
      })
    },err => {
      console.log(err);
      this.app.openAlert("Erro ao Excluir Faturamento!")
    })
  }
}

}

