<div class="container-fluid">
<div id="myModal" class="modalLightbox">
    <span (click)="closeModal()" class="close">&times;</span>
    <div class="prev" (click)="prevImg()">&lt;</div>
    <img [src]="imagemSelecionada" class="imagemLightbox">
    <div class="next" (click)="nextImg()">&gt;</div>
</div> 
<div style="width: 800px; height: 400px;display: none;">
    <ng2-pdfjs-viewer 
      #pdfViewerCaixa
      [externalWindow]="true"
      [downloadFileName]="'vivamax.pdf'"
      [openFile]="false"
      [viewBookmark]="false"
      [download]="true"></ng2-pdfjs-viewer>
  </div>
  <!-- MODAL VER CONTA RECEBER -->
<div class="modal" id="modalVerContaReceber">
    <div style="padding:50px">
        <div class="row">
            <div class="col col-6">
                <label>Favorecido</label>
                <select [(ngModel)]="favorecidoEditar" class="form-control">
                    <option selected disabled></option>
                    <option *ngFor="let favorecido of favorecidos">{{favorecido.razaoSocial}}</option>
                </select>
            </div>
            <div class="col col-6">
                <label>Observação</label>
                <input type="text" class="form-control" [(ngModel)]="observacaoContaEditar" oninput="this.value = this.value.toUpperCase()">
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col col-4">
                <label>Tipo Vencimento</label>
                <select class="form-control" [(ngModel)]="tipoVencimentoContaEditar" readonly>
                    <option value="À Vista">À Vista</option>
                    <option value="Parcelado">Parcelado</option>
                </select>
            </div>
            <div class="col col-4">
                <label>Tipo de Entrada</label>
                <select class="form-control" [(ngModel)]="tipoEntradaContaEditar" [ngStyle]="{'border-color': !tipoEntradaContaEditar ? '#dd443e' : '#3cb42c'}">
                    <option [value]=""></option>
                    <option [value]="tC.nome" *ngFor="let tC of tiposEntrada">{{tC.nome}}</option>
                </select>
            </div>
            <div class="col col-4">
                <label>Banco</label>
                <select class="form-control" [(ngModel)]="bancoEditar">
                    <option [value]=""></option>
                    <option [value]="bank.nome" *ngFor="let bank of todosBancos">{{bank.nome}}</option>
                </select>
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col col-3">
                <label>Valor</label>
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon1">R$</span>
                    </div>
                    <input type="number" class="form-control" [(ngModel)]="valorContaEditar" (change)="calcularTotalEditar()" min="0">
                </div>    
            </div>
            <div class="col col-3">
                <label>Vencimento</label>
                <input type="date" class="form-control" [(ngModel)]="vencimentoContaEditar">
            </div>
            <div class="col col-3">
                <label>Parcela</label>
                <input type="text" class="form-control" [(ngModel)]="parcelaConta" readonly>
            </div> 
            <div class="col col-3">
                <label>Forma de Recebimento</label>
                <select [(ngModel)]="formaPagamentoEditar" class="form-control" [ngStyle]="{'border-color': !formaPagamentoEditar ? '#dd443e' : '#3cb42c'}">
                    <option selected></option>
                    <option *ngFor="let forma of todasFormasPagamento">{{forma.forma}}</option>
                </select>
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col col-3">
                <label>Juros</label>
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon1">R$</span>
                    </div>
                    <input type="number" class="form-control" [(ngModel)]="jurosContaEditar" (change)="calcularTotalEditar()" min="0">
                </div>  
            </div>
            <div class="col col-3">
                <label>Desconto</label>
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon1">R$</span>
                    </div>
                    <input type="number" class="form-control" [(ngModel)]="descontoContaEditar" (change)="calcularTotalEditar()" min="0">
                </div>  
            </div>
            <div class="col col-3">
                <label>Total a Receber</label>
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon1">R$</span>
                    </div>
                    <input type="number" class="form-control" [(ngModel)]="valorTotalContaEditar" readonly>
                </div>  
            </div>
            <div class="col col-3">
                <label>Data Pagamento</label>
                <input type="date" class="form-control" [(ngModel)]="dataPagamentoEditar">
            </div>
        </div>
        <br>
        <div class="row justify-content-md-center">
            <div class="col col-3" *ngFor="let pdf of pdfsConta;" style="margin: 10px; background-color:#ce2626; color:white; height: 250px; width:100%; text-align: center; border-radius: 10px;">
                <div class="delButton" name="delButton" *ngIf="db?.usuario?.permissoes['financeiro'] == 'EDITAR'">
                    <button class="btn btn-dark btn-sm" (click)="delDocConta(pdf.url)"><strong>X</strong></button>
                </div>
                <div (click)="openModalPdfViewer(pdf.url)" style="position: relative;left:50%;top:50%;transform: translate(-50%,-50%);cursor:pointer">
                    <fa-icon [icon]="['fas', 'file-pdf']" style="font-size: 5em;"></fa-icon>
                        <br>DOCUMENTO PDF
                </div>
            </div>
            <div class="col col-3 img-container" *ngFor="let img of imagesConta; let i = index" style="margin: 10px; text-align: center;">
                <div class="delButton" name="delButton" *ngIf="db?.usuario?.permissoes['financeiro'] == 'EDITAR'">
                    <button class="btn btn-danger btn-sm" (click)="delDocConta(img.url)"><strong>X</strong></button>
                </div>
                <img [src]="img.url" (click)="openModalLightBox(img.url, i)" style="border-radius: 10px; height: 250px; width:100%;" crossorigin="anonymous">
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col">
                <div class="uploadfilecontainer" (click)="fileDocumentosREditar.click()" appDragDrop (onFileDropped)="onChangeContaEditar($event)">
                    <input hidden type="file" #fileDocumentosREditar (change)="onChangeClickContaEditar($event)" multiple>
                </div>
                <div class="row align-items-center">
                    <div class="col col-1">
                        &nbsp;
                    </div>
                    <div class="col">
                        <div class="files-list" *ngFor="let file of filesContaEditar; let i= index">
                            <p>{{ file }}</p>
                        </div> 
                    </div>
                    <div class="col col-2" [hidden]="filesContaEditar.length < 1">
                        <button class="btn btn-primary btn-block" (click)="anexarDoc()">ANEXAR</button>
                    </div>
                    <div class="col col-1">
                        &nbsp;
                    </div>
                </div>
            </div>
        </div>
       <br>
       <div class="row">
           <div class="col">
               &nbsp;
           </div>
           <div class="col col-2">
                <button class="btn btn-danger btn-block" (click)="closeModalVerContaReceber()">FECHAR</button>
           </div>
           <div class="col col-3">
                <button class="btn btn-primary btn-block" (click)="editarConta()" [disabled]="filesContaEditar.length > 0">EDITAR CONTA</button>
           </div>
          <div class="col">
            &nbsp;
          </div>
       </div>
    </div>
</div>
<!-- MODAL VER CONTA PAGAR -->
<div class="modal" id="modalVerContaPagarCaixa">
    <div style="padding:50px">
        <div *ngIf="tipoDeConta == 'CONTA A PAGAR'">
            <div class="row">
                <div class="col col-4">
                    <label>Data Pagamento</label>
                    <input type="date" class="form-control" [(ngModel)]="dataPagamentoEditar">
                </div>
                <div class="col col-4">
                    <label>Hora Pagamento</label>
                    <input type="text" class="form-control" [(ngModel)]="horaPagamentoEditar" readonly>
                </div>
                <div class="col col-4">
                    <label>Banco</label>
                    <select class="form-control" [(ngModel)]="bancoEditar">
                        <option [value]=""></option>
                        <option [value]="bank.nome" *ngFor="let bank of todosBancos">{{bank.nome}}</option>
                    </select>
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col col-5" *ngIf="tipoConta == 'Saida'">
                    <label>Fornecedor</label>
                    <input type="text" class="form-control" [(ngModel)]="fornecedorEditar">
                </div>
                <div class="col col-5" *ngIf="tipoConta == 'Entrada'">
                    <label>Favorecido</label>
                    <input type="text" class="form-control" [(ngModel)]="favorecidoEditar">
                </div>
                <div class="col col-4">
                    <label>Observação</label>
                    <input type="text" class="form-control" [(ngModel)]="observacaoContaEditar" oninput="this.value = this.value.toUpperCase()">
                </div>
                <div class="col col-3">
                    <label>Tipo Vencimento</label>
                    <select class="form-control" [(ngModel)]="tipoVencimentoContaEditar" disabled>
                        <option value="À Vista">À Vista</option>
                        <option value="Parcelado">Parcelado</option>
                    </select>
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col col-3">
                    <label>Valor</label>
                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                        <span class="input-group-text" id="basic-addon1">R$</span>
                        </div>
                        <input type="number" class="form-control" [(ngModel)]="valorContaEditar" (change)="calcularTotalEditar()" min="0">
                    </div>    
                </div>
                <div class="col col-3">
                    <label>Vencimento</label>
                    <input type="date" class="form-control" [(ngModel)]="vencimentoContaEditar">
                </div>
                <div class="col col-3">
                    <label>Parcela</label>
                    <input type="text" class="form-control" [(ngModel)]="parcelaConta" readonly>
                </div>
                <div class="col col-3">
                    <label>Forma de Pagamento</label>
                    <select [(ngModel)]="formaPagamentoEditar" class="form-control">
                        <option selected></option>
                        <option *ngFor="let forma of db.todasFormasPagamento" [value]="forma.forma">{{forma.forma}}</option>
                    </select>
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col col-3">
                    <label>Juros</label>
                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                        <span class="input-group-text" id="basic-addon1">R$</span>
                        </div>
                        <input type="number" class="form-control" [(ngModel)]="jurosContaEditar" (change)="calcularTotalEditar()" min="0">
                    </div>  
                </div>
                <div class="col col-3">
                    <label>Taxas</label>
                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                        <span class="input-group-text" id="basic-addon1">R$</span>
                        </div>
                        <input type="number" class="form-control" [(ngModel)]="taxasContaEditar" (change)="calcularTotalEditar()" min="0">
                    </div>  
                </div>
                <div class="col col-3">
                    <label>Desconto</label>
                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                        <span class="input-group-text" id="basic-addon1">R$</span>
                        </div>
                        <input type="number" class="form-control" [(ngModel)]="descontoContaEditar" (change)="calcularTotalEditar()" min="0">
                    </div>  
                </div>
                <div class="col col-3">
                    <label>Total a Pagar</label>
                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                        <span class="input-group-text" id="basic-addon1">R$</span>
                        </div>
                        <input type="number" class="form-control" [(ngModel)]="valorTotalContaEditar" readonly>
                    </div>  
                </div>
            </div>
        </div>
    <!-- INICIO CAMPOS REEMBOLSO -->
     <div *ngIf="tipoDeConta == 'REEMBOLSO'" style="pointer-events: none;">
        <div class="row">
            <div class="col col-4">
                <label>Data da Solicitação</label><br>
                <input type="date" class="form-control" [(ngModel)]="dataSolicitacaoEditar">
            </div>
            <div class="col col-4">
                <label>Motivo</label><br>
                <input type="text" class="form-control" [(ngModel)]="motivoReembolsoEditar" oninput="this.value = this.value.toUpperCase()">
            </div>
            <div class="col col-4">
                <label>Colaborador</label><br>
                <select [(ngModel)]="colaboradorReembolsoEditar" class="form-control">
                    <option selected disabled></option>
                    <option *ngFor="let colaborador of colaboradores" [value]="colaborador.nome">{{colaborador.nome}}</option>
                </select>
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col col-4">
                <label>Situação</label><br>
                <select [(ngModel)]="situacaoReembolsoEditar" class="form-control">
                    <option value=""></option>
                    <option value="DEFERIDO">DEFERIDO</option>
                    <option value="INDEFERIDO">INDEFERIDO</option>
                </select>
            </div>
            <div class="col col-8" *ngIf="situacaoReembolsoEditar == 'INDEFERIDO'">
                <label>Motivo Indeferimento</label>
                <input type="text" class="form-control" [(ngModel)]="observacaoReembolso">
            </div>
            <div class="col col-4" *ngIf="situacaoReembolsoEditar == 'DEFERIDO'">
                <label>Forma de Reembolso</label>
                <select [(ngModel)]="formaPagamentoEditar" class="form-control" [ngStyle]="{'border-color': !formaPagamentoEditar ? '#dd443e' : '#3cb42c'}">
                    <option selected></option>
                    <option *ngFor="let forma of todasFormasPagamento" [value]="forma.forma">{{forma.forma}}</option>
                </select>
            </div>
            <div class="col col-4" *ngIf="situacaoReembolsoEditar == 'DEFERIDO'">
                <label>Data do Reembolso</label><br>
                <input type="date" class="form-control" [(ngModel)]="dataReembolso">
            </div>
        </div>
        <br>
        <div class="row" *ngIf="situacaoReembolsoEditar == 'DEFERIDO'">
            <div class="col col-12">
                <label>Observação</label>
                <input type="text" class="form-control" [(ngModel)]="observacaoReembolso" oninput="this.value = this.value.toUpperCase()">
            </div>
        </div>
        <br>
        <div class="row" *ngFor="let r of listaReembolso">
            <div class="col col-8">
               {{r?.tipo}}
            </div>
            <div class="col col-4">
                {{r?.valor | currency:'BRL'}}
            </div>
        </div>
    </div>
    <!-- FIM CAMPOS REEMBOLSO -->
     <!-- VENCIMENTOS -->
     <div *ngIf="tipoDeConta == 'VENCIMENTOS'">
        <div class="row">
            <div class="col col-5">
                <label>Colaborador</label><br>
                <select [(ngModel)]="colaboradorVencimento" class="form-control">
                    <option selected disabled></option>
                    <option *ngFor="let colaborador of colaboradores" [value]="colaborador.nome">{{colaborador.nome}}</option>
                </select>
            </div>
            <div class="col col-4">
                <label>Tipo Vencimento</label>
                <select class="form-control" [(ngModel)]="tipoVencimentoCadastro">
                    <option value="13º SALÁRIO">13º SALÁRIO</option>
                    <option value="ADIANTAMENTO">ADIANTAMENTO</option>
                    <option value="AJUDA DE CUSTO">AJUDA DE CUSTO</option>
                    <option value="BÔNUS DE PRODUTIVIDADE">BÔNUS DE PRODUTIVIDADE</option>
                    <option value="FÉRIAS">FÉRIAS</option>
                    <option value="PARTICIPAÇÃO DOS LUCROS">PARTICIPAÇÃO DOS LUCROS</option>
                    <option value="PRÓ-LABORE">PRÓ-LABORE</option>
                    <option value="RECISÃO">RECISÃO</option>
                    <option value="SALÁRIO">SALÁRIO</option>
                    <option value="VALE REFEIÇÃO">VALE REFEIÇÃO</option>
                    <option value="VALE TRANSPORTE">VALE TRANSPORTE</option>
                </select>
            </div>
            <div class="col col-3">
                <label>Vencimento</label>
                <input type="date" class="form-control" [(ngModel)]="vencimentoContaEditar">
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col col-3">
                <label>Valor</label>
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon1">R$</span>
                    </div>
                    <input type="number" class="form-control" [(ngModel)]="valorContaEditar" min="0">
                </div>    
            </div>       
            <div class="col col-3">
                <label>Forma de Pagamento</label>
                <select [(ngModel)]="formaPagamentoEditar" class="form-control" [ngStyle]="{'border-color': !formaPagamentoEditar ? '#dd443e' : '#3cb42c'}">
                    <option selected></option>
                    <option *ngFor="let forma of todasFormasPagamento" [value]="forma.forma">{{forma.forma}}</option>
                </select>
            </div>   
            <div class="col col-6">
                <label>Observação</label>
                <input type="text" class="form-control" [(ngModel)]="observacaoContaEditar" oninput="this.value = this.value.toUpperCase()">
            </div>
        </div>
    </div>
    <!-- FIM VENCIMENTOS -->
    <br>
        <div class="row">
            <div class="col">
                <label>Selecionar Centro de Custo:</label>
                <form [formGroup]="ccEditar">
                    <div class="form-group col-3" style="text-align: left;" [hidden]="ccListEditar?.length > 0">
                        <br>
                      <button class="btn btn-outline-success btn-sm" (click)="addCCEditar()">
                        <fa-icon [icon]="['fas', 'plus-circle']" size="1x"></fa-icon> ADICIONAR CENTRO DE CUSTO
                      </button>
                    </div>
                    <div class="card-body" formArrayName="centroCusto">
                        <div [formGroupName]="i" class="row" *ngFor="let centros of ccFormGroupEditar.controls; let i = index;">
                            <div class="form-group col-5">
                                <label>Centro de Custo</label><br>
                                <select [ngClass]="{'desabilita': ccEditar.controls.centroCusto.value[i].contaOrcamentaria}" formControlName="centroDeCusto" class="form-control" [ngStyle]="{'border-color': !ccListEditar.valid ? '#dd443e' : '#3cb42c'}">
                                    <option value="Administrativo">Administrativo</option>
                                    <option value="Comercial">Comercial</option>
                                    <option value="Publicidade">Publicidade</option>
                                </select>
                            </div>
                            <div class="form-group col-7">
                                <label>Conta Orçamentária</label><br>
                                <select [ngClass]="{'desabilita': ccEditar.controls.centroCusto.value[i].contaContabil}" formControlName="contaOrcamentaria" class="form-control" [ngStyle]="{'border-color': !ccListEditar.valid ? '#dd443e' : '#3cb42c'}">
                                    <option selected [value]=""></option>
                                    <option *ngFor="let cC of (listaDeCC | filterCC: ccEditar.controls.centroCusto.value[i].centroDeCusto)" [value]="cC.contaOrcamentaria">{{cC.contaOrcamentaria}}</option>
                                </select>
                            </div>
                            <div class="form-group col-6">
                                <label>Conta Contabil</label><br>
                                <select formControlName="contaContabil" class="form-control" [ngStyle]="{'border-color': !ccListEditar.valid ? '#dd443e' : '#3cb42c'}" *ngFor="let cO of (listaDeCC | filterCO: ccEditar.controls.centroCusto.value[i].contaOrcamentaria)">
                                    <option selected [value]=""></option>
                                    <option *ngFor="let c of cO.contasContabeis" [value]="c.conta">{{c.conta}}</option>
                                </select>
                            </div>
                            <div class="form-group col-4">
                                <label>Valor do Centro de Custo</label><br>
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="basic-addon1">R$</span>
                                    </div>
                                    <input type="number" min="0" class="form-control" placeholder="Valor" formControlName="valor" [ngStyle]="{'border-color': !ccListEditar.valid ? '#dd443e' : '#3cb42c'}">
                                </div> 
                            </div>
                            <div class="form-group col-2 text-right">
                                <br>
                                <button class="btn btn-danger" (click)="removeCCEditar(i)">
                                    <fa-icon [icon]="['fas', 'minus-circle']" size="1x"></fa-icon>
                                </button>&nbsp;
                                <button class="btn btn-success" (click)="addCCEditar()" [disabled]="!ccListEditar.valid">
                                    <fa-icon [icon]="['fas', 'plus-circle']" size="1x"></fa-icon>
                                </button>
                            </div>
                        </div>   
                    </div> 
                </form>
            </div>
        </div>
        <br>
        <div class="row justify-content-md-center">
            <div class="col col-3" *ngFor="let pdf of pdfsConta;" style="margin: 10px; background-color:#ce2626; color:white; height: 250px; width:100%; text-align: center; border-radius: 10px;">
                <div class="delButton" name="delButton" *ngIf="db?.usuario?.permissoes['financeiro'] == 'EDITAR'">
                    <button class="btn btn-dark btn-sm" (click)="delDocConta(pdf.url)"><strong>X</strong></button>
                </div>
                <div (click)="openModalPdfViewer(pdf.url)" style="position: relative;left:50%;top:50%;transform: translate(-50%,-50%);cursor:pointer">
                    <fa-icon [icon]="['fas', 'file-pdf']" style="font-size: 5em;"></fa-icon>
                        <br>DOCUMENTO PDF
                </div>
            </div>
            <div class="col col-3 img-container" *ngFor="let img of imagesConta; let i = index" style="margin: 10px; text-align: center;">
                <div class="delButton" name="delButton" *ngIf="db?.usuario?.permissoes['financeiro'] == 'EDITAR'">
                    <button class="btn btn-danger btn-sm" (click)="delDocConta(img.url)"><strong>X</strong></button>
                </div>
                <img [src]="img.url" (click)="openModalLightBox(img.url, i)" style="border-radius: 10px; height: 250px; width:100%;" crossorigin="anonymous">
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col">
                <div class="uploadfilecontainer" (click)="fileDocumentosEditar.click()" appDragDrop (onFileDropped)="onChangeContaEditar($event)">
                    <input hidden type="file" #fileDocumentosEditar (change)="onChangeClickContaEditar($event)" multiple>
                </div>
                <div class="row align-items-center">
                    <div class="col col-1">
                        &nbsp;
                    </div>
                    <div class="col">
                        <div class="files-list" *ngFor="let file of filesContaEditar; let i= index">
                            <p>{{ file }}</p>
                        </div> 
                    </div>
                    <div class="col col-2" [hidden]="filesContaEditar.length < 1">
                        <button class="btn btn-primary btn-block" (click)="anexarDoc()">ANEXAR</button>
                    </div>
                    <div class="col col-1">
                        &nbsp;
                    </div>
                </div>
            </div>
        </div>
       <br>
       <div class="row">
           <div class="col">
               &nbsp;
           </div>
           <div class="col col-2">
                <button class="btn btn-danger btn-block" (click)="closeModalVerConta()">FECHAR</button>
           </div>
           <div class="col col-2" *ngIf="tipoDeConta != 'REEMBOLSO'">
                <button class="btn btn-primary btn-block" (click)="editarConta()" [disabled]="filesContaEditar.length > 0 || ccListEditar.value.length < 1 || !ccListEditar.valid">EDITAR CONTA</button>
           </div>
           <div class="col">
                &nbsp;
          </div>
       </div>
    </div>
</div>
<div class="row  align-items-center header">
    <div class="col col-1">
        &nbsp;
    </div>
    <div class="col titulo" style="text-align: center;">
        FLUXO DE CAIXA
    </div>
    <div class="col col-9 titulo" style="text-align: center;">
        <div class="row">
            <div class="col">
                Saldo Anterior:<br> {{ saldoAnterior | currency:'BRL' }}
            </div>
            <div class="col">
                Entradas<br> {{totalCaixaRecebido | currency:'BRL'}}
            </div>
            <div class="col">
                Saidas<br> {{totalCaixaPago | currency:'BRL'}}
            </div>
            <div class="col">
                Saldo do Mês<br> {{saldo | currency:'BRL'}}
            </div>
            <div class="col">
                Saldo Total<br> {{saldoAnterior - totalCaixaPago + totalCaixaRecebido | currency:'BRL'}}
            </div>
        </div>
    </div>
</div>
<br>
<div class="row align-items-end" style="padding:5px">
    <div class="col col-3">
        <label>Data Inicio</label>
        <input type="date" class="form-control" [(ngModel)]="dataInicioConta">
    </div>
    <div class="col col-3">
        <label>Data Fim</label>
        <input type="date" class="form-control" [(ngModel)]="dataFimConta">
    </div>
    <div class="col col-6">
        <label>Fornecedor / Favorecido</label>
        <ng-select class="form-control" [items]="fornecedores" bindLabel="razaoSocial" appendTo="body" [(ngModel)]="fornecedorConta"></ng-select>
    </div>
    <div class="col col-3">
        <label>Banco</label>
        <select [(ngModel)]="banco" class="form-control">
            <option [value]="" selected></option>
            <option *ngFor="let banco of todosBancos" [value]="banco.nome">{{banco.nome}}</option>
        </select>
    </div>
    <div class="col col-3">
        <label>Forma de Pagamento</label>
        <select [(ngModel)]="formaPagamento" class="form-control">
            <option value="" selected></option>
            <option *ngFor="let forma of todasFormasPagamento" [value]="forma.forma">{{forma.forma}}</option>
        </select>
    </div>
    <div class="col col-1">
        <label>&nbsp;</label>
        <button class="btn btn-primary" (click)="getContas(dataInicioConta,dataFimConta,fornecedorConta,formaPagamento,banco)" [disabled]="!dataInicioConta || !dataFimConta"><fa-icon [icon]="['fas', 'search']"></fa-icon></button>
    </div>
    <div class="col" style="text-align:right">
        <button class="btn btn-outline-primary" (click)="filtrar('saida')" [disabled]="todoCaixa.length == 0">Todas Saidas</button>
        &nbsp;&nbsp;
        <button class="btn btn-outline-primary" (click)="filtrar('entrada')" [disabled]="todoCaixa.length == 0">Todas Entradas</button>
    </div>  
</div>
<br>
<div *ngIf="caixa.length > 0">
    <table class="table table-striped" style="text-align: center; font-size: 0.9em;">
        <thead class="thead-dark">
            <tr>
                <th>Forma</th>
                <th>Data Pagamento</th>
                <th>Fornecedor / Favorecido</th>
                <th>Observação</th>
                <th>Banco</th>
                <th>Vencimento</th>
                <th>Tipo</th>
                <th>Valor Original</th>
                <th>Valor Pago</th>
                <th>Parcela</th> 
                <th *ngIf="db?.usuario?.permissoes['financeiro'] == 'EDITAR'">Editar</th>
                <th *ngIf="db?.usuario?.permissoes['financeiro'] == 'EDITAR'">Estornar</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let conta of todoCaixa">
                <td [ngStyle]="{'color': conta?.tipo == 'Entrada' ? 'green' : 'red'}"><strong>{{ conta?.tipo }}</strong></td>
                <td>{{ conta?.dataPagamento | date:'dd/MM/yyyy' }}</td>
                <td>{{ conta?.fornecedor }}{{ conta?.favorecido }}</td>
                <td>{{ conta?.observacao }}</td>
                <td>{{ conta?.banco }}</td>
                <td>{{ conta?.vencimento | date:'dd/MM/yyyy' }}</td>
                <td>{{ conta?.tipoVencimento }}</td>
                <td>{{ conta?.valor | currency:'BRL' }}</td>
                <td>{{ conta?.valorTotal | currency:'BRL' }}</td>
                <td>{{ conta?.parcela }}</td>
                <td *ngIf="db?.usuario?.permissoes['financeiro'] == 'EDITAR' && conta?.tipo == 'Entrada'">
                    <button class="btn btn-outline-success" (click)="openModalVerContaReceber(conta)">
                        <fa-icon [icon]="['fas', 'edit']"></fa-icon>
                    </button>
                </td>
                <td *ngIf="db?.usuario?.permissoes['financeiro'] == 'EDITAR' && conta?.tipo == 'Saida'">
                    <button class="btn btn-outline-success" (click)="openModalVerConta(conta)">
                        <fa-icon [icon]="['fas', 'edit']"></fa-icon>
                    </button>
                </td>
                <td *ngIf="db?.usuario?.permissoes['financeiro'] == 'EDITAR'">
                    <button class="btn btn-outline-danger" (click)="estornarConta(conta)" [disabled]="conta?._id == undefined">
                        <fa-icon [icon]="['fas', 'hand-holding-usd']"></fa-icon>
                    </button>
                </td>
            </tr>
        </tbody>
    </table>
</div>
</div>