<div class="container-fluid">
    <!-- MODAL CARGOS  -->
    <div class="modal" id="modalCargos">
        <div style="padding:50px">
            <form [formGroup]="cargos">
                <div class="row" formArrayName="cargo">
                    <div class="col col-12" style="font-size: 0.85em" *ngFor="let cargo of cargosFormGroup.controls; let i = index;">
                        <div [formGroupName]="i" class="row">
                            <div class="form-group col-9">
                                <input type="text" class="form-control" formControlName="cargo" placeholder="Nome do Cargo" oninput="this.value = this.value.toUpperCase()">
                            </div>
                            <div class="form-group col-3 text-right">
                                <button class="btn btn-danger" type="button" (click)="removeCargo(i)" *ngIf="this.cargosList?.length > 1">
                                <fa-icon [icon]="['fas', 'minus-circle']" size="1x"></fa-icon>
                                </button>&nbsp;
                                <button class="btn btn-success" type="button" (click)="addCargoNovo()">
                                <fa-icon [icon]="['fas', 'plus-circle']" size="1x"></fa-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>   
            </form>
        <br>
        <div class="row">
            <div class="col col-4">
                &nbsp;
            </div>
            <div class="col col-2">
                    <button class="btn btn-danger btn-block" (click)="closeModalCargos()">CANCELAR</button>
            </div>
            <div class="col col-2">
                    <button class="btn btn-success btn-block" (click)="salvarCargos()">SALVAR</button>
            </div>
            <div class="col col-4">
                    &nbsp;
                </div>
        </div>
        </div>
    </div>
    <!-- MODAL TIMES -->
    <div class="modal" id="modalTimes">
        <div style="padding:50px">
            <form [formGroup]="times">
                <div class="row" formArrayName="time">
                    <div class="col col-12" style="font-size: 0.85em" *ngFor="let time of timesFormGroup.controls; let i = index;">
                        <div [formGroupName]="i" class="row">
                            <div class="form-group col-5">
                                <input type="text" class="form-control" formControlName="time" placeholder="Nome do Time" oninput="this.value = this.value.toUpperCase()">
                            </div>
                            <div class="form-group col-4">
                                <select class="form-control" formControlName="tipo" placeholder="Tipo de Time">
                                    <option value=""></option>
                                    <option value="INTERNO">INTERNO</option>
                                    <option value="EXTERNO">EXTERNO</option>
                                </select>
                            </div>
                            <div class="form-group col-3 text-right">
                                <button class="btn btn-danger btn-sm" type="button" (click)="removeTime(i)" *ngIf="this.timesList?.length > 1">
                                <fa-icon [icon]="['fas', 'minus-circle']" size="1x"></fa-icon>
                                </button>&nbsp;
                                <button class="btn btn-success btn-sm" type="button" (click)="addTimeNovo()">
                                <fa-icon [icon]="['fas', 'plus-circle']" size="1x"></fa-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>   
            </form>
        <br>
        <div class="row">
            <div class="col col-4">
                &nbsp;
            </div>
            <div class="col col-2">
                    <button class="btn btn-danger btn-block" (click)="closeModalTimes()">CANCELAR</button>
            </div>
            <div class="col col-2">
                    <button class="btn btn-success btn-block" (click)="salvarTimes()">SALVAR</button>
            </div>
            <div class="col col-4">
                    &nbsp;
                </div>
        </div>
        </div>
    </div>
    <!-- MODAL NOVO USUARIO -->
    <div class="modal" id="modalNovo">
        <div class="row align-items-center header">
            <div class="col titulo">
                CADASTRAR NOVO COLABORADOR
            </div>
            <div class="col botoes">
                <button class="btn btn-outline-light" (click)="closeModalNovo()">
                    <fa-icon [icon]="['fas', 'window-close']"></fa-icon>&nbsp;CANCELAR
                </button>
                &nbsp;&nbsp;
                <button class="btn btn-outline-light" (click)="cadastrarUsuario()" [disabled]="(colaboradorVivaMax && (!email || !nome || !tipo || !senha || !cpf || !nascimento || !telefone)) || (representantePj && (!email || !senha || !razaoEmpresa || !fantasiaEmpresa || !cnpj || !telefone || !nomeContato || !cargoResponsavel)) || (!colaboradorVivaMax && !representantePj) || (comissaoSobreVendasCheck && !equipe.controls.equipe.value[0].nome)">
                    <fa-icon [icon]="['fas', 'save']"></fa-icon>&nbsp;SALVAR
                </button>
            </div>
        </div>
        <br>
        <div class="corpo">
            <div class="row" style="padding-left: 10px">
                <div class="col">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" [(ngModel)]="representantePj" id="repPj" [disabled]="colaboradorVivaMax">
                        <label class="form-check-label" for="repPj">&nbsp;&nbsp;Representante PJ</label>
                    </div>
                </div>
                <div class="col">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" [(ngModel)]="colaboradorVivaMax" id="colabVm" [disabled]="representantePj">
                        <label class="form-check-label" for="colabVm">&nbsp;&nbsp;Colaborador Viva Max</label>
                    </div>
                </div>
                <div class="col">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" [(ngModel)]="colaboradorComercial" id="colabCom">
                        <label class="form-check-label" for="colabCom">&nbsp;&nbsp;Comercial</label>
                    </div>
                </div>
            </div>
            <br>
            <div *ngIf="colaboradorVivaMax">
                <label class="titles">Dados:</label>
                <div class="row">
                    <div class="col col-2">
                        <label>Data de Admissão:</label>
                        <input type="text" class="form-control" [(ngModel)]="admissao" maxlength="10" onkeyup="var v = this.value;if(v.match(/^\d{2}$/) !== null){this.value = v + '/';}else if(v.match(/^\d{2}\/\d{2}$/) !== null){this.value = v + '/';}">
                    </div>
                    <div class="col col-7">
                        <label>Nome Completo:</label>
                        <input type="text" class="form-control" [(ngModel)]="nome" [ngStyle]="{'border-color': !nome ? '#dd443e' : '#3cb42c'}" oninput="this.value = this.value.toUpperCase()">
                    </div>
                    <div class="col col-3">
                        <label>CPF:</label>
                        <input type="text" class="form-control" [(ngModel)]="cpf" cpf [textMask]="{mask: MASKS.cpf.textMask}" [ngStyle]="{'border-color': !cpf ? '#dd443e' : '#3cb42c'}">
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col col-2">
                        <label>Data Nascimento:</label>
                        <input type="text" class="form-control" [(ngModel)]="nascimento" maxlength="10" onkeyup="var v = this.value;if(v.match(/^\d{2}$/) !== null){this.value = v + '/';}else if(v.match(/^\d{2}\/\d{2}$/) !== null){this.value = v + '/';}" [ngStyle]="{'border-color': nascimento?.length != 10 ? '#dd443e' : '#3cb42c'}">
                    </div>
                    <div class="col col-2">
                        <label>Telefone Particular:</label>
                        <input type="text" class="form-control" [textMask]="{mask: MASKS.telefone.textMaskFunction}" [(ngModel)]="telefone" [ngStyle]="{'border-color': !telefone ? '#dd443e' : '#3cb42c'}">
                    </div>
                    <div class="col col-2">
                        <label>Telefone Recado:</label>
                        <input type="text" class="form-control" [textMask]="{mask: MASKS.telefone.textMaskFunction}" [(ngModel)]="telefoneRecado">
                    </div>
                    <div class="col col-3">
                        <label>Pessoa de Recado:</label>
                        <input type="text" class="form-control" oninput="this.value = this.value.toUpperCase()" [(ngModel)]="pessoaRecado" [ngStyle]="{'border-color': !pessoaRecado ? '#dd443e' : '#3cb42c'}">
                    </div>
                    <div class="col col-3">
                        <label>Estado Civil:</label>
                        <select class="form-control" [(ngModel)]="estadoCivil" [ngStyle]="{'border-color': !estadoCivil ? '#dd443e' : '#3cb42c'}">
                            <option value="SOLTEIRO">SOLTEIRO</option>
                            <option value="CASADO">CASADO</option>
                            <option value="DIVORCIADO">DIVORCIADO</option>
                            <option value="VÍUVO">VÍUVO</option>
                        </select>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col col-3">
                        <label>CEP:</label>
                        <input type="text" cep [textMask]="{mask: MASKS.cep.textMask}" class="form-control" [(ngModel)]="cep" (keyup)="buscarCep(cep)">
                    </div>
                    <div class="col col-6">
                        <label>Rua:</label>
                        <input type="text" class="form-control" [(ngModel)]="rua" oninput="this.value = this.value.toUpperCase()">
                    </div>
                    <div class="col col-3">
                        <label>Número:</label>
                        <input type="text" class="form-control" [(ngModel)]="numero" oninput="this.value = this.value.toUpperCase()">
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col col-4">
                        <label>Bairro:</label>
                        <input type="text" class="form-control" [(ngModel)]="bairro" oninput="this.value = this.value.toUpperCase()">
                    </div>
                    <div class="col col-3">
                        <label>Complemento:</label>
                        <input type="text" class="form-control" [(ngModel)]="complemento" oninput="this.value = this.value.toUpperCase()">
                    </div>
                    <div class="col col-4">
                        <label>Município:</label>
                        <input type="text" class="form-control" [(ngModel)]="municipio" oninput="this.value = this.value.toUpperCase()">
                    </div>
                    <div class="col col-1">
                        <label>UF:</label>
                        <input type="text" class="form-control" [(ngModel)]="estado" oninput="this.value = this.value.toUpperCase()">
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col col-4">
                        <label>E-mail:</label>
                        <input type="text" class="form-control" (keyup)="app.checkEmail(email)" [(ngModel)]="email" [ngStyle]="{'border-color': !email ? '#dd443e' : '#3cb42c'}" oninput="this.value = this.value.toUpperCase()">
                        <div class="errorMsg" *ngIf="email && !app.checkEmail(email)">E-mail inválido!</div>
                    </div>
                    <div class="col col-3">
                        <label>Senha:</label>
                        <input type="password" class="form-control" [(ngModel)]="senha" [ngStyle]="{'border-color': !senha ? '#dd443e' : '#3cb42c'}">
                        <div *ngIf="confereSenha(senha)" class="msgAlert">NÃO UTILIZE OS CARACTERES (%, $, # e &)</div>
                    </div>
                    <div class="col col-5">
                        <label>Tamanho de Uniforme:</label>
                        <select class="form-control" [(ngModel)]="tamanhoUniforme" [ngStyle]="{'border-color': !tamanhoUniforme ? '#dd443e' : '#3cb42c'}">
                            <option value="" disabled></option>
                            <option value="PP">PP</option>
                            <option value="P">P</option>
                            <option value="M">M</option>
                            <option value="G">G</option>
                            <option value="GG">GG</option>
                            <option value="G1">G1</option>
                            <option value="G2">G2</option>
                            <option value="ESPECIAL">ESPECIAL</option>
                        </select>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col col-3">
                        <label>Cargo:</label>
                        <select class="form-control" [(ngModel)]="tipo" [ngStyle]="{'border-color': !tipo ? '#dd443e' : '#3cb42c'}" (change)="limparSelects()">
                            <option value=""></option>
                            <option *ngFor="let c of todosCargos" [value]="c?.cargo" [hidden]="c.cargo == 'MASTER' && tipoUsuario != 'MASTER'">{{c?.cargo}}</option>
                        </select>
                    </div>
                    <div class="col col-4" *ngIf="colaboradorComercial">
                        <label>Time:</label>
                        <select class="form-control" [(ngModel)]="timeVendedor" [ngStyle]="{'border-color': !timeVendedor ? '#dd443e' : '#3cb42c'}">
                            <option value="" disabled></option>
                            <option *ngFor="let time of todosTimes" [value]="time.time">{{time.time}}</option>
                        </select>
                    </div>
                    <div class="col col-3">
                        <label>Situação</label>
                        <select class="form-control" [(ngModel)]="situacao" [ngStyle]="{'border-color': !situacao ? '#dd443e' : '#3cb42c'}">
                            <option value="" disabled></option>
                            <option value="EXPERIÊNCIA">EXPERIÊNCIA</option>
                            <option value="EFETIVADO">EFETIVADO</option>
                            <option value="DEMITIDO">DEMITIDO</option>
                        </select>
                    </div>
                    <div class="col col-2" *ngIf="situacao == 'DEMITIDO'">
                        <label>Data Demissão</label>
                        <input type="text" class="form-control" [(ngModel)]="dataDemissao" maxlength="10" onkeyup="var v = this.value;if(v.match(/^\d{2}$/) !== null){this.value = v + '/';}else if(v.match(/^\d{2}\/\d{2}$/) !== null){this.value = v + '/';}">
                    </div>
                    <div class="col col-2" *ngIf="tipo == 'MASTER'">
                        <label>Excluir Associado</label>
                        <select class="form-control" [(ngModel)]="excluirAssociado">
                            <option value="NÃO">NÃO</option>
                            <option value="SIM">SIM</option>
                        </select>
                    </div>
                    <div class="col col-4" *ngIf="colaboradorComercial">
                        <label>Gerente Responsável:</label>
                        <select class="form-control" [(ngModel)]="gerente" [ngStyle]="{'border-color': !gerente ? '#dd443e' : '#3cb42c','pointer-events': situacao != 'DEMITIDO' ? 'auto' : 'none'}">
                            <option *ngFor="let u of vendedores" [value]="u.nome">{{u.nome}}</option>
                        </select>
                    </div>
                    <div class="col col-3" *ngIf="colaboradorComercial">
                        <label>COR DA AGENDA:</label><br>
                        <input [style.background]="color" [colorPicker]="color" (colorPickerChange)="color=$event"/>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col">
                        <label>Observação:</label>
                        <textarea class="form-control" row="3" [(ngModel)]="observacao"></textarea>
                    </div>
                </div>
            </div>
            <div *ngIf="representantePj">
                <div class="row">
                    <div class="col col-5">
                        <label>Razão Social</label>
                        <input type="text" class="form-control" [(ngModel)]="razaoEmpresa" [ngStyle]="{'border-color': !razaoEmpresa ? '#dd443e' : '#3cb42c'}" oninput="this.value = this.value.toUpperCase()">
                    </div>
                    <div class="col col-4">
                        <label>Nome Fantasia</label>
                        <input type="text" class="form-control" [(ngModel)]="fantasiaEmpresa" [ngStyle]="{'border-color': !fantasiaEmpresa ? '#dd443e' : '#3cb42c'}" oninput="this.value = this.value.toUpperCase()">
                    </div>
                    <div class="col col-3">
                        <label>CNPJ</label>
                        <input type="text" class="form-control" cnpj [textMask]="{mask: MASKS.cnpj.textMask}" [(ngModel)]="cnpj" [ngStyle]="{'border-color': !cnpj ? '#dd443e' : '#3cb42c'}">
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col col-3">
                        <label>Telefone:</label>
                        <input type="text" class="form-control" [textMask]="{mask: MASKS.telefone.textMaskFunction}" [(ngModel)]="telefone" [ngStyle]="{'border-color': !telefone ? '#dd443e' : '#3cb42c'}">
                    </div>
                    <div class="col col-4">
                        <label>Responsável:</label>
                        <input type="text" class="form-control" [(ngModel)]="nomeContato" oninput="this.value = this.value.toUpperCase()" [ngStyle]="{'border-color': !nomeContato ? '#dd443e' : '#3cb42c'}">
                    </div>
                    <div class="col col-5">
                        <label>Cargo:</label>
                        <input type="text" class="form-control" [(ngModel)]="cargoResponsavel" oninput="this.value = this.value.toUpperCase()" [ngStyle]="{'border-color': !cargoResponsavel ? '#dd443e' : '#3cb42c'}">
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col col-7">
                        <label>E-mail:</label>
                        <input type="text" class="form-control" (keyup)="app.checkEmail(email)" [(ngModel)]="email" [ngStyle]="{'border-color': !email ? '#dd443e' : '#3cb42c'}" oninput="this.value = this.value.toUpperCase()">
                        <div class="errorMsg" *ngIf="email && !app.checkEmail(email)">E-mail inválido!</div>
                    </div>
                    <div class="col col-5">
                        <label>Senha:</label>
                        <input type="password" class="form-control" [(ngModel)]="senha" [ngStyle]="{'border-color': !senha ? '#dd443e' : '#3cb42c'}">
                        <div *ngIf="confereSenha(senha)" class="msgAlert">NÃO UTILIZE OS CARACTERES (%, $, # e &)</div>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col">
                        <label>Observação:</label>
                        <textarea class="form-control" [(ngModel)]="observacao" row="3"></textarea>
                    </div>
                </div>
            </div>
            <br>
            <div *ngIf="colaboradorComercial">
                <label class="titles">Bonificação:</label>
                <div class="row align-items-center">
                    <div class="col col-2" style="padding:1em">
                        <label>META MENSAL</label>
                        <div class="input-group mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text" id="basic-addon1">R$</span>
                            </div>
                            <input type="number" min="0" class="form-control" [(ngModel)]="meta">
                        </div>  
                    </div>
                    <div class="col col-2" style="padding:1em">
                        <label>BÔNUS</label>
                        <div class="input-group mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text" id="basic-addon1">R$</span>
                            </div>
                            <input type="number" min="0" class="form-control" [(ngModel)]="bonus">
                        </div> 
                    </div>
                    <div class="col">
                        <br>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" [(ngModel)]="comissaoSobreVendasCheck" id="comissaoVenda">
                            <label class="form-check-label" for="comissaoVenda">&nbsp;&nbsp;Comissão Sobre Vendas</label>
                        </div>
                    </div>
                    <div class="col" style="padding:1em" [hidden]="!comissaoSobreVendasCheck">
                        <label>VALOR COMISSÃO P/ VENDAS</label>
                        <div class="input-group mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text" id="basic-addon1">R$</span>
                            </div>
                            <input type="number" min="0" class="form-control" [(ngModel)]="comissaoSobreVendas">
                        </div> 
                    </div>
                </div>
            </div>
            <br>
            <div [hidden]="!comissaoSobreVendasCheck">
                <label class="titles">Equipe:</label>
                <form [formGroup]="equipe">
                    <div class="row" formArrayName="equipe">
                        <div class="col col-12" style="font-size: 0.85em" *ngFor="let equipes of equipeFormGroup.controls; let i = index;">
                            <div [formGroupName]="i" class="row">
                                <div class="form-group col-6">
                                    <select class="form-control" formControlName="vendedor" (change)="selectVendedorEquipe(i,$event)">
                                        <option value=""></option>
                                        <option *ngFor="let vendedor of vendedores" [value]="vendedor.nome">{{vendedor.nome}}</option>
                                    </select>
                                </div>
                                <div class="form-group col-3 text-right">
                                    <button class="btn btn-danger btn-sm" type="button" (click)="removeEquipe(i)" *ngIf="this.equipeList?.length > 1">
                                    <fa-icon [icon]="['fas', 'minus-circle']" size="1x"></fa-icon>
                                    </button>&nbsp;
                                    <button class="btn btn-success btn-sm" type="button" (click)="addEquipeNovo()">
                                    <fa-icon [icon]="['fas', 'plus-circle']" size="1x"></fa-icon>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>   
                </form>
            </div>
            <br>
            <div class="row" *ngIf="colaboradorVivaMax">
                <div class="col">
                    <label class="titles">Permissões:</label>
                    <div class="row">
                        <div class="col col-4" *ngFor="let p of permissoes | keyvalue" style="padding:1em">
                            <label>{{p?.key | uppercase}}</label>
                            <select class="form-control" [(ngModel)]="p.value" (change)="mudarPermissao(p.key, p.value)">
                                <option value="VER">VER</option>
                                <option value="EDITAR">EDITAR</option>
                                <option value="NENHUMA">NENHUMA</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- MODAL EDITAR USUARIO -->
    <div class="modal" id="modalEditar">
        <!-- MODAL LIGHTBOX -->
        <div id="myModal" class="modalLightbox">
            <span (click)="closeModal()" class="close">&times;</span>
            <div class="prev" (click)="prevImg()">&lt;</div>
            <img [src]="imagemSelecionada" class="imagemLightbox">
        <div class="next" (click)="nextImg()">&gt;</div>
        </div> 
        <div style="width: 800px; height: 400px;display: none;">
            <ng2-pdfjs-viewer 
            #pdfViewerUsuarios
            [externalWindow]="true"
            [downloadFileName]="'vivamax.pdf'"
            [openFile]="false"
            [viewBookmark]="false"
            [download]="true"></ng2-pdfjs-viewer>
        </div>
        <div class="row align-items-center header">
            <div class="col titulo">
                EDITAR COLABORADOR
            </div>
            <div class="col botoes">
                <button class="btn btn-outline-light" (click)="fecharModalEditar()">
                    <fa-icon [icon]="['fas', 'window-close']"></fa-icon>&nbsp;CANCELAR
                </button>
                &nbsp;&nbsp;
                <button class="btn btn-outline-light" (click)="editarUsuario()" [disabled]="(!colaboradorVivaMaxEditar && !representantePjEditar) || (colaboradorVivaMaxEditar && (tipoEditar == 'REPRESENTANTE PJ' || !emailEditar || !nomeEditar || !tipoEditar || !senhaEditar || !cpfEditar || !nascimentoEditar || !telefoneEditar)) || (representantePjEditar && (!emailEditar || !senhaEditar || !razaoEmpresaEditar || !fantasiaEmpresaEditar || !cnpjEditar || !telefoneEditar || !nomeContatoEditar || !cargoResponsavelEditar)) || (comissaoSobreVendasCheckEditar && !equipeEditar?.controls?.equipeEditar?.value[0]?.nome)">
                    <fa-icon [icon]="['fas', 'save']"></fa-icon>&nbsp;SALVAR
                </button>
            </div>
        </div>
        <br>
        <div class="row" style="padding-left: 10px">
            <div class="col">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" [(ngModel)]="representantePjEditar" id="repPjEditar" [disabled]="colaboradorVivaMaxEditar">
                    <label class="form-check-label" for="repPjEditar">&nbsp;&nbsp;Representante PJ</label>
                </div>
            </div>
            <div class="col">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" [(ngModel)]="colaboradorVivaMaxEditar" id="colabVmEditar" [disabled]="representantePjEditar">
                    <label class="form-check-label" for="colabVmEditar">&nbsp;&nbsp;Colaborador Viva Max</label>
                </div>
            </div>
            <div class="col">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" [(ngModel)]="colaboradorComercialEditar" id="colabComEditar">
                    <label class="form-check-label" for="colabComEditar">&nbsp;&nbsp;Comercial</label>
                </div>
            </div>
        </div>
        <br>
        <div class="corpo">
            <div *ngIf="colaboradorVivaMaxEditar">
                <label class="titles">Dados:</label>
                <div class="row">
                    <div class="col col-2">
                        <label>Data de Admissão:</label>
                        <input type="text" class="form-control" [(ngModel)]="admissaoEditar" maxlength="10" onkeyup="var v = this.value;if(v.match(/^\d{2}$/) !== null){this.value = v + '/';}else if(v.match(/^\d{2}\/\d{2}$/) !== null){this.value = v + '/';}" [ngStyle]="{'pointer-events': situacaoEditar != 'DEMITIDO' ? 'auto' : 'none'}">
                    </div>
                    <div class="col col-7">
                        <label>Nome Completo:</label>
                        <input type="text" class="form-control" [(ngModel)]="nomeEditar" oninput="this.value = this.value.toUpperCase()" [ngStyle]="{'border-color': !nomeEditar ? '#dd443e' : '#3cb42c','pointer-events': situacaoEditar != 'DEMITIDO' ? 'auto' : 'none'}">
                    </div>
                    <div class="col col-3">
                        <label>CPF:</label>
                        <input type="text" class="form-control" [(ngModel)]="cpfEditar" cpf [textMask]="{mask: MASKS.cpf.textMask}" [ngStyle]="{'border-color': !cpfEditar ? '#dd443e' : '#3cb42c','pointer-events': situacaoEditar != 'DEMITIDO' ? 'auto' : 'none'}">
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col col-2">
                        <label>Data Nascimento:</label>
                        <input type="text" class="form-control" [(ngModel)]="nascimentoEditar" maxlength="10" onkeyup="var v = this.value;if(v.match(/^\d{2}$/) !== null){this.value = v + '/';}else if(v.match(/^\d{2}\/\d{2}$/) !== null){this.value = v + '/';}" [ngStyle]="{'border-color': nascimentoEditar?.length != 10 ? '#dd443e' : '#3cb42c','pointer-events': situacaoEditar != 'DEMITIDO' ? 'auto' : 'none'}">
                    </div>
                    <div class="col col-2">
                        <label>Telefone Particular:</label>
                        <input type="text" class="form-control" [textMask]="{mask: MASKS.telefone.textMaskFunction}" [(ngModel)]="telefoneEditar" [ngStyle]="{'border-color': !telefoneEditar ? '#dd443e' : '#3cb42c','pointer-events': situacaoEditar != 'DEMITIDO' ? 'auto' : 'none'}">
                    </div>
                    <div class="col col-2">
                        <label>Telefone Recado:</label>
                        <input type="text" class="form-control" [textMask]="{mask: MASKS.telefone.textMaskFunction}" [(ngModel)]="telefoneRecadoEditar" [ngStyle]="{'pointer-events': situacaoEditar != 'DEMITIDO' ? 'auto' : 'none'}">
                    </div>
                    <div class="col col-3">
                        <label>Pessoa de Recado:</label>
                        <input type="text" class="form-control" oninput="this.value = this.value.toUpperCase()" [(ngModel)]="pessoaRecadoEditar" [ngStyle]="{'pointer-events': situacaoEditar != 'DEMITIDO' ? 'auto' : 'none'}">
                    </div>
                    <div class="col col-3">
                        <label>Estado Civil:</label>
                        <select class="form-control" [(ngModel)]="estadoCivilEditar" [ngStyle]="{'border-color': !estadoCivilEditar ? '#dd443e' : '#3cb42c','pointer-events': situacaoEditar != 'DEMITIDO' ? 'auto' : 'none'}">
                            <option value="" disabled></option>
                            <option value="SOLTEIRO">SOLTEIRO</option>
                            <option value="CASADO">CASADO</option>
                            <option value="DIVORCIADO">DIVORCIADO</option>
                            <option value="VÍUVO">VÍUVO</option>
                        </select>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col col-3">
                        <label>CEP:</label>
                        <input type="text" cep [textMask]="{mask: MASKS.cep.textMask}" class="form-control" [(ngModel)]="cepEditar" (keyup)="buscarCepEditar(cepEditar)" [ngStyle]="{'pointer-events': situacaoEditar != 'DEMITIDO' ? 'auto' : 'none'}">
                    </div>
                    <div class="col col-6">
                        <label>Rua:</label>
                        <input type="text" class="form-control" [(ngModel)]="ruaEditar" oninput="this.value = this.value.toUpperCase()" [ngStyle]="{'pointer-events': situacaoEditar != 'DEMITIDO' ? 'auto' : 'none'}">
                    </div>
                    <div class="col col-3">
                        <label>Número:</label>
                        <input type="text" class="form-control" [(ngModel)]="numeroEditar" oninput="this.value = this.value.toUpperCase()" [ngStyle]="{'pointer-events': situacaoEditar != 'DEMITIDO' ? 'auto' : 'none'}">
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col col-4">
                        <label>Bairro:</label>
                        <input type="text" class="form-control" [(ngModel)]="bairroEditar" oninput="this.value = this.value.toUpperCase()" [ngStyle]="{'pointer-events': situacaoEditar != 'DEMITIDO' ? 'auto' : 'none'}">
                    </div>
                    <div class="col col-3">
                        <label>Complemento:</label>
                        <input type="text" class="form-control" [(ngModel)]="complementoEditar" oninput="this.value = this.value.toUpperCase()" [ngStyle]="{'pointer-events': situacaoEditar != 'DEMITIDO' ? 'auto' : 'none'}">
                    </div>
                    <div class="col col-4">
                        <label>Município:</label>
                        <input type="text" class="form-control" [(ngModel)]="municipioEditar" oninput="this.value = this.value.toUpperCase()" [ngStyle]="{'pointer-events': situacaoEditar != 'DEMITIDO' ? 'auto' : 'none'}">
                    </div>
                    <div class="col col-1">
                        <label>UF:</label>
                        <input type="text" class="form-control" [(ngModel)]="estadoEditar" oninput="this.value = this.value.toUpperCase()" [ngStyle]="{'pointer-events': situacaoEditar != 'DEMITIDO' ? 'auto' : 'none'}">
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col col-4">
                        <label>E-mail:</label>
                        <input type="text" class="form-control" (keyup)="app.checkEmail(emailEditar)" [(ngModel)]="emailEditar" oninput="this.value = this.value.toUpperCase()" [ngStyle]="{'border-color': !emailEditar ? '#dd443e' : '#3cb42c','pointer-events': situacaoEditar != 'DEMITIDO' ? 'auto' : 'none'}">
                        <div class="errorMsg" *ngIf="emailEditar && !app.checkEmail(emailEditar)">E-mail inválido!</div>
                    </div>
                    <div class="col col-3">
                        <label>Senha:</label>
                        <input type="password" class="form-control" [(ngModel)]="senhaEditar" [ngStyle]="{'border-color': !senhaEditar ? '#dd443e' : '#3cb42c','pointer-events': situacaoEditar != 'DEMITIDO' ? 'auto' : 'none'}">
                        <div *ngIf="confereSenha(senhaEditar)" class="msgAlert">NÃO UTILIZE OS CARACTERES (%, $, # e &)</div>
                    </div>
                    <div class="col col-5">
                        <label>Tamanho de Uniforme:</label>
                    <select class="form-control" [(ngModel)]="tamanhoUniformeEditar" [ngStyle]="{'border-color': !tamanhoUniformeEditar ? '#dd443e' : '#3cb42c'}">
                        <option value="" disabled></option>
                        <option value="PP">PP</option>
                        <option value="P">P</option>
                        <option value="M">M</option>
                        <option value="G">G</option>
                        <option value="GG">GG</option>
                        <option value="G1">G1</option>
                        <option value="G2">G2</option>
                        <option value="ESPECIAL">ESPECIAL</option>
                    </select>
                </div>
                </div>
                <br>
                <div class="row">
                    <div class="col col-3">
                        <label>Cargo:</label>
                        <select class="form-control" [(ngModel)]="tipoEditar" (change)="limparSelectsEditar()" [ngStyle]="{'border-color': (!tipoEditar || tipoEditar == 'REPRESENTANTE PJ') ? '#dd443e' : '#3cb42c','pointer-events': situacaoEditar != 'DEMITIDO' ? 'auto' : 'none'}">
                            <option *ngFor="let c of todosCargos" [value]="c?.cargo" [hidden]="c.cargo == 'MASTER' && tipoUsuario != 'MASTER'">{{c?.cargo}}</option>
                        </select>
                    </div>
                    <div class="col col-3" *ngIf="colaboradorComercial">
                        <label>Time:</label>
                        <select class="form-control" [(ngModel)]="timeVendedorEditar" [ngStyle]="{'border-color': !timeVendedorEditar ? '#dd443e' : '#3cb42c'}">
                            <option value="" disabled></option>
                            <option *ngFor="let time of todosTimes" [value]="time.time">{{time.time}}</option>
                        </select>
                    </div>
                    <div class="col col-2">
                        <label>Situação</label>
                        <select class="form-control" [(ngModel)]="situacaoEditar" [ngStyle]="{'border-color': !situacaoEditar ? '#dd443e' : '#3cb42c','pointer-events': situacaoEditar != 'DEMITIDO' ? 'auto' : 'none'}">
                            <option value="" disabled></option>
                            <option value="EXPERIÊNCIA">EXPERIÊNCIA</option>
                            <option value="EFETIVADO">EFETIVADO</option>
                            <option value="DEMITIDO">DEMITIDO</option>
                        </select>
                    </div>
                    <div class="col col-2" *ngIf="situacaoEditar == 'EXPERIÊNCIA'">
                        <label>Fim Experiência</label>
                        <input type="text" class="form-control" [(ngModel)]="fimExperienciaEditar" maxlength="10" onkeyup="var v = this.value;if(v.match(/^\d{2}$/) !== null){this.value = v + '/';}else if(v.match(/^\d{2}\/\d{2}$/) !== null){this.value = v + '/';}" [ngStyle]="{'border-color': fimExperienciaEditar?.length != 10 ? '#dd443e' : '#3cb42c','pointer-events': situacaoEditar != 'DEMITIDO' ? 'auto' : 'none'}">
                    </div>
                    <div class="col col-2" *ngIf="situacaoEditar == 'DEMITIDO'">
                        <label>Data Demissão</label>
                        <input type="text" class="form-control" [(ngModel)]="dataDemissaoEditar" maxlength="10" onkeyup="var v = this.value;if(v.match(/^\d{2}$/) !== null){this.value = v + '/';}else if(v.match(/^\d{2}\/\d{2}$/) !== null){this.value = v + '/';}" [ngStyle]="{'pointer-events': situacaoEditar != 'DEMITIDO' ? 'auto' : 'none'}">
                    </div>
                    <div class="col col-2" *ngIf="tipoEditar == 'MASTER'">
                        <label>Excluir Associado</label>
                        <select class="form-control" [(ngModel)]="excluirAssociadoEditar" [ngStyle]="{'pointer-events': situacaoEditar != 'DEMITIDO' ? 'auto' : 'none'}">
                            <option value="NÃO">NÃO</option>
                            <option value="SIM">SIM</option>
                        </select>
                    </div>
                    <div class="col col-4" *ngIf="colaboradorComercialEditar">
                        <label>Gerente Responsável:</label>
                        <select class="form-control" [(ngModel)]="gerenteEditar" [ngStyle]="{'border-color': !gerenteEditar ? '#dd443e' : '#3cb42c','pointer-events': situacaoEditar != 'DEMITIDO' ? 'auto' : 'none'}">
                            <option *ngFor="let u of vendedores" [value]="u.nome">{{u.nome}}</option>
                        </select>
                    </div>
                    <div class="col col-3" *ngIf="colaboradorComercialEditar">
                        <label>COR DA AGENDA:</label><br>
                        <input [style.background]="colorEditar" [colorPicker]="colorEditar" (colorPickerChange)="colorEditar=$event"/>
                    </div>
                </div>
            </div>
            <div *ngIf="representantePjEditar">
                <div class="row">
                    <div class="col col-5">
                        <label>Razão Social</label>
                        <input type="text" class="form-control" [(ngModel)]="razaoEmpresaEditar" [ngStyle]="{'border-color': !razaoEmpresaEditar ? '#dd443e' : '#3cb42c'}" oninput="this.value = this.value.toUpperCase()">
                    </div>
                    <div class="col col-4">
                        <label>Nome Fantasia</label>
                        <input type="text" class="form-control" [(ngModel)]="fantasiaEmpresaEditar" [ngStyle]="{'border-color': !fantasiaEmpresaEditar ? '#dd443e' : '#3cb42c'}" oninput="this.value = this.value.toUpperCase()">
                    </div>
                    <div class="col col-3">
                        <label>CNPJ</label>
                        <input type="text" class="form-control" cnpj [textMask]="{mask: MASKS.cnpj.textMask}" [(ngModel)]="cnpjEditar" [ngStyle]="{'border-color': !cnpjEditar ? '#dd443e' : '#3cb42c'}">
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col col-3">
                        <label>Telefone:</label>
                        <input type="text" class="form-control" [textMask]="{mask: MASKS.telefone.textMaskFunction}" [(ngModel)]="telefoneEditar" [ngStyle]="{'border-color': !telefoneEditar ? '#dd443e' : '#3cb42c'}">
                    </div>
                    <div class="col col-4">
                        <label>Responsável:</label>
                        <input type="text" class="form-control" [(ngModel)]="nomeContatoEditar" oninput="this.value = this.value.toUpperCase()" [ngStyle]="{'border-color': !nomeContatoEditar ? '#dd443e' : '#3cb42c'}">
                    </div>
                    <div class="col col-5">
                        <label>Cargo:</label>
                        <input type="text" class="form-control" [(ngModel)]="cargoResponsavelEditar" oninput="this.value = this.value.toUpperCase()" [ngStyle]="{'border-color': !cargoResponsavelEditar ? '#dd443e' : '#3cb42c'}">
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col col-7">
                        <label>E-mail:</label>
                        <input type="text" class="form-control" (keyup)="app.checkEmail(emailEditar)" [(ngModel)]="emailEditar" [ngStyle]="{'border-color': !emailEditar ? '#dd443e' : '#3cb42c'}" oninput="this.value = this.value.toUpperCase()">
                        <div class="errorMsg" *ngIf="emailEditar && !app.checkEmail(emailEditar)">E-mail inválido!</div>

                    </div>
                    <div class="col col-5">
                        <label>Senha:</label>
                        <input type="password" class="form-control" [(ngModel)]="senhaEditar" [ngStyle]="{'border-color': !senhaEditar ? '#dd443e' : '#3cb42c'}">
                        <div *ngIf="confereSenha(senhaEditar)" class="msgAlert">NÃO UTILIZE OS CARACTERES (%, $, # e &)</div>
                    </div>
                </div>
            </div>
            <br>
            <div *ngIf="colaboradorComercialEditar">
                <label class="titles">Bonificação:</label>
                <div class="row align-items-center">
                    <div class="col col-2" style="padding:1em">
                        <label>META</label>
                        <div class="input-group mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text" id="basic-addon1">Qtde.</span>
                            </div>
                            <input type="number" min="0" class="form-control" [(ngModel)]="metaEditar">
                        </div>  
                    </div>
                    <div class="col col-2" style="padding:1em">
                        <label>BÔNUS</label>
                        <div class="input-group mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text" id="basic-addon1">R$</span>
                            </div>
                            <input type="number" min="0" class="form-control" [(ngModel)]="bonusEditar">
                        </div> 
                    </div>
                    <div class="col">
                        <br>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" [(ngModel)]="comissaoSobreVendasCheckEditar" id="comissaoVendaEditar">
                            <label class="form-check-label" for="comissaoVendaEditar">&nbsp;&nbsp;Comissão Sobre Vendas</label>
                        </div>
                    </div>
                    <div class="col" style="padding:1em" [hidden]="!comissaoSobreVendasCheckEditar">
                        <label>VALOR COMISSÃO P/ VENDAS</label>
                        <div class="input-group mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text" id="basic-addon1">R$</span>
                            </div>
                            <input type="number" min="0" class="form-control" [(ngModel)]="comissaoSobreVendasEditar">
                        </div> 
                    </div>
                </div>
            </div>
            <br>
            <div [hidden]="!comissaoSobreVendasCheckEditar">
                <label class="titles">Equipe:</label>
                <form [formGroup]="equipeEditar">
                    <div class="row" formArrayName="equipeEditar">
                        <div class="col col-12" style="font-size: 0.85em" *ngFor="let equipeEditar of equipeFormGroupEditar.controls; let i = index;">
                            <div [formGroupName]="i" class="row">
                                <div class="form-group col-6">
                                    <select class="form-control" formControlName="nome" (change)="selectVendedorEquipeEditar(i,$event)">
                                        <option value=""></option>
                                        <option *ngFor="let vendedor of vendedores" [value]="vendedor.nome">{{vendedor.nome}}</option>
                                    </select>
                                </div>
                                <div class="form-group col-3 text-right">
                                    <button class="btn btn-danger btn-sm" type="button" (click)="removeEquipeEditar(i)" *ngIf="this.equipeListEditar?.length > 1">
                                    <fa-icon [icon]="['fas', 'minus-circle']" size="1x"></fa-icon>
                                    </button>&nbsp;
                                    <button class="btn btn-success btn-sm" type="button" (click)="addEquipeEditar()">
                                    <fa-icon [icon]="['fas', 'plus-circle']" size="1x"></fa-icon>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>   
                </form>
            </div>
            <br>
            <div class="row" *ngIf="colaboradorVivaMaxEditar" [hidden]="tipoUsuario != 'MASTER'">
                <div class="col">
                    <label class="titles">Permissões:</label>
                    <div class="row">
                        <div class="col col-4" *ngFor="let p of permissoesEditar | keyvalue" style="padding:1em">
                            <label>{{p?.key | uppercase}}</label>
                            <select class="form-control" [(ngModel)]="p.value" (change)="mudarPermissaoEditar(p.key, p.value)">
                                <option value="VER">VER</option>
                                <option value="EDITAR">EDITAR</option>
                                <option value="NENHUMA">NENHUMA</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col">
                    <label>Observações:</label>
                    <p *ngFor="let observacao of observacaoEditar.reverse()"><strong>{{observacao.data | date:'dd/MM/yyyy'}}</strong> - {{observacao.conteudo}}</p>
                </div>
            </div>
            <div class="row align-items-center">
                <div class="col col-9">
                    <textarea class="form-control" [(ngModel)]="newObservacao" rows="3"></textarea>
                </div>
                <div class="col col-3">
                    <button class="btn btn-danger" (click)="novaObservacao(newObservacao)">CRIAR NOVA<br> OBSERVAÇÃO</button>
                </div>
            </div>
        </div>
        <br><br><br>
            <!-- DRAG DROP -->
            <div class="row">
                <div class="col" style="text-align: center; color:#5e5e5e">
                    <h5>Clique ou Arraste para Enviar Documentos ou Fotos</h5>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <div class="uploadfilecontainer" (click)="fileInput.click()" appDragDrop (onFileDropped)="onChange($event)">
                        <input hidden type="file" #fileInput (change)="onChange($event)" multiple>
                    </div>
                        <div  class="files-list" *ngFor="let file of files;let i= index">
                        <p> {{ file }} </p>
                    </div> 
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col col-1">
                    <br>
                </div>
                <div class="col">
                    <button class="btn btn-primary btn-block" (click)="uploadFile()" [disabled]="files?.length < 1">
                        <fa-icon [icon]="['fas', 'upload']"></fa-icon>&nbsp;&nbsp;ENVIAR
                    </button>
                </div>
                <div class="col col-1">
                    <br>
                </div>
            </div>
            <br><br><br>
                <div class="row justify-content-md-center">
                <div class="col col-3" *ngFor="let pdf of pdfs;" style="margin: 10px; background-color:#ce2626; color:white; height: 250px; width:100%; text-align: center; border-radius: 10px;">
                    <div class="delButton" name="delButton">
                        <button class="btn btn-danger btn-sm" (click)="delDoc(pdf.url)"><strong>X</strong></button>
                    </div>
                    <div (click)="openModalPdfViewer(pdf.url)" style="position: relative;left:50%;top:50%;transform: translate(-50%,-50%);cursor:pointer">
                        <fa-icon [icon]="['fas', 'file-pdf']" style="font-size: 5em;"></fa-icon>
                            <br>DOCUMENTO PDF
                    </div>
                </div>
                <div class="col col-3 img-container" *ngFor="let img of images; let i = index" style="margin: 10px; text-align: center;">
                    <div class="delButton" name="delButton">
                        <button class="btn btn-danger btn-sm" (click)="delDoc(img.url)"><strong>X</strong></button>
                    </div>
                    <img [src]="img.url" (click)="openModalLightBox(img.url, i)" style="border-radius: 10px; height: 250px; width:100%;">
                </div>
            </div>
            <br><br><br>
    </div>
    <div class="row align-items-center header">
        <div class="col titulo">
            COLABORADORES
        </div>
        <div class="col botoes" *ngIf="db?.usuario?.permissoes['colaboradores'] == 'EDITAR'">
            <button class="btn btn-outline-light" (click)="openModalCargos()" [hidden]="tipoUsuario != 'MASTER'">
                <fa-icon [icon]="['fas', 'user-shield']"></fa-icon>&nbsp;CARGOS
            </button>
            &nbsp;&nbsp;
            <button class="btn btn-outline-light" (click)="openModalTimes()">
                <fa-icon [icon]="['fas', 'users']"></fa-icon>&nbsp;TIMES
            </button>
            &nbsp;&nbsp;            
            <button class="btn btn-outline-light" (click)="openModalNovo()">
                <fa-icon [icon]="['fas', 'plus']"></fa-icon>&nbsp;NOVO COLABORADOR
            </button>
        </div>
    </div>
    <br>
    <table class="table table-striped" style="text-align: center;">
        <thead class="thead-dark">
            <tr>
                <th>Empresa</th>
                <th>Nome</th>
                <th>Cargo</th>
                <th>
                    <select class="form-control" [(ngModel)]="filtroSituacao" (change)="filterColaboradores()">
                        <option value=""></option>
                        <option value="EXPERIÊNCIA">EXPERIÊNCIA</option>
                        <option value="EFETIVADO">EFETIVADO</option>
                        <option value="DEMITIDO">DEMITIDO</option>
                    </select>
                </th>
                <th *ngIf="db?.usuario?.permissoes['colaboradores'] == 'EDITAR'"></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let usuario of usuarios">
                <td [ngStyle]="{'color': usuario?.representantePj ? '#169e16' : '#e64249'}"><span *ngIf="usuario?.representantePj">Representante PJ</span><span *ngIf="!usuario?.representantePj">Viva Max</span></td>
                <td><span *ngIf="usuario?.representantePj">{{ usuario?.razaoEmpresa }}</span><span *ngIf="!usuario?.representantePj">{{ usuario?.nome }}</span></td>
                <td><span *ngIf="usuario?.representantePj">REPRESENTANTE PJ</span><span *ngIf="!usuario?.representantePj">{{ usuario?.tipo }}</span></td>
                <td>{{ usuario?.situacao }}</td>
                <td *ngIf="db?.usuario?.permissoes['colaboradores'] == 'EDITAR'">
                    <button class="btn btn-sm btn-outline-success" (click)="openModalEditar(usuario)">
                        <fa-icon [icon]="['fas', 'edit']"></fa-icon>
                    </button>
                    &nbsp; &nbsp;
                    <button class="btn btn-sm btn-outline-danger" (click)="excluirUsuario(usuario?._id)">
                        <fa-icon [icon]="['fas', 'trash']"></fa-icon>
                    </button>
                </td>
            </tr>
        </tbody>
    </table>
</div>