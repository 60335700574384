import { Component, OnInit,ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppComponent } from '../app.component';
import { DataBaseService } from '../provider.service';

@Component({
  selector: 'app-caixa',
  templateUrl: './caixa.component.html',
  styleUrls: ['./caixa.component.css']
})
export class CaixaComponent implements OnInit {
  @ViewChild('pdfViewerCaixa') pdfViewerOnDemand;
  dataInicioConta:any = "";
  dataFimConta:any = "";
  caixa:any = [];
  dataHoje:any = "";
  idConta:any = "";
  fornecedorEditar:any = "";
  favorecidoEditar:any = "";
  valorContaEditar:number = 0;
  valorTotalContaEditar:number = 0;
  observacaoContaEditar:any = "";
  tipoVencimentoContaEditar:any = "";
  vencimentoContaEditar:any = "";
  parcelaConta:any = "";
  formaPagamentoEditar:any = "";
  pdfsConta:any = "";
  imagesConta:any = "";
  filesContaEditar:any = [];
  jurosContaEditar:number = 0;
  taxasContaEditar:number = 0;
  descontoContaEditar:number = 0;
  docsConta:any = [];
  indexImage:any = "";
  imagemSelecionada:any = "";
  selectedfilesContaEditar:any = [];
  filesConta:any = [];
  images:any = [];
  totalCaixaPago:number = 0;
  totalCaixaRecebido:number = 0;
  saldo:number = 0;
  dataPagamentoEditar:any = "";
  horaPagamentoEditar:any = "";
  tipoConta:any = "";
  tipoDeConta:any = "";
  dataSolicitacaoEditar:any = "";
  motivoReembolsoEditar:any = "";
  colaboradorReembolsoEditar:any = "";
  situacaoReembolsoEditar:any = "";
  observacaoReembolso:any = "";
  dataReembolso:any = "";
  listaReembolso:any = [];
  colaboradores:any = [];
  colaboradorVencimento:any = "";
  tipoVencimentoCadastro:any = "";
  page:number = 1;
  contasPagasVindi:any = [];
  contasVindi:any = [];
  todoCaixa:any = [];
  fornecedores:any = [];
  fornecedorConta:any = "";
  todasFormasPagamento:any = [];
  formaPagamento:any = "";
  favorecidos:any = [];
  ccEditar:FormGroup;
  public ccListEditar: FormArray;
  get ccFormGroupEditar(){return this.ccEditar.get('centroCusto') as FormArray;}
  listaDeCC:any = [];
  tipoEntradaContaEditar:any = "";
  tiposEntrada:any = [];
  todosBancos:any = [];
  banco:any = "";
  bancoEditar:any = "";
  saldoAnterior:any = 0;
  
  constructor(public db:DataBaseService, private formBuilder: FormBuilder, public app:AppComponent) { }

  ngOnInit(){
    this.getFornecedor();
    this.getFavorecidos();
      this.dataHoje = new Date(Date.now() - 10800000).toISOString().split('T')[0];
      this.dataInicioConta = this.dataHoje;
      this.dataFimConta = this.dataHoje;
      this.getContas(this.dataInicioConta,this.dataFimConta,"","","");
      this.getCCs();
      this.db.getAllUsuariosAtivos().subscribe((data:any) => {
        this.colaboradores = data.sort((a,b) => {
          if(a.nome < b.nome)return -1;
          if(b.nome < a.nome)return 1;
        });
      });
      this.db.getConfigs().subscribe((z:any) => {
        this.todasFormasPagamento = z[5].pagamentos;
        this.todosBancos = z[25].bancos;
        this.tiposEntrada = z[21].tiposEntrada;
        this.db.getMetricas().subscribe((me:any) => {
          this.saldoAnterior = me[0].financeiroSaldo;
        })
      });
      this.ccEditar = this.formBuilder.group({centroCusto: this.formBuilder.array([this.createCCEditar()])});
      this.ccListEditar = this.ccEditar.get('centroCusto') as FormArray;
  }

  getCCs(){
    this.db.getCentrosCusto().subscribe((data:any) => {
      this.listaDeCC = data;
    })
  }
  
  createCCEditarOpen(item): FormGroup {
    return this.formBuilder.group({
      centroDeCusto: [item.centroDeCusto, Validators.compose([Validators.required])],
      contaOrcamentaria: [item.contaOrcamentaria, Validators.compose([Validators.required])],
      contaContabil: [item.contaContabil, Validators.compose([Validators.required])],
      valor: [item.valor, Validators.compose([Validators.required])]
    });
  }

  createCCEditar(): FormGroup {
    return this.formBuilder.group({
      centroDeCusto: ["", Validators.compose([Validators.required])],
      contaOrcamentaria: ["", Validators.compose([Validators.required])],
      contaContabil: ["", Validators.compose([Validators.required])],
      valor: [null, Validators.compose([Validators.required])]
    });
  }
  
  addCCEditarOpen(item) {
    this.ccListEditar.push(this.createCCEditarOpen(item));
  }

  addCCEditar() {
    this.ccListEditar.push(this.createCCEditar());
  }

  removeCCEditar(index) {
    this.ccListEditar.removeAt(index);
  }

  getFornecedor(){
    this.fornecedores = [];
    this.db.getSubContratos().subscribe((df:any) => {
      let c = 1;
      df.forEach((s) => {
        s.razaoSocial = s.nome;
      if(df.length == c){
        this.db.getAllFornecedores().subscribe((data:any) => {
          this.db.getAllFavorecidos().subscribe((dt:any) => {
              this.fornecedores = [...df,...data,...dt];
              this.fornecedores.sort((a,b) => {if(a.razaoSocial < b.razaoSocial){return -1}});
            });
          });
        }else{
          c++
        }
      });
    });
}

getFavorecidos(){
  this.favorecidos = [];
  let controle = 1;
  let ctrl = 1;
  this.db.getAllFavorecidos().subscribe((data:any) => {
    this.favorecidos = data;
    this.db.getSubContratos().subscribe((subs:any) => {
      subs.forEach((item) => {
        item.razaoSocial = item.nome;
        item.imagem = true;
        this.favorecidos.push(item);
        if(subs.length == controle){
          this.db.getEmpresas().subscribe((emp:any) => {
            emp.forEach((e) => {
              e.razaoSocial = e.fantasiaEmpresa;
              e.imagem = true;
              this.favorecidos.push(e);
              if(emp.length == ctrl){
                this.favorecidos = this.favorecidos.sort((a,b)=>{if(a.razaoSocial < b.razaoSocial)return -1});
              }else{
                ctrl++
              }
            })
          })
        }else{
          controle++
        }
      })
    })
  })
}

  getContas(dtInicio,dtFim,fornecedor,formaPagamento,banco){
    let f = (fornecedor == '' || fornecedor == null) ? "" : fornecedor.razaoSocial;
    this.caixa = [];
    this.todoCaixa = [];
    this.db.getCaixaFornecedor(dtInicio,dtFim,f,formaPagamento,banco).subscribe((data:any) => {
      this.caixa = data;
      this.todoCaixa = data;
      this.somar();
    })
  }

somar(){
  this.totalCaixaPago = 0;
  this.totalCaixaRecebido = 0;
  this.saldo = 0;
  let control = 1;
  this.todoCaixa.forEach((item) => {
    if(item.tipo == "Saida"){
      this.totalCaixaPago += item.valorTotal;
    }
    if(item.tipo == "Entrada"){
      this.totalCaixaRecebido += item.valorTotal;
    }
    if(control == this.todoCaixa.length){
      this.saldo = this.totalCaixaRecebido - this.totalCaixaPago;
    }else{
      control++
    }
  })
}

  openModalVerConta(conta){
    this.idConta = conta._id;
    this.bancoEditar = conta.banco;
    this.fornecedorEditar = conta.fornecedor;
    this.favorecidoEditar = conta.favorecido;
    this.valorContaEditar = conta.valor;
    this.valorTotalContaEditar = conta.valorTotal;
    this.observacaoContaEditar = conta.observacao;
    this.tipoVencimentoContaEditar = conta.tipoVencimento;
    this.vencimentoContaEditar = conta.vencimento;
    this.parcelaConta = conta.parcela;
    this.formaPagamentoEditar = conta.formaPagamento;
    this.dataPagamentoEditar = conta.dataPagamento;
    this.horaPagamentoEditar = conta.hora;
    this.tipoConta = conta.tipo;
    this.tipoDeConta = conta.tipoDeConta;
    if(conta.juros){
      this.jurosContaEditar = conta.juros;
    }else{
      this.jurosContaEditar = 0;
    }
    if(conta.taxas){
      this.taxasContaEditar = conta.taxas;
    }else{
      this.taxasContaEditar = 0;
    }
    if(conta.desconto){
      this.descontoContaEditar = conta.desconto;
    }else{
      this.descontoContaEditar = 0;
    }
    this.docsConta = conta.anexos;
    if(this.docsConta && this.docsConta.length > 0){
      this.pdfsConta = [];
      this.imagesConta = [];
      this.docsConta.forEach((item) => {
      let ext = item.url.split('.').pop();
        if(ext == 'pdf' || ext == 'PDF'){
          this.pdfsConta.push(item);
        }else{
          this.imagesConta.push(item);
        }
      })
    }
    if(this.tipoDeConta == "REEMBOLSO"){
      this.dataSolicitacaoEditar = conta.vencimento;
      this.motivoReembolsoEditar = conta.observacao;
      this.colaboradorReembolsoEditar = conta.fornecedor;
      this.situacaoReembolsoEditar = conta.situacaoReembolso;
      this.observacaoReembolso = conta.observacaoReembolso;
      this.dataReembolso = conta.dataReembolso;
      this.listaReembolso = conta.reembolsos;
    }
    if(this.tipoDeConta == "VENCIMENTOS"){
      this.colaboradorVencimento = conta.fornecedor;
      this.tipoVencimentoCadastro = conta.tipoVencimentoCadastro;
      this.vencimentoContaEditar = conta.vencimento;
      this.valorContaEditar = conta.valor;
      this.formaPagamentoEditar = conta.formaPagamento;
      this.observacaoContaEditar = conta.observacao;
    }
    console.log(conta)
    this.ccListEditar.clear();
    if(conta.centrosCusto){
      conta.centrosCusto.forEach((item)=> {
        this.addCCEditarOpen(item);
      })
    }
    let modal = document.getElementById('modalVerContaPagarCaixa');
    modal.style.display = "block"
  }

  closeModalVerConta(){
    let modal = document.getElementById('modalVerContaPagarCaixa');
    modal.style.display = "none";
    let modalRec = document.getElementById('modalVerContaReceber');
    modalRec.style.display = "none";
    this.pdfsConta = [];
    this.imagesConta = [];
    this.filesContaEditar = [];
    this.bancoEditar = "";
    this.getContas(this.dataInicioConta,this.dataFimConta,"","","");
  }

getDocsConta(){
  this.docsConta = [];
  this.filesContaEditar = [];
  this.db.getContasById(this.idConta).subscribe((result:any) => {
    this.docsConta = result.anexos;
    this.pdfsConta = [];
    this.imagesConta = [];
    this.selectedfilesContaEditar = "";
    this.filesConta = [];
    if(this.docsConta && this.docsConta.length > 0){
      this.pdfsConta = [];
      this.imagesConta = [];
      this.docsConta.forEach((item) => {
      let ext = item.url.split('.').pop();
        if(ext == 'pdf' || ext == 'PDF'){
          this.pdfsConta.push(item);
        }else{
          this.imagesConta.push(item);
        }
      })
    }
  })
}

  delDocConta(url){
    if(window.confirm('Deseja Realmente Deletar esse Documento?')){
     this.db.removeDoc(url).subscribe(res => {
       console.log(res);
       this.db.removeDocConta(this.idConta,url).subscribe(result => {
         console.log(result);
         this.getDocsConta();
       }, error => {
         console.log(error);
       })
      },err => {
        console.log(err);
         this.db.removeDocConta(this.idConta,url).subscribe(result => {
         console.log(result);
         this.getDocsConta();
       }, error => {
         console.log(error);
       })
     })
   }
 }
 
 openModalPdfViewer(url){
  this.pdfViewerOnDemand.pdfSrc = url;
  this.pdfViewerOnDemand.refresh();
}

openModalLightBox(url, i){
  this.indexImage = i;
  this.imagemSelecionada = url;
  let modal = document.getElementById('myModal')
  modal.style.display = "block";
}

nextImg(){
  let i = this.indexImage
  if((this.images.length - 1) == i){
    this.imagemSelecionada = this.images[0].url;
    this.indexImage = 0;
  }else{
    i++
    this.imagemSelecionada = this.images[i].url;
    this.indexImage++
  }
}

prevImg(){
  let i = this.indexImage
  if(i == 0){
    this.imagemSelecionada = this.images[this.images.length - 1].url;
    this.indexImage = this.images.length - 1;
  }else{
    i--
    this.imagemSelecionada = this.images[i].url;
    this.indexImage--
  }
}

closeModal(){
  let modal = document.getElementById('myModal')
  modal.style.display = "none";
}

onChangeClickContaEditar(event){
  let arrayTypes = ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'];
  this.selectedfilesContaEditar = <FileList>event.srcElement.files;
  for(let i=0; i<this.selectedfilesContaEditar.length; i++){
      if(!arrayTypes.includes(this.selectedfilesContaEditar[i].type)){
      this.app.openAlert("Tipo de Arquivo Não Suportado!\nEscolha uma imagem com a extensão .JPG, .JPEG, .PNG ou PDF")
      this.selectedfilesContaEditar = "";
    }} 
    this.filesContaEditar = [];
    for(let i=0; i<this.selectedfilesContaEditar.length; i++){
      this.filesContaEditar.push(this.selectedfilesContaEditar[i].name);
    }
}

onChangeContaEditar(event){
  let arrayTypes = ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'];
  this.selectedfilesContaEditar = <FileList>event;
  for(let i=0; i<this.selectedfilesContaEditar.length; i++){
      if(!arrayTypes.includes(this.selectedfilesContaEditar[i].type)){
      this.app.openAlert("Tipo de Arquivo Não Suportado!\nEscolha uma imagem com a extensão .JPG, .JPEG, .PNG ou PDF")
      this.selectedfilesContaEditar = "";
    }} 
    this.filesContaEditar = [];
    for(let i=0; i<this.selectedfilesContaEditar.length; i++){
      this.filesContaEditar.push(this.selectedfilesContaEditar[i].name);
    }
}

anexarDoc(){
  if(this.filesContaEditar.length > 0){
    const imagedata = new FormData();
    for(let i=0; i<this.selectedfilesContaEditar.length; i++){imagedata.append('files[]', this.selectedfilesContaEditar[i], this.selectedfilesContaEditar[i].name)};
     this.db.enviarDocFinanceiro(this.idConta, imagedata).subscribe((res) => {
         console.log(res);
         if(this.tipoConta == "Entrada"){
          this.getDocsContaReceber();
         }else{
          this.getDocsConta();
         }
       },(error) => {
         console.log(error);
         if(this.tipoConta == "Entrada"){
          this.getDocsContaReceber();
         }else{
          this.getDocsConta();
         }
      });
  }
}

 editarConta(){
  let ctrl:number = 1;
  let total:number = 0;
  this.ccListEditar.value.forEach((cc:any) => {
    total += cc.valor;
    if(this.ccListEditar.value.length == ctrl){ 
      let fix = total.toFixed(2);
      total = Number(fix)
      console.log(this.tipoConta)
      if(this.valorTotalContaEditar == total || this.tipoConta == "Entrada"){ 
      let dados = {
        fornecedor:this.fornecedorEditar,
        favorecido:this.favorecidoEditar,
        observacao:this.observacaoContaEditar,
        tipoVencimento:this.tipoVencimentoContaEditar,
        tipoVencimentoCadastro:this.tipoVencimentoCadastro,
        valor:this.valorContaEditar,
        vencimento:this.vencimentoContaEditar,
        status:"Pago",
        parcela:this.parcelaConta,
        anexos:this.docsConta,
        formaPagamento:this.formaPagamentoEditar,
        juros: this.jurosContaEditar,
        taxas: this.taxasContaEditar,
        desconto: this.descontoContaEditar,
        valorTotal: this.valorTotalContaEditar,
        dataPagamento:this.dataPagamentoEditar,
        centrosCusto: this.ccListEditar.value,
        tipoEntrada: this.tipoEntradaContaEditar,
        banco:this.bancoEditar
      }
      this.db.editarContaFinanceiro(this.idConta,dados).subscribe(res => {
          console.log(res);
          this.app.openAlert("Conta Editada com Sucesso!");
          this.closeModalVerConta();
        },err => {
          console.log(err);
          this.app.openAlert("Erro ao Editar, Tente Novamente!")
          })
        }else{
          this.app.openAlert("A Soma do Rateio dos Centros de Custos é diferente do Valor da Conta!");
        }
      }else{
        ctrl++;
      }
    })
  }

 calcularTotalEditar(){
  this.valorTotalContaEditar = this.valorContaEditar + this.jurosContaEditar + this.taxasContaEditar - this.descontoContaEditar;
}
 
estornarConta(conta){
  if(window.confirm("Deseja Realmente Estornar essa Conta?")){
  let dados = {
    status:"Pendente",
    formaPagamento:"",
    juros:0,
    desconto:0,
    taxas:0,
    valorTotal:conta.valor,
    dataPagamento:"",
    horaPagamento:"",
    dataReembolso:"",
    observacaoReembolso:"",
    situacaoReembolso:"",
  }
  this.db.editarContaFinanceiro(conta._id,dados).subscribe(res => {
      console.log(res);
      this.app.openAlert("Conta Estornada com Sucesso!");
      this.closeModalVerConta();
    },err => {
      console.log(err);
      this.app.openAlert("Erro ao Estornar, Tente Novamente!")
    })
  }
}

clearData(){
  this.page = 1;
  this.caixa = [];
  this.contasPagasVindi = [];
}

filtrar(tipo){
  this.todoCaixa = this.caixa.filter(item => {
    return item.tipo.toLowerCase().indexOf(tipo.toLowerCase()) > -1;
  });
  this.somar();
}

openModalVerContaReceber(conta){
  this.idConta = conta._id;
  this.tipoConta = conta.tipo;
  this.fornecedorEditar = conta.fornecedor;
  this.favorecidoEditar = conta.favorecido;
  this.horaPagamentoEditar = conta.hora;
  this.observacaoContaEditar = conta.observacao;
  this.tipoVencimentoContaEditar = conta.tipoVencimento;
  this.valorContaEditar = conta.valor;
  this.vencimentoContaEditar = conta.vencimento;
  this.formaPagamentoEditar = conta.formaPagamento;
  this.valorTotalContaEditar = conta.valorTotal;
  this.dataPagamentoEditar = conta.dataPagamento;
  this.parcelaConta = conta.parcela;
  this.tipoEntradaContaEditar = conta.tipoEntrada;
  this.bancoEditar = conta.banco;
  if(conta.juros){
    this.jurosContaEditar = conta.juros;
  }else{
    this.jurosContaEditar = 0;
  }
  if(conta.taxas){
    this.taxasContaEditar = conta.taxas;
  }else{
    this.taxasContaEditar = 0;
  }
  if(conta.desconto){
    this.descontoContaEditar = conta.desconto;
  }else{
    this.descontoContaEditar = 0;
  }
  this.docsConta = conta.anexos;
  if(this.docsConta && this.docsConta.length > 0){
    this.pdfsConta = [];
    this.imagesConta = [];
    this.docsConta.forEach((item) => {
    let ext = item.url.split('.').pop();
      if(ext == 'pdf' || ext == 'PDF'){
        this.pdfsConta.push(item);
      }else{
        this.imagesConta.push(item);
      }
    })
  }
  let modal = document.getElementById('modalVerContaReceber');
  modal.style.display = "block"
}

closeModalVerContaReceber(){
  let modal = document.getElementById('modalVerContaReceber');
  modal.style.display = "none";
  this.pdfsConta = [];
  this.imagesConta = [];
  this.filesContaEditar = [];
  this.tipoEntradaContaEditar = "";
  this.bancoEditar = "";
  this.getContas(this.dataInicioConta,this.dataFimConta,"","","");
}

getDocsContaReceber(){
this.docsConta = [];
this.filesContaEditar = [];
this.db.getContasById(this.idConta).subscribe((result:any) => {
  this.docsConta = result.anexos;
  this.pdfsConta = [];
  this.imagesConta = [];
  this.selectedfilesContaEditar = "";
  this.filesConta = [];
  if(this.docsConta && this.docsConta.length > 0){
    this.pdfsConta = [];
    this.imagesConta = [];
    this.docsConta.forEach((item) => {
    let ext = item.url.split('.').pop();
      if(ext == 'pdf' || ext == 'PDF'){
        this.pdfsConta.push(item);
      }else{
        this.imagesConta.push(item);
      }
    })
  }
})
}

delDocContaReceber(url){
  if(window.confirm('Deseja Realmente Deletar esse Documento?')){
   this.db.removeDoc(url).subscribe(res => {
     console.log(res);
     this.db.removeDocConta(this.idConta,url).subscribe(result => {
       console.log(result);
       this.getDocsConta();
     }, error => {
       console.log(error);
     })
    },err => {
      console.log(err);
       this.db.removeDocConta(this.idConta,url).subscribe(result => {
       console.log(result);
       this.getDocsConta();
     }, error => {
       console.log(error);
     })
   })
 }
}



}