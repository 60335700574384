import { Component, OnInit,ViewChild } from '@angular/core';
import { DataBaseService } from '../provider.service';
import { MASKS } from 'ng-brazil';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { AppComponent } from '../app.component';


@Component({
  selector: 'app-contas-receber',
  templateUrl: './contas-receber.component.html',
  styleUrls: ['./contas-receber.component.css']
})
export class ContasReceberComponent implements OnInit {
  public MASKS = MASKS;

  @ViewChild('pdfViewerContasReceber') pdfViewerOnDemand;
  @ViewChild('pdfViewerContasReceber') pdfViewerAutoLoad;
   usuario:any = "";
  dataHoje:any = "";
  idPagamentos:any = "";
  favorecidos:any = [];
  favorecidoEditar:any = [];
  razaoSocial:any = "";
  nomeFantasia:any = "";
  cnpj:any = "";
  endereco:any = "";
  numero:any = "";
  bairro:any = "";
  cidade:any = "";
  estado:any = "";
  telefone:any = "";
  celular:any = "";
  responsavel:any = "";
  email:any = "";
  favorecidoConta:any = "";
  favorecidoId:any = "";
  favorecidoIdEditar:any = "";
  valorConta:any = "";
  tipoVencimentoConta:any = "";
  vencimentoConta:any = "";
  observacaoConta:any = "";
  todasFormasPagamento:any = [];
  selectedFilescontaReceber:any = "";
  filescontaReceber:any = [];
  contasReceber:any = [];
  totalContasReceber:number = 0;
  dataInicioConta:any = "";
  dataFimConta:any = "";
  docsConta:any = [];
  pdfsConta:any = [];
  imagesConta:any = [];
  idConta:any = "";
  formaPagamento:any = "";
  indexImage:any = "";
  imagemSelecionada:any = "";
  images:any = [];
  valorContaEditar:number = 0;
  observacaoContaEditar:any = "";
  tipoVencimentoContaEditar:any = "";
  vencimentoContaEditar:any = "";
  parcelaConta:any = "";
  formaPagamentoEditar:any = "";
  selectedFilesContaReceberEditar:any = "";
  filesContaReceberEditar:any = "";
  juros:number = 0;
  desconto:number = 0;
  dataPagamento:any = "";
  valorTotal:number = 0;
  jurosContaEditar:number = 0;
  descontoContaEditar:number = 0;
  valorTotalEditar:number = 0;
  parcelados:any;
  formasPagamento:FormGroup;
  contasReceberVindi:any = [];
  page:number = 1;
  todosFavorecidos:any = [];
  favorecidoSearch:any = "";
  tipoEntradaConta:any = "";
  tipoEntradaContaEditar:any = "";
  cpfAssociado:any = "";
  cpfAssociadoEditar:any = "";
  tiposEntrada:any = [];
  associadosContas:any = [];
  bancos:any = [];
  banco:any = "";
  bancoEditar:any = "";
  cpfBusca:any = "";
  spcEditar:boolean = false;
  vindiEditar:boolean = false;
  favorecidoBusca:any = "";
  public formaPagamanetoList: FormArray;
  public parceladosList: FormArray;
  get formasPagamentoFormGroup(){return this.formasPagamento.get('forma') as FormArray;}
  get parcelasFormGroup(){return this.parcelados.get('parcela') as FormArray;}
  
  constructor(public db:DataBaseService, private formBuilder: FormBuilder, public app:AppComponent){}

  ngOnInit(){
    this.dataHoje = new Date(Date.now() - 10800000).toISOString().split('T')[0];
    this.formasPagamento = this.formBuilder.group({forma: this.formBuilder.array([this.createFormaPagamento()])});
    this.formaPagamanetoList = this.formasPagamento.get('forma') as FormArray;
    this.parcelados = this.formBuilder.group({parcela: this.formBuilder.array([this.createParcela()])});
    this.parceladosList = this.parcelados.get('parcela') as FormArray;
    this.usuario = localStorage.getItem('usuarioVivamax');
    this.db.getAssociadosProjection('"nome":1,"matricula":1,"cpf":1').subscribe((data:any) => { 
      this.associadosContas = data;
        this.getInadimplentes();
        this.getFavorecido();
        this.db.getConfigs().subscribe((z:any) => {
          this.todasFormasPagamento = z[5].pagamentos;
          this.tiposEntrada = z[21].tiposEntrada;
          let pgtos = z[5].pagamentos;
          this.idPagamentos = z[5]._id;
          this.bancos = z[25].bancos;
          if(pgtos.length > 0){
            this.formaPagamanetoList.clear();
            pgtos.forEach((item) => {
              this.addForma(item);
            })
          }
      });
    })
  }

  buscarFavorecidoCPF(cpf:any){
    let c:any = cpf.replace(/_/g,'').replace(/[\s.-]*/igm, '');
    if(c.length == 11){
      this.db.getAssociadoByCpf('"nome":1,"matricula":1,"cpf":1,"empresa":1',cpf).subscribe((data:any) => {
        this.favorecidoConta = data[0].nome;
        this.cpfAssociado = data[0].cpf;
        this.favorecidoId = data[0]._id;
      })
    }
  }

  buscarFavorecidoCPFEditar(cpf:any){
    let c:any = cpf.replace(/_/g,'').replace(/[\s.-]*/igm, '');
    if(c.length == 11){
      this.db.getAssociadoByCpf('"nome":1,"matricula":1,"cpf":1',cpf).subscribe((data:any) => {
        this.favorecidoEditar = data[0].nome;
        this.cpfAssociadoEditar = data[0].cpf;
        this.favorecidoIdEditar = data[0]._id;
      })
    }
  }

  getInadimplentes(){
    this.totalContasReceber = 0;
    this.dataInicioConta = "2000-01-01";
    this.dataFimConta = this.dataFimConta ? this.dataFimConta : this.dataHoje;
    this.db.getContasReceber(this.dataInicioConta,this.dataFimConta,'').subscribe((dataCR:any) => {
      let semSpc = dataCR.filter(item => !item.spc);
      semSpc.map((item:any) => {
        let idx = this.associadosContas.map((aC:any) => {
          return aC.nome;
        }).indexOf(item.favorecido)
        if(idx >= 0){
          item.matricula = this.associadosContas[idx].matricula;
        }
      })
      this.contasReceber = semSpc.sort((a,b) => {
        if(a.vencimento < b.vencimento){
          return -1
        }else{
          return 1
        }
      });
      this.somar();
    })
  }

  getSpc(){
    this.totalContasReceber = 0;
    this.dataInicioConta = "2000-01-01";
    this.dataFimConta = this.dataFimConta ? this.dataFimConta : this.dataHoje;
    this.db.getContasReceber(this.dataInicioConta,this.dataFimConta,'').subscribe((dataCR:any) => {
      let filtrados = dataCR.filter(x => x.spc)
      filtrados.map((item:any) => {
        let idx = this.associadosContas.map((aC:any) => {
          return aC.nome;
        }).indexOf(item.favorecido)
        if(idx >= 0){
          item.matricula = this.associadosContas[idx].matricula;
        }
      })
      this.contasReceber = filtrados.sort((a,b) => {
        if(a.vencimento < b.vencimento){
          return -1
        }else{
          return 1
        }
      });
      this.somar();
    })
  }
  
  getVindi(){
    this.totalContasReceber = 0;
    this.dataInicioConta = "2000-01-01";
    this.dataFimConta = this.dataFimConta ? this.dataFimConta : this.dataHoje;
    this.db.getContasReceber(this.dataInicioConta,this.dataFimConta,'').subscribe((dataCR:any) => {
      let filtrados = dataCR.filter(x => x.vindi)
      filtrados.map((item:any) => {
        let idx = this.associadosContas.map((aC:any) => {
          return aC.nome;
        }).indexOf(item.favorecido)
        if(idx >= 0){
          item.matricula = this.associadosContas[idx].matricula;
        }
      })
      this.contasReceber = filtrados.sort((a,b) => {
        if(a.vencimento < b.vencimento){
          return -1
        }else{
          return 1
        }
      });
      this.somar();
    })
  }

  addForma(item){
    this.formaPagamanetoList.push(this.createForma(item));
  }

  createForma(item):FormGroup{
    return this.formBuilder.group({
      forma: [item.forma, Validators.compose([Validators.required])]
    });
  }

  createFormaPagamento():FormGroup{
    return this.formBuilder.group({
      forma: [null, Validators.compose([Validators.required])]
    });
  }

  addPagamento(){
    this.formaPagamanetoList.push(this.createFormaPagamento());
  }

  removePagamento(index:any){
    this.formaPagamanetoList.removeAt(index);
  }

  salvarFormas(){
    let dados = {
      pagamentos: this.formaPagamanetoList.value
    }
    this.db.patchConfiguracoes(this.idPagamentos,dados).subscribe(res => {
      console.log(res);
      this.app.openAlert("Formas de Pagamento salva com sucesso!");
      this.closeModalFormas();
      this.ngOnInit();
    }, err => {
      console.log(err)
    })
  }

  openModalFormas(){
    let modal = document.getElementById('modalFormasPagamento');
    modal.style.display = "block"
  }

  closeModalFormas(){
    let modal = document.getElementById('modalFormasPagamento');
    modal.style.display = "none"
  }

  getContactsFormGroup(index):FormGroup{
    const formGroup = this.parceladosList.controls[index] as FormGroup;
    return formGroup;
  }
  
  createParcela():FormGroup{
    return this.formBuilder.group({
      valor: [null, Validators.compose([Validators.required])],
      vencimento: [null, Validators.compose([Validators.required])]
    });
  }
  
  addParcela(){
    this.parceladosList.push(this.createParcela());
  }
  
  addParcelaNova(i:any){
    this.parceladosList.push(this.createParcelaNova(i));
  }

  createParcelaNova(i:any):FormGroup{
    let novoVencimento = moment(i.vencimento).add(1,'M').toISOString();
    let ven = novoVencimento.split('T')[0];
    return this.formBuilder.group({
      valor: [i.valor, Validators.compose([Validators.required])],
      vencimento: [ven, Validators.compose([Validators.required])]
    });
  }
  
  removeParcela(index) {
    this.parceladosList.removeAt(index);
  }

  getContas(dtInicio:any,dtFim:any,favorecido:any){
    this.totalContasReceber = 0;
    this.db.getContasReceber(dtInicio,dtFim,favorecido).subscribe((data:any) => {
      let semSpc = data.filter(item => !item.spc);
      semSpc.map((item:any) => {
        let idx = this.associadosContas.map((aC:any) => {
          return aC.nome;
        }).indexOf(item.favorecido)
        if(idx >= 0){
          item.matricula = this.associadosContas[idx].matricula;
        }
      })
      this.contasReceber = semSpc.sort((a,b) => {
        if(a.vencimento < b.vencimento){
          return -1
        }else{
          return 1
        }
      });
      this.somar();
    })
  }
  
  somar(){
    if(this.contasReceber.length > 0){
      this.contasReceber.forEach((item:any) => {
        this.totalContasReceber += item.valor;
      })
    }
  }

  getFavorecido(){
    let controle:number = 1;
    let ctrl:number = 1;
    let aCtrl:number = 1;
    let fav:any = [];
    this.db.getAllFavorecidos().subscribe((data:any) => {
      fav = data;
      fav.map((f:any) => {
        return f.cpf = "";
      })
      this.db.getAssociadosPlanoFisicoCarne().subscribe((aPF:any) => {
        aPF.forEach((a:any) => {
          a.razaoSocial = a.nome;
          a.imagem = true;
          fav.push(a);
          if(aPF.length == aCtrl){
            this.db.getSubContratos().subscribe((subs:any) => {
              subs.forEach((item) => {
                item.razaoSocial = item.nome;
                item.imagem = true;
                fav.push(item);
                if(subs.length == controle){
                  this.db.getEmpresas().subscribe((emp:any) => {
                    emp.forEach((e:any) => {
                      e.razaoSocial = e.razaoEmpresa;
                      e.imagem = true;
                      fav.push(e);
                      if(emp.length == ctrl){
                        this.favorecidos = fav.sort((a:any,b:any)=>{if(a.razaoSocial < b.razaoSocial)return -1});
                        this.todosFavorecidos = this.favorecidos;
                      }else{
                        ctrl++
                      }
                    })
                  })
                }else{
                  controle++
                }
              })
            })
          }else{
            aCtrl++;
          }
        })
      })
    })
  }
  
  openModalNovoFavorecido(){
    let modal = document.getElementById('modalNovoFavorecido');
    modal.style.display = "block";
  }

  closeModalNovoFavorecido(){
    let modal = document.getElementById('modalNovoFavorecido');
    modal.style.display = "none";
  }

  openModalFavorecido(){
    this.getFavorecido();
    let modal = document.getElementById('modalFavorecidos');
    modal.style.display = "block"
  }

  closeModalFavorecido(){
    let modal = document.getElementById('modalFavorecidos');
    modal.style.display = "none";
    this.favorecidoEditar = [];
  }

  openModalEditarFavorecido(favorecido){
    this.closeModalFavorecido();
    let modal = document.getElementById('modalEditarFavorecido');
    modal.style.display = "block"
    this.favorecidoEditar = favorecido;
  }

  closeModalEditarFavorecido(){
    let modal = document.getElementById('modalEditarFavorecido');
    modal.style.display = "none";
    this.favorecidoSearch = "";
  }
  
  salvarFavorecido(){
    let dados = {
      razaoSocial: this.razaoSocial,
      nomeFantasia: this.nomeFantasia,
      cnpj: this.cnpj,
      endereco: this.endereco,
      numero: this.numero,
      bairro: this.bairro,
      cidade: this.cidade,
      estado: this.estado,
      telefone: this.telefone,
      celular: this.celular,
      responsavel: this.responsavel,
      email: this.email
    };
    this.db.cadastroFavorecido(dados).subscribe(res => {
      console.log(res);
      let jsonDate = new Date().toJSON();
      let hora = new Date(jsonDate).toLocaleTimeString();
      let body = {
       "menu" : "CONTAS A RECEBER", 
       "descricao" : "Favorecido Cadastrado: "+this.razaoSocial, 
       "data" : this.dataHoje, 
       "hora" : hora, 
       "usuario" : this.usuario
     }
     this.db.postLogs(body).subscribe(res => {
       console.log(res);
       this.app.openAlert("Favorecido cadastrado com sucesso!");
      this.closeModalNovoFavorecido();
      this.getFavorecido();
    },err => console.log(err))
      
    }, err => {
      console.log(err);
      this.app.openAlert("Erro ao cadastrar Favorecido!");
    })
  }

  editarFavorecido(){
    let dados = {
      razaoSocial: this.favorecidoEditar.razaoSocial,
      nomeFantasia: this.favorecidoEditar.nomeFantasia,
      cnpj: this.favorecidoEditar.cnpj,
      endereco: this.favorecidoEditar.endereco,
      numero: this.favorecidoEditar.numero,
      bairro: this.favorecidoEditar.bairro,
      cidade: this.favorecidoEditar.cidade,
      estado: this.favorecidoEditar.estado,
      telefone: this.favorecidoEditar.telefone,
      celular: this.favorecidoEditar.celular,
      responsavel: this.favorecidoEditar.responsavel,
      email: this.favorecidoEditar.email
    };
    this.db.editarFavorecido(this.favorecidoEditar._id,dados).subscribe(res => {
      console.log(res);
      let jsonDate = new Date().toJSON();
      let hora = new Date(jsonDate).toLocaleTimeString();
      let body = {
       "menu" : "CONTAS A RECEBER", 
       "descricao" : "Favorecido Editado: "+this.favorecidoEditar.razaoSocial, 
       "data" : this.dataHoje, 
       "hora" : hora, 
       "usuario" : this.usuario
     }
     this.db.postLogs(body).subscribe(res => {
       console.log(res);
       this.app.openAlert("Favorecido editado com sucesso!");
      this.closeModalEditarFavorecido();
    },err => console.log(err))
      this.getFavorecido();
    }, err => {
      console.log(err);
      this.app.openAlert("Erro ao cadastrar Favorecido!");
    })
  }

  openModalVerConta(conta){
    this.idConta = conta._id;
    this.bancoEditar = conta.banco;
    this.favorecidoEditar = conta.favorecido;
    this.valorContaEditar = conta.valor;
    this.observacaoContaEditar = conta.observacao;
    this.tipoVencimentoContaEditar = conta.tipoVencimento;
    this.vencimentoContaEditar = conta.vencimento;
    this.parcelaConta = conta.parcela;
    this.formaPagamentoEditar = conta.formaPagamento;
    this.dataPagamento = this.dataHoje;
    this.tipoEntradaContaEditar = conta.tipoEntrada;
    this.spcEditar = conta.spc;
    this.vindiEditar = conta.vindi;
    this.cpfAssociadoEditar = conta.documentoFavorecido;
    this.cpfBusca = this.cpfAssociadoEditar ? this.cpfAssociadoEditar : "";
    this.favorecidoIdEditar = conta.idUser;
    if(conta.juros){
      this.jurosContaEditar = conta.juros;
    }else{
      this.jurosContaEditar = 0;
    }
    if(conta.desconto){
      this.descontoContaEditar = conta.desconto;
    }else{
      this.descontoContaEditar = 0;
    }
    if(conta.valorTotal){
      this.valorTotalEditar = conta.valorTotal;
    }else{
      this.valorTotalEditar = 0;
    }
    this.docsConta = conta.anexos;
    if(this.docsConta && this.docsConta.length > 0){
      this.pdfsConta = [];
      this.imagesConta = [];
      this.docsConta.forEach((item) => {
      let ext = item.url.split('.').pop();
        if(ext == 'pdf' || ext == 'PDF'){
          this.pdfsConta.push(item);
        }else{
          this.imagesConta.push(item);
        }
      })
    }
    let modal = document.getElementById('modalVerContaReceber');
    modal.style.display = "block"
  }

  closeModalVerConta(){
    let modal = document.getElementById('modalVerContaReceber');
    modal.style.display = "none";
    this.pdfsConta = [];
    this.imagesConta = [];
    this.filesContaReceberEditar = [];
    this.formaPagamento = "";
    this.jurosContaEditar = null;
    this.descontoContaEditar = null;
    this.valorTotalEditar = null;
    this.tipoEntradaContaEditar = "";
    this.spcEditar = false;
    this.vindiEditar = false;
    this.bancoEditar = "";
    this.cpfAssociadoEditar= "";
    this.favorecidoIdEditar= "";
    this.cpfBusca = "";
    this.getContas(this.dataInicioConta,this.dataFimConta,'');
  }

  openModalNovaConta(){
    this.selectedFilescontaReceber = "";
    this.filescontaReceber = [];
    let modal = document.getElementById('modalContaReceber');
    modal.style.display = "block";
  }

  closeModalNovaConta(){
    let modal = document.getElementById('modalContaReceber');
    modal.style.display = "none";
    this.favorecidoConta = "";
    this.favorecidoId = "";
    this.cpfBusca = "";
    this.cpfAssociado = "";
    this.observacaoConta = "";
    this.tipoVencimentoConta = "";
    this.valorConta = null;
    this.valorTotal = null;
    this.tipoEntradaConta = "";
    this.banco = "";
    this.cpfBusca = "";
    this.vencimentoConta = null;
    this.parceladosList.clear();
    this.addParcela();
  }
  
  onChangeClickContaReceber(event){
  let arrayTypes = ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'];
  this.selectedFilescontaReceber = <FileList>event.srcElement.files;
  for(let i=0; i<this.selectedFilescontaReceber.length; i++){
      if(!arrayTypes.includes(this.selectedFilescontaReceber[i].type)){
      this.app.openAlert("Tipo de Arquivo Não Suportado!\nEscolha uma imagem com a extensão .JPG, .JPEG, .PNG ou PDF")
      this.selectedFilescontaReceber = "";
    }} 
    this.filescontaReceber = [];
    for(let i=0; i<this.selectedFilescontaReceber.length; i++){
      this.filescontaReceber.push(this.selectedFilescontaReceber[i].name);
    }
}

onChangeContaReceber(event){
  let arrayTypes = ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'];
  this.selectedFilescontaReceber = <FileList>event;
  for(let i=0; i<this.selectedFilescontaReceber.length; i++){
      if(!arrayTypes.includes(this.selectedFilescontaReceber[i].type)){
      this.app.openAlert("Tipo de Arquivo Não Suportado!\nEscolha uma imagem com a extensão .JPG, .JPEG, .PNG ou PDF")
      this.selectedFilescontaReceber = "";
    }} 
    this.filescontaReceber = [];
    for(let i=0; i<this.selectedFilescontaReceber.length; i++){
      this.filescontaReceber.push(this.selectedFilescontaReceber[i].name);
    }
}

  cadastrarConta(){
    let jsonDate = new Date().toJSON();
    let hora = new Date(jsonDate).toLocaleTimeString();
    this.banco = !this.banco ? "" : this.banco;
    if(this.tipoVencimentoConta == "À Vista"){
      let dados = {
        usuario:this.usuario,
        data:this.dataHoje,
        hora: hora,
        favorecido:this.favorecidoConta,
        observacao:this.observacaoConta,
        tipoVencimento:this.tipoVencimentoConta,
        valor: this.valorConta,
        vencimento:this.vencimentoConta,
        status:"Pendente",
        parcela:"1/1",
        anexos:[],
        juros: 0,
        desconto: 0,
        valorTotal: this.valorConta,
        tipo:"Entrada",
        formaPagamento:"",
        tipoLancamento:"",
        aguardandoAprovacao:"",
        tipoDeConta: "",
        codigoBarras:"",
        banco:this.banco,
        tipoEntrada: this.tipoEntradaConta,
        idUser:this.favorecidoId,
        documentoFavorecido:this.cpfAssociado
         }
      this.db.postFinanceiro(dados).subscribe(res => {
        console.log(res);
        if(this.selectedFilescontaReceber.length > 0){
          const imagedata = new FormData();
          for(let i=0; i<this.selectedFilescontaReceber.length; i++){imagedata.append('files[]', this.selectedFilescontaReceber[i], this.selectedFilescontaReceber[i].name)};
           this.db.enviarDocFinanceiro( res['insertedId'], imagedata).subscribe((result) => {
               console.log(result);
               this.app.openAlert("Conta Cadastrada com Sucesso!");
              this.closeModalNovaConta();
             },(error) => {
               console.log(error);
               this.app.openAlert("Conta Cadastrada com Sucesso!");
              this.closeModalNovaConta();
             });
            }else{
              this.app.openAlert("Conta Cadastrada com Sucesso!");
              this.closeModalNovaConta();
            }
      }, err => {
        console.log(err);
        this.app.openAlert("Erro ao Cadastrar Conta, Tente Novamente!")
      });
    }
    if(this.tipoVencimentoConta == "Parcelado"){
      let control = 1;
      this.parceladosList.value.forEach((item,idx) => {
        let parcela = (idx+1)+'/'+this.parceladosList.value.length;
        let dados = {
          usuario:this.usuario,
          data:this.dataHoje,
          hora: hora,
          favorecido:this.favorecidoConta,
          observacao:this.observacaoConta,
          tipoVencimento:this.tipoVencimentoConta,
          valor:item.valor,
          vencimento:item.vencimento,
          status:"Pendente",
          parcela:parcela,
          anexos:[],
          juros: 0,
          banco:this.banco,
          desconto: 0,
          valorTotal: item.valor,
          tipo:"Entrada",
          formaPagamento:"",
          tipoLancamento:"",
          aguardandoAprovacao:"",
          tipoDeConta: "",
          codigoBarras: "",
          tipoEntrada: this.tipoEntradaConta,
          idUser:this.favorecidoId
        }
        this.db.postFinanceiro(dados).subscribe((r:any) => {
          console.log(r);
          if(this.selectedFilescontaReceber.length > 0){
            const imagedata = new FormData();
            for(let i=0; i<this.selectedFilescontaReceber.length; i++){imagedata.append('files[]', this.selectedFilescontaReceber[i], this.selectedFilescontaReceber[i].name)};
             this.db.enviarDocFinanceiro(r['insertedId'], imagedata).subscribe((result) => {
                 console.log(result);
               },(error) => {
                 console.log(error);
               });
            }
          }, err => {
            console.log(err);
            this.app.openAlert("Erro ao Cadastrar Conta, Tente Novamente!")
          })
        if(control == this.parceladosList.value.length){
          this.app.openAlert("Contas Cadastradas com Sucesso!");
          this.closeModalNovaConta();
        }else{
          control++
        }
      })
    }
    let body = {
     "menu" : "CONTAS A RECEBER", 
     "descricao" : "Conta Cadastrada do "+this.favorecidoConta, 
     "data" : this.dataHoje, 
     "hora" : hora, 
     "usuario" : this.usuario
   }
   this.db.postLogs(body).subscribe(res => {
     console.log(res);
  },err => console.log(err))
  }

  excluirConta(c:any){
    if(window.confirm("Deseja realmente excluir essa Conta?")){
      let jsonDate = new Date().toJSON();
      let hora = new Date(jsonDate).toLocaleTimeString();
      this.db.deleteContaFinanceiro(c._id).subscribe(res => {
        console.log(res);
        let body = {
          "menu" : "CONTAS A RECEBER", 
          "descricao" : "Conta Excluida do "+c.favorecido, 
          "data" : this.dataHoje, 
          "hora" : hora, 
          "usuario" : this.usuario
        }
        this.db.postLogs(body).subscribe(res => {
          console.log(res);
          this.app.openAlert("Conta Excluida com Sucesso!");
          if(c.origem == 'Faturamento'){
            this.estornarFaturamento(c);
          }
        this.getContas(this.dataInicioConta,this.dataFimConta,'');
        },err => console.log(err))
      },err => {
      console.log(err)
    })
  }
}

estornarFaturamento(f:any){
  this.db.patchFaturamentoStatus(f.idFaturamento,f.idFaturado,"NÃO FATURADO").subscribe(res => {
    console.log(res);
    let jsonDate = new Date().toJSON();
    let hora = new Date(jsonDate).toLocaleTimeString();
    let body = {
      "menu" : "CONTAS A RECEBER", 
      "descricao" : "Faturamento do "+f.favorecido+" Estornado!", 
      "data" : this.dataHoje, 
      "hora" : hora, 
      "usuario" : this.usuario
    }
    this.db.postLogs(body).subscribe(res => {
      console.log(res);
    },err => {
      console.log(err);
    });
  },err => {
    console.log(err);
  });
}

getDocsConta(){
  this.docsConta = [];
  this.db.getContasById(this.idConta).subscribe((result:any) => {
    this.docsConta = result.anexos;
    this.pdfsConta = [];
    this.imagesConta = [];
    this.selectedFilescontaReceber = "";
    this.filescontaReceber = [];
    if(this.docsConta && this.docsConta.length > 0){
      this.pdfsConta = [];
      this.imagesConta = [];
      this.docsConta.forEach((item) => {
      let ext = item.url.split('.').pop();
        if(ext == 'pdf' || ext == 'PDF'){
          this.pdfsConta.push(item);
        }else{
          this.imagesConta.push(item);
        }
      })
    }
  })
}

  delDocConta(url:any){
    if(window.confirm('Deseja Realmente Deletar esse Documento?')){
     this.db.removeDoc(url).subscribe(res => {
       console.log(res);
       this.db.removeDocConta(this.idConta,url).subscribe(result => {
         console.log(result);
         this.getDocsConta();
       }, error => {
         console.log(error);
       })
      },err => {
        console.log(err);
         this.db.removeDocConta(this.idConta,url).subscribe(result => {
         console.log(result);
         this.getDocsConta();
       }, error => {
         console.log(error);
       })
     })
     let jsonDate = new Date().toJSON();
     let hora = new Date(jsonDate).toLocaleTimeString();
     let body = {
      "menu" : "CONTAS A RECEBER", 
      "descricao" : "Documento Excluida do "+this.favorecidoEditar, 
      "data" : this.dataHoje, 
      "hora" : hora, 
      "usuario" : this.usuario
    }
    this.db.postLogs(body).subscribe(res => {
      console.log(res);
   },err => console.log(err))
  }
 }
 
  openModalPdfViewer(url:any){
   this.pdfViewerOnDemand.pdfSrc = url;
   this.pdfViewerOnDemand.refresh();
}
 
openModalLightBox(url:any, i:any){
  this.indexImage = i;
  this.imagemSelecionada = url;
  let modal = document.getElementById('myModal')
  modal.style.display = "block";
}

nextImg(){
  let i = this.indexImage
  if((this.images.length - 1) == i){
    this.imagemSelecionada = this.images[0].url;
    this.indexImage = 0;
  }else{
    i++
    this.imagemSelecionada = this.images[i].url;
    this.indexImage++
  }
}

prevImg(){
  let i = this.indexImage
  if(i == 0){
    this.imagemSelecionada = this.images[this.images.length - 1].url;
    this.indexImage = this.images.length - 1;
  }else{
    i--
    this.imagemSelecionada = this.images[i].url;
    this.indexImage--
  }
}

closeModal(){
  let modal = document.getElementById('myModal')
  modal.style.display = "none";
}

onChangeClickContaReceberEditar(event:any){
  let arrayTypes = ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'];
  this.selectedFilesContaReceberEditar = <FileList>event.srcElement.files;
  for(let i=0; i<this.selectedFilesContaReceberEditar.length; i++){
      if(!arrayTypes.includes(this.selectedFilesContaReceberEditar[i].type)){
      this.app.openAlert("Tipo de Arquivo Não Suportado!\nEscolha uma imagem com a extensão .JPG, .JPEG, .PNG ou PDF")
      this.selectedFilesContaReceberEditar = "";
    }} 
    this.filesContaReceberEditar = [];
    for(let i=0; i<this.selectedFilesContaReceberEditar.length; i++){
      this.filesContaReceberEditar.push(this.selectedFilesContaReceberEditar[i].name);
    }
}

onChangeContaReceberEditar(event:any){
  let arrayTypes = ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'];
  this.selectedFilesContaReceberEditar = <FileList>event;
  for(let i=0; i<this.selectedFilesContaReceberEditar.length; i++){
      if(!arrayTypes.includes(this.selectedFilesContaReceberEditar[i].type)){
      this.app.openAlert("Tipo de Arquivo Não Suportado!\nEscolha uma imagem com a extensão .JPG, .JPEG, .PNG ou PDF")
      this.selectedFilesContaReceberEditar = "";
    }} 
    this.filesContaReceberEditar = [];
    for(let i=0; i<this.selectedFilesContaReceberEditar.length; i++){
      this.filesContaReceberEditar.push(this.selectedFilesContaReceberEditar[i].name);
    }
}

anexarDoc(){
  if(this.filesContaReceberEditar.length > 0){
    const imagedata = new FormData();
    for(let i=0; i<this.selectedFilesContaReceberEditar.length; i++){imagedata.append('files[]', this.selectedFilesContaReceberEditar[i], this.selectedFilesContaReceberEditar[i].name)};
     this.db.enviarDocFinanceiro(this.idConta, imagedata).subscribe((res) => {
         console.log(res);
         this.getDocsConta();
       },(error) => {
         console.log(error);
         this.getDocsConta();
       });
  }
}

 editarConta(){
  if(window.confirm("Deseja Realmente Editar essa Conta?")){
    this.bancoEditar = !this.bancoEditar ? "" : this.bancoEditar;
  let dados = {
    favorecido:this.favorecidoEditar,
    observacao:this.observacaoContaEditar,
    tipoVencimento:this.tipoVencimentoContaEditar,
    valor:this.valorContaEditar,
    vencimento:this.vencimentoContaEditar,
    status:"Pendente",
    parcela:this.parcelaConta,
    anexos:this.docsConta,
    formaPagamento:this.formaPagamentoEditar,
    juros: this.jurosContaEditar,
    desconto: this.descontoContaEditar,
    valorTotal: this.valorTotalEditar,
    tipoEntrada: this.tipoEntradaContaEditar,
    tipo:"Entrada",
    spc:this.spcEditar,
    vindo:this.vindiEditar,
    banco:this.bancoEditar,
    idUser: this.favorecidoIdEditar,
    documentoFavorecido:this.cpfAssociadoEditar
  }
  this.db.editarContaFinanceiro(this.idConta,dados).subscribe(res => {
      console.log(res);
      let jsonDate = new Date().toJSON();
      let hora = new Date(jsonDate).toLocaleTimeString();
      let body = {
       "menu" : "CONTAS A RECEBER", 
       "descricao" : "Conta Editada do "+this.favorecidoEditar, 
       "data" : this.dataHoje, 
       "hora" : hora, 
       "usuario" : this.usuario
     }
     this.db.postLogs(body).subscribe(res => {
       console.log(res);
       this.app.openAlert("Conta Editada com Sucesso!");
      this.closeModalVerConta();
    },err => console.log(err))      
    },err => {
      console.log(err);
      this.app.openAlert("Erro ao Editar, Tente Novamente!")
    })
  }
 }

 receberConta(){
  if(window.confirm("Deseja Realmente Receber essa Conta?")){
    this.bancoEditar = !this.bancoEditar ? "" : this.bancoEditar;
  let jsonDate = new Date().toJSON();
  let hora = new Date(jsonDate).toLocaleTimeString();
  let dados = {
    status:"Pago",
    formaPagamento:this.formaPagamentoEditar,
    juros: this.jurosContaEditar,
    desconto: this.descontoContaEditar,
    valor:this.valorContaEditar,
    vencimento:this.vencimentoContaEditar,
    valorTotal: this.valorTotalEditar,
    dataPagamento:this.dataPagamento,
    horaPagamento:hora,
    tipoEntrada: this.tipoEntradaContaEditar,
    tipo:"Entrada",
    spc:this.spcEditar,
    vindi:this.vindiEditar,
    banco:this.bancoEditar,
    observacao:this.observacaoContaEditar
  }
  this.db.editarContaFinanceiro(this.idConta,dados).subscribe(res => {
      console.log(res);
      let jsonDate = new Date().toJSON();
      let hora = new Date(jsonDate).toLocaleTimeString();
      let body = {
       "menu" : "CONTAS A RECEBER", 
       "descricao" : "Conta Recebida do "+this.favorecidoEditar, 
       "data" : this.dataHoje, 
       "hora" : hora, 
       "usuario" : this.usuario
     }
     this.db.postLogs(body).subscribe(res => {
       console.log(res);
      this.app.openAlert("Conta Recebida com Sucesso!");
      this.closeModalVerConta();
    },err => console.log(err))
    },err => {
      console.log(err);
      this.app.openAlert("Erro ao Receber, Tente Novamente!")
    })
  }
}

calcularTotalEditar(){
  let vlrJuros = (this.valorContaEditar + this.jurosContaEditar - this.descontoContaEditar).toFixed(2)
  this.valorTotalEditar = Number(vlrJuros);
}

buscarFavorecido(favorecido:any){
  this.todosFavorecidos = this.favorecidos.filter(item => {
    return item.razaoSocial.toLowerCase().indexOf(favorecido.toLowerCase()) > -1;
  });
}

receberRapido(conta,i){
  if(window.confirm("Deseja Realmente Receber essa Conta?")){
  let jsonDate = new Date().toJSON();
  let hora = new Date(jsonDate).toLocaleTimeString();
  let dados = {
    status:"Pago",
    formaPagamento:"BOLETO BANCÁRIO",
    juros: conta.juros,
    desconto: conta.desconto,
    valorTotal: conta.valorTotal,
    dataPagamento:this.dataHoje,
    horaPagamento:hora,
    tipo:"Entrada",
    banco:""
  }
  this.db.editarContaFinanceiro(conta._id,dados).subscribe(res => {
      console.log(res);
      let jsonDate = new Date().toJSON();
      let hora = new Date(jsonDate).toLocaleTimeString();
      let body = {
       "menu" : "CONTAS A RECEBER", 
       "descricao" : "Conta Recebida Rápida do "+conta.favorecido, 
       "data" : this.dataHoje, 
       "hora" : hora, 
       "usuario" : this.usuario
     }
     this.db.postLogs(body).subscribe(res => {
       console.log(res);
      this.contasReceber.splice(i,1)
      this.app.openAlert("Conta Recebida com Sucesso!");
    },err => console.log(err))
    },err => {
      console.log(err);
      this.app.openAlert("Erro ao Receber, Tente Novamente!")
    })
  }
}

escolheFavorecido(k:any){
  if(k){
    this.favorecidoConta = k.razaoSocial;
    this.favorecidoId = k._id;
    this.cpfAssociado = k.cpf;
    this.cpfBusca = k.cpf;
  }else{
    this.favorecidoConta = "";
    this.favorecidoId = "";
    this.cpfAssociado = "";
    this.cpfBusca = "";
  }
}

excluirFavorecido(key:any){
  if(window.confirm("Deseja Realmente Excluir esse Favorecido?")){
    this.db.deleteFavorecido(key?._id).subscribe(res => {
      console.log(res);
      let jsonDate = new Date().toJSON();
      let hora = new Date(jsonDate).toLocaleTimeString();
      let body = {
          "menu" : "CONTAS A RECEBER", 
          "descricao" : "Favorecido Excluido: "+key?.razaoSocial, 
          "data" : this.dataHoje, 
          "hora" : hora, 
          "usuario" : this.usuario
        }
        this.db.postLogs(body).subscribe(res => {
          console.log(res);
          this.app.openAlert("Favorecido Excluido com Sucesso!");
          this.favorecidoSearch = "";
          this.getFavorecido();
        })
    }, err => {
      console.log(err);
      this.app.openAlert("Não foi possivel excluir o Favorecido!");
    })
  }
}


}