import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { DataBaseService } from '../provider.service';

@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.css']
})

export class InicioComponent implements OnInit{

  constructor(public db:DataBaseService, public auth:AuthService) { }

ngOnInit(): void {
  if(localStorage.getItem('idUserVivamax')){
    this.db.getUsuarioById(localStorage.getItem('idUserVivamax')).subscribe((usr:any) => {
      if(usr.situacao == 'DEMITIDO'){
        this.auth.logOut()
      }
    })
  }
}

formatCPF(cpfCnpj)  {
  let cpfCnpjStr:any = cpfCnpj.toString();
  if (cpfCnpjStr.length === 10) {
      cpfCnpjStr = '0' + cpfCnpjStr;
  }
    if (cpfCnpjStr.length === 9) {
      cpfCnpjStr = '00' + cpfCnpjStr;
  }
  if (cpfCnpjStr.length === 8) {
    cpfCnpjStr = '000' + cpfCnpjStr;
  }
  return cpfCnpjStr;
}

formatId(cpfCnpj)  {
  let cpfCnpjStr:any = cpfCnpj.toString();
  cpfCnpjStr = '0' + cpfCnpjStr;
  return cpfCnpjStr;
}

}