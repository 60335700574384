import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MASKS } from 'ng-brazil';
import { TabsetComponent } from 'ngx-bootstrap/tabs';
import { DataBaseService } from '../provider.service';
import html2canvas from 'html2canvas';
import { jsPDF } from "jspdf";
import * as moment from 'moment';
import { AppComponent } from '../app.component';
import { forkJoin, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Component({
  selector: 'app-associados',
  templateUrl: './associados.component.html',
  styleUrls: ['./associados.component.css']
})
export class AssociadosComponent implements OnInit {
  public MASKS = MASKS;
  @ViewChild('pdfViewerAssociado') pdfViewerOnDemand;
  @ViewChild('staticTabs', { static: false }) staticTabs: TabsetComponent;
  tipoProduto:any = "TODOS";
  empresaAssociado:any = "";
  loadCard:boolean = false;
  user:any = "";
  associados:any = [];
  todosVendedores:any = [];
  todosDependentes:any = [];
  agendamentos:any = [];
  tempArray:any = [];
  emails:any = [];  
  planosSeguro:any = [];
  planosSeguroEditar:any = [];
  extratoAssociado:any = [];
  matriculaSearch:any = "";
  situacao:any = "";
  vendedor:any = "";
  dataHoje:any = new Date(Date.now() - 10800000).toISOString().split('T')[0];
  cpfAssociado:any = "";
  matricula:any = "";
  matriculaServidor:any = null;
  matriculaServidorEditar:any = null;
  subContrato:any = "";
  planoFisicoCheck:boolean = false;
  planoEmpresarialCheck:boolean = false;
  semAssinatura:boolean = false;
  semAutorizacao:boolean = false;
  gerarLinkCartao:boolean = false;
  confirmarCartao:boolean = false;
  cpf:any = "";
  estadoCivil:any = "";
  nascimento:any = "";
  nome:any = "";
  telefone:any = "";
  celular:any = "";
  rua:any = "";
  numero:any = "";
  complemento:any = "";
  bairro:any = "";
  municipio:any = "";
  estado:any = "";
  email:any = "";
  localTrabalho:any = "";
  funcao:any = "";
  doenca:any = "";
  especificarDoenca:any = "";
  mensalidade:number = 0;
  totalMensalidade:number = 0;
  taxaAdesao:number = 0;
  diaVencimento:number = 0;
  subContratos:any = [];
  historicos:any = [];
  motivo:any = "";
  motivoInadimplente:any = "";
  mensagem:any = "";
  idAssociado:any = "";
  nomeTitular:any = "";
  nomeBeneficiario:any = "";
  sexo:any = "";
  cep:any = "";
  planosFisicos = [];
  planosServidores = [];
  planosEmpresariais = [];
  plano:any = "";
  dependentes:FormGroup;
  dependentesExtra:FormGroup;
  dependentesEditar:FormGroup;
  dependentesExtraEditar:FormGroup;
  cartoes:any = ["VISA","MASTERCARD","ELO","DINERS CLUB","AMERICAN EXPRESS","HIPERCARD"];
  cartaoSelecionado:any = "";
  formaPagamento:any = "";
  mesFolhaPagamento:any = "";
  dataAdesao:any = "";
  whatsapp:boolean = false;
  nomeCartao:any = "";
  numeroCartao:any = "";
  validadeCartao:any = "";
  codigoCartao:any = "";
  idVendedor:any = "";
  backup:any = [];
  matriculaEditar:any = "";
  subContratoEditar:any = "";
  planoFisicoCheckEditar:boolean = false;
  planoEmpresarialCheckEditar:boolean = false;
  cpfEditar:any = "";
  sexoEditar:any = "";
  estadoCivilEditar:any = "";
  nascimentoEditar:any = "";
  nomeEditar:any = "";
  telefoneEditar:any = "";
  celularEditar:any = "";
  ruaEditar:any = "";
  numeroEditar:any = "";
  complementoEditar:any = "";
  bairroEditar:any = "";
  municipioEditar:any = "";
  estadoEditar:any = "";
  emailEditar:any = "";
  localTrabalhoEditar:any = "";
  funcaoEditar:any = "";
  doencaEditar:any = "";
  numSorte:any = null;
  especificarDoencaEditar:any = "";
  formaPagamentoEditar:any = "";
  mensalidadeEditar:any = 0;
  taxaAdesaoEditar:any = 0;
  diaVencimentoEditar:number = 0;
  mesFolhaPagamentoEditar:any = "";
  whatsappEditar:any = "";
  cepEditar:any = "";
  dataAdesaoEditar:any = "";
  planoEditar:any = "";
  totalMensalidadeEditar:any = 0;
  nomeCartaoEditar:any = "";
  numeroCartaoEditar:any = "";
  validadeCartaoEditar:any = "";
  codigoCartaoEditar:any = "";
  idVendedorEditar:any = "";
  vendedorEditar:any = "";
  cartaoSelecionadoEditar:any = "";
  pacienteMedico:any = "";
  telefoneContatoMedico:any = "";
  whatsappContatoMedico:boolean = false;
  telefoneRecadoMedico:any = "";
  whatsappRecadoMedico:boolean = false;
  guiaSusMedico:any = "";
  procedimentoMedico:any = "";
  especialidadeMedico:any = "";
  medicoPreferencialMedico:any = "";
  medicoPreferencia:any = "";
  primeiraDataMedico:any = "";
  primeiroPeriodoMedico:any = "";
  segundaDataMedico:any = "";
  segundoPeriodoMedico:any = "";
  laboratorioMedico:any = "";
  telefoneMedico:any = "";
  secretariaMedico:any = "";
  jaEPacienteMedico:any = "";
  agendadoParaMedico:any = "";
  horarioMedico:any = "";
  guiaEmitidaMedico:any = "";
  levarGuiaMedico:boolean = false;
  retiraGuiaMedico:boolean = false;
  semGuiaMedico:boolean = false;
  observacaoMedico:any = "";
  finalizadoMedico:any = 'NÃO';
  pacienteMedicoEditar:any = "";
  telefoneContatoMedicoEditar:any = "";
  whatsappContatoMedicoEditar:boolean = false;
  telefoneRecadoMedicoEditar:any = "";
  whatsappRecadoMedicoEditar:boolean = false;
  guiaSusMedicoEditar:any = "";
  procedimentoMedicoEditar:any = "";
  especialidadeMedicoEditar:any = "";
  medicoPreferencialMedicoEditar:any = "";
  medicoPreferenciaEditar:any = "";
  primeiraDataMedicoEditar:any = "";
  primeiroPeriodoMedicoEditar:any = "";
  segundaDataMedicoEditar:any = "";
  segundoPeriodoMedicoEditar:any = "";
  laboratorioMedicoEditar:any = "";
  profissionalMedicoEditar:any = "";
  profissionalMedico:any = "";
  telefoneMedicoEditar:any = "";
  secretariaMedicoEditar:any = "";
  jaEPacienteMedicoEditar:any = "";
  agendadoParaMedicoEditar:any = "";
  horarioMedicoEditar:any = "";
  guiaEmitidaMedicoEditar:any = "";
  levarGuiaMedicoEditar:boolean = false;
  retiraGuiaMedicoEditar:boolean = false;
  semGuiaMedicoEditar:boolean = false;
  observacaoMedicoEditar:any = "";
  finalizadoMedicoEditar:any = "NÃO";
  idAgendamento:any = "";
  dataAgendamentoEditar:any = "";
  dataAgendamento:any = "";
  cpfCheck:boolean = true;
  matriculaCheck:boolean;
  cpfCheckEditar:boolean = true;
  matriculaCheckEditar:boolean;
  bloqueioPagamentos:boolean = false;
  formaPagamentoConsulta:any = "";
  valorConsultaMedicaEditar:number = null;
  formaPagamentoMedicoEditar:any = "";
  precisaTrocoMedicoEditar:any = "";
  trocoMedicoEditar:any = "";
  funeralConjuge:any = "";
  funeralExtra:any = "";
  funeralFilhosMaior:any = "";
  funeralFilhosMenor:any = "";
  editaAdesao:any = "";
  editaMensalidade:any = "";
  editaTotal:any = "";
  valorCapitalSegurado:number = 0;
  valorFuneralConjuge:number = 0;
  valorFuneralExtra:number = 0;
  valorFuneralFilhosMaior:number = 0;
  valorFuneralFilhosMenor:number = 0;
  funeralConjugeEditar:any = "";
  funeralExtraEditar:any = "";
  funeralFilhosMaiorEditar:any = "";
  funeralFilhosMenorEditar:any = "";
  editaAdesaoEditar:any = "";
  editaMensalidadeEditar:any = "";
  editaTotalEditar:any = "";
  valorCapitalSeguradoEditar:number = 0;
  valorFuneralConjugeEditar:number = 0;
  valorFuneralExtraEditar:number = 0;
  valorFuneralFilhosMaiorEditar:number = 0;
  valorFuneralFilhosMenorEditar:number = 0;
  novoAgendamento:boolean = false;
  editSinitro:boolean = false;
  indenizacaoSinistro:boolean = false;
  assistenciaSinistro:boolean = false;
  servicosSinistro:boolean = false;
  dataSinistro:any = "";
  numeroSinistro:number = null;
  valorSinistro:number = null;
  dataIndenizacao:any = "";
  qualIndenizacaoSinistro:any = "";
  qualAssistenciaSinistro:any = "";
  qualServicosSinistro:any = "";
  mensagemSinistro:any = "";
  nomeSinistro:any = "";
  sinistroHistoricos:any = [];
  totalAsfDependentes:number = 0;
  totalAsfDependentesExtras:number = 0;
  totalAsfDependentesEditar:number = 0;
  totalAsfDependentesExtrasEditar:number = 0;
  planosFisicosEditar:any = [];
  planosServidoresEditar:any = [];
  planosEmpresariaisEditar:any = [];
  dependenteTipo:any = "";
  novoSinistro:boolean = true;
  valorConsultaMedica:number = null;
  formaPagamentoMedico:any = "";
  precisaTrocoMedico:any = "";
  trocoMedico:number = null;
  files:any = [];
  selectedFiles:any = [];
  indexImage:number = 0;
  imagemSelecionada:any = "";
  pdfs:any = [];
  images:any = [];
  docs:any = [];
  maisSessentaNove:boolean = false;
  filhoMenorIdadeCheck:boolean = false;
  sinistrado:any = [];
  tipoSinistrado:any = "";
  situacaoSinistro:any = "";
  motivoCancelamento:any = "";
  observacoesCancelamento:any = "";
  spcCancelamento:boolean = false;
  selectedFilesSinistro:any = [];
  filesSinistro:any = [];
  pdfsSinistro:any = [];
  imagesSinistro:any = [];
  docsSinistro:any = [];
  show:boolean = false;
  imagemSelecionadaSinistro:any = "";
  indexImageSinistro:number = 0;
  indexImageCancelamento:number = 0;
  imagemSelecionadaCancelamento:any = "";
  imagesCancelamento:any = [];
  selectedFilesCancelamento:any = [];
  filesCancelamento:any = [];
  pdfsCancelamento:any = [];
  docsCancelamento:any = [];
  botao:boolean = true;
  dep:any = [];
  depExtras:any = [];
  cnpjSubContrato:any = "";
  mesSeguinte:any = "";
  mesFolhaPagamentoEditarPrint:any = "";
  pendente:boolean = false;
  observacaoPendente:any = "";
  observacaoPendenteEditar:any = "";
  situacaoEditar:any = "";
  situacaoOriginal:any = "";
  credenciados:any = [];
  listaProfissionais:any = [];
  todasEspecialidades:any = [];
  motivosPendencia:any = [];
  motivosCancelamento:any = [];
  motivosInadimplente:any = [];
  motivoPendencia:any = "";
  motivoPendenciaEditar:any = "";
  checkSolicitouCancelamento:boolean = false;
  valorPersonalizado:boolean = false;
  motivoCancelarSolicitacaoCancelamento:any = "";
  solicitadoCancelamento:boolean = false;
  motivoSolicitacaoCancelamento:any = "";
  senhaEditar:any = "";
  empresas:any = [];
  empresa:any = [];
  origem:any = "";
  hibrido:any = false;
  hibridoEditar:any = false;
  codigoEmpresa:any = "";
  afastadoDesde:any = "";
  vendedorEmpresaOriginal:any = "";
  subCheckConsignet:boolean = false;
  planosEmpresa:any = [];
  carteirinhaSelecionada:any = [];
  carteirinhas:any = [];
  carteirinhasExtras:any = [];
  unidadeConsumidora:any = "";
  primeiroVencimento:any = "";
  primeiroVencimentoEditar:any = "";
  loginEconsig:any = "";
  loginEconsigEditar:any = "";
  senhaEconsig:any = "";
  senhaEconsigEditar:any = "";
  tipoPlano:any = "";
  cpfAnalise:boolean = false;
  mudancaParaConecta:boolean = false;
  mudancaDeConecta:boolean = false;
  numeroCartaoOriginal:any = "";
  codigoCartaoOriginal:any = "";
  dataFim:any = new Date(Date.now() - 10800000).toISOString().split('T')[0];
  dataInicio:any = new Date(Date.now() - 10800000).toISOString().split('T')[0];
  arrConecta:any = [];
  arrConectaExtra:any = [];
  conectaInclusos:number = 0;
  valorConectaExtra:number = 0;
  maxDependentesConecta:number = 0;
  maxDependentesConectaEditar:number = 0;
  conectaInclusosEditar:number = 0;
  valorConectaExtraEditar:number = 0;
  qtdeConecta:number = 0;
  totalValorConectaExtras:number = 0;
  totalValorConectaExtrasEditar:number = 0;
  public listaDeDependentes:FormArray;
  public listaDeDependentesExtra:FormArray;
  public listaDeDependentesEditar:FormArray;
  public listaDeDependentesExtraEditar:FormArray;
  get dependentesFormGroup(){return this.dependentes.get('dependente') as FormArray;};
  get dependentesExtraFormGroup(){return this.dependentesExtra.get('dependenteExtra') as FormArray;};
  get dependentesEditarFormGroup(){return this.dependentesEditar.get('dependenteEditar') as FormArray;};
  get dependentesExtraEditarFormGroup(){return this.dependentesExtraEditar.get('dependenteExtraEditar') as FormArray;};
  key:any = null;

  constructor(public db:DataBaseService, private formBuilder: FormBuilder,private route: ActivatedRoute, public app:AppComponent){
    this.key = this.route.snapshot.paramMap.get('key');
    this.user = localStorage.getItem('usuarioVivamax');
   }

   selectTab(tabId: number){
     this.staticTabs.tabs[tabId].active = true;
    }

   ngOnInit(){
     this.dataHoje = new Date(Date.now() - 10800000).toISOString().split('T')[0];
     this.associados = [];
     this.dependentes = this.formBuilder.group({
      dependente: this.formBuilder.array([])
      });
      this.dependentesExtra = this.formBuilder.group({
        dependenteExtra: this.formBuilder.array([])
      });
      this.dependentesEditar = this.formBuilder.group({
        dependenteEditar: this.formBuilder.array([])
      });
      this.dependentesExtraEditar = this.formBuilder.group({
        dependenteExtraEditar: this.formBuilder.array([])
      });
      this.listaDeDependentes = this.dependentes.get('dependente') as FormArray;
      this.listaDeDependentesExtra = this.dependentesExtra.get('dependenteExtra') as FormArray;
      this.listaDeDependentesEditar = this.dependentesEditar.get('dependenteEditar') as FormArray;
      this.listaDeDependentesExtraEditar = this.dependentesExtraEditar.get('dependenteExtraEditar') as FormArray;
      this.db.getVendedoresAtivosProjection('"nome":1,"razaoEmpresa":1').subscribe((vend:any) => {
      vend.map((v:any) => {
        if(v.nome == ''){
          v.nome = v.razaoEmpresa;
        }
      })
      this.todosVendedores = vend.sort((a:any,b:any) => {if(a.nome < b.nome)return -1});
       this.db.getSubContratos().subscribe((data:any) => {
         this.subContratos = data;
         this.planosFisicos = [];
         this.planosServidores = [];
         this.planosEmpresariais = [];
         this.db.getPlanos().subscribe((p:any) => {
           p.forEach((item) => {
            if(item.tipo == 'PLANO FISICO'){
              this.planosFisicos.push(item);
              this.planosFisicos.sort((a,b) => {
                if(a.nome < b.nome)return -1;
                if(a.nome > b.nome)return 1;
              });
            }
            if(item.tipo == 'PLANO EMPRESARIAL'){
              this.planosEmpresariais.push(item);
              this.planosEmpresariais.sort((a,b) => {
                if(a.nome < b.nome)return -1;
                if(a.nome > b.nome)return 1;
              });
            }
            if(item.tipo == 'PLANO SERVIDOR'){
              this.planosServidores.push(item);
              this.planosServidores.sort((a,b) => {
                if(a.nome < b.nome)return -1;
                if(a.nome > b.nome)return 1;
              });
            }
           })
           this.db.getCredenciadosProjection('"razaoCredenciado":1,"profissionais":1').subscribe((cred:any) => {
            this.credenciados = cred;
            this.db.getEmpresas().subscribe((data:any) => {
              this.empresas = data;
            });
          });
         });
          this.motivosPendencia = this.db.configuracoes[11].motivosPendencia;
          this.motivosInadimplente = this.db.configuracoes[12].inadimplencias;
          this.motivosCancelamento = this.db.configuracoes[13].motivosCancelamento;
          this.todasEspecialidades = this.db.configuracoes[7].especialidades.sort((a,b) => {if(a.nome < b.nome){return -1}if(b.nome < a.nome){return 1}});
        });
       });
       if(this.key){
        this.matriculaSearch = this.key
        this.buscarAssociadoMatricula(this.key);
       }else{
        this.buscarPorData(this.dataInicio,this.dataFim);
       }
   }

  createDependente():FormGroup{
    return this.formBuilder.group({
      tipo:[null, Validators.compose([Validators.required])],
      nome: [null, Validators.compose([Validators.required])],
      nascimento:[null, Validators.compose([Validators.required])],
      cpf: null,
      asf:null,
      corretora:false,
      numSorte:null,
      situacao:'ATIVO',
      conecta:null,
      email:null,
      sexo:null
    });
  }

  addDependente(){
    this.listaDeDependentes.push(this.createDependente());
  }

  removeDependente(index){
    this.listaDeDependentes.removeAt(index);
    this.selectAsf();
  }

  createDependenteExtra():FormGroup{
    return this.formBuilder.group({
      nome: [null, Validators.compose([Validators.required])],
      nascimento:[null, Validators.compose([Validators.required])],
      cpf: null,
      tipo:[null, Validators.compose([Validators.required])],
      asf:null,
      corretora:false,
      numSorte:null,
      situacao:'ATIVO',
      conecta:null,
      email:null,
      sexo:null
    });
  }

  addDependenteExtra(){
    this.listaDeDependentesExtra.push(this.createDependenteExtra());
  }

  removeDependenteExtra(index){
    this.listaDeDependentesExtra.removeAt(index);
    this.selectAsfExtras();
  }

  createDependenteEditar(item):FormGroup{
    return this.formBuilder.group({
      nome: item.nome,
      nascimento:item.nascimento,
      cpf:item.cpf,
      tipo:item.tipo,
      asf:item.asf,
      corretora:item.corretora,
      numSorte:item.numSorte,
      sinistro:item.sinistro,
      situacao:'ATIVO',
      conecta:item.conecta,
      email:item.email,
      sexo:item.sexo
    });
  }

  addDependenteEditar(item){
    this.listaDeDependentesEditar.push(this.createDependenteEditar(item));
  }

  removeDependenteEditar(index){
    if(window.confirm("Deseja realmente remover esse Dependente?")){
      if(this.listaDeDependentesEditar.value[index].sinistro){
        this.app.openAlert("Dependente Possui Sinistro Registrado, Impossível Remover!")
      }else{
        this.arrConecta.push(this.dependentesEditar.value.dependenteEditar[index]);
        this.listaDeDependentesEditar.removeAt(index);
        this.selectAsfEditar();
        this.selectAsfExtrasEditar();
      }
    }
  }

  createDependenteExtraEditar(item):FormGroup{
    return this.formBuilder.group({
      nome: item.nome,
      nascimento:item.nascimento,
      cpf:item.cpf,
      tipo:item.tipo,
      asf:item.asf,
      corretora:item.corretora,
      numSorte:item.numSorte,
      sinistro:item.sinistro,
      situacao:'ATIVO',
      conecta:item.conecta,
      email:item.email,
      sexo:item.sexo
    });
  }

  addDependenteExtraEditar(item){
    this.listaDeDependentesExtraEditar.push(this.createDependenteExtraEditar(item));
  }

  removeDependenteExtraEditar(index){
    if(window.confirm("Deseja realmente remover esse Dependente?")){
      if(this.listaDeDependentesExtraEditar.value[index].sinistro){
        this.app.openAlert("Dependente Possui Sinistro Registrado, Impossível Remover!")
      }else{
        this.arrConectaExtra.push(this.dependentesExtraEditar.value.dependenteExtraEditar[index]);
        this.listaDeDependentesExtraEditar.removeAt(index);
        this.selectAsfEditar();
        this.selectAsfExtrasEditar();
      }
    }
  }

  buscarAssociadoMatricula(matricula){
    this.db.getAssociadosMatricula(matricula,this.tipoProduto).subscribe((data:any) => {
      this.associados = data
    })
  }

  buscarAssociadoCpf(cpf){
    this.db.getAssociadosCpf(cpf,this.tipoProduto).subscribe((data:any) => {
      this.associados = data
    })
  }

   buscarTitular(nomeTitular){
    this.db.getAssociadoNome(nomeTitular,this.tipoProduto).subscribe((data:any) => {
      this.associados = data
    })
   }
   
   buscarPorData(dtInicio,dtFim){
    this.db.getTodosAssociadosData(dtInicio,dtFim,this.tipoProduto).subscribe((data:any) => {
      this.associados = data;
    })
   }

   buscarBeneficiario(nomeBeneficiario){
    this.associados = [];
    let ctrlDep = 1;
    let ctrlDepExtra = 1;
    this.db.getAssociadosVivamaxBeneficiario(nomeBeneficiario).subscribe((data:any) => {
      if(data.length > 0){
          data.forEach((item) => {
            let idx = this.associados.map((w) => {
              return w.nome
            }).indexOf(item.nome);
           if(idx < 0){
            this.associados.push(item)
           } 
          if(data.length == ctrlDep){
            this.db.getAssociadosVivamaxBeneficiarioExtra(nomeBeneficiario).subscribe((dataExtra:any) => {
              if(dataExtra.length > 0){
                dataExtra.forEach((itemExtra) => {   
                  let idxE = this.associados.map((w) => {
                    return w.nome
                  }).indexOf(itemExtra.nome);
                 if(idxE < 0){
                  this.associados.push(itemExtra)
                 } 
                 if(dataExtra.length == ctrlDepExtra){ 
                  this.associados.sort((a,b) => {
                    if(a.nome < b.nome){
                      return -1;
                    }else{
                      return 1;
                    }
                  });
                }else{
                  ctrlDepExtra++
                }
              });
            }else{
              this.associados.sort((a,b) => {
                if(a.nome < b.nome){
                  return -1;
                }else{
                  return 1;
                }
              });
            }
          })
          }else{
            ctrlDep++
          }
        });
      }else{
         this.db.getAssociadosVivamaxBeneficiarioExtra(nomeBeneficiario).subscribe((dataExtra:any) => {
          if(dataExtra.length > 0){
            dataExtra.forEach((item) => {
              let idx = this.associados.map((w) => {
                return w.nome
              }).indexOf(item.nome);
             if(idx < 0){
              this.associados.push(item)
             } 
            if(dataExtra.length == ctrlDepExtra){
              this.associados.sort((a,b) => {
                if(a.nome < b.nome){
                  return -1;
                }else{
                  return 1;
                }
              });
            }else{
              ctrlDepExtra++
            }
          });
        }
      })
    }
  })
}

  openModalNova(){
    this.planosFisicos = this.planosFisicos.filter((x:any) => { return x.empresaOrigem == "VIVA MAX"});
    this.planosEmpresariais = this.planosEmpresariais.filter((x:any) => { return x.empresaOrigem == "VIVA MAX"});
    this.planosServidores = this.planosServidores.filter((x:any) => { return x.empresaOrigem == "VIVA MAX"});
    this.empresaAssociado = "VIVA MAX";
    let modal = document.getElementById('modalNova');
    modal.style.display = "block"
  }

  openModalNovoPj(){
    this.planosFisicos = this.planosFisicos.filter((x:any) => { return x.empresaOrigem == "VIVA MAX"});
    this.planosEmpresariais = this.planosEmpresariais.filter((x:any) => { return x.empresaOrigem == "VIVA MAX"});
    this.planosServidores = this.planosServidores.filter((x:any) => { return x.empresaOrigem == "VIVA MAX"});
    this.empresaAssociado = "VIVA MAX";
    let modal = document.getElementById('modalNovoPj');
    modal.style.display = "block";
  }

  openModalNovoConecta(){
    this.planosFisicos = this.planosFisicos.filter((x:any) => { return x.empresaOrigem == "CONECTA"});
    this.planosEmpresariais = this.planosEmpresariais.filter((x:any) => { return x.empresaOrigem == "CONECTA"});
    this.planosServidores = this.planosServidores.filter((x:any) => { return x.empresaOrigem == "CONECTA"});
    this.empresaAssociado = "CONECTA";
    let modal = document.getElementById('telemodalNova');
    modal.style.display = "block";
  }

  openModalNovoSeguros(){ 
    this.planosFisicos = this.planosFisicos.filter((x:any) => { return x.empresaOrigem == "SEGUROS"});
    this.planosEmpresariais = this.planosEmpresariais.filter((x:any) => { return x.empresaOrigem == "SEGUROS"});
    this.planosServidores = this.planosServidores.filter((x:any) => { return x.empresaOrigem == "SEGUROS"});
    this.empresaAssociado = "SEGUROS";
    let modal = document.getElementById('modalNovaSeg');
    modal.style.display = "block";
  }

  openExtrato(){
    this.extratoAssociado = [];
    this.db.getContasCliente(this.nomeEditar,this.cpfEditar).subscribe((contas:any) => {
      if(contas.length > 0){
        this.extratoAssociado = contas;
      }else{
        this.db.getFinanceiro(this.subContratoEditar).subscribe((contaSubContrato:any) => {
          this.extratoAssociado = contaSubContrato;
        })
      }
      let modal = document.getElementById('modalExtrato');
      modal.style.display = "block";
    })
  }

  closeExtrato(){
    this.extratoAssociado = [];
    let modal = document.getElementById('modalExtrato');
    modal.style.display = "none";
  }

  limparDados(){
    this.empresaAssociado = "";
    this.origem = "";
    this.empresa = [];
    this.motivoPendencia = "";
    this.semAssinatura = false;
    this.semAutorizacao = false;
    this.pendente = false;
    this.observacaoPendente = "";
    this.matricula = null;
    this.subContrato = "";
    this.planoFisicoCheck = false;
    this.planoEmpresarialCheck = false;
    this.cpf = "";
    this.cpfCheck = true;
    this.matriculaCheck = null;
    this.estadoCivil = "";
    this.primeiroVencimento = "";
    this.nascimento = "";
    this.nome = "";
    this.telefone = "";
    this.celular = "";
    this.rua = "";
    this.numero = "";
    this.complemento = "";
    this.bairro = "";
    this.municipio = "";
    this.estado = "";
    this.unidadeConsumidora = "";
    this.cpfAnalise = false;
    this.email = "";
    this.localTrabalho = "";
    this.funcao = "";
    this.doenca = "";
    this.especificarDoenca = "";
    this.formaPagamento = "";
    this.mensalidade = 0;
    this.taxaAdesao= 0;
    this.diaVencimento = 0;
    this.mesFolhaPagamento = "";
    this.whatsapp = false;
    this.cep = "";
    this.sexo = "";
    this.listaDeDependentes.clear();
    this.listaDeDependentesExtra.clear();
    this.dataAdesao = "";
    this.plano = "";
    this.totalMensalidade = 0;
    this.nomeCartao = "";
    this.numeroCartao = "";
    this.validadeCartao = "";
    this.codigoCartao = "";
    this.idVendedor = "";
    this.vendedor = "";
    this.bloqueioPagamentos = false;
    this.funeralConjuge = "";
    this.funeralExtra = "";
    this.funeralFilhosMaior = "";
    this.funeralFilhosMenor = "";
    this.editaAdesao = "";
    this.editaMensalidade = "";
    this.valorCapitalSegurado = 0;
    this.valorFuneralConjuge = 0;
    this.valorFuneralExtra = 0;
    this.valorFuneralFilhosMaior = 0;
    this.valorFuneralFilhosMenor = 0;
    this.planosFisicos = [];
    this.planosServidores = [];
    this.planosFisicosEditar = [];
    this.planosServidoresEditar = [];
    this.planosEmpresariaisEditar = [];
    this.files = [];
    this.loginEconsig = "";
    this.senhaEconsig = "";
    let dF = <HTMLInputElement>document.getElementById('descontoFolha');
    dF.checked = false;
    let dR = <HTMLInputElement>document.getElementById('debitoRecorrente');
      dR.checked = false;
    let bM = <HTMLInputElement>document.getElementById('boletoMensal');
    bM.checked = false;
    let dC = <HTMLInputElement>document.getElementById('carne');
    dC.checked = false;
  }

  fecharModalNova(){
    this.limparDados();
    this.ngOnInit();
    let modal = document.getElementById('modalNova');
    modal.style.display = "none";
  }

  fecharModalNovoPj(){
    this.limparDados();
    this.ngOnInit();
    let modal = document.getElementById('modalNovoPj');
    modal.style.display = "none";
  }

  fecharModalNovoConecta(){
    this.limparDados();
    this.ngOnInit();
    let modal = document.getElementById('telemodalNova');
    modal.style.display = "none";
  }

  fecharModalNovoSeguros(){
    this.limparDados();
    this.ngOnInit();
    let modal = document.getElementById('modalNovaSeg');
    modal.style.display = "none";
  }

  openModalEditar(proposta, origem){
    this.empresaAssociado = proposta.empresa;
    this.matriculaEditar = proposta.matricula;
    this.matriculaServidorEditar = proposta.matriculaServidor;
    this.hibridoEditar = proposta.hibrido;
    this.nomeEditar = proposta.nome;
    let dFi = <HTMLInputElement>document.getElementById('descontoFolhaEditar');
    dFi.checked = false;
    let dRi = <HTMLInputElement>document.getElementById('debitoRecorrenteEditar');
    dRi.checked = false;
    let bMi = <HTMLInputElement>document.getElementById('boletoMensalEditar');
      bMi.checked = false;
    let dCi = <HTMLInputElement>document.getElementById('carneEditar');
      dCi.checked = false;
    if(origem == "sinistro"){
      this.todosDependentes = [];
      this.listaDeDependentesEditar.clear();
      this.listaDeDependentesExtraEditar.clear();
      if(proposta.dependentes && proposta.dependentes.length > 0){
        proposta.dependentes.forEach((item) => {
          if(item.sinistro){
            if(item.sinistro.numeroSinistro){
              item.sinistro.numeroSinistro =item.sinistro.numeroSinistr;
            }
            if(item.sinistro.valorSinistro){
              item.sinistro.valorSinistro =item.sinistro.valorSinistr;
            }
          }
        this.addDependenteEditar(item)
        })
      }
      if(proposta.dependentesExtra && proposta.dependentesExtra.length > 0){
        proposta.dependentesExtra.forEach((item) => {
          if(item.sinistro){
            if(item.sinistro.numeroSinistro){
              item.sinistro.numeroSinistro =item.sinistro.numeroSinistr;
            }
            if(item.sinistro.valorSinistro){
              item.sinistro.valorSinistro =item.sinistro.valorSinistr;
            }
          }
        this.addDependenteExtraEditar(item)
        })
      }
      let qtdeDependentes = this.listaDeDependentesEditar.value;
      qtdeDependentes.forEach((item) => {
        if(item.nome != null){
          this.todosDependentes.push(item)
        }
      })
      let qtdeDependentesExtras = this.listaDeDependentesExtraEditar.value;
      qtdeDependentesExtras.forEach((item) => {
        if(item.nome != null){
          this.todosDependentes.push(item)
        }
      });
    }else{
    this.todosDependentes = [];
    this.idAssociado = proposta._id;
    this.listaDeDependentesEditar.clear();
    this.listaDeDependentesExtraEditar.clear();
    this.backup = proposta;
    this.numSorte = proposta.numSorte;
    this.checkSolicitouCancelamento = proposta.solicitouCancelamento;
    if(this.checkSolicitouCancelamento == true){
      this.solicitadoCancelamento = true;
    }else{
      this.solicitadoCancelamento = false;
    }
    this.motivoSolicitacaoCancelamento = proposta.motivoSolicitacaoCancelamento;
    this.planoFisicoCheckEditar = proposta.planoFisicoCheck;
    this.planoEmpresarialCheckEditar = proposta.planoEmpresarialCheck;
    this.codigoEmpresa = proposta.codigoEmpresa;
    this.subContratoEditar = proposta.subContrato;
    this.cpfEditar = proposta.cpf;
    this.semAssinatura = proposta.semAssinatura;
    this.semAutorizacao = proposta.semAutorizacao;
    this.motivoPendenciaEditar = proposta.motivoPendencia;
    this.sexoEditar = proposta.sexo;
    this.primeiroVencimentoEditar = proposta.primeiroVencimento;
    this.situacaoEditar = proposta.situacao;
    this.situacaoOriginal = proposta.situacao;
    this.afastadoDesde = proposta.afastadoDesde;
    this.observacaoPendenteEditar = proposta.observacaoPendente;
    this.estadoCivilEditar = proposta.estadoCivil;
    this.nascimentoEditar = proposta.nascimento;
    this.telefoneEditar = proposta.telefone;
    this.celularEditar = proposta.celular;
    this.ruaEditar = proposta.rua;
    this.numeroEditar = proposta.numero;
    this.complementoEditar = proposta.complemento;
    this.motivoInadimplente = proposta.motivoInadimplente;
    this.bairroEditar = proposta.bairro;
    this.municipioEditar = proposta.municipio;
    this.estadoEditar = proposta.estado;
    this.emailEditar = proposta.email;
    this.localTrabalhoEditar = proposta.localTrabalho;
    this.funcaoEditar = proposta.funcao;
    this.doencaEditar = proposta.doenca;
    this.especificarDoencaEditar = proposta.especificarDoenca;
    this.formaPagamentoEditar = proposta.formaPagamento;
    this.mensalidadeEditar = proposta.mensalidade;
    this.taxaAdesaoEditar = proposta.taxaAdesao;
    this.diaVencimentoEditar = proposta.diaVencimento;
    this.whatsappEditar = proposta.whatsapp;
    this.cepEditar = proposta.cep;
    this.dataAdesaoEditar = proposta.dataAdesao;
    this.totalMensalidadeEditar = proposta.totalMensalidade;
    this.nomeCartaoEditar = proposta.nomeCartao;
    this.vendedorEditar = proposta.vendedor;
    this.idVendedorEditar = proposta.idVendedor;
    this.motivoCancelamento = proposta.motivoCancelamento;
    this.observacoesCancelamento = proposta.observacoesCancelamento;
    this.spcCancelamento = proposta.spcCancelamento;
    this.numeroCartaoOriginal = proposta.numeroCartao;
    this.codigoCartaoOriginal = proposta.codigoCartao;
    this.numeroCartaoEditar = proposta.numeroCartao ? "**** **** **** "+proposta.numeroCartao.slice(-4) : proposta.numeroCartao;
    this.codigoCartaoEditar = proposta.codigoCartao ? "***" : proposta.codigoCartao;
    this.validadeCartaoEditar = proposta.validadeCartao;
    this.idVendedorEditar = proposta.idVendedor;
    this.vendedorEditar = proposta.vendedor;
    this.cartaoSelecionadoEditar = proposta.cartaoSelecionado;
    this.mesFolhaPagamentoEditar = proposta.mesFolhaPagamento;
    this.senhaEditar =this.reverseReplaceStrings(proposta.senha);
    this.loginEconsigEditar = this.reverseReplaceStrings(proposta.loginEconsig);
    this.senhaEconsigEditar = this.reverseReplaceStrings(proposta.senhaEconsig);
    this.agendamentos = proposta.agendamentos.sort((a,b) => {
      if(a.dataAgendamento > b.dataAgendamento){
        return -1
      }
      if(a.dataAgendamento < b.dataAgendamento){
        return 1
      }
    });
    this.planoEditar = proposta.plano;
    let ctrl = 1;
    if(proposta.codigoEmpresa){
      this.db.getEmpresasByNome(proposta.localTrabalho).subscribe((dataPE:any) => {
        let planoEmpresa = dataPE[0];
        let capitalSeg = planoEmpresa?.coberturas?.length > 0 ? "SIM" : "NÃO";
        let pl = {
          "tipo" : "PLANO EMPRESARIAL",
          "nome" : planoEmpresa.plano,
          "mensalidade" : planoEmpresa.valorPlano,
          "adesao" : 0,
          "funeralConjuge" : planoEmpresa.plano.funeralConjuge,
          "funeralFilhosMenor" : planoEmpresa.plano.funeralFilhosMenor,
          "funeralFilhosMaior" : planoEmpresa.plano.funeralFilhosMaior,
          "funeralExtra" : planoEmpresa.plano.funeralExtra,
          "valorFuneralConjuge" : planoEmpresa.valorFuneralConjuge,
          "valorFuneralFilhosMenor" : planoEmpresa.valorFuneralFilhosMenor,
          "valorFuneralFilhosMaior" : planoEmpresa.valorFuneralFilhosMaior,
          "valorFuneralExtra" : planoEmpresa.valorFuneralExtra,
          "capitalSegurado" : capitalSeg,
          "valorCapitalSegurado" : planoEmpresa.coberturas[0]?.capital,
          "editaAdesao" : "NÃO",
          "editaMensalidade" : "NÃO",
          "editaTotal" : "NÃO",
          "contrato" : "",
          "comissao" : planoEmpresa.comissao,
          "empresaOrigem" : "VIVA MAX"
        }
        this.planosEmpresariaisEditar.push(pl);
    })
  }
  this.planosFisicosEditar = [];
  this.planosServidoresEditar = [];
  this.planosEmpresariaisEditar = [];
  if(this.empresaAssociado == "CONECTA"){
    this.db.getAllPlanosConecta().subscribe((p:any) => {
      p.forEach((item) => {
       if(item.tipo == 'PLANO FISICO'){
         this.planosFisicosEditar.push(item);
       }
       if(item.tipo == 'PLANO SERVIDOR'){
         this.planosServidoresEditar.push(item);
       }
       if(item.tipo == 'PLANO EMPRESARIAL'){
        this.planosEmpresariaisEditar.push(item);
      }
       if(ctrl == p.length){
        if(this.planoFisicoCheckEditar == true){
          this.selectPlanoEditar('fisico',this.planoEditar,'start');
        }else if(this.planoEmpresarialCheckEditar == true){
          this.selectPlanoEditar('empresarial',this.planoEditar,'start');
        }else{
          this.selectPlanoEditar('servidor',this.planoEditar,'start');
        }
       }else{
         ctrl++
       }
      })
    });
  }
  if(this.empresaAssociado == "SEGUROS"){
    this.db.getAllPlanosVivamaxSeguro().subscribe((p:any) => {
      p.forEach((item) => {
       if(item.tipo == 'PLANO FISICO'){
         this.planosFisicosEditar.push(item);
       }
       if(item.tipo == 'PLANO SERVIDOR'){
         this.planosServidoresEditar.push(item);
       }
       if(item.tipo == 'PLANO EMPRESARIAL'){
        this.planosEmpresariaisEditar.push(item);
      }
       if(ctrl == p.length){
        if(this.planoFisicoCheckEditar == true){
          this.selectPlanoEditar('fisico',this.planoEditar,'start');
        }else if(this.planoEmpresarialCheckEditar == true){
          this.selectPlanoEditar('empresarial',this.planoEditar,'start');
        }else{
          this.selectPlanoEditar('servidor',this.planoEditar,'start');
        }
       }else{
         ctrl++
       }
      })
    });
  }
  if(this.empresaAssociado == "VIVA MAX"){
    this.db.getAllPlanosVivamax().subscribe((p:any) => {
      p.forEach((item) => {
       if(item.tipo == 'PLANO FISICO'){
         this.planosFisicosEditar.push(item);
       }
       if(item.tipo == 'PLANO SERVIDOR'){
         this.planosServidoresEditar.push(item);
       }
       if(item.tipo == 'PLANO EMPRESARIAL'){
        this.planosEmpresariaisEditar.push(item);
      }
       if(ctrl == p.length){
        if(this.planoFisicoCheckEditar == true){
          this.selectPlanoEditar('fisico',this.planoEditar,'start');
        }else if(this.planoEmpresarialCheckEditar == true){
          this.selectPlanoEditar('empresarial',this.planoEditar,'start');
        }else{
          this.selectPlanoEditar('servidor',this.planoEditar,'start');
        }
       }else{
         ctrl++
       }
      })
    });
  }
    proposta.historicos.map((h:any) => {
      return this.reverseReplaceStrings(h.msg)
    })
    this.historicos = proposta.historicos.sort((a:any,b:any) => {
      if(a.data > b.data){return -1}
      if(a.data < b.data){return 1}
      if(a.hora > b.hora){return -1}
      if(a.hora < b.hora){return 1}
    });
    this.carteirinhas = [];
    let matriculaGeral = proposta.matricula;
    let emissaoGeral = proposta.dataAdesao;
    let planoGeral = proposta.plano;
    let vencimento = proposta.dataAdesao;
    let objTitular = {
      nome:proposta.nome,
      plano:planoGeral,
      matricula: matriculaGeral,
      tipo:"TITULAR",
      emissao:emissaoGeral,
      validade:moment(this.dataHoje).add(6,'month').toISOString()
    }
    this.carteirinhas.push(objTitular);
    if(proposta.dependentes.length > 0){
      proposta.dependentes.forEach((dep:any) => {
        let objDependente = {
          nome:dep.nome,
          plano:planoGeral,
          matricula: matriculaGeral,
          tipo:"DEPENDENTE",
          emissao:emissaoGeral,
          validade:moment(this.dataHoje).add(6,'month').toISOString()
        }
        this.carteirinhas.push(objDependente);
      })
    }
    if(proposta.dependentesExtra.length > 0){
      proposta.dependentesExtra.forEach((depExtra:any) => {
        let objDependenteExtra = {
          nome:depExtra.nome,
          plano:planoGeral,
          matricula: matriculaGeral,
          tipo:"DEPENDENTE",
          emissao:emissaoGeral,
          validade:moment(this.dataHoje).add(6,'month').toISOString()
        }
        this.carteirinhasExtras.push(objDependenteExtra);
      })
    }
    if(proposta.dependentes && proposta.dependentes.length > 0){
      this.dep = proposta.dependentes;
      proposta.dependentes.forEach((item:any) => {
        if(item.sinistro){
          if(item.sinistro.numeroSinistro){
            item.sinistro.numeroSinistro =item.sinistro.numeroSinistro;
          }
          if(item.sinistro.valorSinistro){
            item.sinistro.valorSinistro =item.sinistro.valorSinistro;
          }
        }
      this.addDependenteEditar(item)
      })
    }
    if(proposta.dependentesExtra && proposta.dependentesExtra.length > 0){
    this.depExtras = proposta.dependentesExtra;
      proposta.dependentesExtra.forEach((item:any) => {
        if(item.sinistro){
          if(item.sinistro.numeroSinistro){
            item.sinistro.numeroSinistro =item.sinistro.numeroSinistro;
          }
          if(item.sinistro.valorSinistro){
            item.sinistro.valorSinistro =item.sinistro.valorSinistro;
          }
        }
      this.addDependenteExtraEditar(item)
      })
    }
    let qtdeDependentes = this.listaDeDependentesEditar.value;
    qtdeDependentes.forEach((item) => {
      if(item.nome != null){
        this.todosDependentes.push(item)
      }
    })
    let qtdeDependentesExtras = this.listaDeDependentesExtraEditar.value;
    qtdeDependentesExtras.forEach((item) => {
      if(item.nome != null){
        this.todosDependentes.push(item)
      }
    });
       let modal = document.getElementById('modalEditar');
       modal.style.display = "block";
       switch(this.formaPagamentoEditar){
        case 'DESCONTO EM FOLHA DE PAGAMENTO' : {
          let dF:any = this.empresaAssociado == "CONECTA" ? <HTMLInputElement>document.getElementById('teledescontoFolhaEditar') : <HTMLInputElement>document.getElementById('descontoFolhaEditar');
          dF.checked = true;
          this.bloqueioPagamentos = true;
          break;
        };
        case 'DEBITO RECORRENTE' : {
          let dR:any = this.empresaAssociado == "CONECTA" ? <HTMLInputElement>document.getElementById('teledebitoRecorrenteEditar') : <HTMLInputElement>document.getElementById('debitoRecorrenteEditar');
          dR.checked = true;
          break;
        };
        case 'BOLETO' : {
          let bM:any = this.empresaAssociado == "CONECTA" ? <HTMLInputElement>document.getElementById('teleboletoMensalEditar') : <HTMLInputElement>document.getElementById('boletoMensalEditar');
          bM.checked = true;
          break;
        };
        case 'CARNÊ' : {
          let dC:any = this.empresaAssociado == "CONECTA" ? <HTMLInputElement>document.getElementById('telecarneEditar') : <HTMLInputElement>document.getElementById('carneEditar');
          dC.checked = true;
          break;
        };
        default:break;
      }   
      if(this.doencaEditar && this.empresaAssociado == "VIVA MAX"){
        let c:any;
        if(this.doencaEditar == "SIM"){
          c = <HTMLInputElement>document.getElementById('doencaSimEditar');
          c.checked = true
        }
        if(this.doencaEditar == "NÃO"){
            c = <HTMLInputElement>document.getElementById('doencaNaoEditar');
            c.checked = true
        }
      }  
    this.selectTab(0);
    this.docs = [];
    this.docs = proposta.documentos;
    if(this.docs && this.docs.length > 0){
      this.docs.forEach((item) => {
       let ext = item.url.split('.').pop();
        if(ext == 'pdf' || ext == 'PDF'){
          this.pdfs.push(item);
        }else{
          this.images.push(item);
        }
      })
    }
    this.docsCancelamento = [];
    this.docsCancelamento = proposta.documentosCancelamento;
    if(this.docsCancelamento && this.docsCancelamento.length > 0){
      this.docsCancelamento.forEach((item) => {
       let extensao = item.url.split('.').pop();
        if(extensao == 'pdf' || extensao == 'PDF'){
          this.pdfsCancelamento.push(item);
        }else{
          this.imagesCancelamento.push(item);
        }
      })
    }
  }
  let dataVl = new Date(this.dataAdesaoEditar);
  let mesVl = dataVl.getMonth();
  switch(mesVl){
    case 11:
    this.mesSeguinte = "JANEIRO";
    break;
    case 0:
    this.mesSeguinte = "FEVEREIRO";
    break;
    case 1:
    this.mesSeguinte = "MARÇO";
    break;
    case 2:
    this.mesSeguinte = "ABRIL";
    break;
    case 3:
    this.mesSeguinte = "MAIO";
    break;
    case 4:
    this.mesSeguinte = "JUNHO";
    break;
    case 5:
    this.mesSeguinte = "JULHO";
    break;
    case 6:
    this.mesSeguinte = "AGOSTO";
    break;
    case 7:
    this.mesSeguinte = "SETEMBRO";
    break;
    case 8:
    this.mesSeguinte = "OUTUBRO";
    break;
    case 9:
    this.mesSeguinte = "NOVEMBRO";
    break;
    case 10:
    this.mesSeguinte = "DEZEMBRO";
    break;
  }
  let mesFolha = this.mesFolhaPagamentoEditar ? this.mesFolhaPagamentoEditar.split('-')[1] : "";
  switch(mesFolha){
    case '01':
    this.mesFolhaPagamentoEditarPrint = "JANEIRO";
    break;
    case '02':
    this.mesFolhaPagamentoEditarPrint = "FEVEREIRO";
    break;
    case '03':
    this.mesFolhaPagamentoEditarPrint = "MARÇO";
    break;
    case '04':
    this.mesFolhaPagamentoEditarPrint = "ABRIL";
    break;
    case '05':
    this.mesFolhaPagamentoEditarPrint = "MAIO";
    break;
    case '06':
    this.mesFolhaPagamentoEditarPrint = "JUNHO";
    break;
    case '07':
    this.mesFolhaPagamentoEditarPrint = "JULHO";
    break;
    case '08':
    this.mesFolhaPagamentoEditarPrint = "AGOSTO";
    break;
    case '09':
    this.mesFolhaPagamentoEditarPrint = "SETEMBRO";
    break;
    case '10':
    this.mesFolhaPagamentoEditarPrint = "OUTUBRO";
    break;
    case '11':
    this.mesFolhaPagamentoEditarPrint = "NOVEMBRO";
    break;
    case '12':
    this.mesFolhaPagamentoEditarPrint = "DEZEMBRO";
    break;
  }
  if(this.subContratoEditar){
    this.db.getSubContratosByNome(this.subContratoEditar).subscribe((data:any) => {
      this.cnpjSubContrato = data[0].cnpj;
    })
  }
}

  closeModalEditar(){
    this.backup = [];
    this.associados = [];
    this.sinistrado = [];
    this.empresaAssociado = "";
    this.afastadoDesde = "";
    this.planoEditar = "";
    this.hibrido = false;
    this.motivoInadimplente = "";
    this.motivoPendenciaEditar = "";
    this.observacaoPendenteEditar = "";
    this.primeiroVencimentoEditar = "";
    this.loginEconsigEditar = "";
    this.senhaEconsigEditar = "";
    this.idAssociado = "";
    this.matriculaEditar = 0;
    this.matriculaServidorEditar = null;
    this.subContratoEditar = "";
    this.planoFisicoCheckEditar = false;
    this.planoEmpresarialCheckEditar = false;
    this.cpfEditar = "";
    this.estadoCivilEditar = "";
    this.nascimentoEditar = "";
    this.nomeEditar = "";
    this.telefoneEditar = "";
    this.celularEditar = "";
    this.ruaEditar = "";
    this.numeroEditar = "";
    this.complementoEditar = "";
    this.bairroEditar = "";
    this.sexoEditar = "";
    this.municipioEditar = "";
    this.estadoEditar = "";
    this.emailEditar = "";
    this.localTrabalhoEditar = "";
    this.funcaoEditar = "";
    this.doencaEditar = "";
    this.especificarDoencaEditar = "";
    this.mensalidadeEditar = 0;
    this.taxaAdesaoEditar = 0;
    this.diaVencimentoEditar = 0;
    this.mesFolhaPagamentoEditar = "";
    this.whatsappEditar = "";
    this.cepEditar = "";
    this.dataAdesaoEditar = "";
    this.planoEditar = "";
    this.totalMensalidadeEditar = 0;
    this.nomeCartaoEditar = "";
    this.numeroCartaoEditar = "";
    this.validadeCartaoEditar = "";
    this.numeroCartaoOriginal = "";
    this.codigoCartaoOriginal = "";
    this.codigoCartaoEditar = "";
    this.idVendedorEditar = "";
    this.vendedorEditar = "";
    this.codigoEmpresa = "";
    this.formaPagamentoEditar = "";
    this.cartaoSelecionadoEditar = "";
    this.listaDeDependentesEditar.clear();
    this.listaDeDependentesExtraEditar.clear();
    this.historicos = [];
    this.bloqueioPagamentos = false;
    this.funeralConjugeEditar = "";
    this.funeralExtraEditar = "";
    this.funeralFilhosMaiorEditar = "";
    this.funeralFilhosMenorEditar = "";
    this.editaAdesaoEditar = "";
    this.editaMensalidadeEditar = "";
    this.valorCapitalSeguradoEditar = null;
    this.valorFuneralConjugeEditar = null;
    this.valorFuneralExtraEditar = null;
    this.valorFuneralFilhosMaiorEditar = null;
    this.valorFuneralFilhosMenorEditar = null;
    this.valorConsultaMedicaEditar = null;
    this.formaPagamentoMedicoEditar = "";
    this.precisaTrocoMedicoEditar = "";
    this.trocoMedicoEditar = null;
    this.pdfs = [];
    this.images = [];
    this.pdfsCancelamento = [];
    this.imagesCancelamento = [];
    this.pdfsSinistro = [];
    this.imagesSinistro = [];
    this.docs = [];
    this.docsSinistro = [];
    this.docsCancelamento = [];
    this.checkSolicitouCancelamento = false;
    this.motivoCancelarSolicitacaoCancelamento = "";
    this.motivoSolicitacaoCancelamento = "";
    this.semAssinatura = false;
    this.semAutorizacao = false;
    this.senhaEditar = "";
    this.numSorte = null;
    this.carteirinhaSelecionada = null;
    this.mudancaDeConecta = false;
    this.mudancaParaConecta = false;
    this.closeSinistro();
    this.ngOnInit();
    let modal = document.getElementById('modalEditar');
    modal.style.display = "none"
  }

  cadastrarAssociado(){
    if(window.confirm("Deseja realmente cadastrar esse Associado?")){
      this.db.getConfigs().subscribe((x:any) => {
        let dependentes = this.dependentes.get('dependente').value;
        let dependentesExtras = this.dependentesExtra.get('dependenteExtra').value;
        this.db.getSorteLivre().subscribe((dataNum:any) => {
          let idNumSorte = dataNum[0]._id;
          if(this.plano.indexOf("PLUS") >= 0 || this.localTrabalho.indexOf("APCS") >= 0){
            if(dependentes.length > 0){
              for(let i = 0;dependentes.length < i;i++){
                if(dependentes[i].asf == "FUNERAL CONTRATADO" || dependentes[i].asf == "FUNERAL AUTOMÁTICO VITALÍCIO" || dependentes[i].asf == "FUNERAL AUTOMÁTICO ATÉ 18 ANOS"){
                  dependentes[i].numSorte = dataNum[i+3].numero;
                  this.db.patchSorte(dataNum[i+3]._id,{usado:true}).subscribe(resN => {
                    console.log(resN)
                  }, errN => {console.log(errN)})
                }
              }
            }
            if(dependentesExtras.length > 0){
                for(let iE = 0;dependentesExtras.length < iE;iE++){
                  if(dependentesExtras[iE].asf == "FUNERAL CONTRATADO" || dependentesExtras[iE].asf == "AUTOMÁTICO SEM CUSTO"){
                    dependentesExtras[iE].numSorte = dataNum[iE+13].numero;
                    this.db.patchSorte(dataNum[iE+3]._id,{usado:true}).subscribe(resN => {
                      console.log(resN)
                    }, errN => {console.log(errN)})
                  }
                }
            }
          }
        this.emails = x[23].emails;
        let matricula = x[10].matricula+7;
        let idMatricula = x[10]._id;
        this.diaVencimento = this.diaVencimento ? this.diaVencimento : this.primeiroVencimento;
        let idLinkCartao:any = "";
        let responsavelLinkCartao:any = "";
        let idCartaoVindi:any = "";
        let responsavelCartaoVindi:any = "";
        let idMargemConsignet:any = "";
        let responsavelMargemConsignet:any = "";
        let idBoletoVindi:any = "";
        let responsavelBoletoVindi:any = "";
        let idCarne:any = "";
        let responsavelCarne:any = "";
        let idxLinkCartao = this.emails.map((e:any) => {
          return JSON.stringify(Object.keys(e));
        }).indexOf('["linkCartao"]');
        if(idxLinkCartao >= 0){
          let dadosLinkCartao:any = Object.values(this.emails[idxLinkCartao])[0];
          idLinkCartao = dadosLinkCartao.idResponsavel;
          responsavelLinkCartao = dadosLinkCartao.nomeResponsavel;
        }
        let idxCartaoVindi = this.emails.map((e:any) => {
          return JSON.stringify(Object.keys(e));
        }).indexOf('["cartaoVindi"]');
        if(idxCartaoVindi >= 0){
          let dadosCartaoVindi:any = Object.values(this.emails[idxCartaoVindi])[0];
          idCartaoVindi = dadosCartaoVindi.idResponsavel;
          responsavelCartaoVindi = dadosCartaoVindi.nomeResponsavel;
        }
        let idxMargemConsignet = this.emails.map((e:any) => {
          return JSON.stringify(Object.keys(e));
        }).indexOf('["margemConsignet"]');
        if(idxMargemConsignet >= 0){
          let dadosMargemConsignet:any = Object.values(this.emails[idxMargemConsignet])[0];
          idMargemConsignet = dadosMargemConsignet.idResponsavel;
          responsavelMargemConsignet = dadosMargemConsignet.nomeResponsavel;
        }
        let idxBoletoVindi = this.emails.map((e:any) => {
          return JSON.stringify(Object.keys(e));
        }).indexOf('["boletoVindi"]');
        if(idxBoletoVindi >= 0){
          let dadosBoletoVindi:any = Object.values(this.emails[idxBoletoVindi])[0];
          idBoletoVindi = dadosBoletoVindi.idResponsavel;
          responsavelBoletoVindi = dadosBoletoVindi.nomeResponsavel;
        }
        let idxCarne = this.emails.map((e:any) => {
          return JSON.stringify(Object.keys(e));
        }).indexOf('["gerarCarne"]');
        if(idxCarne >= 0){
          let dadosCarne:any = Object.values(this.emails[idxCarne])[0];
          idCarne = dadosCarne.idResponsavel;
          responsavelCarne = dadosCarne.nomeResponsavel;
        }
        if(this.taxaAdesao == null){this.taxaAdesao = 0;}
        if(this.mensalidade == null){this.mensalidade = 0;}
        if(this.taxaAdesao == null){this.taxaAdesao = 0;}
        if(this.totalMensalidade == null){this.totalMensalidade = 0;}
        this.confirmarCartao = (!this.gerarLinkCartao && this.planoFisicoCheck) ? true : false;
        this.botao = false;
        let jsonDate = new Date().toJSON();
        let hora = new Date(jsonDate).toLocaleTimeString();
        let sit:any = "";
        let descr:any = "";
        let msg:any = "";
        let descriDocs = this.files.length > 0 ? ' - Com '+this.files.length+' documentos anexados' : '';
        if(this.pendente == true){
          sit = "PENDENTE";
          descr = "Novo Associado PENDENTE: "+this.nome+" | "+this.observacaoPendente;
          msg = "ASSOCIADO CADASTRADO COMO PENDENTE! | "+this.observacaoPendente;
        }else{
          sit = "ATIVO";
          descr = "Novo Associado: "+this.nome;
          msg = "ASSOCIADO CADASTRADO!";
        }
        let planoHibrido:any = this.plano.indexOf('SMART') >= 0 ? true : false;
        let dados = {
          matricula: matricula,
          numSorte:dataNum[0].numero,
          matriculaServidor:this.matriculaServidor,
          data: this.dataHoje,
          hora: hora,
          primeiroVencimento: this.primeiroVencimento,
          plano : this.plano,
          hibrido: planoHibrido,
          cpf : this.cpf,
          estadoCivil : this.estadoCivil,
          nascimento : this.nascimento.split('/').reverse().join('-'),
          nome : this.nome,
          sexo: this.sexo,
          telefone : this.telefone,
          celular : this.celular,
          cep: this.cep,
          rua : this.rua,
          numero : this.numero,
          complemento : this.complemento,
          bairro : this.bairro,
          municipio : this.municipio,
          estado : this.estado,
          email:this.email,
          localTrabalho :  this.localTrabalho,
          funcao : this.funcao,
          doenca : this.doenca,
          especificarDoenca :  this.especificarDoenca,
          formaPagamento :  this.formaPagamento,
          mensalidade :  this.mensalidade,
          taxaAdesao :  this.taxaAdesao,
          diaVencimento :  this.diaVencimento,
          mesFolhaPagamento :  this.mesFolhaPagamento,
          motivoPendencia : this.motivoPendencia,
          semAssinatura : false,
          senha:"",
          semAutorizacao : false,
          historicos:[{
            "data" : this.dataHoje, 
            "hora" : hora, 
            "motivo" : "CADASTRO", 
            "msg" : msg, 
            "usuario" : localStorage.getItem('usuarioVivamax')
           }
          ],
          agendamentos:[],
          dataAdesao:this.dataAdesao,
          whatsapp: this.whatsapp,
          dependentes:dependentes,
          dependentesExtra:dependentesExtras,
          nomeCartao : this.nomeCartao,
          numeroCartao : this.numeroCartao,
          validadeCartao : this.validadeCartao,
          codigoCartao : this.codigoCartao,
          idVendedor:this.idVendedor,
          vendedor:this.vendedor,
          totalMensalidade:this.totalMensalidade,
          subContrato : this.subContrato.nome,
          planoFisicoCheck: this.planoFisicoCheck,
          planoEmpresarialCheck:this.planoEmpresarialCheck,
          cartaoSelecionado: this.cartaoSelecionado,
          valorCapitalSegurado: this.valorCapitalSegurado,
          documentos:[],
          situacao:sit,
          observacaoPendente : this.observacaoPendente,
          checkSolicitouCancelamento:false,
          motivoSolicitacaoCancelamento:"",
          empresa:this.empresaAssociado,
          cpfAnalise:this.cpfAnalise,
          unidadeConsumidora:this.unidadeConsumidora,
          loginEconsig: this.loginEconsig,
          senhaEconsig: this.replaceStrings(this.senhaEconsig)
        }
        this.db.cadastroProposta(dados).subscribe(res => {
          console.log(res);
          if(this.files.length > 0){
            const imagedata = new FormData();
            for(let i=0; i<this.selectedFiles.length; i++){imagedata.append('files[]', this.selectedFiles[i], this.selectedFiles[i].name)};
             this.db.enviarDocAssociado( res['insertedId'], imagedata).subscribe((res) => {
                 console.log(res);
               },(error) => {
                 console.log(error);
              });
          }
          let body = {
            "menu" : "CADASTRAR ASSOCIADOS", 
            "descricao" : descr+descriDocs+" | Sistema App", 
            "data" : this.dataHoje, 
            "hora" : hora, 
            "usuario" : this.user
          }
          this.db.postLogs(body).subscribe(res => {
            console.log(res);
            if(this.cpfAnalise){
              alert("Cliente possui cancelamento por inadimplência - em análise");
              let randomGeraLink = Math.random().toString(36).slice(-8);
              let cpfAnalisar = {
                "id": randomGeraLink,
                "data" : this.dataHoje,
                "hora" : hora,
                "titulo" : "CADASTRO CPF INADIMPLENTE",
                "descricao" : "Associado: "+ dados.nome + " - Matricula: "+dados.matricula,
                "idResponsavel" : idBoletoVindi,
                "responsavel" : responsavelBoletoVindi,
                "resolvido":false,
                "dataResolucao" : "",
                "horaResolucao" : "",
                "prioridade" : "ALTA",
                "origem":"Sistema",
                "idUserSolicitacao":localStorage.getItem('idUserVivamax'),
                "nomeSolicitante":localStorage.getItem('usuarioVivamax')
              }
              this.db.postTarefa(cpfAnalisar).subscribe(res => console.log(res),err => console.log(err));
            }
            if(this.formaPagamento == "DESCONTO EM FOLHA DE PAGAMENTO"){
              if(this.subContrato?.checkConsignet){
                let randomConfCard = Math.random().toString(36).slice(-8);
                let confirmarCartaoAgenda = {
                  "id": randomConfCard,
                  "data" : this.dataHoje,
                  "hora" : hora,
                  "titulo" : "CONSULTAR MARGEM NO CONSIGNET",
                  "descricao" : "Associado: "+ dados.nome + " - Matricula: "+dados.matricula,
                  "idResponsavel" : idMargemConsignet,
                  "responsavel" : responsavelMargemConsignet,
                  "resolvido":false,
                  "dataResolucao" : "",
                  "horaResolucao" : "",
                  "prioridade" : "ALTA",
                  "origem":"Sistema",
                  "idUserSolicitacao":localStorage.getItem('idUserVivamax'),
                  "nomeSolicitante":localStorage.getItem('usuarioVivamax')
                 }
                this.db.postTarefa(confirmarCartaoAgenda).subscribe(res => console.log(res),err => console.log(err));
              }else{
                  let randomInd = Math.random().toString(36).slice(-8);
                  let bolGera = {
                    "id": randomInd,
                    "data" : this.dataHoje,
                    "hora" : hora,
                    "titulo" : "CONFERIR AUTORIZAÇÃO DE DESCONTO",
                    "descricao" : "Associado: "+ dados.nome + " - Matricula: "+dados.matricula,
                    "idResponsavel" : idMargemConsignet,
                    "responsavel" : responsavelMargemConsignet,
                    "resolvido":false,
                    "dataResolucao" : "",
                    "horaResolucao" : "",
                    "prioridade" : "ALTA",
                    "origem":"Sistema",
                    "idUserSolicitacao":localStorage.getItem('idUserVivamax'),
                    "nomeSolicitante":localStorage.getItem('usuarioVivamax')
                  }
                  this.db.postTarefa(bolGera).subscribe(res => console.log(res),err => console.log(err));
               }
            }else{
              if(this.confirmarCartao){
                let randomGeraLink = Math.random().toString(36).slice(-8);
                let linkCartaoAgenda = {
                  "id": randomGeraLink,
                  "data" : this.dataHoje,
                  "hora" : hora,
                  "titulo" : "GERAR LINK CARTÃO",
                  "descricao" : "Associado: "+ dados.nome + " - Matricula: "+dados.matricula,
                  "idResponsavel" : idLinkCartao,
                  "responsavel" : responsavelLinkCartao,
                  "resolvido":false,
                  "dataResolucao" : "",
                  "horaResolucao" : "",
                  "prioridade" : "ALTA",
                  "origem":"Sistema",
                  "idUserSolicitacao":localStorage.getItem('idUserVivamax'),
                  "nomeSolicitante":localStorage.getItem('usuarioVivamax')
                }
                this.db.postTarefa(linkCartaoAgenda).subscribe(res => console.log(res),err => console.log(err));
              }   
              if(this.formaPagamento == 'BOLETO'){
                  let randomInd = Math.random().toString(36).slice(-8);
                  let bolGera = {
                    "id": randomInd,
                    "data" : this.dataHoje,
                    "hora" : hora,
                    "titulo" : "CADASTRAR BOLETO NA VINDI",
                    "descricao" : "Associado: "+ dados.nome + " - Matricula: "+dados.matricula,
                    "idResponsavel" : idBoletoVindi,
                    "responsavel" : responsavelBoletoVindi,
                    "resolvido":false,
                    "dataResolucao" : "",
                    "horaResolucao" : "",
                    "prioridade" : "ALTA",
                    "origem":"Sistema",
                    "idUserSolicitacao":localStorage.getItem('idUserVivamax'),
                    "nomeSolicitante":localStorage.getItem('usuarioVivamax')
                  }
                  this.db.postTarefa(bolGera).subscribe(res => console.log(res),err => console.log(err));
              }
              if(this.formaPagamento == 'CARNÊ'){
                  let randomInd = Math.random().toString(36).slice(-8);
                  let bolGera = {
                    "id": randomInd,
                    "data" : this.dataHoje,
                    "hora" : hora,
                    "titulo" : "GERAR CARNÊ",
                    "descricao" : "Associado: "+ dados.nome + " - Matricula: "+dados.matricula,
                    "idResponsavel" : idCarne,
                    "responsavel" : responsavelCarne,
                    "resolvido":false,
                    "dataResolucao" : "",
                    "horaResolucao" : "",
                    "prioridade" : "ALTA",
                    "origem":"Sistema",
                    "idUserSolicitacao":localStorage.getItem('idUserVivamax'),
                    "nomeSolicitante":localStorage.getItem('usuarioVivamax')
                  }
                  this.db.postTarefa(bolGera).subscribe(res => console.log(res),err => console.log(err));
              }
              if(this.formaPagamento == 'DEBITO RECORRENTE' && !this.gerarLinkCartao){
                let rnd = Math.random().toString(36).slice(-8);
                let bolGera = {
                  "id": rnd,
                  "data" : this.dataHoje,
                  "hora" : hora,
                  "titulo" : "VERIFICAR CADASTRO E COBRANÇA NA VINDI: "+this.formaPagamento,
                  "descricao" : "Associado: "+ dados.nome + " - Matricula: "+dados.matricula,
                  "idResponsavel" : idBoletoVindi,
                  "responsavel" : responsavelBoletoVindi,
                  "resolvido":false,
                  "dataResolucao" : "",
                  "horaResolucao" : "",
                  "prioridade" : "ALTA",
                  "origem":"Sistema",
                  "idUserSolicitacao":localStorage.getItem('idUserVivamax'),
                  "nomeSolicitante":localStorage.getItem('usuarioVivamax')
                }
                this.db.postTarefa(bolGera).subscribe(res => {
                  console.log(res);
                }, err => {
                  console.log(err)
                })
              }
            }
            this.app.openAlert("Cadastro Realizado com Sucesso");
            this.botao = true;
            this.db.patchMatricula(idMatricula,{'matricula':matricula+1}).subscribe(res => {
              console.log(res);
              if(this.plano.indexOf("PLUS") >= 0 || this.localTrabalho.indexOf("APCS") >= 0){
                this.db.patchSorte(idNumSorte,{usado:true}).subscribe(resN => {
                  console.log(resN)
                }, errN => {console.log(errN)})
              }
            })
            this.fecharModalNova();
            this.fecharModalNovoPj();
            this.fecharModalNovoConecta();
            this.fecharModalNovoSeguros();
          }, err => {
            console.log(err)
            })
          }, err => {
            console.log(err);
            this.app.openAlert("Não foi possivel cadastrar o Associado, por favor tente novamente!");
            this.botao = true;
          })
        })
      })
    }
  }

  cadastrarAssociadoSmart(){
    if(window.confirm("Deseja realmente cadastrar esse Associado?")){
      this.qtdeConecta = 0;
      this.totalAsfDependentes = 0;
      this.totalMensalidade = 0;
      this.db.getTokenDoc().subscribe((res: any) => {
        let tokenDoc: any = res.token;
        if (!tokenDoc) {
          this.app.openAlert("Erro ao obter token Doc24, favor contatar o suporte!");
          return;
        }
          let observables = [];
          let { firstName, lastName } = this.app.splitName(this.nome);
          let cpfLimpo: any = this.app.limpaCPF(this.cpf);
          let body: any = {"afiliado":{
            "nombre": firstName,
            "apellido": lastName,
            "sexo": this.sexo,
            "email": this.email,
            "fecha_nacimiento":this.nascimentoEditar,
            "nro_documento": cpfLimpo
          }};
          observables.push(
            this.db.postDoc24(tokenDoc, body).pipe(
              catchError(err => {
                console.log(err);
                return of(null);
              }),
              map((res: any) => {
                console.log(res);
              })
            )
          )
          if (this.dependentes.value.dependente.length > 0) {
            for (let i: any = 0; i < this.dependentes.value.dependente.length; i++) {
              let item = this.dependentes.value.dependente[i];
              if (item.conecta) {
                this.qtdeConecta++;
                let { firstName, lastName } = this.app.splitName(item.nome);
                let cpfLimpo: any = this.app.limpaCPF(item.cpf);
                let dataNascimento:any = item.nascimento ? item.nascimento.split('/').reverse().join('-') : "";
                let body: any = {"afiliado":{
                  "nombre": firstName,
                  "apellido": lastName,
                  "sexo": item.sexo,
                  "email": item.email,
                  "fecha_nacimiento":dataNascimento,
                  "nro_documento": cpfLimpo
                }};
                observables.push(
                  this.db.postDoc24(tokenDoc, body).pipe(
                    catchError(err => {
                      console.log(err);
                      return of(null);
                    }),
                    map((res: any) => {
                      console.log(res);
                      if (item.asf == "FUNERAL CONTRATADO" && item.situacao == "ATIVO") {
                        this.totalAsfDependentes += this.valorFuneralFilhosMaior;
                      }
                    })
                  )
                );
              }
            }
          }
          if (this.dependentesExtra.value.dependenteExtra.length > 0) {
            for (let i: any = 0; i < this.dependentesExtra.value.dependenteExtra.length; i++) {
              let itemExtra: any = this.dependentesExtra.value.dependenteExtra[i];
              if (itemExtra.conecta) {
                this.qtdeConecta++;
                let { firstName, lastName } = this.app.splitName(itemExtra.nome);
                let cpfLimpo: any = this.app.limpaCPF(itemExtra.cpf);
                let dataNascimentoExtra:any = itemExtra.nascimento ? itemExtra.nascimento.split('/').reverse().join('-') : "";
                let body: any = {"afiliado":{
                  "nombre": firstName,
                  "apellido": lastName,
                  "sexo": itemExtra.sexo,
                  "email": itemExtra.email,
                  "fecha_nacimiento":dataNascimentoExtra,
                  "nro_documento": cpfLimpo
                }};
                observables.push(
                  this.db.postDoc24(tokenDoc, body).pipe(
                    catchError(err => {
                      console.log(err);
                      return of(null);
                    }),
                    map((res: any) => {
                      console.log(res);
                      if (this.funeralExtra == "SIM" && itemExtra.asf == "FUNERAL CONTRATADO" && itemExtra.situacao == "ATIVO") {
                        this.totalAsfDependentesExtras += this.valorFuneralExtra;
                      }
                    })
                  )
                );
              }
            }
          }
          if (observables.length > 0) {
            forkJoin(observables).subscribe(() => {
              this.totalValorConectaExtras = 0;
              if(this.qtdeConecta > this.conectaInclusos){
                let diferenca:number = this.qtdeConecta - this.conectaInclusos;
                this.totalValorConectaExtras = diferenca * this.valorConectaExtra;
              }
              this.totalMensalidade = this.totalAsfDependentes + this.totalAsfDependentesExtras + this.mensalidade + this.totalValorConectaExtras;
              this.db.getConfigs().subscribe((x:any) => {
                let dependentes = this.dependentes.get('dependente').value;
                let dependentesExtras = this.dependentesExtra.get('dependenteExtra').value;
                this.db.getSorteLivre().subscribe((dataNum:any) => {
                  let idNumSorte = dataNum[0]._id;
                  if(this.plano.indexOf("PLUS") >= 0 || this.localTrabalho.indexOf("APCS") >= 0){
                    if(dependentes.length > 0){
                      for(let i = 0;dependentes.length < i;i++){
                        if(dependentes[i].asf == "FUNERAL CONTRATADO" || dependentes[i].asf == "FUNERAL AUTOMÁTICO VITALÍCIO" || dependentes[i].asf == "FUNERAL AUTOMÁTICO ATÉ 18 ANOS"){
                          dependentes[i].numSorte = dataNum[i+3].numero;
                          this.db.patchSorte(dataNum[i+3]._id,{usado:true}).subscribe(resN => {
                            console.log(resN)
                          }, errN => {console.log(errN)})
                        }
                      }
                    }
                    if(dependentesExtras.length > 0){
                        for(let iE = 0;dependentesExtras.length < iE;iE++){
                          if(dependentesExtras[iE].asf == "FUNERAL CONTRATADO" || dependentesExtras[iE].asf == "AUTOMÁTICO SEM CUSTO"){
                            dependentesExtras[iE].numSorte = dataNum[iE+13].numero;
                            this.db.patchSorte(dataNum[iE+3]._id,{usado:true}).subscribe(resN => {
                              console.log(resN)
                            }, errN => {console.log(errN)})
                          }
                        }
                    }
                  }
                this.emails = x[23].emails;
                let matricula = x[10].matricula+7;
                let idMatricula = x[10]._id;
                this.diaVencimento = this.diaVencimento ? this.diaVencimento : this.primeiroVencimento;
                let idLinkCartao:any = "";
                let responsavelLinkCartao:any = "";
                let idCartaoVindi:any = "";
                let responsavelCartaoVindi:any = "";
                let idMargemConsignet:any = "";
                let responsavelMargemConsignet:any = "";
                let idBoletoVindi:any = "";
                let responsavelBoletoVindi:any = "";
                let idCarne:any = "";
                let responsavelCarne:any = "";
                let idxLinkCartao = this.emails.map((e:any) => {
                  return JSON.stringify(Object.keys(e));
                }).indexOf('["linkCartao"]');
                if(idxLinkCartao >= 0){
                  let dadosLinkCartao:any = Object.values(this.emails[idxLinkCartao])[0];
                  idLinkCartao = dadosLinkCartao.idResponsavel;
                  responsavelLinkCartao = dadosLinkCartao.nomeResponsavel;
                }
                let idxCartaoVindi = this.emails.map((e:any) => {
                  return JSON.stringify(Object.keys(e));
                }).indexOf('["cartaoVindi"]');
                if(idxCartaoVindi >= 0){
                  let dadosCartaoVindi:any = Object.values(this.emails[idxCartaoVindi])[0];
                  idCartaoVindi = dadosCartaoVindi.idResponsavel;
                  responsavelCartaoVindi = dadosCartaoVindi.nomeResponsavel;
                }
                let idxMargemConsignet = this.emails.map((e:any) => {
                  return JSON.stringify(Object.keys(e));
                }).indexOf('["margemConsignet"]');
                if(idxMargemConsignet >= 0){
                  let dadosMargemConsignet:any = Object.values(this.emails[idxMargemConsignet])[0];
                  idMargemConsignet = dadosMargemConsignet.idResponsavel;
                  responsavelMargemConsignet = dadosMargemConsignet.nomeResponsavel;
                }
                let idxBoletoVindi = this.emails.map((e:any) => {
                  return JSON.stringify(Object.keys(e));
                }).indexOf('["boletoVindi"]');
                if(idxBoletoVindi >= 0){
                  let dadosBoletoVindi:any = Object.values(this.emails[idxBoletoVindi])[0];
                  idBoletoVindi = dadosBoletoVindi.idResponsavel;
                  responsavelBoletoVindi = dadosBoletoVindi.nomeResponsavel;
                }
                let idxCarne = this.emails.map((e:any) => {
                  return JSON.stringify(Object.keys(e));
                }).indexOf('["gerarCarne"]');
                if(idxCarne >= 0){
                  let dadosCarne:any = Object.values(this.emails[idxCarne])[0];
                  idCarne = dadosCarne.idResponsavel;
                  responsavelCarne = dadosCarne.nomeResponsavel;
                }
                if(this.taxaAdesao == null){this.taxaAdesao = 0;}
                if(this.mensalidade == null){this.mensalidade = 0;}
                if(this.taxaAdesao == null){this.taxaAdesao = 0;}
                if(this.totalMensalidade == null){this.totalMensalidade = 0;}
                this.confirmarCartao = (!this.gerarLinkCartao && this.planoFisicoCheck) ? true : false;
                this.botao = false;
                let jsonDate = new Date().toJSON();
                let hora = new Date(jsonDate).toLocaleTimeString();
                let sit:any = "";
                let descr:any = "";
                let msg:any = "";
                let descriDocs = this.files.length > 0 ? ' - Com '+this.files.length+' documentos anexados' : '';
                if(this.pendente == true){
                  sit = "PENDENTE";
                  descr = "Novo Associado PENDENTE: "+this.nome+" | "+this.observacaoPendente;
                  msg = "ASSOCIADO CADASTRADO COMO PENDENTE! | "+this.observacaoPendente;
                }else{
                  sit = "ATIVO";
                  descr = "Novo Associado: "+this.nome;
                  msg = "ASSOCIADO CADASTRADO!";
                }
                let planoHibrido:any = this.plano.indexOf('SMART') >= 0 ? true : false;
                let dados = {
                  matricula: matricula,
                  numSorte:dataNum[0].numero,
                  matriculaServidor:this.matriculaServidor,
                  data: this.dataHoje,
                  hora: hora,
                  primeiroVencimento: this.primeiroVencimento,
                  plano : this.plano,
                  hibrido: planoHibrido,
                  cpf : this.cpf,
                  estadoCivil : this.estadoCivil,
                  nascimento : this.nascimento.split('/').reverse().join('-'),
                  nome : this.nome,
                  sexo: this.sexo,
                  telefone : this.telefone,
                  celular : this.celular,
                  cep: this.cep,
                  rua : this.rua,
                  numero : this.numero,
                  complemento : this.complemento,
                  bairro : this.bairro,
                  municipio : this.municipio,
                  estado : this.estado,
                  email:this.email,
                  localTrabalho :  this.localTrabalho,
                  funcao : this.funcao,
                  doenca : this.doenca,
                  especificarDoenca :  this.especificarDoenca,
                  formaPagamento :  this.formaPagamento,
                  mensalidade :  this.mensalidade,
                  taxaAdesao :  this.taxaAdesao,
                  diaVencimento :  this.diaVencimento,
                  mesFolhaPagamento :  this.mesFolhaPagamento,
                  motivoPendencia : this.motivoPendencia,
                  semAssinatura : false,
                  senha:"",
                  semAutorizacao : false,
                  historicos:[{
                    "data" : this.dataHoje, 
                    "hora" : hora, 
                    "motivo" : "CADASTRO", 
                    "msg" : msg, 
                    "usuario" : localStorage.getItem('usuarioVivamax')
                  }
                  ],
                  agendamentos:[],
                  dataAdesao:this.dataAdesao,
                  whatsapp: this.whatsapp,
                  dependentes:dependentes,
                  dependentesExtra:dependentesExtras,
                  nomeCartao : this.nomeCartao,
                  numeroCartao : this.numeroCartao,
                  validadeCartao : this.validadeCartao,
                  codigoCartao : this.codigoCartao,
                  idVendedor:this.idVendedor,
                  vendedor:this.vendedor,
                  totalMensalidade:this.totalMensalidade,
                  subContrato : this.subContrato.nome,
                  planoFisicoCheck: this.planoFisicoCheck,
                  planoEmpresarialCheck:this.planoEmpresarialCheck,
                  cartaoSelecionado: this.cartaoSelecionado,
                  valorCapitalSegurado: this.valorCapitalSegurado,
                  documentos:[],
                  situacao:sit,
                  observacaoPendente : this.observacaoPendente,
                  checkSolicitouCancelamento:false,
                  motivoSolicitacaoCancelamento:"",
                  empresa:this.empresaAssociado,
                  cpfAnalise:this.cpfAnalise,
                  unidadeConsumidora:this.unidadeConsumidora,
                  loginEconsig: this.loginEconsig,
                  senhaEconsig: this.replaceStrings(this.senhaEconsig)
                }
                this.db.cadastroProposta(dados).subscribe(res => {
                  console.log(res);
                  this.idAssociado = res['insertedId'];
                  if(this.files.length > 0){
                    const imagedata = new FormData();
                    for(let i=0; i<this.selectedFiles.length; i++){imagedata.append('files[]', this.selectedFiles[i], this.selectedFiles[i].name)};
                    this.db.enviarDocAssociado( res['insertedId'], imagedata).subscribe((res) => {
                        console.log(res);
                      },(error) => {
                        console.log(error);
                      });
                  }
                  let body = {
                    "menu" : "CADASTRAR ASSOCIADOS", 
                    "descricao" : descr+descriDocs+" | Sistema App", 
                    "data" : this.dataHoje, 
                    "hora" : hora, 
                    "usuario" : this.user
                  }
                  this.db.postLogs(body).subscribe(res => {
                    console.log(res);
                    if(this.cpfAnalise){
                      alert("Cliente possui cancelamento por inadimplência - em análise");
                      let randomGeraLink = Math.random().toString(36).slice(-8);
                      let cpfAnalisar = {
                        "id": randomGeraLink,
                        "data" : this.dataHoje,
                        "hora" : hora,
                        "titulo" : "CADASTRO CPF INADIMPLENTE",
                        "descricao" : "Associado: "+ dados.nome + " - Matricula: "+dados.matricula,
                        "idResponsavel" : idBoletoVindi,
                        "responsavel" : responsavelBoletoVindi,
                        "resolvido":false,
                        "dataResolucao" : "",
                        "horaResolucao" : "",
                        "prioridade" : "ALTA",
                        "origem":"Sistema",
                        "idUserSolicitacao":localStorage.getItem('idUserVivamax'),
                        "nomeSolicitante":localStorage.getItem('usuarioVivamax')
                      }
                      this.db.postTarefa(cpfAnalisar).subscribe(res => console.log(res),err => console.log(err));
                    }
                    if(this.formaPagamento == "DESCONTO EM FOLHA DE PAGAMENTO"){
                      if(this.subContrato?.checkConsignet){
                        let randomConfCard = Math.random().toString(36).slice(-8);
                        let confirmarCartaoAgenda = {
                          "id": randomConfCard,
                          "data" : this.dataHoje,
                          "hora" : hora,
                          "titulo" : "CONSULTAR MARGEM NO CONSIGNET",
                          "descricao" : "Associado: "+ dados.nome + " - Matricula: "+dados.matricula,
                          "idResponsavel" : idMargemConsignet,
                          "responsavel" : responsavelMargemConsignet,
                          "resolvido":false,
                          "dataResolucao" : "",
                          "horaResolucao" : "",
                          "prioridade" : "ALTA",
                          "origem":"Sistema",
                          "idUserSolicitacao":localStorage.getItem('idUserVivamax'),
                          "nomeSolicitante":localStorage.getItem('usuarioVivamax')
                        }
                        this.db.postTarefa(confirmarCartaoAgenda).subscribe(res => console.log(res),err => console.log(err));
                      }else{
                          let randomInd = Math.random().toString(36).slice(-8);
                          let bolGera = {
                            "id": randomInd,
                            "data" : this.dataHoje,
                            "hora" : hora,
                            "titulo" : "CONFERIR AUTORIZAÇÃO DE DESCONTO",
                            "descricao" : "Associado: "+ dados.nome + " - Matricula: "+dados.matricula,
                            "idResponsavel" : idMargemConsignet,
                            "responsavel" : responsavelMargemConsignet,
                            "resolvido":false,
                            "dataResolucao" : "",
                            "horaResolucao" : "",
                            "prioridade" : "ALTA",
                            "origem":"Sistema",
                            "idUserSolicitacao":localStorage.getItem('idUserVivamax'),
                            "nomeSolicitante":localStorage.getItem('usuarioVivamax')
                          }
                          this.db.postTarefa(bolGera).subscribe(res => console.log(res),err => console.log(err));
                      }
                    }else{
                      if(this.confirmarCartao){
                        let randomGeraLink = Math.random().toString(36).slice(-8);
                        let linkCartaoAgenda = {
                          "id": randomGeraLink,
                          "data" : this.dataHoje,
                          "hora" : hora,
                          "titulo" : "GERAR LINK CARTÃO",
                          "descricao" : "Associado: "+ dados.nome + " - Matricula: "+dados.matricula,
                          "idResponsavel" : idLinkCartao,
                          "responsavel" : responsavelLinkCartao,
                          "resolvido":false,
                          "dataResolucao" : "",
                          "horaResolucao" : "",
                          "prioridade" : "ALTA",
                          "origem":"Sistema",
                          "idUserSolicitacao":localStorage.getItem('idUserVivamax'),
                          "nomeSolicitante":localStorage.getItem('usuarioVivamax')
                        }
                        this.db.postTarefa(linkCartaoAgenda).subscribe(res => console.log(res),err => console.log(err));
                      }   
                      if(this.formaPagamento == 'BOLETO'){
                          let randomInd = Math.random().toString(36).slice(-8);
                          let bolGera = {
                            "id": randomInd,
                            "data" : this.dataHoje,
                            "hora" : hora,
                            "titulo" : "CADASTRAR BOLETO NA VINDI",
                            "descricao" : "Associado: "+ dados.nome + " - Matricula: "+dados.matricula,
                            "idResponsavel" : idBoletoVindi,
                            "responsavel" : responsavelBoletoVindi,
                            "resolvido":false,
                            "dataResolucao" : "",
                            "horaResolucao" : "",
                            "prioridade" : "ALTA",
                            "origem":"Sistema",
                            "idUserSolicitacao":localStorage.getItem('idUserVivamax'),
                            "nomeSolicitante":localStorage.getItem('usuarioVivamax')
                          }
                          this.db.postTarefa(bolGera).subscribe(res => console.log(res),err => console.log(err));
                      }
                      if(this.formaPagamento == 'CARNÊ'){
                          let randomInd = Math.random().toString(36).slice(-8);
                          let bolGera = {
                            "id": randomInd,
                            "data" : this.dataHoje,
                            "hora" : hora,
                            "titulo" : "GERAR CARNÊ",
                            "descricao" : "Associado: "+ dados.nome + " - Matricula: "+dados.matricula,
                            "idResponsavel" : idCarne,
                            "responsavel" : responsavelCarne,
                            "resolvido":false,
                            "dataResolucao" : "",
                            "horaResolucao" : "",
                            "prioridade" : "ALTA",
                            "origem":"Sistema",
                            "idUserSolicitacao":localStorage.getItem('idUserVivamax'),
                            "nomeSolicitante":localStorage.getItem('usuarioVivamax')
                          }
                          this.db.postTarefa(bolGera).subscribe(res => console.log(res),err => console.log(err));
                      }
                      if(this.formaPagamento == 'DEBITO RECORRENTE' && !this.gerarLinkCartao){
                        let rnd = Math.random().toString(36).slice(-8);
                        let bolGera = {
                          "id": rnd,
                          "data" : this.dataHoje,
                          "hora" : hora,
                          "titulo" : "VERIFICAR CADASTRO E COBRANÇA NA VINDI: "+this.formaPagamento,
                          "descricao" : "Associado: "+ dados.nome + " - Matricula: "+dados.matricula,
                          "idResponsavel" : idBoletoVindi,
                          "responsavel" : responsavelBoletoVindi,
                          "resolvido":false,
                          "dataResolucao" : "",
                          "horaResolucao" : "",
                          "prioridade" : "ALTA",
                          "origem":"Sistema",
                          "idUserSolicitacao":localStorage.getItem('idUserVivamax'),
                          "nomeSolicitante":localStorage.getItem('usuarioVivamax')
                        }
                        this.db.postTarefa(bolGera).subscribe(res => {
                          console.log(res);
                        }, err => {
                          console.log(err)
                        })
                      }
                    }
                    this.app.openAlert("Cadastro Realizado com Sucesso");
                    this.botao = true;
                    this.db.patchMatricula(idMatricula,{'matricula':matricula+1}).subscribe(res => {
                      console.log(res);
                      if(this.plano.indexOf("PLUS") >= 0 || this.localTrabalho.indexOf("APCS") >= 0){
                        this.db.patchSorte(idNumSorte,{usado:true}).subscribe(resN => {
                          console.log(resN)
                        }, errN => {console.log(errN)})
                      }
                    })
                    this.fecharModalNova();
                    this.fecharModalNovoPj();
                    this.fecharModalNovoConecta();
                    this.fecharModalNovoSeguros();
                  }, err => {
                    console.log(err)
                    })
                  }, err => {
                    console.log(err);
                    this.app.openAlert("Não foi possivel cadastrar o Associado, por favor tente novamente!");
                    this.botao = true;
                  })
                })
              })
            });
          }
     })
    }
  }
 
cpfUnico(cpf:any,emp:any){
  this.cpfCheck = true;
  this.cpfAnalise = false;
  let cp = cpf.replace(/\D/g,"");
  let empresa:any = emp == "seguros" ? "SEGUROS" : emp == "conecta" ? "CONECTA" : "VIVA MAX";
  if(cp.length == 11){
      this.db.getAssociadoByCpfEmpresa('"nome":1,"matricula":1,"cpf":1,"empresa":1',cpf,empresa).subscribe((result:any) => {
      if(result && result.length > 0){
        this.cpfCheck = false;
      }else{
        this.db.getAssociadoByCpfEmpresa('"nome":1,"matricula":1,"dependentes.cpf":1,"empresa":1',cpf,empresa).subscribe((resDep:any) => {
          if(resDep && resDep.length > 0){
            this.cpfCheck = false;
          }else{
            this.db.getAssociadoByCpfEmpresa('"nome":1,"matricula":1,"dependentesExtra.cpf":1,"empresa":1',cpf,empresa).subscribe((resDepExtra:any) => {
              if(resDepExtra && resDepExtra.length > 0){
                this.cpfCheck = false;
              }else{
              this.cpfCheck = true;
              this.db.getCanceladoInadimplenteCpf(cpf).subscribe((data:any) => {
                if(data && data.length > 0){
                  this.cpfAnalise = true;
                }
              })
              }
            })
            }
          })
        }
      })
  }
}
  
  matriculaUnica(matricula){
    this.matriculaCheck = true;
     this.db.getAssociadosVivamaxMatricula(matricula).subscribe((data:any) => {
        if(data.length > 0){
          this.matriculaCheck = false;
          this.app.openAlert("ESTA MATRICULA JÁ ESTA CADASTRADA!");
        }else{
          this.matriculaCheck = true;
        }
      })
  }
    
cpfUnicoEditar(cpf:any){
  this.cpfCheckEditar = true;
  let cp = cpf.replace(/\D/g,"");
  if(cp.length == 11){
      this.db.getAssociadoByCpfEmpresa('"nome":1,"matricula":1,"cpf":1,"empresa":1',cpf,this.empresaAssociado).subscribe((result:any) => {
      if(result && result.length > 0){
        this.cpfCheckEditar = false;
      }else{
        this.db.getAssociadoByCpfEmpresa('"nome":1,"matricula":1,"dependentes.cpf":1,"empresa":1',cpf,this.empresaAssociado).subscribe((resDep:any) => {
          if(resDep && resDep.length > 0){
            this.cpfCheckEditar = false;
          }else{
            this.db.getAssociadoByCpfEmpresa('"nome":1,"matricula":1,"dependentesExtra.cpf":1,"empresa":1',cpf,this.empresaAssociado).subscribe((resDepExtra:any) => {
              if(resDepExtra && resDepExtra.length > 0){
                this.cpfCheckEditar = false;
              }else{
              this.cpfCheckEditar = true;    
              this.salvarTemp('cpf',cpf)  
              }
            })
            }
          })
        }
      })
  }
}
  
  matriculaUnicaEditar(matricula){
      this.db.getAssociadosVivamaxMatricula(matricula).subscribe((data:any) => {
        if(data.length > 0){
          this.matriculaCheckEditar = false;
          this.app.openAlert("ESTA MATRICULA JÁ ESTA CADASTRADA!");
        }else{
          this.matriculaCheckEditar = true;
          this.salvarTemp('matricula',matricula)
        }
      })
  }
 
tipoDependente(i:any){
  if(this.empresaAssociado == "VIVA MAX" || this.hibrido){
      let tipoInput = (<HTMLInputElement>document.getElementById('tipo'+i)).value;
      let asfInput = (<HTMLInputElement>document.getElementById('asf'+i));
      if(tipoInput == "CÔNJUGE" && this.funeralConjuge == "AUTOMATICO"){
          asfInput.value = "FUNERAL AUTOMÁTICO VITALÍCIO";
          this.dependentes.get('dependente.'+i+'.asf').patchValue('FUNERAL AUTOMÁTICO VITALÍCIO');
      }else{
        asfInput.value = "";
        this.dependentes.get('dependente.'+i+'.asf').patchValue('')
      }
      if((tipoInput == "FILHO" || tipoInput == "FILHA") && this.funeralFilhosMenor == "AUTOMATICO"){
        asfInput.value = "FUNERAL AUTOMÁTICO ATÉ 18 ANOS";
        this.dependentes.get('dependente.'+i+'.asf').patchValue('FUNERAL AUTOMÁTICO ATÉ 18 ANOS');
      }
    }
  }

  tipoDependenteExtra(i:any){
    if(this.empresaAssociado == "VIVA MAX" || this.hibrido){
      let conta = 100+i;
      let asfInput = (<HTMLInputElement>document.getElementById('asf'+conta.toString()));
      if(this.funeralExtra == "AUTOMATICO"){
        asfInput.value = "AUTOMÁTICO SEM CUSTO";
        this.dependentesExtra.get('dependenteExtra.'+i+'.asf').patchValue('AUTOMÁTICO SEM CUSTO')
      }
    }
  }

tipoDependenteSmart(i:any){
  if(this.hibrido){
      let tipoInput = (<HTMLInputElement>document.getElementById('tipoSmart'+i)).value;
      let asfInput = (<HTMLInputElement>document.getElementById('asfSmart'+i));
      if(tipoInput == "CÔNJUGE" && this.funeralConjuge == "AUTOMATICO"){
          asfInput.value = "FUNERAL AUTOMÁTICO VITALÍCIO";
          this.dependentes.get('dependente.'+i+'.asf').patchValue('FUNERAL AUTOMÁTICO VITALÍCIO');
      }else{
        asfInput.value = "";
        this.dependentes.get('dependente.'+i+'.asf').patchValue('')
      }
      if((tipoInput == "FILHO" || tipoInput == "FILHA") && this.funeralFilhosMenor == "AUTOMATICO"){
        asfInput.value = "FUNERAL AUTOMÁTICO ATÉ 18 ANOS";
        this.dependentes.get('dependente.'+i+'.asf').patchValue('FUNERAL AUTOMÁTICO ATÉ 18 ANOS');
      }
    }
  }

  tipoDependenteExtraSmart(i:any){
    if(this.hibrido){
      let conta = 100+i;
      let asfInput = (<HTMLInputElement>document.getElementById('asfSmartExtra'+conta.toString()));
      if(this.funeralExtra == "AUTOMATICO"){
        asfInput.value = "AUTOMÁTICO SEM CUSTO";
        this.dependentesExtra.get('dependenteExtra.'+i+'.asf').patchValue('AUTOMÁTICO SEM CUSTO')
      }
    }
  }

  tipoDependenteEditar(i:any){
    if(this.empresaAssociado == "VIVA MAX" || this.hibrido){
      let tipoInput = (<HTMLInputElement>document.getElementById('tipoEditar'+i)).value;
      let asfInput = (<HTMLInputElement>document.getElementById('asfEditar'+i));
      if(tipoInput == "CÔNJUGE" && this.funeralConjugeEditar == "AUTOMATICO"){
        asfInput.value = "FUNERAL AUTOMÁTICO VITALÍCIO";
        this.dependentesEditar.get('dependenteEditar.'+i+'.asf').patchValue('FUNERAL AUTOMÁTICO VITALÍCIO')
      }else{
        this.dependentesEditar.get('dependenteEditar.'+i+'.asf').patchValue('')
        asfInput.value = "";
      }
      if((tipoInput == "FILHO" || tipoInput == "FILHA") && this.funeralFilhosMenorEditar == "AUTOMATICO"){
        asfInput.value = "FUNERAL AUTOMÁTICO ATÉ 18 ANOS";
        this.dependentesEditar.get('dependenteEditar.'+i+'.asf').patchValue('FUNERAL AUTOMÁTICO ATÉ 18 ANOS')
      }
    }
  }

  tipoDependenteExtraEditar(i:any){
    if(this.empresaAssociado == "VIVA MAX" || this.hibrido){
      let conta = 100+i;
      let asfInput = (<HTMLInputElement>document.getElementById('asfEditar'+conta.toString()));
      if(this.funeralExtraEditar == "AUTOMATICO"){
        asfInput.value = "AUTOMÁTICO SEM CUSTO";
        this.dependentesExtraEditar.get('dependenteExtraEditar.'+i+'.asf').patchValue('AUTOMÁTICO SEM CUSTO')
      }
    }
  }

  tipoDependentePj(i:any){
    let tipoInput = (<HTMLInputElement>document.getElementById('tipoPj'+i)).value;
    let asfInput = (<HTMLInputElement>document.getElementById('asfPj'+i));
    if(tipoInput == "CÔNJUGE" && this.funeralConjuge == "AUTOMATICO"){
        asfInput.value = "FUNERAL AUTOMÁTICO VITALÍCIO";
        this.dependentes.get('dependente.'+i+'.asf').patchValue('FUNERAL AUTOMÁTICO VITALÍCIO')
    }else{
      asfInput.value = "";
      this.dependentes.get('dependente.'+i+'.asf').patchValue('')
    }
    if((tipoInput == "FILHO" || tipoInput == "FILHA") && this.funeralFilhosMenor == "AUTOMATICO"){
      asfInput.value = "FUNERAL AUTOMÁTICO ATÉ 18 ANOS";
      this.dependentes.get('dependente.'+i+'.asf').patchValue('FUNERAL AUTOMÁTICO ATÉ 18 ANOS')
   }
  }

  tipoDependenteExtraPj(i:any){
    let conta = 100+i;
    let asfInput = (<HTMLInputElement>document.getElementById('asfPj'+conta.toString()));
    if(this.funeralExtra == "AUTOMATICO"){
      asfInput.value = "AUTOMÁTICO SEM CUSTO";
      this.dependentesExtra.get('dependenteExtra.'+i+'.asf').patchValue('AUTOMÁTICO SEM CUSTO')
    }
  }

  verificaNascimento(i:any){
    let tipoInput = (<HTMLInputElement>document.getElementById('tipo'+i)).value;
    let asfInput = (<HTMLInputElement>document.getElementById('asf'+i));
    let nascimentoInput = (<HTMLInputElement>document.getElementById('nascimento'+i));
    if(tipoInput == "FILHO" || tipoInput == 'FILHA'){
      if(!this.verificaIdade(nascimentoInput.value)){
         this.filhoMenorIdadeCheck = true;
         if(this.funeralFilhosMenor == "AUTOMATICO"){
           asfInput.value = "FUNERAL AUTOMÁTICO ATÉ 18 ANOS";
           this.dependentes.get('dependente.'+i+'.asf').patchValue('FUNERAL AUTOMÁTICO ATÉ 18 ANOS');
         }
     }else{
      this.filhoMenorIdadeCheck = false;
      asfInput.value = "NÃO HÁ FUNERAL CONTRATADO";
      this.dependentes.get('dependente.'+i+'.asf').patchValue('NÃO HÁ FUNERAL CONTRATADO');
     }      
   }
}

verificaNascimentoPj(i:any){
  let tipoInput = (<HTMLInputElement>document.getElementById('tipoPj'+i)).value;
  let asfInput = (<HTMLInputElement>document.getElementById('asfPj'+i));
  let nascimentoInput = (<HTMLInputElement>document.getElementById('nascimentoPj'+i));
  if(tipoInput == "FILHO" || tipoInput == 'FILHA'){
    if(!this.verificaIdade(nascimentoInput.value)){
       this.filhoMenorIdadeCheck = true;
       if(this.funeralFilhosMenor == "AUTOMATICO"){
         asfInput.value = "FUNERAL AUTOMÁTICO ATÉ 18 ANOS";
         this.dependentes.get('dependente.'+i+'.asf').patchValue('FUNERAL AUTOMÁTICO ATÉ 18 ANOS')
       }else{
        asfInput.value = "NÃO HÁ FUNERAL CONTRATADO";
        this.dependentes.get('dependente.'+i+'.asf').patchValue('NÃO HÁ FUNERAL CONTRATADO');
       }
   }else{
    this.filhoMenorIdadeCheck = false;
    asfInput.value = "NÃO HÁ FUNERAL CONTRATADO";
    this.dependentes.get('dependente.'+i+'.asf').patchValue('NÃO HÁ FUNERAL CONTRATADO')
   }      
 }
}

verificaNascimentoEditar(i:any){
  let tipoInputEditar = (<HTMLInputElement>document.getElementById('tipoEditar'+i)).value;
  let asfInputEditar = (<HTMLInputElement>document.getElementById('asfEditar'+i));
  let nascimentoInputEditar = (<HTMLInputElement>document.getElementById('nascimentoEditar'+i));
  if(tipoInputEditar == "FILHO" || tipoInputEditar == 'FILHA'){
    if(!this.verificaIdade(nascimentoInputEditar.value)){
       this.filhoMenorIdadeCheck = true;
         asfInputEditar.value = "FUNERAL AUTOMÁTICO ATÉ 18 ANOS";
         this.dependentesEditar.get('dependenteEditar.'+i+'.asf').patchValue('FUNERAL AUTOMÁTICO ATÉ 18 ANOS')
   }else{
    this.filhoMenorIdadeCheck = false;
    asfInputEditar.value = "NÃO HÁ FUNERAL CONTRATADO";
    this.dependentesEditar.get('dependenteEditar.'+i+'.asf').patchValue('NÃO HÁ FUNERAL CONTRATADO')
   }      
 }
}

  selectAsf(){
    if(this.empresaAssociado == "VIVA MAX" || this.hibrido){
      this.totalAsfDependentes = 0;
      this.totalMensalidade = 0;
      let control = 1;
      if(this.dependentes.value.dependente.length > 0){
          this.dependentes.value.dependente.forEach((item) => {
          if(item.asf == "FUNERAL CONTRATADO"){
            this.totalAsfDependentes += this.valorFuneralFilhosMaior;
          }
            if(control == this.dependentes.value.dependente.length){
              this.totalMensalidade = this.totalAsfDependentes + this.totalAsfDependentesExtras + this.mensalidade;
            }else{
              control++
            }
        })
      }else{
        this.totalMensalidade = this.totalAsfDependentes + this.totalAsfDependentesExtras + this.mensalidade;
      }
    }
  }

   selectAsfExtras(){
    if(this.empresaAssociado == "VIVA MAX" || this.hibrido){
      this.totalAsfDependentesExtras = 0;
      this.totalMensalidade = 0;
      let control = 1;
      if(this.dependentesExtra.value.dependenteExtra.length > 0){
        this.dependentesExtra.value.dependenteExtra.forEach((item) => {
          if(this.funeralExtra == "SIM" && item.asf == "FUNERAL CONTRATADO"){
            this.totalAsfDependentesExtras += this.valorFuneralExtra;
          }
          if(control == this.dependentesExtra.value.dependenteExtra.length){
            this.totalMensalidade = this.totalAsfDependentes + this.totalAsfDependentesExtras + this.mensalidade;
          }else{
            control++
          }
        })
      }else{
        this.totalMensalidade = this.totalAsfDependentes + this.totalAsfDependentesExtras + this.mensalidade
      }
    }
  }
  
  selectAsfEditar(){
    if(this.empresaAssociado == "VIVA MAX" || this.hibrido){
      this.totalAsfDependentesEditar = 0;
      this.totalMensalidadeEditar = 0;
      let control = 1;
      if(this.dependentesEditar.value.dependenteEditar.length > 0){
          this.dependentesEditar.value.dependenteEditar.forEach((item:any) => {
          if(item.asf == "FUNERAL CONTRATADO" && item.situacao == "ATIVO"){
            this.totalAsfDependentesEditar += this.valorFuneralFilhosMaiorEditar;
          }
          if(control == this.dependentesEditar.value.dependenteEditar.length){
            this.totalMensalidadeEditar = this.totalAsfDependentesEditar + this.totalAsfDependentesExtrasEditar + this.mensalidadeEditar + this.totalValorConectaExtrasEditar;
          }else{
            control++
          }
        })
      }else{
        this.totalMensalidadeEditar = this.totalAsfDependentesEditar + this.totalAsfDependentesExtrasEditar + this.mensalidadeEditar + this.totalValorConectaExtrasEditar;
      }
    }
  }

   selectAsfExtrasEditar(){
    if(this.empresaAssociado == "VIVA MAX" || this.hibrido){
      this.totalAsfDependentesExtrasEditar = 0;
      this.totalMensalidadeEditar = 0;
      let control = 1;
      if(this.dependentesExtraEditar.value.dependenteExtraEditar.length > 0){
        this.dependentesExtraEditar.value.dependenteExtraEditar.forEach((item) => {
          if(this.funeralExtraEditar == "SIM" && item.asf == "FUNERAL CONTRATADO" && item.situacao == "ATIVO"){
            this.totalAsfDependentesExtrasEditar += this.valorFuneralExtraEditar;
          }
          if(control == this.dependentesExtraEditar.value.dependenteExtraEditar.length){
            this.totalMensalidadeEditar = this.totalAsfDependentesEditar + this.totalAsfDependentesExtrasEditar + this.mensalidadeEditar + this.totalValorConectaExtrasEditar;
          }else{
            control++
          }
        })
      }else{
        this.totalMensalidadeEditar = this.totalAsfDependentesEditar + this.totalAsfDependentesExtrasEditar + this.mensalidadeEditar + this.totalValorConectaExtrasEditar;
      }
    }
  }
 
  verificaIdade(e:any){
    if(e){
      var d = new Date;
      let dt = e.split('/').reverse().join('-');
      let data = new Date(dt.split('-')[0],dt.split('-')[1]-1,dt.split('-')[2])
      let ano_atual = d.getFullYear();
      let mes_atual = d.getMonth() + 1;
      let dia_atual = d.getDate();
      let ano_aniversario = data.getFullYear();;
      let mes_aniversario = data.getMonth() + 1;
      let dia_aniversario = data.getDate();
      let idade = ano_atual - ano_aniversario;
      if(mes_atual < mes_aniversario || mes_atual == mes_aniversario && dia_atual < dia_aniversario){
          idade--;
      }
      if(idade >= 18){
        return true;
      }else{
        return false;
      }
    }else{
      return false;
    }
  }

  cadastrarHistorico(){
    let jsonDate = new Date().toJSON();
    let hora = new Date(jsonDate).toLocaleTimeString();
    let dados = {
      "data" : this.dataHoje, 
      "hora" : hora, 
      "motivo" : this.motivo, 
      "msg" : this.replaceStrings(this.mensagem), 
      "usuario" : localStorage.getItem('usuarioVivamax')
    }
    this.db.newHistorico(this.idAssociado,dados).subscribe(res => {
      console.log(res);
      let body = {
        "menu" : "ATENDIMENTO", 
        "descricao" : "Matricula: "+this.matriculaEditar+" - "+this.nomeEditar+" : "+this.replaceStrings(this.mensagem), 
        "data" : this.dataHoje, 
        "hora" : hora, 
        "usuario" : this.user
      }
      this.db.postLogs(body).subscribe(result => {
        console.log(result);
      this.app.openAlert("Atendimento Cadastrado com Sucesso!");
      this.reverseReplaceStrings(dados.msg)
      this.historicos.unshift(dados);
      this.mensagem = "";
      this.motivo = "";
    }, err => {
      console.log(err);
      this.app.openAlert("Erro ao cadastrar atendimento, por favor tente novamente!")
    })
    }, err => {
      console.log(err);
      this.app.openAlert("Erro ao cadastrar atendimento, por favor tente novamente!")
    })
  }

  medicoChange(){
    if(this.medicoPreferencialMedico != "SIM"){
      this.medicoPreferencia = "";
    }
  }

  buscarCep(cepGet){
    let c = cepGet.replace(/_/gi,'').replace('.','').replace('-','');
    if(c.length > 7){
      this.db.getCep(c).subscribe((data:any) => {
        let cep = data;
         if(cep.erro == true){
           this.app.openAlert("CEP NAO ENCONTRADO");
         }else{
           this.rua = cep.logradouro.toUpperCase();
           this.bairro = cep.bairro.toUpperCase();
           this.municipio = cep.localidade.toUpperCase();
           this.estado = cep.uf.toUpperCase();
         }
      });
    }
  }

  buscarCepEditar(cepEdit){
    let cEdit = cepEdit.replace(/_/gi,'').replace('.','').replace('-','');
    if(cEdit.length > 7){
      this.db.getCep(cEdit).subscribe((data:any) => {
        let cepEdit = data;
         if(cepEdit.erro == true){
           this.app.openAlert("CEP NAO ENCONTRADO");
         }else{
           this.ruaEditar = cepEdit.logradouro.toUpperCase();
           this.bairroEditar = cepEdit.bairro.toUpperCase();
           this.municipioEditar = cepEdit.localidade.toUpperCase();
           this.estadoEditar = cepEdit.uf.toUpperCase();
           if(this.ruaEditar != this.backup.rua){
            this.salvarTemp('rua',this.ruaEditar)
           }
           if(this.bairroEditar != this.backup.bairro){
            this.salvarTemp('bairro',this.bairroEditar)
          }
          if(this.municipioEditar != this.backup.municipio){
            this.salvarTemp('municipio',this.municipioEditar)
          }
          if(this.estadoEditar != this.backup.estado){
            this.salvarTemp('estado',this.estadoEditar)
          }
         }
      });
    }
  }

  escolhePlanoFisico(planoFisico){
    if(planoFisico == true){
      this.subContrato = "";
      this.mensalidade = 0;
      this.taxaAdesao = 0;
    }
  }

  escolhePlanoEmpresarial(planoEmpresarial){
    if(planoEmpresarial == true){
      this.subContrato = "";
      this.mensalidade = 0;
      this.taxaAdesao = 0;
    }
  }

  escolhePlanoFisicoEditar(planoFisico){
    if(planoFisico == true){
      this.subContratoEditar = "";
      this.mensalidadeEditar = 0;
      this.planoEditar = "";
      this.diaVencimentoEditar = 0;
      this.formaPagamentoEditar = "";
      this.mesFolhaPagamentoEditar = "";
      this.cartaoSelecionadoEditar = "";
      this.nomeCartaoEditar = "";
      this.validadeCartaoEditar = "";
      this.numeroCartaoEditar = "";
      this.codigoCartaoEditar = "";
      this.bloqueioPagamentos = false;
      let dF = <HTMLInputElement>document.getElementById('descontoFolhaEditar');
      dF.checked = false;
      let dR = <HTMLInputElement>document.getElementById('debitoRecorrenteEditar');
        dR.checked = false;
      let bM = <HTMLInputElement>document.getElementById('boletoMensalEditar');
      bM.checked = false;
      let dC = <HTMLInputElement>document.getElementById('carneEditar');
      dC.checked = false;
      this.planoEmpresarialCheck = false;
    }
  }

  escolhePlanoEmpresarialEditar(planoEmpresarial){
    if(planoEmpresarial == true){
      this.subContratoEditar = "";
      this.mensalidadeEditar = 0;
      this.planoEditar = "";
      this.diaVencimentoEditar = 0;
      this.formaPagamentoEditar = "";
      this.mesFolhaPagamentoEditar = "";
      this.cartaoSelecionadoEditar = "";
      this.nomeCartaoEditar = "";
      this.validadeCartaoEditar = "";
      this.numeroCartaoEditar = "";
      this.codigoCartaoEditar = "";
      this.bloqueioPagamentos = false;
      let dF = <HTMLInputElement>document.getElementById('descontoFolhaEditar');
      dF.checked = false;
      let dR = <HTMLInputElement>document.getElementById('debitoRecorrenteEditar');
        dR.checked = false;
      let bM = <HTMLInputElement>document.getElementById('boletoMensalEditar');
      bM.checked = false;
      let dC = <HTMLInputElement>document.getElementById('carneEditar');
      dC.checked = false;
      this.planoFisicoCheck = false;
    }
  }

  escolheSubContrato(subContrato){
    let dFa = <HTMLInputElement>document.getElementById('descontoFolha');
    dFa.checked = false;
    let dRa = <HTMLInputElement>document.getElementById('debitoRecorrente');
    dRa.checked = false;
    let bMa = <HTMLInputElement>document.getElementById('boletoMensal');
    bMa.checked = false;
    let dCa = <HTMLInputElement>document.getElementById('carne');
    dCa.checked = false;
    if(subContrato == ""){
      this.plano = "";
      this.formaPagamento = "";
      this.bloqueioPagamentos = false;
      this.subCheckConsignet = false;
    }else{
      this.planoFisicoCheck = false;
      this.planoEmpresarialCheck = false;
      this.mensalidade = 0;
      this.taxaAdesao = 0;
      this.subCheckConsignet = subContrato?.checkConsignet;
    }
  }

  escolheSubContratoEditar(subContrato){
    if(subContrato != ""){
      this.planoFisicoCheckEditar = false;
      this.planoEmpresarialCheck = false;
      this.mensalidadeEditar = 0;
      this.planoEditar = "";
      this.diaVencimentoEditar = 0;
      this.formaPagamentoEditar = "";
      this.mesFolhaPagamentoEditar = "";
      this.cartaoSelecionadoEditar = "";
      this.nomeCartaoEditar = "";
      this.validadeCartaoEditar = "";
      this.numeroCartaoEditar = "";
      this.codigoCartaoEditar = "";
      let dF = <HTMLInputElement>document.getElementById('descontoFolhaEditar');
      dF.checked = true;
      let dR = <HTMLInputElement>document.getElementById('debitoRecorrenteEditar');
        dR.checked = false;
      let bM = <HTMLInputElement>document.getElementById('boletoMensalEditar');
      bM.checked = false;
      let dC = <HTMLInputElement>document.getElementById('carneEditar');
      dC.checked = false;
    }
  }

  selectPlano(tipo,plano){
    if(tipo == 'fisico'){
      if(plano == ""){
        this.formaPagamento = "";
        this.mensalidade = 0;
        this.taxaAdesao = 0;
        this.totalMensalidade = 0;
        let dF = <HTMLInputElement>document.getElementById('descontoFolha');
        dF.checked = false;
        let dR = <HTMLInputElement>document.getElementById('debitoRecorrente');
        dR.checked = false;
        let bM = <HTMLInputElement>document.getElementById('boletoMensal');
        bM.checked = false;
        let dC = <HTMLInputElement>document.getElementById('carne');
        dC.checked = false;  
      }else{
        let i = this.planosFisicos.map((item) => {
          return item.nome
        }).indexOf(plano);
        this.mensalidade =this.planosFisicos[i]?.mensalidade;
        this.funeralConjuge = this.planosFisicos[i]?.funeralConjuge;
        this.funeralExtra = this.planosFisicos[i]?.funeralExtra;
        this.funeralFilhosMaior = this.planosFisicos[i]?.funeralFilhosMaior;
        this.funeralFilhosMenor = this.planosFisicos[i]?.funeralFilhosMenor;
        this.editaAdesao = this.planosFisicos[i]?.editaAdesao;
        this.editaMensalidade = this.planosFisicos[i]?.editaMensalidade;
        this.editaTotal = this.planosFisicos[i]?.editaTotal;
        this.conectaInclusos = this.planosFisicos[i]?.conectaInclusos;
        this.valorConectaExtra = this.planosFisicos[i]?.valorConectaExtra;
        this.maxDependentesConecta = this.planosFisicos[i]?.maxDependentesConecta;
        this.hibrido = this.planosFisicos[i]?.hibrido;
        if(this.planosFisicos[i]?.adesao == null){
          this.taxaAdesao = 0;
        }else{
          this.taxaAdesao =this.planosFisicos[i]?.adesao;
        }
        if(this.planosFisicos[i]?.valorCapitalSegurado == null){
          this.valorCapitalSegurado = 0;
        }else{
          this.valorCapitalSegurado =this.planosFisicos[i]?.valorCapitalSegurado;
        }
        if(this.planosFisicos[i]?.valorFuneralConjuge == null){
          this.valorFuneralConjuge = 0;
        }else{
          this.valorFuneralConjuge =this.planosFisicos[i]?.valorFuneralConjuge;
        }
        if(this.planosFisicos[i]?.valorFuneralExtra == null){
          this.valorFuneralExtra = 0;
        }else{
          this.valorFuneralExtra =this.planosFisicos[i]?.valorFuneralExtra;
        }
        if(this.planosFisicos[i]?.valorFuneralFilhosMaior == null){
          this.valorFuneralFilhosMaior = 0;
        }else{
          this.valorFuneralFilhosMaior =this.planosFisicos[i]?.valorFuneralFilhosMaior;
        }
        if(this.planosFisicos[i]?.valorFuneralFilhosMenor == null){
          this.valorFuneralFilhosMenor = 0;
        }else{
          this.valorFuneralFilhosMenor = this.planosFisicos[i]?.valorFuneralFilhosMenor;
        }
        this.totalMensalidade = this.totalAsfDependentes + this.totalAsfDependentesExtras + this.mensalidade;
      }
    }
    if(tipo == 'empresarial'){
      if(plano == ""){
        this.formaPagamento = "";
        this.mensalidade = 0;
        this.taxaAdesao = 0;
        this.totalMensalidade = 0;
        let dF = <HTMLInputElement>document.getElementById('descontoFolha');
        dF.checked = false;
        let dR = <HTMLInputElement>document.getElementById('debitoRecorrente');
        dR.checked = false;
        let bM = <HTMLInputElement>document.getElementById('boletoMensal');
        bM.checked = false;
        let dC = <HTMLInputElement>document.getElementById('carne');
        dC.checked = false;  
      }else{
        let i = this.planosEmpresariais.map((item) => {
          return item.nome
        }).indexOf(plano);
        this.mensalidade =this.planosEmpresariais[i]?.valorPlano;
        this.funeralConjuge = this.planosEmpresariais[i]?.funeralConjuge;
        this.funeralExtra = this.planosEmpresariais[i]?.funeralExtra;
        this.funeralFilhosMaior = this.planosEmpresariais[i]?.funeralFilhosMaior;
        this.funeralFilhosMenor = this.planosEmpresariais[i]?.funeralFilhosMenor;
        this.editaAdesao = this.planosEmpresariais[i]?.editaAdesao;
        this.editaMensalidade = this.planosEmpresariais[i]?.editaMensalidade;
        this.editaTotal = this.planosEmpresariais[i]?.editaTotal;
        this.conectaInclusos = this.planosEmpresariais[i]?.conectaInclusos;
        this.valorConectaExtra = this.planosEmpresariais[i]?.valorConectaExtra;
        this.maxDependentesConecta = this.planosEmpresariais[i]?.maxDependentesConecta;
        this.hibrido = this.planosEmpresariais[i]?.hibrido;
        if(this.planosEmpresariais[i]?.adesao == null){
          this.taxaAdesao = 0;
        }else{
          this.taxaAdesao =this.planosEmpresariais[i]?.adesao;
        }
        if(this.planosEmpresariais[i]?.valorCapitalSegurado == null){
          this.valorCapitalSegurado = 0;
        }else{
          this.valorCapitalSegurado =this.planosEmpresariais[i]?.valorCapitalSegurado;
        }
        if(this.planosEmpresariais[i]?.valorFuneralConjuge == null){
          this.valorFuneralConjuge = 0;
        }else{
          this.valorFuneralConjuge =this.planosEmpresariais[i]?.valorFuneralConjuge;
        }
        if(this.planosEmpresariais[i]?.valorFuneralExtra == null){
          this.valorFuneralExtra = 0;
        }else{
          this.valorFuneralExtra =this.planosEmpresariais[i]?.valorFuneralExtra;
        }
        if(this.planosEmpresariais[i]?.valorFuneralFilhosMaior == null){
          this.valorFuneralFilhosMaior = 0;
        }else{
          this.valorFuneralFilhosMaior =this.planosEmpresariais[i]?.valorFuneralFilhosMaior;
        }
        if(this.planosEmpresariais[i]?.valorFuneralFilhosMenor == null){
          this.valorFuneralFilhosMenor = 0;
        }else{
          this.valorFuneralFilhosMenor = this.planosEmpresariais[i]?.valorFuneralFilhosMenor;
        }
        this.totalMensalidade = this.totalAsfDependentes + this.totalAsfDependentesExtras + this.mensalidade;
      }
    }
    if(tipo == 'servidor'){ 
      if(plano == ""){
        this.formaPagamento = "";
        this.mesFolhaPagamento = "";
        this.mensalidade = 0;
        this.taxaAdesao = 0;
        this.totalMensalidade = 0;
        let dF = <HTMLInputElement>document.getElementById('descontoFolha');
        dF.checked = false;
        let dR = <HTMLInputElement>document.getElementById('debitoRecorrente');
        dR.checked = false;
        let bM = <HTMLInputElement>document.getElementById('boletoMensal');
        bM.checked = false;
        let dC = <HTMLInputElement>document.getElementById('carne');
        dC.checked = false;
        this.bloqueioPagamentos = false;
      }else{
        this.diaVencimento = 15;
        let i = this.planosServidores.map((item) => {
          return item.nome
        }).indexOf(plano);
        this.mensalidade =this.planosServidores[i]?.mensalidade;
        this.funeralConjuge = this.planosServidores[i]?.funeralConjuge;
        this.funeralExtra = this.planosServidores[i]?.funeralExtra;
        this.funeralFilhosMaior = this.planosServidores[i]?.funeralFilhosMaior;
        this.funeralFilhosMenor = this.planosServidores[i]?.funeralFilhosMenor;
        this.editaAdesao = this.planosServidores[i]?.editaAdesao;
        this.editaMensalidade = this.planosServidores[i]?.editaMensalidade;
        this.editaTotal = this.planosServidores[i]?.editaTotal;
        this.conectaInclusos = this.planosServidores[i]?.conectaInclusos;
        this.valorConectaExtra = this.planosServidores[i]?.valorConectaExtra;
        this.maxDependentesConecta = this.planosServidores[i]?.maxDependentesConecta;
        this.hibrido = this.planosServidores[i]?.hibrido;
        if(this.planosServidores[i]?.adesao == null){
          this.taxaAdesao = 0;
        }else{
          this.taxaAdesao =this.planosServidores[i]?.adesao;
        }
        if(this.planosServidores[i]?.valorCapitalSegurado == null){
          this.valorCapitalSegurado = 0;
        }else{
          this.valorCapitalSegurado =this.planosServidores[i]?.valorCapitalSegurado;
        }
        if(this.planosServidores[i]?.valorFuneralConjuge == null){
          this.valorFuneralConjuge = 0;
        }else{
          this.valorFuneralConjuge =this.planosServidores[i]?.valorFuneralConjuge;
        }
        if(this.planosServidores[i]?.valorFuneralExtra == null){
          this.valorFuneralExtra = 0;
        }else{
          this.valorFuneralExtra =this.planosServidores[i]?.valorFuneralExtra;
        }
        if(this.planosServidores[i]?.valorFuneralFilhosMaior == null){
          this.valorFuneralFilhosMaior = 0;
        }else{
          this.valorFuneralFilhosMaior =this.planosServidores[i]?.valorFuneralFilhosMaior;
        }
        if(this.planosServidores[i]?.valorFuneralFilhosMenor == null){
          this.valorFuneralFilhosMenor = 0;
        }else{
          this.valorFuneralFilhosMenor = this.planosServidores[i]?.valorFuneralFilhosMenor;
        }
        this.totalMensalidade = this.totalAsfDependentes + this.totalAsfDependentesExtras + this.mensalidade;
        this.formaPagamento = "DESCONTO EM FOLHA DE PAGAMENTO";
        let dFa = <HTMLInputElement>document.getElementById('descontoFolha');
        dFa.checked = true;
        this.bloqueioPagamentos = true;
      }
    }
    if(tipo == 'seguro'){ 
      if(plano == ""){
        this.formaPagamento = "";
        this.mesFolhaPagamento = "";
        this.mensalidade = 0;
        this.taxaAdesao = 0;
        this.totalMensalidade = 0;
        let dF = <HTMLInputElement>document.getElementById('descontoFolhaSeg');
        dF.checked = false;
        let dR = <HTMLInputElement>document.getElementById('debitoRecorrenteSeg');
        dR.checked = false;
        let bM = <HTMLInputElement>document.getElementById('boletoMensalSeg');
        bM.checked = false;
        let dC = <HTMLInputElement>document.getElementById('carneSeg');
        dC.checked = false;
        this.bloqueioPagamentos = false;
      }else{
        this.diaVencimento = 15;
        let i = this.planosSeguro.map((item) => {
          return item.nome
        }).indexOf(plano);
        this.mensalidade = this.planosSeguro[i]?.mensalidade;
        this.funeralConjuge = this.planosSeguro[i]?.funeralConjuge;
        this.funeralExtra = this.planosSeguro[i]?.funeralExtra;
        this.funeralFilhosMaior = this.planosSeguro[i]?.funeralFilhosMaior;
        this.funeralFilhosMenor = this.planosSeguro[i]?.funeralFilhosMenor;
        this.editaAdesao = this.planosSeguro[i]?.editaAdesao;
        this.editaMensalidade = this.planosSeguro[i]?.editaMensalidade;
        this.editaTotal = this.planosSeguro[i]?.editaTotal;
        this.conectaInclusos = this.planosSeguro[i]?.conectaInclusos;
        this.valorConectaExtra = this.planosSeguro[i]?.valorConectaExtra;
        this.maxDependentesConecta = this.planosSeguro[i]?.maxDependentesConecta;
        this.hibrido = this.planosSeguro[i]?.hibrido;
        if(this.planosSeguro[i]?.adesao == null){
          this.taxaAdesao = 0;
        }else{
          this.taxaAdesao =this.planosSeguro[i]?.adesao;
        }
        if(this.planosSeguro[i]?.valorCapitalSegurado == null){
          this.valorCapitalSegurado = 0;
        }else{
          this.valorCapitalSegurado =this.planosSeguro[i]?.valorCapitalSegurado;
        }
        if(this.planosSeguro[i]?.valorFuneralConjuge == null){
          this.valorFuneralConjuge = 0;
        }else{
          this.valorFuneralConjuge =this.planosSeguro[i]?.valorFuneralConjuge;
        }
        if(this.planosSeguro[i]?.valorFuneralExtra == null){
          this.valorFuneralExtra = 0;
        }else{
          this.valorFuneralExtra =this.planosSeguro[i]?.valorFuneralExtra;
        }
        if(this.planosSeguro[i]?.valorFuneralFilhosMaior == null){
          this.valorFuneralFilhosMaior = 0;
        }else{
          this.valorFuneralFilhosMaior =this.planosSeguro[i]?.valorFuneralFilhosMaior;
        }
        if(this.planosSeguro[i]?.valorFuneralFilhosMenor == null){
          this.valorFuneralFilhosMenor = 0;
        }else{
          this.valorFuneralFilhosMenor = this.planosSeguro[i]?.valorFuneralFilhosMenor;
        }
        this.totalMensalidade = this.totalAsfDependentes + this.totalAsfDependentesExtras + this.mensalidade;
        this.formaPagamento = "DESCONTO EM FOLHA DE PAGAMENTO";
        let dFa = <HTMLInputElement>document.getElementById('descontoFolhaSeg');
        dFa.checked = true;
        this.bloqueioPagamentos = true;
      }
    }
  }

  selectPlanoEditar(tipo:any,plano:any,origem:any){
    let i:any;
    if(tipo == 'fisico'){
      if(plano == ""){
        this.formaPagamentoEditar = "";
        this.mensalidadeEditar = 0;
        this.totalMensalidadeEditar = 0;
      }else{
        i = this.planosFisicosEditar.map((item:any) => {
          return item.nome
        }).indexOf(plano);
        if(i >= 0){
        this.funeralConjugeEditar = this.planosFisicosEditar[i]?.funeralConjuge;
        this.funeralExtraEditar = this.planosFisicosEditar[i]?.funeralExtra;
        this.funeralFilhosMaiorEditar = this.planosFisicosEditar[i]?.funeralFilhosMaior;
        this.funeralFilhosMenorEditar = this.planosFisicosEditar[i]?.funeralFilhosMenor;
        this.editaAdesaoEditar = this.planosFisicosEditar[i]?.editaAdesao;
        this.editaMensalidadeEditar = this.planosFisicosEditar[i]?.editaMensalidade;
        this.editaTotalEditar = this.planosFisicosEditar[i]?.editaTotal;
        this.valorCapitalSeguradoEditar = this.planosFisicosEditar[i]?.valorCapitalSegurado;
        this.valorFuneralConjugeEditar = this.planosFisicosEditar[i]?.valorFuneralConjuge;
        this.valorFuneralExtraEditar = this.planosFisicosEditar[i]?.valorFuneralExtra;
        this.valorFuneralFilhosMaiorEditar = this.planosFisicosEditar[i]?.valorFuneralFilhosMaior;
        this.valorFuneralFilhosMenorEditar = this.planosFisicosEditar[i]?.valorFuneralFilhosMenor;
        this.conectaInclusosEditar = this.planosFisicosEditar[i]?.conectaInclusos;
        this.valorConectaExtraEditar = this.planosFisicosEditar[i]?.valorConectaExtra;
        this.maxDependentesConectaEditar = this.planosFisicosEditar[i]?.maxDependentesConecta;
        this.hibrido = this.planosFisicosEditar[i]?.hibrido;
        if(this.planosFisicosEditar[i]?.valorCapitalSegurado == null){
          this.valorCapitalSeguradoEditar = 0;
        }else{
          this.valorCapitalSeguradoEditar =this.planosFisicosEditar[i]?.valorCapitalSegurado;
        }
        if(this.planosFisicosEditar[i]?.valorFuneralConjuge == null){
          this.valorFuneralConjugeEditar = 0;
        }else{
          this.valorFuneralConjugeEditar =this.planosFisicosEditar[i]?.valorFuneralConjuge;
        }
        if(this.planosFisicosEditar[i]?.valorFuneralExtra == null){
          this.valorFuneralExtraEditar = 0;
        }else{
          this.valorFuneralExtraEditar =this.planosFisicosEditar[i]?.valorFuneralExtra;
        }
        if(this.planosFisicosEditar[i]?.valorFuneralFilhosMaior == null){
          this.valorFuneralFilhosMaiorEditar = 0;
        }else{
          this.valorFuneralFilhosMaiorEditar =this.planosFisicosEditar[i]?.valorFuneralFilhosMaior;
        }
        if(this.planosFisicosEditar[i]?.valorFuneralFilhosMenor == null){
          this.valorFuneralFilhosMenorEditar = 0;
        }else{
          this.valorFuneralFilhosMenorEditar = this.planosFisicosEditar[i]?.valorFuneralFilhosMenor;
        }
        if(origem != 'start'){
          this.mensalidadeEditar =this.planosFisicosEditar[i]?.mensalidade;
          this.alterarPlano();
        }
        }
      }
    }
    if(tipo == 'empresarial'){
      if(plano == ""){
        this.formaPagamentoEditar = "";
        this.mensalidadeEditar = 0;
        this.totalMensalidadeEditar = 0;
      }else{
        i = this.planosEmpresariaisEditar.map((item) => {
          return item.nome
        }).indexOf(plano);
        if(i >= 0){
        this.funeralConjugeEditar = this.planosEmpresariaisEditar[i]?.funeralConjuge;
        this.funeralExtraEditar = this.planosEmpresariaisEditar[i]?.funeralExtra;
        this.funeralFilhosMaiorEditar = this.planosEmpresariaisEditar[i]?.funeralFilhosMaior;
        this.funeralFilhosMenorEditar = this.planosEmpresariaisEditar[i]?.funeralFilhosMenor;
        this.editaAdesaoEditar = this.planosEmpresariaisEditar[i]?.editaAdesao;
        this.editaMensalidadeEditar = this.planosEmpresariaisEditar[i]?.editaMensalidade;
        this.editaTotalEditar = this.planosEmpresariaisEditar[i]?.editaTotal;
        this.valorCapitalSeguradoEditar =this.planosEmpresariaisEditar[i]?.valorCapitalSegurado;
        this.valorFuneralConjugeEditar =this.planosEmpresariaisEditar[i]?.valorFuneralConjuge;
        this.valorFuneralExtraEditar =this.planosEmpresariaisEditar[i]?.valorFuneralExtra;
        this.valorFuneralFilhosMaiorEditar =this.planosEmpresariaisEditar[i]?.valorFuneralFilhosMaior;
        this.valorFuneralFilhosMenorEditar =this.planosEmpresariaisEditar[i]?.valorFuneralFilhosMenor;
        this.conectaInclusosEditar = this.planosEmpresariaisEditar[i]?.conectaInclusos;
        this.valorConectaExtraEditar = this.planosEmpresariaisEditar[i]?.valorConectaExtra;
        this.maxDependentesConectaEditar = this.planosEmpresariaisEditar[i]?.maxDependentesConecta;
        this.hibrido = this.planosEmpresariaisEditar[i]?.hibrido;
        if(this.planosEmpresariaisEditar[i]?.valorCapitalSegurado == null){
          this.valorCapitalSeguradoEditar = 0;
        }else{
          this.valorCapitalSeguradoEditar =this.planosEmpresariaisEditar[i]?.valorCapitalSegurado;
        }
        if(this.planosEmpresariaisEditar[i]?.valorFuneralConjuge == null){
          this.valorFuneralConjugeEditar = 0;
        }else{
          this.valorFuneralConjugeEditar =this.planosEmpresariaisEditar[i]?.valorFuneralConjuge;
        }
        if(this.planosEmpresariaisEditar[i]?.valorFuneralExtra == null){
          this.valorFuneralExtraEditar = 0;
        }else{
          this.valorFuneralExtraEditar =this.planosEmpresariaisEditar[i]?.valorFuneralExtra;
        }
        if(this.planosEmpresariaisEditar[i]?.valorFuneralFilhosMaior == null){
          this.valorFuneralFilhosMaiorEditar = 0;
        }else{
          this.valorFuneralFilhosMaiorEditar =this.planosEmpresariaisEditar[i]?.valorFuneralFilhosMaior;
        }
        if(this.planosEmpresariaisEditar[i]?.valorFuneralFilhosMenor == null){
          this.valorFuneralFilhosMenorEditar = 0;
        }else{
          this.valorFuneralFilhosMenorEditar = this.planosEmpresariaisEditar[i]?.valorFuneralFilhosMenor;
        }
        if(origem != 'start'){
          this.mensalidadeEditar =this.planosEmpresariaisEditar[i]?.mensalidade;
          this.alterarPlano();
        }
        }
      }
    }
    if(tipo == 'servidor'){
      if(plano == ""){
        this.formaPagamentoEditar = "";
        this.mesFolhaPagamentoEditar = null;
        this.bloqueioPagamentos = false;
        let dF = <HTMLInputElement>document.getElementById('descontoFolhaEditar');
        dF.checked = false
      }else{
        if(this.diaVencimentoEditar == 0 || this.diaVencimentoEditar == null){
          this.diaVencimentoEditar = 15;
        }
        i = this.planosServidoresEditar.map((item) => {
          return item.nome
        }).indexOf(plano);
        if(i >= 0){
          this.funeralConjugeEditar = this.planosServidoresEditar[i]?.funeralConjuge;
          this.funeralExtraEditar = this.planosServidoresEditar[i]?.funeralExtra;
          this.funeralFilhosMaiorEditar = this.planosServidoresEditar[i]?.funeralFilhosMaior;
          this.funeralFilhosMenorEditar = this.planosServidoresEditar[i]?.funeralFilhosMenor;
          this.editaAdesaoEditar = this.planosServidoresEditar[i]?.editaAdesao;
          this.editaMensalidadeEditar = this.planosServidoresEditar[i]?.editaMensalidade;
          this.editaTotalEditar = this.planosServidoresEditar[i]?.editaTotal;
          this.conectaInclusosEditar = this.planosServidoresEditar[i]?.conectaInclusos;
          this.valorConectaExtraEditar = this.planosServidoresEditar[i]?.valorConectaExtra;
          this.maxDependentesConectaEditar = this.planosServidoresEditar[i]?.maxDependentesConecta;
          this.hibrido = this.planosServidoresEditar[i]?.hibrido;
          if(this.planosServidoresEditar[i]?.valorCapitalSegurado == null){
            this.valorCapitalSeguradoEditar = 0;
          }else{
            this.valorCapitalSeguradoEditar =this.planosServidoresEditar[i]?.valorCapitalSegurado;
          }
          if(this.planosServidoresEditar[i]?.valorFuneralConjuge == null){
            this.valorFuneralConjugeEditar = 0;
          }else{
            this.valorFuneralConjugeEditar =this.planosServidoresEditar[i]?.valorFuneralConjuge;
          }
          if(this.planosServidoresEditar[i]?.valorFuneralExtra == null){
            this.valorFuneralExtraEditar = 0;
          }else{ 
            this.valorFuneralExtraEditar =this.planosServidoresEditar[i]?.valorFuneralExtra;
          }
          if(this.planosServidoresEditar[i]?.valorFuneralFilhosMaior == null){
            this.valorFuneralFilhosMaiorEditar = 0;
          }else{
            this.valorFuneralFilhosMaiorEditar =this.planosServidoresEditar[i]?.valorFuneralFilhosMaior;
          }
          if(this.planosServidoresEditar[i]?.valorFuneralFilhosMenor == null){
            this.valorFuneralFilhosMenorEditar = 0;
          }else{
            this.valorFuneralFilhosMenorEditar = this.planosServidoresEditar[i]?.valorFuneralFilhosMenor;
          }
          if(origem != 'start'){
            this.mensalidadeEditar =this.planosServidoresEditar[i]?.mensalidade;
            this.formaPagamentoEditar = "DESCONTO EM FOLHA DE PAGAMENTO";
            let dF = <HTMLInputElement>document.getElementById('descontoFolhaEditar');
            dF.checked = true;
            this.bloqueioPagamentos = true;
            this.alterarPlano();
          }
        }
      }
    }
    if(tipo == 'seguro'){ 
      if(plano == ""){
        this.formaPagamento = "";
        this.mesFolhaPagamento = "";
        this.mensalidade = 0;
        this.taxaAdesao = 0;
        this.totalMensalidade = 0;
        let dF = <HTMLInputElement>document.getElementById('descontoFolhaSeg');
        dF.checked = false;
        let dR = <HTMLInputElement>document.getElementById('debitoRecorrenteSeg');
        dR.checked = false;
        let bM = <HTMLInputElement>document.getElementById('boletoMensalSeg');
        bM.checked = false;
        let dC = <HTMLInputElement>document.getElementById('carneSeg');
        dC.checked = false;
        this.bloqueioPagamentos = false;
      }else{
        this.diaVencimento = 15;
        let i = this.planosSeguro.map((item) => {
          return item.nome
        }).indexOf(plano);
        this.mensalidade = this.planosSeguro[i]?.mensalidade;
        this.funeralConjuge = this.planosSeguro[i]?.funeralConjuge;
        this.funeralExtra = this.planosSeguro[i]?.funeralExtra;
        this.funeralFilhosMaior = this.planosSeguro[i]?.funeralFilhosMaior;
        this.funeralFilhosMenor = this.planosSeguro[i]?.funeralFilhosMenor;
        this.editaAdesao = this.planosSeguro[i]?.editaAdesao;
        this.editaMensalidade = this.planosSeguro[i]?.editaMensalidade;
        this.editaTotal = this.planosSeguro[i]?.editaTotal;
        if(this.planosSeguro[i]?.adesao == null){
          this.taxaAdesao = 0;
        }else{
          this.taxaAdesao =this.planosSeguro[i]?.adesao;
        }
        if(this.planosSeguro[i]?.valorCapitalSegurado == null){
          this.valorCapitalSegurado = 0;
        }else{
          this.valorCapitalSegurado =this.planosSeguro[i]?.valorCapitalSegurado;
        }
        if(this.planosSeguro[i]?.valorFuneralConjuge == null){
          this.valorFuneralConjuge = 0;
        }else{
          this.valorFuneralConjuge =this.planosSeguro[i]?.valorFuneralConjuge;
        }
        if(this.planosSeguro[i]?.valorFuneralExtra == null){
          this.valorFuneralExtra = 0;
        }else{
          this.valorFuneralExtra =this.planosSeguro[i]?.valorFuneralExtra;
        }
        if(this.planosSeguro[i]?.valorFuneralFilhosMaior == null){
          this.valorFuneralFilhosMaior = 0;
        }else{
          this.valorFuneralFilhosMaior =this.planosSeguro[i]?.valorFuneralFilhosMaior;
        }
        if(this.planosSeguro[i]?.valorFuneralFilhosMenor == null){
          this.valorFuneralFilhosMenor = 0;
        }else{
          this.valorFuneralFilhosMenor = this.planosSeguro[i]?.valorFuneralFilhosMenor;
        }
        this.totalMensalidade = this.totalAsfDependentes + this.totalAsfDependentesExtras + this.mensalidade;
        this.formaPagamento = "DESCONTO EM FOLHA DE PAGAMENTO";
        let dFa = <HTMLInputElement>document.getElementById('descontoFolhaSeg');
        dFa.checked = true;
        this.bloqueioPagamentos = true;
      }
    }
  }
  
  cartaoSelect(e,index){
    let checked = e.srcElement.checked;
    if(this.cartaoSelecionado != ""){
     let checks = document.getElementsByName('ch');
      for(let i = 0; i < checks.length; i++){
        let c = checks[i] as HTMLInputElement;
        c.checked= false
        if(i+1 == checks.length){
          if(checked){
            let cb = checks[index] as HTMLInputElement;
            cb.checked= true
            this.cartaoSelecionado = e.srcElement.labels[0].innerText;
          }else{
            this.cartaoSelecionado = "";
          }
        }
      }
    }else{
      this.cartaoSelecionado = e.srcElement.labels[0].innerText;
    }
  }

  cartaoSelectEditar(e,index){
    let checked = e.srcElement.checked;
    if(this.cartaoSelecionadoEditar != ""){
     let checks = document.getElementsByName('cards');
      for(let i = 0; i < checks.length; i++){
        let c = checks[i] as HTMLInputElement;
        c.checked= false
        if(i+1 == checks.length){
          if(checked){
            let cb = checks[index] as HTMLInputElement;
            cb.checked= true
            this.cartaoSelecionadoEditar = e.srcElement.labels[0].innerText;
          }else{
            this.cartaoSelecionadoEditar = "";
          }
        }
      }
    }else{
      this.cartaoSelecionadoEditar = e.srcElement.labels[0].innerText;
    }
  }

  selectDoenca(e:any){
    let inner = e.srcElement.labels[0].innerText;
    let checked = e.srcElement.checked;
    let c:any;
    if(inner == "SIM" && checked == true){
        this.doenca = "SIM";
        c = <HTMLInputElement>document.getElementById('doencaNao');
        c.checked = false
    }else if(inner == "NÃO" && checked == true){
        this.doenca = "NÃO";
        c = <HTMLInputElement>document.getElementById('doencaSim');
        c.checked = false
    }else{
      this.doenca = "";
    }
  }
  
  selectDoencaEditar(e){
    let inner = e.srcElement.labels[0].innerText;
    let checked = e.srcElement.checked;
    let c:any;
    if(inner == "SIM" && checked == true){
        this.doencaEditar = "SIM";
        c = <HTMLInputElement>document.getElementById('doencaNaoEditar');
        c.checked = false
        this.salvarTemp('doenca','SIM');

    }else if(inner == "NÃO" && checked == true){
        this.doencaEditar = "NÃO";
        c = <HTMLInputElement>document.getElementById('doencaSimEditar');
        c.checked = false
        this.especificarDoencaEditar = "";
        this.salvarTemp('doenca','NÃO')
        this.salvarTemp('especificarDoenca','')
    }else{
      this.doenca = "";
    }
  }
  
  selectDoencaSmart(e:any){
    let inner = e.srcElement.labels[0].innerText;
    let checked = e.srcElement.checked;
    let c:any;
    if(inner == "SIM" && checked == true){
        this.doenca = "SIM";
        c = <HTMLInputElement>document.getElementById('doencaNaoSmart');
        c.checked = false
    }else if(inner == "NÃO" && checked == true){
        this.doenca = "NÃO";
        c = <HTMLInputElement>document.getElementById('doencaSimSmart');
        c.checked = false
    }else{
      this.doenca = "";
    }
  }

  selectFormaPagamentoSmart(e){
    let inner = e.srcElement.labels[0].innerText;
    let checked = e.srcElement.checked;
    if(inner == 'DESCONTO EM FOLHA DE PAGAMENTO'){
      this.formaPagamento = inner;
      let dR = <HTMLInputElement>document.getElementById('debitoRecorrenteSmart');
      dR.checked = false;
      let bM = <HTMLInputElement>document.getElementById('boletoMensalSmart');
      bM.checked = false;
      let dC = <HTMLInputElement>document.getElementById('carneSmart');
      dC.checked = false;
      let cC = <HTMLInputElement>document.getElementById('celescSmart');
      if(cC){cC.checked = false;}
    }
    if(inner == 'DEBITO RECORRENTE'){
      this.formaPagamento = inner;
      let dF = <HTMLInputElement>document.getElementById('descontoFolhaSmart');
      dF.checked = false;
      let bM = <HTMLInputElement>document.getElementById('boletoMensalSmart');
      bM.checked = false;
      let dC = <HTMLInputElement>document.getElementById('carneSmart');
      dC.checked = false;
      let cC = <HTMLInputElement>document.getElementById('celescSmart');
      if(cC){cC.checked = false;}
      this.mesFolhaPagamento = "";
    }
    if(inner == 'BOLETO'){
      let dF = <HTMLInputElement>document.getElementById('descontoFolhaSmart');
      dF.checked = false;
      let dR = <HTMLInputElement>document.getElementById('debitoRecorrenteSmart');
      dR.checked = false;
      let dC = <HTMLInputElement>document.getElementById('carneSmart');
      dC.checked = false;
      let cC = <HTMLInputElement>document.getElementById('celescSmart');
      if(cC){cC.checked = false;}
      this.mesFolhaPagamento = "";
    }
    if(inner == 'CARNÊ'){
      let dF = <HTMLInputElement>document.getElementById('descontoFolhaSmart');
      dF.checked = false;
      let dR = <HTMLInputElement>document.getElementById('debitoRecorrenteSmart');
      dR.checked = false;
      let bM = <HTMLInputElement>document.getElementById('boletoMensalSmart');
      bM.checked = false;
      let cC = <HTMLInputElement>document.getElementById('celescSmart');
      if(cC){cC.checked = false;}
      this.mesFolhaPagamento = "";
    }
    if(inner == 'CONVÊNIO CELESC'){
      let dF = <HTMLInputElement>document.getElementById('descontoFolhaSmart');
      dF.checked = false;
      let dR = <HTMLInputElement>document.getElementById('debitoRecorrenteSmart');
      dR.checked = false;
      let bM = <HTMLInputElement>document.getElementById('boletoMensalSmart');
      bM.checked = false;
      let dC = <HTMLInputElement>document.getElementById('carneSmart');
      dC.checked = false;
      this.mesFolhaPagamento = "";
    }
    if(checked){
      this.formaPagamento = inner;
    }else{
      this.formaPagamento = "";
    }
  }

  selectFormaPagamento(e){
    let inner = e.srcElement.labels[0].innerText;
    let checked = e.srcElement.checked;
    if(inner == 'DESCONTO EM FOLHA DE PAGAMENTO'){
      this.formaPagamento = inner;
      let dR = <HTMLInputElement>document.getElementById('debitoRecorrente');
      dR.checked = false;
      let bM = <HTMLInputElement>document.getElementById('boletoMensal');
      bM.checked = false;
      let dC = <HTMLInputElement>document.getElementById('carne');
      dC.checked = false;
      let cC = <HTMLInputElement>document.getElementById('celesc');
      if(cC){cC.checked = false;}
    }
    if(inner == 'DEBITO RECORRENTE'){
      this.formaPagamento = inner;
      let dF = <HTMLInputElement>document.getElementById('descontoFolha');
      dF.checked = false;
      let bM = <HTMLInputElement>document.getElementById('boletoMensal');
      bM.checked = false;
      let dC = <HTMLInputElement>document.getElementById('carne');
      dC.checked = false;
      let cC = <HTMLInputElement>document.getElementById('celesc');
      if(cC){cC.checked = false;}
      this.mesFolhaPagamento = "";
    }
    if(inner == 'BOLETO'){
      let dF = <HTMLInputElement>document.getElementById('descontoFolha');
      dF.checked = false;
      let dR = <HTMLInputElement>document.getElementById('debitoRecorrente');
      dR.checked = false;
      let dC = <HTMLInputElement>document.getElementById('carne');
      dC.checked = false;
      let cC = <HTMLInputElement>document.getElementById('celesc');
      if(cC){cC.checked = false;}
      this.mesFolhaPagamento = "";
    }
    if(inner == 'CARNÊ'){
      let dF = <HTMLInputElement>document.getElementById('descontoFolha');
      dF.checked = false;
      let dR = <HTMLInputElement>document.getElementById('debitoRecorrente');
      dR.checked = false;
      let bM = <HTMLInputElement>document.getElementById('boletoMensal');
      bM.checked = false;
      let cC = <HTMLInputElement>document.getElementById('celesc');
      if(cC){cC.checked = false;}
      this.mesFolhaPagamento = "";
    }
    if(inner == 'CONVÊNIO CELESC'){
      let dF = <HTMLInputElement>document.getElementById('descontoFolha');
      dF.checked = false;
      let dR = <HTMLInputElement>document.getElementById('debitoRecorrente');
      dR.checked = false;
      let bM = <HTMLInputElement>document.getElementById('boletoMensal');
      bM.checked = false;
      let dC = <HTMLInputElement>document.getElementById('carne');
      dC.checked = false;
      this.mesFolhaPagamento = "";
    }
    if(checked){
      this.formaPagamento = inner;
    }else{
      this.formaPagamento = "";
    }
  }
 
  selectFormaPagamentoEditar(e:any){
    let inner = e?.srcElement?.labels[0]?.innerText;
    let checked = e?.srcElement?.checked;
    if(inner == 'DESCONTO EM FOLHA DE PAGAMENTO'){
      this.formaPagamentoEditar = inner;
      let dR = <HTMLInputElement>document.getElementById('debitoRecorrenteEditar');
      dR.checked = false;
      let bM = <HTMLInputElement>document.getElementById('boletoMensalEditar');
      bM.checked = false;
      let dC = <HTMLInputElement>document.getElementById('carneEditar');
      dC.checked = false;
      let cC = <HTMLInputElement>document.getElementById('celescEditar');
      if(cC){cC.checked = false;}
      this.mesFolhaPagamentoEditar = this.mesFolhaPagamento;
    }
    if(inner == 'DEBITO RECORRENTE'){
      this.formaPagamentoEditar = inner;
      let dF = <HTMLInputElement>document.getElementById('descontoFolhaEditar');
      dF.checked = false;
      let bM = <HTMLInputElement>document.getElementById('boletoMensalEditar');
      bM.checked = false;
      let dC = <HTMLInputElement>document.getElementById('carneEditar');
      dC.checked = false;
      let cC = <HTMLInputElement>document.getElementById('celescEditar');
      if(cC){cC.checked = false;}
      this.mesFolhaPagamentoEditar = "";
    }
    if(inner == 'BOLETO'){
      let dF = <HTMLInputElement>document.getElementById('descontoFolhaEditar');
      dF.checked = false;
      let dR = <HTMLInputElement>document.getElementById('debitoRecorrenteEditar');
      dR.checked = false;
      let dC = <HTMLInputElement>document.getElementById('carneEditar');
      dC.checked = false;
      let cC = <HTMLInputElement>document.getElementById('celescEditar');
      if(cC){cC.checked = false;}
      this.mesFolhaPagamentoEditar = "";
    }
    if(inner == 'CARNÊ'){
      let dF = <HTMLInputElement>document.getElementById('descontoFolhaEditar');
      dF.checked = false;
      let dR = <HTMLInputElement>document.getElementById('debitoRecorrenteEditar');
      dR.checked = false;
      let bM = <HTMLInputElement>document.getElementById('boletoMensalEditar');
      bM.checked = false;
      let cC = <HTMLInputElement>document.getElementById('celescEditar');
      if(cC){cC.checked = false;}
      this.mesFolhaPagamentoEditar = "";
    }
    if(inner == 'CARNÊ'){
      let dF = <HTMLInputElement>document.getElementById('descontoFolhaEditar');
      dF.checked = false;
      let dR = <HTMLInputElement>document.getElementById('debitoRecorrenteEditar');
      dR.checked = false;
      let bM = <HTMLInputElement>document.getElementById('boletoMensalEditar');
      bM.checked = false;
      let cC = <HTMLInputElement>document.getElementById('celescEditar');
      if(cC){cC.checked = false;}
      this.mesFolhaPagamentoEditar = "";
    }
    if(checked){
      this.formaPagamentoEditar = inner;
    }else{
      this.formaPagamentoEditar = "";
    }
  }
 
  selectVendedor(vendedor){
    if(vendedor){
      let i = this.todosVendedores.map((item:any) => {
        return item.nome || item.razaoEmpresa;
      }).indexOf(vendedor);
      if(i >= 0){
        this.idVendedor = this.todosVendedores[i]._id;
      }
    }
  }

  selectVendedorEditar(vendedor){
    if(vendedor){
      let i = this.todosVendedores.map((item:any) => {
        return item.nome || item.razaoEmpresa;
      }).indexOf(vendedor);
      this.idVendedorEditar = this.todosVendedores[i]._id;
      this.salvarTemp('vendedor',vendedor);
      this.salvarTemp('idVendedor',this.idVendedorEditar);
      }
    }

  showSenha(){
    this.show = !this.show
  }

  salvarTemp(campo,valor){
    valor = typeof valor == "string" ? this.replaceStrings(valor) : valor
    let jsonDate = new Date().toJSON();
    let hora = new Date(jsonDate).toLocaleTimeString();
    let dados = {};
    let valorAntigo = this.backup[campo];
    if(typeof valorAntigo === 'object'){valorAntigo = this.backup[campo]};
    if(valorAntigo == true){valorAntigo = 'MARCADO'};
    if(valorAntigo == false){valorAntigo = 'DESMARCADO'};
    if(valor == true){valor = 'MARCADO'};
    if(valor == false){valor = 'DESMARCADO'};
    if(campo == "doenca" && valor == false){
      dados = {especificarDoenca : null};
    }else{
      dados = {[campo] : valor};
    }
    let logs = {
      "menu" : "EDITAR ASSOCIADOS", 
      "descricao" : "MATRICULA: "+this.matriculaEditar+" - ASSOCIADO: "+this.nomeEditar+" - Campo "+campo+" alterado de: "+valorAntigo+" para: "+valor, 
      "data" : this.dataHoje, 
      "hora" : hora, 
      "usuario" : this.user
    }
    this.tempArray.push([dados,logs]);
  }
  
  salvarDados(){
    let ctrl = 1;
    this.tempArray.forEach((item:any) => {
      console.log(item)
      this.db.patchAssociado(this.idAssociado,item[0]).subscribe(res => {
        console.log(res);
        this.db.postLogs(item[1]).subscribe(result => {
          console.log(result);
          if(this.tempArray.length == ctrl){
            this.tempArray = [];
            if(this.empresaAssociado == "CONECTA"){
              this.db.getTokenDoc().subscribe((res: any) => {
                let tokenDoc: any = res.token;
              let { firstName, lastName } = this.app.splitName(this.nomeEditar);
              let cpfLimpo: any = this.app.limpaCPF(this.cpfEditar);
              let body: any = {"afiliado":{
                "nombre": firstName,
                "apellido": lastName,
                "sexo": this.sexoEditar,
                "email": this.emailEditar,
                "fecha_nacimiento":this.nascimentoEditar,
                "nro_documento": cpfLimpo
              }};
                this.db.postDoc24(tokenDoc, body).subscribe((res: any) => {
                  console.log(res);
                  },(err) => {
                    console.log(err);
                  })
              })
            }
          }else{
            ctrl++;
          }
        }, error => {
          console.log(error)
        })        
      }, err => {
        console.log(err)
      })
    })
 }

  salvar(campo,valor){
     let dados = {
       [campo] : valor
    }
    let valorAntigo = this.backup[campo];
    if(typeof valorAntigo === 'object'){
      valorAntigo = this.backup[campo];
    }
    this.db.patchAssociado(this.idAssociado,dados).subscribe(res => {
      console.log(res);
      let jsonDate = new Date().toJSON();
      let hora = new Date(jsonDate).toLocaleTimeString();
      if(valorAntigo == true){
        valorAntigo = 'MARCADO'
      }
      if(valorAntigo == false){
        valorAntigo = 'DESMARCADO'
      }
      if(valor == true){
        valor = 'MARCADO'
      }
      if(valor == false){
        valor = 'DESMARCADO'
      }
      if(campo == "doenca" && valor == false){
        let bd = {
          especificarDoenca : null
        }
        this.db.patchAssociado(this.idAssociado,bd).subscribe(res => {
          console.log(res);
          }, err => {
            console.log(err);
          })
       }
      let body = {
        "menu" : "EDITAR ASSOCIADOS", 
        "descricao" : "MATRICULA: "+this.matriculaEditar+" - ASSOCIADO: "+this.nomeEditar+" - Campo "+campo+" alterado de: "+valorAntigo+" para: "+valor, 
        "data" : this.dataHoje, 
        "hora" : hora, 
        "usuario" : this.user
      }
      this.db.postLogs(body).subscribe(result => {
        console.log(result);
        this.backup[campo] = valor;
        if(campo == 'observacaoPendente'){
          let dados = {
            "data" : this.dataHoje, 
            "hora" : hora, 
            "motivo" : "CADASTRO PENDENTE", 
            "msg" : this.observacaoPendenteEditar, 
            "usuario" : localStorage.getItem('usuarioVivamax')
          }
          this.db.newHistorico(this.idAssociado,dados).subscribe(res => console.log(res),err => console.log(err))
        }
      }, error => {
        console.log(error)
      }) 
    }, err => {
      console.log(err)
    })
  }

  mudarStatus(status){
    let jsonDate = new Date().toJSON();
    let hora = new Date(jsonDate).toLocaleTimeString();
    let dados:any;
    if(this.situacaoOriginal == "PENDENTE" && status == "ATIVO"){
      dados = {
        "situacao": status,
        "dataAdesao": this.dataHoje
      }
      if(this.empresaAssociado == "CONECTA"){
        this.enviarDoc24();
      }
      if(this.planoEditar.indexOf("PLUS") >= 0 || this.localTrabalhoEditar.indexOf("APCS") >= 0){
        this.db.getSorteLivre().subscribe((dataNum:any) => {
          let idNumSorte = dataNum[0]._id;
          dados.numSorte = dataNum[0].numero;
          this.db.patchSorte(idNumSorte,{usado:true}).subscribe(resN => {
            console.log(resN)
          }, errN => {console.log(errN)})
        })
      }
      if(this.motivoPendenciaEditar == "AGUARDANDO CADASTRO NA VINDI/CYCLOPAY"){
        this.db.enviarEmailBoasVindas("vivamax",this.nomeEditar,this.emailEditar);
      }
    }else if(this.situacaoOriginal == "CANCELADO" && status == "ATIVO"){
      dados = {
        motivoCancelamento : "",
        observacoesCancelamento : "",
        situacao : "ATIVO",
        dataCancelamento: ""
      }
    }else if(this.situacaoOriginal == "ATIVO" && status == "ALTERAÇÃO"){
      dados = {
        "situacao": status,
        "dataAlteracao":this.dataHoje
      }
      let random = Math.random().toString(36).slice(-8);
      this.db.getConfigs().subscribe((x:any) => {
        this.emails = x[23].emails;
      let idBoletoVindi:any = "";
      let responsavelBoletoVindi:any = "";
      let idxBoletoVindi = this.emails.map((e:any) => {
        return JSON.stringify(Object.keys(e));
      }).indexOf('["boletoVindi"]');
      if(idxBoletoVindi >= 0){
        let dadosBoletoVindi:any = Object.values(this.emails[idxBoletoVindi])[0];
        idBoletoVindi = dadosBoletoVindi.idResponsavel;
        responsavelBoletoVindi = dadosBoletoVindi.nomeResponsavel;
      }
      let objDados = {
        "id": random,
        "data" : this.dataHoje,
        "hora" : hora,
        "titulo" : "VERIFICAR FORMA DE PAGAMENTO",
        "descricao" : "Situação: ALTERAÇÃO - Associado: "+ this.nomeEditar + " - Matricula: "+this.matriculaEditar,
        "idResponsavel" : idBoletoVindi,
        "responsavel" : responsavelBoletoVindi,
        "resolvido":false,
        "dataResolucao" : "",
        "horaResolucao" : "",
        "prioridade" : "ALTA",
        "origem":"Sistema",
        "idUserSolicitacao":localStorage.getItem('idUserVivamax'),
        "nomeSolicitante":localStorage.getItem('usuarioVivamax')
      }
      this.db.postTarefa(objDados).subscribe(res => console.log(res),err => console.log(err));
    })
    }else{
      dados = {
        "situacao": status
      }
    }
    this.situacaoOriginal = status;
    let dadosHist = {
      "data" : this.dataHoje, 
      "hora" : hora, 
      "motivo" : "ALTERADO SITUAÇÃO", 
      "msg" : "ASSOCIADO ALTERADO DE "+this.situacaoOriginal+" PARA "+status, 
      "usuario" : localStorage.getItem('usuarioVivamax')
    }
    this.db.patchAssociado(this.idAssociado,dados).subscribe(res => {
      console.log(res);
      let body = {
        "menu" : "EDITAR ASSOCIADOS", 
        "descricao" : this.nomeEditar+" - Status alterado para: "+status, 
        "data" : this.dataHoje, 
        "hora" : hora, 
        "usuario" : this.user
      }
      this.db.postLogs(body).subscribe(result => {
        console.log(result);
        this.backup.situacao = status;
        this.situacao = status;
        this.db.newHistorico(this.idAssociado,dadosHist).subscribe(res => console.log(res),err => console.log(err));
      }, error => {
        console.log(error)
      }) 
    }, err => {
      console.log(err)
    })
  }

  editarPlanoEmpresarial(){
    this.db.getSorteLivre().subscribe((dataNum:any) => {
      let dependentes = this.dependentesEditar.value.dependenteEditar;
      let dependentesExtra = this.dependentesExtraEditar.value.dependenteExtraEditar;
      let idNumSorte = dataNum[0]._id;
        if(!this.numSorte && (this.plano.indexOf("PLUS") >= 0 || this.localTrabalho.indexOf("APCS") >= 0)){
          this.numSorte = dataNum[0].numero;
          if(dependentes.length > 0){
            for(let i = 0;dependentes.length < i;i++){
              if(dependentes[i].asf == "FUNERAL CONTRATADO" || dependentes[i].asf == "FUNERAL AUTOMÁTICO VITALÍCIO" || dependentes[i].asf == "FUNERAL AUTOMÁTICO ATÉ 18 ANOS"){
                dependentes[i].numSorte = dataNum[i+3].numero;
                this.db.patchSorte(dataNum[i+3]._id,{usado:true}).subscribe(resN => {
                  console.log(resN)
                }, errN => {console.log(errN)})
              }
            }
          }
          if(dependentesExtra.length > 0){
              for(let iE = 0;dependentesExtra.length < iE;iE++){
                if(dependentesExtra[iE].asf == "FUNERAL CONTRATADO" || dependentesExtra[iE].asf == "AUTOMÁTICO SEM CUSTO"){
                  dependentesExtra[iE].numSorte = dataNum[iE+13].numero;
                  this.db.patchSorte(dataNum[iE+3]._id,{usado:true}).subscribe(resN => {
                    console.log(resN)
                  }, errN => {console.log(errN)})
                }
              }
          }
        }
      let planoHibrido:any = this.planoEditar.indexOf('SMART') >= 0 ? true : false;
      let dados = {
        plano : this.planoEditar,
        hibrido: planoHibrido,
        valorCapitalSegurado: this.valorCapitalSeguradoEditar,
        mensalidade:Number(this.mensalidadeEditar),
        totalMensalidade:this.totalMensalidadeEditar,
        codigoEmpresa: this.codigoEmpresa,
        formaPagamento: "EMPRESARIAL",
        subContrato:"",
        planoFisicoCheck:false,
        planoEmpresarialCheck:true,
        localTrabalho: this.empresa.razaoEmpresa,
        numSorte:this.numSorte,
        dependentes: dependentes,
        dependentesExtra: dependentesExtra
      };
      this.db.patchAssociado(this.idAssociado,dados).subscribe(res => {
        console.log(res);
        let jsonDate = new Date().toJSON();
        let hora = new Date(jsonDate).toLocaleTimeString();
        let body = {
          "menu" : "EDITAR ASSOCIADOS", 
          "descricao" : "MATRICULA: "+this.matriculaEditar+" - ASSOCIADO: "+this.nomeEditar+" - Plano Alterado", 
          "data" : this.dataHoje, 
          "hora" : hora, 
          "usuario" : this.user
        }
        this.db.postLogs(body).subscribe(res => {
          console.log(res);
          this.db.patchSorte(idNumSorte,{usado:true}).subscribe(resN => {
            console.log(resN);
            this.app.openAlert("Plano Alterado com Sucesso!")
          }, errN => {console.log(errN)})
        },err => console.log(err))
      })
    })
  }

  alterarPlano(){
    let dependentes = this.dependentesEditar.value.dependenteEditar;
    let dependentesExtra = this.dependentesExtraEditar.value.dependenteExtraEditar;
    let controlDependentes = 1;
    let controlDependentesExtra = 1;
    let totalAsfDependentes = 0;
    let totalAsfDependentesExtras = 0;
    if(dependentes.length > 0){
        dependentes.forEach((item:any) => {
          if(item.tipo == "CÔNJUGE"){
            if(this.funeralConjugeEditar == "AUTOMATICO"){
              item.asf = "FUNERAL AUTOMÁTICO VITALÍCIO"
            }
          }else{
            if(this.funeralFilhosMaiorEditar == "AUTOMATICO"){
              item.asf = "FUNERAL AUTOMÁTICO VITALÍCIO"
            }
          }
        if(item.asf == "FUNERAL CONTRATADO" && item.tipo != "CÔNJUGE" && item.situacao == "ATIVO"){
          if(this.funeralFilhosMaiorEditar == "SIM"){
            totalAsfDependentes += this.valorFuneralFilhosMaiorEditar;
          }
        }
        if(item.asf == "FUNERAL CONTRATADO" && item.tipo == "CÔNJUGE" && item.situacao == "ATIVO"){
          if(this.funeralConjugeEditar == "SIM"){
            totalAsfDependentes += this.valorFuneralConjugeEditar;
          }
        }
        if(controlDependentes == dependentes.length){
          if(dependentesExtra.length > 0){
              dependentesExtra.forEach((itemExtra:any) => {
                if(this.funeralExtraEditar == "AUTOMATICO"){
                  itemExtra.asf = "AUTOMÁTICO SEM CUSTO"
                }
              if(itemExtra.asf == "FUNERAL CONTRATADO" && item.situacao == "ATIVO"){ 
                totalAsfDependentesExtras += this.valorFuneralExtraEditar;
              }
              if(controlDependentesExtra == dependentesExtra.length){
                this.totalMensalidadeEditar = totalAsfDependentes + totalAsfDependentesExtras + this.mensalidadeEditar;
              }else{
                controlDependentesExtra++;
              }
            })
          }else{
            this.totalMensalidadeEditar = totalAsfDependentes + this.mensalidadeEditar;
          }
        }else{
          controlDependentes++;
        }
      })
    }else{
      if(dependentesExtra.length > 0){
        dependentesExtra.forEach((itExtra:any) => {
          if(this.funeralExtraEditar == "AUTOMATICO"){
            itExtra.asf = "AUTOMÁTICO SEM CUSTO"
          }
        if(itExtra.asf == "FUNERAL CONTRATADO" && itExtra.situacao == "ATIVO"){
          totalAsfDependentesExtras += this.valorFuneralExtraEditar;
        }
        if(controlDependentesExtra == dependentesExtra.length){
          this.totalMensalidadeEditar = totalAsfDependentesExtras + this.mensalidadeEditar;
        }else{
          controlDependentesExtra++;
        }
      })
    }else{
        this.totalMensalidadeEditar = this.mensalidadeEditar;
      }
    }
  }

  editarPlano(){
    let dependentes:any;
    let dependentesExtra:any;
    this.db.getSorteLivre().subscribe((dataNum:any) => {
      if (this.mudancaDeConecta) {
        let dep = this.dependentesEditar.value.dependenteEditar;
        let depExtra = this.dependentesExtraEditar.value.dependenteExtraEditar;
        dep.forEach(dependente => {
          dependente.conecta = false;
        });
        depExtra.forEach(dependenteExtra => {
          dependenteExtra.conecta = false;
        });
        this.dependentesEditar.setValue({ dependenteEditar: dep });
        this.dependentesExtraEditar.setValue({ dependenteExtraEditar: depExtra });
        dependentes = this.dependentesEditar.value.dependenteEditar;
        dependentesExtra = this.dependentesExtraEditar.value.dependenteExtraEditar;
      }else{
        dependentes = this.dependentesEditar.value.dependenteEditar;
        dependentesExtra = this.dependentesExtraEditar.value.dependenteExtraEditar;
      }
      let idNumSorte = dataNum[0]._id;
        if(!this.numSorte && (this.plano.indexOf("PLUS") >= 0 || this.localTrabalho.indexOf("APCS") >= 0)){
          this.numSorte = dataNum[0].numero;
          if(dependentes.length > 0){
            for(let i = 0;dependentes.length < i;i++){
              if(dependentes[i].asf == "FUNERAL CONTRATADO" || dependentes[i].asf == "FUNERAL AUTOMÁTICO VITALÍCIO" || dependentes[i].asf == "FUNERAL AUTOMÁTICO ATÉ 18 ANOS"){
                dependentes[i].numSorte = dataNum[i+3].numero;
                this.db.patchSorte(dataNum[i+3]._id,{usado:true}).subscribe(resN => {
                  console.log(resN)
                }, errN => {console.log(errN)})
              }
            }
          }
          if(dependentesExtra.length > 0){
              for(let iE = 0;dependentesExtra.length < iE;iE++){
                if(dependentesExtra[iE].asf == "FUNERAL CONTRATADO" || dependentesExtra[iE].asf == "AUTOMÁTICO SEM CUSTO"){
                  dependentesExtra[iE].numSorte = dataNum[iE+13].numero;
                  this.db.patchSorte(dataNum[iE+3]._id,{usado:true}).subscribe(resN => {
                    console.log(resN)
                  }, errN => {console.log(errN)})
                }
              }
          }
          this.db.patchSorte(idNumSorte,{usado:true}).subscribe(resN => {
            console.log(resN);
          }, errN => {console.log(errN)})
        }
        if(this.mudancaParaConecta){
          this.numSorte = null;
          dependentes = [];
          dependentesExtra = [];
        }
    let planoHibrido:any = this.planoEditar.indexOf('SMART') >= 0 ? true : false;
    let nrCartao:any = (this.numeroCartaoEditar && this.numeroCartaoEditar.indexOf("*") >= 0) ? this.numeroCartaoOriginal : this.numeroCartaoEditar;
    let nrSeguranca:any = (this.codigoCartaoEditar && this.codigoCartaoEditar.indexOf("*") >= 0) ? this.codigoCartaoOriginal : this.codigoCartaoEditar;
    let dados = {
      planoFisicoCheck : this.planoFisicoCheckEditar,
      planoEmpresarialCheck: this.planoEmpresarialCheckEditar,
      subContrato :  this.subContratoEditar,
      plano : this.planoEditar,
      diaVencimento : this.diaVencimentoEditar,
      formaPagamento : this.formaPagamentoEditar,
      mesFolhaPagamento : this.mesFolhaPagamentoEditar,
      cartaoSelecionado : this.cartaoSelecionadoEditar,
      nomeCartao : this.nomeCartaoEditar,
      validadeCartao : this.validadeCartaoEditar,
      numeroCartao : nrCartao,
      codigoCartao : nrSeguranca,
      valorCapitalSegurado: this.valorCapitalSeguradoEditar,
      mensalidade:Number(this.mensalidadeEditar),
      totalMensalidade:this.totalMensalidadeEditar,
      taxaAdesao: this.taxaAdesaoEditar,
      dependentes: dependentes,
      dependentesExtra: dependentesExtra,
      valorPersonalizado:this.valorPersonalizado,
      codigoEmpresa:null,
      empresa:this.empresaAssociado,
      numSorte:this.numSorte,
      hibrido: planoHibrido
    };    
    this.db.patchAssociado(this.idAssociado,dados).subscribe(res => {
      console.log(res);
      if(this.backup.planoFisicoCheck != this.planoFisicoCheckEditar){this.salvar('planoFisicoCheck',this.planoFisicoCheckEditar)}
      if(this.backup.planoEmpresarialCheck != this.planoEmpresarialCheckEditar){this.salvar('planoEmpresarialCheck',this.planoEmpresarialCheckEditar)}
      if(this.backup.subContrato != this.subContratoEditar){this.salvar('subContrato',this.subContratoEditar)}
      if(this.backup.plano != this.planoEditar){this.salvar('plano',this.planoEditar)}
      if(this.backup.formaPagamento != this.formaPagamentoEditar){this.salvar('formaPagamento',this.formaPagamentoEditar)}
      if(this.backup.mesFolhaPagamento != this.mesFolhaPagamentoEditar){this.salvar('mesFolhaPagamento',this.mesFolhaPagamentoEditar)}
      if(this.backup.cartaoSelecionado != this.cartaoSelecionadoEditar){this.salvar('cartaoSelecionado',this.cartaoSelecionadoEditar)}
      if(this.backup.nomeCartao != this.nomeCartaoEditar){this.salvar('nomeCartao',this.nomeCartaoEditar)}
      if(this.backup.validadeCartao != this.validadeCartaoEditar){this.salvar('validadeCartao',this.validadeCartaoEditar)}
      if(this.backup.valorCapitalSegurado != this.valorCapitalSeguradoEditar){this.salvar('valorCapitalSegurado',this.valorCapitalSeguradoEditar)}
      if(this.backup.mensalidade != this.mensalidadeEditar){this.salvar('mensalidade',this.mensalidadeEditar)}
      if(this.backup.totalMensalidade != this.totalMensalidadeEditar){this.salvar('totalMensalidade',this.totalMensalidadeEditar)}
      if(this.backup.numeroCartao){if(this.backup.numeroCartao != this.numeroCartaoOriginal){this.salvar('numeroCartao',this.numeroCartaoEditar)}}
      if(this.backup.valorPersonalizado != this.valorPersonalizado){this.salvar('valorPersonalizado',this.valorPersonalizado)}
      if(this.backup.codigoCartao){if(this.backup.codigoCartao != this.codigoCartaoOriginal){this.salvar('codigoCartao',this.codigoCartaoEditar)}}
      if(this.backup.diaVencimento){if(Number(this.backup.diaVencimento != this.diaVencimentoEditar)){this.salvar('diaVencimento',this.diaVencimentoEditar)}}
      let jsonDate = new Date().toJSON();
      let hora = new Date(jsonDate).toLocaleTimeString();
      let body = {
        "menu" : "EDITAR ASSOCIADOS", 
        "descricao" : "MATRICULA: "+this.matriculaEditar+" - ASSOCIADO: "+this.nomeEditar+" - Plano Alterado", 
        "data" : this.dataHoje, 
        "hora" : hora, 
        "usuario" : this.user
      }
      this.db.postLogs(body).subscribe(res => {
        console.log(res);
        if(this.mudancaParaConecta){
         this.enviarDoc24();
         this.app.openAlert("Plano Alterado com Sucesso!");
       }
       if(this.mudancaDeConecta){
        this.cancelarTodosConecta();      
        this.db.patchSorte(idNumSorte,{usado:true}).subscribe(resN => {
        console.log(resN);
      }, errN => {console.log(errN)})
     }
      },err => console.log(err))
    })
  })
  }
  
calcularDependentes(){
  this.totalAsfDependentesEditar = 0;
  this.totalMensalidadeEditar = 0;
  let control = 1;
  if(this.dependentesEditar.value.dependenteEditar.length > 0){
      this.dependentesEditar.value.dependenteEditar.forEach((item:any) => {
      if(item.asf == "FUNERAL CONTRATADO" && item.situacao == "ATIVO"){
        this.totalAsfDependentesEditar += this.valorFuneralFilhosMaiorEditar;
      }
      if(control == this.dependentesEditar.value.dependenteEditar.length){
        let controlExtra = 1;
        if(this.dependentesExtraEditar.value.dependenteExtraEditar.length > 0){
          this.dependentesExtraEditar.value.dependenteExtraEditar.forEach((itemExtra) => {
            if(this.funeralExtraEditar == "SIM" && itemExtra.asf == "FUNERAL CONTRATADO" && itemExtra.situacao == "ATIVO"){
              this.totalAsfDependentesExtrasEditar += this.valorFuneralExtraEditar;
            }
            if(controlExtra == this.dependentesExtraEditar.value.dependenteExtraEditar.length){
              this.totalMensalidadeEditar = this.totalAsfDependentesEditar + this.totalAsfDependentesExtrasEditar + this.mensalidadeEditar;
            }else{
              controlExtra++
            }
          })
        }else{
          this.totalMensalidadeEditar = this.totalAsfDependentesEditar + this.totalAsfDependentesExtrasEditar + this.mensalidadeEditar;
        }
      }else{
        control++
      }
    })
  }else{
    let controlExtra = 1;
    if(this.dependentesExtraEditar.value.dependenteExtraEditar.length > 0){
      this.dependentesExtraEditar.value.dependenteExtraEditar.forEach((item) => {
        if(this.funeralExtraEditar == "SIM" && item.asf == "FUNERAL CONTRATADO" && item.situacao == "ATIVO"){
          this.totalAsfDependentesExtrasEditar += this.valorFuneralExtraEditar;
        }
        if(controlExtra == this.dependentesExtraEditar.value.dependenteExtraEditar.length){
          this.totalMensalidadeEditar = this.totalAsfDependentesEditar + this.totalAsfDependentesExtrasEditar + this.mensalidadeEditar;
        }else{
          controlExtra++
        }
      })
    }else{
      this.totalMensalidadeEditar = this.totalAsfDependentesEditar + this.totalAsfDependentesExtrasEditar + this.mensalidadeEditar;
    }
  }
}

salvarDependentes() {
  this.qtdeConecta = 0;
  this.db.getTokenDoc().subscribe((res: any) => {
    let tokenDoc: any = res.token;
    if (!tokenDoc) {
      this.app.openAlert("Erro ao obter token Doc24, favor contatar o suporte!");
      return;
    }
    this.totalAsfDependentesEditar = 0;
    this.totalMensalidadeEditar = 0;
    let observables = [];
    console.log(this.arrConecta)
    if (this.arrConecta.length > 0) {
      for (let x = this.arrConecta.length - 1; x >= 0; x--) {
        if(this.arrConecta[x].cpf != null){
          let cpfLimpo:any = this.app.limpaCPF(this.arrConecta[x].cpf);
          observables.push(
            this.db.delDoc24(tokenDoc, cpfLimpo).pipe(
              catchError(err => {
                console.log(err);
                return of(null);
              }),
              map((result: any) => {
                if (result?.mensaje) {
                  alert("Doc24: " + result.mensaje);
                } else {
                  this.arrConecta.splice(x, 1);
                }
              })
            )
          );
        }
      }
    }
    console.log(this.arrConectaExtra)

    if (this.arrConectaExtra.length > 0) {
      for (let x = this.arrConectaExtra.length - 1; x >= 0; x--) {
        let cpfLimpoExtra:any = this.app.limpaCPF(this.arrConectaExtra[x].cpf);
        observables.push(
          this.db.delDoc24(tokenDoc, cpfLimpoExtra).pipe(
            catchError(err => {
              console.log(err);
              return of(null);
            }),
            map((result: any) => {
              if (result?.mensaje) {
                alert("Doc24: " + result.mensaje);
              } else {
                this.arrConectaExtra.splice(x, 1);
              }
            })
          )
        );
      }
    }
    if (this.dependentesEditar.value.dependenteEditar.length > 0) {
      for (let i: any = 0; i < this.dependentesEditar.value.dependenteEditar.length; i++) {
        let item = this.dependentesEditar.value.dependenteEditar[i];
        if (item.conecta) {
          this.qtdeConecta++;
          let { firstName, lastName } = this.app.splitName(item.nome);
          let cpfLimpo: any = this.app.limpaCPF(item.cpf);
          let dataNascimento:any = item.nascimento ? item.nascimento.split('/').reverse().join('-') : "";
          let body: any = {"afiliado":{
            "nombre": firstName,
            "apellido": lastName,
            "sexo": item.sexo,
            "email": item.email,
            "fecha_nacimiento":dataNascimento,
            "nro_documento": cpfLimpo
          }};
          observables.push(
            this.db.postDoc24(tokenDoc, body).pipe(
              catchError(err => {
                console.log(err);
                return of(null);
              }),
              map((res: any) => {
                console.log(res);
                if (item.asf == "FUNERAL CONTRATADO" && item.situacao == "ATIVO") {
                  this.totalAsfDependentesEditar += this.valorFuneralFilhosMaiorEditar;
                }
              })
            )
          );
        }
      }
    }
    if (this.dependentesExtraEditar.value.dependenteExtraEditar.length > 0) {
      for (let i: any = 0; i < this.dependentesExtraEditar.value.dependenteExtraEditar.length; i++) {
        let itemExtra: any = this.dependentesExtraEditar.value.dependenteExtraEditar[i];
        if (itemExtra.conecta) {
          this.qtdeConecta++;
          let { firstName, lastName } = this.app.splitName(itemExtra.nome);
          let cpfLimpo: any = this.app.limpaCPF(itemExtra.cpf);
          let dataNascimentoExtra:any = itemExtra.nascimento ? itemExtra.nascimento.split('/').reverse().join('-') : "";
          let body: any = {"afiliado":{
            "nombre": firstName,
            "apellido": lastName,
            "sexo": itemExtra.sexo,
            "email": itemExtra.email,
            "fecha_nacimiento":dataNascimentoExtra,
            "nro_documento": cpfLimpo
          }}
          observables.push(
            this.db.postDoc24(tokenDoc, body).pipe(
              catchError(err => {
                console.log(err);
                return of(null);
              }),
              map((res: any) => {
                console.log(res);
                if (this.funeralExtraEditar == "SIM" && itemExtra.asf == "FUNERAL CONTRATADO" && itemExtra.situacao == "ATIVO") {
                  this.totalAsfDependentesExtrasEditar += this.valorFuneralExtraEditar;
                }
              })
            )
          );
        }
      }
    }
    if (observables.length > 0) {
      forkJoin(observables).subscribe(() => {
        this.patchDependentes();
      });
    } else {
      this.patchDependentes();
    }
  });
}

  patchDependentes(){
    this.totalValorConectaExtrasEditar = 0;
    if(this.qtdeConecta > this.conectaInclusosEditar){
      let diferenca:number = this.qtdeConecta - this.conectaInclusosEditar;
      this.totalValorConectaExtrasEditar = diferenca * this.valorConectaExtraEditar;
    }
    this.totalMensalidadeEditar = this.totalAsfDependentesEditar + this.totalAsfDependentesExtrasEditar + this.mensalidadeEditar + this.totalValorConectaExtrasEditar;
    let dados = {
      dependentes:this.dependentesEditar.get('dependenteEditar').value,
      dependentesExtra:this.dependentesExtraEditar.get('dependenteExtraEditar').value,
      mensalidade:Number(this.mensalidadeEditar),
      totalMensalidade:Number(this.totalMensalidadeEditar)
    }
    this.db.patchAssociado(this.idAssociado,dados).subscribe(res => {
      this.selectAsfEditar();
      this.selectAsfExtrasEditar();
      this.salvar('totalMensalidade',Number(this.totalMensalidadeEditar));
      console.log(res);
      let jsonDate = new Date().toJSON();
      let hora = new Date(jsonDate).toLocaleTimeString();
      let body = {
        "menu" : "EDITAR ASSOCIADOS", 
        "descricao" : "Dependentes Alterados do Cliente: "+this.nomeEditar, 
        "data" : this.dataHoje, 
        "hora" : hora, 
        "usuario" : this.user
      }
      this.db.postLogs(body).subscribe(result => {
        console.log(result)
        this.db.getAssociadosById(this.idAssociado).subscribe((data:any) => {
          let indice = this.associados.map((item) => {
            return item._id
          }).indexOf(data._id)
          this.associados[indice] = data;
          this.backup = data;
          this.listaDeDependentesEditar.clear();
          this.listaDeDependentesExtraEditar.clear();
          this.openModalEditar(data, "")
          this.app.openAlert("Atualizado com Sucesso!");
        })
      }, error => {
        console.log(error)
      }) 
    }, err => {
      console.log(err)
    })
}

criarAgendamento(){
  if(window.confirm("Deseja realmente cadastrar esse Agendamento?")){
    let idRandom = Math.random().toString(32).substr(4, 14);
    let dados = {
      id : idRandom,
      dataAgendamento: this.dataHoje,
      pacienteMedico : this.pacienteMedico,
      telefoneContatoMedico : this.telefoneContatoMedico,
      whatsappContatoMedico : this.whatsappContatoMedico,
      telefoneRecadoMedico: this.telefoneRecadoMedico,
      whatsappRecadoMedico : this.whatsappRecadoMedico,
      guiaSusMedico : this.guiaSusMedico,
      procedimentoMedico : this.procedimentoMedico,
      especialidadeMedico : this.especialidadeMedico,
      medicoPreferencialMedico : this.medicoPreferencialMedico,
      medicoPreferencia : this.medicoPreferencia,
      primeiraDataMedico : this.primeiraDataMedico,
      primeiroPeriodoMedico : this.primeiroPeriodoMedico,
      segundaDataMedico : this.segundaDataMedico,
      segundoPeriodoMedico : this.segundoPeriodoMedico,
      laboratorioMedico : this.laboratorioMedico,
      telefoneMedico : this.telefoneMedico,
      secretariaMedico : this.secretariaMedico,
      jaEPacienteMedico : this.jaEPacienteMedico,
      agendadoParaMedico : this.agendadoParaMedico,
      horarioMedico : this.horarioMedico,
      guiaEmitidaMedico : this.guiaEmitidaMedico,
      levarGuiaMedico : this.levarGuiaMedico,
      profissionalMedico: this.profissionalMedico,
      retiraGuiaMedico : this.retiraGuiaMedico,
      semGuiaMedico: this.semGuiaMedico,
      observacaoMedico : this.replaceStrings(this.observacaoMedico),
      finalizadoMedico : this.finalizadoMedico,
      valorConsulta: this.valorConsultaMedica,
      formaPagamento: this.formaPagamentoMedico,
      precisaTroco: this.precisaTrocoMedico,
      trocoQuanto: this.trocoMedico
     }
     this.db.newAgendamento(this.idAssociado,dados).subscribe(res => {
      this.agendamentos.unshift(dados)
       console.log(res);
       this.app.openAlert("Agendamento Cadastrado com Sucesso!");
       this.motivo = "";
       this.limparAgendamentoMedico();
     }, err => {
       console.log(err);
     })
    }
  }

  limparAgendamentoMedico(){
      this.pacienteMedico = "";
      this.telefoneContatoMedico = "";
      this.telefoneRecadoMedico = "";
      this.whatsappContatoMedico = false;
      this.whatsappRecadoMedico = false;
      this.guiaSusMedico = "";
      this.procedimentoMedico = "";
      this.especialidadeMedico = "";
      this.medicoPreferencialMedico = "";
      this.medicoPreferencia = "";
      this.primeiraDataMedico = "";
      this.primeiroPeriodoMedico = "";
      this.segundaDataMedico = "";
      this.segundoPeriodoMedico = "";
      this.laboratorioMedico = "";
      this.telefoneMedico = "";
      this.secretariaMedico = "";
      this.jaEPacienteMedico = "";
      this.agendadoParaMedico = "";
      this.horarioMedico = "";
      this.guiaEmitidaMedico = "";
      this.levarGuiaMedico = false;
      this.retiraGuiaMedico = false;
      this.profissionalMedico = "";
      this.semGuiaMedico = false;
      this.observacaoMedico = "";
      this.dataAgendamento = "";
      this.novoAgendamento = false;
      this.finalizadoMedico = "";
      this.valorConsultaMedica = null;
      this.formaPagamentoConsulta = "";
      this.precisaTrocoMedico = "";
      this.trocoMedico = null;
  }

  editarAgenda(agenda){
    this.idAgendamento = agenda.id;
    this.dataAgendamentoEditar = agenda.dataAgendamento,
    this.pacienteMedicoEditar = agenda.pacienteMedico;
    this.telefoneContatoMedicoEditar = agenda.telefoneContatoMedico;
    this.telefoneRecadoMedicoEditar = agenda.telefoneRecadoMedico;
    this.whatsappContatoMedicoEditar = agenda.whatsappContatoMedico;
    this.whatsappRecadoMedicoEditar = agenda.whatsappRecadoMedico;
    this.guiaSusMedicoEditar = agenda.guiaSusMedico;
    this.procedimentoMedicoEditar = agenda.procedimentoMedico;
    this.especialidadeMedicoEditar = agenda.especialidadeMedico;
    this.medicoPreferencialMedicoEditar = agenda.medicoPreferencialMedico;
    this.medicoPreferenciaEditar = agenda.medicoPreferencia;
    this.primeiraDataMedicoEditar = agenda.primeiraDataMedico;
    this.primeiroPeriodoMedicoEditar = agenda.primeiroPeriodoMedico;
    this.segundaDataMedicoEditar = agenda.segundaDataMedico;
    this.segundoPeriodoMedicoEditar = agenda.segundoPeriodoMedico;
    this.laboratorioMedicoEditar = agenda.laboratorioMedico;
    this.profissionalMedicoEditar = agenda.profissionalMedico;
    this.telefoneMedicoEditar = agenda.telefoneMedico;
    this.secretariaMedicoEditar = agenda.secretariaMedico;
    this.jaEPacienteMedicoEditar = agenda.jaEPacienteMedico;
    this.agendadoParaMedicoEditar = agenda.agendadoParaMedico;
    this.horarioMedicoEditar = agenda.horarioMedico;
    this.guiaEmitidaMedicoEditar = agenda.guiaEmitidaMedico;
    this.levarGuiaMedicoEditar = agenda.levarGuiaMedico;
    this.semGuiaMedicoEditar = agenda.semGuiaMedico;
    this.retiraGuiaMedicoEditar = agenda.retiraGuiaMedico;
    this.observacaoMedicoEditar = this.reverseReplaceStrings(agenda.observacaoMedico);
    this.finalizadoMedicoEditar = agenda.finalizadoMedico;
    this.formaPagamentoMedicoEditar = agenda.formaPagamento;
    this.precisaTrocoMedicoEditar = agenda.precisaTroco;
    this.valorConsultaMedicaEditar = agenda.valorConsulta;
    if((this.valorConsultaMedicaEditar && typeof this.valorConsultaMedicaEditar != 'number') || this.valorConsultaMedicaEditar == Number.NaN ){
      this.valorConsultaMedicaEditar =agenda.valorConsulta;
    }
    this.trocoMedicoEditar = agenda.trocoQuanto;
    if((this.trocoMedicoEditar && typeof this.trocoMedicoEditar != 'number') || this.trocoMedicoEditar == Number.NaN ){
      this.trocoMedicoEditar =agenda.trocoQuanto;
    }
    let modalEditAgenda = document.getElementById('modalEditarAgendamento');
    modalEditAgenda.style.display = "block";
    let modal = document.getElementById('modalEditar');
    modal.style.display = "none"
  }

  fecharAgenda(){
    this.idAgendamento = "";
    this.dataAgendamentoEditar = "";
    this.pacienteMedicoEditar = "";
    this.telefoneContatoMedicoEditar = "";
    this.telefoneRecadoMedico = "";
    this.whatsappContatoMedico = false;
    this.whatsappRecadoMedico = false;
    this.guiaSusMedicoEditar = "";
    this.procedimentoMedicoEditar = "";
    this.especialidadeMedicoEditar = "";
    this.medicoPreferencialMedicoEditar = "";
    this.medicoPreferenciaEditar = "";
    this.primeiraDataMedicoEditar = "";
    this.primeiroPeriodoMedicoEditar = "";
    this.segundaDataMedicoEditar = "";
    this.segundoPeriodoMedicoEditar = "";
    this.laboratorioMedicoEditar = "";
    this.profissionalMedicoEditar = "";
    this.telefoneMedicoEditar = "";
    this.secretariaMedicoEditar = "";
    this.jaEPacienteMedicoEditar = "";
    this.agendadoParaMedicoEditar = "";
    this.horarioMedicoEditar = "";
    this.guiaEmitidaMedicoEditar = "";
    this.levarGuiaMedicoEditar = false;
    this.semGuiaMedicoEditar = false;
    this.retiraGuiaMedicoEditar = false;
    this.observacaoMedicoEditar = "";
    let modalEditAgenda = document.getElementById('modalEditarAgendamento');
    modalEditAgenda.style.display = "none";
    let modal = document.getElementById('modalEditar');
    modal.style.display = "block"
  }

  excluirAgenda(agenda){
    if(window.confirm("Deseja realmente excluir esse Agendamento?")){
      let jsonDate = new Date().toJSON();
      let hora = new Date(jsonDate).toLocaleTimeString();
      this.db.deleteAgenda(this.idAssociado,agenda.id).subscribe(res => {
        console.log(res);
        let index = this.agendamentos.map((item) => {
          return item.id
        }).indexOf(agenda.id)
       this.agendamentos.splice(index, 1);
       let body = {
        "menu" : "AGENDAMENTO", 
        "descricao" : "Agendamento excluido do Associado: "+agenda.pacienteMedico, 
        "data" : this.dataHoje, 
        "hora" : hora, 
        "usuario" : this.user
      }
      this.db.postLogs(body).subscribe(res => {
        console.log(res);
        this.app.openAlert("Agendamento Excluido com Sucesso!")
      },err=>{ 
        console.log(err)
      })
      },err=> {
        console.log(err)
      })
    }
  }
 
editarAgendamento(){
  if(window.confirm("Deseja realmente alterar esse Agendamento?")){
    let dados = {
      id:this.idAgendamento,
      pacienteMedico : this.pacienteMedicoEditar,
      dataAgendamento : this.dataAgendamentoEditar,
      guiaSusMedico : this.guiaSusMedicoEditar,
      procedimentoMedico : this.procedimentoMedicoEditar,
      especialidadeMedico : this.especialidadeMedicoEditar,
      medicoPreferencialMedico : this.medicoPreferencialMedicoEditar,
      medicoPreferencia : this.medicoPreferenciaEditar,
      primeiraDataMedico : this.primeiraDataMedicoEditar,
      primeiroPeriodoMedico : this.primeiroPeriodoMedicoEditar,
      segundaDataMedico : this.segundaDataMedicoEditar,
      segundoPeriodoMedico : this.segundoPeriodoMedicoEditar,
      laboratorioMedico : this.laboratorioMedicoEditar,
      profissionalMedico : this.profissionalMedicoEditar,
      telefoneMedico : this.telefoneMedicoEditar,
      telefoneRecadoMedico: this.telefoneRecadoMedicoEditar,
      whatsappRecadoMedico : this.whatsappRecadoMedicoEditar,
      telefoneContatoMedico : this.telefoneContatoMedicoEditar,
      whatsappContatoMedico : this.whatsappContatoMedicoEditar,
      secretariaMedico : this.secretariaMedicoEditar,
      jaEPacienteMedico : this.jaEPacienteMedicoEditar,
      agendadoParaMedico : this.agendadoParaMedicoEditar,
      horarioMedico : this.horarioMedicoEditar,
      guiaEmitidaMedico : this.guiaEmitidaMedicoEditar,
      levarGuiaMedico : this.levarGuiaMedicoEditar,
      semGuiaMedico : this.semGuiaMedicoEditar,
      retiraGuiaMedico : this.retiraGuiaMedicoEditar,
      observacaoMedico : this.replaceStrings(this.observacaoMedicoEditar),
      formaPagamento : this.formaPagamentoMedicoEditar,
      precisaTrocoMedico : this.precisaTrocoMedicoEditar,
      trocoMedico : this.trocoMedicoEditar,
      valorConsulta : this.valorConsultaMedicaEditar
     }
     this.db.patchAgendamento(this.idAssociado, this.idAgendamento, dados).subscribe(res => {
       console.log(res);
       this.app.openAlert("Agendamento Editado com Sucesso!")
       let i = this.agendamentos.map((item) => {
         return item.id
       }).indexOf(this.idAgendamento);
       this.agendamentos[i] = dados;
       this.limparAgendamentoMedico();
       let modalEditAgenda = document.getElementById('modalEditarAgendamento');
       modalEditAgenda.style.display = "none";
       let modal = document.getElementById('modalEditar');
       modal.style.overflow = "auto"
     }, err => {
       console.log(err);
     })
    }
  }

  checkMotivo(motivo){
    if(motivo == "AGENDAMENTO"){
      this.mensagem = "";
      this.novoAgendamento = true
    }else{
      this.limparAgendamentoMedico();
      this.novoAgendamento = false;
    }
  }

openSinistro(tipo,sinistrado){
  if((this.sinistrado.nome) == sinistrado.nome){
    sinistrado = this.sinistrado;
  }else{
    this.sinistrado = sinistrado
  }
  this.nomeSinistro = sinistrado.nome;
  if(tipo == 'titular'){
    this.dependenteTipo = tipo;
  }
  if(tipo == 'dependente'){
    if(sinistrado.tipo == "CÔNJUGE" ||sinistrado.tipo == "FILHA" || sinistrado.tipo == "FILHO" ){
      this.dependenteTipo = 'direto';
    }else{
      this.dependenteTipo = 'extra';
    }
  }
  if(sinistrado.sinistro != null && sinistrado.sinistro != ''){
    this.novoSinistro = false;
    this.editSinitro = true;
    this.dataSinistro =  sinistrado.sinistro.dataSinistro;
    this.numeroSinistro = sinistrado.sinistro.numeroSinistro;
      if((this.numeroSinistro && typeof this.numeroSinistro != 'number') || this.numeroSinistro == Number.NaN ){
        this.numeroSinistro =sinistrado.sinistro.numeroSinistro;
      }
      this.valorSinistro = sinistrado.sinistro.valorSinistro;
      if(this.valorSinistro && typeof this.valorSinistro != 'number'){
        this.valorSinistro =sinistrado.sinistro.valorSinistro;
      }
    this.dataIndenizacao = sinistrado.sinistro.dataIndenizacao;
    this.indenizacaoSinistro = sinistrado.sinistro.indenizacaoSinistro;
    this.assistenciaSinistro = sinistrado.sinistro.assistenciaSinistro;
    this.servicosSinistro = sinistrado.sinistro.servicosSinistro;
    this.qualIndenizacaoSinistro = sinistrado.sinistro.qualIndenizacaoSinistro;
    this.qualAssistenciaSinistro = sinistrado.sinistro.qualAssistenciaSinistro;
    this.qualServicosSinistro = sinistrado.sinistro.qualServicosSinistro;
    this.situacaoSinistro = sinistrado.sinistro.situacao;
    this.tipoSinistrado = sinistrado.tipoSinistrado;
    this.docsSinistro = sinistrado.sinistro.documentos;
    this.pdfsSinistro = [];
    this.imagesSinistro = [];
    if(this.docsSinistro && this.docsSinistro.length > 0){
        this.docsSinistro.forEach((item) => {
         let ext = item.url.split('.').pop();
          if(ext == 'pdf' || ext == 'PDF'){
            this.pdfsSinistro.push(item);
          }else{
            this.imagesSinistro.push(item);
          }
        })
    }
    if(sinistrado.sinistro.historicos.length > 0){
      this.sinistroHistoricos = sinistrado.sinistro.historicos;
    }else{
      this.sinistroHistoricos = [];
    }
  }else{
    this.novoSinistro = true;
    this.editSinitro = true;
  }
}

  cadastrarSinistro(tipo){
    let msg:any = "";
    let dados:any = {};
    let titulo:any = "";
    this.db.getConfigs().subscribe((x:any) => {
      this.emails = x[23].emails;
    if(tipo == 'novo'){
      msg = "SINISTRO CADASTRADO: "+this.nomeSinistro;
      dados = {
        dataSinistro: this.dataSinistro,
        numeroSinistro: this.numeroSinistro,
        valorSinistro: this.valorSinistro,
        dataIndenizacao: this.dataIndenizacao,
        indenizacaoSinistro: this.indenizacaoSinistro,
        assistenciaSinistro: this.assistenciaSinistro,
        servicosSinistro: this.servicosSinistro,
        qualIndenizacaoSinistro: this.qualIndenizacaoSinistro,
        qualAssistenciaSinistro: this.qualAssistenciaSinistro,
        qualServicosSinistro: this.qualServicosSinistro,
        situacao:this.situacaoSinistro,
        tipoSinistrado:this.tipoSinistrado,
        historicos: [],
        documentos: []
      }
      titulo = "CADASTRO DE SINISTRO";
    }
    if(tipo == 'editar'){
      msg = "SINISTRO EDITADO: "+this.nomeSinistro;
      dados = {
        dataSinistro: this.dataSinistro,
        numeroSinistro: this.numeroSinistro,
        valorSinistro: this.valorSinistro,
        dataIndenizacao: this.dataIndenizacao,
        indenizacaoSinistro: this.indenizacaoSinistro,
        assistenciaSinistro: this.assistenciaSinistro,
        servicosSinistro: this.servicosSinistro,
        qualIndenizacaoSinistro: this.qualIndenizacaoSinistro,
        qualAssistenciaSinistro: this.qualAssistenciaSinistro,
        qualServicosSinistro: this.qualServicosSinistro,
        historicos: this.sinistroHistoricos,
        situacao:this.situacaoSinistro,
        documentos:this.docsSinistro
      }
      titulo = "SINISTRO EDITADO";
    }
    this.db.cadastrarSinistro(this.idAssociado, this.dependenteTipo, this.nomeSinistro, dados).subscribe(res => {
      console.log(res);
      let jsonDate = new Date().toJSON();
      let hora = new Date(jsonDate).toLocaleTimeString();
      let histDados = {
        "data" : this.dataHoje, 
        "hora" : hora, 
        "motivo" : "CADASTRO DE SINISTRO", 
        "msg" : msg, 
        "usuario" : localStorage.getItem('usuarioVivamax')
      }
      this.db.newHistorico(this.idAssociado,histDados).subscribe(res => {
        console.log(res);
        this.historicos.unshift(histDados);
        let responsavelBoletoVindi:any = "";
        let idBoletoVindi:any = "";
        let idxBoletoVindi = this.emails.map((e:any) => {
          return JSON.stringify(Object.keys(e));
        }).indexOf('["boletoVindi"]');
        if(idxBoletoVindi >= 0){
          let dadosBoletoVindi:any = Object.values(this.emails[idxBoletoVindi])[0];
          idBoletoVindi = dadosBoletoVindi.idResponsavel;
          responsavelBoletoVindi = dadosBoletoVindi.nomeResponsavel;
        }
        let randomGeraLink = Math.random().toString(36).slice(-8);
        let taskSinistro = {
          "id": randomGeraLink,
          "data" : this.dataHoje,
          "hora" : hora,
          "titulo" : titulo,
          "descricao" : "Associado: "+ this.nomeEditar + " - Matricula: "+this.matriculaEditar+". Sinistro: "+this.nomeSinistro,
          "idResponsavel" : idBoletoVindi,
          "responsavel" : responsavelBoletoVindi,
          "resolvido":false,
          "dataResolucao" : "",
          "horaResolucao" : "",
          "prioridade" : "ALTA",
          "origem":"Sistema",
          "idUserSolicitacao":localStorage.getItem('idUserVivamax'),
          "nomeSolicitante":localStorage.getItem('usuarioVivamax')
        }
        this.db.postTarefa(taskSinistro).subscribe(res => console.log(res),err => console.log(err));
      }, err => {
        console.log(err);
      })
      if(this.dependenteTipo != 'titular'){
         let index = this.todosDependentes.map((item) => {
          return item.nome;
        }).indexOf(this.nomeSinistro);
        if(index >= 0){
          this.todosDependentes[index].sinistro = dados;
          let idxArray = 0;
          if(this.backup.dependentes && this.backup.dependentes.length > 0){
            this.backup.dependentes.forEach((obj) => {
              if(obj.nome == this.nomeSinistro){
                this.backup.dependentes[idxArray].sinistro = dados;
              }
              if(this.backup.dependentes.length == idxArray+1){
                let idxArrayExtra = 0;
                if(this.backup.dependentesExtra && this.backup.dependentesExtra.length > 0){
                this.backup.dependentesExtra.forEach((ob) => {
                  if(ob.nome == this.nomeSinistro){
                    this.backup.dependentesExtra[idxArrayExtra].sinistro = dados;
                  }
                  if(this.backup.dependentesExtra.length == idxArrayExtra+1){            
                    this.closeSinistro();
                    this.openModalEditar(this.backup,'sinistro');
                    this.app.openAlert("Sinistro Cadastrado com Sucesso!");
                  }else{
                    idxArrayExtra++
                  }
                })
              }else{
                this.closeSinistro();
                this.openModalEditar(this.backup,'sinistro');
                this.app.openAlert("Sinistro Cadastrado com Sucesso!");
              }
              }else{
                idxArray++
              }
            })
          }else{
            if(this.backup.dependentesExtra && this.backup.dependentesExtra.length > 0){
                  let idxArrayExtra = 0;
                  this.backup.dependentesExtra.forEach((ob) => {
                    if(ob.nome == this.nomeSinistro){
                      this.backup.dependentesExtra[idxArrayExtra].sinistro = dados;
                    }
                    if(this.backup.dependentesExtra.length == idxArrayExtra+1){
                      this.closeSinistro();
                      this.openModalEditar(this.backup,'sinistro');
                      this.app.openAlert("Sinistro Cadastrado com Sucesso!");
                    }else{
                      idxArrayExtra++
                    }
                  })
            }else{
              this.closeSinistro();
              this.openModalEditar(this.backup,'sinistro');
              this.app.openAlert("Sinistro Cadastrado com Sucesso!");
            }
          }
        }
      }else{
        this.backup.sinistro = dados;
        this.app.openAlert("Sinistro Cadastrado com Sucesso!");
        this.openModalEditar(this.backup,'sinistro');
        this.closeSinistro();
      }      
    }, err => {
      console.log(err);
    })
  })
  }

  closeSinistro(){
    this.editSinitro = false;
    this.mensagemSinistro = "";
    this.nomeSinistro = "";
    this.dependenteTipo = "";
    this.novoSinistro = false;
    this.dataSinistro = "";
    this.numeroSinistro = null;
    this.valorSinistro = null;
    this.dataIndenizacao = "";
    this.indenizacaoSinistro = false;
    this.assistenciaSinistro = false;
    this.servicosSinistro = false;
    this.qualIndenizacaoSinistro = "";
    this.qualAssistenciaSinistro = "";
    this.qualServicosSinistro = "";
    this.situacaoSinistro = "";
    this.tipoSinistrado = "";
    this.sinistroHistoricos = [];
    this.pdfsSinistro = [];
    this.imagesSinistro = [];
  }
  
  cadastrarHistoricoSinistro(){
    let jsonDate = new Date().toJSON();
    let horaAgora = new Date(jsonDate).toLocaleTimeString();
    let dados = {
      data: this.dataHoje,
      hora: horaAgora,
      historico: this.replaceStrings(this.mensagemSinistro),
      associado: this.nomeSinistro,
      usuario : this.user
    }
    if(this.dependenteTipo == 'titular'){
      this.db.postHistoricoSinistro(this.idAssociado,dados).subscribe(res => {
        console.log(res);
        if(this.sinistroHistoricos.length > 0){
          this.sinistroHistoricos.unshift(dados);
        }else{
          this.sinistroHistoricos.push(dados);
        }
        this.mensagemSinistro = "";
      }, err => {
        console.log(err)
      })
    }else{
       this.db.postHistoricoSinistroDependente(this.idAssociado, this.dependenteTipo, this.nomeSinistro, dados).subscribe(res => {
        console.log(res);
        if(this.sinistroHistoricos.length > 0){
          this.sinistroHistoricos.unshift(dados);
        }else{
          this.sinistroHistoricos.push(dados);
        }
        this.mensagemSinistro = "";
        }, err => {
          console.log(err)
      })
    }
  }

  onChange(event){
    let arrayTypes = ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'];
    this.selectedFiles = <FileList>event;
    for(let i=0; i<this.selectedFiles.length; i++){
        if(!arrayTypes.includes(this.selectedFiles[i].type)){
        this.app.openAlert("Tipo de Arquivo Não Suportado!\nEscolha uma imagem com a extensão .JPG, .JPEG, .PNG ou PDF")
        this.selectedFiles = "";
      }} 
      this.files = [];
      for(let i=0; i<this.selectedFiles.length; i++){
        this.files.push(this.selectedFiles[i].name);
      }
  }

  onChangeClick(event){
    let arrayTypes = ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'];
    this.selectedFiles = <FileList>event.srcElement.files;
    for(let i=0; i<this.selectedFiles.length; i++){
        if(!arrayTypes.includes(this.selectedFiles[i].type)){
        this.app.openAlert("Tipo de Arquivo Não Suportado!\nEscolha uma imagem com a extensão .JPG, .JPEG, .PNG ou PDF")
        this.selectedFiles = "";
      }} 
      this.files = [];
      for(let i=0; i<this.selectedFiles.length; i++){
        this.files.push(this.selectedFiles[i].name);
      }
  }
  
  onChangeCadastro(event){
    let arrayTypes = ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'];
    this.selectedFiles = <FileList>event;
    for(let i=0; i<this.selectedFiles.length; i++){
        if(!arrayTypes.includes(this.selectedFiles[i].type)){
        this.app.openAlert("Tipo de Arquivo Não Suportado!\nEscolha uma imagem com a extensão .JPG, .JPEG, .PNG ou PDF")
        this.selectedFiles = "";
      }} 
      this.files = [];
      for(let i=0; i<this.selectedFiles.length; i++){
        this.files.push(this.selectedFiles[i].name);
      }
  }

  onChangeClickCadastro(event){
    let arrayTypes = ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'];
    this.selectedFiles = <FileList>event.srcElement.files;
    for(let i=0; i<this.selectedFiles.length; i++){
        if(!arrayTypes.includes(this.selectedFiles[i].type)){
        this.app.openAlert("Tipo de Arquivo Não Suportado!\nEscolha uma imagem com a extensão .JPG, .JPEG, .PNG ou PDF")
        this.selectedFiles = "";
      }} 
      this.files = [];
      for(let i=0; i<this.selectedFiles.length; i++){
        this.files.push(this.selectedFiles[i].name);
      }
  }
  
  uploadFile(){
   const imagedata = new FormData();
   for(let i=0; i<this.selectedFiles.length; i++){imagedata.append('files[]', this.selectedFiles[i], this.selectedFiles[i].name)};
    this.db.enviarDocAssociado(this.idAssociado, imagedata).subscribe((result) => {
      let jsonDate = new Date().toJSON();
      let horaAgora = new Date(jsonDate).toLocaleTimeString();
        console.log(result);
        let body = {
          "menu" : "ASSOCIADOS", 
          "descricao" : "Documento Enviado: "+this.matriculaEditar+" - "+this.nomeEditar, 
          "data" : this.dataHoje, 
          "hora" : horaAgora, 
          "usuario" : this.user
        }
        this.db.postLogs(body).subscribe(res => {
          console.log(res);
        this.atualizarDocs();
        })
      },(error) => {
        let jsonDate = new Date().toJSON();
      let horaAgora = new Date(jsonDate).toLocaleTimeString();
        console.log(error);
        let body = {
          "menu" : "ASSOCIADOS", 
          "descricao" : "Documento Enviado: "+this.matriculaEditar+" - "+this.nomeEditar, 
          "data" : this.dataHoje, 
          "hora" : horaAgora, 
          "usuario" : this.user
        }
        this.db.postLogs(body).subscribe(res => {
        console.log(res);
        this.atualizarDocs();
        })
      });
  }
  
  atualizarDocs(){
    this.docs = [];
    this.db.getAssociadosById(this.idAssociado).subscribe((result:any) => {
      this.docs = result.documentos;
      this.pdfs = [];
      this.images = [];
      this.selectedFiles = "";
      this.files = [];
      if(this.docs && this.docs.length > 0){
        this.pdfs = [];
        this.images = [];
        this.docs.forEach((item) => {
        let ext = item.url.split('.').pop();
          if(ext == 'pdf' || ext == 'PDF'){
            this.pdfs.push(item);
          }else{
            this.images.push(item);
          }
        })
      }
    })
  }
  
  openModalPdfViewer(url){
    this.pdfViewerOnDemand.pdfSrc = url;
    this.pdfViewerOnDemand.refresh();
  }
  
  openModalLightBox(url, i){
    this.indexImage = i;
    this.imagemSelecionada = url;
    let modal = document.getElementById('myModal')
    modal.style.display = "block";
  }
  
  nextImg(){
    let i = this.indexImage
    if((this.images.length - 1) == i){
      this.imagemSelecionada = this.images[0].url;
      this.indexImage = 0;
    }else{
      i++
      this.imagemSelecionada = this.images[i].url;
      this.indexImage++
    }
  }
  
  prevImg(){
    let i = this.indexImage
    if(i == 0){
      this.imagemSelecionada = this.images[this.images.length - 1].url;
      this.indexImage = this.images.length - 1;
    }else{
      i--
      this.imagemSelecionada = this.images[i].url;
      this.indexImage--
    }
  }
  
  closeModal(){
    let modal = document.getElementById('myModal')
    modal.style.display = "none";
  }
  
  delButton(){
    let buttonDiv = document.getElementsByName("delButton")
    for(let i=0; i < buttonDiv.length; i++ ){
      buttonDiv[i].toggleAttribute("hidden")
      if(buttonDiv[i].className == "delButton"){
        buttonDiv[i].className = "delButtonShow"
      }else{
        buttonDiv[i].className = "delButton"
      }
    }
  }
  
  delDoc(url){
     if(window.confirm('Deseja Realmente Deletar esse Documento?')){
      let jsonDate = new Date().toJSON();
      let horaAgora = new Date(jsonDate).toLocaleTimeString();
      let bodyLogs = {
        "menu" : "ASSOCIADOS", 
        "descricao" : "Documento Excluido: "+this.matriculaEditar+" - "+this.nomeEditar, 
        "data" : this.dataHoje, 
        "hora" : horaAgora, 
        "usuario" : this.user
      }
      this.db.removeDoc(url).subscribe(res => {
        console.log(res);
        this.db.removeDocAssociadoDB(this.idAssociado,url).subscribe(result => {
          console.log(result);         
            this.db.postLogs(bodyLogs).subscribe(res => {
              console.log(res);
              this.atualizarDocsDel(url);
            },error => console.log(error))
        }, error => {
          console.log(error);
        })
       },err => {
         console.log(err);
          this.db.removeDocAssociadoDB(this.idAssociado,url).subscribe(result => {
          console.log(result);
          this.db.postLogs(bodyLogs).subscribe(res => {
            console.log(res);
            this.atualizarDocsDel(url);
          },error => console.log(error))
        }, error => {
          console.log(error);
        })
      })
    }
  }
  
atualizarDocsDel(url){
  this.pdfs = [];
  this.images = [];
  let idx = this.docs.map((item) => {
    return item.url
  }).indexOf(url)
  if(idx >= 0){
    this.docs.splice(idx,1);
    if(this.docs && this.docs.length > 0){
        this.docs.forEach((item) => {
        let ext = item.url.split('.').pop();
          if(ext == 'pdf' || ext == 'PDF'){
            this.pdfs.push(item);
          }else{
            this.images.push(item);
          }
        })
      }
    }
  }

verificaEnter(input,tipo,e){
   if(e.keyCode === 13){
       if(tipo == 'titular'){
        this.buscarTitular(input)
       }
       if(tipo == 'beneficiario'){
         this.buscarBeneficiario(input)
       }
       if(tipo == 'matricula'){
        this.buscarAssociadoMatricula(input)
      }
      if(tipo == 'cpf'){
        this.buscarAssociadoCpf(input)
      }
    }
}

cancelarContrato(){ 
  if(window.confirm("Deseja realmente Cancelar esse Associado?")){
    let jsonDate = new Date().toJSON();
    let horaAgora = new Date(jsonDate).toLocaleTimeString();
    let body = {
      motivoCancelamento : this.replaceStrings(this.motivoCancelamento),
      observacoesCancelamento : this.replaceStrings(this.observacoesCancelamento),
      spcCancelamento : this.spcCancelamento,
      situacao : "CANCELADO",
      dataCancelamento: this.dataHoje
    }
    this.db.patchAssociado(this.idAssociado,body).subscribe(res => {
      console.log(res);
      let dados = {
        "data" : this.dataHoje, 
        "hora" : horaAgora, 
        "motivo" : "CANCELAMENTO", 
        "msg" : this.replaceStrings(this.motivoCancelamento), 
        "usuario" : localStorage.getItem('usuarioVivamax')
      }
      this.db.newHistorico(this.idAssociado,dados).subscribe(res => {
        console.log(res);
      this.situacao = "CANCELADO";
      let jsonDate = new Date().toJSON();
      let horaAgora = new Date(jsonDate).toLocaleTimeString();
      let body = {
        "menu" : "CANCELAMENTO", 
        "descricao" : "Cancelamento do Associado: "+this.nome+" matricula nº "+this.matriculaEditar, 
        "data" : this.dataHoje, 
        "hora" : horaAgora, 
        "usuario" : this.user
      }
      this.db.postLogs(body).subscribe(res => {
        console.log(res);
        this.app.openAlert("Pronto!")
        if(this.empresaAssociado == "CONECTA"){
         this.cancelarTodosConecta();
        }else{
          this.cancelarDependentes();
          this.situacao = "";
        }
      }, err => {
        console.log(err)
      })
      }, err => {
        console.log(err)
      })
    }, err => {
      console.log(err)
    })
  }
}

cancelarDependentes(){
  this.db.getAssociadosById(this.idAssociado).subscribe((data:any) => {
    let todos = data;
    if(todos.dependentes && todos.dependentes.length > 0){
      let controlDependentes = 1;
      todos.dependentes.forEach((item) => {
        if(item.situacao != "SUSPENSO"){
          item.situacao = "SUSPENSO"
        }
        if(item.sinistro && item.sinistro.numeroSinistro != null){
          item.sinistro.numeroSinistro =item.sinistro.numeroSinistro
        }
        if(item.sinistro && item.sinistro.valorSinistro != null){
          item.sinistro.valorSinistro =item.sinistro.valorSinistro
        }
        if(controlDependentes == todos.dependentes.length){
          let body = {
            dependentes: todos.dependentes
          }
          this.db.patchAssociado(this.idAssociado,body).subscribe(res => console.log(res), err => console.log(err))
        }else{
          controlDependentes++
        }
      })
    }
    if(todos.dependentesExtra && todos.dependentesExtra.length > 0){
      let controlDependentesExtra = 1;
      todos.dependentesExtra.forEach((item) => {
        if(item.situacao != "SUSPENSO"){
          item.situacao = "SUSPENSO"
        }
        if(item.sinistro && item.sinistro.numeroSinistro != null){
          item.sinistro.numeroSinistro =item.sinistro.numeroSinistro
        }
        if(item.sinistro && item.sinistro.valorSinistro != null){
          item.sinistro.valorSinistro =item.sinistro.valorSinistro
        }
        if(controlDependentesExtra == todos.dependentesExtra.length){
          let body = {
            dependentesExtra: todos.dependentesExtra
          }
          this.db.patchAssociado(this.idAssociado,body).subscribe(res => console.log(res), err => console.log(err))
        }else{
          controlDependentesExtra++
        }
      })
    }
  })
  this.db.getAssociadosConectaCpf(this.cpfEditar).subscribe((dataConecta:any) => {
    if(dataConecta && dataConecta.length > 0){
      this.app.openAlert("Contrato Cancelado com Sucesso, porém este associado possui Plano Conecta, matricula: "+dataConecta[0].matricula);
      this.closeModalEditar();
    }else{
      this.app.openAlert("Contrato Cancelado com Sucesso!");
      this.closeModalEditar();
    }
  })
}

ativarDependentes(){
  this.db.getAssociadosById(this.idAssociado).subscribe((data:any) => {
    let todos = data;
    if(todos.dependentes && todos.dependentes.length > 0){
      let controlDependentes = 1;
      todos.dependentes.forEach((item) => {
        if(item.situacao != "ATIVO"){
          item.situacao = "ATIVO"
        }
        if(item.sinistro && item.sinistro.numeroSinistro != null){
          item.sinistro.numeroSinistro =item.sinistro.numeroSinistro
        }
        if(item.sinistro && item.sinistro.valorSinistro != null){
          item.sinistro.valorSinistro =item.sinistro.valorSinistro
        }
        if(controlDependentes == todos.dependentes.length){
          let body = {
            dependentes: todos.dependentes
          }
          this.db.patchAssociado(this.idAssociado,body).subscribe(res => console.log(res), err => console.log(err))
        }else{
          controlDependentes++
        }
      })
    }
    if(todos.dependentesExtra && todos.dependentesExtra.length > 0){
      let controlDependentesExtra = 1;
      todos.dependentesExtra.forEach((item) => {
        if(item.situacao != "ATIVO"){
          item.situacao = "ATIVO"
        }
        if(item.sinistro && item.sinistro.numeroSinistro != null){
          item.sinistro.numeroSinistro =item.sinistro.numeroSinistro
        }
        if(item.sinistro && item.sinistro.valorSinistro != null){
          item.sinistro.valorSinistro =item.sinistro.valorSinistro
        }
        if(controlDependentesExtra == todos.dependentesExtra.length){
          let body = {
            dependentesExtra: todos.dependentesExtra
          }
          this.db.patchAssociado(this.idAssociado,body).subscribe(res => console.log(res), err => console.log(err))
        }else{
          controlDependentesExtra++
        }
      })
    }
  })
  this.app.openAlert("Contrato Ativado com Sucesso!");
}

verificaData(data){
  let ano = data.split('-')[0];
  if(Number(ano) > 9999){
    this.app.openAlert("ATENÇÃO! O ANO NÃO PODE TER MAIS QUE 4 DIGITOS!")
  }
}

openModalLightBoxSinistro(url, i){
  this.indexImageSinistro = i;
  this.imagemSelecionadaSinistro = url;
  let modal = document.getElementById('lightSinistro')
  modal.style.display = "block";
}

nextImgSinistro(){
  let i = this.indexImageSinistro
  if((this.imagesSinistro.length - 1) == i){
    this.imagemSelecionadaSinistro = this.imagesSinistro[0].url;
    this.indexImageSinistro = 0;
  }else{
    i++
    this.imagemSelecionadaSinistro = this.imagesSinistro[i].url;
    this.indexImageSinistro++
  }
}

prevImgSinistro(){
  let i = this.indexImageSinistro
  if(i == 0){
    this.imagemSelecionadaSinistro = this.imagesSinistro[this.imagesSinistro.length - 1].url;
    this.indexImageSinistro = this.imagesSinistro.length - 1;
  }else{
    i--
    this.imagemSelecionadaSinistro = this.imagesSinistro[i].url;
    this.indexImageSinistro--
  }
}

closeModalSinistro(){
  let modal = document.getElementById('lightSinistro')
  modal.style.display = "none";
}

onChangeSinistro(event){
  let arrayTypes = ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'];
  this.selectedFilesSinistro = <FileList>event;
  for(let i=0; i<this.selectedFilesSinistro.length; i++){
      if(!arrayTypes.includes(this.selectedFilesSinistro[i].type)){
      this.app.openAlert("Tipo de Arquivo Não Suportado!\nEscolha uma imagem com a extensão .JPG, .JPEG, .PNG ou PDF")
      this.selectedFilesSinistro = "";
    }} 
    this.filesSinistro = [];
    for(let i=0; i<this.selectedFilesSinistro.length; i++){
      this.filesSinistro.push(this.selectedFilesSinistro[i].name);
    }
}

onChangeClickSinistro(event){
  let arrayTypes = ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'];
  this.selectedFilesSinistro = <FileList>event.srcElement.files;
  for(let i=0; i<this.selectedFilesSinistro.length; i++){
      if(!arrayTypes.includes(this.selectedFilesSinistro[i].type)){
      this.app.openAlert("Tipo de Arquivo Não Suportado!\nEscolha uma imagem com a extensão .JPG, .JPEG, .PNG ou PDF")
      this.selectedFilesSinistro = "";
    }} 
    this.filesSinistro = [];
    for(let i=0; i<this.selectedFilesSinistro.length; i++){
      this.filesSinistro.push(this.selectedFilesSinistro[i].name);
    }
}

uploadFileSinistro(){
  const imagedata = new FormData();
  for(let i=0; i<this.selectedFilesSinistro.length; i++){imagedata.append('files[]', this.selectedFilesSinistro[i], this.selectedFilesSinistro[i].name)};
   this.db.enviarDocSinistro(this.idAssociado, imagedata,this.nomeSinistro,this.dependenteTipo).subscribe((res) => {
       console.log(res);
       this.atualizarDocsSinistro();
     },(error) => {
       console.log(error);
       this.atualizarDocsSinistro();
     });
 }
 
atualizarDocsSinistro(){
  this.filesSinistro = [];
  this.pdfsSinistro = [];
  this.imagesSinistro = [];
  this.db.getAssociadosById(this.idAssociado).subscribe((data:any) => {
    this.backup = data;
    if(this.dependenteTipo == 'titular'){
      this.sinistrado = this.backup;
      this.docsSinistro = this.sinistrado.sinistro.documentos;
      this.getDocsSinistros();      
    }
    if(this.dependenteTipo == 'direto'){
      let index = this.backup.dependentes.map((item) => {
        return item.nome;
      }).indexOf(this.nomeSinistro);
      if(index >= 0){
        this.sinistrado = this.backup.dependentes[index];
      this.docsSinistro = this.sinistrado.sinistro.documentos;
      this.getDocsSinistros();
      }
    }
    if(this.dependenteTipo == 'extra'){
      let index = this.backup.dependentesExtra.map((item) => {
        return item.nome;
      }).indexOf(this.nomeSinistro);
      if(index >= 0){
        this.sinistrado = this.backup.dependentesExtra[index];
        this.docsSinistro = this.sinistrado.sinistro.documentos;
        this.getDocsSinistros();
      }
    }
  })
}

getDocsSinistros(){
  if(this.docsSinistro && this.docsSinistro.length > 0){
      this.docsSinistro.forEach((item) => {
       let ext = item.url.split('.').pop();
        if(ext == 'pdf' || ext == 'PDF'){
          this.pdfsSinistro.push(item);
        }else{
          this.imagesSinistro.push(item);
        }
      })
    }
  }

 delDocSinistro(url){
   if(window.confirm('Deseja Realmente Deletar esse Documento?')){
      this.db.removeDoc(url).subscribe(res => {
        console.log(res);
        this.db.removeSinistroAssociadoDB(this.idAssociado,url,this.dependenteTipo,this.nomeSinistro).subscribe(result => {
          console.log(result);
          this.atualizarDocsSinistro();
        }, error => {
          console.log(error);
        })
        },err => {
          console.log(err);
          this.db.removeSinistroAssociadoDB(this.idAssociado,url,this.dependenteTipo,this.nomeSinistro).subscribe(result => {
          console.log(result);
          this.atualizarDocsSinistro();
        }, error => {
          console.log(error);
        })
      })
    } 
  }


  openModalLightBoxCancelamento(url, i){
  this.indexImageCancelamento = i;
  this.imagemSelecionadaCancelamento = url;
  let modal = document.getElementById('lightCancelamento')
  modal.style.display = "block";
}

nextImgCancelamento(){
  let i = this.indexImageCancelamento;
  if((this.imagesCancelamento.length - 1) == i){
    this.imagemSelecionadaCancelamento = this.imagesCancelamento[0].url;
    this.indexImageCancelamento = 0;
  }else{
    i++
    this.imagemSelecionadaCancelamento = this.imagesCancelamento[i].url;
    this.indexImageCancelamento++
  }
}

prevImgCancelamento(){
  let i = this.indexImageCancelamento;
  if(i == 0){
    this.imagemSelecionadaCancelamento = this.imagesCancelamento[this.imagesCancelamento.length - 1].url;
    this.indexImageCancelamento = this.imagesCancelamento.length - 1;
  }else{
    i--
    this.imagemSelecionadaCancelamento = this.imagesCancelamento[i].url;
    this.indexImageCancelamento--
  }
}

closeModalCancelamento(){
  let modal = document.getElementById('lightCancelamento')
  modal.style.display = "none";
}

onChangeCancelamento(event){
  let arrayTypes = ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'];
  this.selectedFilesCancelamento = <FileList>event;
  for(let i=0; i<this.selectedFilesCancelamento.length; i++){
      if(!arrayTypes.includes(this.selectedFilesCancelamento[i].type)){
      this.app.openAlert("Tipo de Arquivo Não Suportado!\nEscolha uma imagem com a extensão .JPG, .JPEG, .PNG ou PDF")
      this.selectedFilesCancelamento = "";
    }} 
    this.filesCancelamento = [];
    for(let i=0; i<this.selectedFilesCancelamento.length; i++){
      this.filesCancelamento.push(this.selectedFilesCancelamento[i].name);
    }
}

onChangeClickCancelamento(event){
  let arrayTypes = ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'];
  this.selectedFilesCancelamento = <FileList>event.srcElement.files;
  for(let i=0; i<this.selectedFilesCancelamento.length; i++){
      if(!arrayTypes.includes(this.selectedFilesCancelamento[i].type)){
      this.app.openAlert("Tipo de Arquivo Não Suportado!\nEscolha uma imagem com a extensão .JPG, .JPEG, .PNG ou PDF")
      this.selectedFilesCancelamento = "";
    }} 
    this.filesCancelamento = [];
    for(let i=0; i<this.selectedFilesCancelamento.length; i++){
      this.filesCancelamento.push(this.selectedFilesCancelamento[i].name);
    }
}

uploadFileCancelamento(){
  const imagedata = new FormData();
  for(let i=0; i<this.selectedFilesCancelamento.length; i++){imagedata.append('files[]', this.selectedFilesCancelamento[i], this.selectedFilesCancelamento[i].name)};
   this.db.enviarDocCancelamento(this.idAssociado, imagedata).subscribe((res) => {
       console.log(res);
       this.getDocsCancelamentos();
     },(error) => {
       console.log(error);
       this.getDocsCancelamentos();
     });
 }

getDocsCancelamentos(){
  this.docsCancelamento = [];
  this.db.getAssociadosById(this.idAssociado).subscribe((result:any) => {
    this.docsCancelamento = result.documentosCancelamento ;
    this.pdfsCancelamento = [];
    this.imagesCancelamento = [];
    this.selectedFilesCancelamento = "";
    this.filesCancelamento = [];
    if(this.docsCancelamento && this.docsCancelamento.length > 0){
      this.pdfsCancelamento = [];
      this.imagesCancelamento = [];
      this.docsCancelamento.forEach((item) => {
      let ext = item.url.split('.').pop();
        if(ext == 'pdf' || ext == 'PDF'){
          this.pdfsCancelamento.push(item);
        }else{
          this.imagesCancelamento.push(item);
        }
      })
    }
  })
}

  delDocCancelamento(url){
    if(window.confirm('Deseja Realmente Deletar esse Documento?')){
     this.db.removeDoc(url).subscribe(res => {
       console.log(res);
       this.db.removeDocCancelamento(this.idAssociado,url).subscribe(result => {
         console.log(result);
         this.getDocsCancelamentos();
       }, error => {
         console.log(error);
       })
      },err => {
        console.log(err);
         this.db.removeDocCancelamento(this.idAssociado,url).subscribe(result => {
         console.log(result);
         this.getDocsCancelamentos();
       }, error => {
         console.log(error);
       })
     })
   }
 }
 
 printTudo(){
  let divProposta = document.getElementById('divToPrint').innerHTML;
  let divFinanceiro = document.getElementById('divToPrintFinanceiro').innerHTML;
  let divRemetente = document.getElementById('divToPrintRemetente').innerHTML;
  let newWindow = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
  newWindow.document.open();
  newWindow.document.write(`
  <html>
      <head>
        <title>Viva Max - Proposta</title>
        <link rel="stylesheet" type="text/css" href="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css">
        <script type="text/javascript" src="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/js/bootstrap.min.js"></script>
      </head>
      <body onload="window.print()">
      ${divProposta}   
      <div style="page-break-after: always"></div>
      ${divFinanceiro}   
      <div style="page-break-after: always"></div>
      <div style="margin-top:80px;margin-left:50px">
            ${divRemetente}   
      </div>
      </body>
    </html>
  `);
  newWindow.document.close();
  this.printContrato();
 }

 printProposta(){
    let divToPrint = document.getElementById('divToPrint').innerHTML;
    let newWindow = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    newWindow.document.open();
    newWindow.document.write(`
    <html>
        <head>
          <title>Viva Max - Proposta</title>
          <link rel="stylesheet" type="text/css" href="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css">
          <script type="text/javascript" src="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/js/bootstrap.min.js"></script>
        </head>
        <body onload="window.print()">${divToPrint}   
        </body>
      </html>
    `);
    newWindow.document.close();
 }
 
 printFinanceiro(){
     let divToPrint = document.getElementById('divToPrintFinanceiro').innerHTML;
     let newWindow = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
     newWindow.document.open();
     newWindow.document.write(`
     <html>
         <head>
           <title>Viva Max - Proposta</title>
           <link rel="stylesheet" type="text/css" href="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css">
           <script type="text/javascript" src="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/js/bootstrap.min.js"></script>
         </head>
         <body onload="window.print()">${divToPrint}   
         </body>
       </html>
     `);
     newWindow.document.close();
}

printRemetente(){
  let divToPrint = document.getElementById('divToPrintRemetente').innerHTML;
  let newWindow = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
  newWindow.document.open();
  newWindow.document.write(`
  <html>
      <head>
        <title>Viva Max - Proposta</title>
        <link rel="stylesheet" type="text/css" href="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css">
        <script type="text/javascript" src="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/js/bootstrap.min.js"></script>
      </head>
      <body onload="window.print()">
      <div style="margin-top:80px;margin-left:50px">
      ${divToPrint}   
      </div>
      </body>
    </html>
  `);
  newWindow.document.close();
}

  printContrato(){
    let tipo:any = "";
    if(this.planoFisicoCheckEditar == true){
      tipo = "PLANO FISICO"
    }else if(this.planoEmpresarialCheckEditar == true){
      tipo = "PLANO EMPRESARIAL"
    }else{
      tipo = "PLANO SERVIDOR"
    }
    this.db.getPlanosVivamaxByTipoByNome(tipo,this.planoEditar).subscribe((data:any) => {
      if(data && data.length > 0 && data[0].contrato != ""){
        let contrato = data[0].contrato;
        window.open(contrato, "_blank","fullscreen=yes");
      }else{
        this.app.openAlert("Plano sem Contrato Cadastrado, por favor Cadastre em: PLANOS")
      }
    })
  }
 
  excluirAssociado(associado){
    if(window.confirm('Deseja Realmente Excluir esse Associado? Esta ação é irreversivel')){
      let jsonDate = new Date().toJSON();
      let hora = new Date(jsonDate).toLocaleTimeString();
      this.db.deleteAssociado(associado._id).subscribe(res => {
        console.log(res);
        let body = {
          "menu" : "EXCLUIR ASSOCIADO", 
          "descricao" : "Associado "+associado.nome+ " Excluido!", 
          "data" : this.dataHoje, 
          "hora" : hora, 
          "usuario" : this.user
        }
        this.db.postLogs(body).subscribe(res => {
          console.log(res);
         this.app.openAlert("Associado Excluido com Sucesso");
         this.ngOnInit();
        }, err => {
          console.log(err)
        })
      }, err => {
        console.log(err)
     })
    }
  }

replaceStrings(string:any){
  return string.replace(/\%/gi,'%25').replace(/\+/gi,'%2B').replace(/\!/gi,'%21').replace(/\&/gi,'%26').replace(/\;/gi,'%3B').replace(/\#/gi,'%23').replace(/\$/gi,'%24');
}

reverseReplaceStrings(string:any){
  if(!string){return}
  return string.replace(/%25/gi,'%').replace(/%2B/gi,'+').replace(/%21/gi,'!').replace(/%26/gi,'&').replace(/%3B/gi,';').replace(/\%23/gi,'#').replace(/\%24/gi,'$');
}

getProfissionalByCredenciado(lab){
  this.profissionalMedicoEditar = "";
  this.profissionalMedico = "";
  let i = this.credenciados.map((item) => {
    return item.razaoCredenciado
  }).indexOf(lab);
  if(i >= 0){
    this.listaProfissionais = this.credenciados[i].profissionais;
  }
}

solicitarCancelamento(){
  let jsonDate = new Date().toJSON();
  let hora = new Date(jsonDate).toLocaleTimeString();
  let dados = {
    solicitouCancelamento : this.checkSolicitouCancelamento,
    motivoSolicitacaoCancelamento : this.replaceStrings(this.motivoSolicitacaoCancelamento),
    dataSolicitacaoCancelamento : this.dataHoje,
    horaSolicitacaoCancelamento : hora,
    usuarioSolicitacoCancelamento : this.user
  }
  this.db.patchAssociado(this.idAssociado,dados).subscribe(ress => {
    console.log(ress)
    let dados = {
      "data" : this.dataHoje, 
      "hora" : hora, 
      "motivo" : "SOLICITAÇÃO DE CANCELAMENTO", 
      "msg" : this.replaceStrings(this.motivoSolicitacaoCancelamento), 
      "usuario" : this.user
    }
    this.db.newHistorico(this.idAssociado,dados).subscribe(res => {
    console.log(res);
    let body = {
      "menu" : "EDITAR ASSOCIADOS", 
      "descricao" : "Solicitação de Cancelamento do Associado, motivo: "+this.replaceStrings(this.motivoSolicitacaoCancelamento), 
      "data" : this.dataHoje, 
      "hora" : hora, 
      "usuario" : this.user
    }
    this.db.postLogs(body).subscribe(result => {
      console.log(result);
      this.db.getAssociadosConectaCpf(this.cpfEditar).subscribe((dataConecta:any) => {
        if(dataConecta && dataConecta.length > 0){
          this.app.openAlert("Solicitação enviada com Sucesso, porém este associado possui Plano Conecta, matricula: "+dataConecta[0].matricula);
          this.closeModalEditar();
        }else{
          this.app.openAlert("Solicitação Enviada com Sucesso");
          this.closeModalEditar();
        }
      })
      },err => {
        console.log(err);
        this.app.openAlert("Falha ao Enviar, por favor recarregue a página e solicite novamente")
      })
    },err => {
      console.log(err);
      this.app.openAlert("Falha ao Enviar, por favor recarregue a página e solicite novamente")
    })
  },err => {
    console.log(err);
    this.app.openAlert("Falha ao Enviar, por favor recarregue a página e solicite novamente")
  })
}

cancelarCancelamento(){
  let jsonDate = new Date().toJSON();
  let hora = new Date(jsonDate).toLocaleTimeString();
  let dados = {
    solicitouCancelamento : false,
    motivoCancelarSolicitacaoCancelamento : this.motivoCancelarSolicitacaoCancelamento,
    dataCancelarSolicitacaoCancelamento : this.dataHoje,
    motivoSolicitacaoCancelamento: "",
    horaCancelarSolicitacaoCancelamento : hora,
    usuarioSolicitacoCancelamento : this.user
  }
  this.db.patchAssociado(this.idAssociado,dados).subscribe(ress => {
    console.log(ress)
    let dados = {
      "data" : this.dataHoje, 
      "hora" : hora, 
      "motivo" : "REVERSÃO DE CANCELAMENTO", 
      "msg" : this.motivoCancelarSolicitacaoCancelamento, 
      "usuario" : this.user
    }
    this.db.newHistorico(this.idAssociado,dados).subscribe(res => {
    console.log(res);
    let body = {
      "menu" : "EDITAR ASSOCIADOS", 
      "descricao" : "Reversão de Cancelamento do Associado, motivo: "+this.motivoCancelarSolicitacaoCancelamento, 
      "data" : this.dataHoje, 
      "hora" : hora, 
      "usuario" : this.user
    }
    this.db.postLogs(body).subscribe(result => {
      console.log(result);
      this.app.openAlert("Reversão Enviada com Sucesso");
      this.closeModalEditar();
    },err => {
      console.log(err);
      this.app.openAlert("Falha ao Enviar, por favor recarregue a página e solicite novamente")
    })
    },err => {
      console.log(err);
      this.app.openAlert("Falha ao Enviar, por favor recarregue a página e solicite novamente")
    })
  },err => {
    console.log(err);
    this.app.openAlert("Falha ao Enviar, por favor recarregue a página e solicite novamente")
  })
}

deleteDocFilesEditar(idx){
  this.files.splice(idx,1);
  let fileArray = Array.from(this.selectedFiles);
  fileArray.splice(idx,1);
  this.selectedFiles = fileArray;
}

deleteDocFiles(idx){
  this.files.splice(idx,1);
  let fileArray = Array.from(this.selectedFiles);
  fileArray.splice(idx,1);
  this.selectedFiles = fileArray;
}

deleteDocFilesCancelamento(idx){
  this.filesCancelamento.splice(idx,1);
  let fileArray = Array.from(this.selectedFilesCancelamento);
  fileArray.splice(idx,1);
  this.selectedFilesCancelamento = fileArray;
}

deleteDocFilesSinistro(idx){
  this.filesSinistro.splice(idx,1);
  let fileArray = Array.from(this.selectedFilesSinistro);
  fileArray.splice(idx,1);
  this.selectedFilesSinistro = fileArray;
}

selectEmpresa(empresa){
  this.vendedorEmpresaOriginal = empresa.vendedor
  this.empresa = empresa;
  this.plano = empresa.plano;
  this.localTrabalho = empresa.razaoEmpresa;
  this.mensalidade =empresa.valorPlano;
  this.funeralConjuge = empresa.funeralConjuge;
  this.funeralExtra = empresa.funeralExtra;
  this.funeralFilhosMaior = empresa.funeralFilhosMaior;
  this.funeralFilhosMenor = empresa.funeralFilhosMenor;
  if(empresa.valorSeguro == null){
    this.valorCapitalSegurado = 0;
  }else{
    this.valorCapitalSegurado =empresa.valorSeguro;
  }
  if(empresa.valorFuneralConjuge == null){
    this.valorFuneralConjuge = 0;
  }else{
    this.valorFuneralConjuge =empresa.valorFuneralConjuge;
  }
  if(empresa.valorFuneralExtra == null){
    this.valorFuneralExtra = 0;
  }else{
    this.valorFuneralExtra =empresa.valorFuneralExtra;
  }
  if(empresa.valorFuneralFilhosMaior == null){
    this.valorFuneralFilhosMaior = 0;
  }else{
    this.valorFuneralFilhosMaior =empresa.valorFuneralFilhosMaior;
  }
  if(empresa.valorFuneralFilhosMenor == null){
    this.valorFuneralFilhosMenor = 0;
  }else{
    this.valorFuneralFilhosMenor = empresa.valorFuneralFilhosMenor;
  }
  this.totalMensalidade = this.totalAsfDependentes + this.mensalidade;
}


selectEmpresaEditar(empresa:any){
  this.empresa = empresa;
  this.codigoEmpresa = empresa.codigo;
  this.planoEditar = empresa.plano;
  this.localTrabalhoEditar = empresa.razaoEmpresa;
  this.mensalidadeEditar =empresa.valorPlano;
  this.funeralConjugeEditar = empresa.funeralConjuge;
  this.funeralExtraEditar = empresa.funeralExtra;
  this.funeralFilhosMaiorEditar = empresa.funeralFilhosMaior;
  this.funeralFilhosMenorEditar = empresa.funeralFilhosMenor;
  this.formaPagamentoEditar = "EMPRESARIAL";
  if(empresa.valorSeguro == null){
    this.valorCapitalSeguradoEditar = 0;
  }else{
    this.valorCapitalSeguradoEditar = empresa.valorSeguro;
  }
  if(empresa.valorFuneralConjuge== null){
    this.valorFuneralConjugeEditar = 0;
  }else{
    this.valorFuneralConjugeEditar = empresa.valorFuneralConjuge;
  }
  if(empresa.valorFuneralExtra == null){
    this.valorFuneralExtraEditar = 0;
  }else{
    this.valorFuneralExtraEditar = empresa.valorFuneralExtra;
  }
  if(empresa.valorFuneralFilhosMaior == null){
    this.valorFuneralFilhosMaiorEditar = 0;
  }else{
    this.valorFuneralFilhosMaiorEditar = empresa.valorFuneralFilhosMaior;
  }
  if(empresa.valorFuneralFilhosMenor == null){
    this.valorFuneralFilhosMenorEditar = 0;
  }else{
    this.valorFuneralFilhosMenorEditar = empresa.valorFuneralFilhosMenor;
  }
  this.totalMensalidadeEditar = this.totalAsfDependentesEditar + this.mensalidadeEditar;
}
 
cadastrarAssociadoPj(){
  if(this.mensalidade == null){this.mensalidade = 0;}
  if(this.taxaAdesao == null){this.taxaAdesao = 0;}
  if(this.totalMensalidade == null){this.totalMensalidade = 0;}
  if(window.confirm("Deseja realmente cadastrar esse Associado?")){
    this.db.getConfigs().subscribe((x:any) => {
      let matricula = x[10].matricula+7;
      let idMatricula = x[10]._id;
      this.db.getSorteLivre().subscribe((dataNum:any) => {
        let idNumSorte = dataNum[0]._id;
        let numSorte = null;
        if(this.plano.indexOf("PLUS") >= 0 || this.localTrabalho.indexOf("APCS") >= 0){
          numSorte = dataNum[0].numero;
        }
    let jsonDate = new Date().toJSON();
    let hora = new Date(jsonDate).toLocaleTimeString();
    let sit = "ATIVO";
    let descr = "Novo Associado: "+this.nome+" cadastrado na Empresa: "+this.empresa.razaoEmpresa;
    let descriDocs = this.files.length > 0 ? ' - Com '+this.files.length+' documentos anexados' : '';
    let msg = "ASSOCIADO CADASTRADO!";
    let dados = {
      codigoEmpresa:this.empresa.codigo,
      matricula:matricula,
      data: this.dataHoje,
      hora: hora,
      plano : this.plano,
      cpf : this.cpf,
      estadoCivil : this.estadoCivil,
      nascimento : this.nascimento.split('/').reverse().join('-'),
      nome : this.nome,
      sexo: this.sexo,
      telefone : this.telefone,
      celular : this.celular,
      cep: this.cep,
      rua : this.rua,
      numero : this.numero,
      complemento : this.complemento,
      bairro : this.bairro,
      municipio : this.municipio,
      estado : this.estado,
      email:this.email,
      localTrabalho : this.empresa.razaoEmpresa,
      funcao : this.funcao,
      doenca : this.doenca,
      origem: this.origem,
      especificarDoenca :  this.especificarDoenca,
      formaPagamento :  "EMPRESARIAL",
      mensalidade :  this.mensalidade,
      taxaAdesao :  this.taxaAdesao,
      diaVencimento :  "",
      mesFolhaPagamento :  "",
      motivoPendencia : "",
      numSorte:numSorte,
      semAssinatura : false,
      senha: matricula.toString(),
      semAutorizacao : false,
      historicos:[{
        "data" : this.dataHoje, 
        "hora" : hora, 
        "motivo" : "CADASTRO", 
        "msg" : msg, 
        "usuario" : localStorage.getItem('usuarioVivamax')
       }
      ],
      agendamentos:[],
      dataAdesao:this.dataAdesao,
      whatsapp: this.whatsapp,
      dependentes:this.dependentes.get('dependente').value,
      dependentesExtra:this.dependentesExtra.get('dependenteExtra').value,
      nomeCartao : "",
      numeroCartao : "",
      validadeCartao : "",
      codigoCartao : "",
      idVendedor:this.idVendedor,
      vendedor:this.vendedor,
      totalMensalidade:this.totalMensalidade,
      subContrato : "",
      planoFisicoCheck: false,
      planoEmpresarialCheck: true,
      cartaoSelecionado: "",
      valorCapitalSegurado: this.valorCapitalSegurado,
      documentos:[],
      situacao:sit,
      observacaoPendente : "",
      checkSolicitouCancelamento:false,
      motivoSolicitacaoCancelamento:"",
      vendedorEmpresa: this.vendedorEmpresaOriginal,
      empresa:"VIVA MAX",
      unidadeConsumidora:this.unidadeConsumidora
    }
    this.db.cadastroProposta(dados).subscribe(res => {
      console.log(res);
      if(this.files.length > 0){
        const imagedata = new FormData();
        for(let i=0; i<this.selectedFiles.length; i++){imagedata.append('files[]', this.selectedFiles[i], this.selectedFiles[i].name)};
         this.db.enviarDocAssociado( res['insertedId'], imagedata).subscribe((res) => {
             console.log(res);
           },(error) => {
             console.log(error);
           });
      }
      let body = {
        "menu" : "CADASTRAR ASSOCIADOS", 
        "descricao" : descr+descriDocs, 
        "data" : this.dataHoje, 
        "hora" : hora, 
        "usuario" : this.user
      }
      this.db.postLogs(body).subscribe(res => {
        console.log(res);
        if(this.plano.indexOf("PLUS") >= 0 || this.localTrabalho.indexOf("APCS") >= 0){
          this.db.patchSorte(idNumSorte,{usado:true}).subscribe(resN => {
            console.log(resN)
          }, errN => {console.log(errN)})
        }
        this.db.patchMatricula(idMatricula,{'matricula':matricula+1}).subscribe(res => {
          console.log(res);
        })
        this.app.openAlert("Cadastro Realizado com Sucesso");
        this.botao = true;
        this.fecharModalNovoPj();
      }, err => {
        console.log(err)
      })
    }, err => {
      console.log(err);
      this.app.openAlert("Não foi possivel cadastrar o Associado, por favor tente novamente!");
      this.botao = true;
      })
    })
  })
  }else{
    this.botao = true;
  }
}
 
baixarCarteirinha(origem:any,i:any){
  this.loadCard = true;
  if(origem == "dep"){
    this.carteirinhaSelecionada = this.carteirinhas[i+1];
  }else{
      this.carteirinhaSelecionada = this.carteirinhasExtras[i];
  }
  let element = document.getElementById("carteiraDownload");
  var HTML_Width = element.offsetWidth;
  var HTML_Height = element.offsetHeight;
  var top_left_margin = 15;
  var PDF_Width = HTML_Width+(top_left_margin*2);
  var PDF_Height = (PDF_Width*1.5)+(top_left_margin*2);
  var canvas_image_width = HTML_Width;
  var canvas_image_height = HTML_Height;
  var pdf = new jsPDF('p', 'pt',  [PDF_Width, PDF_Height]);
   setTimeout(() => {
    html2canvas(element).then((canvas) => {
          canvas.toBlob((blob) => {
          pdf.addImage(canvas, 'PNG', top_left_margin, top_left_margin,canvas_image_width,canvas_image_height,'','FAST');
          pdf.save("carteirinha-vivamax-"+this.carteirinhaSelecionada.nome+".pdf");
        },'image/png');
        this.loadCard = false;
      });
    },1000);
  }

  baixarCarteirinhaTitular(){
    this.loadCard = true;
    this.carteirinhaSelecionada = this.carteirinhas[0];
    let element = document.getElementById("carteiraDownload");
    var HTML_Width = element.offsetWidth;
    var HTML_Height = element.offsetHeight;
    var top_left_margin = 15;
    var PDF_Width = HTML_Width+(top_left_margin*2);
    var PDF_Height = (PDF_Width*1.5)+(top_left_margin*2);
    var canvas_image_width = HTML_Width;
    var canvas_image_height = HTML_Height;
    var pdf = new jsPDF('p', 'pt',  [PDF_Width, PDF_Height]);
     setTimeout(() => {
      html2canvas(element).then((canvas) => {
            canvas.toBlob((blob) => {
            pdf.addImage(canvas, 'PNG', top_left_margin, top_left_margin,canvas_image_width,canvas_image_height,'','FAST');
            pdf.save("carteirinha-vivamax-"+this.carteirinhaSelecionada.nome+".pdf");
          },'image/png');
          this.loadCard = false;
        });
      },1000);
  }
  
escolherTipoPlano(tipoPlano){
  switch(tipoPlano){
    case 'FISICO' : {
    this.planoFisicoCheck = true;
    this.planoEmpresarialCheck = false;
    if(this.planosFisicos.length < 1){
      this.app.openAlert("Não Há Planos Físicos Conecta Cadastrados!")
    }
    }
    break;
    case 'SERVIDOR' : {
      this.planoFisicoCheck = false;
      this.planoEmpresarialCheck = false;
      if(this.planosServidores.length < 1){
        this.app.openAlert("Não Há Planos Empresariais Conecta Cadastrados!")
      }
    }
    break;
    case 'EMPRESARIAL' : {
      this.planoFisicoCheck = false;
      this.planoEmpresarialCheck = true;
      if(this.planosEmpresariais.length < 1){
        this.app.openAlert("Não Há Planos Empresariais Conecta Cadastrados!")
      }
    }
    break;
  }
}

escolherTipoPlanoEditar(tipoPlano){
  switch(tipoPlano){
    case 'FISICO' : {
    this.planoFisicoCheckEditar = true;
    this.planoEmpresarialCheckEditar = false;
    if(this.planosFisicos.length < 1){
      this.app.openAlert("Não Há Planos Físicos Conecta Cadastrados!")
    }
    }
    break;
    case 'SERVIDOR' : {
      this.planoFisicoCheckEditar = false;
      this.planoEmpresarialCheckEditar = false;
      if(this.planosServidores.length < 1){
        this.app.openAlert("Não Há Planos Empresariais Conecta Cadastrados!")
      }
    }
    break;
    case 'EMPRESARIAL' : {
      this.planoFisicoCheckEditar = false;
      this.planoEmpresarialCheckEditar = true;
      if(this.planosEmpresariais.length < 1){
        this.app.openAlert("Não Há Planos Empresariais Conecta Cadastrados!")
      }
    }
    break;
  }
}

enviarDoc24(){
  this.qtdeConecta = 0;
  this.totalAsfDependentesEditar = 0;
  this.totalMensalidadeEditar = 0;
  this.db.getTokenDoc().subscribe((res: any) => {
    let tokenDoc: any = res.token;
    if (!tokenDoc) {
      this.app.openAlert("Erro ao obter token Doc24, favor contatar o suporte!");
      return;
    }
    let observables = [];
    let { firstName, lastName } = this.app.splitName(this.nomeEditar);
    let cpfLimpo: any = this.app.limpaCPF(this.cpfEditar);
    let body: any = {"afiliado":{
      "nombre": firstName,
      "apellido": lastName,
      "sexo": this.sexoEditar,
      "email": this.emailEditar,
      "fecha_nacimiento":this.nascimentoEditar,
      "nro_documento": cpfLimpo
    }}
    observables.push(
      this.db.postDoc24(tokenDoc, body).pipe(
        catchError(err => {
          console.log(err);
          return of(null);
        }),
        map((res: any) => {
          console.log(res);
        })
      )
    )
  if (this.dependentesEditar.value.dependenteEditar.length > 0) {
    for (let i: any = 0; i < this.dependentesEditar.value.dependenteEditar.length; i++) {
      let item = this.dependentesEditar.value.dependenteEditar[i];
      if (item.conecta) {
        this.qtdeConecta++;
        let { firstName, lastName } = this.app.splitName(item.nome);
        let cpfLimpo: any = this.app.limpaCPF(item.cpf);
        let dataNascimento:any = item.nascimento ? item.nascimento.split('/').reverse().join('-') : "";
        let body: any = {"afiliado":{
          "nombre": firstName,
          "apellido": lastName,
          "sexo": item.sexo,
          "email": item.email,
          "fecha_nacimiento":dataNascimento,
          "nro_documento": cpfLimpo
        }}
        observables.push(
          this.db.postDoc24(tokenDoc, body).pipe(
            catchError(err => {
              console.log(err);
              return of(null);
            }),
            map((res: any) => {
              console.log(res);
              if (item.asf == "FUNERAL CONTRATADO" && item.situacao == "ATIVO") {
                this.totalAsfDependentesEditar += this.valorFuneralFilhosMaiorEditar;
              }
            })
          )
        );
      }
    }
  }
  if (this.dependentesExtraEditar.value.dependenteExtraEditar.length > 0) {
    for (let i: any = 0; i < this.dependentesExtraEditar.value.dependenteExtraEditar.length; i++) {
      let itemExtra: any = this.dependentesExtraEditar.value.dependenteExtraEditar[i];
      if (itemExtra.conecta) {
        this.qtdeConecta++;
        let { firstName, lastName } = this.app.splitName(itemExtra.nome);
        let cpfLimpo: any = this.app.limpaCPF(itemExtra.cpf);
        let dataNascimentoExtra:any = itemExtra.nascimento ? itemExtra.nascimento.split('/').reverse().join('-') : "";
        let body: any ={"afiliado":{
          "nombre": firstName,
          "apellido": lastName,
          "sexo": itemExtra.sexo,
          "email": itemExtra.email,
          "fecha_nacimiento":dataNascimentoExtra,
          "nro_documento": cpfLimpo
        }}
        observables.push(
          this.db.postDoc24(tokenDoc, body).pipe(
            catchError(err => {
              console.log(err);
              return of(null);
            }),
            map((res: any) => {
              console.log(res);
              if (this.funeralExtraEditar == "SIM" && itemExtra.asf == "FUNERAL CONTRATADO" && itemExtra.situacao == "ATIVO") {
                this.totalAsfDependentesExtrasEditar += this.valorFuneralExtraEditar;
              }
            })
          )
        );
      }
    }
  }
  if (observables.length > 0) {
    forkJoin(observables).subscribe(() => {
      this.patchDependentes();
    });
  } else {
    this.patchDependentes();
  }
});
}

converterPlanoParaConecta(tipoAntigo:any){
  if(window.confirm("Deseja realmente converter esse plano?")){
    this.mudancaParaConecta = true;
    let ctrl:any = 1;
    this.planoEditar = "";
    this.empresaAssociado = "CONECTA";
    this.planosFisicosEditar = [];
    this.planosServidoresEditar = [];
    this.planosEmpresariaisEditar = [];
      this.db.getPlanosConecta().subscribe((p:any) => {
        p.forEach((item:any) => {
        if(item.tipo == 'PLANO FISICO'){
          this.planosFisicosEditar.push(item);
        }
        if(item.tipo == 'PLANO SERVIDOR'){
          this.planosServidoresEditar.push(item);
        }
        if(item.tipo == 'PLANO EMPRESARIAL'){
          this.planosEmpresariaisEditar.push(item);
        }
        if(ctrl == p.length){
          if(this.planoFisicoCheckEditar == true){
            this.selectPlanoEditar('fisico',this.planoEditar,'start');
          }else if(this.planoEmpresarialCheckEditar == true){
            this.selectPlanoEditar('empresarial',this.planoEditar,'start');
          }else{
            this.selectPlanoEditar('servidor',this.planoEditar,'start');
          }
        }else{
          ctrl++
        }
        })
      });
    let jsonDate = new Date().toJSON();
    let hora = new Date(jsonDate).toLocaleTimeString();
    let body = {
      "menu" : "EDITAR ASSOCIADOS", 
      "descricao" : "MATRICULA: "+this.matriculaEditar+" - ASSOCIADO: "+this.nomeEditar+" - Tipo de Plano Alterado de " +tipoAntigo+ "para CONECTA", 
      "data" : this.dataHoje, 
      "hora" : hora, 
      "usuario" : this.user
    }
     this.db.postLogs(body).subscribe(res => {
      console.log(res);
    })
  }
}

converterPlano(tipo:any){
  if(window.confirm("Deseja realmente converter esse plano?")){
    let ctrl:any = 1;
    this.planoEditar = "";
    if(this.empresaAssociado == "CONECTA"){
      this.mudancaDeConecta = true;
    }
    if(tipo == 'vivamax'){
      this.empresaAssociado = "VIVA MAX";
    }else if(tipo == 'seguros'){
      this.empresaAssociado = "SEGUROS";
      let ctrlDep:any = 1;
      let ctrlDepExtra:any = 1;
      if(this.listaDeDependentesEditar.value.length > 0){
          this.listaDeDependentesEditar.value.forEach((d:any) => {
            d.situacao = "SUSPENSO";
            if(this.listaDeDependentesEditar.value.length == ctrlDep){
              if(this.listaDeDependentesExtraEditar.value.length > 0){
                this.listaDeDependentesExtraEditar.value.forEach((dExtra:any) => {
                  dExtra.situacao = "SUSPENSO";
                  if(this.listaDeDependentesExtraEditar.value.length == ctrlDepExtra){
                    
                  }else{
                    ctrlDepExtra++;
                  }
                }) 
              }
            }else{
              ctrlDep++;
            }
          }) 
      }else{
        if(this.listaDeDependentesExtraEditar.value.length > 0){
          this.listaDeDependentesExtraEditar.value.forEach((dExtra:any) => {
            dExtra.situacao = "SUSPENSO";
            if(this.listaDeDependentesExtraEditar.value.length == ctrlDepExtra){
            }else{
              ctrlDepExtra++;
            }
          }) 
        }
      }
    }
    this.planosFisicosEditar = [];
    this.planosServidoresEditar = [];
    this.planosEmpresariaisEditar = [];
    if(this.empresaAssociado == "SEGUROS"){
      this.db.getPlanosVivamaxSeguro().subscribe((p:any) => {
        p.forEach((item:any) => {
        if(item.tipo == 'PLANO FISICO'){
          this.planosFisicosEditar.push(item);
        }
        if(item.tipo == 'PLANO SERVIDOR'){
          this.planosServidoresEditar.push(item);
        }
        if(item.tipo == 'PLANO EMPRESARIAL'){
          this.planosEmpresariaisEditar.push(item);
        }
        if(ctrl == p.length){
          if(this.planoFisicoCheckEditar == true){
            this.selectPlanoEditar('fisico',this.planoEditar,'start');
          }else if(this.planoEmpresarialCheckEditar == true){
            this.selectPlanoEditar('empresarial',this.planoEditar,'start');
          }else{
            this.selectPlanoEditar('servidor',this.planoEditar,'start');
          }
        }else{
          ctrl++
        }
        })
      });
    }
    if(this.empresaAssociado == "VIVA MAX"){
      this.db.getPlanosVivamax().subscribe((p:any) => {
        p.forEach((item:any) => {
        if(item.tipo == 'PLANO FISICO'){
          this.planosFisicosEditar.push(item);
        }
        if(item.tipo == 'PLANO SERVIDOR'){
          this.planosServidoresEditar.push(item);
        }
        if(item.tipo == 'PLANO EMPRESARIAL'){
          this.planosEmpresariaisEditar.push(item);
        }
        if(ctrl == p.length){
          if(this.planoFisicoCheckEditar == true){
            this.selectPlanoEditar('fisico',this.planoEditar,'start');
          }else if(this.planoEmpresarialCheckEditar == true){
            this.selectPlanoEditar('empresarial',this.planoEditar,'start');
          }else{
            this.selectPlanoEditar('servidor',this.planoEditar,'start');
          }
        }else{
          ctrl++
        }
        })
      });
    }
    let jsonDate = new Date().toJSON();
    let hora = new Date(jsonDate).toLocaleTimeString();
    let frase:any = "";
    if(this.mudancaDeConecta){
      frase = tipo == 'vivamax' ?  'Tipo de Plano Alterado de CONECTA para VIVA MAX' : 'Tipo de Plano Alterado de CONECTA para SEGUROS';
    }else{
      frase = tipo == 'vivamax' ?  'Tipo de Plano Alterado de SEGUROS para VIVA MAX' : 'Tipo de Plano Alterado de VIVA MAX para SEGUROS';
    }
    let body = {
      "menu" : "EDITAR ASSOCIADOS", 
      "descricao" : "MATRICULA: "+this.matriculaEditar+" - ASSOCIADO: "+this.nomeEditar+" - "+frase, 
      "data" : this.dataHoje, 
      "hora" : hora, 
      "usuario" : this.user
    }
    this.db.postLogs(body).subscribe(res => {
      console.log(res);
    })
  }
}

qtdeConectaDependentes():number{
  let qtdeConecta:number = 0;
  if(this.dependentes.value.dependente.length > 0){
    for(let i:any = 0; i < this.dependentes.value.dependente.length; i++){
      if(this.dependentes.value.dependente[i].conecta){
        qtdeConecta++;
      }
        if(i+1 == this.dependentes.value.dependente.length){
          if(this.dependentesExtra.value.dependenteExtra.length > 0){
            for(let iE:any = 0; iE < this.dependentesExtra.value.dependenteExtra.length; iE++){
              if(this.dependentesExtra.value.dependenteExtra[iE].conecta){
                qtdeConecta++;
              }
            }
          }
        }
      }
      }else{
        if(this.dependentesExtra.value.dependenteExtra.length > 0){
          for(let iE:any = 0; iE < this.dependentesExtra.value.dependenteExtra.length; iE++){
            if(this.dependentesExtra.value.dependenteExtra[iE].conecta){
              qtdeConecta++;
            }
          }
        }
      }
      return qtdeConecta;
}

qtdeConectaDependentesEditar():number{
  let qtdeConecta:number = 0;
  if(this.dependentesEditar.value.dependenteEditar.length > 0){
    for(let i:any = 0; i < this.dependentesEditar.value.dependenteEditar.length; i++){
      if(this.dependentesEditar.value.dependenteEditar[i].conecta){
        qtdeConecta++;
      }
        if(i+1 == this.dependentesEditar.value.dependenteEditar.length){
          if(this.dependentesExtraEditar.value.dependenteExtraEditar.length > 0){
            for(let iE:any = 0; iE < this.dependentesExtraEditar.value.dependenteExtraEditar.length; iE++){
              if(this.dependentesExtraEditar.value.dependenteExtraEditar[iE].conecta){
                qtdeConecta++;
              }
            }
          }
        }
      }
      }else{
        if(this.dependentesExtraEditar.value.dependenteExtraEditar.length > 0){
          for(let iE:any = 0; iE < this.dependentesExtraEditar.value.dependenteExtraEditar.length; iE++){
            if(this.dependentesExtraEditar.value.dependenteExtraEditar[iE].conecta){
              qtdeConecta++;
            }
          }
        }
      }
      return qtdeConecta;
}

checkConectaEditar(user:any){
  this.totalValorConectaExtrasEditar = 0;
  this.qtdeConecta = 0;
  let conectaChecked:any = user.conecta;
  if(!conectaChecked){
    this.arrConecta.push(user)
  }
  if(this.listaDeDependentesEditar.value.length > 0){
    this.listaDeDependentesEditar.value.forEach((item:any) => {
      if(item.conecta){
        this.qtdeConecta++;
      }
    })
  }
  if(this.listaDeDependentesExtraEditar.value.length > 0){
    this.listaDeDependentesExtraEditar.value.forEach((item:any) => {
      if(item.conecta){
        this.qtdeConecta++;
      }
    })
  }  
  if(this.qtdeConecta > this.conectaInclusosEditar){
    let diferenca:number = this.qtdeConecta - this.conectaInclusosEditar;
    this.totalValorConectaExtrasEditar = diferenca * this.valorConectaExtraEditar;
  }
    this.totalMensalidadeEditar = this.totalAsfDependentesEditar + this.totalAsfDependentesExtrasEditar + this.mensalidadeEditar;
}

checkConectaExtraEditar(user:any){
  this.totalValorConectaExtrasEditar = 0;
  let conectaChecked:any = user.conecta;
  this.qtdeConecta = 0;
  if(!conectaChecked){
    this.arrConectaExtra.push(user)
  }
  if(this.listaDeDependentesEditar.value.length > 0){
    this.listaDeDependentesEditar.value.forEach((item:any) => {
      if(item.conecta){
        this.qtdeConecta++;
      }
    })
  }
  if(this.listaDeDependentesExtraEditar.value.length > 0){
    this.listaDeDependentesExtraEditar.value.forEach((item:any) => {
      if(item.conecta){
        this.qtdeConecta++;
      }
    })
  }
  if(this.qtdeConecta > this.conectaInclusosEditar){
    let diferenca:number = this.qtdeConecta - this.conectaInclusosEditar;
    this.totalValorConectaExtrasEditar = diferenca * this.valorConectaExtraEditar;
  }
    this.totalMensalidadeEditar = this.totalAsfDependentesEditar + this.totalAsfDependentesExtrasEditar + this.mensalidadeEditar + this.totalValorConectaExtrasEditar;
}

cancelarTodosConecta(){
  this.db.getTokenDoc().subscribe((res: any) => {
    let tokenDoc: any = res.token;
    if (!tokenDoc) {
      this.app.openAlert("Erro ao obter token Doc24, favor contatar o suporte!");
      return;
    }
    let cpf:any = this.app.limpaCPF(this.cpfEditar);
    this.db.delDoc24(tokenDoc, cpf).subscribe((result:any) => {
      console.log(result);
      if(this.listaDeDependentesEditar.value.length > 0){
        this.listaDeDependentesEditar.value.forEach((item:any) => {
          let cpfLimpo:any = this.app.limpaCPF(item.cpf);
          if(item.conecta){
           this.db.delDoc24(tokenDoc, cpfLimpo).subscribe((resT:any) => console.log(resT), (errT:any) => console.log(errT))
          }
        })
      }
      if(this.listaDeDependentesExtraEditar.value.length > 0){
        this.listaDeDependentesExtraEditar.value.forEach((itemExtra:any) => {
          let cpfLimpoExtra:any = this.app.limpaCPF(itemExtra.cpf);
          if(itemExtra.conecta){
            this.db.delDoc24(tokenDoc, cpfLimpoExtra).subscribe((resE:any) => console.log(resE), (errE:any) => console.log(errE))
           }
        })
      }
    }, (error:any) => {
      console.log(error)
    })
  })
}

}